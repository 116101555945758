<div class="header-bar header-bar-default" style="text-align: center;">{{ 'global.analytics.title' | translate }}</div>
<span [ngClass]="{ 'listing-card': listing }">
  <ion-row>
    <ion-col size="5">
      <div class="metrics-headings">{{ 'global.analytics.metrics' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-headings">{{ 'global.analytics.last30Days' | translate }}</div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-headings">{{ 'global.analytics.total' | translate }}</div>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="listingCount">
    <ion-col size="12">
      <div class="metrics-desc">
        {{ 'global.menu.options.listings' | translate }}
        <span class="listings-count">({{ (listingCount ? listingCount : 0) | localNumber: '1.0-0' }})</span>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="5">
      <div class="metrics-desc">{{ 'global.analytics.cardViews' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.monthlyMetrics?.cardViews ? listingAnalytics?.monthlyMetrics?.cardViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.monthlyMetrics?.cardViews ? listing?.analyticsDTO?.monthlyMetrics?.cardViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.totalMetrics?.cardViews ? listingAnalytics?.totalMetrics?.cardViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.totalMetrics?.cardViews ? listing?.analyticsDTO?.totalMetrics?.cardViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="5">
      <div class="metrics-desc">{{ 'global.analytics.detailsViews' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.monthlyMetrics?.detailViews ? listingAnalytics?.monthlyMetrics?.detailViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.monthlyMetrics?.detailViews
              ? listing?.analyticsDTO?.monthlyMetrics?.detailViews
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.totalMetrics?.detailViews ? listingAnalytics?.totalMetrics?.detailViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.totalMetrics?.detailViews ? listing?.analyticsDTO?.totalMetrics?.detailViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="5">
      <div class="metrics-desc">{{ 'global.analytics.phoneClicks' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.monthlyMetrics?.phoneClicks ? listingAnalytics?.monthlyMetrics?.phoneClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.monthlyMetrics?.phoneClicks
              ? listing?.analyticsDTO?.monthlyMetrics?.phoneClicks
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.totalMetrics?.phoneClicks ? listingAnalytics?.totalMetrics?.phoneClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.totalMetrics?.phoneClicks ? listing?.analyticsDTO?.totalMetrics?.phoneClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="5">
      <div class="metrics-desc">{{ 'global.analytics.whatsappClicks' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.monthlyMetrics?.whatsappClicks ? listingAnalytics?.monthlyMetrics?.whatsappClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.monthlyMetrics?.whatsappClicks
              ? listing?.analyticsDTO?.monthlyMetrics?.whatsappClicks
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.totalMetrics?.whatsappClicks ? listingAnalytics?.totalMetrics?.whatsappClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.totalMetrics?.whatsappClicks
              ? listing?.analyticsDTO?.totalMetrics?.whatsappClicks
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="5">
      <div class="metrics-desc">{{ 'global.analytics.emailClicks' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.monthlyMetrics?.emailClicks ? listingAnalytics?.monthlyMetrics?.emailClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.monthlyMetrics?.emailClicks
              ? listing?.analyticsDTO?.monthlyMetrics?.emailClicks
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.totalMetrics?.emailClicks ? listingAnalytics?.totalMetrics?.emailClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.totalMetrics?.emailClicks ? listing?.analyticsDTO?.totalMetrics?.emailClicks : 0)
              | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="agent-profile">
    <ion-col size="5">
      <div class="metrics-desc">{{ 'global.analytics.agentProfile' | translate }}</div>
    </ion-col>
    <ion-col size="4">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.monthlyMetrics?.agentProfileViews
              ? listingAnalytics?.monthlyMetrics?.agentProfileViews
              : 0) | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.monthlyMetrics?.agentProfileViews
              ? listing?.analyticsDTO?.monthlyMetrics?.agentProfileViews
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
    <ion-col size="3">
      <div class="metrics-amount">
        <span *ngIf="!listing">
          {{
            (listingAnalytics?.totalMetrics?.agentProfileViews ? listingAnalytics?.totalMetrics?.agentProfileViews : 0)
              | localNumber: '1.0-0'
          }}</span
        >
        <span *ngIf="listing">
          {{
            (listing?.analyticsDTO?.totalMetrics?.agentProfileViews
              ? listing?.analyticsDTO?.totalMetrics?.agentProfileViews
              : 0) | localNumber: '1.0-0'
          }}</span
        >
      </div>
    </ion-col>
  </ion-row>
</span>
