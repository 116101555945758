import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { SpotBuildingSearchRequest } from '@app/models/spot-buildings/spot-building-search.model';
import { SpotBuildingsSearchService } from '@app/spot-buildings/services/spot-buildings-search.service';
import { faRandom, faSearch, faSearchLocation, faSortAmountDownAlt, faSortAmountUpAlt, faSave, faMap, faList } from '@fortawesome/free-solid-svg-icons';
import { SearchTagsService } from '../../../core/search-tags.service';
import { I18nService } from '../../../core/i18n.service';
import { PROPERTY_TYPES } from '@app/models';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { IonContent } from '@ionic/angular';
import { PaginationComponent } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { ListingType } from '@app/models/transaction-type.model';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { SpotSearchService } from '../../services/spot-search.service';
import { CommonGenericService } from '../../../core/common.service';
import { SaveChangeModalComponent } from '@app/shared/save-change-prompt-modal/save-change-modal.component';
import { SaveModalTypes } from '@app/models/shared/shared-interfaces.model';
import { LoginFormDialogService } from '@app/shared/login';
import { AuthenticationService } from '@app/core/auth';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from '@app/core/seo';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { AppRouteNamesService } from '@app/core/helpers/app-route-names.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { Logger } from '@app/core';
var log = new Logger('Search-Spots');
var SpotBuildingsSearchComponent = /** @class */ (function () {
    function SpotBuildingsSearchComponent(popOverCtrlHelper, spotBuildingSearchService, searchTagService, i18nService, activatedRoute, nearbyListingSvc, spotSearchSrv, commonService, loginFormDialog, router, auth, _ts, metaService, _location, routingHelperSrv, appRouteNames, platformId, appId) {
        var _this = this;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.spotBuildingSearchService = spotBuildingSearchService;
        this.searchTagService = searchTagService;
        this.i18nService = i18nService;
        this.activatedRoute = activatedRoute;
        this.nearbyListingSvc = nearbyListingSvc;
        this.spotSearchSrv = spotSearchSrv;
        this.commonService = commonService;
        this.loginFormDialog = loginFormDialog;
        this.router = router;
        this.auth = auth;
        this._ts = _ts;
        this.metaService = metaService;
        this._location = _location;
        this.routingHelperSrv = routingHelperSrv;
        this.appRouteNames = appRouteNames;
        this.platformId = platformId;
        this.appId = appId;
        this.views = { map: 'global.search.viewMap', list: 'global.search.viewList' };
        this.currentView = this.views.list;
        this.topMap = 0;
        this.displayScrollToTop = false;
        this.breadcrumbNavOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.propertyTypes = tslib_1.__spread(PROPERTY_TYPES);
        //Map
        this.mapOptions = {
            zoom: 15,
            singleMarker: false,
            ignoreZoom: false,
            geopositionControl: true
        };
        this.hasGeoLocationError = false;
        // Search
        this.currentPage = 1;
        this.searching = false;
        this.pageSize = 10;
        this.maxSizePag = 4;
        // Results
        this.totalSpotBuildingsCount = 0;
        this.allSpotBuildings = [];
        this.stepValue = 20;
        this.previousScrollPost = this.stepValue;
        this.paginationHeader = '';
        this.justLoaded = true;
        this.topPropertiesCount = 0;
        this.otherPropertiesCount = 0;
        this.totalPropertiesCount = 0;
        this.scrollEvent = function (event) {
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
            var scrollPosition = event.srcElement.scrollTop;
            var delta = (scrollPosition / _this.outerScreenHeight) * 100;
            _this.displayScrollToTop = delta > 50;
            /*
            let pos = 0;
            pos = (this.outerScreenHeight * 3 * delta) / 100;
        
            if (scrollPosition > 70) {
              if (delta > 50) {
                this.topMap = this.topMap - 50;
              } else {
                this.topMap = scrollPosition - 70;
              }
            }
        
            if (this.topMap < 0 || this.topMap >= 300) {
              this.topMap = 0;
            }
        
           // console.log(`Inner ${window.innerHeight}`);
            //console.log(`Scroll Position: ${scrollPosition} - % Map Top: ${this.topMap} - Delta: ${delta}`);
        
           // this.spotBulidingCardsContainer.scrollToPoint(0, pos, 0);
        
            //this.mapResultsContainer.scrollToTop();
            */
        };
        this.showGeoLocationError = function (error) {
            _this.hasGeoLocationError = true;
            _this.noListingsFoundMessage = _this.nearbyListingSvc.getNoResultMessage(0);
            _this.geoLocationErrorCode = _this.nearbyListingSvc.getGeoLocationErrorCode(error);
        };
        this.searchByGeoLocation = function (position) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.hasGeoLocationError = false;
                this.nearbyListingSvc
                    .getUserLocationCoordinate(position.coords, this.searchModel.propertyType)
                    .then(function (position) {
                    _this.clientPosition = position;
                    _this.setGeoPositionValuesToRequest(_this.clientPosition);
                    _this.searchBuildingListings();
                });
                return [2 /*return*/];
            });
        }); };
        this.screenWidth = window.innerWidth;
        this.outerScreenWidth = window.outerWidth;
        if (this.screenWidth < 500) {
            this.maxSizePag = 3;
        }
        this.outerScreenHeight = window.outerHeight;
        this.resetSearchModel();
        this.faSearch = faSearch;
        this.locationIcon = faSearchLocation;
        this.spotBuildingsResultsHeight = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--spot-building-results-height'));
        this.faSortAmountDown = faSortAmountDownAlt;
        this.faSortAmountUp = faSortAmountUpAlt;
        this.faSortIcon = faRandom;
        this.faMap = faMap;
        this.faList = faList;
        this.faSave = faSave;
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe(function (res) {
            _this.customLoginText = res;
        });
    }
    SpotBuildingsSearchComponent.prototype.onResize = function (event) {
        if (event) {
            this.screenWidth = event.target.innerWidth;
            this.outerScreenWidth = event.target.outerWidth;
        }
        if (this.screenWidth < 500) {
            this.maxSizePag = 3;
        }
    };
    SpotBuildingsSearchComponent.prototype.resetSearchModel = function () {
        this.searchModel = {
            keywordText: '',
            propertyType: '',
            listingType: '',
            stateId: null,
            cityId: null,
            propertySubTypes: null,
            spotSetName: null,
            currencyType: 'MXN'
        };
    };
    SpotBuildingsSearchComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var onlyMetaTags;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metaService.setHomeMetatags();
                        return [4 /*yield*/, this.metaService.onlyMetatags()];
                    case 1:
                        onlyMetaTags = _a.sent();
                        if (onlyMetaTags) {
                            return [2 /*return*/];
                        }
                        this.firstLbl = this.i18nService.get('global.first');
                        this.lastLbl = this.i18nService.get('global.last');
                        this.initializeSpotSearchRequest();
                        this.subscribeToMarkerSelection();
                        window.addEventListener('scroll', this.scrollEvent, true);
                        this.buildingTypeTranslated = this._ts.instant('global.menu.entities.buildingType');
                        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) {
                            _this.isLoggedIn = val;
                        });
                        this.initAsync();
                        this.justLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.subscribeToMarkerSelection = function () {
        var _this = this;
        this.selectionSubscription = this.spotBuildingSearchService.selectMarker$.subscribe(function (listing) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var selectedSpotBuilding;
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        selectedSpotBuilding = this.allSpotBuildings.find(function (spotBuilding) { return spotBuilding.id === listing.listingIds[0]; });
                        if (selectedSpotBuilding) {
                            this.selectedMarkerBuilding = selectedSpotBuilding;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.spotBuildingSearchService.getSpotBuildingDetails(1, 1, [listing.listingIds[0]], this.searchModel.currencyType)];
                    case 1:
                        _a = tslib_1.__read.apply(void 0, [_b.sent(), 1]), selectedSpotBuilding = _a[0];
                        if (selectedSpotBuilding) {
                            this.selectedMarkerBuilding = selectedSpotBuilding;
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SpotBuildingsSearchComponent.prototype.unsubscribeFromMarkerSelection = function () {
        if (this.selectionSubscription) {
            this.selectionSubscription.unsubscribe();
        }
    };
    SpotBuildingsSearchComponent.prototype.updateURLBasedOnDTO = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, lang, newUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spotBuildingSearchService.getUrlFromListingsSearchDto(this.spotBuildingSearchRequest)];
                    case 1:
                        response = _a.sent();
                        lang = this.i18nService.getCurrentLanguage();
                        response.url = JSON.parse(response.url);
                        newUrl = response.url[lang];
                        if (response.keyword)
                            newUrl = "" + response.url[lang] + (response.keyword ? '?keyword=' + response.keyword : '');
                        if (response.spotSet)
                            newUrl =
                                response.url[lang] + "/" + this.appRouteNames.getFilterRouteName() + "/" + lang +
                                    '?' +
                                    this.appRouteNames.getSpotSetRouteName() +
                                    '=' +
                                    response.spotSet;
                        if (response.building)
                            newUrl = "" + response.url[lang] + (response.building ? '?building=' + response.building : '');
                        if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                            this._location.go(this.i18nService.addCurrentLanguageToPath("" + this.routingHelperSrv.getSpotSearchURL() + newUrl));
                        }
                        else {
                            this._location.go("" + this.routingHelperSrv.getSpotSearchURL() + newUrl);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.setSpotRequest = function (dto) {
        this.spotBuildingSearchRequest = dto;
    };
    SpotBuildingsSearchComponent.prototype.buildSearchDTOBasedOnURL = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchURL, homeFilters_1, buildingNameFilter_1, error_1, state;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchURL = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        homeFilters_1 = this.spotBuildingSearchService.readSearchDTOFromStorage(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS, null);
                        if (!(this.activatedRoute.snapshot.url.length > 1)) return [3 /*break*/, 3];
                        searchURL = this.activatedRoute.snapshot.url.slice(1).join('/');
                        searchURL = this.i18nService.removeLanguageFromURL(searchURL);
                        if (!searchURL || searchURL.length == 0) {
                            return [2 /*return*/];
                        }
                        buildingNameFilter_1 = this.activatedRoute.snapshot.queryParamMap.get('building');
                        return [4 /*yield*/, this.spotBuildingSearchService
                                .findSearchDTOFromURL(searchURL, this.activatedRoute.snapshot.queryParamMap.get('keyword'), buildingNameFilter_1, this.activatedRoute.snapshot.queryParamMap.get(this.appRouteNames.getSpotSetRouteName()))
                                .then(function (dtoFromURL) {
                                if (dtoFromURL) {
                                    _this.setSpotRequest(dtoFromURL);
                                    if (buildingNameFilter_1) {
                                        _this.spotBuildingSearchRequest.buildingName = buildingNameFilter_1;
                                        _this.searchModel.currencyType = null;
                                        _this.spotBuildingSearchRequest.currencyType = null;
                                    }
                                    if (homeFilters_1) {
                                        var tempModel = _this.setQueryParamsFilters(homeFilters_1);
                                        if (tempModel) {
                                            _this.spotBuildingSearchRequest.neighborhood = tempModel.neighborhood || null;
                                            _this.spotBuildingSearchRequest.keyword =
                                                tempModel.neighborhood || (tempModel.marketIds && tempModel.marketIds.length > 0)
                                                    ? null
                                                    : _this.spotBuildingSearchRequest.keyword;
                                            _this.spotBuildingSearchRequest.stateId = tempModel.stateId ? tempModel.stateId : null;
                                            _this.spotBuildingSearchRequest.cityId = tempModel.cityId ? tempModel.cityId : null;
                                            _this.spotBuildingSearchRequest.marketIds = tempModel.marketIds;
                                            _this.spotBuildingSearchRequest.fakeMarketId = tempModel.fakeMarketId;
                                        }
                                    }
                                    _this.spotBuildingSearchService.removeFilterPreferencesFrom(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS);
                                    _this.updateSearchModelFromSearchDTO();
                                    if (buildingNameFilter_1) {
                                        _this.searchModel.currencyType = null;
                                        _this.spotBuildingSearchRequest.currencyType = null;
                                    }
                                    _this.searchFilterAction();
                                }
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        state = {
                            badURL: environment.spotServerUrl + "/spot-search/" + searchURL
                        };
                        console.error('Error search page', error_1, searchURL);
                        this.router.navigateByUrl('/not-found', { state: state });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.initializeSpotSearchRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var queryParams, savedFilters, params, keepRunning, savedSearchId, userId, savedSearch;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParams = this.activatedRoute.snapshot.queryParams;
                        savedFilters = this.spotBuildingSearchService.readSearchDTOFromStorage(SelectedFilterKey.SPOT_BUILDING_SEARCH, null);
                        this.spotBuildingSearchRequest = new SpotBuildingSearchRequest();
                        this.setDefaultFilterValues();
                        params = this.activatedRoute.snapshot.params;
                        if (!(params && params['listingType'] && params['propertyType'])) return [3 /*break*/, 1];
                        this.buildSearchDTOBasedOnURL();
                        return [3 /*break*/, 4];
                    case 1:
                        keepRunning = true;
                        if (!(params && params['savedSearchId'] && params['userId'])) return [3 /*break*/, 3];
                        savedSearchId = params['savedSearchId'];
                        userId = params['userId'];
                        return [4 /*yield*/, this.spotBuildingSearchService.findSavedSearchBy(userId, savedSearchId)];
                    case 2:
                        savedSearch = _a.sent();
                        if (savedSearch && savedSearch.json) {
                            this.loadSavedSearch(savedSearch.json);
                            keepRunning = false;
                        }
                        _a.label = 3;
                    case 3:
                        if (!keepRunning) {
                            return [2 /*return*/];
                        }
                        if (queryParams.keyword) {
                            this.setSpotRequest(savedFilters == null ? {} : savedFilters);
                            this.searchModel.keywordText = queryParams.keyword;
                        }
                        else if (queryParams && queryParams.filters) {
                            this.setQueryParamsFilters(queryParams);
                        }
                        else if (queryParams && queryParams.spotset) {
                            this.resetSearchModel();
                            this.searchModel.spotSetName = queryParams.spotset;
                        }
                        else if (queryParams && queryParams.building) {
                            this.resetSearchModel();
                            this.searchModel.buildingName = queryParams.building;
                        }
                        else if (savedFilters) {
                            this.setSpotRequest(savedFilters);
                            this.updateSearchModelFromSearchDTO();
                        }
                        this.searchFilterAction();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('scroll', this.scrollEvent, true);
        this.unsubscribeFromMarkerSelection();
    };
    SpotBuildingsSearchComponent.prototype.setDefaultFilterValues = function () {
        if (this.searchModel) {
            this.searchModel.listingType = 'L';
            this.setPropertyType(2001);
            this.searchModel.currencyType = 'MXN';
        }
    };
    SpotBuildingsSearchComponent.prototype.getQueryParamsFilters = function (filters) {
        if (!filters)
            return { filters: this.searchModel };
        var listingType = filters.listingType, propertyType = filters.propertyType, keyword = filters.keyword, advanced = filters.advanced;
        var stateId = null;
        if (keyword && (keyword.type === 'city' || keyword.type === 'state')) {
            stateId = keyword.type === 'city' ? keyword.stateId : keyword.id;
        }
        return {
            filters: tslib_1.__assign({}, this.searchModel, { listingType: listingType, propertyType: String(propertyType), marketIds: keyword && keyword.type === 'market' ? [keyword.id] : null, fakeMarketId: keyword && keyword.type === 'market' ? keyword.fakeId : null, cityId: keyword && keyword.type === 'city' ? keyword.id : null, neighborhood: keyword && keyword.type === 'neighborhood' ? keyword.name : null, stateId: stateId }),
            advanced: advanced
        };
    };
    SpotBuildingsSearchComponent.prototype.setQueryParamsFilters = function (homeFilters) {
        var _a = this.getQueryParamsFilters(homeFilters), filters = _a.filters, advanced = _a.advanced;
        //this.searchModel = filters;
        if (advanced) {
            this.openMorePopover();
        }
        return filters;
    };
    SpotBuildingsSearchComponent.prototype.initAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.spotBuildingSearchService.getCities()];
                    case 1:
                        _a.allCities = _d.sent();
                        _b = this;
                        return [4 /*yield*/, this.spotBuildingSearchService.getStates()];
                    case 2:
                        _b.states = _d.sent();
                        _c = this;
                        return [4 /*yield*/, this.spotBuildingSearchService.initCheckboxFields()];
                    case 3:
                        _c.checkBoxValues = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /* APPLY FILTERS */
    SpotBuildingsSearchComponent.prototype.searchFilterAction = function () {
        this.currentPage = 1;
        this.scrollToTop();
        this.searching = true;
        this.searchSpotBuildingsChangeListener();
    };
    SpotBuildingsSearchComponent.prototype.clearSearchAction = function (avoidAutoSearch) {
        this.clearSearchModel();
        this.setGeoPositionValuesToRequest(null);
        this.searchTagService.clearSearchTags();
        this.clearSpotBuildingRequest();
        this.noListingsFoundMessage = undefined;
        this.clearMapAndResults();
        this.justLoaded = true;
        if (!avoidAutoSearch)
            this.searchFilterAction();
    };
    SpotBuildingsSearchComponent.prototype.clearMapAndResults = function () {
        this.allSpotBuildings = [];
        this.spotMarkers = undefined;
        this.searching = false;
        this.totalSpotBuildingsCount = 0;
        this.allListingIds = [];
        this.currentPage = 1;
        this.topPropertiesCount = 0;
        this.otherPropertiesCount = 0;
        this.totalPropertiesCount = 0;
    };
    SpotBuildingsSearchComponent.prototype.clearSearchModel = function () {
        this.searchModel = {
            cityId: null,
            stateId: null,
            propertySubTypeIds: null,
            propertyType: '2001',
            propertySubTypes: null,
            listingType: 'L',
            keywordText: null,
            currencyType: 'MXN',
            marketIds: null,
            fakeMarketId: null,
            spotSetName: null,
            buildingName: null
        };
    };
    SpotBuildingsSearchComponent.prototype.clearSpotBuildingRequest = function () {
        this.allSpotBuildings = [];
        this.setSpotRequest(this.searchTagService.clearSearchTagsFromDTO(this.spotBuildingSearchRequest));
        if (this.spotBuildingSearchRequest) {
            this.spotBuildingSearchRequest.buildingTypes = null;
            this.spotBuildingSearchRequest.listingLeaseOrSale = null;
            this.spotBuildingSearchRequest.stateId = null;
            this.spotBuildingSearchRequest.cityId = null;
            this.spotBuildingSearchRequest.keyword = null;
            this.spotBuildingSearchRequest.spotTitlesOrAddresses = null;
            this.spotBuildingSearchRequest.currencyType = null;
            this.clearPropertySubtypes();
        }
    };
    SpotBuildingsSearchComponent.prototype.clearPropertySubtypes = function () {
        this.searchModel.propertySubTypes = null;
        this.spotBuildingSearchRequest.propertySubTypes = null;
        this.spotBuildingSearchRequest.propertySubtypeIds = null;
    };
    /* APPLY FILTERS*/
    SpotBuildingsSearchComponent.prototype.searchSpotBuildingsChangeListener = function () {
        this.updateSearchDTOFromModal();
        this.searchBuildingListings();
    };
    SpotBuildingsSearchComponent.prototype.updateSearchDTOFromModal = function () {
        if (this.spotBuildingSearchRequest && this.searchModel) {
            this.spotBuildingSearchRequest.stateId = this.searchModel.stateId ? this.searchModel.stateId : null;
            this.spotBuildingSearchRequest.cityId = this.searchModel.cityId ? this.searchModel.cityId : null;
            this.spotBuildingSearchRequest.neighborhood = this.searchModel.neighborhood || null;
            this.spotBuildingSearchRequest.keyword = this.searchModel.keywordText ? this.searchModel.keywordText : null;
            this.spotBuildingSearchRequest.listingLeaseOrSale = this.searchModel.listingType
                ? [this.searchModel.listingType]
                : null;
            this.spotBuildingSearchRequest.currencyType = this.searchModel.currencyType;
            this.spotBuildingSearchRequest.buildingTypes = this.searchModel.propertyType
                ? [this.searchModel.propertyType]
                : null;
            if (this.searchModel.propertySubTypes) {
                this.spotBuildingSearchRequest.propertySubTypes = this.searchModel.propertySubTypes;
                this.spotBuildingSearchRequest.propertySubtypeIds = this.searchModel.propertySubTypes.map(function (t) { return t.id; });
            }
            else {
                this.spotBuildingSearchRequest.propertySubtypeIds = null;
            }
            this.spotBuildingSearchRequest.marketIds = this.searchModel.marketIds;
            this.spotBuildingSearchRequest.fakeMarketId = this.searchModel.fakeMarketId;
            this.spotBuildingSearchRequest.spotSetName = this.searchModel.spotSetName;
            this.spotBuildingSearchRequest.buildingName = this.searchModel.buildingName;
            this.spotBuildingSearchRequest.spotSetId = this.searchModel.spotSetId;
        }
    };
    SpotBuildingsSearchComponent.prototype.updateSearchModelFromSearchDTO = function () {
        this.clearSearchModel();
        if (this.spotBuildingSearchRequest && this.searchModel) {
            this.searchModel.stateId = this.spotBuildingSearchRequest.stateId ? this.spotBuildingSearchRequest.stateId : null;
            this.searchModel.cityId = this.spotBuildingSearchRequest.cityId ? this.spotBuildingSearchRequest.cityId : null;
            this.searchModel.neighborhood = this.spotBuildingSearchRequest.neighborhood || null;
            this.searchModel.keywordText = this.spotBuildingSearchRequest.keyword
                ? this.spotBuildingSearchRequest.keyword
                : null;
            this.searchModel.listingType = this.spotBuildingSearchRequest.listingLeaseOrSale
                ? this.spotBuildingSearchRequest.listingLeaseOrSale[0]
                : null;
            this.searchModel.propertyType = this.spotBuildingSearchRequest.buildingTypes
                ? this.spotBuildingSearchRequest.buildingTypes[0]
                : null;
            if (this.spotBuildingSearchRequest.propertySubTypes) {
                this.searchModel.propertySubTypes = this.spotBuildingSearchRequest.propertySubTypes;
            }
            this.searchModel.currencyType = this.spotBuildingSearchRequest.currencyType
                ? this.spotBuildingSearchRequest.currencyType
                : this.searchModel.currencyType;
            this.searchModel.marketIds = this.spotBuildingSearchRequest.marketIds || null;
            this.searchModel.fakeMarketId = this.spotBuildingSearchRequest.fakeMarketId || null;
            this.searchModel.spotSetName = this.spotBuildingSearchRequest.spotSetName;
            this.searchModel.buildingName = this.spotBuildingSearchRequest.buildingName;
            this.searchModel.spotSetId = this.spotBuildingSearchRequest.spotSetId;
        }
    };
    SpotBuildingsSearchComponent.prototype.applySortBy = function (type, direction) {
        var _this = this;
        if (this.allListingIds && this.allListingIds.length > 0) {
            var sortDTO = {
                sortBy: type,
                direction: direction,
                spotBuildingType: this.searchModel.listingType,
                spotIds: tslib_1.__spread(this.allListingIds),
                currencyType: this.spotBuildingSearchRequest.currencyType
            };
            if (direction === 'ASC') {
                this.faSortIcon = this.faSortAmountUp;
            }
            else {
                this.faSortIcon = this.faSortAmountDown;
            }
            this.setCurrentSort(sortDTO);
            if (sortDTO.spotIds) {
                this.spotBuildingSearchService.sortCurrentSpotBuildingIds(sortDTO).then(function (result) {
                    var sortedIds = result.body;
                    _this.currentPage = 1;
                    _this.findAllDetailsAndMapPins(sortedIds);
                });
            }
        }
    };
    SpotBuildingsSearchComponent.prototype.clearSortSelection = function () {
        this.faSortIcon = faRandom;
        this.setCurrentSort(undefined);
        this.findAllDetailsAndMapPins(this.allListingIds);
    };
    SpotBuildingsSearchComponent.prototype.setCurrentSort = function (sortBy) {
        this.spotBuildingSearchRequest.sortSpotsBy = sortBy;
    };
    SpotBuildingsSearchComponent.prototype.getCurrentSort = function () {
        return this.spotBuildingSearchRequest ? this.spotBuildingSearchRequest.sortSpotsBy : '';
    };
    SpotBuildingsSearchComponent.prototype.isActiveSort = function (type, direction) {
        var currentSortFilter = this.getCurrentSort();
        if (currentSortFilter) {
            return currentSortFilter.sortBy == type && currentSortFilter.direction == direction;
        }
        return false;
    };
    SpotBuildingsSearchComponent.prototype.searchBuildingListings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.justLoaded = false;
                        this.updateURLBasedOnDTO();
                        this.printListingSearchAndSearchModel();
                        this.clearSelectedMarker();
                        return [4 /*yield*/, this.updateTags()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.findAllSpotBuildingIds().then(function (result) {
                                _this.allListingIds = result.body;
                                _this.findAllDetailsAndMapPins(_this.allListingIds);
                            })];
                    case 2:
                        _a.sent();
                        this.countSpotsByListingClass();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.findAllDetailsAndMapPins = function (spotIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.totalSpotBuildingsCount = spotIds ? spotIds.length : 0;
                if (this.totalSpotBuildingsCount > 0) {
                    this.getSpotBuildingsPaginated(spotIds);
                    this.getMapPinsFromCurrentSearch();
                }
                else {
                    this.clearMapAndResults();
                    this.noListingsFoundMessage = 'Not found';
                }
                return [2 /*return*/];
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.getSpotBuildingsPaginated = function (spotIds, concatNewResult) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spotBuildingSearchService
                            .getSpotBuildingDetails(this.currentPage, this.pageSize, spotIds, this.searchModel.currencyType)
                            .then(function (result) {
                            _this.allSpotBuildings = [];
                            if (concatNewResult) {
                                var newListings = result;
                                if (newListings && newListings.length) {
                                    _this.allSpotBuildings = _this.allSpotBuildings.concat(newListings);
                                }
                            }
                            else {
                                _this.allSpotBuildings = result;
                            }
                            _this.updateTags();
                            _this.paginationHeader = _this.getPaginationText();
                            _this.splitSpotBuildingsByListingType();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.pageChanged = function (event) {
        if (!event) {
            return;
        }
        this.currentPage = event.page;
        this.getSpotBuildingsPaginated(this.allListingIds);
        this.scrollToTop();
    };
    SpotBuildingsSearchComponent.prototype.scrollToTop = function () {
        this.contentBlock ? this.contentBlock.scrollToTop() : null;
    };
    SpotBuildingsSearchComponent.prototype.setCustomAttributes = function () {
        if (this.areThereSpotBuildings()) {
            var featuredCopy = tslib_1.__assign({}, this.allSpotBuildings[0]);
            featuredCopy.listingType = 'FEATURED';
            featuredCopy.featured = true;
            this.allSpotBuildings.unshift(featuredCopy);
        }
    };
    SpotBuildingsSearchComponent.prototype.splitSpotBuildingsByListingType = function () {
        this.featuredSpotBuildings = [];
        this.regularSpotBuildings = [];
        if (this.areThereSpotBuildings()) {
            this.featuredSpotBuildings = this.allSpotBuildings.filter(function (spot) { return spot.featured || spot.premium; });
            if (!this.getCurrentSort()) {
                this.featuredSpotBuildings = this.sortPremiumAndGold();
            }
            this.regularSpotBuildings = this.allSpotBuildings.filter(function (spot) { return !spot.featured && !spot.premium; });
        }
    };
    SpotBuildingsSearchComponent.prototype.sortPremiumAndGold = function () {
        var platinumSpots = tslib_1.__spread(this.featuredSpotBuildings.filter(function (spot) { return spot.featured; }));
        var goldSpots = tslib_1.__spread(this.featuredSpotBuildings.filter(function (spot) { return spot.premium; }));
        this.featuredSpotBuildings = [];
        platinumSpots = this.sortSpotsByModifiedDate(platinumSpots);
        goldSpots = this.sortSpotsByModifiedDate(goldSpots);
        this.featuredSpotBuildings = tslib_1.__spread(platinumSpots, goldSpots);
        return this.featuredSpotBuildings;
    };
    SpotBuildingsSearchComponent.prototype.sortSpotsByModifiedDate = function (spots) {
        return spots.sort(function (first, second) { return second.modifiedDate.localeCompare(first.modifiedDate); });
    };
    SpotBuildingsSearchComponent.prototype.isThereFeaturedSpots = function () {
        return this.featuredSpotBuildings && this.featuredSpotBuildings.length > 0;
    };
    SpotBuildingsSearchComponent.prototype.isThereRegularSpots = function () {
        return this.regularSpotBuildings && this.regularSpotBuildings.length > 0;
    };
    SpotBuildingsSearchComponent.prototype.isFeaturedSpot = function (spotBuilding) {
        return spotBuilding.listingType && spotBuilding.listingType == 'FEATURED';
    };
    SpotBuildingsSearchComponent.prototype.getMapPinsFromCurrentSearch = function () {
        var _this = this;
        var pageNumber = 1;
        this.hasGeoLocationError = false;
        this.spotBuildingSearchService
            .getSpotBulidingMapPins(this.spotBuildingSearchRequest, pageNumber)
            .then(function (result) {
            var totalListingsCount = parseInt(result.headers.get('x-total-count'));
            _this.noListingsFoundMessage = _this.getNoResultMessage(totalListingsCount);
            //TODO Move everything to this component?
            _this.spotMarkers = _this.spotBuildingSearchService.createNearbyListingMarker(result.body, _this.spotBuildingSearchRequest, _this.isLoggedIn);
            _this.searching = false;
        });
    };
    SpotBuildingsSearchComponent.prototype.getNoResultMessage = function (resultCount) {
        return resultCount > 0 ? '' : this.i18nService.get('global.search.noListingsFound');
    };
    SpotBuildingsSearchComponent.prototype.updateTags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.searchTagService.updateSearchTags(this.spotBuildingSearchRequest, this.isSpotNearMeSearch)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.findAllSpotBuildingIds = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.spotBuildingSearchService.saveSearchDtoOnStorage(SelectedFilterKey.SPOT_BUILDING_SEARCH, this.spotBuildingSearchRequest);
                        return [4 /*yield*/, this.spotBuildingSearchService
                                .searchSpotBuildingIds(this.spotBuildingSearchRequest, 1)
                                .then(function (spotBulidingIdsResponse) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    return [2 /*return*/, spotBulidingIdsResponse];
                                });
                            }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.isFooterVisible = function (event) {
        this.displayScrollToTop = event.visible;
    };
    SpotBuildingsSearchComponent.prototype.showNoResultMessage = function () {
        var result = [this.noListingsFoundMessage && this.noListingsFoundMessage.length].every(function (condition) { return condition; });
        return result;
    };
    SpotBuildingsSearchComponent.prototype.setButtonColor = function (width) {
        return this.screenWidth >= width ? 'light' : 'secondary';
    };
    SpotBuildingsSearchComponent.prototype.openMorePopover = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popOverCtrlHelper.openMorePopoverForBuildingListing(ev, this.checkBoxValues, this.searchModel, this.spotBuildingSearchRequest, function (data) {
                            _this.setSpotRequest(data);
                            _this.searchModel.listingType = _this.spotBuildingSearchRequest.listingLeaseOrSale[0];
                            _this.searchModel.propertyType = _this.spotBuildingSearchRequest.buildingTypes[0];
                            _this.searchModel.currencyType = _this.spotBuildingSearchRequest.currencyType;
                            _this.searchBuildingListings();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.setSelectedPropertyTypeToModel = function (newPropertyType, newPropertySubTypes) {
        var oldPropertyType = this.searchModel.propertyType;
        this.searchModel.propertyType = newPropertyType;
        var searchListings = this.haveSubTypesChanged(oldPropertyType, this.searchModel.propertyType);
        this.searchModel.propertySubTypes = newPropertySubTypes;
        this.populatePropertyTypeName();
        if (this.searchModel.propertyType === ListingOfferType.Coworking) {
            this.spotBuildingSearchRequest.listingLeaseOrSale = [ListingOfferType.Coworking];
            this.searchModel.listingType = ListingOfferType.Coworking;
        }
        if (oldPropertyType === ListingOfferType.Coworking &&
            (!this.searchModel.propertyType || this.searchModel.propertyType !== ListingOfferType.Coworking)) {
            this.searchModel.listingType = null;
            this.spotBuildingSearchRequest.listingLeaseOrSale = null;
        }
    };
    SpotBuildingsSearchComponent.prototype.populatePropertyTypeName = function () {
        var _this = this;
        if (this.searchModel.propertyType) {
            if (this.searchModel.propertyType === ListingOfferType.Coworking) {
                this.propertyTypeText = this.i18nService.get('global.list-your-property.coworking');
            }
            else {
                this.propertyTypes.forEach(function (element) {
                    if (_this.searchModel.propertyType == element.id) {
                        _this.propertyTypeText = _this.i18nService.get(element.name);
                    }
                });
                if (this.searchModel.propertySubTypes && this.searchModel.propertySubTypes.length > 0) {
                    this.propertyTypeText += ': ';
                    var subtypeNames = this.searchModel.propertySubTypes.map(function (t) { return t.name; });
                    this.propertyTypeText += subtypeNames.join(', ');
                }
            }
        }
        else {
            this.propertyTypeText = '';
        }
    };
    SpotBuildingsSearchComponent.prototype.haveSubTypesChanged = function (oldPropertyType, newPropertyType) {
        if (oldPropertyType == newPropertyType) {
            var newSubTypes = this.spotBuildingSearchRequest.propertySubTypes;
            var oldSubTypes = this.searchModel.propertySubTypes;
            if (!newSubTypes && !oldSubTypes) {
                return false;
            }
            return JSON.stringify(newSubTypes) !== JSON.stringify(oldSubTypes);
        }
        return false;
    };
    SpotBuildingsSearchComponent.prototype.printListingSearchAndSearchModel = function () {
        var listingsearchcopy = JSON.parse(JSON.stringify(this.spotBuildingSearchRequest));
        this.removeNullAttributes(listingsearchcopy);
        this.printLog("Listing Search:  " + listingsearchcopy);
        var searchModelCopy = JSON.parse(JSON.stringify(this.searchModel));
        this.removeNullAttributes(searchModelCopy);
        this.printLog("Search Model:  " + searchModelCopy);
    };
    SpotBuildingsSearchComponent.prototype.printLog = function (msg) {
        log.debug(msg);
    };
    SpotBuildingsSearchComponent.prototype.removeNullAttributes = function (obj) {
        return Object.keys(obj).forEach(function (k) { return obj[k] == null && delete obj[k]; });
    };
    SpotBuildingsSearchComponent.prototype.showResults = function () {
        return this.areThereSpotBuildings();
    };
    SpotBuildingsSearchComponent.prototype.areThereSpotBuildings = function () {
        return this.allSpotBuildings && this.allSpotBuildings.length > 0;
    };
    /* TAGS */
    SpotBuildingsSearchComponent.prototype.showTags = function () {
        var allTags = this.getAllCurrentTags();
        return allTags && allTags.length > 0;
    };
    SpotBuildingsSearchComponent.prototype.getAllCurrentTags = function () {
        return this.searchTagService.getSearchTags();
    };
    SpotBuildingsSearchComponent.prototype.checkTagNames = function (tag) {
        // console.log('Tag: ', tag);
        return this.searchTagService.checkTagNames(tag);
    };
    SpotBuildingsSearchComponent.prototype.removeTag = function (fieldName, index) {
        if (fieldName && fieldName == 'spotsNearMe') {
            this.isSpotNearMeSearch = false;
            this.setGeoPositionValuesToRequest(null);
        }
        if (fieldName && fieldName == 'buildingName') {
            this.setDefaultFilterValues();
            this.updateSearchDTOFromModal();
        }
        this.searchTagService.removeTagFromModels(fieldName, index, this.searchModel, this.spotBuildingSearchRequest, this.checkBoxValues);
        this.searchBuildingListings();
    };
    /* END TAGS*/
    /* MAP */
    SpotBuildingsSearchComponent.prototype.searchByGeoposition = function (clientMarker) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clientCoordinates;
            return tslib_1.__generator(this, function (_a) {
                if (clientMarker.position && clientMarker.map) {
                    clientMarker.clientCoordinates = true;
                    clientCoordinates = {
                        lat: -23.548322,
                        lng: -46.631155,
                        clientCoordinates: true
                    };
                    //this.refreshSearchData(clientCoordinates);
                    // console.log('Refresh Search Data', clientCoordinates);
                }
                return [2 /*return*/];
            });
        });
    };
    /* END MAP */
    SpotBuildingsSearchComponent.prototype.getPaginationText = function () {
        if (this.areThereSpotBuildings()) {
            var totalPages = Math.ceil(this.totalSpotBuildingsCount / this.pageSize);
            return (this._ts.instant('global.search.viewingPage') +
                (" " + this.currentPage + " " + this._ts.instant('global.of') + " " + totalPages + " (" + this.allListingIds.length + " ") +
                ("" + (this.allListingIds.length == 1
                    ? this._ts.instant('global.search.found')
                    : this._ts.instant('global.search.found2'))) +
                ')');
        }
        return '';
    };
    SpotBuildingsSearchComponent.prototype.setListingLeaseOrSale = function (event) {
        this.searchModel.listingType = event;
        this.spotBuildingSearchRequest.listingLeaseOrSale = [this.searchModel.listingType];
    };
    SpotBuildingsSearchComponent.prototype.setPropertyType = function (event, type) {
        if (type) {
            this.setListingLeaseOrSale(type);
        }
        else {
            if (this.searchModel.listingType == 'C') {
                this.setListingLeaseOrSale('L');
            }
        }
        var previousValue = this.searchModel.propertyType;
        if (previousValue != event) {
            if (this.searchModel.marketIds && this.searchModel.marketIds.length > 0) {
                var cdmxIds = [1, 2];
                var guadalajaraIds = [6, 24];
                var monterreyIds = [3, 4];
                var queretaroIds = [11, 15];
                this.includeSimilarMarket(cdmxIds);
                this.includeSimilarMarket(guadalajaraIds);
                this.includeSimilarMarket(monterreyIds);
                this.includeSimilarMarket(queretaroIds);
            }
        }
        this.searchModel.propertyType = event;
        this.spotBuildingSearchRequest.buildingTypes = [this.searchModel.propertyType];
    };
    SpotBuildingsSearchComponent.prototype.includeSimilarMarket = function (similarMarketIds) {
        if (this.searchModel.marketIds.includes(similarMarketIds[0]) &&
            !this.searchModel.marketIds.includes(similarMarketIds[1])) {
            this.searchModel.marketIds.push(similarMarketIds[1]);
        }
        if (this.searchModel.marketIds.includes(similarMarketIds[1]) &&
            !this.searchModel.marketIds.includes(similarMarketIds[0])) {
            this.searchModel.marketIds.push(similarMarketIds[0]);
        }
    };
    SpotBuildingsSearchComponent.prototype.searchByListingType = function (event) {
        this.clearSpotBuildingRequest();
        this.setListingLeaseOrSale(event);
        this.searchFilterAction();
    };
    SpotBuildingsSearchComponent.prototype.searchByPropertyType = function (event, type) {
        this.clearSpotBuildingRequest();
        this.setPropertyType(event, type);
        if (this.isSpotNearMeSearch) {
            this.spotBuildingSearchRequest.radius = this.nearbyListingSvc.getSpotRadius(this.searchModel.propertyType);
        }
        this.searchFilterAction();
    };
    SpotBuildingsSearchComponent.prototype.switchView = function () {
        this.clearSelectedMarker();
        if (this.currentView === this.views.list) {
            this.currentView = this.views.map;
            return;
        }
        this.currentView = this.views.list;
    };
    SpotBuildingsSearchComponent.prototype.clearSelectedMarker = function () {
        this.selectedMarkerBuilding = null;
    };
    SpotBuildingsSearchComponent.prototype.countSpotsByListingClass = function () {
        var _this = this;
        this.topPropertiesCount = 0;
        this.otherPropertiesCount = 0;
        this.totalPropertiesCount = 0;
        this.spotBuildingSearchService
            .countSpotsByListingClass(this.spotBuildingSearchRequest)
            .then(function (spotByTypes) {
            if (spotByTypes) {
                var basicCount = spotByTypes[ListingType.Basic] ? spotByTypes[ListingType.Basic] : 0;
                var featuredCount = spotByTypes[ListingType.Featured] ? spotByTypes[ListingType.Featured] : 0;
                var premiumCount = spotByTypes[ListingType.Premium] ? spotByTypes[ListingType.Premium] : 0;
                var promotedCount = spotByTypes[ListingType.Promoted] ? spotByTypes[ListingType.Promoted] : 0;
                _this.topPropertiesCount = featuredCount + premiumCount + promotedCount + basicCount;
                _this.otherPropertiesCount = promotedCount + basicCount;
            }
        });
    };
    SpotBuildingsSearchComponent.prototype.initSpotsNearmeSearch = function () {
        this.isSpotNearMeSearch = true;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.searchByGeoLocation, this.showGeoLocationError);
        }
        else {
            var errorCode = { code: { POSITION_UNAVAILABLE: 'POSITION_UNAVAILABLE' } };
            this.showGeoLocationError(errorCode);
        }
    };
    SpotBuildingsSearchComponent.prototype.setGeoPositionValuesToRequest = function (clientPosition) {
        if (!clientPosition) {
            this.spotBuildingSearchRequest.latitude = undefined;
            this.spotBuildingSearchRequest.longitude = undefined;
            this.spotBuildingSearchRequest.radius = undefined;
            this.isSpotNearMeSearch = false;
            return;
        }
        this.spotBuildingSearchRequest.latitude = clientPosition.latitude;
        this.spotBuildingSearchRequest.longitude = clientPosition.longitude;
        this.spotBuildingSearchRequest.radius = clientPosition.radius;
        this.isSpotNearMeSearch = true;
    };
    SpotBuildingsSearchComponent.prototype.saveSearchAction = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var actions, modalProps, infoModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopPropagation();
                        if (!this.isLoggedIn) {
                            this.validateUserIsAuthenticated();
                            return [2 /*return*/];
                        }
                        actions = {
                            leftButton: 'Cancel',
                            rightButton: 'OK'
                        };
                        modalProps = {
                            backdropDismiss: true,
                            showBackdrop: true,
                            cssClass: 'save-info-modal',
                            component: SaveChangeModalComponent,
                            componentProps: {
                                headerTitle: 'SaveSearch',
                                bodyMessage: 'Guarde su busqueda',
                                leftButtonLabel: 'Cancel',
                                rightButtonLabel: 'Ok',
                                action: actions,
                                modalType: SaveModalTypes.SAVE_SEARCH
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) {
                            if (result && result.data) {
                                var data = result.data;
                                if (data.action == 'OK' && data.customInput) {
                                    _this.updateCurrentSearch(data.customInput);
                                }
                            }
                        });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.loadSearchAction = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var savedSearches, modalProps, infoModal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopPropagation();
                        if (!this.isLoggedIn) {
                            this.validateUserIsAuthenticated();
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.spotSearchSrv.getAll()];
                    case 1:
                        savedSearches = _a.sent();
                        modalProps = {
                            backdropDismiss: true,
                            showBackdrop: true,
                            cssClass: 'save-info-modal',
                            component: SaveChangeModalComponent,
                            componentProps: {
                                headerTitle: 'LoadSearchTitle',
                                leftButtonLabel: 'Cancel',
                                rightButtonLabel: 'load',
                                modalType: SaveModalTypes.LOAD_SEARCH,
                                dropdownItems: savedSearches
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 2:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) {
                            if (result && result.data) {
                                var data = result.data;
                                if (data.action == 'OK' && data.selectedItem) {
                                    _this.loadSavedSearch(data.selectedItem.json);
                                }
                            }
                        });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    SpotBuildingsSearchComponent.prototype.loadSavedSearch = function (jsonObject) {
        var searchRequestDTO = JSON.parse(jsonObject);
        this.searchTagService.clearSearchTags();
        this.setSpotRequest(searchRequestDTO);
        this.updateSearchModelFromSearchDTO();
        this.searchFilterAction();
    };
    SpotBuildingsSearchComponent.prototype.updateCurrentSearch = function (searchName) {
        this.commonService.deleteNullAttributesFrom(this.spotBuildingSearchRequest);
        var dto = {
            name: searchName,
            json: JSON.stringify(this.spotBuildingSearchRequest)
        };
        this.spotSearchSrv.update(dto);
    };
    SpotBuildingsSearchComponent.prototype.validateUserIsAuthenticated = function (callback) {
        var _this = this;
        if (!this.isLoggedIn) {
            this.openLoginDialog()
                .afterClosed()
                .subscribe(function () {
                if (_this.isLoggedIn) {
                    if (callback) {
                        callback();
                    }
                }
            });
        }
    };
    SpotBuildingsSearchComponent.prototype.openLoginDialog = function () {
        var currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    };
    SpotBuildingsSearchComponent.prototype.showPreviewSpotOnMobile = function () {
        return this.selectedMarkerBuilding && this.currentView === this.views.map;
    };
    SpotBuildingsSearchComponent.prototype.searchByCurrencyType = function (currencyType) {
        this.searchModel.currencyType = currencyType;
        this.clearSpotBuildingRequest();
        this.searchFilterAction();
    };
    return SpotBuildingsSearchComponent;
}());
export { SpotBuildingsSearchComponent };
