import { Component, OnInit } from '@angular/core';
import { BreadcrumbOptions } from '@app/shared';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';

@Component({
  selector: 'my-message',
  templateUrl: './my-message.component.html',
  styleUrls: ['./my-message.component.scss']
})
export class MyMessageComponent implements OnInit {
  alternateBack: boolean;

  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true,
    stickyBackButton: true
  };

  constructor(private userActivityService: UserActivityService) {}

  ngOnInit() {
    this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_MESSAGES_PAGE);
  }

  enableAlternateBack(event: any) {
    this.alternateBack = event.visible ? false : true;
  }

  goBack() {
    window.history.back();
  }
}
