import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ListingService } from './listing.service';

@Injectable()
export class ListPropertyService {
  private host: string = 'api/listings/purchase-options';
  private minMax: string = 'api/listings/filters-min-max';
  private skipPayment: string = 'api/listings/four-all-payment/skip-payment';
  private fourAllPayment: string = 'api/listings/four-all-payment';
  private userPaymentInfo: string = 'api/account/user-payment-info';
  public data: any = [];
  private cartItems: any = [];
  private paymentSpotAdminSelection: any;

  constructor(private http: HttpClient, private listingService: ListingService) {}

  getPaymentSpotAdminSelection() {
    return this.paymentSpotAdminSelection;
  }

  setPaymentSpotAdminSelection(val: any) {
    this.paymentSpotAdminSelection = val;
  }

  getListOptions() {
    return this.http.get(`${this.host}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getMinMaxOptions(dto: any) {
    return this.http
      .post(`${this.minMax}`, this.listingService.handlingCoworkingPropertyType(dto))
      .pipe(map(val => val));
  }

  fourAllPaymentTest(dto: any) {
    return this.http.post(`${this.fourAllPayment}`, dto).pipe(map(val => val));
  }

  skipPaymentAPI(dto: any) {
    return this.http.post(`${this.skipPayment}`, dto).pipe(map(val => val));
  }

  getPropertySubtypes(buildingTypeId: number) {
    return this.http
      .get(`api/lookUpTables/building-types/${buildingTypeId}/property-subtypes`, { observe: 'response' })
      .pipe(map(val => val.body));
  }

  getUserPaymentInfo() {
    return this.http.get(`${this.userPaymentInfo}`).pipe(map(val => val));
  }

  // use session storage
  setListingSelected(selection: any) {
    localStorage.setItem('selection', JSON.stringify(selection));
  }

  // set cart total price
  setCartTotalPrice(price: any) {
    localStorage.setItem('cartTotalPrice', price);
  }

  getCartTotalPrice() {
    return localStorage.getItem('cartTotalPrice');
  }

  // set cart total price
  setCartItems(cart: any) {
    this.cartItems = cart;
  }

  getCartItems() {
    return this.cartItems;
  }

  unsetListingSelected() {
    localStorage.removeItem('selection');
  }

  getListingSelected() {
    return JSON.parse(localStorage.getItem('selection'));
  }

  mapMinMaxOptions(filters: any, minMax: any) {
    let tags = {
      ceilingHeight: {
        upper: filters && filters.ceilingHeightMax ? filters.ceilingHeightMax : minMax.ceilingHeight.max,
        lower: filters && filters.ceilingHeightMin ? filters.ceilingHeightMin : 0
      },
      baySize: {
        upper: filters && filters.baySizeMax ? filters.baySizeMax : minMax.baySize.max,
        lower: filters && filters.baySizeMin ? filters.baySizeMin : 0
      },
      dockRatio: {
        upper: filters && filters.dockRatioMax ? filters.dockRatioMax : minMax.dockRatio.max,
        lower: filters && filters.dockRatioMin ? filters.dockRatioMin : 0
      },
      loadCapacity: {
        upper: filters && filters.loadCapacityMax ? filters.loadCapacityMax : minMax.loadCapacity.max,
        lower: filters && filters.loadCapacityMin ? filters.loadCapacityMin : 0
      },
      carParkingSpaces: {
        upper: filters && filters.carParkingSpacesMax ? filters.carParkingSpacesMax : minMax.carParkingSpaces.max,
        lower: filters && filters.carParkingSpacesMin ? filters.carParkingSpacesMin : 0
      },
      /*lockerRooms: {
        upper: filters && filters.lockerRoomsMax ? filters.lockerRoomsMax : minMax.lockerRooms.max,
        lower: filters && filters.lockerRoomsMin ? filters.lockerRoomsMin : 0
      },*/
      truckParkingSpaces: {
        upper: filters && filters.truckParkingSpacesMax ? filters.truckParkingSpacesMax : minMax.truckParkingSpaces.max,
        lower: filters && filters.truckParkingSpacesMin ? filters.truckParkingSpacesMin : 0
      },
      parkingSpaces: {
        upper: filters && filters.parkingSpacesMax ? filters.parkingSpacesMax : minMax.parkingSpaces.max,
        lower: filters && filters.parkingSpacesMin ? filters.parkingSpacesMin : 0
      },
      offeringPrice: {
        upper: filters && filters.offeringPriceEnd ? filters.offeringPriceEnd : minMax.offeringPrice.max,
        lower: filters && filters.offeringPriceIni ? filters.offeringPriceIni : 0
      },
      offeringPricePerArea: {
        upper:
          filters && filters.offeringPricePerAreaEnd
            ? filters.offeringPricePerAreaEnd
            : minMax.offeringPricePerArea.max,
        lower: filters && filters.offeringPricePerAreaIni ? filters.offeringPricePerAreaIni : 0
      },
      rentableArea: {
        upper: filters && filters.rentableAreaEnd ? Math.ceil(filters.rentableAreaEnd) : minMax.rentableArea.max,
        lower: filters && filters.rentableAreaIni ? Math.floor(filters.rentableAreaIni) : 0
      },
      monthlyRent: {
        upper: filters && filters.monthlyRentMax ? filters.monthlyRentMax : minMax.monthlyRent.max,
        lower: filters && filters.monthlyRentMin ? filters.monthlyRentMin : 0
      },
      monthlyRentPerArea: {
        upper: filters && filters.monthlyRentPerAreaMax ? filters.monthlyRentPerAreaMax : minMax.monthlyRentPerArea.max,
        lower: filters && filters.monthlyRentPerAreaMin ? filters.monthlyRentPerAreaMin : 0
      }
    };
    return tags;
  }

  setStaticMinMaxValues(filters: any) {
    let obj = {
      offeringPrice: { min: filters.offeringPriceIni, max: filters.offeringPriceEnd },
      offeringPricePerArea: { min: filters.offeringPricePerAreaIni, max: filters.offeringPricePerAreaEnd },
      monthlyRent: { min: filters.monthlyRentMin, max: filters.monthlyRentMax },
      monthlyRentPerArea: { min: filters.monthlyRentPerAreaMin, max: filters.monthlyRentPerAreaMax },
      rentableArea: { min: Math.floor(filters.rentableAreaIni), max: Math.ceil(filters.rentableAreaEnd) },
      ceilingHeight: { min: filters.ceilingHeightMin, max: filters.ceilingHeightMax },
      baySize: { min: filters.baySizeMin, max: filters.baySizeMax },
      dockRatio: { min: filters.dockRatioMin, max: filters.dockRatioMax },
      loadCapacity: { min: filters.loadCapacityMin, max: filters.loadCapacityMax },
      carParkingSpaces: { min: filters.carParkingSpacesMin, max: filters.carParkingSpacesMax },
      //lockerRooms: { min: filters.lockerRoomsMin, max: filters.lockerRoomsMax },
      truckParkingSpaces: { min: filters.truckParkingSpacesMin, max: filters.truckParkingSpacesMax },
      parkingSpaces: { min: filters.parkingSpacesMin, max: filters.parkingSpacesMax }
    };
    return obj;
  }

  isRangeFilters(dto: any) {
    if (dto.ceilingHeight || dto.ceilingHeight) {
      return true;
    }
    if (dto.parkingSpacesMin || dto.parkingSpacesMax) {
      return true;
    }
    if (dto.truckParkingSpacesMin || dto.truckParkingSpacesMax) {
      return true;
    }
    if (dto.carParkingSpacesMin || dto.carParkingSpacesMax) {
      return true;
    }
    /*if (dto.lockerRoomsMin || dto.lockerRoomsMax) {
      return true;
    }*/
    if (dto.loadCapacityMin || dto.loadCapacityMax) {
      return true;
    }
    if (dto.dockRatioMin || dto.dockRatioMax) {
      return true;
    }
    if (dto.baySizeMin || dto.baySizeMax) {
      return true;
    }
    if (dto.offeringPriceIni || dto.offeringPriceEnd) {
      return true;
    }
    if (dto.offeringPricePerAreaIni || dto.offeringPricePerAreaEnd) {
      return true;
    }
    if (dto.rentableAreaIni || dto.rentableAreaEnd) {
      return true;
    }
    if (dto.monthlyRentMin || dto.monthlyRentMax) {
      return true;
    }
    if (dto.monthlyRentPerAreaMin || dto.monthlyRentPerAreaMax) {
      return true;
    }

    return false;
  }

  setSearchRangeFilters(searchRangeFilters: any, minmax: any) {
    let filters = {
      ceilingHeightMin:
        searchRangeFilters.ceilingHeight.lower !== minmax.ceilingHeight.min ||
        searchRangeFilters.ceilingHeight.upper !== minmax.ceilingHeight.max
          ? searchRangeFilters.ceilingHeight.lower
          : null,
      ceilingHeightMax:
        searchRangeFilters.ceilingHeight.lower !== minmax.ceilingHeight.min ||
        searchRangeFilters.ceilingHeight.upper !== minmax.ceilingHeight.max
          ? searchRangeFilters.ceilingHeight.upper
          : null,
      parkingSpacesMin:
        searchRangeFilters.parkingSpaces.lower !== minmax.parkingSpaces.min ||
        searchRangeFilters.parkingSpaces.upper !== minmax.parkingSpaces.max
          ? searchRangeFilters.parkingSpaces.lower
          : null,
      parkingSpacesMax:
        searchRangeFilters.parkingSpaces.lower !== minmax.parkingSpaces.min ||
        searchRangeFilters.parkingSpaces.upper !== minmax.parkingSpaces.max
          ? searchRangeFilters.parkingSpaces.upper
          : null,
      truckParkingSpacesMin:
        searchRangeFilters.truckParkingSpaces.lower !== minmax.truckParkingSpaces.min ||
        searchRangeFilters.truckParkingSpaces.upper !== minmax.truckParkingSpaces.max
          ? searchRangeFilters.truckParkingSpaces.lower
          : null,
      truckParkingSpacesMax:
        searchRangeFilters.truckParkingSpaces.lower !== minmax.truckParkingSpaces.min ||
        searchRangeFilters.truckParkingSpaces.upper !== minmax.truckParkingSpaces.max
          ? searchRangeFilters.truckParkingSpaces.upper
          : null,
      carParkingSpacesMin:
        searchRangeFilters.carParkingSpaces.lower !== minmax.carParkingSpaces.min ||
        searchRangeFilters.carParkingSpaces.upper !== minmax.carParkingSpaces.max
          ? searchRangeFilters.carParkingSpaces.lower
          : null,
      carParkingSpacesMax:
        searchRangeFilters.carParkingSpaces.lower !== minmax.carParkingSpaces.min ||
        searchRangeFilters.carParkingSpaces.upper !== minmax.carParkingSpaces.max
          ? searchRangeFilters.carParkingSpaces.upper
          : null,
      /*lockerRoomsMin:
        searchRangeFilters.lockerRooms.lower !== minmax.lockerRooms.min ||
        searchRangeFilters.lockerRooms.upper !== minmax.lockerRooms.max
          ? searchRangeFilters.lockerRooms.lower
          : null,
      lockerRoomsMax:
        searchRangeFilters.lockerRooms.lower !== minmax.lockerRooms.min ||
        searchRangeFilters.lockerRooms.upper !== minmax.lockerRooms.max
          ? searchRangeFilters.lockerRooms.upper
          : null,*/
      loadCapacityMin:
        searchRangeFilters.loadCapacity.lower !==
        minmax.loadCapacity.min /*||
        searchRangeFilters.loadCapacity.upper !== minmax.loadCapacity.*/
          ? searchRangeFilters.loadCapacity.lower
          : null,
      loadCapacityMax:
        searchRangeFilters.loadCapacity.lower !== minmax.loadCapacity.min ||
        searchRangeFilters.loadCapacity.upper !== minmax.loadCapacity.max
          ? searchRangeFilters.loadCapacity.upper
          : null,
      dockRatioMin:
        searchRangeFilters.dockRatio.lower !== minmax.dockRatio.min ||
        searchRangeFilters.dockRatio.upper !== minmax.dockRatio.max
          ? searchRangeFilters.dockRatio.lower
          : null,
      dockRatioMax:
        searchRangeFilters.dockRatio.lower !== minmax.dockRatio.min ||
        searchRangeFilters.dockRatio.upper !== minmax.dockRatio.max
          ? searchRangeFilters.dockRatio.upper
          : null,
      baySizeMin:
        searchRangeFilters.baySize.lower !== minmax.baySize.min ||
        searchRangeFilters.baySize.upper !== minmax.baySize.max
          ? searchRangeFilters.baySize.lower
          : null,
      baySizeMax:
        searchRangeFilters.baySize.lower !== minmax.baySize.min ||
        searchRangeFilters.baySize.upper !== minmax.baySize.max
          ? searchRangeFilters.baySize.upper
          : null,
      offeringPriceIni:
        searchRangeFilters.offeringPrice.lower !== minmax.offeringPrice.min ||
        searchRangeFilters.offeringPrice.upper !== minmax.offeringPrice.max
          ? searchRangeFilters.offeringPrice.lower
          : null,
      offeringPriceEnd:
        searchRangeFilters.offeringPrice.lower !== minmax.offeringPrice.min ||
        searchRangeFilters.offeringPrice.upper !== minmax.offeringPrice.max
          ? searchRangeFilters.offeringPrice.upper
          : null,
      offeringPricePerAreaIni:
        searchRangeFilters.offeringPricePerArea.lower !== minmax.offeringPricePerArea.min ||
        searchRangeFilters.offeringPricePerArea.upper !== minmax.offeringPricePerArea.max
          ? searchRangeFilters.offeringPricePerArea.lower
          : null,
      offeringPricePerAreaEnd:
        searchRangeFilters.offeringPricePerArea.lower !== minmax.offeringPricePerArea.min ||
        searchRangeFilters.offeringPricePerArea.upper !== minmax.offeringPricePerArea.max
          ? searchRangeFilters.offeringPricePerArea.upper
          : null,
      monthlyRentMin:
        searchRangeFilters.monthlyRent.lower !== minmax.monthlyRent.min ||
        searchRangeFilters.monthlyRent.upper !== minmax.monthlyRent.max
          ? searchRangeFilters.monthlyRent.lower
          : null,
      monthlyRentMax:
        searchRangeFilters.monthlyRent.lower !== minmax.monthlyRent.min ||
        searchRangeFilters.monthlyRent.upper !== minmax.monthlyRent.max
          ? searchRangeFilters.monthlyRent.upper
          : null,
      monthlyRentPerAreaMin:
        searchRangeFilters.monthlyRentPerArea.lower !== minmax.monthlyRentPerArea.min ||
        searchRangeFilters.monthlyRentPerArea.upper !== minmax.monthlyRentPerArea.max
          ? searchRangeFilters.monthlyRentPerArea.lower
          : null,
      monthlyRentPerAreaMax:
        searchRangeFilters.monthlyRentPerArea.lower !== minmax.monthlyRentPerArea.min ||
        searchRangeFilters.monthlyRentPerArea.upper !== minmax.monthlyRentPerArea.max
          ? searchRangeFilters.monthlyRentPerArea.upper
          : null,
      rentableAreaIni:
        Math.floor(searchRangeFilters.rentableArea.lower) !== Math.floor(minmax.rentableArea.min) ||
        Math.ceil(searchRangeFilters.rentableArea.upper) !== Math.ceil(minmax.rentableArea.max)
          ? searchRangeFilters.rentableArea.lower
          : null,
      rentableAreaEnd:
        Math.floor(searchRangeFilters.rentableArea.lower) !== Math.floor(minmax.rentableArea.min) ||
        Math.ceil(searchRangeFilters.rentableArea.upper) !== Math.ceil(minmax.rentableArea.max)
          ? searchRangeFilters.rentableArea.upper
          : null
    };

    //this.deleteFilterIfMaxValueIsNull(filters);

    return filters;
  }

  /**
   * If the maxixum value of a field is null, that means it is not necessary to apply a filter for that field.
   * @param filters
   */
  deleteFilterIfMaxValueIsNull(filters: any) {
    // Send min value as null as long as the max value is null.

    filters.baySizeMin = filters.baySizeMax ? filters.baySizeMin : null;
    filters.ceilingHeightMin = filters.ceilingHeightMax ? filters.ceilingHeightMin : null;
    filters.dockRatioMin = filters.dockRatioMax ? filters.dockRatioMin : null;
    filters.loadCapacityMin = filters.loadCapacityMax ? filters.loadCapacityMin : null;
    filters.monthlyRentPerAreaMin = filters.monthlyRentPerAreaMax ? filters.monthlyRentPerAreaMin : null;
    filters.monthlyRentMin = filters.monthlyRentMax ? filters.monthlyRentMin : null;
    filters.carParkingSpacesMin = filters.carParkingSpacesMax ? filters.carParkingSpacesMin : null;
    //filters.lockerRoomsMin = filters.lockerRoomsMax ? filters.lockerRoomsMin : null;
    filters.truckParkingSpacesMin = filters.truckParkingSpacesMax ? filters.truckParkingSpacesMin : null;
    filters.parkingSpacesMin = filters.parkingSpacesMax ? filters.parkingSpacesMin : null;
    filters.rentableAreaIni = filters.rentableAreaEnd ? filters.rentableAreaIni : null;
    filters.offeringPriceIni = filters.offeringPriceEnd ? filters.offeringPriceIni : null;
    filters.offeringPricePerAreaIni = filters.offeringPricePerAreaEnd ? filters.offeringPricePerAreaIni : null;
  }

  getTagTranslations(filters: any) {
    let arr = [];
    let obj = {
      armoredCabin: filters.armoredCabin ? arr.push({ name: 'global.addtSearchFilters.armoredCabin' }) : null,
      manufacturing: filters.manufacturing ? arr.push({ name: 'global.addtSearchFilters.manufacturing' }) : null,
      //logistics: filters.logistics ? arr.push({ name: 'global.addtSearchFilters.logistics' }) : null,
      officeSpaceAvailable: filters.officeSpaceAvailable
        ? arr.push({ name: 'global.addtSearchFilters.officeSpaceAvailable' })
        : null,
      /*fireProtectionSystem: filters.fireProtectionSystem
        ? arr.push({ name: 'global.addtSearchFilters.fireProtectionSystem' })
        : null,*/
      blockConstruction: filters.blockConstruction
        ? arr.push({ name: 'global.addtSearchFilters.blockConstruction' })
        : null,
      steelConstruction: filters.steelConstruction
        ? arr.push({ name: 'global.addtSearchFilters.steelConstruction' })
        : null,
      hasLoadCapacity: filters.loadCapacity
        ? arr.push({ name: 'global.addtSearchFilters.hasLoadCapacity', value: filters.loadCapacity })
        : null,
      hasDockRatio: filters.dockRatio
        ? arr.push({
            name: 'global.addtSearchFilters.hasDockRatio',
            value: filters.dockRatio,
            decimal: true
          })
        : null,
      blockAndSteelConstruction: filters.blockAndSteelConstruction
        ? arr.push({ name: 'global.addtSearchFilters.blockAndSteelConstruction' })
        : null,
      skyLights: filters.skyLights ? arr.push({ name: 'global.addtSearchFilters.skyLights' }) : null,
      multiTenant: filters.multiTenant ? arr.push({ name: 'global.addtSearchFilters.multiTenant' }) : null,
      singleTenant: filters.singleTenant ? arr.push({ name: 'global.addtSearchFilters.singleTenant' }) : null,
      hasCarParkingSpaces: filters.carParkingSpaces
        ? arr.push({ name: 'global.addtSearchFilters.carParkingSpaces', value: filters.carParkingSpaces })
        : null,
      hasTruckParkingSpaces: filters.truckParkingSpaces
        ? arr.push({ name: 'global.addtSearchFilters.truckParkingSpaces', value: filters.truckParkingSpaces })
        : null,
      //industrialPark: filters.industrialPark ? arr.push({ name: 'global.addtSearchFilters.industrialPark' }) : null,
      //industrialZone: filters.industrialZone ? arr.push({ name: 'global.addtSearchFilters.industrialZone' }) : null,
      truckYard: filters.truckYard ? arr.push({ name: 'global.addtSearchFilters.truckYard' }) : null,
      crossDocking: filters.crossDocking ? arr.push({ name: 'global.addtSearchFilters.crossDocking' }) : null,
      refrigerated: filters.refrigerated ? arr.push({ name: 'global.addtSearchFilters.refrigerated' }) : null,
      sluiceGate: filters.sluiceGate ? arr.push({ name: 'global.addtSearchFilters.sluiceGate' }) : null,
      hasLockerRooms: filters.hasLockerRooms ? arr.push({ name: 'global.addtSearchFilters.lockerRoom' }) : null,
      twentyFourHourSecurity: filters.twentyFourHourSecurity
        ? arr.push({ name: 'global.addtSearchFilters.twentyFourHourSecurity' })
        : null,
      hasCeilingHeight: filters.ceilingHeight
        ? arr.push({
            name: 'global.addtSearchFilters.hasCeilingHeight',
            value: filters.ceilingHeight,
            decimal: true
          })
        : null,
      heliport: filters.heliport ? arr.push({ name: 'global.addtSearchFilters.heliport' }) : null,
      sprinklers: filters.sprinklers ? arr.push({ name: 'global.addtSearchFilters.sprinklers' }) : null,
      electricGenerator: filters.electricGenerator
        ? arr.push({ name: 'global.addtSearchFilters.electricGenerator' })
        : null,
      hasParkingSpaces: filters.parkingSpaces
        ? arr.push({ name: 'global.addtSearchFilters.hasParkingSpaces', value: filters.parkingSpaces })
        : null,
      plugAndPlay: filters.plugAndPlay ? arr.push({ name: 'global.addtSearchFilters.plugAndPlay' }) : null,
      //finishedOut: filters.finishedOut ? arr.push({ name: 'global.addtSearchFilters.finishedOut' }) : null,
      furnished: filters.furnished ? arr.push({ name: 'global.addtSearchFilters.furnished' }) : null,
      coreAndShel: filters.coreAndShel ? arr.push({ name: 'global.addtSearchFilters.coreAndShel' }) : null,
      centralAirConditioning: filters.centralAirConditioning
        ? arr.push({ name: 'global.addtSearchFilters.centralAirConditioning' })
        : null,
      miniSplitAirConditioning: filters.miniSplitAirConditioning
        ? arr.push({ name: 'global.addtSearchFilters.miniSplitAirConditioning' })
        : null,
      bicycleRack: filters.bicycleRack ? arr.push({ name: 'global.addtSearchFilters.bicycleRack' }) : null,
      leedStatus: filters.leedStatus ? arr.push({ name: 'global.addtSearchFilters.leedStatus' }) : null,
      raisedFloor: filters.raisedFloor ? arr.push({ name: 'global.addtSearchFilters.raisedFloor' }) : null,
      hourSecurity: filters.hourSecurity ? arr.push({ name: 'global.addtSearchFilters.hourSecurity' }) : null,
      neverUsed: filters.neverUsed ? arr.push({ name: 'global.addtSearchFilters.neverUsed' }) : null,
      hasRestrooms: filters.hasRestrooms ? arr.push({ name: 'global.addtSearchFilters.restroom' }) : null,
      hasBaySize: filters.baySize
        ? arr.push({ name: 'global.addtSearchFilters.hasBaySize', value: filters.baySize, decimal: true })
        : null
    };
    return arr;
  }

  clearFilters(dto: any, checkboxValues: any) {
    if (checkboxValues) {
      checkboxValues.offeringPrice = false;
      checkboxValues.offeringPricePerArea = false;
      checkboxValues.monthlyRent = false;
      checkboxValues.monthlyRentPerArea = false;
      checkboxValues.rentableArea = false;
      checkboxValues.ceilingHeight = false;
      checkboxValues.hasParkingSpaces = false;
      checkboxValues.hasDockRatio = false;
      checkboxValues.hasLoadCapacity = false;
      checkboxValues.trckParkingSpaces = false;
      checkboxValues.parkingSpots = false;
      checkboxValues.baySize = false;
    }
    if (dto.refrigerated) dto.refrigerated = false;
    if (dto.sluiceGate) dto.sluiceGate = false;
    if (dto.hasLockerRooms) dto.hasLockerRooms = false;
    //if (dto.hasCeilingHeight) dto.hasCeilingHeight = false;
    if (dto.heliport) dto.heliport = false;
    if (dto.sprinklers) dto.sprinklers = false;
    if (dto.electricGenerator) dto.electricGenerator = false;
    //if (dto.hasParkingSpaces) dto.hasParkingSpaces = false;
    if (dto.plugAndPlay) dto.plugAndPlay = false;
    //if (dto.finishedOut) dto.finishedOut = false;
    if (dto.furnished) dto.furnished = false;
    if (dto.coreAndShel) dto.coreAndShel = false;
    if (dto.centralAirConditioning) dto.centralAirConditioning = false;
    if (dto.miniSplitAirConditioning) dto.miniSplitAirConditioning = false;
    if (dto.bicycleRack) dto.bicycleRack = false;
    if (dto.leedStatus) dto.leedStatus = false;
    if (dto.raisedFloor) dto.raisedFloor = false;
    if (dto.hourSecurity) dto.hourSecurity = false;
    if (dto.neverUsed) dto.neverUsed = false;
    if (dto.hasRestrooms) dto.hasRestrooms = false;
    if (dto.armoredCabin) dto.armoredCabin = false;
    if (dto.manufacturing) dto.manufacturing = false;
    //if (dto.logistics) dto.logistics = false;
    if (dto.officeSpaceAvailable) dto.officeSpaceAvailable = false;
    //if (dto.fireProtectionSystem) dto.fireProtectionSystem = false;
    if (dto.blockConstruction) dto.blockConstruction = false;
    if (dto.steelConstruction) dto.steelConstruction = false;
    if (dto.blockAndSteelConstruction) dto.blockAndSteelConstruction = false;
    if (dto.skyLights) dto.skyLights = false;
    //if (dto.hasLoadCapacity) dto.hasLoadCapacity = false;
    //if (dto.hasDockRatio) dto.hasDockRatio = false;
    //if (dto.hasCarParkingSpaces) dto.hasCarParkingSpaces = false;
    if (dto.singleTenant) dto.singleTenant = false;
    if (dto.multiTenant) dto.multiTenant = false;
    //if (dto.hasTruckParkingSpaces) dto.hasTruckParkingSpaces = false;
    //if (dto.industrialPark) dto.industrialPark = false;
    //if (dto.industrialZone) dto.industrialZone = false;
    if (dto.truckYard) dto.truckYard = false;
    if (dto.crossDocking) dto.crossDocking = false;
    if (dto.twentyFourHourSecurity) dto.twentyFourHourSecurity = false;
    if (dto.coreAndShel) dto.coreAndShel = false;
    //if (dto.hasBaySize) dto.hasBaySize = false;
    return dto;
  }
}
