import { Component, Input, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { RegisterService } from '@app/core/register.service';
import { BrokersDTO } from '@app/models/bokers.model';
import { CompanyDTO } from '@app/models/company.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { IonicSelectableComponent } from 'ionic-selectable';

@Component({
  selector: 'spot-more-buildings',
  templateUrl: './spot-more-buildings.component.html',
  styleUrls: ['./spot-more-buildings.component.scss']
})
export class SpotMoreBuildingsComponent implements OnInit {
  @ViewChild('portComponent', { static: false }) portComponent?: IonicSelectableComponent;
  @Input('spotBuildings') spotBuildings: CreateSpotBuildingsRequest;
  @Input('buildingDetail') buildingDetail: SpotBuildingDTO;
  @Input('propertyType') propertyType: number[];
  existingComments: any;
  descriptionFieldOptions: any;
  addressFieldOptions: any;
  allCompanies: any;
  selectCompany: any = null;
  ownerCompany: any = null;
  company = new CompanyDTO();
  brokers: BrokersDTO[] = [];
  users: any;
  offeredByUser: any = null;
  address: any;
  addressArray: any;

  existingInvestment: any;
  InvestmentFieldOptions: any;

  constructor(private registerService: RegisterService) {}

  async ngOnInit() {
    this.multiLangInit();
    this.allCompanies = await this.getCompanies();
    this.addressArray = this.spotBuildings.address;
  }

  ngOnChanges(changes: any) {
    if (this.buildingDetail) {
      this.existingComments = this.buildingDetail.comments;
      this.spotBuildings.comments = this.buildingDetail.comments;

      this.existingInvestment = this.buildingDetail.investmentOpportunity;
      this.spotBuildings.investmentOpportunity = this.buildingDetail.investmentOpportunity;

      this.ownerCompany = this.buildingDetail.offeredByCompany;
      this.spotBuildings.offeredByCompany = this.buildingDetail.offeredByCompany;

      if (this.buildingDetail.brokers && this.buildingDetail.brokers.length > 0) {
        this.selectCompany = this.buildingDetail.brokers[0].company;
      } else {
        this.selectCompany = this.buildingDetail.offeredByCompany;
      }

      this.getUsersByCompany(this.selectCompany.id);
      this.spotBuildings.brokers = this.buildingDetail.brokers;
      this.offeredByUser = this.buildingDetail.brokers;
      this.spotBuildings.address = this.buildingDetail.address;
      this.spotBuildings.neighborhood = this.buildingDetail.neighborhood;
    }
  }

  multiLangInit() {
    this.descriptionFieldOptions = {
      columnLabel: 'global.registerSpot.description',
      element: { richtext: true },
      formArrayName: 'description',
      required: true
    };

    this.addressFieldOptions = {
      columnLabel: 'global.register.address',
      element: { input: true },
      formArrayName: 'address',
      required: true
    };

    this.InvestmentFieldOptions = {
      columnLabel: 'global.list-your-property.investmentOpportunity',
      element: { richtext: true },
      formArrayName: 'description2',
      required: true
    };
  }

  addressChange(value: any) {
    if (value.address.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.address).forEach(async (key, idx) => {
        let obj: any = value.address[key];
        if (obj['en'] === '' || obj['es'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues ? (this.spotBuildings.address = '') : (this.spotBuildings.address = JSON.stringify(mergedObject));
    } else {
      this.spotBuildings.address = '';
    }
  }

  async getCompanies() {
    return this.registerService.getListingCompanies().toPromise();
  }

  async getUsersByCompany(companyId: number) {
    this.offeredByUser = '';
    this.users = await this.registerService.getUsersByCompany(companyId).toPromise();
    this.users.map((user: any) => {
      user.userId = user.id;
      return user;
    });
  }

  selectOwnerCompany($event: any) {
    this.company.id = $event.value.id;
    this.spotBuildings.offeredByCompany = this.company;
  }

  portChange($event: any) {
    //this.company.id = $event.value.id;
    //this.spotBuildings.offeredByCompany = this.company;

    this.getUsersByCompany($event.value.id);
    this.clearBokers();
  }

  clearBokers() {
    this.spotBuildings.brokers = null;
    this.brokers = [];
  }

  nameChange($event: any) {
    this.clearBokers();
    $event.value.forEach((element: any) => {
      let broker = new BrokersDTO();
      broker.userId = element.userId;
      this.brokers.push(broker);
    });
    this.spotBuildings.brokers = this.brokers.length == 0 ? null : this.brokers;
  }

  descriptionChange(value: any, update?: boolean) {
    if (value.description.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.description).forEach(async (key, idx) => {
        let obj: any = value.description[key];
        if (obj['en'] === '' || obj['en'] === null || obj['es'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues ? (this.spotBuildings.comments = '') : (this.spotBuildings.comments = JSON.stringify(mergedObject));
    } else {
      this.spotBuildings.comments = '';
    }
  }

  investmentChange(value: any, update?: boolean) {
    if (value.description.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.description).forEach(async (key, idx) => {
        let obj: any = value.description[key];
        if (obj['en'] === '' || obj['en'] === null || obj['es'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues
        ? (this.spotBuildings.investmentOpportunity = '')
        : (this.spotBuildings.investmentOpportunity = JSON.stringify(mergedObject));
    } else {
      this.spotBuildings.investmentOpportunity = '';
    }
  }
}
