<div>
  <div>
    <button
      class="carrousel__control prev-slide btn-carousel"
      ion-button
      [hidden]="!spotBuildings || !spotBuildings.length"
      [disabled]="loading || !spotBuildings.length || disabledPrev"
      (click)="onSlideChange('prev')"
    >
      <fa-icon class="arrow-icon " [icon]="leftArrow" size="lg"></fa-icon>
    </button>
  </div>

  <div style="float: right;">
    <button
      class="carrousel__control next-slide btn-carousel"
      ion-button
      [hidden]="!spotBuildings || !spotBuildings.length"
      [disabled]="loading || !spotBuildings.length || disabledNext"
      (click)="onSlideChange('next')"
    >
      <fa-icon class="arrow-icon " [icon]="rightArrow" size="lg"></fa-icon>
    </button>
  </div>

  <div class="flex-grow-1 carrousel__items">
    <lazy-slider
      *ngIf="!isPromoted && !firstFetch"
      #appCarousel
      [noDataMsg]="'No Data Found'"
      [showPager]="true"
      [showScrollbar]="true"
      [slideOptionOverride]="slideOptionOverride"
      [totalCount]="allSpotIds.length"
      [loadedItems]="spotBuildings"
      [loading]="loading"
      [queryPage]="queryPage"
      [querySize]="querySize"
      [changeSlide]="changeSlide$"
      (getItems)="fetchMore($event)"
      (onSliderUpdate)="onSliderUpdate($event)"
      [isPromoted]="!isPromoted"
    >
      <ion-slide class="spot-card-slide" *ngFor="let spotBuilding of spotBuildings">
        <div [class]="'item '+ cardContainerClass || ''">
          <app-spot-card
            (favoriteChangeEvent)="favoriteChange($event)"
            [listing]="spotBuilding"
            [customContainerClass]="'w-50'"
            [lazyLoadImages]="true"
            [hideFavIcon]="hideFavIcon"
            *ngIf="spotBuilding !== null"
            [isPromoted]="isPromoted"
          >
          </app-spot-card>
          <app-spot-card-placeholder *ngIf="spotBuilding === null"></app-spot-card-placeholder>
        </div>
      </ion-slide>
    </lazy-slider>

    <lazy-slider [isPromoted]="isPromoted" *ngIf="isPromoted" [slideOptionOverride]="slideOptionOverride" #appCarousel>
      <ion-slide class="spot-card-slide" *ngFor="let card of promotedCards">
        <div [class]="'item '+ cardContainerClass || ''">
          <app-spot-card
            (favoriteChangeEvent)="favoriteChange($event)"
            [promotedCard]="card"
            [customContainerClass]="'w-50'"
            [lazyLoadImages]="true"
            [hideFavIcon]="hideFavIcon"
            *ngIf="spotBuilding !== null"
            [isPromoted]="isPromoted"
          >
          </app-spot-card>
          <app-spot-card-placeholder *ngIf="spotBuilding === null"></app-spot-card-placeholder>
        </div>
      </ion-slide>
    </lazy-slider>

    <div class="loading-slides" *ngIf="!isPromoted && firstFetch">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  </div>
</div>
