<div *ngIf="articles?.content.length">
  <div style="text-align: center;">
    <span (click)="openHomeNews()" class="title">{{ 'newsHeader' | translate }}</span>
  </div>
  <div class="mt-4" *ngIf="articles?.content.length">
    <div>
      <button class="back" *ngIf="!slideOpts.isBeginningSlide" ion-button>
        <fa-icon class="arrow-icon " (click)="prevSlide()" [icon]="leftArrow" size="lg"></fa-icon>
      </button>
    </div>
    <div style="float: right;">
      <button class="next" ion-button (click)="nextSlide()">
        <fa-icon class="arrow-icon " [icon]="rightArrow" size="lg"></fa-icon>
      </button>
    </div>
    <div>
      <ion-slides class="m-slid" (ionSlideReachEnd)="slideReachEnd()" pager="false" [options]="slideOpts" #slider>
        <ion-slide class="lazy-slides" *ngFor="let article of articles.content">
          <news-card [article]="article"></news-card>
        </ion-slide>
      </ion-slides>
    </div>
  </div>
  <div style="text-align: center;">
    <ion-button class="btn-color-2" (click)="openHomeNews()">
      <span style="color: black;font-weight:bold">{{ 'readMore' | translate }}</span>
    </ion-button>
  </div>
</div>
