import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked
} from '@angular/core';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material';
import { CommonGenericService } from '@app/core/common.service';
import { I18nService } from '@app/core/i18n.service';
import { IDNameDTO } from '@app/models/id-name.model';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { StateDTO } from '@app/models/state.model';
import { SpotBuildingSearchRequest } from '../../models/spot-buildings/spot-building-search.model';
import { SpotBuildingsSearchService } from '../../spot-buildings/services/spot-buildings-search.service';
import { LocationService } from '../services/location.service';
import { Output, EventEmitter } from '@angular/core';
import { AppSpotCardSlider } from '../spot-card-slider/spot-card-slider';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { SpotBuildingsSortDTO } from '@app/models/spot-buildings/spot-buildings-sort.model';
import { FavoriteChange } from '../../models/shared/shared-interfaces.model';
import { TRANSACTION_TYPES } from '@app/models/transaction-type.model';

@Component({
  selector: 'app-spot-card-tabs',
  templateUrl: './spot-card-tabs.component.html',
  styleUrls: ['./spot-card-tabs.component.scss']
})
export class SpotCardTabsComponent implements OnInit, AfterViewInit, AfterContentChecked {
  showForLeaseTab: boolean;
  showForSaleTab: boolean;
  showForCoworkingTab: boolean;
  @Input() spotBuildingSearchRequest: SpotBuildingSearchRequest;
  @Input() title?: string;
  @Input() portfolioFor?: string;
  @Input() hideStatesFilter?: any;
  @Input() showHeaderSorting?: any;
  @Input() onlyFavorites?: boolean;
  @Input() isFavorite: boolean = false;
  @Output() totalCountEvent = new EventEmitter<number>();

  @ViewChild('spotCardSlider', { static: false }) spotCardSlider: AppSpotCardSlider;
  @ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;
  allSpotIds: number[];
  totalSpotBuildingsCount: number = 0;
  allSpotBuildings: SpotBuildingDetailDTO[] = [];
  currentPage: number = 1;
  pageSize: number = 12;
  cityFilters: StateDTO[];
  currentCityFilterId: number = null;
  allStateFilter: IDNameDTO;
  currentSpotBuildingType: string = 'L';
  tabIndex: number = 0;
  searching: boolean = false;
  isMobile: boolean = false;
  searchingMobile: boolean = false;
  currentPhotoIndex: number = 0;
  faHeart: any;
  firstTime: boolean = false;
  forLeaseLbl: string;
  forSaleLbl: string;
  forCoworkingLbl: string;

  constructor(
    private spotBuildingSearchService: SpotBuildingsSearchService,
    private commonService: CommonGenericService,
    private locationService: LocationService,
    private i18NService: I18nService,
    private breakpointObserver: BreakpointObserver,
    private routingHelperService: RoutingHelperService,
    private cdr: ChangeDetectorRef
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateInkBar();
    }, 500);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  updateInkBar() {
    if (this.tabGroup) {
      this.tabGroup.realignInkBar();
    }
  }

  ngOnInit() {
    this.faHeart = faHeart;
    this.setSearching(true);
    this.countSpotsByType();
    this.allStateFilter = {
      id: null,
      name: this.i18NService.get('All')
    };
    this.cityFilters = [];
    this.forLeaseLbl = this.i18NService.get(TRANSACTION_TYPES[0].name);
    this.forSaleLbl = this.i18NService.get(TRANSACTION_TYPES[2].name);
    this.forCoworkingLbl = 'Coworking';
  }

  countSpotsByType() {
    this.spotBuildingSearchService.countSpotsByType(this.spotBuildingSearchRequest).then((spotByTypes: any) => {
      if (spotByTypes) {
        this.showForLeaseTab = spotByTypes['L'] ? spotByTypes['L'] > 0 : false;
        this.showForSaleTab = spotByTypes['S'] ? spotByTypes['S'] > 0 : false;
        this.showForCoworkingTab = spotByTypes['C'] ? spotByTypes['C'] > 0 : false;

        this.currentSpotBuildingType = this.showForLeaseTab
          ? 'L'
          : this.showForSaleTab
          ? 'S'
          : this.showForCoworkingTab
          ? 'C'
          : null;

        if (!this.currentSpotBuildingType) {
          this.setSearching(false);
          return;
        }

        this.firstTime = true;
        if (!this.commonService.isMobileWidth()) {
          this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
        } else {
          this.updatePayload(this.currentSpotBuildingType, null);
        }
      }
    });
  }

  changeSpotType(type: string) {
    this.currentSpotBuildingType = type;
    this.clearSearch();
    if (this.isMobile) {
      this.updatePayload(this.currentSpotBuildingType, null);
      this.findStatesByRequestFilter(this.currentSpotBuildingType);
    } else {
      this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.setSearching(true);
    this.tabIndex = tabChangeEvent.index;
    this.currentSpotBuildingType =
      tabChangeEvent.tab.textLabel == this.forLeaseLbl
        ? 'L'
        : tabChangeEvent.tab.textLabel == this.forSaleLbl
        ? 'S'
        : tabChangeEvent.tab.textLabel == this.forCoworkingLbl
        ? 'C'
        : null;
    this.cityFilters = [];
    this.currentCityFilterId = this.allStateFilter.id;
    this.clearSearch();

    if (this.isMobile) {
      this.updatePayload(this.currentSpotBuildingType, null);
      this.findStatesByRequestFilter(this.currentSpotBuildingType);
    } else {
      this.fetchSpots(this.currentSpotBuildingType, this.currentPage);
    }
  }

  clearSearch() {
    this.currentPage = 1;
    this.allSpotIds = [];
    this.allSpotBuildings = [];
  }

  applyCityFilter(stateId: number) {
    this.currentCityFilterId = stateId;
    this.setSearching(true);
    this.findAllSpotBuildingIds(this.currentSpotBuildingType, stateId).then((result: any) => {
      this.allSpotIds = result.body;
      this.totalSpotBuildingsCount = this.allSpotIds ? this.allSpotIds.length : 0;
      this.getSpotBuildingsPaginated(this.allSpotIds, this.currentPage, false);
    });
  }

  applyStateFilterOnMobile(event: any, stateId: number, sliderIndex: number) {
    this.currentCityFilterId = stateId;
    this.setSearching(true);

    if (sliderIndex >= 0) {
      this.currentPhotoIndex = sliderIndex;
      this.finishSearch();
    }
    this.updatePayload(this.currentSpotBuildingType, stateId);
  }

  updatePayload(spotBuildingType: string, stateId: number) {
    this.spotBuildingSearchRequest = {
      ...this.spotBuildingSearchRequest,
      stateId: stateId,
      listingLeaseOrSale: [...spotBuildingType]
    };
  }

  showMoreResults() {
    return this.totalSpotBuildingsCount - this.currentPage * this.pageSize > 0;
  }

  showMoreResultsAction() {
    this.currentPage++;
    this.getSpotBuildingsPaginated(this.allSpotIds, this.currentPage, true);
  }

  updateCounts(total?: number) {
    if (total) {
      this.totalSpotBuildingsCount = total;
    } else {
      this.totalSpotBuildingsCount = this.allSpotIds ? this.allSpotIds.length : 0;
    }

    this.totalCountEvent.emit(this.totalSpotBuildingsCount);
  }

  spotsCountSlider(total: number) {
    this.finishSearch(total);
  }

  async fetchSpots(spotBuildingType: string, page: number) {
    this.setSearching(true);

    await this.findAllSpotBuildingIds(spotBuildingType).then((result: any) => {
      this.allSpotIds = result.body;
      this.getSpotBuildingsPaginated(this.allSpotIds, page, false);
    });

    this.findStatesByRequestFilter(spotBuildingType);
  }

  findStatesByRequestFilter(spotBuildingType: string) {
    this.getStatesByRequestFilter(spotBuildingType).then((states: StateDTO[]) => {
      this.cityFilters = [];

      if (states && states.length > 0) {
        states = states.map(state => {
          const stateName = this.getDefaultTranslation(state.name);
          state.name = stateName;
          return state;
        });

        this.cityFilters.push(this.allStateFilter);
        states = this.locationService.putRioAndSaoPauloOnTop(states);
        if (states.length > 5) {
          states = states.slice(0, 5);
        }
        this.cityFilters = [...states];
        this.cityFilters.unshift(this.allStateFilter);

        this.currentPhotoIndex = 0;
      }
    });
  }

  getStatesByRequestFilter(spotBuildingType: string) {
    if (this.portfolioFor == 'BROKER_DETAIL') {
      return this.locationService.getStatesByBrokerIdAndSpotBuildingType(
        this.spotBuildingSearchRequest.brokerId,
        spotBuildingType
      );
    }
    if (this.portfolioFor == 'COMPANY_DETAIL') {
      return this.locationService.getStatesByCompanyAndSpotBuildingType(
        this.spotBuildingSearchRequest.companyId,
        spotBuildingType
      );
    }

    return new Promise(() => []);
  }

  getDefaultTranslation(translationArtifact: any) {
    try {
      return this.i18NService.getTranslation(translationArtifact);
    } catch (e) {
      console.error(e);
    }
  }

  private async findAllSpotBuildingIds(spotBuildingType: string, stateId?: number) {
    if (spotBuildingType && spotBuildingType == 'L' && this.isFavoriteSearch()) {
      this.spotBuildingSearchRequest.listingLeaseOrSale = [...spotBuildingType, 'C'];
    } else {
      this.spotBuildingSearchRequest.listingLeaseOrSale = [...spotBuildingType];
    }

    this.spotBuildingSearchRequest.stateId = stateId;

    this.clearSearch();

    return await this.spotBuildingSearchService
      .searchSpotBuildingIds(this.spotBuildingSearchRequest, 1)
      .then(async spotBulidingIdsResponse => {
        return spotBulidingIdsResponse;
      });
  }

  async getSpotBuildingsPaginated(spotIds: number[], page: number, concatNewResult: boolean) {
    if (!spotIds || spotIds.length == 0) {
      this.allSpotBuildings = [];
      this.finishSearch();
      return;
    }

    await this.spotBuildingSearchService.getSpotBuildingDetails(page, this.pageSize, spotIds).then(result => {
      if (concatNewResult) {
        let newListings: any = result;
        if (newListings && newListings.length) {
          this.allSpotBuildings = this.allSpotBuildings.concat(newListings);
        }
      } else {
        this.allSpotBuildings = result;
      }
      this.sortSpots();
    });
  }

  finishSearch(total?: number) {
    this.updateCounts(total);
    setTimeout(() => {
      this.setSearching(false);
      this.firstTime = false;
    }, 100);
  }

  sortSpots() {
    if (!this.getCurrentSort()) {
      this.allSpotBuildings = this.allSpotBuildings.sort(
        (first, second) => Number(first.premium) - Number(second.premium)
      );
    }

    this.finishSearch();
  }

  isMobileDevice() {
    return this.isMobile && this.spotCardSlider;
  }

  areThereSpotBuildings() {
    if (this.isMobileDevice()) {
      return this.spotCardSlider.allSpotIds && this.spotCardSlider.allSpotIds.length > 0;
    }
    return this.allSpotBuildings && this.allSpotBuildings.length > 0;
  }

  showNoFoundSection() {
    return !this.areThereSpotBuildings() && this.isFavoriteSearch();
  }

  isFavoriteSearch() {
    return this.portfolioFor && this.portfolioFor == 'FAVORITE_SEARCH';
  }

  isSearching() {
    if (this.isMobile) {
      return this.searchingMobile;
    }
    return this.searching;
  }

  goToSearch() {
    this.routingHelperService.navigateToSearchPage(false);
  }

  applySortBy(type: string, direction: string) {
    if (this.allSpotIds && this.allSpotIds.length > 0) {
      const sortDTO: SpotBuildingsSortDTO = {
        sortBy: type,
        direction: direction,
        spotBuildingType: this.currentSpotBuildingType,
        spotIds: [...this.allSpotIds],
        currencyType: null
      };

      this.setCurrentSort(sortDTO);

      if (sortDTO.spotIds) {
        this.spotBuildingSearchService.sortCurrentSpotBuildingIds(sortDTO).then((result: any) => {
          const sortedIds = result.body;
          this.getSpotBuildingsPaginated(sortedIds, 1, false);
        });
      }
    }
  }

  isActiveSort(type: string, direction: string) {
    const currentSortFilter = this.getCurrentSort();
    if (currentSortFilter) {
      return currentSortFilter.sortBy == type && currentSortFilter.direction == direction;
    }
    return false;
  }

  clearSortSelection() {
    this.setCurrentSort(undefined);
    this.getSpotBuildingsPaginated(this.allSpotIds, 1, false);
  }

  setCurrentSort(sortBy: SpotBuildingsSortDTO) {
    this.spotBuildingSearchRequest.sortSpotsBy = sortBy;
  }

  getCurrentSort() {
    return this.spotBuildingSearchRequest.sortSpotsBy;
  }

  getHeaderResults() {
    return `${this.totalSpotBuildingsCount} ${this.i18NService.get('global.savedAsFav')}`;
  }

  hideFavoriteIcon() {
    return false;
  }

  favoriteChange(changedFavorite: FavoriteChange) {
    if (this.onlyFavorites) {
      this.keepFavoriteSpots(changedFavorite);
      this.updateCounts();
    }
  }

  keepFavoriteSpots(updatedFavorite: FavoriteChange) {
    this.allSpotBuildings = this.allSpotBuildings.filter(spot => spot.favorite);
    if (updatedFavorite && updatedFavorite.id) {
      this.allSpotIds = this.allSpotIds.filter(spotId => spotId != updatedFavorite.id);
    }
  }

  setSearching(value: boolean) {
    if (this.isMobile) {
      this.searchingMobile = value;
    } else {
      this.searching = value;
    }
  }
}
