import { Component, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageService, I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { AgentNotificationComponent } from '@app/search-navigation/components/agent-notification/agent-notification.component';
import { PopoverController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { BreadcrumbOptions } from '@app/shared';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core/auth';
import { LoginFormDialogService } from '@app/shared/login';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';

@Component({
  selector: 'app-listing-user',
  templateUrl: './listing-user.component.html',
  styleUrls: ['./listing-user.component.scss']
})
export class ListUserComponent implements OnInit, OnDestroy {
  formData: any;
  angForm: FormGroup;
  isMobile: boolean;
  sendMessage: boolean = false;
  loading: boolean = true;
  listings: any;
  listingId: any;
  userId: any;
  broker: any;
  submitted = false;
  brokerEmail: boolean = false;
  brokerPhone: boolean = false;
  unsetBioOverflow: boolean = true;
  profilePic: any;
  listingIds: any;
  currentPage: number = 1;
  showMoreIcon: boolean;
  totalListingsCount: number = 0;
  displayScrollToTop: boolean;
  isLoggedIn: boolean = false;
  loginSubscription: Subscription;
  whatsappPhone: boolean = false;
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  lang: any;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  countSubscribe: number;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private listingDetailService: ListingDetailService,
    private location: Location,
    private _ts: TranslateService,
    private imgService: ImageService,
    private listingService: ListingService,
    private userActivityService: UserActivityService,
    private i18nService: I18nService,
    private whatsAppService: WhatsAppService,
    private agentNotPopOverCtrl: PopoverController,
    private analyticsService: AnalyticsService,
    private deviceDetector: DeviceDetectorService,
    private auth: AuthenticationService,
    private loginFormDialog: LoginFormDialogService,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
    });
    const navigation = this.router.getCurrentNavigation();
    this.listingId =
      navigation.extras.state && navigation.extras.state.listingId ? navigation.extras.state.listingId : null;
  }

  ngOnInit(): void {
    try {
      this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
      this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
      this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
      if (this.isDesktop.length) {
        this.currentDevice = this.isDesktop;
      } else if (this.isMobilePhone.length) {
        this.currentDevice = this.isMobilePhone;
      } else if (this.isTablet.length) {
        this.currentDevice = this.isTablet;
      }
      this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
      this.loading = true;
      this.userId = this.route.snapshot.params.id;
      this.createForm();
      this.getUserInfo(this.userId)
        .then((result: any) => {
          this.broker = result;
          11;
          let id = { offeredByUserId: this.broker.userId };
          this.listingService.searchListings(id, this.currentPage).then(response => {
            this.totalListingsCount = parseInt(response.headers.get('x-total-count'));
            this.listings = response.body.listings;
            this.listingIds = response.body.allListingIds;
            this.showMoreIcon = this.totalListingsCount - this.currentPage * 20 > 0;
            this.loading = false;
          });
        })
        .catch((e: any) => {
          const state: any = {
            badURL: `${environment.spotServerUrl}/listing-user/${this.userId}`
          };
          console.error('Listing User page', e, this.userId);
          this.router.navigateByUrl('/not-found', { state: state });
        });
    } catch (e) {
      const state: any = {
        badURL: `${environment.spotServerUrl}/listing-user/${this.userId}`
      };
      console.error('Listing User page', e, this.userId);
      this.router.navigateByUrl('/not-found', { state: state });
    }
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
  }

  unsetOverflow() {
    this.unsetBioOverflow = true;
  }

  get form() {
    return this.angForm.controls;
  }

  checkOverflow() {
    let el = document.getElementById('#bio');
    var curOverflow = el.style.overflow;

    if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';

    var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

    el.style.overflow = curOverflow;

    return isOverflowing;
  }

  async getMoreListings() {
    this.loading = true;
    let id = { offeredByUserId: this.broker.userId };
    this.listingService.searchListings(id, ++this.currentPage).then(response => {
      let newListings: any = response.body.listings;
      this.showMoreIcon = this.totalListingsCount - this.currentPage * 20 > 0;
      if (newListings && newListings.length) {
        this.listings = this.listings.concat(newListings);
      }
      this.loading = false;
    });
  }

  createForm() {
    this.angForm = this.fb.group(
      {
        name: ['', Validators.required],
        phone: [''],
        email: [''],
        note: ['', Validators.required]
      },
      { validator: this.checkFields }
    );
  }

  checkFields(formGroup: any) {
    let emailField = formGroup.controls['email'].value;
    let phoneField = formGroup.controls['phone'].value;
    return !emailField.length && !phoneField.length ? { oneRequired: true } : null;
  }

  goBack() {
    this.location.back();
  }

  isHeaderVisible(event: any) {
    this.displayScrollToTop = event.visible ? false : true;
  }

  getMediumImg(id: any) {
    return this.imgService.mediumThumbnail(id);
  }

  openShowMoreLogInModal(id: number) {
    const subscribeDialog = this.dialog.open(SubscribeModalComponent, {
      width: '400px',
      panelClass: 'my-custom-dialog-class',
      data: id
    });

    subscribeDialog.afterClosed().subscribe(val => {
      if (val != undefined) {
        switch (val) {
          case 1:
            this.showBrokerPhone(val);
            break;
          case 2:
            this.showBrokerEmail(val);
            break;
          case 3:
            this.showBrokerWhatsapp(val);
            break;
          default:
            return null;
        }
      }
    });
  }

  showBrokerPhone(notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(1);
        return;
      }
    }
    this.brokerPhone = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
  }

  showBrokerEmail(notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(2);
        return;
      }
    }
    this.brokerEmail = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
  }

  showBrokerWhatsapp(notSubscribe: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(3);
        return;
      }
    }
    this.whatsappPhone = true;
    this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
  }

  registerUserActivity(userActivityType: UserActivityType) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: this.broker.userId,
      listingId: null
    };
    this.userActivityService.saveUserActivity(userActivity);
  }

  async getUserInfo(id: any) {
    return await this.listingDetailService.getUserById(id, null).toPromise();
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18nService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  async clickToChat() {
    const headerMsg = this.i18nService.get('global.broker-detail.clickToChat.whatsAppMsgHeader');
    const msgToUserAgent = this.i18nService.get('global.broker-detail.clickToChat.msgToUserAgent');
    const spotURL = environment.spotServerUrl.concat(this.i18nService.getLanguagePathURL());

    const message = `${headerMsg} ${spotURL}

${msgToUserAgent}
    `;

    this.whatsAppService.sendMessageViaWhataspp(this.broker.whatsappNumber, message);
    return false;
  }

  async clickToEmail(ev: any) {
    const defaultMsg = this.i18nService.get('global.broker-detail.clickToEmail.msgToUserAgent');
    const message = `${defaultMsg}`;

    const popover = await this.agentNotPopOverCtrl.create({
      component: AgentNotificationComponent,
      event: ev,
      cssClass: 'popover-agent-email',
      componentProps: {
        defaultMsg: message,
        brokerId: this.broker.userId,
        popupCtrl: this.agentNotPopOverCtrl,
        pageURLFrom: environment.spotServerUrl
      }
    });
    return await popover.present();
  }

  openEmail() {
    window.location.href = `mailto:${this.broker.email}`;
    return false;
  }
}
