import { Injectable, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { UserProfile } from '@app/models/user-account.model';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountService {
  public currentUser$ = new BehaviorSubject<any>(this.getCurrentUser);
  public seenPersonalDataFlagKey: string = 'seenPersonalDataPopup';
  private endpoint: string = `api/account`;
  private externalIpEndpoint: string = `api/account/ip`;
  private unreadMessagesEndpoint: string = `api/userChat/unreadConversationsFromSupport`;
  private publicProfilePhoto: string = 'api/account/photoByUserLogin';
  private keepConnectedEndpoint: string = 'api/account/keep-connected';
  private acceptPersonalDataEndpoint: string = '/api/account/acceptPersonalData/';
  private changeLangAPI: string = '/api/account/change-language/';

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: any) {}

  public get getCurrentUser() {
    return !!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  }

  public get getPersonalDataFlag() {
    return localStorage.getItem(this.seenPersonalDataFlagKey)
      ? JSON.parse(localStorage.getItem(this.seenPersonalDataFlagKey))
      : null;
  }

  public getAccount() {
    return this.http.get(this.endpoint);
  }

  getProfilePicture(login: any) {
    return this.http.get(`${this.publicProfilePhoto}/${login}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  public getAccountFast() {
    return this.http.get(`${this.endpoint}/deviceFast`);
  }

  public getUserIP() {
    return this.http.get(`${this.externalIpEndpoint}`).toPromise();
  }

  public getUnreadMessagesCount() {
    return this.http.get(`${this.unreadMessagesEndpoint}`);
  }

  public saveUserProfile(userProfile: UserProfile) {
    return this.http.post(this.endpoint, userProfile);
  }

  async activateUserWithHash(hash: String) {
    return await this.http.post(`api/account/hash/${hash}/activate`, null).toPromise();
  }

  public getUserPhotoList() {
    return this.http.get('api/account/photos');
  }

  public changeUserEmail(login: string, newEmail: string) {
    return this.http.post(`/api/account/${login}/change-email`, newEmail);
  }

  public changeUserPassword(passwordProps: any) {
    return this.http.post('/api/account/change-password', passwordProps);
  }

  public setKeepConnected(keepConnectedData: { keepConnected: boolean }) {
    return this.http.put(this.keepConnectedEndpoint, keepConnectedData).subscribe(
      () => {
        const user = { ...this.getCurrentUser, keepConnected: keepConnectedData.keepConnected };
        localStorage.setItem('user', JSON.stringify(user));
        this.currentUser$.next(user);
      },
      error => {
        throw Error(error);
      }
    );
  }

  public getUploadedImageId(imageList: any[]) {
    let uploadedImage: any = {};

    if (!imageList.length) {
      uploadedImage.originalImageId = null;
      uploadedImage.profileImageId = null;
    } else if (imageList.length < 2) {
      uploadedImage.originalImageId = imageList[0];
      uploadedImage.profileImageId = imageList[0];
    } else {
      uploadedImage.originalImageId = imageList[imageList.length - 2];
      uploadedImage.profileImageId = imageList[imageList.length - 1];
    }
    return uploadedImage;
  }

  public acceptPersonalData(date: string) {
    return this.http.post(`${this.acceptPersonalDataEndpoint}${date}`, {}).subscribe(() => {}, error => {});
  }

  public accountLogout() {
    localStorage.removeItem(this.seenPersonalDataFlagKey);
    this.currentUser$.next(null);
  }

  public changeLanguage(language: string) {
    return this.http.post(this.changeLangAPI, language).toPromise();
  }
}
