import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { SpotBuildingSearchRequest } from '@app/models/spot-buildings/spot-building-search.model';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  favoritesCount: number = 0;
  displayScrollToTop: boolean = false;
  isMobile: boolean = false;
  spotBuildingSearchRequest: SpotBuildingSearchRequest = {};
  isFavorite = true;
  selectedIndex: number = 0;
  countSpotSet: number = 0;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM', 'PROMOTED'];
    this.spotBuildingSearchRequest.favoriteSearch = true;
  }

  updateCounts(total: number) {
    this.favoritesCount = total;
  }
  getHeaderResults() {
    return `${this.favoritesCount} SPOTs Saved As Favorites`;
  }

  isFooterVisible(event: any) {
    this.displayScrollToTop = event.visible;
  }

  changeStep(step: number) {
    this.selectedIndex = step;
  }

  eventSpotSet(event: number) {
    this.countSpotSet = event;
  }
}
