import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController, PopoverController } from '@ionic/angular';
var SpotSpacesBuildingsComponent = /** @class */ (function () {
    function SpotSpacesBuildingsComponent(createSpotBuildingsService, popOverCtrlHelper, alertCtrl, i18n, popoverCtrl) {
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.alertCtrl = alertCtrl;
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.fileName = null;
        this.loading = false;
    }
    SpotSpacesBuildingsComponent.prototype.ngOnInit = function () {
        this.spacesParameters = [];
    };
    SpotSpacesBuildingsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.SpotBuildingsResponse) {
            this.createSpotBuildingsService
                .getSpaceConditions(this.SpotBuildingsResponse.id)
                .then(function (response) {
                _this.spacesParameters = response;
            });
        }
    };
    SpotSpacesBuildingsComponent.prototype.downloadExcel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.createSpotBuildingsService.getUrlExcel(this.SpotBuildingsResponse.id)];
                    case 1:
                        url = _a.sent();
                        this.createSpotBuildingsService.downloadExcel(url.fileName);
                        setTimeout(function () {
                            _this.loading = false;
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotSpacesBuildingsComponent.prototype.fileChangedEvent = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.fileUpload = event.srcElement.files[0];
                this.fileName = event.srcElement.files[0].name;
                return [2 /*return*/];
            });
        });
    };
    SpotSpacesBuildingsComponent.prototype.processTemplate = function () {
        var _this = this;
        this.loading = true;
        this.createSpotBuildingsService
            .createSpacesBuilding(this.fileUpload, this.SpotBuildingsResponse.id)
            .then(function (resp) {
            _this.createSpotBuildingsService
                .getSpaceConditions(_this.SpotBuildingsResponse.id)
                .then(function (response) {
                _this.spacesParameters = null;
                _this.spacesParameters = response;
                _this.loading = false;
                var inputFile = document.getElementById('inputImageFile');
                inputFile.value = null;
                _this.fileUpload = null;
                _this.fileName = null;
                if (resp && resp.messages.length > 0) {
                    _this.showAlert("\n\n" + resp.messages[0]);
                }
                else {
                    _this.showAlert('\n\nProcess completed');
                }
            });
        })
            .catch();
    };
    SpotSpacesBuildingsComponent.prototype.seeMoreAction = function (parameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popOverCtrlHelper.openImageUploadSpaceBuilding(parameters.id, function (popoverData) {
                            _this.createSpotBuildingsService
                                .getSpaceConditions(_this.SpotBuildingsResponse.id)
                                .then(function (response) {
                                _this.spacesParameters = null;
                                _this.spacesParameters = response;
                            });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotSpacesBuildingsComponent.prototype.showAlert = function (alertMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl
                            .create({
                            header: this.i18n.get('global.form.sendMsgToAgent.notification'),
                            subHeader: alertMsg,
                            buttons: [
                                {
                                    text: this.i18n.get('global.form.sendMsgToAgent.ok'),
                                    handler: function () {
                                        if (_this.formData.valid) {
                                            _this.popoverCtrl.dismiss();
                                        }
                                    }
                                }
                            ]
                        })
                            .then(function (alert) {
                            alert.present();
                        })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SpotSpacesBuildingsComponent;
}());
export { SpotSpacesBuildingsComponent };
