import { LocaleData } from 'ngx-bootstrap';
import { PropertySubType } from '../property-subtype.model';
import { SpotTitlesOrAddress } from '../titlesOrAddressDTO';
import { CoworkingSpaceType } from './spot-available-spaces';
import { SpotBuildingsSortDTO } from './spot-buildings-sort.model';

export class SpotBuildingSearchRequest {
  listingClasses?: string[];
  stateId?: number;
  cityId?: number;
  keyword?: string; // location keyword
  marketIds?: number[];
  marketId?: number;
  fakeMarketId?: number;
  neighborhood?: string;
  companyId?: number;
  brokerIds?: number[];
  brokerId?: number;
  // Main Filter
  listingLeaseOrSale?: string[]; // Listing Type
  buildingTypes?: string[]; // Property Type
  propertySubtypeIds?: number[];
  propertySubTypes?: PropertySubType[];
  spotTitlesOrAddresses?: SpotTitlesOrAddress[];
  availabilityDate?: string;

  // Advanced Search - Investements
  askingPricePerAreaMin?: number;
  askingPricePerAreaMax?: number;
  parkingSpacesMin?: number;
  parkingSpacesMax?: number;
  ceilingHeightMin?: number;
  ceilingHeightMax?: number;
  cityIds?: number[];
  landAreaIni?: number;
  landAreaEnd?: number;
  location?: string;
  monthlyRentPerAreaMin?: number;
  monthlyRentPerAreaMax?: number;
  numberFloorIni?: number;
  numberFloorEnd?: number;
  stateIds?: number[];
  totalAreaIni?: number; // OR aVAILABLE SPACE
  totalAreaEnd?: number;
  totalAskingPriceIni?: number;
  totalAskingPriceEnd?: number;
  totalMonthlyRentIni?: number;
  totalMonthlyRentEnd?: number;
  yearBuiltIni?: number;
  yearBuiltEnd?: number;
  yearRenovatedIni?: number;
  yearRenovatedEnd?: number;
  propertySizeIni?: number;
  propertySizeEnd?: number;
  monthlyCostPerPersonMin?: number;
  monthlyCostPerPersonMax?: number;
  positions?: number;
  latitude?: number;
  longitude?: number;
  radius?: number;
  // End Avanced Search

  // coworking Type//

  coworkingType?: CoworkingSpaceType;

  //Features
  bicycleRack?: boolean;
  fiberOptic?: boolean;
  bulletProofCabin?: boolean;
  centralAirConditioning?: boolean;
  electricGenerator?: boolean;
  heliport?: boolean;
  leedStatus?: boolean; // sustainabilityCertification
  sluiceGate?: boolean;
  singleTenant?: boolean;
  sprinklers?: boolean;
  truckYard?: boolean;
  twentyFourHourSecurity?: boolean;
  //End Features

  // Spot Features
  blockAndSteelConstruction?: boolean;
  coreAndShellCondition?: boolean;
  crossDocking?: boolean;
  datacenterCondition?: boolean;
  forRetailUseConditionOffice?: boolean;
  forRetailUseConditionIndustrial?: boolean;
  flexCondition?: boolean;
  fullFloorCondition?: boolean;
  includeColdStorage?: boolean;
  manufacturingCondition?: boolean;
  logisticsCondition?: boolean;
  plugAndPlayConditionOffice?: boolean;
  preBuildCondition?: boolean;
  skyLightsSpace?: boolean;
  steelConstruction?: boolean;
  subLease?: boolean;
  petFriendly?: boolean;

  plugAndPlayConditionCoworking?: boolean;
  furnishedConditionCoworking?: boolean;
  barista?: boolean;
  enhancedCleaning?: boolean;
  parkingAvailable?: boolean;
  conferenceRoom?: boolean;
  privateOutdoorSpace?: boolean;
  // End Spot Features

  sortSpotsBy?: SpotBuildingsSortDTO;
  favoriteSearch?: boolean;
  spotStatus?: string;
  currencyType?: string;

  waterHydrant?: boolean;
  withinIndustrialPark?: boolean;
  accessToShowers?: boolean;
  parkingLotType?: string;

  kvaMin?: number;
  kvaMax?: number;
  spotSetName?: string;
  buildingName?: string;
  spotSetId?: number;

  constructor() {}
}

export type SpotBuildingSearchRequestType = {
  notSpotIds?: number[];
  listingClasses?: string[];
  stateId?: number;
  cityId?: number;
  keyword?: string; // location keyword
  neighborhood?: string;
  companyId?: number;
  brokerIds?: number[];
  // Main Filter
  listingLeaseOrSale?: string[]; // Listing Type
  buildingTypes?: string[]; // Property Type
  propertySubtypeIds?: number[];
  propertySubTypes?: PropertySubType[];
  spotTitlesOrAddresses?: SpotTitlesOrAddress[];
  isFeaturedWithoutCheckingDate?: boolean;

  // Advanced Search - Investements
  askingPricePerAreaMin?: number;
  askingPricePerAreaMax?: number;
  ceilingHeightMin?: number;
  ceilingHeightMax?: number;
  cityIds?: number[];
  landAreaIni?: number;
  landAreaEnd?: number;
  location?: string;
  monthlyRentPerAreaMin?: number;
  monthlyRentPerAreaMax?: number;
  numberFloorIni?: number;
  numberFloorEnd?: number;
  stateIds?: number[];
  totalAreaIni?: number; // OR aVAILABLE SPACE
  totalAreaEnd?: number;
  totalAskingPriceIni?: number;
  totalAskingPriceEnd?: number;
  totalMonthlyRentIni?: number;
  totalMonthlyRentEnd?: number;
  yearBuiltIni?: number;
  yearBuiltEnd?: number;
  yearRenovatedIni?: number;
  yearRenovatedEnd?: number;
  propertySizeIni?: number;
  propertySizeEnd?: number;
  // End Avanced Search

  //Features
  bicycleRack?: boolean;
  fiberOptic?: boolean;
  bulletProofCabin?: boolean;
  centralAirConditioning?: boolean;
  electricGenerator?: boolean;
  heliport?: boolean;
  leedStatus?: boolean; // sustainabilityCertification
  sluiceGate?: boolean;
  singleTenant?: boolean;
  sprinklers?: boolean;
  truckYard?: boolean;
  twentyFourHourSecurity?: boolean;
  //End Features

  // Spot Features
  blockAndSteelConstruction?: boolean;
  coreAndShellCondition?: boolean;
  crossDocking?: boolean;
  datacenterCondition?: boolean;
  forRetailUseConditionOffice?: boolean;
  forRetailUseConditionIndustrial?: boolean;
  flexCondition?: boolean;
  fullFloorCondition?: boolean;
  includeColdStorage?: boolean;
  manufacturingCondition?: boolean;
  logisticsCondition?: boolean;
  plugAndPlayConditionOffice?: boolean;
  preBuildCondition?: boolean;
  skyLightsSpace?: boolean;
  steelConstruction?: boolean;
  subLease?: boolean;
  // End Spot Features

  sortSpotsBy?: SpotBuildingsSortDTO;
  randomOrder?: boolean;

  waterHydrant?: boolean;
  withinIndustrialPark?: boolean;
  accessToShowers?: boolean;
  parkingLotType?: string;

  kvaMin?: number;
  kvaMax?: number;
};
export interface SearchViewsType {
  map: 'global.search.viewMap';
  list: 'global.search.viewList';
}
