import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
var RenewExtendModalComponent = /** @class */ (function () {
    function RenewExtendModalComponent(navParams, i18NService, popoverCtrl, listingService) {
        this.navParams = navParams;
        this.i18NService = i18NService;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
    }
    RenewExtendModalComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var interpolateParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.listingIds = this.navParams.data.listingIds;
                        interpolateParams = { totalListings: this.listingIds ? this.listingIds.length : 0 };
                        return [4 /*yield*/, this.listingService.countActiveAndExpiredListings(this.listingIds).then(function (response) {
                                if (response) {
                                    interpolateParams.totalListings = response.body.totalActiveExpiredListings;
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.renewExtendDescription = this.i18NService.getMetatagTranslation('renewExtendListings.description', interpolateParams);
                        return [2 /*return*/];
                }
            });
        });
    };
    RenewExtendModalComponent.prototype.checkDate = function (ev) { };
    RenewExtendModalComponent.prototype.closeModal = function (updated) {
        this.popoverCtrl.dismiss({ updated: updated });
    };
    RenewExtendModalComponent.prototype.formatDate = function (date) {
        var d = date, month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    };
    RenewExtendModalComponent.prototype.updateNewExpirationDate = function () {
        if (this.newExpirationDate && this.listingIds) {
            this.listingService.setNewExpirationDateFor(this.listingIds, this.formatDate(this.newExpirationDate));
            this.closeModal(true);
        }
    };
    return RenewExtendModalComponent;
}());
export { RenewExtendModalComponent };
