<div class="privacy-policy-dialog">
  <img mat-dialog-title src="../../../assets/siila-spot-brand-br.png" alt="siila Spot logo" />
  <form (ngSubmit)="onSubmit()" class="px-2">
    <mat-dialog-content class="privacy-policy-dialog__content">
      <p>
        {{ 'global.privacy-policy.dialog.paragraphs.p1' | translate }}
        {{ date | date: 'M/d/yyyy' }}.
      </p>
      <p>
        {{ 'global.privacy-policy.dialog.paragraphs.p2-1' | translate }}
        <span role="button" (click)="openPrivacyReview()" class="privacy-policy-dialog__review">
          {{ 'global.privacy-policy.dialog.paragraphs.linkText' | translate }}</span
        >{{ 'global.privacy-policy.dialog.paragraphs.p2-2' | translate }}
      </p>
      <div class="form-group d-flex align-items-center justify-content-between">
        <ion-checkbox
          class="flex-shrink-0"
          required
          type="checkbox"
          id="accept-privacy-policy"
          [(ngModel)]="acceptedPolicy"
          name="acceptedPolicy"
        ></ion-checkbox>
        <label for="accept-privacy-policy" class="mb-0 ml-2">
          {{ 'global.privacy-policy.dialog.checkBoxlabel' | translate }}
          {{ date | date: 'M/d/yyyy' }}.
        </label>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="position-relative">
      <ion-button [color]="acceptedPolicy ? 'primary' : 'medium'" type="submit" [disabled]="!acceptedPolicy">
        {{ 'global.privacy-policy.dialog.accept' | translate | uppercase }}
      </ion-button>
      <span class="privacy-policy-dialog__dismiss" role="button" (click)="onDismiss()" style="margin: 4%;">{{
        'global.privacy-policy.dialog.close' | translate
      }}</span>
    </mat-dialog-actions>
  </form>
</div>
