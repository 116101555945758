import { Injectable } from '@angular/core';

@Injectable()
export class PropertyTypeHelperService {
  constructor() {}

  getPropertyTypeName(propertyType: number) {
    switch (propertyType) {
      case 1001:
        return 'Industrial';
      case 2001:
        return 'Office';
      case 3001:
        return 'Retail';
      case 5001:
        return 'Hotel';
      default:
        return null;
    }
  }

  getPropertyTypeTranslationName(propertyType: number) {
    switch (propertyType) {
      case 1001:
        return 'global.industrial';
      case 2001:
        return 'global.office';
      case 3001:
        return 'global.retail';
      case 5001:
        return 'global.hotel';
      default:
        return null;
    }
  }

  getSpotBuildingTypeTranslation(type: string) {
    switch (type) {
      case 'L':
        return 'global.lease';
      case 'S':
        return 'global.sale';
      case 'C':
        return 'global.coworkingLbl';
      default:
        return null;
    }
  }

  getSpotBuildingTypeLongTranslation(type: string) {
    switch (type) {
      case 'L':
        return 'global.list-your-property.forLease';
      case 'S':
        return 'global.list-your-property.forSale';
      case 'C':
        return 'global.list-your-property.forCoworking';
      default:
        return null;
    }
  }
}
