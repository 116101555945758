/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social-media.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./social-media.component";
import * as i5 from "ng-navigator-share";
import * as i6 from "@ionic/angular";
var styles_SocialMediaComponent = [i0.styles];
var RenderType_SocialMediaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialMediaComponent, data: {} });
export { RenderType_SocialMediaComponent as RenderType_SocialMediaComponent };
function View_SocialMediaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "image-social"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.shareLink(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.img; _ck(_v, 0, 0, currVal_0); }); }
export function View_SocialMediaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 51, "div", [["class", "main-container flex"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "title-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "flex-align-content-end header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["alt", ""], ["class", "close-icon"], ["src", "assets/images/icons/close.png"], ["style", "width: 14px;height: 14px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "social-media-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialMediaComponent_1)), i1.ɵdid(21, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(24, 0, null, null, 21, "div", [["class", "share-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "div", [["class", "shared-this-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(28, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(29, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(33, 0, null, null, 11, "div", [["class", "copy-url-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "div", [["class", "url-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(36, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(38, 0, null, null, 5, "div", [["class", "copy-btn-section"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(40, 0, null, null, 2, "button", [["class", "copy-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyURLToClipboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(41, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(47, 0, null, null, 4, "div", [["class", "tooltip-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(49, 0, [[1, 0], ["toolTip", 1]], null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The Link has been copied "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.socialMediaLinks; _ck(_v, 21, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.input.modalTitle; _ck(_v, 8, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform("ShareLink")); _ck(_v, 29, 0, currVal_2); var currVal_3 = _co.getSharedURL(); _ck(_v, 36, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 41, 0, i1.ɵnov(_v, 42).transform("CopyURL")); _ck(_v, 41, 0, currVal_4); }); }
export function View_SocialMediaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social-media", [], null, null, null, View_SocialMediaComponent_0, RenderType_SocialMediaComponent)), i1.ɵdid(1, 114688, null, 0, i4.SocialMediaComponent, [i2.DOCUMENT, i1.Renderer2, i5.NgNavigatorShareService, i6.NavParams, i6.PopoverController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialMediaComponentNgFactory = i1.ɵccf("app-social-media", i4.SocialMediaComponent, View_SocialMediaComponent_Host_0, {}, {}, []);
export { SocialMediaComponentNgFactory as SocialMediaComponentNgFactory };
