import { DomPortalHost, TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector
} from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-full-screen-map-wrapper',
  templateUrl: './full-screen-map-wrapper.component.html',
  styleUrls: ['./full-screen-map-wrapper.component.scss']
})
export class FullScreenMapWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild('templatePortalContent', { static: false }) templatePortalContent: TemplateRef<unknown>;

  isIos = false;
  isApp = false;
  templatePortal: TemplatePortal;
  bodyPortalHost: DomPortalHost;
  fullscreenMode = false;

  constructor(
    private platform: Platform,
    private _viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.bodyPortalHost = new DomPortalHost(document.body, this.componentFactoryResolver, this.appRef, this.injector);
  }

  ngOnInit(): void {
    this.isApp = this.platform.is('pwa');
    this.isIos = this.platform.is('ios');
  }

  ngAfterViewInit() {
    setTimeout(() => this.setLocalMap(), 0);
  }

  setLocalMap() {
    this.templatePortal = new TemplatePortal(this.templatePortalContent, this._viewContainerRef);
  }

  goFullScreen() {
    this.bodyPortalHost.attach(this.templatePortal);
    this.fullscreenMode = true;
  }

  exitFullScreen() {
    this.bodyPortalHost.detach();
    this.fullscreenMode = false;
    this.setLocalMap();
  }
}
