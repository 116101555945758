/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-top-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./app-top-banner.component";
import * as i5 from "@angular/cdk/layout";
var styles_AppTopBannerComponent = [i0.styles];
var RenderType_AppTopBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppTopBannerComponent, data: { "animation": [{ type: 7, name: "visibleHidden", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { maxHeight: "40vh", padding: "1rem", transform: "scaleY(1)" }, offset: null }, options: undefined }, { type: 0, name: "hidden", styles: { type: 6, styles: { maxHeight: 0, padding: "0 1rem", transform: "scaleY(0)" }, offset: null }, options: undefined }, { type: 1, expr: "hidden => visible", animation: [{ type: 4, styles: null, timings: "0.35s" }], options: null }, { type: 1, expr: "visible => hidden", animation: [{ type: 4, styles: null, timings: "0.35s" }], options: null }], options: {} }] } });
export { RenderType_AppTopBannerComponent as RenderType_AppTopBannerComponent };
export function View_AppTopBannerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 17, "section", [["class", "top-banner"]], [[24, "@visibleHidden", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "mobile-mode": 0 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "top-banner__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "top-banner__actions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "accept mat-flat-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onActionClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["\n      ", "\n    "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(16, 1), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "top-banner"; var currVal_2 = _ck(_v, 4, 0, _co.mobileScreen); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible ? "visible" : "hidden"); _ck(_v, 1, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 14, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("acceptCookies.accept")))); _ck(_v, 14, 0, currVal_3); }); }
export function View_AppTopBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-app-top-banner", ""]], null, null, null, View_AppTopBannerComponent_0, RenderType_AppTopBannerComponent)), i1.ɵdid(1, 114688, null, 0, i4.AppTopBannerComponent, [i5.BreakpointObserver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppTopBannerComponentNgFactory = i1.ɵccf("[app-app-top-banner]", i4.AppTopBannerComponent, View_AppTopBannerComponent_Host_0, { buttonText: "buttonText", visible: "visible" }, { onButtonClick: "onButtonClick" }, ["*"]);
export { AppTopBannerComponentNgFactory as AppTopBannerComponentNgFactory };
