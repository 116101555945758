import { Component, OnInit, HostListener, ViewChild, AfterViewChecked } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatStepper, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/auth/auth.service';
import { ToastNotificationService } from '../../core/listings/toast-notification.service';
import { AccountService } from '@app/core/auth';
import { ForgotFormComponent } from '../forgot-password';
import { RegisterFormComponent } from '../register';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  loginForm = this.formBuilder.group({
    passwordCtrl: ['', Validators.compose([Validators.required])],
    emailCtrl: ['', Validators.compose([Validators.required, Validators.email])]
  });

  targetInput: any = 'input0';
  route: any;
  dialogRef: any;
  customText: string;
  passwordCtrl: FormControl = new FormControl('', Validators.compose([Validators.required]));
  emailCtrl: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.email]));
  keepConnected: FormControl = new FormControl(true, Validators.compose([]));
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  autoClose: boolean;
  hide: boolean = true;
  error: boolean = false;
  forgot: boolean = false;

  constructor(
    private dialog: MatDialog,
    private account: AccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
    private _toaster: ToastNotificationService,
    private data: MatDialogRef<any>,
    private loginDialog: MatDialogRef<LoginFormComponent>,
    private analyticsService: AnalyticsService,
    private routingHelperSrv: RoutingHelperService
  ) {}

  ngOnInit(): void {
    const inputData = this.data._containerInstance._config.data;

    if (inputData.forgot) {
      this.forgot = true;
      this.error = false;
    } else {
      if (typeof inputData === 'string') {
        this.route = inputData.toString();
      } else if (inputData && typeof inputData === 'object') {
        this.route = inputData.url.toString();
        this.customText = inputData.customText;
        this.autoClose = inputData.autoClose;
      }
    }
    document.getElementById('input0').focus();
  }

  dismissAsDialog(route?: any) {
    if (route) {
      this.loginDialog.close();
      this.router.navigateByUrl(route);
    } else {
      this.loginDialog.close();
    }
  }

  submit() {
    const keepUrlOnBadCredentials = /^\/detail\/.*$/g.test(this.router.url); // Is in listing detail Page?

    //submits login credentials
    this.auth
      .submitLoginCredentials(this.emailCtrl.value, this.passwordCtrl.value, keepUrlOnBadCredentials)
      .then(response => {
        if (!response['error']) {
          const serverKeepConnected = response['account'] && response['account'].keepConnected;
          localStorage.setItem('emailSubscribed', 'logged in');
          if (serverKeepConnected !== this.keepConnected.value) {
            // /this.account.setKeepConnected({ keepConnected: this.keepConnected.value });
          }
        }

        return response;
      })
      .then((response: any) => {
        if (response != null && response['error']) {
          if (response.error == 'Bad credentials') {
            this.error = true;
          } else {
            this.dismissAsDialog(null);
          }
        } else {
          if (this.autoClose) {
            this.dismissAsDialog(this.route);
          } else if (this.route === 'my-listing') {
            this.dismissAsDialog(this.route);
            /*  this.openListingMenu(); */
          } else if (this.route) {
            this.dismissAsDialog(this.route);
            /*  this.openListingMenu(); */
          } else {
            this.route = '/user-home';
            this.dismissAsDialog(this.route);
          }
        }
        return response;
      })
      .catch(e => {
        this.showErrorNotification(e);
      });
  }

  showErrorNotification(e: any) {
    this._toaster.error(e);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 13 && this.stepper.selectedIndex === 1 && this.passwordCtrl.valid) {
      this.submit();
    }
  }

  navigateToRegister() {
    this.dialog.closeAll();
    //this.location.replaceState('/'); // clears browser history so they can't navigate with back button
    /*  if (this.router.url !== '/register') {
       this.router.navigateByUrl('/register');
     } */

    this.dialogRef = this.dialog.open(RegisterFormComponent, {
      height: 'auto',
      width: '350px',
      maxWidth: 'none'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (!result) {
        this.dialog.open(LoginFormComponent, { width: '315px' });
      }
    });
  }

  navigateToForgot() {
    this.dialog.closeAll();

    this.dialogRef = this.dialog.open(ForgotFormComponent, {
      height: '315px',
      width: '350px'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.dialog.open(LoginFormComponent, { width: '315px', data: { forgot: result } });
    });
  }

  goToSiilaTermsPage() {
    this.routingHelperSrv.navigateToTermsPage();
  }

  goToSiilaPrivacyPolicyPage() {
    this.routingHelperSrv.navigateToPrivacyPolicyPage();
  }
}
