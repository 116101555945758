<div style="display: flex; width: 100%; justify-content: center; align-items: center;">
  <div style="margin-top: 50px;" *ngIf="readyToUnsubscribe">
    <span>{{ 'notifications.turnoffOK' | translate }}</span>
  </div>
  <div *ngIf="validationCode > 0">
    <div style="margin-top: 50px;" *ngIf="validationCode == 100">
      <span>{{ 'notifications.turnoffExpired' | translate }}</span>
    </div>
    <div style="margin-top: 50px;" *ngIf="validationCode != 100">
      <p [innerHTML]="turnOffBadRequestTxt"></p>
    </div>
  </div>

  <div *ngIf="!readyToUnsubscribe">
    <p [innerHTML]="turnOffBadRequestTxt"></p>
  </div>
</div>
