import { Component, OnInit } from '@angular/core';
import { I18nService } from '@app/core';
import { SrvRecord } from 'dns';

@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  constructor(private i18nService: I18nService) {}
  termsOfUse2: string;
  ownership: string;
  limitations: string;
  indemnification: string;
  jurisdiction: string;
  policies: string;
  services: string;
  information: string;

  ngOnInit() {
    this.termsOfUse2 = this.i18nService.get('global.terms.paragraphs.termsOfUse2');
    this.ownership = this.i18nService.get('global.terms.paragraphs.ownership');
    this.limitations = this.i18nService.get('global.terms.paragraphs.limitations');
    this.indemnification = this.i18nService.get('global.terms.paragraphs.indemnification');
    this.jurisdiction = this.i18nService.get('global.terms.paragraphs.jurisdiction');
    this.policies = this.i18nService.get('global.terms.paragraphs.policies');
    this.services = this.i18nService.get('global.terms.paragraphs.services');
    this.information = this.i18nService.get('global.terms.paragraphs.information');
  }
}
