/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./row-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./row-header.component";
var styles_RowHeaderComponent = [i0.styles];
var RenderType_RowHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RowHeaderComponent, data: {} });
export { RenderType_RowHeaderComponent as RenderType_RowHeaderComponent };
function View_RowHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-label", [["class", "header-label capitalize"]], [[8, "hidden", 0]], null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(1, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "unset-cap": 0 }), (_l()(), i1.ɵted(5, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "header-label capitalize"; var currVal_2 = _ck(_v, 4, 0, _co.sameCharacters); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.title; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.title; _ck(_v, 5, 0, currVal_3); }); }
function View_RowHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "flex-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "header-label transform bold-weight"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "header-label-desc transform"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("global.providerHeader")); _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.title; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("global.providerSubtitle")); _ck(_v, 7, 0, currVal_3); }); }
export function View_RowHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-item", [["class", "standard-shadow"], ["style", "border-top:1px solid gray;"]], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "unset-cap": 0 }), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RowHeaderComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RowHeaderComponent_2)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "standard-shadow"; var currVal_1 = _ck(_v, 4, 0, _co.sameCharacters); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = !_co.providers; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.providers; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_RowHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-row-header", [], null, null, null, View_RowHeaderComponent_0, RenderType_RowHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i6.RowHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RowHeaderComponentNgFactory = i1.ɵccf("app-row-header", i6.RowHeaderComponent, View_RowHeaderComponent_Host_0, { title: "title", providers: "providers", sameCharacters: "sameCharacters" }, {}, []);
export { RowHeaderComponentNgFactory as RowHeaderComponentNgFactory };
