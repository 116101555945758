import { Injectable } from '@angular/core';
import { HttpService } from '@app/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonGenericService } from '@app/core/common.service';

@Injectable()
export class SiilaChatSupportService {
  messageData = new BehaviorSubject<any>({
    messages: []
  });

  //Makes api requests to conversation service
  constructor(private http: HttpService, private commonSvc: CommonGenericService) {}

  async populateMessageData() {
    await this.getSupportMessages(0, 10).then((messageData: { messages: Array<any> }) => {
      this.messageData.next(messageData);
    });
  }

  appendMessages(newMessage: any) {
    let currentMessageData = this.messageData.getValue();
    currentMessageData.messages = [...currentMessageData.messages, newMessage];
    currentMessageData.messages = this.commonSvc.sortDateDesc(currentMessageData.messages, 'createdAt');
    this.messageData.next(currentMessageData);
  }

  getSupportMessages(index?: number, count?: number): Promise<object> {
    return this.http
      .get(`/api/conversationWithSupport?messageIndex=${index || 0}&messageCount=${count || 10}`)
      .pipe(
        map((messageData: { messages: Array<any> }) => ({
          ...messageData,
          messages: messageData.messages ? this.commonSvc.sortDateDesc(messageData.messages, 'createdAt') : []
        }))
      )
      .toPromise();
  }

  refreshUserChat(userId: any) {
    return this.http.get(`/api/userChat/${userId}/refresh`).toPromise();
  }

  getUsernameInfo(userId: string) {
    return this.http.get(`api/users/fast/${userId}`).toPromise();
  }

  updateUnreadMessages(userId: string, conversationId: string) {
    return this.http.put(`api/conversation/${conversationId}/unread/${userId}`, {}).toPromise();
  }
}
