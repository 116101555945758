import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export function validatePhoneNumber(validateIsoCode: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const isRequired: boolean = control.errors && control.errors.required;
    const formControlValue: any = control.value;
    let number: any;

    try {
      number = formControlValue.number ? formControlValue.number : formControlValue;
      const countryCode = formControlValue.countryCode ? formControlValue.countryCode : 'none';

      if (!validateIsoCode && countryCode === 'none') {
        return null;
      }

      const phoneNumber = PhoneNumberUtil.getInstance().parse(number, countryCode);
      if (formControlValue) {
        if (!phoneNumber) {
          return { invalidNumber: true };
        } else {
          if (!PhoneNumberUtil.getInstance().isValidNumberForRegion(phoneNumber, countryCode)) {
            return { invalidNumberForRegion: true };
          }
        }
      }
    } catch (e) {
      if (isRequired) {
        return { phoneRequired: false };
      }
    }

    return null;
  };
}

export function formatPhoneNumberWithoutRegion(phone: string) {
  const phoneNumber = PhoneNumberUtil.getInstance().parse(phone);
  if (phoneNumber) {
    return PhoneNumberUtil.getInstance().format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
  }
  return null;
}
