import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { faChevronLeft, faChevronRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IonSlides, PopoverController, NavParams } from '@ionic/angular';
var ImageGalleryComponent = /** @class */ (function () {
    function ImageGalleryComponent(i18n, popoverCtrl, platformConfigHelper, imageService, navParams) {
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.navParams = navParams;
        this.listingType = null;
        this.description = null;
        this.nestedFloating = false;
        this.currentPhotoIndex = 0;
        this.prevCurrentPhotoIndex = 0;
        this.fromPopup = false;
        this.isMobile = false;
    }
    ImageGalleryComponent.prototype.ngOnInit = function () {
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.closeIcon = faTimesCircle;
        this.noPhotos = false;
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        if (this.navParams && this.navParams.data) {
            if (this.navParams.data.fromPopup) {
                this.fromPopup = true;
                this.photos = this.navParams.data.photos;
            }
            if (this.navParams.data.topImage) {
                this.topImage = this.navParams.data.topImage;
            }
        }
        if (!this.photos || this.photos.length == 0) {
            this.galleryImgs = [];
            this.galleryImgs.push(this.defaultImage);
            this.noPhotos = true;
        }
        else {
            this.galleryImgs = tslib_1.__spread(this.photos);
        }
        this.leftArrow = faChevronLeft;
        this.rightArrow = faChevronRight;
        this.sliderPreviewOptions = {
            slidesPerView: 5,
            spaceBetween: 16
        };
    };
    ImageGalleryComponent.prototype.getCurrentFromSlider = function () {
        return this.currentPhotoIndex + 1 + " " + this.i18n.get('global.of') + " " + this.photos.length;
    };
    ImageGalleryComponent.prototype.isStartOfSlide = function () {
        return this.currentPhotoIndex <= 0;
    };
    ImageGalleryComponent.prototype.isEndOfSlide = function () {
        return this.currentPhotoIndex === this.galleryImgs.length - 1;
    };
    ImageGalleryComponent.prototype.getCroppedOrOriginalImage = function (spotImg) {
        if (spotImg) {
            if (spotImg.croppedImageId) {
                return spotImg.croppedImageId;
            }
            return spotImg.imageId;
        }
    };
    ImageGalleryComponent.prototype.slideToIndex = function (event, index) {
        event.stopPropagation();
        this.currentPhotoIndex += index;
        this.syncSliders();
    };
    ImageGalleryComponent.prototype.syncSlidersFromPreview = function (event, index) {
        event.stopPropagation();
        this.currentPhotoIndex = index;
        this.syncSliders();
    };
    ImageGalleryComponent.prototype.syncSliders = function () {
        this.photoSlider.slideTo(this.currentPhotoIndex);
        this.previewSlider.slideTo(this.currentPhotoIndex);
    };
    ImageGalleryComponent.prototype.getPreviousPhoto = function () {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.galleryImgs.length);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    };
    ImageGalleryComponent.prototype.updatePhotoIndex = function () {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            _this.currentPhotoIndex = index;
            _this.syncSliders();
        });
    };
    ImageGalleryComponent.prototype.getNextPhoto = function ($event) {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            if (index < _this.currentPhotoIndex) {
                _this.currentPhotoIndex = index;
                _this.syncSliders();
            }
            else {
                if ($event.type === 'click') {
                    _this.photoSlider.slideNext();
                    _this.updatePhotoIndex();
                }
                else {
                    _this.updatePhotoIndex();
                }
            }
        });
    };
    ImageGalleryComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    ImageGalleryComponent.prototype.closeModal = function () {
        this.popoverCtrl.dismiss();
    };
    ImageGalleryComponent.prototype.cssImgMobile = function () {
        return {
            width: window.screen.width + 'px',
            height: (window.screen.width / 4) * 3 + 'px',
            'margin-top': this.topImage
        };
    };
    return ImageGalleryComponent;
}());
export { ImageGalleryComponent };
