import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationService } from '../services/notification.service';
import { I18nService } from '../../core/i18n.service';
var SendNotificationComponent = /** @class */ (function () {
    function SendNotificationComponent(dialog, data, notificationService, breakpointObserver, i18n) {
        var _this = this;
        this.dialog = dialog;
        this.data = data;
        this.notificationService = notificationService;
        this.breakpointObserver = breakpointObserver;
        this.i18n = i18n;
        this.searching = false;
        this.isMobile = false;
        this.predefinedId = null;
        this.yesNo = true;
        this.errorMaxLength = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    SendNotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.descriptionFieldOptions = {
            columnLabel: 'notifications.messageNotification',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
        this.notificationService.predefinedNotification().then(function (resp) {
            var e_1, _a;
            _this.notificationOptions = resp;
            try {
                for (var _b = tslib_1.__values(_this.notificationOptions), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var option = _c.value;
                    option.name = _this.i18n.getTranslation(option.name);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    SendNotificationComponent.prototype.close = function () {
        this.dialog.close();
    };
    SendNotificationComponent.prototype.sendNotification = function () {
        var _this = this;
        this.searching = true;
        this.data.notify.predefinedNotificationId = this.predefinedId;
        this.notificationService.sendNotification(this.data.notify).then(function () {
            _this.dialog.close(true);
        });
    };
    SendNotificationComponent.prototype.descriptionChange = function (value) {
        var _this = this;
        this.validateMaxLength(value);
        if (value.description.length > 0) {
            var mergedObject_1 = {};
            var nullValues_1 = false;
            Object.keys(value.description).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.description[key];
                    if (obj['en'] === '' || obj['en'] === null || obj['pt-br'] === '') {
                        nullValues_1 = true;
                    }
                    else {
                        mergedObject_1 = Object.assign(mergedObject_1, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_1 ? (this.data.notify.content = '') : (this.data.notify.content = JSON.stringify(mergedObject_1));
        }
    };
    SendNotificationComponent.prototype.validateMaxLength = function (value) {
        var length1 = value.description[0] ? JSON.stringify(value.description[0]).length : '';
        var length2 = value.description[1] ? JSON.stringify(value.description[1]).length : '';
        if (length1 > 120 || length2 > 120) {
            this.errorMaxLength = true;
        }
        else {
            this.errorMaxLength = false;
        }
    };
    SendNotificationComponent.prototype.yesNotification = function () {
        this.yesNo = false;
    };
    SendNotificationComponent.prototype.noNotification = function () {
        this.dialog.close('close');
    };
    return SendNotificationComponent;
}());
export { SendNotificationComponent };
