import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material';
import { CoreModule } from '@app/core';
import { SearchNavigationModule } from '@app/search-navigation/search-navigation.module';
import { SharedModule } from '@app/shared';
import { ShellModule } from '@app/shell/shell.module';
import { HomeComponent } from '@app/home/components/home/home.component';
import { CustomKeywordSearchComponent } from '@app/home/components/keyword-search/custom-keyword-search.component';
import { AppTabButtonComponent } from '@app/home/components/tab-button/tab-button.component';
import { ClickOutsideDirective } from '@app/home/directives/click-outside.directive';
import { CustomHighlightPipe } from '@app/home/pipes/custom-highlight.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetaModule } from '@ngx-meta/core';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { PaginationModule } from 'ngx-bootstrap';
import { QuillModule } from 'ngx-quill';
import { HomeRoutingModule } from './home.routing.module';
import { HomeSearchControlComponent } from './components/home-search-control/home-search-control.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    FormsModule,
    InViewportModule,
    IonicModule,
    MetaModule,
    QuillModule.forRoot(),
    SharedModule,
    ShellModule,
    TranslateModule,
    HomeRoutingModule,
    FontAwesomeModule,
    SearchNavigationModule,
    PaginationModule.forRoot(),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    NgbModule,
    MatTabsModule
  ],
  entryComponents: [],
  declarations: [
    HomeComponent,
    AppTabButtonComponent,
    CustomKeywordSearchComponent,
    HomeSearchControlComponent,
    ClickOutsideDirective,
    CustomHighlightPipe
  ],
  exports: [],
  providers: []
})
export class HomeModule {}
