/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./complete-profile-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./complete-profile-modal.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/router";
var styles_CompleteProfileComponent = [i0.styles];
var RenderType_CompleteProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompleteProfileComponent, data: {} });
export { RenderType_CompleteProfileComponent as RenderType_CompleteProfileComponent };
export function View_CompleteProfileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "font-weight-bold px-3 text-center mt-3"], ["translate", "global.register.joinCommunity"]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "flex-row align-end checkbox-label-margin text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["class", "siila-submit login-btn block-btn"], ["mat-raised-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToMyAccount() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(8, 0, ["\n    ", "\n  "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(10, 1), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "text-center mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["class", "close-modal"], ["role", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "u", [["translate", "global.register.maybeLater"]], null, null, null, null, null)), i1.ɵdid(17, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "global.register.joinCommunity"; _ck(_v, 2, 0, currVal_0); var currVal_4 = "global.register.maybeLater"; _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 7).disabled || null); var currVal_2 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("global.register.completeProfile")))); _ck(_v, 8, 0, currVal_3); }); }
export function View_CompleteProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-complete-profile", [], null, null, null, View_CompleteProfileComponent_0, RenderType_CompleteProfileComponent)), i1.ɵdid(1, 49152, null, 0, i8.CompleteProfileComponent, [i9.MatDialogRef, i10.Router], null, null)], null, null); }
var CompleteProfileComponentNgFactory = i1.ɵccf("app-complete-profile", i8.CompleteProfileComponent, View_CompleteProfileComponent_Host_0, {}, {}, []);
export { CompleteProfileComponentNgFactory as CompleteProfileComponentNgFactory };
