<!-- Range Filters-->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.rentableArea">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.rentableArea"
      (click)="checkboxValue('rentableArea', 'rentableArea')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.rentableArea"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.rentableArea"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.rentableArea"
        [max]="staticMinMax.rentableArea.max"
        ticks="false"
        min="0"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>

    <ion-item [disabled]="!checkboxValues.rentableArea" class="item">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div>
            <ion-input
              class="input-style item"
              [ngClass]="{ inputBorder: checkboxValues.rentableArea }"
              type="number"
              (change)="checkValues('rentableArea')"
              [(ngModel)]="rangeModelValues.rentableArea.lower"
              [max]="staticMinMax.rentableArea.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span style="text-transform: lowercase;">m<sup>2</sup></span>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.rentableArea }"
              type="number"
              (change)="checkValues('rentableArea')"
              [(ngModel)]="rangeModelValues.rentableArea.upper"
              [max]="staticMinMax.rentableArea.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span *ngIf="rangeModelValues?.rentableArea.upper === staticMinMax.rentableArea.max">+</span>
            <span style="text-transform: lowercase;">&nbsp;m<sup>2</sup></span>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<ion-row
  class="padding-10"
  *ngIf="
    rangeModelValues && rangeModelValues.offeringPrice && (listingLeaseOrSale == 'S' || listingLeaseOrSale == null)
  "
>
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.offeringPrice"
      (click)="checkboxValue('offeringPrice', 'offeringPrice')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.offeringPrice"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        dual-knobs="true"
        [disabled]="!checkboxValues.offeringPrice"
        pin="false"
        [(ngModel)]="rangeModelValues.offeringPrice"
        [max]="staticMinMax.offeringPrice.max"
        ticks="false"
        min="0"
        step="3"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.offeringPrice">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div class="span-top" style="margin-right: 4px;">
            <span>R$</span>
          </div>
          <div style="float: left;">
            <ion-input
              class="input-style-price"
              [ngClass]="{ inputBorder: checkboxValues.offeringPrice }"
              type="number"
              (change)="checkValues('offeringPrice')"
              [(ngModel)]="rangeModelValues.offeringPrice.lower"
              [max]="staticMinMax.offeringPrice.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="span-top input-pading-price">
            <span>R$</span>
          </div>
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.offeringPrice }"
              type="number"
              (change)="checkValues('offeringPrice')"
              [(ngModel)]="rangeModelValues.offeringPrice.upper"
              [max]="staticMinMax.offeringPrice.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span *ngIf="rangeModelValues?.offeringPrice.upper === staticMinMax.offeringPrice.max">+</span>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<ion-row
  class="padding-10"
  *ngIf="
    rangeModelValues &&
    rangeModelValues.offeringPricePerArea &&
    (listingLeaseOrSale == 'S' || listingLeaseOrSale == null)
  "
>
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.offeringPricePerArea"
      (click)="checkboxValue('offeringPricePerArea', 'offeringPricePerArea')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.offeringPrice"></ion-label>
    <span class="pre-space">{{ 'global.addtSearchFilters.totalPricerPerM' | translate }}<sup>2</sup></span>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.offeringPricePerArea"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.offeringPricePerArea"
        [max]="staticMinMax.offeringPricePerArea.max"
        ticks="false"
        min="0"
        step="2"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.offeringPricePerArea">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div class="span-top" style="margin-right: 4px;">
            <span>R$</span>
          </div>
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.offeringPricePerArea }"
              type="number"
              (change)="checkValues('offeringPricePerArea')"
              [(ngModel)]="rangeModelValues.offeringPricePerArea.lower"
              [max]="staticMinMax.offeringPricePerArea.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="span-top input-pading-price">
            <span>R$</span>
          </div>
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.offeringPricePerArea }"
              type="number"
              (change)="checkValues('offeringPricePerArea')"
              [(ngModel)]="rangeModelValues.offeringPricePerArea.upper"
              [max]="staticMinMax.offeringPricePerArea.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span *ngIf="rangeModelValues?.offeringPricePerArea.upper === staticMinMax.offeringPricePerArea.max"
              >+</span
            >
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!--Monthly Rent -->
<ion-row
  class="padding-10"
  style="margin-bottom: 30px;"
  *ngIf="rangeModelValues && rangeModelValues.monthlyRent && (listingLeaseOrSale == 'L' || listingLeaseOrSale == null)"
>
  <ion-col size="12" class="flex-row-filter">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.monthlyRent"
      (click)="checkboxValue('monthlyRent', 'monthlyRent')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.monthlyRent"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.monthlyRent"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.monthlyRent"
        ticks="false"
        min="0"
        [max]="staticMinMax.monthlyRent.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.monthlyRent">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div class="span-top" style="margin-right: 4px;">
            <span>R$</span>
          </div>
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.monthlyRent }"
              type="number"
              (change)="checkValues('monthlyRent')"
              [(ngModel)]="rangeModelValues.monthlyRent.lower"
              [max]="staticMinMax.monthlyRent.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="span-top input-pading-price">
            <span>R$</span>
          </div>
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.monthlyRent }"
              type="number"
              (change)="checkValues('monthlyRent')"
              [(ngModel)]="rangeModelValues.monthlyRent.upper"
              [max]="staticMinMax.monthlyRent.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span *ngIf="rangeModelValues?.monthlyRent.upper === staticMinMax.monthlyRent.max">+</span>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!--Monthly Rent Per Area-->
<ion-row
  class="padding-10"
  *ngIf="
    rangeModelValues && rangeModelValues.monthlyRentPerArea && (listingLeaseOrSale == 'L' || listingLeaseOrSale == null)
  "
>
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.monthlyRentPerArea"
      (click)="checkboxValue('monthlyRentPerArea', 'monthlyRentPerArea')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.monthlyRent"></ion-label>
    <span class="pre-space">{{ 'global.addtSearchFilters.totalPricerPerM' | translate }}<sup>2</sup></span>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.monthlyRentPerArea"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.monthlyRentPerArea"
        [max]="staticMinMax.monthlyRentPerArea.max"
        ticks="false"
        min="0"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.monthlyRentPerArea">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div class="span-top" style="margin-right: 4px;">
            <span>R$</span>
          </div>
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.monthlyRentPerArea }"
              type="number"
              (change)="checkValues('monthlyRentPerArea')"
              [(ngModel)]="rangeModelValues.monthlyRentPerArea.lower"
              [max]="staticMinMax.monthlyRentPerArea.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="span-top input-pading-price">
            <span>R$</span>
          </div>
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.monthlyRentPerArea }"
              type="number"
              (change)="checkValues('monthlyRentPerArea')"
              [(ngModel)]="rangeModelValues.monthlyRentPerArea.upper"
              [max]="staticMinMax.monthlyRentPerArea.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span *ngIf="rangeModelValues?.monthlyRentPerArea.upper === staticMinMax.monthlyRentPerArea.max">+</span>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!-- Ceiling Height -->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.ceilingHeight">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.ceilingHeight"
      (click)="checkboxValue('ceilingHeight', 'ceilingHeight')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.ceilingHeight"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.ceilingHeight"
        dual-knobs="true"
        pin="false"
        ticks="false"
        [(ngModel)]="rangeModelValues.ceilingHeight"
        min="0"
        [max]="staticMinMax.ceilingHeight.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.ceilingHeight">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div>
            <ion-input
              class="input-style"
              [ngClass]="{ inputBorder: checkboxValues.ceilingHeight }"
              style="width: 34px !important;"
              type="number"
              (change)="checkValues('ceilingHeight')"
              [(ngModel)]="rangeModelValues.ceilingHeight.lower"
              [max]="staticMinMax.ceilingHeight.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span style="text-transform: lowercase;">m</span>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.ceilingHeight }"
              type="number"
              (change)="checkValues('ceilingHeight')"
              [(ngModel)]="rangeModelValues.ceilingHeight.upper"
              [max]="staticMinMax.ceilingHeight.max"
              min="0"
            >
            </ion-input>
          </div>
          <div class="span-top">
            <span *ngIf="rangeModelValues?.ceilingHeight.upper === staticMinMax.ceilingHeight.max">+</span>
            <span style="text-transform: lowercase;">&nbsp;m</span>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!--Number of Parking Spots-->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.parkingSpaces">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.hasParkingSpaces"
      (click)="checkboxValue('hasParkingSpaces', 'parkingSpaces')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.hasParkingSpaces"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.hasParkingSpaces"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.parkingSpaces"
        ticks="false"
        min="0"
        [max]="staticMinMax.parkingSpaces.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.hasParkingSpaces">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.hasParkingSpaces }"
              type="number"
              (change)="checkValues('parkingSpaces')"
              [(ngModel)]="rangeModelValues.parkingSpaces.lower"
              [max]="staticMinMax.parkingSpaces.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.hasParkingSpaces }"
              type="number"
              (change)="checkValues('parkingSpaces')"
              [(ngModel)]="rangeModelValues.parkingSpaces.upper"
              [max]="staticMinMax.parkingSpaces.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!-- Dock Ratio -->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.dockRatio">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.hasDockRatio"
      (click)="checkboxValue('hasDockRatio', 'dockRatio')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.hasDockRatio"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        [disabled]="!checkboxValues.hasDockRatio"
        color="secondary"
        dual-knobs="true"
        pin="false"
        ticks="false"
        [(ngModel)]="rangeModelValues.dockRatio"
        min="0"
        [max]="staticMinMax.dockRatio.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.hasDockRatio">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.hasDockRatio }"
              type="number"
              (change)="checkValues('dockRatio')"
              [(ngModel)]="rangeModelValues.dockRatio.lower"
              [max]="staticMinMax.dockRatio.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.hasDockRatio }"
              type="number"
              (change)="checkValues('dockRatio')"
              [(ngModel)]="rangeModelValues.dockRatio.upper"
              [max]="staticMinMax.dockRatio.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>
<!--Load Capacity-->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.loadCapacity">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.hasLoadCapacity"
      (click)="checkboxValue('hasLoadCapacity', 'loadCapacity')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.hasLoadCapacity"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.hasLoadCapacity"
        dual-knobs="true"
        pin="false"
        ticks="false"
        [(ngModel)]="rangeModelValues.loadCapacity"
        min="0"
        [max]="staticMinMax.loadCapacity.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.hasLoadCapacity">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.hasLoadCapacity }"
              type="number"
              (change)="checkValues('loadCapacity')"
              [(ngModel)]="rangeModelValues.loadCapacity.lower"
              [max]="staticMinMax.loadCapacity.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.hasLoadCapacity }"
              type="number"
              (change)="checkValues('loadCapacity')"
              [(ngModel)]="rangeModelValues.loadCapacity.upper"
              [max]="staticMinMax.loadCapacity.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!-- Number of Truck Parking Spaces-->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.truckParkingSpaces">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.trckParkingSpaces"
      (click)="checkboxValue('trckParkingSpaces', 'truckParkingSpaces')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.trckParkingSpaces"></ion-label>
  </ion-col>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.trckParkingSpaces"
        dual-knobs="true"
        pin="false"
        ticks="false"
        [(ngModel)]="rangeModelValues.truckParkingSpaces"
        min="0"
        [max]="staticMinMax.truckParkingSpaces.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.trckParkingSpaces">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.trckParkingSpaces }"
              type="number"
              (change)="checkValues('truckParkingSpaces')"
              [(ngModel)]="rangeModelValues.truckParkingSpaces.lower"
              [max]="staticMinMax.truckParkingSpaces.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.trckParkingSpaces }"
              type="number"
              (change)="checkValues('truckParkingSpaces')"
              [(ngModel)]="rangeModelValues.truckParkingSpaces.upper"
              [max]="staticMinMax.truckParkingSpaces.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!--Number of Parking Spots-->
<ion-row class="padding-10" *ngIf="rangeModelValues && rangeModelValues.carParkingSpaces">
  <ion-col class="flex-row-filter" size="12">
    <ion-checkbox
      class="margin-right-10"
      [(ngModel)]="checkboxValues.parkingSpots"
      (click)="checkboxValue('parkingSpots', 'carParkingSpaces')"
    ></ion-checkbox>
    <ion-label class="weight" translate="global.addtSearchFilters.parkingSpots"></ion-label>
  </ion-col>
  <!-- translate="global.addtSearchFilters.parkingSpots" -->
  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.parkingSpots"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.carParkingSpaces"
        ticks="false"
        min="0"
        [max]="staticMinMax.carParkingSpaces.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-item [disabled]="!checkboxValues.parkingSpots">
      <div class="col-md-12" style="padding: 0%;">
        <div class="col-md-6 content-left">
          <div style="float: left;">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.parkingSpots }"
              type="number"
              (change)="checkValues('carParkingSpaces')"
              [(ngModel)]="rangeModelValues.carParkingSpaces.lower"
              [max]="staticMinMax.carParkingSpaces.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
        <div class="col-md-6 content-right">
          <div class="content-input">
            <ion-input
              [ngClass]="{ inputBorder: checkboxValues.parkingSpots }"
              type="number"
              (change)="checkValues('carParkingSpaces')"
              [(ngModel)]="rangeModelValues.carParkingSpaces.upper"
              [max]="staticMinMax.carParkingSpaces.max"
              min="0"
            >
            </ion-input>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-list>
</ion-row>

<!--Bay Size
<ion-row
  class="padding-10"
  style="margin-bottom: 30px;"
  *ngIf="rangeModelValues && (rangeModelValues.baySize.upper || rangeModelValues.baySize.upper === 0)"
>

  <ion-list style="width: 100%" lines="none">
    <ion-item>
      <ion-range
        color="secondary"
        [disabled]="!checkboxValues.baySize"
        dual-knobs="true"
        pin="false"
        [(ngModel)]="rangeModelValues.baySize"
        ticks="false"
        min="0"
        [max]="staticMinMax.baySize.max"
        step="1"
        snaps="false"
      >
      </ion-range>
    </ion-item>
    <ion-label style="float: left; padding-left:25px;"
      >{{ rangeModelValues.baySize.lower | localNumber: '1.0-0' }}
    </ion-label>
    <ion-label class="label-padding">{{ rangeModelValues.baySize.upper | localNumber: '1.0-0' }} </ion-label>
  </ion-list>
</ion-row>
-->
