import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface TdMessageEvent {
  conversationId: string;
  content: string;
  user?: string;
  broadcast?: boolean;
}

@Injectable()
export class SiilaChatService {
  connected$: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor() {} // private socket: Socket

  sendMessage(msg: TdMessageEvent) {
    //this.socket.emit('message', msg, function(result: any) {
    //Required callback
    //});
  }

  getMessage() {
    //return this.socket.fromEvent('message').pipe(map((event: any) => event.data));
  }
}
