<ion-row style="height:100%;position:relative;" *ngIf="messageData">
  <siila-chat-view
    style="width:100%;background:rgba(54, 94, 115)"
    [messages]="messageData.messages"
    [userFullname]="userFullname"
    [userProfileImage]="userProfileImage"
    [spotAccountImage]="spotAccountImage"
    [spotAccountName]="spotAccountFullname"
    [currentUser]="currentUser"
  >
  </siila-chat-view>
  <div class="new-message-input">
    <div class="message-input-container">
      <ion-textarea
        #inputMessage
        autofocus="true"
        class="inset-border"
        [(ngModel)]="messageInput"
        type="text"
        spellcheck="true"
        (ionFocus)="scrollOnFocus(inputMessage)"
      >
      </ion-textarea>
      <ion-button
        style="padding-left:5px;"
        color="secondary"
        [disabled]="!messageInput"
        (click)="sendMessage()"
        style="z-index:999999;"
      >
        <ion-icon color="light" name="send"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-row>
