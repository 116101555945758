/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-image.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng-lazyload-image";
import * as i3 from "./preview-image.component";
import * as i4 from "@angular/router";
import * as i5 from "../core/helpers/platform-helper.service";
import * as i6 from "../core/image.service";
var styles_PreviewImageComponent = [i0.styles];
var RenderType_PreviewImageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewImageComponent, data: {} });
export { RenderType_PreviewImageComponent as RenderType_PreviewImageComponent };
export function View_PreviewImageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "preview-image-container d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "img", [["alt", "preview-image"]], null, null, null, null, null)), i1.ɵdid(3, 1720320, null, 0, i2.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageSrc; var currVal_1 = _co.defaultImage; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_PreviewImageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-image", [], null, null, null, View_PreviewImageComponent_0, RenderType_PreviewImageComponent)), i1.ɵdid(1, 114688, null, 0, i3.PreviewImageComponent, [i4.ActivatedRoute, i5.PlatformHelperService, i6.ImageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewImageComponentNgFactory = i1.ɵccf("app-preview-image", i3.PreviewImageComponent, View_PreviewImageComponent_Host_0, {}, {}, []);
export { PreviewImageComponentNgFactory as PreviewImageComponentNgFactory };
