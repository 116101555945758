import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { I18nService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { SpacesParameters } from '@app/models/spot-buildings/spot-available-spaces';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'spot-spaces-buildings',
  templateUrl: './spot-spaces-buildings.component.html',
  styleUrls: ['./spot-spaces-buildings.component.scss']
})
export class SpotSpacesBuildingsComponent implements OnInit {
  @Input('SpotBuildingsResponse') SpotBuildingsResponse: any;
  spacesParameters: SpacesParameters[];
  fileName: string = null;
  fileUpload: any;
  loading: boolean = false;
  formData: FormGroup;

  constructor(
    private createSpotBuildingsService: CreateSpotBuildingsService,
    private popOverCtrlHelper: PopoverCtrlHelper,
    private alertCtrl: AlertController,
    private i18n: I18nService,
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    this.spacesParameters = [];
  }

  ngOnChanges(changes: any) {
    if (this.SpotBuildingsResponse) {
      this.createSpotBuildingsService
        .getSpaceConditions(this.SpotBuildingsResponse.id)
        .then((response: SpacesParameters[]) => {
          this.spacesParameters = response;
        });
    }
  }

  async downloadExcel() {
    this.loading = true;
    let url: any = await this.createSpotBuildingsService.getUrlExcel(this.SpotBuildingsResponse.id);
    this.createSpotBuildingsService.downloadExcel(url.fileName);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  async fileChangedEvent(event: any) {
    this.fileUpload = event.srcElement.files[0];
    this.fileName = event.srcElement.files[0].name;
  }

  processTemplate() {
    this.loading = true;
    this.createSpotBuildingsService
      .createSpacesBuilding(this.fileUpload, this.SpotBuildingsResponse.id)
      .then((resp: any) => {
        this.createSpotBuildingsService
          .getSpaceConditions(this.SpotBuildingsResponse.id)
          .then((response: SpacesParameters[]) => {
            this.spacesParameters = null;
            this.spacesParameters = response;
            this.loading = false;
            let inputFile: any = document.getElementById('inputImageFile');
            inputFile.value = null;
            this.fileUpload = null;
            this.fileName = null;
            if (resp && resp.messages.length > 0) {
              this.showAlert(`\n\n${resp.messages[0]}`);
            } else {
              this.showAlert('\n\nProcess completed');
            }
          });
      })
      .catch();
  }

  async seeMoreAction(parameters: any) {
    await this.popOverCtrlHelper.openImageUploadSpaceBuilding(parameters.id, (popoverData: any) => {
      this.createSpotBuildingsService
        .getSpaceConditions(this.SpotBuildingsResponse.id)
        .then((response: SpacesParameters[]) => {
          this.spacesParameters = null;
          this.spacesParameters = response;
        });
    });
  }

  async showAlert(alertMsg: string) {
    const alert = await this.alertCtrl
      .create({
        header: this.i18n.get('global.form.sendMsgToAgent.notification'),
        subHeader: alertMsg,
        buttons: [
          {
            text: this.i18n.get('global.form.sendMsgToAgent.ok'),
            handler: () => {
              if (this.formData.valid) {
                this.popoverCtrl.dismiss();
              }
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
  }
}
