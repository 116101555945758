import { Injectable } from '@angular/core';
import { CommonGenericService } from './common.service';
import { environment } from 'environments/environment';
import { MapIcons } from '../models/asset-server.model';
import { of } from 'rxjs';

@Injectable()
export class GenericMapService {
  private GOOGLE_API_KEY: string = 'AIzaSyBzR4bgsFqSQZXpQQj6ePNlI3ERTJ18M7I';
  public BLUE_PIN: string = `\/assets/maps/newlocationdefault2.png`;
  public ORANGE_PIN: string = `\/assets/maps/newlocationactive2.png`;
  private imageRequestProperty: any = {
    imageUrl: 'https://maps.googleapis.com/maps/api/staticmap',
    params: null
  };

  constructor(private commonSvc: CommonGenericService) {}

  private isAllImageParamAvailable(params: any): boolean {
    return [!this.commonSvc.isObjectEmpty(params), params.marker, params.zoom, params.size].every(
      condition => condition
    );
  }

  private generateStaticImageUrl(request: any): string {
    let url: string = request.imageUrl;
    Object.keys(request.params).forEach(key => {
      url += request.params[key];
    });
    return url;
  }

  private generateStaticMapImage(options: any) {
    const isAllRequireOptionExist = this.isAllImageParamAvailable(options);
    if (isAllRequireOptionExist) {
      let staticImageProps: any = Object.assign({}, this.imageRequestProperty);
      staticImageProps.params = {
        marker: `?markers=icon:${environment.spotServerUrl}/${MapIcons.BLUE_MARKER}|size:small|${options.marker.lat},${options.marker.lng}`,
        zoom: `&zoom=${options.zoom}`,
        size: `&size=${options.size}`,
        scale: `&scale=${options.scale}`,
        mapType: `&maptype=${options.mapType}`,
        key: `&key=${this.GOOGLE_API_KEY}`
      };
      return this.generateStaticImageUrl(staticImageProps);
    }
    return `${environment.spotServerUrl}/${MapIcons.DEFAULT_MAP_IMAGE}`;
  }

  private calcAngleOffset(angle: number, earthDegree: number) {
    let offsetAngle = 0;

    if (angle < 0) {
      offsetAngle = angle + earthDegree;
    } else if (angle === 0) {
      offsetAngle = 1;
    } else {
      offsetAngle = angle;
    }
    return offsetAngle;
  }

  public calculateZoomLevel(map: any, bounds: any) {
    const EARTH_DEGREE = 360;
    const GLOBE_WIDTH = 256;
    let mapPixelWidth = map.getDiv().offsetWidth;
    let eastBound = bounds.getNorthEast().lng();
    let westBound = bounds.getSouthWest().lng();
    let angle = eastBound - westBound;
    let calculatedAngle = this.calcAngleOffset(angle, EARTH_DEGREE);
    let zoomLevel = Math.round(Math.log((mapPixelWidth * 360) / calculatedAngle / GLOBE_WIDTH) / Math.LN2);
    return zoomLevel;
  }

  public generateStatiMapRequest(property: any) {
    let imageParams = {
      marker: { lat: property.latitude, lng: property.longitude },
      zoom: 15,
      size: '500x296',
      mapType: 'roadMap',
      scale: 1
    };
    return this.generateStaticMapImage(imageParams);
  }

  public generateStaticSatelliteMap(latitude: any, longitude: any, customSize: string, scale: number = 1) {
    let imageParams = {
      marker: { lat: latitude, lng: longitude },
      zoom: 15,
      size: customSize,
      mapType: 'roadMap',
      scale: scale
    };
    return this.generateStaticMapImage(imageParams);
  }

  public isListingDetailMarker(markers: any[], attr: string) {
    return markers.some(item => item.hasOwnProperty(attr) && item[attr]);
  }

  public getAllNearbyListing(markers: any[], attr: string) {
    return markers.reduce((accumulator, item) => {
      if (!item.hasOwnProperty(attr)) {
        accumulator.push(item);
      }
      return accumulator;
    }, []);
  }

  public calcNearbyListingZoomLevel(currentZoom: number, minZoomLevel: number) {
    if (currentZoom < minZoomLevel) {
      let offset: number = minZoomLevel - currentZoom;
      return offset + currentZoom;
    }
    return currentZoom;
  }
}
