import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, AlertController } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { I18nService } from '@app/core';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { EmailService } from '@app/core/mail/email.service';
import { AccountService } from '@app/core/auth/account.service';
var AgentNotificationComponent = /** @class */ (function () {
    function AgentNotificationComponent(navParams, userActivityService, emailService, alertCtrl, i18nService, accountService) {
        this.navParams = navParams;
        this.userActivityService = userActivityService;
        this.emailService = emailService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.accountService = accountService;
    }
    AgentNotificationComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.getPopData();
    };
    AgentNotificationComponent.prototype.createForm = function () {
        this.formData = new FormGroup({
            name: new FormControl(''),
            phone: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
            note: new FormControl('', Validators.required)
        });
        this.noteFormCtrl = this.formData.controls['note'];
    };
    AgentNotificationComponent.prototype.getPopData = function () {
        if (this.navParams.data.propType === '') {
            this.defaultMsg = null;
        }
        else {
            this.defaultMsg = this.navParams.data.defaultMsg;
            this.noteFormCtrl.setValue(this.defaultMsg);
            this.listingId = this.navParams.data.listingId;
            this.brokerId = this.navParams.data.brokerId;
            this.popupCtrl = this.navParams.data.popupCtrl;
            this.pageURLFrom = this.navParams.data.pageURLFrom;
        }
        var currentUser = this.accountService.getCurrentUser;
        if (currentUser != null) {
            this.formData.controls['name'].setValue(currentUser.firstName + " " + currentUser.lastName);
            this.formData.controls['email'].setValue("" + currentUser.email);
            var phones = currentUser.phones;
            if (phones != null && phones.length > 0) {
                var phone = phones.find(function (phone) { return phone.phoneType === 'PHONE_NUMBER'; });
                if (phone != null && phone.phoneNumber.length > 0) {
                    this.formData.controls['phone'].setValue(phone.phoneNumber);
                }
                else {
                    phone = phones.find(function (phone) { return phone.phoneType === 'WHATSAPP'; });
                    if (phone != null) {
                        this.formData.controls['phone'].setValue(phone.phoneNumber);
                    }
                }
            }
        }
    };
    AgentNotificationComponent.prototype.sendMessage = function () {
        if (this.formData.invalid) {
            this.showAlert('Please enter the required fields');
            return;
        }
        else {
            // send contact email
            this.registerUserActivity(UserActivityType.BROKER_SENDMESSAGE);
            this.sendEmailNotification();
        }
    };
    AgentNotificationComponent.prototype.registerUserActivity = function (userActivityType) {
        var userActivity = {
            module: userActivityType,
            brokerId: this.brokerId,
            listingId: this.listingId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    AgentNotificationComponent.prototype.sendEmailNotification = function () {
        var listingAgentEmailRequest = {
            name: this.formData.value.name,
            phone: this.formData.value.phone,
            email: this.formData.value.email,
            message: this.noteFormCtrl.value,
            listingId: this.listingId,
            listingAgentId: this.brokerId,
            pageURLFrom: this.pageURLFrom
        };
        var wasEmailSent = this.emailService.sendListingMessage(listingAgentEmailRequest);
        var alertMsg = wasEmailSent
            ? this.i18nService.get('global.form.sendMsgToAgent.emailSuccessMessage')
            : this.i18nService.get('global.form.sendMsgToAgent.emailFailureMessage');
        this.showAlert(alertMsg);
    };
    AgentNotificationComponent.prototype.showAlert = function (alertMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl
                            .create({
                            header: this.i18nService.get('global.form.sendMsgToAgent.notification'),
                            subHeader: alertMsg,
                            buttons: [
                                {
                                    text: this.i18nService.get('global.form.sendMsgToAgent.ok'),
                                    handler: function () {
                                        if (_this.formData.valid) {
                                            _this.popupCtrl.dismiss();
                                        }
                                    }
                                }
                            ]
                        })
                            .then(function (alert) {
                            alert.present();
                        })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AgentNotificationComponent.prototype.rowsFromTextArea = function () {
        if (this.noteFormCtrl.invalid && this.formData.controls['email'].invalid) {
            return 4;
        }
        else if (this.noteFormCtrl.invalid || this.formData.controls['email'].invalid) {
            return 6;
        }
        return 7;
    };
    return AgentNotificationComponent;
}());
export { AgentNotificationComponent };
