import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'forgot-form',
  templateUrl: './forgot-form.component.html',
  styleUrls: ['./forgot-form.component.scss']
})
export class ForgotFormComponent implements OnInit {
  registerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private forgotDialog: MatDialogRef<ForgotFormComponent>
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  async sendEmail() {
    this.registerService
      .forgotPassword(this.registerForm.controls.emailGroup.value.email)
      .then((response: any) => {})
      .catch(err => {});

    this.dismissAsDialog(true);
  }

  trimValue(formControl: any) {
    this.registerForm.get('emailGroup.email').setValue(formControl.trim());
  }

  createForm() {
    this.registerForm = this.fb.group({
      emailGroup: this.fb.group({
        email: ['', Validators.compose([Validators.required, Validators.email])]
      })
    });
  }

  dismissAsDialog(val: boolean) {
    this.forgotDialog.close(val);
  }
}
