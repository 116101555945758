import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { PlatformHelperService, I18nService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { AppListingSliderService } from '@app/search-navigation/components/listing-slider';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { IonSlides } from '@ionic/angular';
import { ImageService } from '../../../../core/image.service';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';
import { SizeIcon } from '../../../../models/shared/shared-enums.model';
var SpotFeaturedCardComponent = /** @class */ (function () {
    function SpotFeaturedCardComponent(imageService, router, detailPositionService, platformConfigHelper, dialog, commonService, i18nService, spotBuildingsService, spacesService, listingSliderService, breakpointObserver) {
        var _this = this;
        this.imageService = imageService;
        this.router = router;
        this.detailPositionService = detailPositionService;
        this.platformConfigHelper = platformConfigHelper;
        this.dialog = dialog;
        this.commonService = commonService;
        this.i18nService = i18nService;
        this.spotBuildingsService = spotBuildingsService;
        this.spacesService = spacesService;
        this.listingSliderService = listingSliderService;
        this.breakpointObserver = breakpointObserver;
        this.reduceCardHeight = false;
        this.isMobile = false;
        this.currentPhotoIndex = 0;
        this.count = 0;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.noPhotos = true;
        this.favSize = SizeIcon.LG;
        this.screenWidth = window.innerWidth;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    SpotFeaturedCardComponent.prototype.ngOnInit = function () {
        var isOffice = this.commonService.isPropertyType(2001, this.listing);
        this.spotName = this.i18nService.getTranslation(this.listing.buildingTitle);
        this.hasPointer = window.matchMedia('(pointer: fine)').matches;
        this.noPhotos = !this.listing.buildingListingPhotos || this.listing.buildingListingPhotos.length == 0;
    };
    SpotFeaturedCardComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    SpotFeaturedCardComponent.prototype.changeSlide = function (event) {
        var swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
        this.listingSliderService.setSlide(swipeDirection);
    };
    SpotFeaturedCardComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    SpotFeaturedCardComponent.prototype.getLargeImg = function (id) {
        return this.imageService.largeThumbnail(id);
    };
    SpotFeaturedCardComponent.prototype.getCompanyLogoImg = function (id) {
        return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
    };
    SpotFeaturedCardComponent.prototype.openPreviewComponent = function ($event) {
        $event.stopImmediatePropagation();
        this.dialogRef = this.dialog.open(PreviewImagesComponent, {
            height: '78%',
            width: '60%',
            data: this.listing,
            panelClass: 'custom-modalbox'
        });
        this.dialogRef.afterClosed().subscribe(function (result) { });
    };
    SpotFeaturedCardComponent.prototype.openCard = function (id, $event, externalLink) {
        if (externalLink === void 0) { externalLink = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, lang, res, detailsURL, spacesFilter;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = {
                            localStorageKey: this.localStorageKey,
                            searchCriteria: this.searchCriteria,
                            searchTags: this.searchTags,
                            checkBoxValues: this.checkBoxValues,
                            openUrl: true
                        };
                        $event.stopImmediatePropagation();
                        lang = this.i18nService.getCurrentLanguage();
                        if (window.location.href.includes('/es')) {
                            lang = 'es';
                        }
                        else if (window.location.href.includes('/en')) {
                            lang = 'en';
                        }
                        return [4 /*yield*/, this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, lang).toPromise()];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            detailsURL = res.detailsURL;
                            if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                                detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                            }
                            if (externalLink) {
                                if (window.matchMedia('(display-mode: standalone)').matches) {
                                    this.router.navigateByUrl(detailsURL);
                                }
                                else {
                                    window.open(detailsURL, '_blank');
                                }
                                return [2 /*return*/];
                            }
                            spacesFilter = Object.assign({}, this.searchCriteria);
                            this.spacesService.saveSpacesFilter(spacesFilter);
                            if (this.isMobile) {
                                this.router.navigateByUrl(detailsURL, { state: state });
                            }
                            else {
                                if (window.matchMedia('(display-mode: standalone)').matches) {
                                    this.router.navigateByUrl(detailsURL);
                                }
                                else {
                                    window.open(detailsURL, '_blank');
                                }
                            }
                            this.detailPositionService.setPositionData(this.activeListingIds);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotFeaturedCardComponent.prototype.getNextPhoto = function (id, $event) {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            if (index < _this.currentPhotoIndex) {
                _this.currentPhotoIndex = index;
            }
            else {
                if (_this.currentPhotoIndex === _this.listing.buildingListingPhotos.length - 1 && _this.count == 0) {
                    _this.openCard(id, $event);
                }
                else {
                    if ($event.type === 'click') {
                        _this.photoSlider.slideNext();
                        _this.updatePhotoIndex();
                        _this.count = 1;
                    }
                    else {
                        if (_this.count == 1) {
                            _this.count = 0;
                        }
                        else {
                            _this.updatePhotoIndex();
                        }
                    }
                }
            }
        });
    };
    SpotFeaturedCardComponent.prototype.getPreviousPhoto = function () {
        if (this.currentPhotoIndex === 0) {
            this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
        }
        else {
            this.photoSlider.slidePrev();
        }
        this.updatePhotoIndex();
    };
    SpotFeaturedCardComponent.prototype.showPrevNextNav = function () {
        return [this.hasPointer, this.listing.buildingListingPhotos && this.listing.buildingListingPhotos.length > 1].every(function (condtion) { return condtion; });
    };
    SpotFeaturedCardComponent.prototype.getCroppedOrOriginalImage = function (listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    };
    SpotFeaturedCardComponent.prototype.updatePhotoIndex = function () {
        var _this = this;
        this.photoSlider.getActiveIndex().then(function (index) {
            _this.currentPhotoIndex = index;
        });
    };
    SpotFeaturedCardComponent.prototype.slideToIndex = function (event, index) {
        event.stopPropagation();
        this.photoSlider.slideTo(index);
    };
    SpotFeaturedCardComponent.prototype.getCityState = function () {
        return this.i18nService.getTranslation(this.listing.cityName) + " - " + this.i18nService.getTranslation(this.listing.stateName);
    };
    SpotFeaturedCardComponent.prototype.getSubHeaderCardInfo = function () {
        return this.i18nService.getTranslation(this.listing.buildingAddress) + " ";
    };
    SpotFeaturedCardComponent.prototype.getTypeText = function () {
        return this.commonService.getTypeText(this.listing.type);
    };
    SpotFeaturedCardComponent.prototype.getPropertyTypeText = function () {
        return this.commonService.getPropertyTypeText(this.listing.buildingTypeId);
    };
    SpotFeaturedCardComponent.prototype.getAvailableAreaText = function () {
        return this.commonService.getAvailableAreaText(this.listing.spaceRangesDTO);
    };
    SpotFeaturedCardComponent.prototype.getAvgAskingRent = function () {
        return this.commonService.getAvgAskingRent(this.listing, this.listing.type, this.searchCriteria.currencyType);
    };
    SpotFeaturedCardComponent.prototype.getPositions = function () {
        return this.commonService.getPositions(this.listing.spaceRangesDTO);
    };
    return SpotFeaturedCardComponent;
}());
export { SpotFeaturedCardComponent };
