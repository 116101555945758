import { NgModule } from '@angular/core';
import { ShellModule } from '@app/shell/shell.module';
import { PreviewImageRoutingModule } from './preview-image.routing.module';
import { PreviewImageComponent } from './preview-image.component';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';

@NgModule({
  imports: [
    ShellModule,
    PreviewImageRoutingModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    })
  ],
  entryComponents: [],
  declarations: [PreviewImageComponent],
  exports: [PreviewImageComponent],
  providers: []
})
export class PreviewImageModule {}
