import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { AuthGuard, BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { CreateSpotBuildingsComponent } from './components/create/create-spot-buildings.component';
import { BuildingsDetailPromotedComponent } from './components/detail-promoted/buildings-detail-promoted.component';
import { BuildingsDetailComponent } from './components/detail/buildings-detail.component';
import { SpotBuildingsSearchComponent } from './components/search/spot-buildings-search.component';
import { TurnoffNotificationsComponent } from './components/turnoff-notifications/turnoff-notifications.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
import { SpotDetailContainerComponent } from './components/spot-detail-container/spot-detail-container.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  Shell.childRoutesRunningGuardsResolversAlways([
    {
      path: 'user/turnoff/notifications/:login/:notificationType/:language',
      component: TurnoffNotificationsComponent,
      data: { title: extract('Turn Off Notifications') }
    },
    {
      path: `${AppRouteNames.SEARCH}`,
      component: SpotBuildingsSearchComponent,
      data: { title: extract('Spot Buildings Search') }
    },
    {
      path: `${AppRouteNames.SEARCH}/:language`,
      component: SpotBuildingsSearchComponent,
      data: { title: extract('Spot Buildings Search') }
    },
    {
      path: `${AppRouteNames.SEARCH}/:listingType/:propertyType/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH}/:listingType/:propertyType/${AppRouteNames.FILTER_URL_PARAM}/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH}/:listingType/:propertyType/${AppRouteNames.MARKET_URL_PARAM}/:marketName/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH}/:listingType/:propertyType/:state/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH}/:listingType/:propertyType/:state/:city/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH}/${AppRouteNames.SAVED_SEARCH_URL_PARAM}/:userId/:savedSearchId`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Saved Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}`,
      component: SpotBuildingsSearchComponent,
      data: { title: extract('Spot Buildings Search') }
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/:language`,
      component: SpotBuildingsSearchComponent,
      data: { title: extract('Spot Buildings Search') }
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/:listingType/:propertyType/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/:listingType/:propertyType/${AppRouteNames.FILTER_URL_PARAM_CL}/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/:listingType/:propertyType/${AppRouteNames.MARKET_URL_PARAM_CL}/:marketName/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/:listingType/:propertyType/:state/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/:listingType/:propertyType/:state/:city/:language`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Listings Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: `${AppRouteNames.SEARCH_COUNTRYLANG}/${AppRouteNames.SAVED_SEARCH_URL_PARAM_CL}/:userId/:savedSearchId`,
      component: SpotBuildingsSearchComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Saved Search') },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'spot-buildings/create',
      component: CreateSpotBuildingsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Spot Buildings create')
      }
    },
    {
      path: 'spot-buildings/create/:language',
      component: CreateSpotBuildingsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Spot Buildings create')
      }
    },
    {
      path: 'spot-buildings/create/:buildingType/:buildingId',
      component: CreateSpotBuildingsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Spot Buildings create')
      }
    },
    {
      path: 'spot-buildings/create/:buildingType/:buildingId/:language',
      component: CreateSpotBuildingsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Spot Buildings create')
      }
    },
    {
      path:
        'spot-property/detail/promoted/:buildingType/:propertyType/:neighborhood/:buildingName/:companyName/:buildingId/:lang',
      component: BuildingsDetailPromotedComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Spot Buildings Detail promoted'), shouldReuse: false }
    },
    {
      path: ':buildingType/:propertyType/:stateName/:cityName/:buildingName/:buildingId',
      component: SpotDetailContainerComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Spot Buildings Detail'), shouldReuse: false }
    },
    {
      path: ':buildingType/:propertyType/:stateName/:cityName/:buildingName/:buildingId/:lang',
      component: SpotDetailContainerComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Spot Buildings Detail'), shouldReuse: false }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SpotBuildingsRoutingModule {}
