<!-- Industrial Filters -->
<ion-row class="padding-10" *ngIf="!propertyType || propertyType == 1001">
  <ion-col size="12">
    <ion-label class="weight">{{ 'global.list-your-property.industrial' | translate }}</ion-label>
  </ion-col>
</ion-row>

<ion-row
  *ngIf="!propertyType || propertyType == 1001"
  style="display: inline-block;padding: 20px; margin-bottom: 70px;"
>
  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.armoredCabin }"
    fill="outline"
    (click)="searchDto.armoredCabin = !searchDto.armoredCabin"
  >
    <span [ngClass]="{ white: searchDto.armoredCabin }" translate="global.addtSearchFilters.armoredCabin"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.manufacturing }"
    fill="outline"
    (click)="searchDto.manufacturing = !searchDto.manufacturing"
  >
    <span [ngClass]="{ white: searchDto.manufacturing }" translate="global.addtSearchFilters.manufacturing"></span>
  </ion-button>

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasLoadCapacity }"
    fill="outline"
    (click)="searchDto.hasLoadCapacity = !searchDto.hasLoadCapacity"
  >
    <span [ngClass]="{ white: searchDto.hasLoadCapacity }" translate="global.addtSearchFilters.hasLoadCapacity"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasDockRatio }"
    fill="outline"
    (click)="searchDto.hasDockRatio = !searchDto.hasDockRatio"
  >
    <span [ngClass]="{ white: searchDto.hasDockRatio }" translate="global.addtSearchFilters.hasDockRatio"></span>
  </ion-button-->

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.logistics }"
    fill="outline"
    (click)="searchDto.logistics = !searchDto.logistics"
  >
    <span [ngClass]="{ white: searchDto.logistics }" translate="global.addtSearchFilters.logistics"></span>
  </ion-button-->

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.officeSpaceAvailable }"
    fill="outline"
    (click)="searchDto.officeSpaceAvailable = !searchDto.officeSpaceAvailable"
  >
    <span
      [ngClass]="{ white: searchDto.officeSpaceAvailable }"
      translate="global.addtSearchFilters.officeSpaceAvailable"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.blockConstruction }"
    fill="outline"
    (click)="searchDto.blockConstruction = !searchDto.blockConstruction"
  >
    <span
      [ngClass]="{ white: searchDto.blockConstruction }"
      translate="global.addtSearchFilters.blockConstruction"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.steelConstruction }"
    fill="outline"
    (click)="searchDto.steelConstruction = !searchDto.steelConstruction"
  >
    <span
      [ngClass]="{ white: searchDto.steelConstruction }"
      translate="global.addtSearchFilters.steelConstruction"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.blockAndSteelConstruction }"
    fill="outline"
    (click)="searchDto.blockAndSteelConstruction = !searchDto.blockAndSteelConstruction"
  >
    <span
      [ngClass]="{ white: searchDto.blockAndSteelConstruction }"
      translate="global.addtSearchFilters.blockAndSteelConstruction"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.skyLights }"
    fill="outline"
    (click)="searchDto.skyLights = !searchDto.skyLights"
  >
    <span [ngClass]="{ white: searchDto.skyLights }" translate="global.addtSearchFilters.skyLights"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.multiTenant }"
    fill="outline"
    (click)="searchDto.multiTenant = !searchDto.multiTenant"
  >
    <span [ngClass]="{ white: searchDto.multiTenant }" translate="global.addtSearchFilters.multiTenant"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.singleTenant }"
    fill="outline"
    (click)="searchDto.singleTenant = !searchDto.singleTenant"
  >
    <span [ngClass]="{ white: searchDto.singleTenant }" translate="global.addtSearchFilters.singleTenant"></span>
  </ion-button>

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasCarParkingSpaces }"
    fill="outline"
    (click)="searchDto.hasCarParkingSpaces = !searchDto.hasCarParkingSpaces"
  >
    <span
      [ngClass]="{ white: searchDto.hasCarParkingSpaces }"
      translate="global.addtSearchFilters.carParkingSpaces"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasTruckParkingSpaces }"
    fill="outline"
    (click)="searchDto.hasTruckParkingSpaces = !searchDto.hasTruckParkingSpaces"
  >
    <span
      [ngClass]="{ white: searchDto.hasTruckParkingSpaces }"
      translate="global.addtSearchFilters.truckParkingSpaces"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasBaySize }"
    fill="outline"
    (click)="searchDto.hasBaySize = !searchDto.hasBaySize"
  >
    <span [ngClass]="{ white: searchDto.hasBaySize }" translate="global.addtSearchFilters.hasBaySize"></span>
  </ion-button-->

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.industrialPark }"
    fill="outline"
    (click)="searchDto.industrialPark = !searchDto.industrialPark"
  >
    <span [ngClass]="{ white: searchDto.industrialPark }" translate="global.addtSearchFilters.industrialPark"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.industrialZone }"
    fill="outline"
    (click)="searchDto.industrialZone = !searchDto.industrialZone"
  >
    <span [ngClass]="{ white: searchDto.industrialZone }" translate="global.addtSearchFilters.industrialZone"></span>
  </ion-button-->

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.truckYard }"
    fill="outline"
    (click)="searchDto.truckYard = !searchDto.truckYard"
  >
    <span [ngClass]="{ white: searchDto.truckYard }" translate="global.addtSearchFilters.truckYard"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.crossDocking }"
    fill="outline"
    (click)="searchDto.crossDocking = !searchDto.crossDocking"
  >
    <span [ngClass]="{ white: searchDto.crossDocking }" translate="global.addtSearchFilters.crossDocking"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.refrigerated }"
    fill="outline"
    (click)="searchDto.refrigerated = !searchDto.refrigerated"
  >
    <span [ngClass]="{ white: searchDto.refrigerated }" translate="global.addtSearchFilters.refrigerated"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.sluiceGate }"
    fill="outline"
    (click)="searchDto.sluiceGate = !searchDto.sluiceGate"
  >
    <span [ngClass]="{ white: searchDto.sluiceGate }" translate="global.addtSearchFilters.sluiceGate"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasLockerRooms }"
    fill="outline"
    (click)="searchDto.hasLockerRooms = !searchDto.hasLockerRooms"
  >
    <span [ngClass]="{ white: searchDto.hasLockerRooms }" translate="global.addtSearchFilters.lockerRoom"></span>
  </ion-button>

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.fireProtectionSystem }"
    fill="outline"
    (click)="searchDto.fireProtectionSystem = !searchDto.fireProtectionSystem"
  >
    <span
      [ngClass]="{ white: searchDto.fireProtectionSystem }"
      translate="global.addtSearchFilters.fireProtectionSystem"
    ></span>
  </ion-button-->
</ion-row>
