<ion-content class="multiple-files-modal" style="overflow: hidden;">
  <ion-row>
    <ion-col>
      <div class="header-col">
        <h4 color="secondary" [translate]="'global.uploadingImgs'"></h4>
      </div>
    </ion-col>
  </ion-row>

  <div id="multiple-files-content" class="multiple-files-content" style="overflow-y: auto;">
    <ion-row
      id="multiple-files-rows"
      *ngFor="let imgFile of imgFiles; let i = index"
      style="background:white;border:lightgrey solid 1px;margin:5px;"
    >
      <ion-col size="6" class="">
        <ion-label class="file-name"> {{ imgFile.name }} </ion-label>
      </ion-col>
      <ion-col size="6" style="text-align: center;">
        <ion-progress-bar *ngIf="!imgFile.uploaded" type="indeterminate"></ion-progress-bar>
        <fa-icon
          style="color: green;"
          *ngIf="imgFile.uploaded && !imgFile.errorMsg"
          class="top-icon"
          [icon]="faSuccess"
        ></fa-icon>
        <ion-label style="color: red;" *ngIf="imgFile.uploaded && imgFile.errorMsg"> {{ imgFile.errorMsg }}</ion-label>
      </ion-col>
    </ion-row>
  </div>

  <ion-row>
    <div class="control-btn">
      <ion-button
        color="primary"
        [translate]="'global.OK'"
        (click)="acceptModal()"
        [disabled]="!areImagesUploaded()"
      ></ion-button>
    </div>
  </ion-row>
</ion-content>
