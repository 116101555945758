import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Observable, SubscriptionLike, Subject, Observer, interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { share, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { AccountService } from '@app/core/auth/account.service';
import { guiid } from '@app/websockets/socket-hash.helper';
import { Router } from '@angular/router';
import { isString } from 'lodash';
import { HttpService } from '@app/core';
import * as i0 from "@angular/core";
import * as i1 from "./websocket.config";
import * as i2 from "@angular/router";
import * as i3 from "../core/auth/account.service";
import * as i4 from "../core/http/http.service";
export var SiilaBusAuthActions;
(function (SiilaBusAuthActions) {
    SiilaBusAuthActions["HANDSHAKE"] = "handshake";
    SiilaBusAuthActions["KICK"] = "kick";
    SiilaBusAuthActions["AUTHORIZE"] = "authorize";
    SiilaBusAuthActions["DISCOVER"] = "discover";
    SiilaBusAuthActions["DEPOSIT"] = "deposit";
})(SiilaBusAuthActions || (SiilaBusAuthActions = {}));
var WebsocketService = /** @class */ (function () {
    function WebsocketService(wsConfig, router, account, http) {
        var _this = this;
        this.wsConfig = wsConfig;
        this.router = router;
        this.account = account;
        this.http = http;
        this.wsMessages$ = new Subject();
        this.targetSource$ = new Subject();
        this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
        this.reconnectAttempts = wsConfig.reconnectAttempts || 10; // number of connection attempts
        this.config = {
            url: wsConfig.url,
            closeObserver: {
                next: function (event) {
                    _this.websocket$ = null;
                    _this.connection$.next(false);
                }
            },
            openObserver: {
                next: function (event) {
                    _this.connection$.next(true);
                    _this.handshake();
                }
            }
        };
        // connection status
        this.status = new Observable(function (observer) {
            _this.connection$ = observer;
        }).pipe(share(), distinctUntilChanged());
        // run reconnect if not connection
        this.statusSub = this.status.subscribe(function (isConnected) {
            _this.isConnected = isConnected;
            if (!_this.reconnection$ && typeof isConnected === 'boolean' && !isConnected) {
                _this.reconnect();
            }
        });
        this.websocketSub = this.wsMessages$.subscribe(null, function (error) {
            return console.error('WebSocket error!', error);
        });
        this.wsMessages$.subscribe(function (message) {
            if (isString(message)) {
                try {
                    message = JSON.parse(message);
                }
                catch (e) { }
            }
            if (message.Items && message.Items.length) {
                var authorizer = message.Items.find(function (i) {
                    return i.authorizer_token;
                });
                if (authorizer) {
                    if (authorizer && authorizer.authorizer_token) {
                        try {
                            var authValue = isString(authorizer.authorizer_token)
                                ? JSON.parse(authorizer.authorizer_token)
                                : authorizer.authorizer_token;
                            if (authValue.tokenValue && authValue.user) {
                                if (!localStorage.getItem('user') && !localStorage.getItem('token')) {
                                    localStorage.setItem('token', JSON.stringify(authValue.tokenValue));
                                    localStorage.setItem('ls.token', JSON.stringify(authValue.tokenValue));
                                    localStorage.setItem('user', JSON.stringify(authValue.user));
                                    _this.account.currentUser$.next(authValue.user);
                                    window.location.reload();
                                }
                            }
                        }
                        catch (e) {
                            console.warn('discovery failed parsing #->', e);
                        }
                    }
                }
            }
            if (message.Attributes) {
                localStorage.setItem('client_guiid', JSON.stringify(message.Attributes['client_guiid']));
                _this.discoveryBroker();
            }
            else {
                var messageContent = message;
                if (messageContent.action === SiilaBusAuthActions.AUTHORIZE &&
                    messageContent.data &&
                    messageContent.data.tokenValue &&
                    messageContent.data.user) {
                    // if (!localStorage.getItem('token') && !localStorage.getItem('user')) {
                    //all check if tokens are still valid
                    localStorage.setItem('token', JSON.stringify(messageContent.data.tokenValue));
                    localStorage.setItem('user', JSON.stringify(messageContent.data.user));
                    //right here
                    _this.account.currentUser$.next(messageContent.data.user);
                    window.location.reload();
                    // }
                }
                else if (message.action === SiilaBusAuthActions.KICK) {
                    if (localStorage.getItem('token') || localStorage.getItem('user')) {
                        // localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        _this.account.currentUser$.next(null);
                        _this.router.navigate(['']);
                    }
                    // window.location.reload();
                }
            }
        });
        //this prevents the socket connetion from ddos over iframe
        // if (window.location === window.parent.location)
        // this.connect();
    }
    WebsocketService.prototype.ngOnDestroy = function () {
        this.websocketSub.unsubscribe();
        this.statusSub.unsubscribe();
    };
    /*
     * connect to WebSocked
     * */
    WebsocketService.prototype.connect = function () {
        var _this = this;
        if (window.location === window.parent.location) {
            this.websocket$ = new WebSocketSubject(this.config);
            this.websocket$.subscribe(function (message) { return _this.wsMessages$.next(message); }, function (error) {
                if (!_this.websocket$) {
                    // run reconnect if errors
                    _this.reconnect();
                }
            });
        }
    };
    /*
     * reconnect if not connecting or errors
     * */
    WebsocketService.prototype.reconnect = function () {
        var _this = this;
        this.reconnection$ = interval(this.reconnectInterval).pipe(takeWhile(function (v, index) { return index < _this.reconnectAttempts && !_this.websocket$; }));
        this.reconnection$.subscribe(function () { return _this.connect(); }, null, function () {
            // Subject complete if reconnect attemts ending
            _this.reconnection$ = null;
            if (!_this.websocket$) {
                _this.wsMessages$.complete();
                _this.connection$.complete();
            }
        });
    };
    /*
     * on message event
     * */
    WebsocketService.prototype.on = function (event) {
        if (event) {
            return this.wsMessages$.pipe(filter(function (message) { return message.event === event; }), map(function (message) { return message.data; }));
        }
    };
    /*
     * on message to server
     * */
    WebsocketService.prototype.send = function (action, data) {
        if (data === void 0) { data = {}; }
        if (event && this.isConnected) {
            this.websocket$.next({ action: action, data: data });
        }
        else {
            console.error('Send error!');
        }
    };
    WebsocketService.prototype.kick = function () {
        this.send(SiilaBusAuthActions.KICK, JSON.stringify({}));
    };
    /*
     * after connect exchange guiid
     * */
    WebsocketService.prototype.handshake = function () {
        return this.send(SiilaBusAuthActions.HANDSHAKE, guiid());
    };
    /*
     * discovers clients in bus and exchanges token with provider id
     * */
    WebsocketService.prototype.discover = function () {
        return this.send(SiilaBusAuthActions.DISCOVER, 'client_discovery');
    };
    WebsocketService.prototype.discoveryBroker = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sessionIsValid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(localStorage.getItem('token') && localStorage.getItem('user'))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.isSessionDestroyed()];
                    case 1:
                        sessionIsValid = _a.sent();
                        sessionIsValid.username ? this.deposit() : this.clearCredentialsFromSocket();
                        return [3 /*break*/, 3];
                    case 2:
                        this.discover();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WebsocketService.prototype.clearCredentialsFromSocket = function () {
        if (localStorage.getItem('token') && localStorage.getItem('user')) {
            // localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.kick();
            window.location.reload();
        }
    };
    WebsocketService.prototype.isSessionDestroyed = function () {
        return this.http.get('api/authenticate').toPromise();
    };
    WebsocketService.prototype.deposit = function () {
        this.send(SiilaBusAuthActions.DEPOSIT, {
            tokenValue: JSON.parse(localStorage.getItem('token')),
            user: JSON.parse(localStorage.getItem('user'))
        });
    };
    WebsocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(i0.ɵɵinject(i1.config), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AccountService), i0.ɵɵinject(i4.HttpService)); }, token: WebsocketService, providedIn: "root" });
    return WebsocketService;
}());
export { WebsocketService };
