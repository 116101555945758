<div class="intl-language-elem" [formGroup]="parentForm">
  <ion-row>
    <ion-col>
      <ion-label color="secondary" [translate]="options.columnLabel"></ion-label>
    </ion-col>

    <ion-col>
      <div class="flex-row-justify-end-nowrap">
        <div
          [ngClass]="setLanguageOrder('en')"
          class="control-label inline-flex-nowrap"
          (click)="selectLanguage('english', 'en')"
        >
          <ion-icon color="secondary" [name]="getCheckboxName(languageSelection.english)"></ion-icon>
          <ion-label class="language-choice" [translate]="'global.languageChoice.english'"></ion-label>
        </div>

        <div
          [ngClass]="setLanguageOrder('es')"
          class="control-label inline-flex-nowrap"
          (click)="selectLanguage('spanish', 'es')"
        >
          <ion-icon color="secondary" [name]="getCheckboxName(languageSelection.spanish)"></ion-icon>
          <ion-label class="language-choice" [translate]="'global.languageChoice.spanish'"></ion-label>
        </div>

        <div
          *ngIf="false"
          [ngClass]="setLanguageOrder('pt-br')"
          class="control-label inline-flex-nowrap"
          (click)="selectLanguage('portuguese', 'pt-br')"
        >
          <ion-icon color="secondary" [name]="getCheckboxName(languageSelection.portuguese)"></ion-icon>
          <ion-label class="language-choice" [translate]="'global.languageChoice.portuguese'"></ion-label>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row [formGroupName]="options.formGroupName">
    <ion-col class="flex-column" [formArrayName]="options.formArrayName">
      <div
        [ngClass]="setElementOrder(selectedLanguage[i])"
        *ngFor="let element of formArrayControl.controls; let i = index"
      >
        <div [formGroupName]="i">
          <div class="flex-col-nowrap">
            <ion-label class="elem-label" [translate]="getLanguageLabel(selectedLanguage, i)"></ion-label>

            <input
              *ngIf="options.element.input"
              type="text"
              class="form-control elem-control"
              [formControlName]="selectedLanguage[i]"
            />

            <textarea
              *ngIf="options.element.textarea"
              class="form-control elem-control"
              cols="3"
              rows="5"
              [formControlName]="selectedLanguage[i]"
            >
            </textarea>

            <quill-editor
              *ngIf="options.element.richtext"
              class="quill-container normal-font elem-control"
              [styles]="{ height: '200px' }"
              [placeholder]="placeholder"
              [modules]="richTextEditorOption"
              [formControlName]="selectedLanguage[i]"
            >
            </quill-editor>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
</div>
