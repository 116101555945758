import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { MatDialogRef } from '@angular/material';
var ForgotFormComponent = /** @class */ (function () {
    function ForgotFormComponent(fb, registerService, forgotDialog) {
        this.fb = fb;
        this.registerService = registerService;
        this.forgotDialog = forgotDialog;
    }
    ForgotFormComponent.prototype.ngOnInit = function () {
        this.createForm();
    };
    ForgotFormComponent.prototype.sendEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.registerService
                    .forgotPassword(this.registerForm.controls.emailGroup.value.email)
                    .then(function (response) { })
                    .catch(function (err) { });
                this.dismissAsDialog(true);
                return [2 /*return*/];
            });
        });
    };
    ForgotFormComponent.prototype.trimValue = function (formControl) {
        this.registerForm.get('emailGroup.email').setValue(formControl.trim());
    };
    ForgotFormComponent.prototype.createForm = function () {
        this.registerForm = this.fb.group({
            emailGroup: this.fb.group({
                email: ['', Validators.compose([Validators.required, Validators.email])]
            })
        });
    };
    ForgotFormComponent.prototype.dismissAsDialog = function (val) {
        this.forgotDialog.close(val);
    };
    return ForgotFormComponent;
}());
export { ForgotFormComponent };
