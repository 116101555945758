import { Component, OnInit, OnChanges, ChangeDetectionStrategy, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'siila-chat-view',
  templateUrl: './siila-chat-view.component.html',
  styleUrls: ['./siila-chat-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SiilaChatViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() messages: any[];
  @Input() userFullname: any;
  @Input() userProfileImage: any;
  @Input() spotAccountImage: any;
  @Input() currentUser: any;
  @Input() spotAccountName: any;

  spotAccountFullname: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 100);
  }

  ngOnChanges(changes: any) {
    // if (changes.messages && !changes.messages.firstChange) {
    //   setTimeout(() => {
    //     const bottom = document.getElementById(`${changes.messages.currentValue.length - 1}`);
    //     bottom.scrollIntoView({ behavior: 'smooth' });
    //   }, 100);
    // }
  }

  scrollToBottom() {
    if (this.messages && this.messages.length) {
      const lastMessage = document.getElementById(`${this.messages.length - 1}`);
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
