<div class="privacy-policy-review">
  <div class="privacy-policy-review__policy-container">
    <privacy-policy></privacy-policy>
  </div>
  <mat-dialog-actions align="center">
    <ion-button color="primary" (click)="closePpReview()">
      {{ 'global.privacy-policy.dialog.close' | translate | uppercase }}
    </ion-button>
  </mat-dialog-actions>
</div>
