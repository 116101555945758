import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountService } from '@app/core/auth';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { ImageService } from '@app/core';
import { RegisterListingService } from '@app/core/register-listing.service';

@Component({
  selector: 'edit-image-modal',
  templateUrl: './edit-image-modal.component.html',
  styleUrls: ['./edit-image-modal.component.scss']
})
export class EditImageModalComponent implements OnInit {
  @Input() primaryProfileImage: any;
  @Input() croppedProfileImage: any;
  @Input() commonService: CommonGenericService;
  @Input() userAccountService: AccountService;
  @Input() userHomeService: UserHomeService;
  @Input() profileImageUrl: any;
  @Input() login: string;
  @Input() openInfoModal: any;
  @Input() getCurrentImageProfile: any;
  @Input() userAccountInfo: any;
  @Input() profileImage: any;
  @Input() editLogo: boolean;

  private UPLOAD_FILE_SIZE_LIMIT: number = 20000000;
  imageCropperOptions: any = {};
  imageChangedEvent: any = {};
  originalImage: any = null;
  croppedImage: any = null;
  filename: string = null;
  originalImageId: number = null;
  uploadingNewPhoto: boolean = false;
  isProcessingImg: boolean = null;

  existingImgURL: string = null;
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};
  profileImgBase64: any;

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  constructor(
    private modalCtrl: ModalController,
    private imgService: ImageService,
    private registerListinService: RegisterListingService
  ) {}

  private convertToDataURL(imageUrl: string, callback: any) {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    this.filename = this.commonService.getFileNameFromPath(imageUrl);

    xhr.onload = () => {
      let fileReader = new FileReader();
      fileReader.onloadend = () => {
        callback(fileReader.result);
      };
      fileReader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', imageUrl);
    xhr.responseType = 'blob';
    xhr.send();
  }

  private closeModal() {
    this.modalCtrl.dismiss();
  }

  private resetFileInput(ctrl: any) {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  private fileUploadErrorHandler(action: string) {
    const infoModalParams = {
      headerTitle: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFile' : 'global.uploadImage',
      bodyMessage:
        action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFileError' : 'global.uploadImageSizeExceed'
    };

    this.imageChangedEvent = null;
    this.originalImage = null;
    this.isProcessingImg = null;
    this.filename = null;
    this.resetFileInput(document.getElementById('inputImageFile'));
    this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
  }

  private async uploadOriginalImage(login: any, file: any) {
    return await this.userHomeService
      .uploadUserProfileImage(login, file)
      .toPromise()
      .then((result: any) => {
        return { originalImageId: result.imageId, error: false };
      })
      .catch((error: Error) => {
        return { response: error, error: true };
      });
  }

  private async updateProfileImage(originalImageId: number, login: any, file: any) {
    await this.userHomeService
      .updateUserProfileImage(login, file, this.uploadingNewPhoto)
      .toPromise()
      .then((result: any) => {
        const profileImageId: any = { originalImageId: originalImageId, profileImageId: result.imageId };
        const params: any = this.userHomeService.getUploadImageInfoModalParam(true, profileImageId);
        this.closeModal();
        this.openInfoModal(params.headerTitle, params.bodyMessage, params.action, params.extraData);
      })
      .catch((error: Error) => {
        const params: any = this.userHomeService.getUploadImageInfoModalParam(false);
        this.openInfoModal(params.headerTitle, params.bodyMessage, params.action);
      });
  }

  ngOnInit() {
    this.imageCropperOptions = this.editLogo
      ? this.userHomeService.getLogoCropperOptions()
      : this.userHomeService.getImageCropperOptions();

    let imgURL;
    this.isProcessingImg = false;
    if (this.profileImage) {
      if (this.profileImage.originalImage) {
        imgURL = this.profileImage.originalImage;
      } else {
        imgURL = this.profileImageUrl;
      }
    } else {
      imgURL = this.profileImageUrl;
    }
    this.existingImgURL = imgURL;
    this.convertToDataURL(imgURL, (dataURL: any) => {
      this.profileImgBase64 = dataURL;
    });
  }

  public imageCropped(image: ImageCroppedEvent) {
    this.croppedImage = image.base64;
  }

  public setNewImageFlag(flag: boolean) {
    this.uploadingNewPhoto = true;
  }

  public fileChangedEvent(event: any, remove?: boolean): void {
    this.uploadingNewPhoto = false;
    this.originalImage = null;
    this.isProcessingImg = true;
    this.profileImgBase64 = null;
    this.existingImgURL = null;

    if (remove) {
      this.croppedImage = null;
      this.imageChangedEvent = null;
    } else {
      const imageExtensions: any[] = this.commonService.getValidImageExension();
      const isValidImage = this.commonService.validateExtension(imageExtensions, event.srcElement.files[0].name);

      if (!isValidImage) {
        this.fileUploadErrorHandler('invalid-file');
        return;
      } else if (event.srcElement.files[0].size > this.UPLOAD_FILE_SIZE_LIMIT) {
        this.fileUploadErrorHandler('exceed-limit');
        return;
      }

      this.imageChangedEvent = event;
      const file = event.srcElement.files[0];
      this.filename = event.srcElement.files[0].name;
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      if (this.editLogo) {
        fileReader.onload = () => {
          const originalImageFile = this.convertImageToFile(fileReader.result);
          this.registerListinService.uploadNewImage(originalImageFile, `Logo_cropped`).then((createdImages: any) => {
            this.originalImageId = createdImages[0].id;
            const imgURL = this.imgService.mediumThumbnail(this.originalImageId);
            this.convertToDataURL(imgURL, (dataURL: any) => {
              this.originalImage = dataURL;
            });
          });
        };
      } else {
        fileReader.onload = () => {
          const originalImageFile = this.convertImageToFile(fileReader.result);
          this.uploadOriginalImage(this.login, originalImageFile).then((result: any) => {
            if (result.error) {
              const params: any = this.userHomeService.getUploadImageInfoModalParam(false);
              this.openInfoModal(params.headerTitle, params.bodyMessage, params.action);
            } else {
              this.originalImageId = result.originalImageId;
              this.isProcessingImg = false;
            }
          });
        };
      }
    }
  }

  public cancel() {
    this.closeModal();
  }

  private convertImageToFile(imgBase64: any) {
    return this.commonService.convertImageBase64ToFileSync(imgBase64, this.filename);
  }

  public async saveImage() {
    const croppedImage = this.croppedImage == null ? this.croppedImage : this.convertImageToFile(this.croppedImage);

    if (this.editLogo) {
      this.updateLogoImage(croppedImage);
    } else {
      this.updateProfileImage(this.originalImageId, this.login, croppedImage);
    }
  }

  public async updateLogoImage(croppedImage: any) {
    if (croppedImage != null) {
      await this.registerListinService.uploadNewImage(croppedImage, `Logo_cropped`).then((createdImages: any) => {
        if (createdImages) {
          this.closeModal();
          this.imgService.setLogoId(createdImages[0].id);
        }
      });
    } else {
      this.closeModal();
      this.imgService.setLogoId(croppedImage);
    }
  }

  public rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  public rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  public flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  public flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
}
