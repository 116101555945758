import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18nService, PlatformHelperService } from '@app/core';
import { SpotSetBody } from '@app/models/spot-set-list.model';
import { TourBookList } from '@app/models/tour-book-list.model';
import { ToastController } from '@ionic/angular';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import {
  faTrash,
  faEllipsisV,
  faEdit,
  faSearch,
  faFilter,
  faArrowCircleDown,
  faFileDownload,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { DownloadFileService } from '../services/downloadFile.service';
import { ShareEmailComponent } from '../share-email/share-email.component';
import { EditTourBookComponent } from '../edit-tour-book/edit-tour-book.component';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-tour-books',
  templateUrl: './tour-books.component.html',
  styleUrls: ['./tour-books.component.scss']
})
export class TourBooksComponent implements OnInit {
  @Output() changeStepOutput = new EventEmitter<number>();
  @Input() countSpotSet: number;

  trash: any;
  ellipsisV: any;
  edit: any;
  search: any;
  filter: any;
  arrowDown: any;
  fileDownload: any;
  share: any;

  pageSize = 10;
  page = 0;
  collection = 0;
  filterTourBook: string;
  seeSearch = false;
  param: string;
  direction: string;
  isMobile: boolean = false;
  filterCount = 0;
  tourBookList: TourBookList;

  constructor(
    private favoriteServices: FavoriteSpotService,
    public i18nService: I18nService,
    private dialog: MatDialog,
    private toastCtrl: ToastController,
    private messageFormDialog: MessageModalService,
    private breakpointObserver: BreakpointObserver,
    private downloadFileService: DownloadFileService,
    private platformHelpService: PlatformHelperService,
    private router: Router,
    private routingHelperSrv: RoutingHelperService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.trash = faTrash;
    this.ellipsisV = faEllipsisV;
    this.edit = faEdit;
    this.search = faSearch;
    this.filter = faFilter;
    this.arrowDown = faArrowCircleDown;
    this.fileDownload = faFileDownload;
    this.share = faShare;

    this.getTourBooks(0);
  }

  getTourBooks(page: number) {
    this.page = page;
    const lang = this.i18nService.getCurrentLanguage();
    const bodySpotSet: SpotSetBody = {
      sortBy: this.param,
      direction: this.direction,
      titleFilter: this.filterTourBook
    };
    this.favoriteServices.getTourBook(page, lang, bodySpotSet).then((value: any) => {
      this.tourBookList = value;
      this.collection = this.tourBookList.totalElements;
      this.pageSize = this.tourBookList.size;
    });
  }

  changePage(page: number) {
    this.getTourBooks(page - 1);
  }

  searchProperty() {
    this.seeSearch = !this.seeSearch;
  }
  searchFilter() {
    if (this.filterCount == 0) {
      this.filterCount = 1;
      setTimeout(() => {
        this.getTourBooks(0);
        this.filterCount = 0;
      }, 2000);
    }
  }

  nameAscending() {
    this.param = 'name';
    this.direction = 'ASC';

    this.getTourBooks(0);
  }

  nameDescending() {
    this.param = 'name';
    this.direction = 'DESC';

    this.getTourBooks(0);
  }

  oldestFirst() {
    this.param = 'date';
    this.direction = 'ASC';
    this.getTourBooks(0);
  }

  newestFirst() {
    this.param = 'date';
    this.direction = 'DESC';
    this.getTourBooks(0);
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3500,
      animated: true,
      keyboardClose: true,
      cssClass: 'toast-alert-detail'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  propertiesText(message: string) {
    this.messageFormDialog.openDialogMessage(message);
  }

  shareTourBook(item: any) {
    let dialogRef = this.dialog.open(ShareEmailComponent, {
      width: this.isMobile ? '100%' : '600px',
      maxWidth: this.isMobile ? '100%' : '',
      height: this.isMobile ? '100%' : '',
      data: {
        id: item.id,
        url: `${environment.spotServerUrl}/api/tourbookdashboard/download/${item.id}/${item.fileName}`
      },
      autoFocus: true,
      restoreFocus: true
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.presentToast(this.i18nService.get('favorites.sendEmailSuccess'));
      }
    });
  }

  downloadTourBook(item: any) {
    this.downloadFileService.downloadPDF(
      `/api/tourbookdashboard/download/${item.id}/`,
      item.fileName,
      this.platformHelpService.isIosSafari()
    );
  }

  deleteTourBook(id: number) {
    let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '500px',
      data: {
        message: this.i18nService.get('favorites.tourBooks.deleteTour'),
        id: id,
        apiName: '/api/tourbookdashboard/'
      },
      autoFocus: true,
      restoreFocus: true
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.presentToast(this.i18nService.get('favorites.deleteTourMessage'));
        this.getTourBooks(0);
      }
    });
  }

  goToSpotSet() {
    this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
  }

  editTourBook(item: any) {
    let dialogRef = this.dialog.open(EditTourBookComponent, {
      width: this.isMobile ? '100%' : '600px',
      maxWidth: this.isMobile ? '100%' : '',
      height: this.isMobile ? '100%' : '',
      data: {
        title: true,
        id: item.id,
        name: item.reportTitle
      }
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.getTourBooks(0);
        this.presentToast(val);
      }
    });
  }
}
