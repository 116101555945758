<ion-grid class="height-tall">
  <ion-row>
    <ion-col style="padding: 16px;">
      <ion-label
        ><b>{{ 'global.my-payment.removeCreditsTitle' | translate }}</b></ion-label
      >
    </ion-col>
  </ion-row>
  <ion-row style="padding: 0px 12px 0px 12px;">
    <ion-col size="7">
      <ion-label style="line-height: 41px; font-size: 16px;">{{
        'global.my-payment.removeCredits' | translate
      }}</ion-label>
    </ion-col>
    <ion-col>
      <ion-input
        #posInput
        type="number"
        style="border: 1px solid; padding: 0px; padding-left: 5px !important;"
        [(ngModel)]="credits"
        (ionChange)="validateCredits()"
        (onValueChange)="validateCredits()"
        min="0"
        max="1000"
        autofocus="true"
      >
      </ion-input>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-row class="fixed-ion-btnrow">
  <ion-col size="12">
    <ion-button class="float-right" color="light" fill="clear" (click)="remove()" translate="Remove"></ion-button>
    <ion-button class="float-right" color="light" fill="clear" (click)="cancel()" translate="Cancel"></ion-button>
  </ion-col>
</ion-row>
