import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { DomSanitizer } from '@angular/platform-browser';

export interface ImageResponse {
  imgBase64: any;
  file: any;
}

export interface EditImageResponse {
  croppedImage: ImageResponse;
  originalImage: ImageResponse;
  wasImageCropped: boolean;
  wasOriginalImgReplaced: boolean;
  originalImageId: number;
  wasRestoreToOriginalImage: boolean;
}

@Component({
  selector: 'app-edit-crop-image-modal',
  templateUrl: './edit-crop-image-modal.component.html',
  styleUrls: ['./edit-crop-image-modal.component.scss']
})
export class EditCropImageModalComponent implements OnInit {
  @Input() originalImageId: any;

  @Input() imageUrlToEdit: string;
  @Input() newImageBased64: any;
  @Input() showCroppingToggle: boolean = false;
  @Input() fileName: string = null;

  private UPLOAD_FILE_SIZE_LIMIT: number = 20000000;
  imageCropperOptions: any = {};
  imageChangedEvent: any = {};
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};
  /*  Image based 64 */
  principalImageToEdit: any = null;
  croppedImage: any = null;
  cropOn: boolean = false;
  removedImage: boolean = false;
  replacedImage: boolean = false;
  restoreToOriginalImage: boolean = false;

  croppedImageWidth: string = '200px';
  croppedImageHeight: string = '150px';

  originalImageUrl: string = null;
  validImage: boolean = true;

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  constructor(
    private modalCtrl: ModalController,
    private commonService: CommonGenericService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getPrincipalImage();
    this.imageCropperOptions = this.getImageCropperOptions();
    if (this.originalImageId) {
      this.originalImageUrl = `/images/${this.originalImageId}/thumbnail/ml`;
    }
  }

  public getImageCropperOptions(): any {
    return {
      maintainAspectRatio: 'true',
      cropperMinWidth: 128,
      cropperMinHeight: 128,
      resizeToWidth: 200,
      format: 'png',
      roundCropper: false,
      onlyScaleDown: true,
      aspectRatio: 4 / 3
    };
  }

  private getPrincipalImage() {
    if (this.newImageBased64) {
      this.principalImageToEdit = this.newImageBased64;
    } else if (this.imageUrlToEdit) {
      this.convertToDataURL(this.imageUrlToEdit, (dataURL: any) => {
        this.principalImageToEdit = dataURL;
      });
    }
  }

  public restoreToOriginalImg() {
    if (this.originalImageUrl) {
      this.convertToDataURL(this.originalImageUrl, (dataURL: any) => {
        this.principalImageToEdit = dataURL;
        this.replacedImage = false;
        this.restoreToOriginalImage = true;
      });
    }
  }

  private convertToDataURL(imageUrl: string, callback: any) {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    this.fileName = this.commonService.getFileNameFromPath(imageUrl);

    xhr.onload = () => {
      let fileReader = new FileReader();
      fileReader.onloadend = () => {
        callback(fileReader.result);
      };
      fileReader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', imageUrl);
    xhr.responseType = 'blob';
    xhr.send();
  }

  private closeModal(data: EditImageResponse) {
    this.modalCtrl.dismiss(data);
  }

  private resetFileInput(ctrl: any) {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  private fileUploadErrorHandler(action: string) {
    const infoModalParams = {
      headerTitle: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFile' : 'global.uploadImage',
      bodyMessage:
        action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFileError' : 'global.uploadImageSizeExceed'
    };

    this.imageChangedEvent = null;
    this.principalImageToEdit = null;
    this.fileName = null;
    this.resetFileInput(document.getElementById('inputImageFile'));
    this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
  }

  private async openInfoModal(headerTitle: string, bodyMessage: string, data?: any, extraData?: any) {
    const modalProps: any = {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'generic-info-modal',
      component: InfoModalComponent,
      componentProps: {
        headerTitle: headerTitle,
        bodyMessage: bodyMessage,
        data: data,
        extraData: extraData
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {});
    return infoModal.present();
  }

  public imageCropped(image: ImageCroppedEvent) {
    if (image && image.cropperPosition) {
      this.croppedImageWidth = image.cropperPosition.x2 - image.cropperPosition.x1 + 'px';
      this.croppedImageHeight = image.cropperPosition.y2 - image.cropperPosition.y1 + 'px';
      this.croppedImage = image.base64;
    }
  }

  public fileChangedEvent(event: any, remove?: boolean, replaced?: boolean): void {
    this.removedImage = remove;
    this.replacedImage = replaced;
    this.validImage = false;
    if (remove) {
      this.croppedImage = null;
      this.imageChangedEvent = null;
      this.principalImageToEdit = null;
    } else {
      const imageExtensions: any[] = this.commonService.getValidImageExension();
      const isValidImage = this.commonService.validateExtension(imageExtensions, event.srcElement.files[0].name);

      if (!isValidImage) {
        this.fileUploadErrorHandler('invalid-file');
        return;
      } else if (event.srcElement.files[0].size > this.UPLOAD_FILE_SIZE_LIMIT) {
        this.fileUploadErrorHandler('exceed-limit');
        return;
      }

      this.validImage = true;
      this.imageChangedEvent = event;
      this.principalImageToEdit = event.srcElement.files[0];
      this.fileName = event.srcElement.files[0].name;
      let fileReader = new FileReader();

      fileReader.onload = () => {
        this.principalImageToEdit = fileReader.result;
      };
      fileReader.readAsDataURL(this.principalImageToEdit);
    }
  }

  public cancel() {
    this.closeModal(null);
  }

  private convertImageToFile(imgBase64: any) {
    return this.commonService.convertImageBase64ToFileSync(imgBase64, this.fileName);
  }

  public saveImage() {
    const originalImageFile = this.convertImageToFile(this.principalImageToEdit);
    const croppedImageFile = this.croppedImage ? this.convertImageToFile(this.croppedImage) : null;

    this.closeModal({
      originalImage: { imgBase64: this.principalImageToEdit, file: originalImageFile },
      croppedImage: { imgBase64: this.croppedImage, file: croppedImageFile },
      wasImageCropped: this.cropOn,
      wasOriginalImgReplaced: this.replacedImage,
      originalImageId: this.originalImageId,
      wasRestoreToOriginalImage: this.restoreToOriginalImage
    });
  }

  public rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  public rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  public flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  public flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  public croppingImageValueChanged(event: any) {}

  public showCroppedImage() {
    if (this.cropOn && !this.removedImage) {
      return true;
    }
    return false;
  }

  public showNotCroppedImage() {
    const showCroppedImage = this.showCroppedImage();
    if (!showCroppedImage) {
      if (!this.imageUrlToEdit || this.imageUrlToEdit == null) {
        if (!this.principalImageToEdit || this.principalImageToEdit == null) {
          return false;
        }
      }
      if (!this.validImage) {
        return false;
      }

      return !this.removedImage;
    }
    return !showCroppedImage;
  }

  public getNotCroppedImage() {
    if (this.imageUrlToEdit && !this.cropOn && !this.replacedImage) {
      return this.imageUrlToEdit;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.principalImageToEdit);
  }

  public showLoadOption() {
    return this.removedImage || !this.cropOn;
  }
}
