import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseService } from '../../spot-buildings/services/base-service';
import { cloneDeep } from 'lodash';
import { SpotBuildingsUtilService } from '../../spot-buildings/util/spot-buildings-util.service';
import {
  DataCache,
  KeywordType,
  KeywordApiItem,
  KeywordResultItem,
  KeywordResult,
  HomeSearchParams
} from '@app/models/spot-buildings/home-search.model';

@Injectable()
export class HomeSearchService extends BaseService {
  private searchData: DataCache = {};
  private emptyData: KeywordResult = {
    state: [],
    city: [],
    neighborhood: [],
    market: []
  };
  private getHomeSearchApi = (propTypeId: number, spotBuildingType: string, lang: string) =>
    `api/buildinglisting/location/names/${propTypeId}/${spotBuildingType}/${lang}`;

  constructor(private http: HttpClient, private UtilSvc: SpotBuildingsUtilService) {
    super();
  }

  getData(searchParams: HomeSearchParams, cacheKey: string, fromServer: boolean = false): Promise<KeywordResult> {
    if (!this.searchData[cacheKey] || fromServer) {
      const { propTypeId, spotBuildingType, lang } = searchParams;
      return this.http
        .get(this.getHomeSearchApi(propTypeId, spotBuildingType, lang))
        .pipe(
          map((response: KeywordApiItem[]) => {
            if (response) {
              const formattedData = this.parseData(response);
              this.searchData[cacheKey] = formattedData;
              return formattedData;
            }
            return cloneDeep(this.emptyData);
          })
        )
        .toPromise();
    }

    return new Promise(res => res(this.searchData[cacheKey]));
  }

  parseData(data: KeywordApiItem[]): KeywordResult {
    //console.log('Data: ', data);
    return data.reduce((acc: KeywordResult, item: KeywordApiItem) => {
      if (acc.market == undefined) {
        acc.market = [];
      }
      const parsedItem: KeywordResultItem = {
        ...item,
        type: item.type.toLowerCase() as KeywordType
      };
      acc = {
        ...acc,
        [parsedItem.type]:
          parsedItem.name.includes('Rio de Janeiro') || parsedItem.name.includes('São Paulo')
            ? [parsedItem].concat(acc[parsedItem.type])
            : acc[parsedItem.type].concat(parsedItem)
      };
      return acc;
    }, cloneDeep(this.emptyData));
  }

  getFirstItems(
    data: KeywordResult,
    tab: KeywordType | 'all',
    keyword: string = '',
    limit: number = 20
  ): KeywordResult {
    let result: KeywordResult = cloneDeep(this.emptyData);

    if (!keyword) return result;

    if (tab !== 'all') {
      result[tab] = this.filterByType(data[tab], keyword, limit);
      return result;
    }

    let itemCounter = 0;
    const propertyTypes = Object.keys(data) as KeywordType[];

    for (let i: number = 0; i < propertyTypes.length; i++) {
      const itemsOnType = data[propertyTypes[i]];
      const filtered = this.filterByType(itemsOnType, keyword, limit - itemCounter);
      result[propertyTypes[i]] = filtered;
      itemCounter += filtered.length;
    }

    return result;
  }

  filterByType(items: KeywordResultItem[], keyword: string, max: number) {
    let itemCounter = 0;
    let result: KeywordResultItem[] = [];
    for (let i: number = 0; i < items.length; i++) {
      if (itemCounter >= max) continue;

      const normalized: string = this.UtilSvc.normalize(items[i].name);
      const pattern = this.UtilSvc.scapeRegexCharacters(keyword);
      const reg = new RegExp(pattern, 'gi');

      if (normalized.match(reg)) {
        result = result.concat(items[i]);
        itemCounter++;
      }
    }
    return result;
  }
}
