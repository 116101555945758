import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18nService } from '@app/core';
import { EditTourBook } from '@app/models/edit-tour-book.model';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';

@Component({
  selector: 'app-edit-tour-book',
  templateUrl: './edit-tour-book.component.html',
  styleUrls: ['./edit-tour-book.component.scss']
})
export class EditTourBookComponent implements OnInit {
  tourBookTitle: string;
  isMobile: boolean = false;
  editTitle: EditTourBook = new EditTourBook();

  constructor(
    private dialog: MatDialogRef<EditTourBookComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private favoriteServices: FavoriteSpotService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {
    if (window.screen.width < 1000) {
      this.isMobile = true;
    }
    this.tourBookTitle = this.data.name;
  }

  close() {
    this.dialog.close();
  }

  editTitleTourBook() {
    this.editTitle.id = this.data.id;
    this.editTitle.name = this.tourBookTitle;

    this.favoriteServices.editTitleTourBook(this.editTitle).then(val => {
      if (val == 200) {
        const message = this.i18nService.get('global.changesSaved');
        this.dialog.close(message);
      }
    });
  }
}
