import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NewsService } from '../services/news.service';
import { SpotCardSliderService } from '../services/spot-card-slider.service';
import { SliderOptionsType } from '@app/models/shared/lazy-slider.model';
import { IonSlides } from '@ionic/angular';
import { IconDefinition, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from '@env/environment';
import { I18nService } from '@app/core';

@Component({
  selector: 'industry-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() companyId: any = null;
  @ViewChild('slider', { static: false }) slider: IonSlides;
  @ViewChild('slideWithNav3', { static: false }) slideWithNav3: IonSlides;
  articles: any;
  slideOpts: any;
  leftArrow: IconDefinition;
  rightArrow: IconDefinition;
  currenPage = 0;
  isMobile = false;
  isFirstEnd = false;
  lang: string;

  constructor(
    private newsService: NewsService,
    private breakpointObserver: BreakpointObserver,
    private i18nService: I18nService
  ) {
    this.leftArrow = faChevronLeft;
    this.rightArrow = faChevronRight;
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
      if (this.isMobile) {
        this.slideOpts = {
          initialSlide: 0,
          slidesPerView: 1,
          isBeginningSlide: true
        };
      } else {
        if (window.innerWidth > 1000 && window.innerWidth < 1200) {
          this.slideOpts = {
            initialSlide: 0,
            slidesPerView: 2,
            isBeginningSlide: true
          };
        } else {
          this.slideOpts = {
            initialSlide: 0,
            slidesPerView: 3,
            isBeginningSlide: true
          };
        }
      }
    });
    this.lang = this.i18nService.getCurrentLanguage();
  }

  ngOnInit() {
    this.getInitNews();
  }

  getInitNews() {
    this.newsService
      .getNewsSearch(this.lang, this.currenPage, this.isMobile ? 2 : 6, null, 67, this.companyId)
      .subscribe((resp: any) => {
        this.articles = resp;
      });
  }

  openHomeNews() {
    window.open(environment.serverUrl + '/resource/', this.isMobile ? '_self' : '_blank');
  }

  nextSlide() {
    this.checkisEnd();
  }

  async prevSlide() {
    this.checkisBeginning(1);
  }

  async checkisBeginning(prev: number) {
    const isBeginning = await this.slider.isBeginning();
    this.slideOpts.isBeginningSlide = isBeginning;
    if (!isBeginning && prev == 1) {
      this.slider.slidePrev();
      const isBeginning = await this.slider.isBeginning();
      this.slideOpts.isBeginningSlide = isBeginning;
    }
  }
  async checkisEnd(value: any = null) {
    this.checkisBeginning(2);
    const isEnd = await this.slider.isEnd();
    if (isEnd && !this.articles.last) {
      this.currenPage += 1;
      this.newsService
        .getNewsSearch(this.lang, this.currenPage, this.isMobile ? 1 : 3, null, 67, this.companyId)
        .subscribe((resp: any) => {
          const news = resp;
          news.content.forEach((n: any) => this.articles.content.push(n));
          setTimeout(() => {
            if (value == null) {
              this.slider.slideNext();
            }
          }, 500);
        });
    } else {
      this.slider.slideNext();
    }
  }

  slideReachEnd() {
    this.checkisEnd(1);
  }
}
