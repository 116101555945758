import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SaveModalActions, SaveModalTypes } from '@app/models/shared/shared-interfaces.model';
import { ModalController } from '@ionic/angular';
import { I18nService } from '../../core/i18n.service';

@Component({
  selector: 'save-change-modal',
  templateUrl: './save-change-modal.component.html',
  styleUrls: ['./save-change-modal.component.scss']
})
export class SaveChangeModalComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() bodyMessage: string;
  @Input() leftButtonLabel: string;
  @Input() rightButtonLabel: string;
  @Input() modalType: SaveModalTypes;
  @Input() dropdownItems: any[];

  customInput: string;
  selectedItem: any;
  customMsg: any;

  constructor(private modalCtrl: ModalController, private i18n: I18nService) {}
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    if (this.isForSaveSearch()) {
      this.customMsg = this.i18n.get('SaveSearchMsg');
    } else if (this.isForLoadSearch()) {
      this.customMsg = this.i18n.get('LoadSearchMsg');
    }
  }

  userAction(action: string) {
    if (this.isForSaveSearch()) {
      this.modalCtrl.dismiss({
        closedModal: true,
        action: action,
        customInput: this.customInput
      });
    }

    if (this.isForLoadSearch()) {
      this.modalCtrl.dismiss({
        closedModal: true,
        action: action,
        selectedItem: this.selectedItem
      });
    }
  }

  setItemSelection(item: any) {
    this.selectedItem = item;
  }

  isForSaveSearch() {
    return this.modalType && this.modalType == SaveModalTypes.SAVE_SEARCH;
  }

  isForLoadSearch() {
    return this.modalType && this.modalType == SaveModalTypes.LOAD_SEARCH;
  }

  disableSentBtn() {
    let invalid = false;
    if (this.isForSaveSearch()) {
      invalid = !this.customInput || this.customInput.length == 0;
    }
    if (this.isForLoadSearch()) {
      invalid = !this.selectedItem || this.selectedItem == null;
    }

    return invalid;
  }

  dismissAsDialog() {
    this.modalCtrl.dismiss();
  }
}
