import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SpotBuildingsSearchComponent } from './components/search/spot-buildings-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ShellModule } from '@app/shell/shell.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { SpotBuildingsSearchService } from './services/spot-buildings-search.service';
import { SpotBuildingsRoutingModule } from './spot-buildings.routing.module';
import { MetaModule } from '@ngx-meta/core';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { SearchNavigationModule } from '@app/search-navigation/search-navigation.module';
import { QuillModule } from 'ngx-quill';
import { CreateSpotBuildingsComponent } from './components/create/create-spot-buildings.component';
import { SpotBuildingsDetailComponent } from './components/create/buildings-detail/spot-buildings-detail.component';
import { SpotMoreBuildingsComponent } from './components/create/more-buildings/spot-more-buildings.component';
import { MoreFiltersSpotbuildingsComponent } from './components/search/more-filters/more-filters-spotbuildings.component';
import { RangeFilterComponent } from '@app/search-navigation/components/search-filters/range-filters/range-filters.component';
import { OfficeFilterComponent } from '../search-navigation/components/search-filters/office-filters/office-filters.component';
import { IndustrialFilterComponent } from '../search-navigation/components/search-filters/industrial-filters/industrial-filters.component';
import { CreateSpotBuildingsService } from './services/create-spot-buildings.service';
import { FilterRangesSpotbuildingsComponent } from './components/search/more-filters/filter-ranges-spotbuildings/filter-ranges-spotbuildings.component';
import { SpotBuildingCardComponent } from './components/card/spot-building-card/spot-building-card.component';
import { SpotBuildingsFeaturesComponent } from './components/create/buildings-features/spot-buildings-features.component';
import { SpotBuildingsUtilService } from './util/spot-buildings-util.service';
import { BuildingsDetailComponent } from './components/detail/buildings-detail.component';
import { SpotBuildingsService } from './services/spot-buildings.service';
import { BaseService } from './services/base-service';
import { SpotFeaturedCardComponent } from './components/card/spot-featured-card/spot-featured-card.component';
import { PaginationModule } from 'ngx-bootstrap';
import { LocalNumberPipe } from '../shared/pipes/number-pipe';
import { SpaceDetailsComponent } from './components/detail/space-details/space-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RequestInformationComponent } from './components/request-information/request-information.component';
import { RequestInfoService } from './services/request-info.service';
import { SpotBuildingSpaceService } from './services/spaces.service';
import { SpotSpacesBuildingsComponent } from './components/create/spaces-buildings/spot-spaces-buildings.component';
import { imageUploadSpacesBuilding } from './components/create/spaces-buildings/image-upload-spaces-building/image-upload-spaces-building';
import { VideoComponent } from '../shared/video/video.component';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { ImageGalleryComponent } from '@app/shared/image-gallery/image-gallery.component';
import { HomeSearchService } from '../home/services/home-search.service';
import { MatTabsModule } from '@angular/material';
import { BuildingsDetailPromotedComponent } from './components/detail-promoted/buildings-detail-promoted.component';
import { SpotSearchService } from './services/spot-search.service';
import { SpotMapMobileCardComponent } from '@app/src/app/spot-buildings/components/card/spot-map-mobile-card/spot-map-mobile-card.component';
import { SetSpacesComponent } from './components/detail/set-spaces/set-spaces.component';
import { ReportSpacesComponent } from './components/detail/report-spaces/report-spaces.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TurnOffNotificationService } from './services/turnoff-notification.service';
import { TurnoffNotificationsComponent } from './components/turnoff-notifications/turnoff-notifications.component';
import { SpotDetailContainerComponent } from './components/spot-detail-container/spot-detail-container.component';
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    InViewportModule,
    IonicModule,
    MetaModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    ShellModule,
    TranslateModule,
    SpotBuildingsRoutingModule,
    FontAwesomeModule,
    SearchNavigationModule,
    PaginationModule.forRoot(),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    NgbModule,
    MatTabsModule,
    MatExpansionModule
  ],
  entryComponents: [
    SpotBuildingsSearchComponent,
    MoreFiltersSpotbuildingsComponent,
    RangeFilterComponent,
    OfficeFilterComponent,
    IndustrialFilterComponent,
    CreateSpotBuildingsComponent,
    SpotBuildingsDetailComponent,
    SpotMoreBuildingsComponent,
    SpotBuildingsFeaturesComponent,
    BuildingsDetailComponent,
    SpaceDetailsComponent,
    RequestInformationComponent,
    SpotDetailContainerComponent,
    ImageGalleryComponent,
    SpotSpacesBuildingsComponent,
    imageUploadSpacesBuilding,
    VideoComponent,
    BuildingsDetailPromotedComponent,
    SpotMapMobileCardComponent,
    SetSpacesComponent,
    ReportSpacesComponent,
    TurnoffNotificationsComponent
  ],
  declarations: [
    FilterRangesSpotbuildingsComponent,
    SpotBuildingsSearchComponent,
    MoreFiltersSpotbuildingsComponent,
    CreateSpotBuildingsComponent,
    SpotBuildingsDetailComponent,
    SpotMoreBuildingsComponent,
    SpotBuildingCardComponent,
    SpotBuildingsFeaturesComponent,
    SpotFeaturedCardComponent,
    BuildingsDetailComponent,
    SpaceDetailsComponent,
    RequestInformationComponent,
    SpotSpacesBuildingsComponent,
    imageUploadSpacesBuilding,
    BuildingsDetailPromotedComponent,
    SpotMapMobileCardComponent,
    SetSpacesComponent,
    ReportSpacesComponent,
    TurnoffNotificationsComponent,
    SpotDetailContainerComponent
  ],
  exports: [
    SpotBuildingsSearchComponent,
    CreateSpotBuildingsComponent,
    SpotBuildingsDetailComponent,
    SpotMoreBuildingsComponent,
    SpotBuildingsFeaturesComponent,
    BuildingsDetailComponent,
    SpotSpacesBuildingsComponent,
    imageUploadSpacesBuilding,
    BuildingsDetailPromotedComponent,
    SetSpacesComponent,
    ReportSpacesComponent,
    SpotDetailContainerComponent
  ],
  providers: [
    DatePipe,
    RequestInfoService,
    SpotBuildingsSearchService,
    SpotSearchService,
    CreateSpotBuildingsService,
    SpotBuildingsUtilService,
    SpotBuildingsService,
    BaseService,
    LocalNumberPipe,
    SpotBuildingSpaceService,
    StreamingMedia,
    HomeSearchService,
    TurnOffNotificationService
  ]
})
export class SpotBuildingsModule {}
