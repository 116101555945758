<div *ngIf="!isCompleted">
  <div
    class="row"
    style="    margin: -12px -24px 0px -24px;
  border-bottom: 1px solid #6c757d;"
  >
    <div style="width: 80%;margin-bottom: 12px;">
      <span style="font-weight: 700;font-size: 23px;  margin-left: 20px;">{{ 'createAccount' | translate }}</span>
    </div>
    <div style="width: 20%;">
      <img class="close-icon" (click)="dismissAsDialog()" src="assets/images/icons/close.png" alt="" />
    </div>
  </div>

  <br />

  <div
    style="width: 100%;text-align: center;"
    *ngIf="
      !inactiveAccountExists &&
      !uniqueEmailError &&
      !phoneError &&
      !companySelectedError &&
      !(
        registerForm.controls.emailGroup.controls.primaryPhone.value &&
        registerForm.controls.emailGroup.controls.primaryPhone.errors?.validatePhoneNumber
      )
    "
  >
    <span class="font-16">{{ 'global.register.welcomeSpot' | translate }}</span>
    <br />
    <span class="font-14">{{ 'global.register.registerText' | translate }}</span>
  </div>

  <div style="width: 100%;  text-align: center;" *ngIf="inactiveAccountExists">
    <span class="font-16" style="color: #DC3545;">
      <img style="margin-top: -5px;" src="assets/images/icons/wrong.png" alt="" /> Email is inactive.</span
    >
  </div>

  <div style="width: 100%;  text-align: center;" *ngIf="uniqueEmailError">
    <span class="font-16" style="color: #DC3545;">
      <img style="margin-top: -5px;" src="assets/images/icons/wrong.png" alt="" /> Email is already in use.</span
    >
  </div>

  <div
    style="width: 100%;  text-align: center;"
    *ngIf="
      phoneError ||
      (registerForm.controls.emailGroup.controls.primaryPhone.value &&
        registerForm.controls.emailGroup.controls.primaryPhone.errors?.validatePhoneNumber)
    "
  >
    <span class="font-16" style="color: #DC3545;">
      <img style="margin-top: -5px;" src="assets/images/icons/wrong.png" alt="" /> Invalid phone.</span
    >
  </div>

  <div style="width: 100%;text-align: center;" *ngIf="companySelectedError">
    <span class="font-16" style="color: #DC3545;">
      <img style="margin-top: -5px;" src="assets/images/icons/wrong.png" alt="" /> Company field is required.</span
    >
  </div>

  <form [formGroup]="registerForm" style="margin-top: 15px;" class="width-100" name="registerForm" autocomplete="off">
    <div formGroupName="emailGroup">
      <ion-input
        id="email"
        class="form-control"
        name="email"
        (ionChange)="trimValue(registerForm.controls.emailGroup.value.email)"
        (ionBlur)="checkEmail()"
        formControlName="email"
        placeholder="{{ 'global.register.emailAddress' | translate }}"
      >
      </ion-input>

      <!-- First Name-->
      <ion-input
        style="margin-top: 6px"
        id="firstName"
        class="form-control"
        name="firstName"
        type="text"
        formControlName="firstName"
        placeholder="{{ 'global.register.firstName' | translate }}"
      >
      </ion-input>

      <!-- Last Name -->
      <ion-input
        style="margin-top: 6px"
        id="lastName"
        class="form-control"
        name="lastName"
        type="text"
        min="6"
        formControlName="lastName"
        placeholder="{{ 'global.register.lastName' | translate }}"
      >
      </ion-input>

      <div>
        <ion-row style="margin-top: 6px">
          <span class="width-full" [ngClass]="{ 'android-padding': !ios }">
            <ngx-intl-tel-input
              id="primaryPhone"
              name="primaryPhone"
              formControlName="primaryPhone"
              class="int-phone"
              placeholder="Phone number"
              [preferredCountries]="intlTelephoneOptions.preferredCountries"
              [enableAutoCountrySelect]="intlTelephoneOptions.enableAutoCountrySelect"
              [enablePlaceholder]="intlTelephoneOptions.enablePlaceholder"
              [searchCountryFlag]="intlTelephoneOptions.searchCountryFlag"
              selectFirstCountry="true"
              [selectedCountryISO]="primaryPhoneCountryISO"
              [maxLength]="intlTelephoneOptions.maxLength"
              [tooltipField]="intlTelephoneOptions.tooltipField"
              [phoneValidation]="true"
              [separateDialCode]="intlTelephoneOptions.separateDialCode"
            >
            </ngx-intl-tel-input>
          </span>
        </ion-row>
      </div>

      <select
        #mySelect
        style="margin-top: 6px"
        formControlName="dropdown"
        (ngModelChange)="portChange($event)"
        class="form-control"
      >
        <option [ngValue]="null" disabled>{{ 'global.my-list-menu.company' | translate }}</option>
        <option *ngFor="let company of allCompanies" [ngValue]="company">
          {{ company.name }}
        </option>
      </select>

      <div *ngIf="selectedCompany.id === 0">
        <ion-row style="margin-top: 6px">
          <ion-input
            id="companySelected"
            formControlName="companySelected"
            class="form-control"
            name="companySelected"
            type="text"
            min="6"
            [disabled]="registerForm.controls.emailGroup.value.dropdown.id"
            placeholder="Company*"
          >
          </ion-input>
        </ion-row>
      </div>

      <ion-input
        style="margin-top: 6px"
        id="password"
        class="form-control"
        [type]="hide ? 'password' : 'text'"
        type="password"
        autocomplete="new-password"
        min="6"
        formControlName="password"
        placeholder="{{ 'global.register.password' | translate }}"
      >
        <img
          (click)="hide = !hide"
          style="position: absolute; margin: 13px 0px 0px 268px;;cursor: pointer;"
          src="assets/images/icons/seePassword.png"
          alt=""
        />
      </ion-input>

      <ion-input
        style="margin-top: 6px"
        id="confirmPass"
        class="form-control"
        [type]="hide1 ? 'password' : 'text'"
        min="6"
        autocomplete="new-password"
        name="confirmPass"
        formControlName="confirmPass"
        placeholder="{{ 'global.register.confirmPassword' | translate }}"
      >
        <img
          (click)="hide1 = !hide1"
          style="position: absolute; margin: 13px 0px 0px 268px;;cursor: pointer;"
          src="assets/images/icons/seePassword.png"
          alt=""
        />
      </ion-input>
    </div>

    <div class="flex-row align-end" style="margin-top: 15px;">
      <ion-button
        [disabled]="
          !registerForm?.controls.emailGroup.valid ||
          registerForm?.controls.emailGroup.value.password != registerForm?.controls.emailGroup.value.confirmPass ||
          uniqueEmailError ||
          inactiveAccountExists ||
          isLoaded
        "
        (click)="submitBasicAccount()"
        class="btn-color-2"
        style="width: 100%;font-size: 14px;margin: 15px 0px 0px 0px;"
      >
        <span>{{ 'createAccount' | translate }}</span>
      </ion-button>
    </div>
  </form>
</div>

<div *ngIf="isCompleted">
  <div class="row" style="text-align: center;margin: 0px 10px 0px 10px;">
    <div style="width: 100%;">
      <img (click)="dismissAsDialog()" src="assets/images/icons/done2.svg" alt="" />
    </div>
    <div style="width: 100%;margin-bottom: 12px;">
      <span class="font-16">{{ 'global.register.accountCreated' | translate }}</span>
    </div>
    <div style="width: 100%;">
      <span class="font-14">{{ 'global.register.accountText' | translate }}</span>
    </div>
  </div>

  <div class="flex-row align-end" style="margin-top: 15px;">
    <ion-button
      (click)="dismissAsDialog()"
      class="btn-color-2"
      style="width: 100%;font-size: 14px;margin: 15px 0px 0px 0px;"
    >
      <span>Close</span>
    </ion-button>
  </div>
</div>

<!-- <form [formGroup]="registerForm" class="width-100" style="height: auto" name="registerForm">
  <div formGroupName="detailGroup">
    <ion-input id="email" class="form-control" name="email"
      (ionChange)="trimValue(registerForm.controls.detailGroup.value.email)" formControlName="email"
      placeholder="{{ 'global.register.emailAddress' | translate }}">
    </ion-input>
  </div> -->
<!--   <input id="email" class="form-control" name="email" autocomplete="new-password"
    (Change)="trimValue(registerForm.controls.emailGroup.value.email)" [ngClass]="{
    'red-border':
      registerForm.errors?.uniqueEmailError && emailName === registerForm?.controls.emailGroup.value.email
  }" formControlName="email" placeholder="{{ 'global.register.emailAddress' | translate }}">
  </input> -->

<!-- First Name-->
<!-- <input id="firstName" class="form-control" name="firstName" type="text" formControlName="firstName"
    placeholder="{{ 'global.register.firstName' | translate }}">
  </input> -->

<!-- Last Name -->
<!-- <input id="password" class="form-control" name="lastName" type="text" min="6" formControlName="lastName"
    placeholder="{{ 'global.register.lastName' | translate }}">
  </input> -->

<!--   <ion-row style="border: 1px solid lightgrey; margin-bottom: 20px;">
    <span class="width-full" [ngClass]="{ 'android-padding': !ios }">
      <ionic-selectable formControlName="dropdown" [items]="allCompanies" itemValueField="id" itemTextField="name"
        [hasVirtualScroll]="true" placeholder="{{ 'global.register.selectCompany' | translate }}" [canSearch]="true"
        (onChange)="portChange($event)" searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}">
      </ionic-selectable>
    </span>
  </ion-row>

  <input id="password" class="form-control" name="password" type="password" autocomplete="new-password" [ngClass]="{
    'red-border': !registerForm.get('emailGroup.password').pristine && registerForm.errors?.passwordLength
  }" min="6" formControlName="password" placeholder="{{ 'global.register.password' | translate }}">
  </input>

  <input id="confirmPass" class="form-control" type="password" min="6" autocomplete="new-password" [ngClass]="{
    'red-border': !registerForm.get('emailGroup.confirmPass').pristine && registerForm.errors?.passwordMatch
  }" name="confirmPass" formControlName="confirmPass"
    placeholder="{{ 'global.register.confirmPassword' | translate }}">
  </input> -->

<!--  <div class="flex-row align-end checkbox-label-margin">
    <button class="siila-submit login-btn block-btn" (click)="submitBasicAccount()" mat-raised-button type="submit"
      [disabled]="!registerForm?.controls.detailGroup.valid">
      {{ 'global.register.getStarted' | translate | uppercase }}
    </button>
  </div>

  <div class="flex-row align-end" style="margin-top: 15px;">
    <ion-button [disabled]="!registerForm?.controls.detailGroup.valid" (click)="submitBasicAccount()"
      class="btn-color-2" style="width: 100%;font-size: 14px;margin: 15px 0px 0px 0px;">
      <span>{{ 'global.register.sendEmail' | translate }}</span>
    </ion-button>
  </div>
</form> -->
<!-- <ion-content no-bounce class="register-ion-grid spot-register-user">
  <ion-row class="back-btn">
    <ion-icon class="signup-icon" *ngIf="isCompleted && !lastStep" (click)="backButton()" color="primary"
      name="arrow-back"></ion-icon>
  </ion-row>

  <ion-row class="register-ionic-row">
    <h4 class="signup-heading" *ngIf="!lastStep">
      {{ 'global.register.signup' | translate }}
    </h4>

    <form [formGroup]="registerForm" class="width-100" style="height: 100% !important" name="registerForm">
      <ion-icon class="signup-icon" *ngIf="isCompleted && lastStep" (click)="goBack()" color="primary"
        name="arrow-back"></ion-icon>
      <mat-horizontal-stepper [linear]="true" [selectedIndex]="stepIndex" #stepper style="height: 100% !important">

        <mat-step label="Email" [completed]="isCompleted" formGroupName="emailGroup">
          <ion-input id="email" class="input-border" name="email" autocomplete="new-password"
            (ionChange)="trimValue(registerForm.controls.emailGroup.value.email)" [ngClass]="{
              'red-border':
                registerForm.errors?.uniqueEmailError && emailName === registerForm?.controls.emailGroup.value.email
            }" formControlName="email" placeholder="{{ 'global.register.emailAddress' | translate }}">
          </ion-input>

          <ion-input id="password" class="input-border" name="password" type="password" autocomplete="new-password"
            [ngClass]="{
              'red-border': !registerForm.get('emailGroup.password').pristine && registerForm.errors?.passwordLength
            }" min="6" formControlName="password" placeholder="{{ 'global.register.password' | translate }}">
          </ion-input>

          <ion-input id="confirmPass" class="input-border" type="password" min="6" autocomplete="new-password"
            [ngClass]="{
              'red-border': !registerForm.get('emailGroup.confirmPass').pristine && registerForm.errors?.passwordMatch
            }" name="confirmPass" formControlName="confirmPass"
            placeholder="{{ 'global.register.confirmPassword' | translate }}">
          </ion-input>
          <div class="flex-row align-end">
            <button class="siila-submit login-btn block-btn" mat-raised-button matStepperNext (click)="checkEmail()"
              [disabled]="
                !registerForm?.controls.emailGroup.valid ||
                registerForm.errors?.passwordLength ||
                registerForm.errors?.passwordMatch
              " translate="next">
              {{ 'global.menu.next' | translate }}
            </button>
          </div>
        </mat-step>


        <mat-step [completed]="lastStep" formGroupName="detailGroup">
          <div class="flex-row justify-signup" style="height: 600px !important">

            <ion-input id="firstName" class="input-border" name="firstName" type="text" formControlName="firstName"
              placeholder="{{ 'global.register.firstName' | translate }}">
            </ion-input>


            <ion-input id="password" class="input-border" name="lastName" type="text" min="6" formControlName="lastName"
              placeholder="{{ 'global.register.lastName' | translate }}">
            </ion-input>
            <div *ngIf="stepper && stepper.selectedIndex == 1">
              <ion-label style="color: grey;" [translate]="'global.register.requiredPrimaryPhone'"></ion-label>
              <ion-row style="margin-bottom: 24px;">
                <span class="width-full" [ngClass]="{ 'android-padding': !ios }">
                  <ngx-intl-tel-input id="primaryPhone" name="primaryPhone" formControlName="primaryPhone"
                    class="int-phone" placeholder="Phone number"
                    [preferredCountries]="intlTelephoneOptions.preferredCountries"
                    [enableAutoCountrySelect]="intlTelephoneOptions.enableAutoCountrySelect"
                    [enablePlaceholder]="intlTelephoneOptions.enablePlaceholder"
                    [searchCountryFlag]="intlTelephoneOptions.searchCountryFlag" selectFirstCountry="true"
                    [selectedCountryISO]="primaryPhoneCountryISO" [maxLength]="intlTelephoneOptions.maxLength"
                    [tooltipField]="intlTelephoneOptions.tooltipField" [phoneValidation]="true"
                    [separateDialCode]="intlTelephoneOptions.separateDialCode">
                  </ngx-intl-tel-input>
                </span>
              </ion-row>
            </div>

            <ion-label style="padding-bottom: 5px; color: grey;">{{ 'global.register.pleaseSelect' | translate }}
            </ion-label>
            <ion-row style="border: 1px solid lightgrey; margin-bottom: 20px;">
              <span class="width-full" [ngClass]="{ 'android-padding': !ios }">
                <ionic-selectable formControlName="dropdown" [items]="allCompanies" itemValueField="id"
                  itemTextField="name" [hasVirtualScroll]="true"
                  placeholder="{{ 'global.register.selectCompany' | translate }}" [canSearch]="true"
                  (onChange)="portChange($event)" searchPlaceholder="{{ 'global.menu.search' | translate }}"
                  closeButtonText="{{ 'Cancel' | translate }}">
                </ionic-selectable>
              </span>
            </ion-row>

            <div *ngIf="selectedCompany.id === 0">
              <ion-label style="padding-bottom: 5px; color: grey;">{{
                'global.register.addNew' | translate
                }}</ion-label>
              <ion-row>
                <ion-input id="companySelected" formControlName="companySelected" class="input-border"
                  name="companySelected" type="text" min="6"
                  [disabled]="registerForm.controls.detailGroup.value.dropdown.id" placeholder="Company*">
                </ion-input>
              </ion-row>
            </div>
            <div>
              <img [src]="image" alt="" />
              <img style="height:50px; margin: 1%;" *ngIf="logoId" [src]="getMediumImg()" alt="company logo"
                default="assets/default_image.jpg" />

              <ion-icon style="margin-bottom: 3%;" *ngIf="logoId" name="close-circle-outline"
                class="remove-image pointer-cursor" color="primary" (click)="removeLogo()">
              </ion-icon>
              <br />
              <div style="margin-top: 10px;">
                <ion-icon style="margin-left: 5px;" name="camera"></ion-icon>
                <ion-label *ngIf="!image" class="edit-profile pointer-cursor" color="secondary"
                  [translate]="'global.user.logo'" (click)="openEditLogoModal()">
                </ion-label>
                <ion-label *ngIf="image" class="edit-profile pointer-cursor" color="secondary"
                  [translate]="'global.user.logoCompany'" (click)="openEditLogoModal()">
                </ion-label>
              </div>
            </div>

            <div class="flex-row align-end checkbox-label-margin">
              <button class="siila-submit login-btn block-btn" (click)="submitBasicAccount()" mat-raised-button
                type="submit" [disabled]="
                  !registerForm?.controls.detailGroup.valid ||
                  (selectedCompany.id === 0 && !registerForm.controls.detailGroup.value.companySelected)
                ">
                {{ 'global.register.getStarted' | translate | uppercase }}
              </button>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </ion-row>
</ion-content> -->
