/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./info-modal.component";
var styles_InfoModalComponent = [i0.styles];
var RenderType_InfoModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoModalComponent, data: {} });
export { RenderType_InfoModalComponent as RenderType_InfoModalComponent };
export function View_InfoModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 43, "div", [["class", "basic-info-modal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 17, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(3, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, 0, 6, "ion-col", [["size", "11"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(6, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "ion-title", [["class", "header header-title"]], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(9, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(10, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(13, 0, null, 0, 5, "ion-col", [["size", "1"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(14, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { size: [0, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "ion-icon", [["class", "close-icon"], ["name", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(17, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(21, 0, null, null, 9, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(22, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(24, 0, null, 0, 5, "ion-col", [], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(25, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(27, 0, null, 0, 1, "p", [["class", "body-message"]], null, null, null, null, null)), i1.ɵdid(28, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(32, 0, null, null, 10, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(33, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(35, 0, null, 0, 6, "ion-col", [["class", "footer"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(36, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(38, 0, null, 0, 2, "ion-button", [["class", "close-button"], ["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(39, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), i1.ɵdid(40, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "11"; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.headerTitle; _ck(_v, 10, 0, currVal_1); var currVal_2 = "1"; _ck(_v, 14, 0, currVal_2); var currVal_3 = "close"; _ck(_v, 17, 0, currVal_3); var currVal_4 = _co.bodyMessage; _ck(_v, 28, 0, currVal_4); var currVal_5 = "secondary"; _ck(_v, 39, 0, currVal_5); var currVal_6 = "Close"; _ck(_v, 40, 0, currVal_6); }, null); }
export function View_InfoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "info-modal", [], null, null, null, View_InfoModalComponent_0, RenderType_InfoModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.InfoModalComponent, [i3.ModalController], null, null)], null, null); }
var InfoModalComponentNgFactory = i1.ɵccf("info-modal", i5.InfoModalComponent, View_InfoModalComponent_Host_0, { headerTitle: "headerTitle", bodyMessage: "bodyMessage", data: "data", extraData: "extraData" }, {}, []);
export { InfoModalComponentNgFactory as InfoModalComponentNgFactory };
