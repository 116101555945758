import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
var ListingModalService = /** @class */ (function () {
    function ListingModalService(modalCtrl, router, platform) {
        this.modalCtrl = modalCtrl;
        this.router = router;
        this.platform = platform;
    }
    ListingModalService.prototype.ngOnInit = function () { };
    ListingModalService.prototype.ngOnDestroy = function () { };
    ListingModalService.prototype.presentModal = function (modalOptions, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalCtrl.create(modalOptions)];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function () {
                            if (callback) {
                                callback();
                            }
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingModalService.prototype.dismiss = function () {
        this.modalCtrl.dismiss();
    };
    return ListingModalService;
}());
export { ListingModalService };
