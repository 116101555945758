import * as tslib_1 from "tslib";
import { SpotBuildingsSearchService } from '@app/spot-buildings/services/spot-buildings-search.service';
var SpotCardSliderService = /** @class */ (function () {
    function SpotCardSliderService(spotBuildingSearchService) {
        this.spotBuildingSearchService = spotBuildingSearchService;
        this.sliderBreakPoints = {
            1: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 0,
                slideNextClass: 'preview-next-margin',
                slidePrevClass: 'preview-prev-margin'
            },
            900: {
                slidesPerGroup: 2,
                slidesPerView: 2,
                spaceBetween: 10
            },
            1300: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 10
            }
        };
        this.defaultSliderOpts = {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            slideVisibleClass: 'swiper-slide-visible',
            grabCursor: false,
            observer: true,
            preventInteractionOnTransition: true,
            breakpoints: this.sliderBreakPoints,
            pagination: false
        };
        this.defaultSearchRequest = {
            //listingClasses: ['FEATURED'],
            isFeaturedWithoutCheckingDate: true,
            randomOrder: true
        };
    }
    SpotCardSliderService.prototype.searchSpotsByCompany = function (page, pageSize, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ids, spotBuildings;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.findAllSpotBuildingIds(payload)];
                    case 1:
                        ids = (_a.sent()).body;
                        return [4 /*yield*/, this.getSpotBuildingsPaginated(ids, page, pageSize)];
                    case 2:
                        spotBuildings = _a.sent();
                        return [2 /*return*/, { spotBuildingIds: ids, spotBuildings: spotBuildings }];
                }
            });
        });
    };
    SpotCardSliderService.prototype.searchAllSpotIdsByCompany = function (payload) {
        return this.spotBuildingSearchService.searchSpotBuildingIds(payload, 1);
    };
    SpotCardSliderService.prototype.searchSpotsByCompanyAsync = function (spotIds, page, pageSize) {
        return this.spotBuildingSearchService.getSpotBuildingDetails(page, pageSize, spotIds);
    };
    SpotCardSliderService.prototype.findAllSpotBuildingIds = function (payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.spotBuildingSearchService.searchSpotBuildingIds(payload, 1)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result];
                }
            });
        });
    };
    SpotCardSliderService.prototype.getSpotBuildingsPaginated = function (spotIds, page, pageSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var spotBuildingList;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        spotBuildingList = [];
                        if (!spotIds || spotIds.length === 0)
                            return [2 /*return*/, spotBuildingList];
                        return [4 /*yield*/, this.spotBuildingSearchService.getSpotBuildingDetails(page, pageSize, spotIds)];
                    case 1:
                        spotBuildingList = _a.sent();
                        return [2 /*return*/, spotBuildingList];
                }
            });
        });
    };
    return SpotCardSliderService;
}());
export { SpotCardSliderService };
