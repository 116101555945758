import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'access-granted-component',
  template: `
    <div class="flex-col">
      <div class="flex-row" [ngClass]="{ 'flex-wrap': handsetMode }">
        <input style="width:100%; border: unset;" type="text" [(ngModel)]="accessPass" />
      </div>
      <mat-divider></mat-divider>
      <div class="flex-row" style="justify-content:space-evenly; padding-top:20px;">
        <button
          class="siila-submit login-btn"
          (click)="access()"
          [disabled]="check()"
          style="width:100%"
          mat-raised-button
        >
          <span translate="access">Access</span>
        </button>
      </div>
    </div>
  `,
  styles: [``]
})
export class AccessGrantedDialogComponent {
  title = 'Angular-Interceptor';
  handsetMode: boolean;
  accessPass: any;
  err: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _breakpoint: BreakpointObserver,
    private _d: MatDialog
  ) {
    this._breakpoint.observe([Breakpoints.Handset]).subscribe(result => (this.handsetMode = result.matches));
  }

  access() {
    localStorage.setItem('accessGranted', 'granted');
    this._d.closeAll();
  }

  check() {
    if (this.accessPass === 'spot!' || this.accessPass === 'SPOT!' || this.accessPass === 'Spot!') {
      return false;
    } else {
      return true;
    }
  }
}
