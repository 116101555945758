export const turnOff = {
  RECOMMENDATION: 'RECOMMENDATION',
  FAVORITES: 'FAVORITES',
  SPOT_SET: 'SPOT_SET'
};

export const urlPromotedRedirects = new Map<string, string>([
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31394',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-6/31394/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31394',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-6/31394/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30001',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/cuautitlan-izcalli/parque-industrial-la-joya-fibra-uno-la-joya-ii/30001/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30001',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/cuautitlan-izcalli/parque-industrial-la-joya-fibra-uno-la-joya-ii/30001/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31392',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-4/31392/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31392',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-4/31392/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11066',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/torre-sur-distrito-santa-fe/11066/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11066',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/torre-sur-distrito-santa-fe/11066/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34837',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-e/34837/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34837',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-e/34837/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34840',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-g/34840/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34840',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-g/34840/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30714',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/tultitlan/prologis-fresno-prologis-fresno-01/30714/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30714',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/tultitlan/prologis-fresno-prologis-fresno-01/30714/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/63779',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/cuautitlan-izcalli/nave-turbo-yarn/63779/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/63779',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/cuautitlan-izcalli/nave-turbo-yarn/63779/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35978',
    'https://siilaspot.com.mx/renta/industrial/aguascalientes/jesus-maria/parque-industrial-chichimeco-nave-84/35978/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35978',
    'https://siilaspot.com.mx/lease/industrial/aguascalientes/jesus-maria/parque-industrial-chichimeco-nave-84/35978/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/36046',
    'https://siilaspot.com.mx/renta/industrial/aguascalientes/san-francisco-de-los-romo/parque-industrial-san-francisco-iii-nave-8/36046/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/36046',
    'https://siilaspot.com.mx/lease/industrial/aguascalientes/san-francisco-de-los-romo/parque-industrial-san-francisco-iii-nave-8/36046/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/62259',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/santa-catarina/finsa-santa-catarina-11/62259/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/62259',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/santa-catarina/finsa-santa-catarina-11/62259/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10737',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/torre-manacar/10737/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10737',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/torre-manacar/10737/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34838',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-d/34838/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34838',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-d/34838/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/63333',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-fa/63333/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/63333',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-fa/63333/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10547',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/reforma-231/10547/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10547',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/reforma-231/10547/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10575',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/torre-blanca/10575/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10575',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/torre-blanca/10575/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35259',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/apolo-industrial-park-nave-2/35259/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35259',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/apolo-industrial-park-nave-2/35259/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10558',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-intelicorp/10558/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10558',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-intelicorp/10558/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10582',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/century-plaza/10582/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10582',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/century-plaza/10582/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38835',
    'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/wtc-confianza-b1/38835/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38835',
    'https://siilaspot.com.mx/lease/industrial/jalisco/el-salto/wtc-confianza-b1/38835/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30582',
    'https://siilaspot.com.mx/renta/industrial/jalisco/san-pedro-tlaquepaque/prologis-el-salto-park-el-salto-dist-ctr-3/30582/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30582',
    'https://siilaspot.com.mx/lease/industrial/jalisco/san-pedro-tlaquepaque/prologis-el-salto-park-el-salto-dist-ctr-3/30582/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/63728',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/pesqueria/prologis-apodaca-east-park-10/63728/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/63728',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/pesqueria/prologis-apodaca-east-park-10/63728/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35282',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/salamanca/bajio-industrial-park-nave-2/35282/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35282',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/salamanca/bajio-industrial-park-nave-2/35282/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39741',
    'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/parque-industrial-guadalajara-21/39741/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39741',
    'https://siilaspot.com.mx/lease/industrial/jalisco/el-salto/parque-industrial-guadalajara-21/39741/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39505',
    'https://siilaspot.com.mx/renta/industrial/jalisco/san-pedro-tlaquepaque/parque-industrial-tecnologico-ii-nave-5/39505/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39505',
    'https://siilaspot.com.mx/lease/industrial/jalisco/san-pedro-tlaquepaque/parque-industrial-tecnologico-ii-nave-5/39505/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10937',
    'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/metropoli-patriotismo-fase-1/10937/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10937',
    'https://siilaspot.com.mx/lease/office/cdmx/benito-juarez/metropoli-patriotismo-fase-1/10937/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39379',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/cpa-guadalajara-technology-park-22/39379/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39379',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/cpa-guadalajara-technology-park-22/39379/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10004',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/blu-corporativo/10004/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10004',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/blu-corporativo/10004/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39501',
    'https://siilaspot.com.mx/renta/industrial/jalisco/san-pedro-tlaquepaque/parque-industrial-tecnologico-ii-nave-1/39501/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39501',
    'https://siilaspot.com.mx/lease/industrial/jalisco/san-pedro-tlaquepaque/parque-industrial-tecnologico-ii-nave-1/39501/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10030',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-virreyes-el-dorito-/10030/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10030',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-virreyes-el-dorito-/10030/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10512',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/reforma-180/10512/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10512',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/reforma-180/10512/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35796',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-asia-nave-30/35796/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35796',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-asia-nave-30/35796/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/62566',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/nexxus-aeropuerto-industrial-park-01/62566/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/62566',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/nexxus-aeropuerto-industrial-park-01/62566/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10506',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/reforma-265/10506/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10506',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/reforma-265/10506/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/63475',
    'https://siilaspot.com.mx/renta/oficina/yucatan/temozon/sky-work/63475/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/63475',
    'https://siilaspot.com.mx/lease/office/yucatan/temozon/sky-work/63475/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35077',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/techno-park-santa-fe-iv-nave-1/35077/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35077',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/techno-park-santa-fe-iv-nave-1/35077/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10491',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/corporativo-magnus/10491/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10491',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/corporativo-magnus/10491/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31187',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/milenium-aeropuerto-business-park-man-02/31187/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31187',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/milenium-aeropuerto-business-park-man-02/31187/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10933',
    'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/corporativo-is-1631/10933/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10933',
    'https://siilaspot.com.mx/lease/office/cdmx/benito-juarez/corporativo-is-1631/10933/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10504',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-cuarzo-torre-a/10504/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10504',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-cuarzo-torre-a/10504/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35182',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/guanajuato/vynmsa-guanajuato-industrial-park-01/35182/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35182',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/guanajuato/vynmsa-guanajuato-industrial-park-01/35182/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10499',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/capital-reforma-torre-a-torre-niza/10499/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10499',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/capital-reforma-torre-a-torre-niza/10499/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30481',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/prologis-agua-fria-park-af-2/30481/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30481',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/prologis-agua-fria-park-af-2/30481/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10505',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-cuarzo-torre-b/10505/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10505',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-cuarzo-torre-b/10505/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35198',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-14/35198/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35198',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-14/35198/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35067',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/santa-fe-ii-industrial-park-nave-31/35067/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35067',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/santa-fe-ii-industrial-park-nave-31/35067/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venta/industrial/(.*)/(.*)/63683',
    'https://siilaspot.com.mx/venta/industrial/cdmx/xochimilco/bodega-tepepan/63683/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/63683',
    'https://siilaspot.com.mx/sale/industrial/cdmx/xochimilco/bodega-tepepan/63683/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/60252',
    'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/puntacero/60252/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/60252',
    'https://siilaspot.com.mx/lease/office/nuevo-leon/monterrey/puntacero/60252/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34839',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-fb/34839/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34839',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/apaseo-el-grande/advance-apaseo-industrial-park-building-fb/34839/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10479',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/centera-chapultepec/10479/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10479',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/centera-chapultepec/10479/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35031',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/santa-fe-i-industrial-park-nave-26/35031/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35031',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/santa-fe-i-industrial-park-nave-26/35031/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10606',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/zentrika-santa-fe-torre-a-torre-azul/10606/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10606',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/zentrika-santa-fe-torre-a-torre-azul/10606/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35032',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/santa-fe-i-industrial-park-nave-27/35032/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35032',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/santa-fe-i-industrial-park-nave-27/35032/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/60202',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/diamante-santa-fe-torre-c/60202/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/60202',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/diamante-santa-fe-torre-c/60202/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10003',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/corporativo-plaza-del-parque-torre-actinver/10003/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10003',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/corporativo-plaza-del-parque-torre-actinver/10003/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31801',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/santa-catarina/las-americas-garcia-industrial-park-tcc-01/31801/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31801',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/santa-catarina/las-americas-garcia-industrial-park-tcc-01/31801/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10511',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-del-angel/10511/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10511',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-del-angel/10511/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35797',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-asia-nave-31/35797/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35797',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-asia-nave-31/35797/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/64174',
    'https://siilaspot.com.mx/renta/industrial/chihuahua/ciudad-juarez/roca-desarrollos-juarez-sur-cj05/64174/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/64174',
    'https://siilaspot.com.mx/lease/industrial/chihuahua/ciudad-juarez/roca-desarrollos-juarez-sur-cj05/64174/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10528',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-diana/10528/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10528',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-diana/10528/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11018',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-m49/11018/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11018',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-m49/11018/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34817',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/celaya/amistad-bajio-industrial-bajio-nave-10/34817/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34817',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/celaya/amistad-bajio-industrial-bajio-nave-10/34817/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10537',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-mapfre/10537/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10537',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-mapfre/10537/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10566',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/torre-acuario/10566/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10566',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/torre-acuario/10566/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10510',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-new-york-life-torre-reforma-342/10510/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10510',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-new-york-life-torre-reforma-342/10510/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11065',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/corporativo-sony/11065/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11065',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/corporativo-sony/11065/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10574',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-ceo/10574/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10574',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-ceo/10574/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31117',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/general-escobedo/parque-industrial-libramiento-nave-1/31117/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31117',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/general-escobedo/parque-industrial-libramiento-nave-1/31117/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34804',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/villagran/villagran-industrial-park-nave-3/34804/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34804',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/villagran/villagran-industrial-park-nave-3/34804/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10571',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-world-plaza/10571/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10571',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-world-plaza/10571/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10535',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-reforma/10535/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10535',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-reforma/10535/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10494',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/cad/10494/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10494',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/cad/10494/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10183',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-del-parque/10183/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10183',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-del-parque/10183/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/36939',
    'https://siilaspot.com.mx/renta/industrial/baja-california-norte/tijuana/parque-industrial-pacifico-iii-nave-16/36939/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/36939',
    'https://siilaspot.com.mx/lease/industrial/baja-california-norte/tijuana/parque-industrial-pacifico-iii-nave-16/36939/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/60382',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/salinas-victoria/hofusan-industrial-park-b1/60382/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/60382',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/salinas-victoria/hofusan-industrial-park-b1/60382/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/37662',
    'https://siilaspot.com.mx/renta/industrial/chihuahua/ciudad-juarez/vesta-park-las-torres-b1/37662/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/37662',
    'https://siilaspot.com.mx/lease/industrial/chihuahua/ciudad-juarez/vesta-park-las-torres-b1/37662/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10557',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/downtown-santa-fe-torre-i/10557/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10557',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/downtown-santa-fe-torre-i/10557/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10561',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/samara-torre-b/10561/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10561',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/samara-torre-b/10561/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10514',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-reforma-222/10514/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10514',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-reforma-222/10514/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10990',
    'https://siilaspot.com.mx/renta/oficina/estado-de-mexico/naucalpan-de-juarez/corporativo-piramides/10990/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10990',
    'https://siilaspot.com.mx/lease/office/estado-de-mexico/naucalpan-de-juarez/corporativo-piramides/10990/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10507',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-reforma-latino-/10507/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10507',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-reforma-latino-/10507/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31297',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/santa-catarina/stand-alone-santa-catarina-mty-028/31297/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31297',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/santa-catarina/stand-alone-santa-catarina-mty-028/31297/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10570',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-zentrum/10570/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10570',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-zentrum/10570/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10597',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/diamante-santa-fe-torre-a/10597/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10597',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/diamante-santa-fe-torre-a/10597/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34831',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/celaya/amistad-sur-industrial-park-nave-2/34831/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34831',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/celaya/amistad-sur-industrial-park-nave-2/34831/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38272',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/arteaga/parque-industrial-server-nave-02/38272/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38272',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/arteaga/parque-industrial-server-nave-02/38272/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11014',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/cervantes-saavedra-251/11014/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11014',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/cervantes-saavedra-251/11014/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10562',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/samara-torre-c/10562/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10562',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/samara-torre-c/10562/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10563',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/samara-torre-d/10563/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10563',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/samara-torre-d/10563/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35020',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/santa-fe-i-industrial-park-nave-15-sil-sf-02/35020/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35020',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/santa-fe-i-industrial-park-nave-15-sil-sf-02/35020/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10158',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/antara-corporativo-ii/10158/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10158',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/antara-corporativo-ii/10158/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venta/industrial/(.*)/(.*)/63825',
    'https://siilaspot.com.mx/venta/industrial/estado-de-mexico/lerma/sensa-park-blvd-aeropuerto-02/63825/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/63825',
    'https://siilaspot.com.mx/sale/industrial/estado-de-mexico/lerma/sensa-park-blvd-aeropuerto-02/63825/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30138',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/saltillo/parque-industrial-alianza-derramadero-providencia-mal2/30138/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30138',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/saltillo/parque-industrial-alianza-derramadero-providencia-mal2/30138/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35199',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-15/35199/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35199',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-15/35199/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35212',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-28/35212/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35212',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-28/35212/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10560',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/samara-torre-a/10560/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10560',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/samara-torre-a/10560/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/61999',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/apodaca-technology-park-atp-b019/61999/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/61999',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/apodaca-technology-park-atp-b019/61999/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10555',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/downtown-santa-fe-torre-iii/10555/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10555',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/downtown-santa-fe-torre-iii/10555/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11007',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-homero/11007/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11007',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/corporativo-homero/11007/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10564',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/park-plaza-torre-i/10564/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10564',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/park-plaza-torre-i/10564/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10594',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/edificio-eurocenter/10594/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10594',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/edificio-eurocenter/10594/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10596',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-santa-fe-50/10596/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10596',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-santa-fe-50/10596/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11061',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/plaza-marine/11061/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11061',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/plaza-marine/11061/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10569',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-santa-fe-505/10569/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10569',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-santa-fe-505/10569/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10551',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/parque-santa-fe/10551/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10551',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/parque-santa-fe/10551/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10595',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/euro-ten/10595/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10595',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/euro-ten/10595/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10605',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/one-o-one/10605/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10605',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/one-o-one/10605/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10059',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-terret-torre-norte/10059/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10059',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/corporativo-terret-torre-norte/10059/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11067',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/park-view-santa-fe/11067/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11067',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/park-view-santa-fe/11067/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35258',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/apolo-industrial-park-nave-1/35258/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35258',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/apolo-industrial-park-nave-1/35258/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38078',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/santa-maria-industrial-park-santa-maria-b901/38078/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38078',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/santa-maria-industrial-park-santa-maria-b901/38078/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31627',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/prologis-apodaca-park-ppa-8/31627/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31627',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/prologis-apodaca-park-ppa-8/31627/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10565',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/park-plaza-torre-iii-the-tower-park-plaza/10565/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10565',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/park-plaza-torre-iii-the-tower-park-plaza/10565/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/60232',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/ejercito-nacional-676/60232/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/60232',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/ejercito-nacional-676/60232/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/63586',
    'https://siilaspot.com.mx/renta/industrial/chihuahua/ciudad-juarez/centro-industrial-juarez-fibrapl-05/63586/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/63586',
    'https://siilaspot.com.mx/lease/industrial/chihuahua/ciudad-juarez/centro-industrial-juarez-fibrapl-05/63586/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10678',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/homero-gold/10678/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10678',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/homero-gold/10678/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10073',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/ejercito-nacional-904/10073/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10073',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/ejercito-nacional-904/10073/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10556',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/downtown-santa-fe-torre-ii/10556/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10556',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/downtown-santa-fe-torre-ii/10556/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10042',
    'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/park-plaza-torre-ii/10042/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10042',
    'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/park-plaza-torre-ii/10042/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31924',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/queretaro-park-i-nave-2/31924/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31924',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/queretaro-park-i-nave-2/31924/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31150',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/cienega-de-flores/cpa-adn-logistics-center-adn-b002/31150/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31150',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/cienega-de-flores/cpa-adn-logistics-center-adn-b002/31150/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34240',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/euro-business-park-nave-5/34240/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34240',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/euro-business-park-nave-5/34240/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35717',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-nave-30/35717/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35717',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-nave-30/35717/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35712',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-nave-25/35712/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35712',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-nave-25/35712/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/63337',
    'https://siilaspot.com.mx/renta/industrial/baja-california-norte/tijuana/prologis-park-el-florido-florido-2/63337/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/63337',
    'https://siilaspot.com.mx/lease/industrial/baja-california-norte/tijuana/prologis-park-el-florido-florido-2/63337/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10064',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/plaza-arquimedes/10064/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10064',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/plaza-arquimedes/10064/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10002',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/altezza-palmas-i/10002/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10002',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/altezza-palmas-i/10002/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30823',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/tepotzotlan/micro-parque-la-teja-nave-1/30823/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30823',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/tepotzotlan/micro-parque-la-teja-nave-1/30823/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10040',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-polanco/10040/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10040',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-polanco/10040/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35723',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-nave-36/35723/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35723',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-nave-36/35723/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10035',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-optima-iii/10035/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10035',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-optima-iii/10035/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34627',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-pyme-nave-16/34627/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34627',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-pyme-nave-16/34627/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10264',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/vita-polanco/10264/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10264',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/vita-polanco/10264/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34635',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-pyme-nave-24/34635/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34635',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-pyme-nave-24/34635/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/32587',
    'https://siilaspot.com.mx/renta/industrial/baja-california-norte/mexicali/el-dorado-industrial-park-nave-totoaba/32587/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/32587',
    'https://siilaspot.com.mx/lease/industrial/baja-california-norte/mexicali/el-dorado-industrial-park-nave-totoaba/32587/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35737',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-tnip-282/35737/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35737',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/tres-naciones-industrial-park-tnip-282/35737/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10087',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/plaza-carso-palmas/10087/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10087',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/plaza-carso-palmas/10087/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10092',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/espacio-lomas/10092/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10092',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/espacio-lomas/10092/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10982',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-gamon-120/10982/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10982',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-gamon-120/10982/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10980',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-magnum/10980/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10980',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-magnum/10980/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31389',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-1/31389/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31389',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-1/31389/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10033',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-optima-i/10033/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10033',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-optima-i/10033/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10034',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/torre-optima-ii/10034/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10034',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/torre-optima-ii/10034/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34636',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-pyme-nave-25/34636/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34636',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-pyme-nave-25/34636/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/60362',
    'https://siilaspot.com.mx/renta/oficina/veracruz-de-ignacio-de-la-llave/boca-del-rio/torre-1519/60362/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/60362',
    'https://siilaspot.com.mx/lease/office/veracruz-de-ignacio-de-la-llave/boca-del-rio/torre-1519/60362/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/11246',
    'https://siilaspot.com.mx/renta/oficina/jalisco/zapopan/torre-alfil/11246/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/11246',
    'https://siilaspot.com.mx/lease/office/jalisco/zapopan/torre-alfil/11246/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35275',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/apolo-industrial-park-nave-18/35275/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35275',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/apolo-industrial-park-nave-18/35275/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34053',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-54/34053/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34053',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-54/34053/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34427',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-tecnologico-innovacion-queretaro-nave-3/34427/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34427',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-tecnologico-innovacion-queretaro-nave-3/34427/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34426',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-tecnologico-innovacion-queretaro-nave-2/34426/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34426',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-tecnologico-innovacion-queretaro-nave-2/34426/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31930',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/queretaro-park-iii-nave-2/31930/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31930',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/queretaro-park-iii-nave-2/31930/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31932',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/queretaro-park-iv-nave-2/31932/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31932',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/queretaro-park-iv-nave-2/31932/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34362',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/zona-industrial-bernardo-quintana-nave-78/34362/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34362',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/zona-industrial-bernardo-quintana-nave-78/34362/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34373',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/zona-industrial-bernardo-quintana-nave-89/34373/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34373',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/zona-industrial-bernardo-quintana-nave-89/34373/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34237',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/euro-business-park-nave-2/34237/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34237',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/euro-business-park-nave-2/34237/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34259',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/zona-industrial-finsa-nave-14/34259/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34259',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/zona-industrial-finsa-nave-14/34259/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/61951',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/parque-industrial-ferran-iii-nave-2/61951/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/61951',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/parque-industrial-ferran-iii-nave-2/61951/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34359',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/zona-industrial-bernardo-quintana-nave-75/34359/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34359',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/zona-industrial-bernardo-quintana-nave-75/34359/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34246',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/zona-industrial-finsa-fibra-macquarie-qro011/34246/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34246',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/zona-industrial-finsa-fibra-macquarie-qro011/34246/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35284',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/salamanca/bajio-industrial-park-nave-4/35284/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35284',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/salamanca/bajio-industrial-park-nave-4/35284/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38072',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/cactus-valley-industrial-park-nave-02/38072/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38072',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/cactus-valley-industrial-park-nave-02/38072/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34238',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/euro-business-park-nave-3/34238/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34238',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/euro-business-park-nave-3/34238/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/32660',
    'https://siilaspot.com.mx/renta/industrial/baja-california-norte/mexicali/industrial-park-ex-xxi-nave-7/32660/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/32660',
    'https://siilaspot.com.mx/lease/industrial/baja-california-norte/mexicali/industrial-park-ex-xxi-nave-7/32660/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38464',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal003/38464/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38464',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal003/38464/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31081',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal004/31081/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31081',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal004/31081/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31031',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/california-monterrey-business-park-monterrey-business-park-ii/31031/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31031',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/california-monterrey-business-park-monterrey-business-park-ii/31031/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34229',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/finsa-queretaro-ii-nave-4/34229/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34229',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/finsa-queretaro-ii-nave-4/34229/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31025',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/general-escobedo/california-tecnocentro-i-tecnocentro-ii/31025/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31025',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/general-escobedo/california-tecnocentro-i-tecnocentro-ii/31025/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39360',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/cpa-guadalajara-technology-park-12/39360/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39360',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/cpa-guadalajara-technology-park-12/39360/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/62185',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/poniente-industrial-park-b1/62185/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/62185',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/poniente-industrial-park-b1/62185/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10938',
    'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/metropoli-patriotismo-fase-2/10938/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10938',
    'https://siilaspot.com.mx/lease/office/cdmx/benito-juarez/metropoli-patriotismo-fase-2/10938/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/60217',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/capital-reforma-torre-b-torre-reforma/60217/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/60217',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/capital-reforma-torre-b-torre-reforma/60217/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30599',
    'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/prologis-park-los-altos-04/30599/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30599',
    'https://siilaspot.com.mx/lease/industrial/jalisco/el-salto/prologis-park-los-altos-04/30599/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34184',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/aereospace-industrial-park-nave-1/34184/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34184',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/aereospace-industrial-park-nave-1/34184/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/60718',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/flexpark-apodaca-industrial-park-nave-1/60718/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/60718',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/flexpark-apodaca-industrial-park-nave-1/60718/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35211',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-27/35211/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35211',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/castro-del-rio-industrial-park-nave-27/35211/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10543',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/centro-bursatil-torre-reforma-axtel/10543/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10543',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/centro-bursatil-torre-reforma-axtel/10543/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10036',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/centro-corporativo-forum/10036/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10036',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/centro-corporativo-forum/10036/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/60719',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/flexpark-apodaca-industrial-park-nave-2/60719/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/60719',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/flexpark-apodaca-industrial-park-nave-2/60719/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venta/industrial/(.*)/(.*)/60382',
    'https://siilaspot.com.mx/venta/industrial/nuevo-leon/salinas-victoria/hofusan-industrial-park-b1/60382/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/60382',
    'https://siilaspot.com.mx/sale/industrial/nuevo-leon/salinas-victoria/hofusan-industrial-park-b1/60382/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10659',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/conjunto-legaria-torre-i/10659/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10659',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/conjunto-legaria-torre-i/10659/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10134',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-lomas-candela/10134/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10134',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/corporativo-lomas-candela/10134/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10567',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/corporativo-opcion-santa-fe-3/10567/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10567',
    'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/corporativo-opcion-santa-fe-3/10567/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31847',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/general-escobedo/igs-mty-escobedo-ii/31847/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31847',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/general-escobedo/igs-mty-escobedo-ii/31847/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/37614',
    'https://siilaspot.com.mx/renta/industrial/chihuahua/ciudad-juarez/parque-industrial-intermex-b9/37614/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/37614',
    'https://siilaspot.com.mx/lease/industrial/chihuahua/ciudad-juarez/parque-industrial-intermex-b9/37614/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10525',
    'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/corporativo-reforma-diana/10525/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10525',
    'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/corporativo-reforma-diana/10525/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38837',
    'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/el-salto-park-nave-2/38837/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38837',
    'https://siilaspot.com.mx/lease/industrial/jalisco/el-salto/el-salto-park-nave-2/38837/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34239',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/euro-business-park-nave-4/34239/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34239',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/euro-business-park-nave-4/34239/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35886',
    'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/finsa-aguascalientes-03/35886/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35886',
    'https://siilaspot.com.mx/lease/industrial/aguascalientes/aguascalientes/finsa-aguascalientes-03/35886/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38462',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal006/38462/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38462',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal006/38462/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31077',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal007/31077/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31077',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/finsa-coahuila-industrial-park-sal007/31077/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35168',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/fipasi-industrial-park-nave-13/35168/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35168',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/fipasi-industrial-park-nave-13/35168/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/37944',
    'https://siilaspot.com.mx/renta/industrial/chihuahua/ciudad-juarez/juarez-industrial-park-nave-16/37944/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/37944',
    'https://siilaspot.com.mx/lease/industrial/chihuahua/ciudad-juarez/juarez-industrial-park-nave-16/37944/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/32589',
    'https://siilaspot.com.mx/renta/industrial/baja-california-norte/mexicali/inqbate-mexicali-building-b/32589/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/32589',
    'https://siilaspot.com.mx/lease/industrial/baja-california-norte/mexicali/inqbate-mexicali-building-b/32589/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31743',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/kalos-encarnacion-ii-ke-ii-03/31743/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31743',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/kalos-encarnacion-ii-ke-ii-03/31743/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34244',
    'https://siilaspot.com.mx/renta/industrial/queretaro/el-marques/zona-industrial-finsa-nave-3/34244/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34244',
    'https://siilaspot.com.mx/lease/industrial/queretaro/el-marques/zona-industrial-finsa-nave-3/34244/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/32025',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/apodaca/la-silla-apodaca-industrial-park-apodaca-spec-iii/32025/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/32025',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/apodaca/la-silla-apodaca-industrial-park-apodaca-spec-iii/32025/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35834',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-nave-68/35834/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35834',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-nave-68/35834/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35842',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-nave-76/35842/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35842',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-nave-76/35842/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35870',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-europa-nave-98/35870/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35870',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-europa-nave-98/35870/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35824',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-slp-10/35824/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35824',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-slp-10/35824/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35773',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-7/35773/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35773',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-7/35773/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35787',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-21/35787/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35787',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-21/35787/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/63105',
    'https://siilaspot.com.mx/renta/industrial/cdmx/iztapalapa/nicolas-bravo-225/63105/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/63105',
    'https://siilaspot.com.mx/lease/industrial/cdmx/iztapalapa/nicolas-bravo-225/63105/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35279',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/parque-central-guanajuato-pcg-012/35279/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35279',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/parque-central-guanajuato-pcg-012/35279/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35963',
    'https://siilaspot.com.mx/renta/industrial/aguascalientes/jesus-maria/parque-industrial-chichimeco-nave-69/35963/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35963',
    'https://siilaspot.com.mx/lease/industrial/aguascalientes/jesus-maria/parque-industrial-chichimeco-nave-69/35963/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38406',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/saltillo/parque-industrial-derramadero-mencorsa-nave-02/38406/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38406',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/saltillo/parque-industrial-derramadero-mencorsa-nave-02/38406/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34580',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-el-marques-nave-103/34580/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34580',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-el-marques-nave-103/34580/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39392',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/parque-industrial-ferran-iii-nave-1/39392/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39392',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/parque-industrial-ferran-iii-nave-1/39392/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31120',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/general-escobedo/parque-industrial-libramiento-nave-4/31120/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31120',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/general-escobedo/parque-industrial-libramiento-nave-4/31120/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34105',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-106/34105/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34105',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-106/34105/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34121',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-122/34121/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34121',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-122/34121/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34127',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-128/34127/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34127',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-128/34127/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34069',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-70/34069/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34069',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-70/34069/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34084',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-85/34084/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34084',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-85/34084/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38852',
    'https://siilaspot.com.mx/renta/industrial/jalisco/tlajomulco-de-zuniga/parque-industrial-tecnologico-pit4-nave-6/38852/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38852',
    'https://siilaspot.com.mx/lease/industrial/jalisco/tlajomulco-de-zuniga/parque-industrial-tecnologico-pit4-nave-6/38852/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/39504',
    'https://siilaspot.com.mx/renta/industrial/jalisco/san-pedro-tlaquepaque/parque-industrial-tecnologico-ii-nave-4/39504/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/39504',
    'https://siilaspot.com.mx/lease/industrial/jalisco/san-pedro-tlaquepaque/parque-industrial-tecnologico-ii-nave-4/39504/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30781',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/cuautitlan-izcalli/parque-logistico-el-peral-cedis-c/30781/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30781',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/cuautitlan-izcalli/parque-logistico-el-peral-cedis-c/30781/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30595',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/parque-pinar-empresarial-nave-3/30595/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30595',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/parque-pinar-empresarial-nave-3/30595/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/10687',
    'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/mitikah-torre-m/10687/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/10687',
    'https://siilaspot.com.mx/lease/office/cdmx/benito-juarez/mitikah-torre-m/10687/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)venta/industrial/(.*)/(.*)/63684',
    'https://siilaspot.com.mx/venta/industrial/morelos/ayala/propiedad-industrial-ignacio-allende/63684/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)sale/industrial/(.*)/(.*)/63684',
    'https://siilaspot.com.mx/sale/industrial/morelos/ayala/propiedad-industrial-ignacio-allende/63684/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/63470',
    'https://siilaspot.com.mx/renta/oficina/yucatan/temozon/the-sky/63470/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/63470',
    'https://siilaspot.com.mx/lease/office/yucatan/temozon/the-sky/63470/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38470',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/saltillo/parque-industrial-vynmsa-ramos-arizpe-i-spec-f/38470/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38470',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/saltillo/parque-industrial-vynmsa-ramos-arizpe-i-spec-f/38470/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34280',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/zona-industrial-finsa-nave-35/34280/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34280',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/zona-industrial-finsa-nave-35/34280/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38298',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/arteaga/stand-alone-arteaga-nave-01/38298/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38298',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/arteaga/stand-alone-arteaga-nave-01/38298/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35830',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-europa-nave-64/35830/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35830',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/logistik-ii-industrial-park-ensamble-logistico-europa-nave-64/35830/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35805',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-nave-39/35805/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35805',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-ii-industrial-park-nave-39/35805/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35770',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-4/35770/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35770',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-4/35770/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35788',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/logistik-industrial-park-slp-vi/35788/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35788',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/logistik-industrial-park-slp-vi/35788/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35786',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-20/35786/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35786',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/logistik-industrial-park-nave-20/35786/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35516',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/millennium-industrial-park-slp-multitenant-iv/35516/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35516',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/millennium-industrial-park-slp-multitenant-iv/35516/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31367',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/naucalpan-de-juarez/negra-modelo-colliers/31367/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31367',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/naucalpan-de-juarez/negra-modelo-colliers/31367/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31262',
    'https://siilaspot.com.mx/renta/industrial/nuevo-leon/general-escobedo/nexxus-escobedo-center-industrial-park-mty-035/31262/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31262',
    'https://siilaspot.com.mx/lease/industrial/nuevo-leon/general-escobedo/nexxus-escobedo-center-industrial-park-mty-035/31262/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/32577',
    'https://siilaspot.com.mx/renta/industrial/baja-california-norte/mexicali/industrial-park-palaco-nave-9/32577/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/32577',
    'https://siilaspot.com.mx/lease/industrial/baja-california-norte/mexicali/industrial-park-palaco-nave-9/32577/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35280',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/irapuato/parque-central-guanajuato-ir01-inv/35280/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35280',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/irapuato/parque-central-guanajuato-ir01-inv/35280/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34853',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/celaya/parque-industrial-buena-opcion-nave-7/34853/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34853',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/celaya/parque-industrial-buena-opcion-nave-7/34853/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38290',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/arteaga/parque-industrial-fundadores-nave-f/38290/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38290',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/arteaga/parque-industrial-fundadores-nave-f/38290/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34595',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-la-bomba-b-delta/34595/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34595',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-la-bomba-b-delta/34595/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/61496',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/san-jose-iturbide/parque-industrial-opcion-prologis-park-opcion-edificio-1/61496/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/61496',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/san-jose-iturbide/parque-industrial-opcion-prologis-park-opcion-edificio-1/61496/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34010',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-11/34010/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34010',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-11/34010/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34122',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-123/34122/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34122',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-123/34122/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/34007',
    'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-8/34007/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/34007',
    'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/parque-industrial-queretaro-piq-8/34007/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30776',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/cuautitlan-izcalli/parque-logistico-el-peral-cedis-1/30776/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30776',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/cuautitlan-izcalli/parque-logistico-el-peral-cedis-1/30776/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30594',
    'https://siilaspot.com.mx/renta/industrial/jalisco/zapopan/parque-pinar-empresarial-nave-2/30594/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30594',
    'https://siilaspot.com.mx/lease/industrial/jalisco/zapopan/parque-pinar-empresarial-nave-2/30594/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/35105',
    'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/santa-fe-iv-industrial-park-nave-35/35105/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/35105',
    'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/santa-fe-iv-industrial-park-nave-35/35105/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/31393',
    'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-5/31393/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/31393',
    'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/tepotzotlan/parque-industrial-tepozpark-i-parque-industrial-tepotzotlan-i-5/31393/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/complejo-industrial/(.*)/(.*)/61443',
    'https://siilaspot.com.mx/renta/complejo-industrial/baja-california-norte/tijuana/vesta-park-alamar/61443/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial-complex/(.*)/(.*)/61443',
    'https://siilaspot.com.mx/lease/industrial-complex/baja-california-norte/tijuana/vesta-park-alamar/61443/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/30087',
    'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/ecologisticspark/30087/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/30087',
    'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/ecologisticspark/30087/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/industrial/(.*)/(.*)/38132',
    'https://siilaspot.com.mx/renta/industrial/coahuila-de-zaragoza/ramos-arizpe/zona-industrial-ramos-arizpe-nave-12/38132/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/industrial/(.*)/(.*)/38132',
    'https://siilaspot.com.mx/lease/industrial/coahuila-de-zaragoza/ramos-arizpe/zona-industrial-ramos-arizpe-nave-12/38132/en'
  ],
  [
    '(.*)/spot-property/detail/(.*)renta/oficina/(.*)/(.*)/63647',
    'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/work-polanco/63647/es'
  ],
  [
    '(.*)/spot-property/detail/(.*)lease/office/(.*)/(.*)/63647',
    'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/work-polanco/63647/en'
  ]
]);

export const urlRedirects = new Map();
urlRedirects.set('https://siilaspot.com.mx/lang/es', 'https://siilaspot.com.mx/es');
urlRedirects.set('https://siilaspot.com.mx/lang/en', 'https://siilaspot.com.mx/en');
urlRedirects.set('https://siilaspot.com.mx/home', 'https://siilaspot.com.mx/es');
urlRedirects.set('https://siilaspot.com.mx/home/lang/es', 'https://siilaspot.com.mx/es');
urlRedirects.set('https://siilaspot.com.mx/home/lang/en', 'https://siilaspot.com.mx/en');
urlRedirects.set('https://siilaspot.com.mx/spot-search', 'https://siilaspot.com.mx/es');
urlRedirects.set('https://siilaspot.com.mx/spot-search/lang/es', 'https://siilaspot.com.mx/es');
urlRedirects.set('https://siilaspot.com.mx/spot-search/lang/en', 'https://siilaspot.com.mx/en');
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-san-luis-potosi-vpslp-04/vesta/63607',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/vesta-park-san-luis-potosi-vpslp-04/63607/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-san-luis-potosi-vpslp-04/vesta/63607/lang/es',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/vesta-park-san-luis-potosi-vpslp-04/63607/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-san-luis-potosi-vpslp-04/vesta/63607/lang/en',
  'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/vesta-park-san-luis-potosi-vpslp-04/63607/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-leon-industrial-park-spec-viii/vynmsa/63956',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/leon/vynmsa-leon-industrial-park-spec-viii/63956/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-leon-industrial-park-spec-viii/vynmsa/63956/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/leon/vynmsa-leon-industrial-park-spec-viii/63956/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vynmsa-leon-industrial-park-spec-viii/vynmsa/63956/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/leon/vynmsa-leon-industrial-park-spec-viii/63956/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-i-industrial-park-building-v/advance-real-estate/35016',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-i-industrial-park-building-v/35016/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-i-industrial-park-building-v/advance-real-estate/35016/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-i-industrial-park-building-v/35016/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/advance-santa-fe-i-industrial-park-building-v/advance-real-estate/35016/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/advance-santa-fe-i-industrial-park-building-v/35016/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/venta/oficina/polanco/plaza-polanco-torre-c/frel/10101',
  'https://siilaspot.com.mx/venta/oficina/cdmx/miguel-hidalgo/plaza-polanco-torre-c/10101/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/venta/oficina/polanco/plaza-polanco-torre-c/frel/10101/lang/es',
  'https://siilaspot.com.mx/venta/oficina/cdmx/miguel-hidalgo/plaza-polanco-torre-c/10101/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/sale/office/polanco/plaza-polanco-torre-c/frel/10101/lang/en',
  'https://siilaspot.com.mx/sale/office/cdmx/miguel-hidalgo/plaza-polanco-torre-c/10101/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/coworking/oficina/polanco/moliere-342/around/63472',
  'https://siilaspot.com.mx/coworking/oficina/cdmx/miguel-hidalgo/moliere-342/63472/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/coworking/oficina/polanco/moliere-342/around/63472/lang/es',
  'https://siilaspot.com.mx/coworking/oficina/cdmx/miguel-hidalgo/moliere-342/63472/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/coworking/office/polanco/moliere-342/around/63472/lang/en',
  'https://siilaspot.com.mx/coworking/office/cdmx/miguel-hidalgo/moliere-342/63472/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/campus-cuautitlan-nave-1/bts-development/61752',
  'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/cuautitlan-izcalli/campus-cuautitlan-nave-1/61752/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/campus-cuautitlan-nave-1/bts-development/61752/lang/es',
  'https://siilaspot.com.mx/renta/industrial/estado-de-mexico/cuautitlan-izcalli/campus-cuautitlan-nave-1/61752/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/campus-cuautitlan-nave-1/bts-development/61752/lang/en',
  'https://siilaspot.com.mx/lease/industrial/estado-de-mexico/cuautitlan-izcalli/campus-cuautitlan-nave-1/61752/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/plaza-polanco-torre-c/frel/10101',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/plaza-polanco-torre-c/10101/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/plaza-polanco-torre-c/frel/10101/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/plaza-polanco-torre-c/10101/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/polanco/plaza-polanco-torre-c/frel/10101/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/plaza-polanco-torre-c/10101/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/coworking/oficina/roma-norte/around-roma-norte/around/63476',
  'https://siilaspot.com.mx/coworking/oficina/cdmx/cuauhtemoc/around-roma-norte/63476/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/coworking/oficina/roma-norte/around-roma-norte/around/63476/lang/es',
  'https://siilaspot.com.mx/coworking/oficina/cdmx/cuauhtemoc/around-roma-norte/63476/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/coworking/office/roma-norte/around-roma-norte/around/63476/lang/en',
  'https://siilaspot.com.mx/coworking/office/cdmx/cuauhtemoc/around-roma-norte/63476/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-ii-industrial-park-building-vi/advance-real-estate/35035',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-ii-industrial-park-building-vi/35035/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-ii-industrial-park-building-vi/advance-real-estate/35035/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-ii-industrial-park-building-vi/35035/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/advance-santa-fe-ii-industrial-park-building-vi/advance-real-estate/35035/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/advance-santa-fe-ii-industrial-park-building-vi/35035/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-ii-industrial-park-building-vii/advance-real-estate/35034',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-ii-industrial-park-building-vii/35034/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-ii-industrial-park-building-vii/advance-real-estate/35034/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-ii-industrial-park-building-vii/35034/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/advance-santa-fe-ii-industrial-park-building-vii/advance-real-estate/35034/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/advance-santa-fe-ii-industrial-park-building-vii/35034/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/corporativo-polanco/frel/10054',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-polanco/10054/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/corporativo-polanco/frel/10054/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-polanco/10054/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/polanco/corporativo-polanco/frel/10054/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/corporativo-polanco/10054/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-mirax/frel/10909',
  'https://siilaspot.com.mx/renta/oficina/estado-de-mexico/naucalpan-de-juarez/torre-mirax/10909/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-mirax/frel/10909/lang/es',
  'https://siilaspot.com.mx/renta/oficina/estado-de-mexico/naucalpan-de-juarez/torre-mirax/10909/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/torre-mirax/frel/10909/lang/en',
  'https://siilaspot.com.mx/lease/office/estado-de-mexico/naucalpan-de-juarez/torre-mirax/10909/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/lagrange-103/frel/10778',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/lagrange-103/10778/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/lagrange-103/frel/10778/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/lagrange-103/10778/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/polanco/lagrange-103/frel/10778/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/lagrange-103/10778/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-guanajuato-puerto-interior-ii-spec-c-vgpi/vynmsa/63297',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/vynmsa-guanajuato-puerto-interior-ii-spec-c-vgpi/63297/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-guanajuato-puerto-interior-ii-spec-c-vgpi/vynmsa/63297/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/vynmsa-guanajuato-puerto-interior-ii-spec-c-vgpi/63297/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vynmsa-guanajuato-puerto-interior-ii-spec-c-vgpi/vynmsa/63297/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/vynmsa-guanajuato-puerto-interior-ii-spec-c-vgpi/63297/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/santa-fe/the-summit-santa-fe/frel/60236',
  'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/the-summit-santa-fe/60236/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/santa-fe/the-summit-santa-fe/frel/60236/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/cuajimalpa-de-morelos/the-summit-santa-fe/60236/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/santa-fe/the-summit-santa-fe/frel/60236/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/cuajimalpa-de-morelos/the-summit-santa-fe/60236/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/florida/torre-siglum/grupo-surge/10709',
  'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/torre-siglum/10709/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/florida/torre-siglum/grupo-surge/10709/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/alvaro-obregon/torre-siglum/10709/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/florida/torre-siglum/grupo-surge/10709/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/alvaro-obregon/torre-siglum/10709/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/reforma/chapultepec-uno-r509/colliers/10539',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/chapultepec-uno-r509/10539/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/reforma/chapultepec-uno-r509/colliers/10539/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/chapultepec-uno-r509/10539/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/reforma/chapultepec-uno-r509/colliers/10539/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/chapultepec-uno-r509/10539/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/sur/centro-empresarial-nuevo-sur-torre-i/u-calli/10345',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/centro-empresarial-nuevo-sur-torre-i/10345/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/sur/centro-empresarial-nuevo-sur-torre-i/u-calli/10345/lang/es',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/centro-empresarial-nuevo-sur-torre-i/10345/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/sur/centro-empresarial-nuevo-sur-torre-i/u-calli/10345/lang/en',
  'https://siilaspot.com.mx/lease/office/nuevo-leon/monterrey/centro-empresarial-nuevo-sur-torre-i/10345/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-queretaro-vpq-04/vesta/62141',
  'https://siilaspot.com.mx/renta/industrial/queretaro/colon/vesta-park-queretaro-vpq-04/62141/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-queretaro-vpq-04/vesta/62141/lang/es',
  'https://siilaspot.com.mx/renta/industrial/queretaro/colon/vesta-park-queretaro-vpq-04/62141/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-queretaro-vpq-04/vesta/62141/lang/en',
  'https://siilaspot.com.mx/lease/industrial/queretaro/colon/vesta-park-queretaro-vpq-04/62141/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-san-luis-potosi-vpslp-01/vesta/35852',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/vesta-park-san-luis-potosi-vpslp-01/35852/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-san-luis-potosi-vpslp-01/vesta/35852/lang/es',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/vesta-park-san-luis-potosi-vpslp-01/35852/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-san-luis-potosi-vpslp-01/vesta/35852/lang/en',
  'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/vesta-park-san-luis-potosi-vpslp-01/35852/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-pocket-park-logistik-ii-spec-iii/vynmsa/63316',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/vynmsa-pocket-park-logistik-ii-spec-iii/63316/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-pocket-park-logistik-ii-spec-iii/vynmsa/63316/lang/es',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/villa-de-reyes/vynmsa-pocket-park-logistik-ii-spec-iii/63316/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vynmsa-pocket-park-logistik-ii-spec-iii/vynmsa/63316/lang/en',
  'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/villa-de-reyes/vynmsa-pocket-park-logistik-ii-spec-iii/63316/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/reforma/torre-mayor/colliers/10536',
  'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-mayor/10536/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/reforma/torre-mayor/colliers/10536/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/cuauhtemoc/torre-mayor/10536/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/reforma/torre-mayor/colliers/10536/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/cuauhtemoc/torre-mayor/10536/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-prisma/corporativo-torre-prisma-torre-prisma-horacio/frel/60253',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-torre-prisma-torre-prisma-horacio/60253/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-prisma/corporativo-torre-prisma-torre-prisma-horacio/frel/60253/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/corporativo-torre-prisma-torre-prisma-horacio/60253/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/torre-prisma/corporativo-torre-prisma-torre-prisma-horacio/frel/60253/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/corporativo-torre-prisma-torre-prisma-horacio/60253/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-helice/u-calli/61076',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/torre-helice/61076/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-helice/u-calli/61076/lang/es',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/torre-helice/61076/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/torre-helice/u-calli/61076/lang/en',
  'https://siilaspot.com.mx/lease/office/nuevo-leon/monterrey/torre-helice/61076/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/trebol-park/colliers/10898',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/trebol-park/10898/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/trebol-park/colliers/10898/lang/es',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/monterrey/trebol-park/10898/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/trebol-park/colliers/10898/lang/en',
  'https://siilaspot.com.mx/lease/office/nuevo-leon/monterrey/trebol-park/10898/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/proximityparks-monterrey-centro-01/proximity-parks/63439',
  'https://siilaspot.com.mx/renta/industrial/nuevo-leon/monterrey/proximityparks-monterrey-centro-01/63439/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/proximityparks-monterrey-centro-01/proximity-parks/63439/lang/es',
  'https://siilaspot.com.mx/renta/industrial/nuevo-leon/monterrey/proximityparks-monterrey-centro-01/63439/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/proximityparks-monterrey-centro-01/proximity-parks/63439/lang/en',
  'https://siilaspot.com.mx/lease/industrial/nuevo-leon/monterrey/proximityparks-monterrey-centro-01/63439/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-guadalajara-vpgdl-06/vesta/39242',
  'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/vesta-park-guadalajara-vpgdl-06/39242/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-guadalajara-vpgdl-06/vesta/39242/lang/es',
  'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/vesta-park-guadalajara-vpgdl-06/39242/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-guadalajara-vpgdl-06/vesta/39242/lang/en',
  'https://siilaspot.com.mx/lease/industrial/jalisco/el-salto/vesta-park-guadalajara-vpgdl-06/39242/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/insurgentes-716/grupo-surge/63986',
  'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/insurgentes-716/63986/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/insurgentes-716/grupo-surge/63986/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/insurgentes-716/63986/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/insurgentes-716/grupo-surge/63986/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/benito-juarez/insurgentes-716/63986/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-apaseo-industrial-park-spec-i/vynmsa/61789',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/vynmsa-apaseo-industrial-park-spec-i/61789/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-apaseo-industrial-park-spec-i/vynmsa/61789/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/apaseo-el-grande/vynmsa-apaseo-industrial-park-spec-i/61789/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vynmsa-apaseo-industrial-park-spec-i/vynmsa/61789/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/apaseo-el-grande/vynmsa-apaseo-industrial-park-spec-i/61789/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/venta/complejoindustrial/terra-park-condesa-lotes-industriales/terra-regia/63686',
  'https://siilaspot.com.mx/venta/complejo-industrial/nuevo-leon/juarez/terra-park-condesa-lotes-industriales/63686/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/venta/complejoindustrial/terra-park-condesa-lotes-industriales/terra-regia/63686/lang/es',
  'https://siilaspot.com.mx/venta/complejo-industrial/nuevo-leon/juarez/terra-park-condesa-lotes-industriales/63686/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/sale/industrialcomplex/terra-park-condesa-lotes-industriales/terra-regia/63686/lang/en',
  'https://siilaspot.com.mx/sale/industrial-complex/nuevo-leon/juarez/terra-park-condesa-lotes-industriales/63686/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-i-industrial-park-building-ii/advance-real-estate/35018',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-i-industrial-park-building-ii/35018/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/advance-santa-fe-i-industrial-park-building-ii/advance-real-estate/35018/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/advance-santa-fe-i-industrial-park-building-ii/35018/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/advance-santa-fe-i-industrial-park-building-ii/advance-real-estate/35018/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/advance-santa-fe-i-industrial-park-building-ii/35018/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/q7001-torre-i/colliers/11339',
  'https://siilaspot.com.mx/renta/oficina/queretaro/queretaro/q7001-torre-i/11339/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/q7001-torre-i/colliers/11339/lang/es',
  'https://siilaspot.com.mx/renta/oficina/queretaro/queretaro/q7001-torre-i/11339/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/q7001-torre-i/colliers/11339/lang/en',
  'https://siilaspot.com.mx/lease/office/queretaro/queretaro/q7001-torre-i/11339/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/aereospace-industrial-park-11-paq-35-/vesta/34194',
  'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/aereospace-industrial-park-11-paq-35-/34194/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/aereospace-industrial-park-11-paq-35-/vesta/34194/lang/es',
  'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/aereospace-industrial-park-11-paq-35-/34194/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/aereospace-industrial-park-11-paq-35-/vesta/34194/lang/en',
  'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/aereospace-industrial-park-11-paq-35-/34194/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/antara-corporativo-torre-b/colliers/60305',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/antara-corporativo-torre-b/60305/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/antara-corporativo-torre-b/colliers/60305/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/antara-corporativo-torre-b/60305/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/polanco/antara-corporativo-torre-b/colliers/60305/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/antara-corporativo-torre-b/60305/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/juruquilla/queretaro-business-park-torre-ii/u-calli/11307',
  'https://siilaspot.com.mx/renta/oficina/queretaro/queretaro/queretaro-business-park-torre-ii/11307/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/juruquilla/queretaro-business-park-torre-ii/u-calli/11307/lang/es',
  'https://siilaspot.com.mx/renta/oficina/queretaro/queretaro/queretaro-business-park-torre-ii/11307/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/juruquilla/queretaro-business-park-torre-ii/u-calli/11307/lang/en',
  'https://siilaspot.com.mx/lease/office/queretaro/queretaro/queretaro-business-park-torre-ii/11307/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-capita/colliers/10006',
  'https://siilaspot.com.mx/renta/oficina/queretaro/queretaro/torre-capita/10006/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/torre-capita/colliers/10006/lang/es',
  'https://siilaspot.com.mx/renta/oficina/queretaro/queretaro/torre-capita/10006/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/torre-capita/colliers/10006/lang/en',
  'https://siilaspot.com.mx/lease/office/queretaro/queretaro/torre-capita/10006/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-aguascalientes-02/vesta/36076',
  'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/vesta-park-aguascalientes-02/36076/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-aguascalientes-02/vesta/36076/lang/es',
  'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/vesta-park-aguascalientes-02/36076/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-aguascalientes-02/vesta/36076/lang/en',
  'https://siilaspot.com.mx/lease/industrial/aguascalientes/aguascalientes/vesta-park-aguascalientes-02/36076/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-queretaro-vpq-05/vesta/62236',
  'https://siilaspot.com.mx/renta/industrial/queretaro/colon/vesta-park-queretaro-vpq-05/62236/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-queretaro-vpq-05/vesta/62236/lang/es',
  'https://siilaspot.com.mx/renta/industrial/queretaro/colon/vesta-park-queretaro-vpq-05/62236/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-queretaro-vpq-05/vesta/62236/lang/en',
  'https://siilaspot.com.mx/lease/industrial/queretaro/colon/vesta-park-queretaro-vpq-05/62236/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/diamante-industrial-park-04/colliers/31890',
  'https://siilaspot.com.mx/renta/industrial/nuevo-leon/cienega-de-flores/diamante-industrial-park-04/31890/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/diamante-industrial-park-04/colliers/31890/lang/es',
  'https://siilaspot.com.mx/renta/industrial/nuevo-leon/cienega-de-flores/diamante-industrial-park-04/31890/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/diamante-industrial-park-04/colliers/31890/lang/en',
  'https://siilaspot.com.mx/lease/industrial/nuevo-leon/cienega-de-flores/diamante-industrial-park-04/31890/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/pbq-3-industrial-building/vesta/34286',
  'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/pbq-3-industrial-building/34286/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/pbq-3-industrial-building/vesta/34286/lang/es',
  'https://siilaspot.com.mx/renta/industrial/queretaro/queretaro/pbq-3-industrial-building/34286/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/pbq-3-industrial-building/vesta/34286/lang/en',
  'https://siilaspot.com.mx/lease/industrial/queretaro/queretaro/pbq-3-industrial-building/34286/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/poligono-san-miguel-de-allende-sma-06/vesta/61765',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-06/61765/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/poligono-san-miguel-de-allende-sma-06/vesta/61765/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-06/61765/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/poligono-san-miguel-de-allende-sma-06/vesta/61765/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-06/61765/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/poligono-san-miguel-de-allende-sma-04/vesta/35298',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-04/35298/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/poligono-san-miguel-de-allende-sma-04/vesta/35298/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-04/35298/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/poligono-san-miguel-de-allende-sma-04/vesta/35298/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-04/35298/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/douki-seisan-mtf-iii/vesta/35879',
  'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/douki-seisan-mtf-iii/35879/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/douki-seisan-mtf-iii/vesta/35879/lang/es',
  'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/douki-seisan-mtf-iii/35879/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/douki-seisan-mtf-iii/vesta/35879/lang/en',
  'https://siilaspot.com.mx/lease/industrial/aguascalientes/aguascalientes/douki-seisan-mtf-iii/35879/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-guanajuato-i-pto-03/vesta/64040',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/vesta-park-guanajuato-i-pto-03/64040/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-guanajuato-i-pto-03/vesta/64040/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/vesta-park-guanajuato-i-pto-03/64040/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-guanajuato-i-pto-03/vesta/64040/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/vesta-park-guanajuato-i-pto-03/64040/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/del-valle/insurgentes-664/grupo-surge/63985',
  'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/insurgentes-664/63985/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/del-valle/insurgentes-664/grupo-surge/63985/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/benito-juarez/insurgentes-664/63985/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/del-valle/insurgentes-664/grupo-surge/63985/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/benito-juarez/insurgentes-664/63985/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/poligono-san-miguel-de-allende-sma-07/vesta/61764',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-07/61764/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/poligono-san-miguel-de-allende-sma-07/vesta/61764/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-07/61764/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/poligono-san-miguel-de-allende-sma-07/vesta/61764/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/san-miguel-de-allende/poligono-san-miguel-de-allende-sma-07/61764/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-guadalajara-vpgdl-08/vesta/62675',
  'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/vesta-park-guadalajara-vpgdl-08/62675/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-guadalajara-vpgdl-08/vesta/62675/lang/es',
  'https://siilaspot.com.mx/renta/industrial/jalisco/el-salto/vesta-park-guadalajara-vpgdl-08/62675/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-guadalajara-vpgdl-08/vesta/62675/lang/en',
  'https://siilaspot.com.mx/lease/industrial/jalisco/el-salto/vesta-park-guadalajara-vpgdl-08/62675/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/colinas-de-silao-industrial-park-plc-141/vesta/35149',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/colinas-de-silao-industrial-park-plc-141/35149/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/colinas-de-silao-industrial-park-plc-141/vesta/35149/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/silao/colinas-de-silao-industrial-park-plc-141/35149/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/colinas-de-silao-industrial-park-plc-141/vesta/35149/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/silao/colinas-de-silao-industrial-park-plc-141/35149/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/proximity-parks-san-pedro-01/proximity-parks/63477',
  'https://siilaspot.com.mx/renta/industrial/nuevo-leon/san-pedro-garza-garcia/proximity-parks-san-pedro-01/63477/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/proximity-parks-san-pedro-01/proximity-parks/63477/lang/es',
  'https://siilaspot.com.mx/renta/industrial/nuevo-leon/san-pedro-garza-garcia/proximity-parks-san-pedro-01/63477/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/proximity-parks-san-pedro-01/proximity-parks/63477/lang/en',
  'https://siilaspot.com.mx/lease/industrial/nuevo-leon/san-pedro-garza-garcia/proximity-parks-san-pedro-01/63477/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-aguascalientes-vpa-01/vesta/36075',
  'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/vesta-park-aguascalientes-vpa-01/36075/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-aguascalientes-vpa-01/vesta/36075/lang/es',
  'https://siilaspot.com.mx/renta/industrial/aguascalientes/aguascalientes/vesta-park-aguascalientes-vpa-01/36075/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-aguascalientes-vpa-01/vesta/36075/lang/en',
  'https://siilaspot.com.mx/lease/industrial/aguascalientes/aguascalientes/vesta-park-aguascalientes-vpa-01/36075/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-celaya-industrial-park-spec-ii/vynmsa/61791',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/celaya/vynmsa-celaya-industrial-park-spec-ii/61791/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-celaya-industrial-park-spec-ii/vynmsa/61791/lang/es',
  'https://siilaspot.com.mx/renta/industrial/guanajuato/celaya/vynmsa-celaya-industrial-park-spec-ii/61791/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vynmsa-celaya-industrial-park-spec-ii/vynmsa/61791/lang/en',
  'https://siilaspot.com.mx/lease/industrial/guanajuato/celaya/vynmsa-celaya-industrial-park-spec-ii/61791/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-queretaro-industrial-park-spec-iv/vynmsa/63624',
  'https://siilaspot.com.mx/renta/industrial/queretaro/el-marques/vynmsa-queretaro-industrial-park-spec-iv/63624/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vynmsa-queretaro-industrial-park-spec-iv/vynmsa/63624/lang/es',
  'https://siilaspot.com.mx/renta/industrial/queretaro/el-marques/vynmsa-queretaro-industrial-park-spec-iv/63624/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vynmsa-queretaro-industrial-park-spec-iv/vynmsa/63624/lang/en',
  'https://siilaspot.com.mx/lease/industrial/queretaro/el-marques/vynmsa-queretaro-industrial-park-spec-iv/63624/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/wtc-industrial-park-huasteco-multitenant/jll/30134',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/wtc-industrial-park-huasteco-multitenant/30134/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/wtc-industrial-park-huasteco-multitenant/jll/30134/lang/es',
  'https://siilaspot.com.mx/renta/industrial/san-luis-potosi/san-luis-potosi/wtc-industrial-park-huasteco-multitenant/30134/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/wtc-industrial-park-huasteco-multitenant/jll/30134/lang/en',
  'https://siilaspot.com.mx/lease/industrial/san-luis-potosi/san-luis-potosi/wtc-industrial-park-huasteco-multitenant/30134/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/valle-oriente/parque-corporativo-valle-oriente-torre-11/u-calli/10413',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/san-pedro-garza-garcia/parque-corporativo-valle-oriente-torre-11/10413/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/valle-oriente/parque-corporativo-valle-oriente-torre-11/u-calli/10413/lang/es',
  'https://siilaspot.com.mx/renta/oficina/nuevo-leon/san-pedro-garza-garcia/parque-corporativo-valle-oriente-torre-11/10413/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/valle-oriente/parque-corporativo-valle-oriente-torre-11/u-calli/10413/lang/en',
  'https://siilaspot.com.mx/lease/office/nuevo-leon/san-pedro-garza-garcia/parque-corporativo-valle-oriente-torre-11/10413/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/plaza-carso-torre-ii/colliers/11177',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/plaza-carso-torre-ii/11177/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/oficina/polanco/plaza-carso-torre-ii/colliers/11177/lang/es',
  'https://siilaspot.com.mx/renta/oficina/cdmx/miguel-hidalgo/plaza-carso-torre-ii/11177/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/office/polanco/plaza-carso-torre-ii/colliers/11177/lang/en',
  'https://siilaspot.com.mx/lease/office/cdmx/miguel-hidalgo/plaza-carso-torre-ii/11177/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/proximityparks-guadalajara-centro/proximity-parks/63478',
  'https://siilaspot.com.mx/renta/industrial/jalisco/guadalajara/proximityparks-guadalajara-centro/63478/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/proximityparks-guadalajara-centro/proximity-parks/63478/lang/es',
  'https://siilaspot.com.mx/renta/industrial/jalisco/guadalajara/proximityparks-guadalajara-centro/63478/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/proximityparks-guadalajara-centro/proximity-parks/63478/lang/en',
  'https://siilaspot.com.mx/lease/industrial/jalisco/guadalajara/proximityparks-guadalajara-centro/63478/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-queretaro-vpq-07/vesta/62237',
  'https://siilaspot.com.mx/renta/industrial/queretaro/colon/vesta-park-queretaro-vpq-07/62237/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/alquiler/industrial/vesta-park-queretaro-vpq-07/vesta/62237/lang/es',
  'https://siilaspot.com.mx/renta/industrial/queretaro/colon/vesta-park-queretaro-vpq-07/62237/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/spot-property/detail/lease/industrial/vesta-park-queretaro-vpq-07/vesta/62237/lang/en',
  'https://siilaspot.com.mx/lease/industrial/queretaro/colon/vesta-park-queretaro-vpq-07/62237/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/u-calli/564590',
  'https://siilaspot.com.mx/empresa/u-calli/564590/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/u-calli/564590/lang/es',
  'https://siilaspot.com.mx/empresa/u-calli/564590/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/u-calli/564590/lang/en',
  'https://siilaspot.com.mx/company/u-calli/564590/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/bts-development/1222124',
  'https://siilaspot.com.mx/empresa/bts-development/1222124/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/bts-development/1222124/lang/es',
  'https://siilaspot.com.mx/empresa/bts-development/1222124/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/bts-development/1222124/lang/en',
  'https://siilaspot.com.mx/company/bts-development/1222124/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/frel/1812411',
  'https://siilaspot.com.mx/empresa/frel/1812411/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/frel/1812411/lang/es',
  'https://siilaspot.com.mx/empresa/frel/1812411/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/frel/1812411/lang/en',
  'https://siilaspot.com.mx/company/frel/1812411/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/advance-real-estate/501185',
  'https://siilaspot.com.mx/empresa/advance-real-estate/501185/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/advance-real-estate/501185/lang/es',
  'https://siilaspot.com.mx/empresa/advance-real-estate/501185/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/advance-real-estate/501185/lang/en',
  'https://siilaspot.com.mx/company/advance-real-estate/501185/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/frel-expired-/1812410',
  'https://siilaspot.com.mx/empresa/frel-expired-/1812410/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/frel-expired-/1812410/lang/es',
  'https://siilaspot.com.mx/empresa/frel-expired-/1812410/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/frel-expired-/1812410/lang/en',
  'https://siilaspot.com.mx/company/frel-expired-/1812410/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/sky-capital/950783',
  'https://siilaspot.com.mx/empresa/sky-capital/950783/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/sky-capital/950783/lang/es',
  'https://siilaspot.com.mx/empresa/sky-capital/950783/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/sky-capital/950783/lang/en',
  'https://siilaspot.com.mx/company/sky-capital/950783/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/proximity-parks/668599',
  'https://siilaspot.com.mx/empresa/proximity-parks/668599/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/proximity-parks/668599/lang/es',
  'https://siilaspot.com.mx/empresa/proximity-parks/668599/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/proximity-parks/668599/lang/en',
  'https://siilaspot.com.mx/company/proximity-parks/668599/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/around/1818390',
  'https://siilaspot.com.mx/empresa/around/1818390/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/around/1818390/lang/es',
  'https://siilaspot.com.mx/empresa/around/1818390/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/around/1818390/lang/en',
  'https://siilaspot.com.mx/company/around/1818390/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/colliers/447164',
  'https://siilaspot.com.mx/empresa/colliers/447164/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/colliers/447164/lang/es',
  'https://siilaspot.com.mx/empresa/colliers/447164/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/colliers/447164/lang/en',
  'https://siilaspot.com.mx/company/colliers/447164/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/vynmsa/688021',
  'https://siilaspot.com.mx/empresa/vynmsa/688021/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/vynmsa/688021/lang/es',
  'https://siilaspot.com.mx/empresa/vynmsa/688021/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/vynmsa/688021/lang/en',
  'https://siilaspot.com.mx/company/vynmsa/688021/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/terra-regia/1908329',
  'https://siilaspot.com.mx/empresa/terra-regia/1908329/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/terra-regia/1908329/lang/es',
  'https://siilaspot.com.mx/empresa/terra-regia/1908329/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/terra-regia/1908329/lang/en',
  'https://siilaspot.com.mx/company/terra-regia/1908329/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/terra-regia/1908330',
  'https://siilaspot.com.mx/empresa/terra-regia/1908330/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/terra-regia/1908330/lang/es',
  'https://siilaspot.com.mx/empresa/terra-regia/1908330/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/terra-regia/1908330/lang/en',
  'https://siilaspot.com.mx/company/terra-regia/1908330/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/bicofi-real-estate/1986300',
  'https://siilaspot.com.mx/empresa/bicofi-real-estate/1986300/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/bicofi-real-estate/1986300/lang/es',
  'https://siilaspot.com.mx/empresa/bicofi-real-estate/1986300/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/bicofi-real-estate/1986300/lang/en',
  'https://siilaspot.com.mx/company/bicofi-real-estate/1986300/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/yama-desarrolladora-inmobiliaria/1657953',
  'https://siilaspot.com.mx/empresa/yama-desarrolladora-inmobiliaria/1657953/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/yama-desarrolladora-inmobiliaria/1657953/lang/es',
  'https://siilaspot.com.mx/empresa/yama-desarrolladora-inmobiliaria/1657953/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/yama-desarrolladora-inmobiliaria/1657953/lang/en',
  'https://siilaspot.com.mx/company/yama-desarrolladora-inmobiliaria/1657953/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/jll/329564',
  'https://siilaspot.com.mx/empresa/jll/329564/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/jll/329564/lang/es',
  'https://siilaspot.com.mx/empresa/jll/329564/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/jll/329564/lang/en',
  'https://siilaspot.com.mx/company/jll/329564/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/grupo-surge/1912011',
  'https://siilaspot.com.mx/empresa/grupo-surge/1912011/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/grupo-surge/1912011/lang/es',
  'https://siilaspot.com.mx/empresa/grupo-surge/1912011/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/grupo-surge/1912011/lang/en',
  'https://siilaspot.com.mx/company/grupo-surge/1912011/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/corporate-properties-americas-cpa-/1079212',
  'https://siilaspot.com.mx/empresa/corporate-properties-americas-cpa-/1079212/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/corporate-properties-americas-cpa-/1079212/lang/es',
  'https://siilaspot.com.mx/empresa/corporate-properties-americas-cpa-/1079212/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/corporate-properties-americas-cpa-/1079212/lang/en',
  'https://siilaspot.com.mx/company/corporate-properties-americas-cpa-/1079212/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/vesta/910962',
  'https://siilaspot.com.mx/empresa/vesta/910962/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/vesta/910962/lang/es',
  'https://siilaspot.com.mx/empresa/vesta/910962/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/company/detail/vesta/910962/lang/en',
  'https://siilaspot.com.mx/company/vesta/910962/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/siila-spot/1803727',
  'https://siilaspot.com.mx/perfil/siila-spot/1803727'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/siila-spot/1803727/lang/es',
  'https://siilaspot.com.mx/perfil/siila-spot/1803727/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/siila-spot/1803727/lang/en',
  'https://siilaspot.com.mx/profile/siila-spot/1803727/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/eduardo-davis/1872679',
  'https://siilaspot.com.mx/perfil/eduardo-davis/1872679'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/eduardo-davis/1872679/lang/es',
  'https://siilaspot.com.mx/perfil/eduardo-davis/1872679/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/eduardo-davis/1872679/lang/en',
  'https://siilaspot.com.mx/profile/eduardo-davis/1872679/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/terra-regia/1908335',
  'https://siilaspot.com.mx/perfil/terra-regia/1908335'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/terra-regia/1908335/lang/es',
  'https://siilaspot.com.mx/perfil/terra-regia/1908335/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/terra-regia/1908335/lang/en',
  'https://siilaspot.com.mx/profile/terra-regia/1908335/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/larry-mejia/329573',
  'https://siilaspot.com.mx/perfil/larry-mejia/329573'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/larry-mejia/329573/lang/es',
  'https://siilaspot.com.mx/perfil/larry-mejia/329573/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/larry-mejia/329573/lang/en',
  'https://siilaspot.com.mx/profile/larry-mejia/329573/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/sergio-resendez/459512',
  'https://siilaspot.com.mx/perfil/sergio-resendez/459512'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/sergio-resendez/459512/lang/es',
  'https://siilaspot.com.mx/perfil/sergio-resendez/459512/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/sergio-resendez/459512/lang/en',
  'https://siilaspot.com.mx/profile/sergio-resendez/459512/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/mariana-diaz/459518',
  'https://siilaspot.com.mx/perfil/mariana-diaz/459518'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/mariana-diaz/459518/lang/es',
  'https://siilaspot.com.mx/perfil/mariana-diaz/459518/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/mariana-diaz/459518/lang/en',
  'https://siilaspot.com.mx/profile/mariana-diaz/459518/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/daniela-soriano/459520',
  'https://siilaspot.com.mx/perfil/daniela-soriano/459520'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/daniela-soriano/459520/lang/es',
  'https://siilaspot.com.mx/perfil/daniela-soriano/459520/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/daniela-soriano/459520/lang/en',
  'https://siilaspot.com.mx/profile/daniela-soriano/459520/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/marco-antonio-duran/607371',
  'https://siilaspot.com.mx/perfil/marco-antonio-duran/607371'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/marco-antonio-duran/607371/lang/es',
  'https://siilaspot.com.mx/perfil/marco-antonio-duran/607371/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/marco-antonio-duran/607371/lang/en',
  'https://siilaspot.com.mx/profile/marco-antonio-duran/607371/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/hector-romero/688022',
  'https://siilaspot.com.mx/perfil/hector-romero/688022'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/hector-romero/688022/lang/es',
  'https://siilaspot.com.mx/perfil/hector-romero/688022/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/hector-romero/688022/lang/en',
  'https://siilaspot.com.mx/profile/hector-romero/688022/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/antonio-francisco/689596',
  'https://siilaspot.com.mx/perfil/antonio-francisco/689596'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/antonio-francisco/689596/lang/es',
  'https://siilaspot.com.mx/perfil/antonio-francisco/689596/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/antonio-francisco/689596/lang/en',
  'https://siilaspot.com.mx/profile/antonio-francisco/689596/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/andres-lomelin/826536',
  'https://siilaspot.com.mx/perfil/andres-lomelin/826536'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/andres-lomelin/826536/lang/es',
  'https://siilaspot.com.mx/perfil/andres-lomelin/826536/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/andres-lomelin/826536/lang/en',
  'https://siilaspot.com.mx/profile/andres-lomelin/826536/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/roberto-serrano-plowells/950787',
  'https://siilaspot.com.mx/perfil/roberto-serrano-plowells/950787'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/roberto-serrano-plowells/950787/lang/es',
  'https://siilaspot.com.mx/perfil/roberto-serrano-plowells/950787/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/roberto-serrano-plowells/950787/lang/en',
  'https://siilaspot.com.mx/profile/roberto-serrano-plowells/950787/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/miguel-beltran/1157024',
  'https://siilaspot.com.mx/perfil/miguel-beltran/1157024'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/miguel-beltran/1157024/lang/es',
  'https://siilaspot.com.mx/perfil/miguel-beltran/1157024/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/miguel-beltran/1157024/lang/en',
  'https://siilaspot.com.mx/profile/miguel-beltran/1157024/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/cati-cerda-narchi/1205535',
  'https://siilaspot.com.mx/perfil/cati-cerda-narchi/1205535'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/cati-cerda-narchi/1205535/lang/es',
  'https://siilaspot.com.mx/perfil/cati-cerda-narchi/1205535/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/cati-cerda-narchi/1205535/lang/en',
  'https://siilaspot.com.mx/profile/cati-cerda-narchi/1205535/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/sergio-rodriguez-de-la-vega/1399855',
  'https://siilaspot.com.mx/perfil/sergio-rodriguez-de-la-vega/1399855'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/sergio-rodriguez-de-la-vega/1399855/lang/es',
  'https://siilaspot.com.mx/perfil/sergio-rodriguez-de-la-vega/1399855/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/sergio-rodriguez-de-la-vega/1399855/lang/en',
  'https://siilaspot.com.mx/profile/sergio-rodriguez-de-la-vega/1399855/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/hugo-perez/1466509',
  'https://siilaspot.com.mx/perfil/hugo-perez/1466509'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/hugo-perez/1466509/lang/es',
  'https://siilaspot.com.mx/perfil/hugo-perez/1466509/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/hugo-perez/1466509/lang/en',
  'https://siilaspot.com.mx/profile/hugo-perez/1466509/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/oscar-velazquez/1639682',
  'https://siilaspot.com.mx/perfil/oscar-velazquez/1639682'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/oscar-velazquez/1639682/lang/es',
  'https://siilaspot.com.mx/perfil/oscar-velazquez/1639682/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/oscar-velazquez/1639682/lang/en',
  'https://siilaspot.com.mx/profile/oscar-velazquez/1639682/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/diego-chavez/1744313',
  'https://siilaspot.com.mx/perfil/diego-chavez/1744313'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/diego-chavez/1744313/lang/es',
  'https://siilaspot.com.mx/perfil/diego-chavez/1744313/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/diego-chavez/1744313/lang/en',
  'https://siilaspot.com.mx/profile/diego-chavez/1744313/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/jahnis-sanchez-hernandez/1747994',
  'https://siilaspot.com.mx/perfil/jahnis-sanchez-hernandez/1747994'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/jahnis-sanchez-hernandez/1747994/lang/es',
  'https://siilaspot.com.mx/perfil/jahnis-sanchez-hernandez/1747994/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/jahnis-sanchez-hernandez/1747994/lang/en',
  'https://siilaspot.com.mx/profile/jahnis-sanchez-hernandez/1747994/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alejandro-mariel/1753661',
  'https://siilaspot.com.mx/perfil/alejandro-mariel/1753661'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alejandro-mariel/1753661/lang/es',
  'https://siilaspot.com.mx/perfil/alejandro-mariel/1753661/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alejandro-mariel/1753661/lang/en',
  'https://siilaspot.com.mx/profile/alejandro-mariel/1753661/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/ana-rincon-rincon-gallardo/1753674',
  'https://siilaspot.com.mx/perfil/ana-rincon-rincon-gallardo/1753674'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/ana-rincon-rincon-gallardo/1753674/lang/es',
  'https://siilaspot.com.mx/perfil/ana-rincon-rincon-gallardo/1753674/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/ana-rincon-rincon-gallardo/1753674/lang/en',
  'https://siilaspot.com.mx/profile/ana-rincon-rincon-gallardo/1753674/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alan-rodriguez/1812433',
  'https://siilaspot.com.mx/perfil/alan-rodriguez/1812433'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alan-rodriguez/1812433/lang/es',
  'https://siilaspot.com.mx/perfil/alan-rodriguez/1812433/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alan-rodriguez/1812433/lang/en',
  'https://siilaspot.com.mx/profile/alan-rodriguez/1812433/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/david-zayas/1818397',
  'https://siilaspot.com.mx/perfil/david-zayas/1818397'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/david-zayas/1818397/lang/es',
  'https://siilaspot.com.mx/perfil/david-zayas/1818397/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/david-zayas/1818397/lang/en',
  'https://siilaspot.com.mx/profile/david-zayas/1818397/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/gabriela-carrera/1819588',
  'https://siilaspot.com.mx/perfil/gabriela-carrera/1819588'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/gabriela-carrera/1819588/lang/es',
  'https://siilaspot.com.mx/perfil/gabriela-carrera/1819588/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/gabriela-carrera/1819588/lang/en',
  'https://siilaspot.com.mx/profile/gabriela-carrera/1819588/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/silvia-sitton/1993017',
  'https://siilaspot.com.mx/perfil/silvia-sitton/1993017'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/silvia-sitton/1993017/lang/es',
  'https://siilaspot.com.mx/perfil/silvia-sitton/1993017/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/silvia-sitton/1993017/lang/en',
  'https://siilaspot.com.mx/profile/silvia-sitton/1993017/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/francisco-estrada/2178801',
  'https://siilaspot.com.mx/perfil/francisco-estrada/2178801'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/francisco-estrada/2178801/lang/es',
  'https://siilaspot.com.mx/perfil/francisco-estrada/2178801/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/francisco-estrada/2178801/lang/en',
  'https://siilaspot.com.mx/profile/francisco-estrada/2178801/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alejandro-munoz/2178805',
  'https://siilaspot.com.mx/perfil/alejandro-munoz/2178805'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alejandro-munoz/2178805/lang/es',
  'https://siilaspot.com.mx/perfil/alejandro-munoz/2178805/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/alejandro-munoz/2178805/lang/en',
  'https://siilaspot.com.mx/profile/alejandro-munoz/2178805/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/adalberto-ortega/2178811',
  'https://siilaspot.com.mx/perfil/adalberto-ortega/2178811'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/adalberto-ortega/2178811/lang/es',
  'https://siilaspot.com.mx/perfil/adalberto-ortega/2178811/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/adalberto-ortega/2178811/lang/en',
  'https://siilaspot.com.mx/profile/adalberto-ortega/2178811/en'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/maria-ibarguengoitia/2178836',
  'https://siilaspot.com.mx/perfil/maria-ibarguengoitia/2178836'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/maria-ibarguengoitia/2178836/lang/es',
  'https://siilaspot.com.mx/perfil/maria-ibarguengoitia/2178836/es'
);
urlRedirects.set(
  'https://siilaspot.com.mx/leasing-contact/detail/maria-ibarguengoitia/2178836/lang/en',
  'https://siilaspot.com.mx/profile/maria-ibarguengoitia/2178836/en'
);
