import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { PrivacyPolicyDialogComponent } from './privacy-policy-dialog.component';
import { PrivacyPolicyReviewComponent } from './privacy-policy-review/privacy-policy-review.component';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrivacyPolicyService {
  private ppDialogRef: MatDialogRef<PrivacyPolicyDialogComponent>;
  private acceptPrivacyPolicyEndoint: string = 'api/account/acceptPrivacyPolicy';
  private currentPolicyVersion: string = '2021-02-08';
  public isPpDialogOpened: boolean = false;

  constructor(private dialog: MatDialog, private http: HttpClient) {}

  openPPDialog() {
    this.isPpDialogOpened = true;
    this.ppDialogRef = this.dialog.open(PrivacyPolicyDialogComponent, {
      closeOnNavigation: false,
      disableClose: true,
      width: '300px',
      data: {
        date: this.currentPolicyVersion,
        acceptPrivacyPolicy: this.acceptPrivacyPolicy.bind(this),
        openPrivacyReview: this.openPPReview.bind(this)
      }
    });

    this.ppDialogRef.afterClosed().subscribe(() => {
      this.isPpDialogOpened = false;
    });

    return this.ppDialogRef;
  }

  openPPReview() {
    this.dialog.open(PrivacyPolicyReviewComponent, {
      closeOnNavigation: false,
      disableClose: true,
      width: '800px',
      height: '500px'
    });
  }

  isUserPolicyUpdated(userPolicy: string) {
    return this.currentPolicyVersion === userPolicy;
  }

  acceptPrivacyPolicy() {
    return this.http.post(`${this.acceptPrivacyPolicyEndoint}/${this.currentPolicyVersion}`, {});
  }
}
