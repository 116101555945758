<p class="font-weight-bold px-3 text-center mt-3" translate="global.register.joinCommunity"></p>

<div class="flex-row align-end checkbox-label-margin text-center">
  <button class="siila-submit login-btn block-btn" (click)="goToMyAccount()" mat-raised-button type="button">
    {{ 'global.register.completeProfile' | translate | uppercase }}
  </button>
</div>

<div class="text-center mt-3">
  <span role="button" class="close-modal" (click)="closeModal()"><u translate="global.register.maybeLater"></u></span>
</div>
