import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcceptCancelDataModel } from './accept-cancel.model';

@Component({
  selector: 'app-app-accept-cancel-modal',
  templateUrl: './app-accept-cancel-modal.component.html',
  styleUrls: ['./app-accept-cancel-modal.component.scss']
})
export class AppAcceptCancelModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AcceptCancelDataModel,
    public dialogRef: MatDialogRef<AppAcceptCancelModalComponent>
  ) {}

  onCancelClick() {
    if (this.data.cancelCb) {
      this.data.cancelCb();
    }
    if (this.data.closeOnCancel !== false) {
      this.closeModal('cancel');
    }
  }

  onAcceptClick() {
    if (this.data.acceptCb) {
      this.data.acceptCb();
    }
    if (this.data.closeOnAccept !== false) {
      this.closeModal('accept');
    }
  }

  closeModal(reason: 'close' | 'cancel' | 'accept') {
    this.dialogRef.close(reason);
  }
}
