import { Component, OnInit } from '@angular/core';
import { I18nService, PlatformHelperService } from '@app/core';
import { AlertController } from '@ionic/angular';
import { InstallPromptDialogService } from '@app/core/install-prompt.service';
import { Observable } from 'rxjs';
import { PWAService } from '../pwa-service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  appInstalled$: Observable<any> = new Observable<any>();
  isIos: boolean;
  isIphone: boolean;
  isChrome: boolean;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  isDesk: boolean = false;

  constructor(
    private platformHelper: PlatformHelperService,
    private alertCtrl: AlertController,
    private promptService: InstallPromptDialogService,
    private pwaService: PWAService,
    private gaService$: GoogleAnalyticsService,
    private deviceDetector: DeviceDetectorService,
    private i18NService: I18nService
  ) {}

  ngOnInit() {
    this.isIos = this.platformHelper.isIosChrome() || this.platformHelper.isIosSafari();
    this.appInstalled$ = this.pwaService.appInstalled$;
    this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
    this.isChrome = !!(window as any).chrome && (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime);
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
      this.isDesk = true;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
  }

  isAppInstalled() {
    return this.pwaService.appInstalled$;
  }

  promptInstallPWA() {
    if (this.isAppInstalled()) {
      this.pwaService.promptPWAInstall();
    }
    this.gaService$.event('install', 'App Adoption', `${this.currentDevice}`);
  }

  promptIos() {
    if (this.isIphone && (this.isChrome || this.platformIsIosChrome())) {
      this.promptIosChromeDialog();
    } else {
      this.promptService.openDialog(null, true);
    }
  }

  platformIsIosChrome() {
    return this.platformHelper.isIosChrome();
  }

  platformIsIosSafari() {
    return this.platformHelper.isIosSafari();
  }

  async promptIosChromeDialog() {
    const alert = await this.alertCtrl.create({
      header: 'SiiLA SPOT Mobile',
      cssClass: 'header-alert-prompt',
      message: 'To install the app, please open our site using the Safari browser and click install App to proceed.'
    });

    await alert.present();
  }

  async promptIsInstalled() {
    const okText = this.i18NService.get('global.OK');
    const alert = await this.alertCtrl.create({
      header: this.isDesktop ? 'SiiLA SPOT Desktop' : 'SiiLA SPOT Mobile',
      cssClass: 'header-alert-prompt',
      message: this.i18NService.get('global.siilaAppInstalled'),
      buttons: [okText]
    });

    await alert.present();
  }
}
