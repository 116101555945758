import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '../../core/auth/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { SizeIcon } from '../../models/shared/shared-enums.model';
var FavoriteSpotComponent = /** @class */ (function () {
    function FavoriteSpotComponent(loginFormDialog, favSpotService, router, _ts, auth) {
        this.loginFormDialog = loginFormDialog;
        this.favSpotService = favSpotService;
        this.router = router;
        this._ts = _ts;
        this.auth = auth;
        this.favoriteChange = new EventEmitter();
    }
    FavoriteSpotComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.faHeart = faHeart;
        this.size = this.size ? this.size : SizeIcon.MD;
        this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) { return (_this.isLoggedIn = val); });
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe(function (res) {
            _this.customLoginText = res;
        });
    };
    FavoriteSpotComponent.prototype.isFavoriteListing = function () {
        return this.spot ? this.spot.favorite : false;
    };
    FavoriteSpotComponent.prototype.setFavoriteLisitng = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                event.stopPropagation();
                if (!this.isLoggedIn) {
                    this.openLoginDialog()
                        .afterClosed()
                        .subscribe(function () {
                        if (_this.isLoggedIn) {
                            _this.toggleFavorite();
                        }
                    });
                }
                else {
                    this.toggleFavorite();
                }
                return [2 /*return*/];
            });
        });
    };
    FavoriteSpotComponent.prototype.toggleFavorite = function () {
        var _this = this;
        this.favSpotService.toggleFavoriteListing(this.spot, this.spot.favorite || false).subscribe(function () {
            _this.favoriteChange.emit({ id: _this.spot.id, isFavorite: _this.spot.favorite });
        });
    };
    FavoriteSpotComponent.prototype.openLoginDialog = function () {
        var currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    };
    FavoriteSpotComponent.prototype.isMdSize = function () {
        return this.size == SizeIcon.MD;
    };
    FavoriteSpotComponent.prototype.isLgSize = function () {
        return this.size == SizeIcon.LG;
    };
    return FavoriteSpotComponent;
}());
export { FavoriteSpotComponent };
