import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { ListingService } from '@app/core/listings';
import { SearchTagsService } from '@app/core/search-tags.service';
import { I18nService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';

@Component({
  selector: 'nearby-listing-map',
  templateUrl: './nearby-listing-map.component.html',
  styleUrls: ['./nearby-listing-map.component.scss']
})
export class NearbyListingMapComponent implements OnInit, OnChanges {
  private ORANGE_PIN: string = this.nearbyListingSvc.orangePin;
  private BLUE_PIN: string = this.nearbyListingSvc.bluePin;
  public mapOptions: any = {};
  public markers: any[] = [];
  public searchModel: any;
  public isMobile: boolean;
  public filterStyle: string = 'filter-tag-container';
  public isIos: boolean = false;

  @Input('isListingDetail') isListingDetail: boolean;
  @Input('searchCriteria') searchCriteria: any;
  @Input('searchTags') searchTags?: any;
  @Input('currentListing') currentListing: any;
  @Input('listings') listings: any[];
  @Output('getSelectPropertyListing') getSelectPropertyListing: EventEmitter<any> = new EventEmitter();
  @Output('refetchListing') refetchListing: EventEmitter<any> = new EventEmitter();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private nearbyListingSvc: NearbyListingSvc,
    private listingSearchService: ListingSearchService,
    private listingSvc: ListingService,
    private searchTagSvc: SearchTagsService
  ) {
    this.subscribe();
  }

  ngOnInit() {
    if (!this.searchTags) {
      this.findSearchTagsFromLocalStorage();
    }
    this.mapOptions = {
      singleMarker: false,
      ignoreZoom: false
    };
  }

  private async findSearchTagsFromLocalStorage() {
    const selectedFilterKey = this.listingSearchService.readLastSearchPageFromStorage();
    if (selectedFilterKey) {
      const searchDTO = this.listingSearchService.readSearchDTOFromStorage(selectedFilterKey, {});
      this.updateSearchTags(searchDTO);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const isListingChange: boolean = this.isListingChange(changes);
    if (isListingChange) {
      this.generateNearByMarker();
    }
  }

  private subscribe() {
    this.listingSvc.currentModel.subscribe(model => {
      this.searchModel = model;
    });

    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
      this.filterStyle = this.isMobile ? 'filter-tag-mobile' : 'filter-tag-container';
    });
  }

  private isListingChange(changes: SimpleChanges): boolean {
    return [changes['listings'] && changes['listings'].currentValue && changes['listings'].currentValue.length].every(
      condition => condition
    );
  }

  private setMarkerEvent(markers: any[], marker: any) {
    marker.addListener('click', () => {
      const prevSelectedMarker: any = this.nearbyListingSvc.prevSelectedMarker(markers, marker);
      if (prevSelectedMarker.length) {
        prevSelectedMarker[0].infoWindow.close();
        prevSelectedMarker[0].set('isSelected', false);
      }

      marker.infoWindow.open(marker.getMap(), marker);
      marker.set('isSelected', true);

      marker.infoWindow.addListener('closeclick', () => {
        marker.set('isSelected', false);
        this.getSelectPropertyListing.emit({ selectedBuilding: null, listings: [] });
      });

      this.getBuildingListing(marker);
    });
  }

  private generateNearByMarker() {
    const currentListingMarker: google.maps.Marker = this.nearbyListingSvc.createBasicMarker(
      this.currentListing.building,
      this.ORANGE_PIN
    );

    const listingMarkers: any[] = this.listings.reduce((accumulator, item) => {
      const isLatLngValid: boolean = this.nearbyListingSvc.hasValidCoordinate(item, 'lat', 'lng');

      if (isLatLngValid && item.id !== this.currentListing.building.id) {
        const infoWindow: google.maps.InfoWindow = this.nearbyListingSvc.createNearByListingInfoWindow(item);
        let marker: any = this.nearbyListingSvc.createMarker(item, infoWindow, this.BLUE_PIN, true);
        marker.set('buildingTitle', item.titleOnListing);
        this.setMarkerEvent(accumulator, marker);
        accumulator.push(marker);
      }
      return accumulator;
    }, []);

    this.markers = [currentListingMarker, ...listingMarkers];
  }

  private getBuildingListing(selectedBuilding: any) {
    let searchDto: any = this.nearbyListingSvc.setNearbyListingSearchCriteria(
      this.searchCriteria.searchDTO,
      selectedBuilding.location
    );
    searchDto.listingIds = selectedBuilding.listingIds;
    this.listingSvc.getAllNearByPropertyListing(searchDto).then((result: any) => {
      this.getSelectPropertyListing.emit({
        listings: result.body.listings,
        selectedBuilding: selectedBuilding.buildingTitle
      });
    });
  }

  private resetSearchFilter(searchDTO: any, checkBoxValues: any) {
    const searchProps: any[] = this.nearbyListingSvc.getClearSearchProperty();
    let checkboxFilters: any = Object.assign({}, checkBoxValues);

    searchProps.forEach(prop => {
      searchDTO[prop] = null;
    });

    Object.keys(checkboxFilters).forEach(key => {
      checkBoxValues[key] = false;
    });
    this.listingSvc.updateModel('clearsearch');
    this.refetchListing.emit({
      searchDTO: searchDTO,
      checkBoxValues: checkboxFilters
    });
  }

  private async updateSearchTags(searchDTO: any) {
    this.searchTags = await this.searchTagSvc.updateSearchTags(searchDTO, false);
    return this.searchTags;
  }

  private setListingFilter(filters: any, fieldName: string) {
    const copyOfFilter: any = Object.assign({}, filters);
    const isLisingTypeArray: boolean = this.nearbyListingSvc.isArray(copyOfFilter, 'listingLeaseOrSale');

    if (fieldName.toLowerCase() === 'propertytype' || fieldName.toLowerCase() === 'officetype') {
      copyOfFilter['propertySubTypes'] = null;
      copyOfFilter['propertySubtypeIds'] = null;
      this.listingSvc.propertySubTypesFromHomepage = [];
    }

    copyOfFilter.listingLeaseOrSale = isLisingTypeArray ? copyOfFilter.listingLeaseOrSale : null;
    copyOfFilter.listingIds = null;
    return copyOfFilter;
  }

  public isTagFieldExist(fieldName: string): boolean {
    return [
      fieldName.toLowerCase() === 'rangeceilingheight',
      fieldName.toLowerCase() === 'baysize',
      fieldName.toLowerCase() === 'dockratio'
    ].some(condition => condition);
  }

  public getMinRangeLocale(tag: any): string {
    const isTagExist: boolean = this.nearbyListingSvc.isTagFieldExist(tag.fieldName);
    return !isTagExist || tag.min < 1 ? '1.0-0' : '1.2-2';
  }

  public getMaxRangeLocale(tag: any): string {
    const isTagExist: boolean = this.nearbyListingSvc.isTagFieldExist(tag.fieldName);
    return !isTagExist ? '1.0-0' : '1.2-2';
  }

  public hasMinMaxRange(tag: any): boolean {
    return tag.min || tag.max;
  }

  public getNewListing(index: number, fieldName: string) {
    let checkBoxValues: any = this.nearbyListingSvc.setCheckboxesFilter(fieldName, this.searchCriteria.checkBoxValues);
    let searchDTO: any = this.searchTagSvc.removeTagFromSearchDTO(
      this.searchCriteria.searchDTO,
      fieldName,
      checkBoxValues
    );
    searchDTO = this.setListingFilter(searchDTO, fieldName);
    const selectedFilterKey = this.listingSearchService.readLastSearchPageFromStorage();
    this.listingSearchService.saveSearchDtoOnStorage(selectedFilterKey, searchDTO);

    this.searchModel.updateModelFromListingDetail = true;
    this.searchModel = this.nearbyListingSvc.resetSearchModelOnChange(fieldName, this.searchModel);
    this.listingSvc.updateModel(this.searchModel);
    this.searchTags.splice(index, 1);
    this.updateSearchTags(searchDTO).then(updatedTags => {
      this.refetchListing.emit({
        searchDTO: searchDTO,
        checkBoxValues: checkBoxValues,
        searchTags: updatedTags
      });
    });
  }

  public clearSearch() {
    let searchDTO: any = this.nearbyListingSvc.resetNearbyFilterToDefault(this.searchCriteria.searchDTO);
    searchDTO = this.searchTagSvc.clearSearchTagsFromDTO(searchDTO);
    this.listingSearchService.saveSearchDtoOnStorage(SelectedFilterKey.REGULAR_SEARCH, searchDTO);

    this.searchTags = [];
    this.resetSearchFilter(searchDTO, this.searchCriteria.checkBoxValues);
  }

  public isRestricted(tagName: string): boolean {
    return [tagName.toLowerCase() === 'positions: 1'].some((condition: any) => condition);
  }
}
