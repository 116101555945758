import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
var ImageUploadService = /** @class */ (function () {
    function ImageUploadService(http) {
        this.http = http;
        this.buildingListingEndpoint = 'api/buildinglistingPhotos';
        this.buildingListingPhotosEndpoint = 'api/buildinglistingPhotosByListing/image-formats-only';
        this.spaceEndpoint = 'api/spacePhotos';
        this.spacePhotoEndpoint = 'api/spacePhotosBySpace';
    }
    ImageUploadService.prototype.createImage = function (formData) {
        return this.http
            .post(this.buildingListingEndpoint + "/no-listing/upload", formData)
            .pipe(map(function (val) { return val; }))
            .toPromise();
    };
    ImageUploadService.prototype.createSpaceImage = function (formData) {
        return this.http
            .post(this.spaceEndpoint + "/no-space/upload", formData)
            .pipe(map(function (val) { return val; }))
            .toPromise();
    };
    ImageUploadService.prototype.associatePhotosToBuildingListing = function (buildingListingId, imgList) {
        if (!buildingListingId) {
            return imgList;
        }
        var buildingListingPhotos = imgList.map(function (photo) {
            photo.buildingListingId = buildingListingId;
            return photo;
        });
        var photoIds = buildingListingPhotos.map(function (img) { return img.id; });
        this.http
            .put(this.buildingListingEndpoint + "/listing/" + buildingListingId, photoIds)
            .pipe(map(function (val) { return val; }))
            .toPromise();
        return buildingListingPhotos;
    };
    ImageUploadService.prototype.associatePhotosToSpace = function (spaceId, imgList) {
        if (!spaceId) {
            return imgList;
        }
        var spacePhotos = imgList.map(function (photo) {
            photo.spaceId = spaceId;
            return photo;
        });
        var photoIds = spacePhotos.map(function (img) { return img.id; });
        this.http
            .put(this.spaceEndpoint + "/space/" + spaceId, photoIds)
            .pipe(map(function (val) { return val; }))
            .toPromise();
        return spacePhotos;
    };
    ImageUploadService.prototype.getBuildingListingPhotos = function (buildingListingId) {
        return this.http
            .get(this.buildingListingPhotosEndpoint + "/?listingId=" + buildingListingId, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }))
            .toPromise();
    };
    ImageUploadService.prototype.getSpacePhotos = function (spaceId) {
        return this.http
            .get(this.spacePhotoEndpoint + "?spaceId=" + spaceId, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }))
            .toPromise();
    };
    ImageUploadService.prototype.logListingPhotonDeletion = function (deletedListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post('api/deletedphotolog/', deletedListingPhoto)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.deleteBuildingListingPhoto = function (bildingListingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(this.buildingListingEndpoint + "/" + bildingListingPhoto.id)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.deleteSpacePhoto = function (spacePhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .delete(this.spaceEndpoint + "/" + spacePhoto.id)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.uploadCroppedImage = function (file, caption, BuildingListingPhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            return tslib_1.__generator(this, function (_a) {
                formData = new FormData();
                formData.append('listingPhotoId', BuildingListingPhotoId);
                formData.append('caption', caption);
                formData.append('file', file);
                return [2 /*return*/, this.http
                        .post(this.buildingListingEndpoint + "/cropped/", formData)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.uploadCroppedImageSpace = function (file, caption, spacePhotoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            return tslib_1.__generator(this, function (_a) {
                formData = new FormData();
                formData.append('spacePhotoId', spacePhotoId);
                formData.append('caption', caption);
                formData.append('file', file);
                return [2 /*return*/, this.http
                        .post(this.spaceEndpoint + "/cropped/", formData)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.updateOrderOrCaptionFrom = function (buildingListingPhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .patch(this.buildingListingEndpoint + "/patch/" + buildingListingPhotoId, { orderId: orderId, caption: caption })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.updateOrderOrCaptionFromSpace = function (spacePhotoId, caption, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .patch(this.spaceEndpoint + "/patch/" + spacePhotoId, { orderId: orderId, caption: caption })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    ImageUploadService.prototype.setFloorPlant = function (spacePhotoId, floorplant) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .patch(this.spaceEndpoint + "/patch/" + spacePhotoId, { floorplant: floorplant })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    return ImageUploadService;
}());
export { ImageUploadService };
