import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { AccountService } from '@app/core/auth';

@Component({
  selector: 'update-password-modal',
  templateUrl: './update-password-modal.component.html',
  styleUrls: ['./update-password-modal.component.scss']
})
export class UpdatePasswordModalComponent implements OnInit {
  @Input() currentPassword: string;
  @Input() userHomeService: UserHomeService;
  @Input() userAccountService: AccountService;
  @Input() openInfoModal: any;
  @Input() login: string;

  updatePasswordForm: FormGroup;
  isError: boolean = false;
  infoProp: any = {};

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.updatePasswordForm = this.userHomeService.createChangePasswordForm();
  }

  cancel() {
    this.modalCtrl.dismiss({
      cancelPasswordChange: true
    });
  }

  changePassword() {
    const newPassword: string = this.updatePasswordForm.get('newPassword').value;
    const oldPassword: string = this.updatePasswordForm.get('currentPassword').value;
    const passwordProps: any = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      login: this.login
    };

    this.userAccountService.changeUserPassword(passwordProps).subscribe(
      () => {
        const action: string = 'changePasswordSuccess';
        this.infoProp = this.userHomeService.getPasswordChangeSuccessMessage();
        this.isError = false;
        this.modalCtrl.dismiss();
        this.openInfoModal(this.infoProp.header, this.infoProp.bodyMessage, action);
      },
      (error: Error) => {
        this.infoProp = this.userHomeService.getPasswordChangeErrorMessage(error);
        this.isError = true;
      }
    );
  }

  validatePassword(): boolean {
    let currentPassword: any = this.updatePasswordForm.get('currentPassword');
    let newPassword: any = this.updatePasswordForm.get('newPassword');
    let confirmPassword: any = this.updatePasswordForm.get('confirmNewPassword');

    return [
      currentPassword.value === '' || !currentPassword.value,
      newPassword.errors && (newPassword.errors.required || newPassword.errors.minlength),
      newPassword.value.toLowerCase() !== confirmPassword.value.toLowerCase()
    ].some(condition => condition);
  }
}
