export interface UserActivity {
  module: UserActivityType;
  brokerId?: number;
  listingId?: number;
  buildingListingId?: number;
  adCompanyId?: number;
  companyId?: number;
  application?: string;
}

export enum UserActivityType {
  BROKER_SHOWPHONE = 'BROKER_SHOWPHONE',
  BROKER_SHOWWHATSAPP = 'BROKER_SHOWWHATSAPP',
  BROKER_SHOWEMAIL = 'BROKER_SHOWEMAIL',
  BROKER_SHOWDETAIL = 'BROKER_SHOWDETAIL',
  BROKER_SENDMESSAGE = 'BROKER_SENDMESSAGE',
  COMPANY_SHOWDETAIL = 'COMPANY_SHOWDETAIL',
  SERVICE_PROVIDER_DETAIL = 'SERVICE_PROVIDER_DETAIL',
  LISTING_SHARE = 'LISTING_SHARE',
  LISTING_REQUEST_VISIT = 'LISTING_REQUEST_VISIT',
  LISTING_MESSAGE = 'LISTING_MESSAGE',
  LISTING_VIRTUAL_TOUR = 'LISTING_VIRTUAL_TOUR',
  LISTING_FAVORITE = 'LISTING_FAVORITE',
  BROKER_SHARE = 'BROKER_SHARE',
  COMPANY_SHARE = 'COMPANY_SHARE',
  SPECIALIST_DETAIL = 'SPECIALIST_DETAIL',
  PAGEVIEW_HOMEPAGE = 'PAGEVIEW_HOMEPAGE',
  PAGEVIEW_LISTING = 'PAGEVIEW_LISTING',
  PAGEVIEW_COMPANY = 'PAGEVIEW_COMPANY',
  PAGEVIEW_BROKER = 'PAGEVIEW_BROKER',
  PAGEVIEW_USER_HOME_PAGE = 'PAGEVIEW_USERHOME_PAGE',
  PAGEVIEW_USER_PROFILE_PAGE = 'PAGEVIEW_USERPROFILE_PAGE',
  PAGEVIEW_MESSAGES_PAGE = 'PAGEVIEW_MESSAGES_PAGE',
  PAGEVIEW_MYPAYMENTS_PAGE = 'PAGEVIEW_MYPAYMENTS_PAGE',
  PAGEVIEW_MYLISTINGS_PAGE = 'PAGEVIEW_MYLISTINGS_PAGE',
  INSTALL_APP = 'INSTALL_APP'
}
