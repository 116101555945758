import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavParams, PopoverController } from '@ionic/angular';
import { SpotBuildingDTO } from '../../../../models/spot-buildings/spot-buildings.model';
import {
  SpacesParameters,
  SpaceDetailsTableRow,
  SpaceDetailsTableField,
  UnitOfMeasure
} from '../../../../models/spot-buildings/spot-available-spaces';
import {
  faArrowLeft,
  faArrowRight,
  faTimesCircle,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { BuildingTypeDTO } from '../../../../models/buidingType.model';
import { I18nService } from '../../../../core/i18n.service';
import { PlatformHelperService } from '@app/core';
import { ImageService } from '../../../../core/image.service';
import { SpacePhotoDTO } from '../../../../models/spot-buildings/space-photo.model';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonGenericService } from '../../../../core/common.service';
import { StoreConfig } from '@ngrx/store';
import { FileDetector } from 'protractor';
import { first } from 'rxjs/operators';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';
import { CoworkingSpaceType } from '../../../../models/spot-buildings/spot-available-spaces';
import { RequestInformationType } from '../../../../models/request-information.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { StreamingMedia, StreamingVideoOptions } from '@awesome-cordova-plugins/streaming-media/ngx';
import { VideoComponent } from '@app/shared/video/video.component';

@Component({
  selector: 'app-space-details',
  templateUrl: './space-details.component.html',
  styleUrls: ['./space-details.component.scss']
})
export class SpaceDetailsComponent implements OnInit {
  constructor(
    private navParams: NavParams,
    private i18n: I18nService,
    private popoverCtrl: PopoverController,
    private platformConfigHelper: PlatformHelperService,
    private spacesService: SpotBuildingSpaceService,
    private spotBuildingsService: SpotBuildingsService,
    private streamingMedia: StreamingMedia,
    private commonService: CommonGenericService
  ) {}

  spot: SpotBuildingDTO;
  space: SpacesParameters;
  closeIcon: any;
  buildingTypeDTO: BuildingTypeDTO;
  isForOffice: boolean;
  isForIndustrial: boolean;
  isForCoworking: boolean;
  showSpaceImgs: boolean;
  showMoreInfo: boolean;
  showMediaKit: boolean;
  spaceDescription: string;
  investmentOpportunity: string;

  spacePhotos: any;
  sliderPreviewOptions: any;
  defaultImage: string;
  spotTitle: string;

  detailsTableRows: any[];
  emptyFieldValue: string = '--';

  isMobile: boolean = false;

  ngOnInit() {
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    this.spot = this.navParams.data.spot;
    this.space = this.navParams.data.space;
    this.closeIcon = faTimesCircle;
    this.buildingTypeDTO = this.spot.building.buildingType;
    this.isForIndustrial = this.buildingTypeDTO.id == 1001;
    this.isForOffice = this.buildingTypeDTO.id == 2001;
    this.isForCoworking = this.spot.type == 'C';
    if (this.isForCoworking) {
      this.isForOffice = false;
    }
    this.showSpaceImgs = true;
    this.spaceDescription = this.i18n.getTranslation(this.space.description);
    this.investmentOpportunity = this.space.investmentOpportunity
      ? this.i18n.getTranslation(this.space.investmentOpportunity)
      : undefined;
    this.spotTitle = this.getSpotTitle();
    this.spacePhotos = this.space.spacePhotos;
    this.fillUpDetailsTableRows();
    if (window.screen.width < 1000) {
      this.isMobile = true;
    }
  }

  fillUpDetailsTableRows() {
    this.detailsTableRows = [];

    if (this.isForOffice) {
      if (this.spot.forLease) {
        const firstRow: SpaceDetailsTableRow = {
          col1: this.getMaxContiguousTableField(),
          col2: this.getMonthlyRentTableField()
        };
        const secondRow: SpaceDetailsTableRow = {
          col1: this.getCeilingHeightOfficeTableField(),
          col2: this.getCondoTableField()
        };
        const thirdRow: SpaceDetailsTableRow = {
          col1: this.getParkingSpacesTableField(),
          col2: null //this.getIptuTableField()
        };
        const fourthRow: SpaceDetailsTableRow = {
          col1: this.getDirectSubleaseTableField(),
          col2: this.getTotalTableField()
        };

        this.detailsTableRows.push(firstRow);
        this.detailsTableRows.push(secondRow);
        this.detailsTableRows.push(thirdRow);
        this.detailsTableRows.push(fourthRow);
      }

      if (this.spot.forSale) {
        const firstRow: SpaceDetailsTableRow = {
          col1: this.getCeilingHeightOfficeTableField(),
          col2: this.getParkingSpacesTableField()
        };
        this.detailsTableRows.push(firstRow);
      }
    }

    if (this.isForIndustrial) {
      if (this.spot.forLease) {
        const row1: SpaceDetailsTableRow = {
          col1: this.getDocksTableField(),
          col2: this.getIncludesRetailTableField()
        };
        this.detailsTableRows.push(row1);

        const row2: SpaceDetailsTableRow = {
          col1: this.getTruckParkingSpacesTableField(),
          col2: this.getFullBuildOutTableField()
        };
        this.detailsTableRows.push(row2);

        const row3: SpaceDetailsTableRow = {
          col1: this.getLoadCapacityTableField(),
          col2: this.getDirectSubleaseTableField()
        };
        this.detailsTableRows.push(row3);

        const row4: SpaceDetailsTableRow = {
          col1: this.getSkyLightsTableField(),
          col2: this.getMonthlyRentTableField()
        };
        this.detailsTableRows.push(row4);

        const row5: SpaceDetailsTableRow = {
          col1: this.getCeilingHeightForIndustrial(),
          col2: this.getCondoTableField()
        };
        this.detailsTableRows.push(row5);

        const row6: SpaceDetailsTableRow = {
          col1: this.getColdStorageTableField(),
          col2: null //this.getIptuTableField()
        };
        this.detailsTableRows.push(row6);

        const row7: SpaceDetailsTableRow = {
          col1: this.getIncludesOfficeTableField(),
          col2: this.getTotalTableField()
        };
        this.detailsTableRows.push(row7);
      }

      if (this.spot.forSale) {
        const row1: SpaceDetailsTableRow = {
          col1: this.getDocksTableField(),
          col2: this.getColdStorageTableField()
        };
        this.detailsTableRows.push(row1);

        const row2: SpaceDetailsTableRow = {
          col1: this.getTruckParkingSpacesTableField(),
          col2: this.getIncludesOfficeTableField()
        };
        this.detailsTableRows.push(row2);

        const row3: SpaceDetailsTableRow = {
          col1: this.getLoadCapacityTableField(),
          col2: this.getIncludesRetailTableField()
        };
        this.detailsTableRows.push(row3);

        const row4: SpaceDetailsTableRow = {
          col1: this.getSkyLightsTableField(),
          col2: this.getFullBuildOutTableField()
        };
        this.detailsTableRows.push(row4);

        const row5: SpaceDetailsTableRow = {
          col1: this.getCeilingHeightForIndustrial(),
          col2: undefined
        };
        this.detailsTableRows.push(row5);
      }
    }

    if (this.isForCoworking) {
      const firstRow: SpaceDetailsTableRow = {
        col1: this.getconferenceTimeHours(),
        col2: this.getparkingCost()
      };

      const secondRow: SpaceDetailsTableRow = {
        col1: this.getprivateOutdoor(),
        col2: this.getprintCopies()
      };

      const thirdRow: SpaceDetailsTableRow = {
        col1: this.getsharedOutdoor(),
        col2: this.getMainHandling()
      };

      const fourthRow: SpaceDetailsTableRow = {
        col1: this.getparkingAvailable(),
        col2: null
      };

      this.detailsTableRows.push(firstRow);
      this.detailsTableRows.push(secondRow);
      this.detailsTableRows.push(thirdRow);
      this.detailsTableRows.push(fourthRow);
    }
  }

  closeModal() {
    this.popoverCtrl.dismiss();
  }

  requestInfoAction(event: Event) {
    this.popoverCtrl.dismiss({
      event: event,
      requestInfo: true,
      spot: this.spot,
      space: this.space,
      spotTitle: this.spotTitle,
      requestType: RequestInformationType.REQ_INFO
    });
  }

  requestTourAction(event: Event) {
    this.popoverCtrl.dismiss({
      event: event,
      requestInfo: true,
      spot: this.spot,
      space: this.space,
      spotTitle: this.spotTitle,
      requestType: RequestInformationType.REQ_TOUR
    });
  }

  openVideoTour(pEvent: any) {
    if (this.space.vrTourUrl) {
      this.openVideoTourPopup(pEvent, this.space.vrTourUrl, this.spot.propertyName, this.isMobile, (error: string) => {
        console.error('Callback from video tour: ', error);
        this.spotBuildingsService.logError(error);
      });
    }
  }

  async openVideoTourPopup(ev: Event, videoURL: string, spotTitle: string, isMobile: boolean, callback?: any) {
    /*  if (isMobile) {
       if (videoURL) {
         let options: StreamingVideoOptions = {
           successCallback: () => {
             console.log('Video played');
           },
           errorCallback: (e: any) => {
             console.log('Error streaming', +e);
             callback(e);
           },
           orientation: 'landscape',
           shouldAutoClose: false,
           controls: true
         };
 
         try {
           this.streamingMedia.playVideo(videoURL, options);
         } catch (err) {
           console.error(err);
           if (callback) {
             callback(err);
           }
         }
       }
       return;
     } */

    const popover = await this.popoverCtrl.create({
      component: VideoComponent,
      event: ev,
      cssClass: 'video-player-popup',
      componentProps: { videoURL: videoURL, fromPopup: true, spotTitle: spotTitle },
      backdropDismiss: true
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  getSpotTitle() {
    let val = this.moduleSuiteValue();
    if (val == '' || val == null) {
      return `${this.warehouseFloorTitle()} ${this.warehouseFloorValue()} - ${this.spot.propertyName}`;
    } else {
      return `${this.warehouseFloorTitle()} ${this.warehouseFloorValue()}, ${this.moduleSuiteTitle()} ${this.moduleSuiteValue()} - ${
        this.spot.propertyName
      }`;
    }
  }

  warehouseFloorTitle() {
    if (this.isForIndustrial) {
      return this.i18n.get('buildinsDetail.wareHouse') + ':';
    }

    if (this.isForOffice || this.isForCoworking) {
      return this.i18n.get('buildinsDetail.floor') + ':';
    }
  }

  warehouseFloorValue() {
    if (this.isForIndustrial) {
      return this.space.warehouse ? this.space.warehouse : '';
    }

    if (this.isForOffice || this.isForCoworking) {
      return this.space.floor ? this.findFloorTranslation(this.space.floor) : '';
    }
  }

  moduleSuiteTitle() {
    if (this.isForIndustrial) {
      return this.i18n.get('buildinsDetail.module') + ':';
    }

    if (this.isForOffice) {
      return this.i18n.get('buildinsDetail.suite') + ':';
    }

    if (this.isForCoworking) {
      if (this.space.coworkingType) {
        if (this.space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE) {
          return this.i18n.get('buildinsDetail.suite') + ':';
        }
        if (this.space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE) {
          return this.i18n.get('spaceDetail.desk') + ':';
        }
      }
    }

    return '';
  }

  moduleSuiteValue() {
    if (this.isForIndustrial) {
      return this.space.module ? this.space.module : '';
    }

    if (this.isForOffice) {
      return this.space.suite ? this.space.suite : '';
    }

    if (this.isForCoworking) {
      if (
        this.space.coworkingType == CoworkingSpaceType.DEDICATED_WORKSPACE ||
        this.space.coworkingType == CoworkingSpaceType.PRIVATE_OFFICE
      ) {
        return this.space.suite ? this.space.suite : '';
      }
    }

    return '';
  }

  getAvailableSpaceTitle() {
    return this.i18n.get('spaceDetail.availableSpace') + ':';
  }

  getAvailableSpaceValue() {
    const area = this.commonService.formatNumberTo(this.space.area, 0) ? this.space.area : 0;
    return this.formatValueBasedOnMeasure(UnitOfMeasure.SQUARE_METERS, area);
  }

  getCoworkingPositionsTitle() {
    if (this.isForCoworking) {
      return this.i18n.get('spaceDetail.availablePositions') + ':';
    }
  }

  getPriceTitle() {
    if (this.spot.forLease) {
      return this.i18n.get('spaceDetail.monthlyRent').concat(':');
    } else {
      return this.i18n.get('spaceDetail.askingPrice') + ':';
    }
  }

  getCoworkingPositions() {
    if (this.isForCoworking) {
      return this.space.positions;
    }
  }

  getPriceValue() {
    let value = undefined;
    if (this.spot.forSale) {
      value = this.space.askingPrice;
    }

    if (this.spot.forLease) {
      value = this.spacesService.getTotalMonthlyRent(this.space);
    }

    if (value) {
      value = this.commonService.formatNumberTo(value, 0);
      value = this.formatValueBasedOnMeasure(this.spot.preferredCurrency, value);
    }

    return value != 0 && value != null ? value : this.i18n.get('global.list-your-property.negotiable');
  }

  getPricePerAreaTitle() {
    if (this.spot.forLease) {
      return this.i18n.get('spaceDetail.monthlyRentRm').concat(':');
    } else {
      return this.i18n.get('spaceDetail.askingPriceRm') + ':';
    }
  }

  getPricePerAreaValue() {
    const decimals = 2;
    let value = undefined;
    if (this.spot.forLease) {
      if (!this.space.area) {
        value = '0.00';
      } else {
        value = this.commonService.formatNumberTo(
          this.spacesService.getTotalMonthlyRent(this.space) / this.space.area,
          decimals
        );
      }
    } else {
      if (!this.space.area) {
        value = '0.00';
      } else {
        value = this.commonService.formatNumberTo(this.space.askingPrice / this.space.area, decimals);
      }
    }

    if (value != '0.00') {
      return this.formatValueBasedOnMeasure(this.spot.preferredCurrency, value);
    } else {
      return this.i18n.get('global.list-your-property.negotiable');
    }
  }

  getMonthlyCostTitle() {
    return this.i18n.get('spaceDetail.monthlyCostPerPerson') + ':';
  }

  getMonthlyCostValue() {
    const value = this.space.monthlyCost;
    if (value) {
      return this.formatValueBasedOnMeasure(this.spot.preferredCurrency, value);
    }
  }

  getFieldValue(val: number, unitMeasure: string, decimals: number) {
    if (!unitMeasure && !decimals) return val ? val : this.emptyFieldValue;

    let fieldValue = decimals && val ? this.commonService.formatNumberTo(val, decimals) : val;

    fieldValue = this.formatValueBasedOnMeasure(unitMeasure, fieldValue);

    if (!fieldValue) {
      return this.emptyFieldValue;
    }

    return fieldValue;
  }

  formatValueBasedOnMeasure(unitMeasure: string, fieldValue: any) {
    if (unitMeasure && fieldValue) {
      if (unitMeasure == UnitOfMeasure.PRICE || unitMeasure == UnitOfMeasure.MXN) {
        fieldValue = `${unitMeasure} ${fieldValue}`;
      } else {
        fieldValue = `${fieldValue} ${unitMeasure}`;
      }
    }
    return fieldValue;
  }

  getBooleanFieldValue(val: boolean) {
    return val ? this.i18n.get('global.revisionChanges.yes') : this.i18n.get('global.revisionChanges.no');
  }

  getMaxContiguousTableField() {
    const field: SpaceDetailsTableField = {
      name: 'maxContiguous',
      translate: 'spaceDetail.maxContiguous',
      value: this.getPropertyValue('maxContiguous', UnitOfMeasure.SQUARE_METERS)
    };

    return field;
  }

  getMonthlyRentTableField() {
    const field: SpaceDetailsTableField = {
      name: 'R',
      translate: 'spaceDetail.R',
      priceClass: true,
      value: this.getPropertyValue('R', this.spot.preferredCurrency, 2)
    };
    return field;
  }

  getCeilingHeightOfficeTableField() {
    const fieldName = 'ceilingHeightOffice';
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      value: this.getPropertyValue(fieldName, UnitOfMeasure.METERS)
    };

    return field;
  }

  getCondoTableField() {
    const fieldName = 'condo';
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      priceClass: true,
      value: this.getPropertyValue(fieldName, this.spot.preferredCurrency, 2)
    };
    return field;
  }

  getParkingSpacesTableField() {
    const fieldName = 'parkingSpaces';
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      value: this.getPropertyValue(fieldName)
    };

    return field;
  }

  getIptuTableField() {
    const fieldName = 'iptu';
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      priceClass: true,
      value: this.getPropertyValue(fieldName, this.spot.preferredCurrency, 2)
    };
    return field;
  }

  getDirectSubleaseTableField() {
    const fieldName = 'directSublease';
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      value: this.getPropertyValue(fieldName)
    };

    return field;
  }

  getTotalTableField() {
    const fieldName = 'total';
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      priceClass: true,
      value: this.getPropertyValue(fieldName, this.spot.preferredCurrency, 2)
    };
    return field;
  }

  getDocksTableField() {
    return this.createTableField('docks');
  }

  getIncludesRetailTableField() {
    return this.createTableField('includesRetail');
  }

  getTruckParkingSpacesTableField() {
    return this.createTableField('truckParkingSpaces');
  }

  getFullBuildOutTableField() {
    return this.createTableField('fullBuildOut');
  }

  getLoadCapacityTableField() {
    return this.createTableField('loadCapacity', UnitOfMeasure.SQUARE_TON_METERS, 0);
  }

  getSkyLightsTableField() {
    return this.createTableField('skyLights');
  }

  getCeilingHeightForIndustrial() {
    return this.createTableField('ceilingHeight', UnitOfMeasure.METERS, 0);
  }

  getColdStorageTableField() {
    return this.createTableField('coldStorage');
  }

  getconferenceTimeHours() {
    return this.createTableField('conferenceTimeHours');
  }

  getparkingCost() {
    return this.createTableField('parkingCost', UnitOfMeasure.PRICE, 0);
  }

  getprivateOutdoor() {
    return this.createTableField('privateOutdoor');
  }

  getprintCopies() {
    return this.createTableField('printCopies', '', 0);
  }

  getsharedOutdoor() {
    return this.createTableField('sharedOutdoor');
  }

  getMainHandling() {
    return this.createTableField('mainHandling');
  }

  getparkingAvailable() {
    return this.createTableField('parkingAvailable');
  }

  getIncludesOfficeTableField() {
    return this.createTableField('includesOffice');
  }

  private createTableField(fieldName: string, unit?: string, decimals?: number) {
    const field: SpaceDetailsTableField = {
      name: fieldName,
      translate: `spaceDetail.${fieldName}`,
      value: this.getPropertyValue(fieldName, unit, decimals)
    };
    return field;
  }

  getPropertyValue(propertyName: string, unitOfMeasure?: string, decimals?: number) {
    switch (propertyName) {
      case 'docks':
        return this.getFieldValue(this.space.docks, unitOfMeasure, decimals);
      case 'includesRetail':
        return this.getBooleanFieldValue(this.space.includesRetail);
      case 'cardParkingSpaces':
      case 'parkingSpaces':
        return this.getFieldValue(this.space.parkingSpaces, unitOfMeasure, decimals);
      case 'fullBuildOut':
        return this.getBooleanFieldValue(this.space.fullBuildOut);
      case 'truckParkingSpaces':
        return this.getFieldValue(this.space.truckParkingSpaces, unitOfMeasure, decimals);
      case 'directSublease':
        return this.commonService.getLeaseTypeTxt(this.space.industrialLeaseType);
      case 'loadCapacity':
        return this.getFieldValue(this.space.loadCapacity, unitOfMeasure, decimals);
      case 'R':
      case 'rentPerm2':
        return this.getFieldValue(this.space.monthlyAskingRent, unitOfMeasure, decimals);
      case 'skyLights':
        return this.getBooleanFieldValue(this.space.skyLights);
      case 'condo':
      case 'condoPerm2':
        return this.getFieldValue(this.space.monthlyCondo, unitOfMeasure, decimals);

      case 'ceilingHeight':
      case 'ceilingHeightOffice':
        return this.getFieldValue(this.space.ceilingHeight, unitOfMeasure, decimals);

      case 'iptu':
      case 'iptuPerm2':
        return this.getFieldValue(this.space.monthlyIptu, unitOfMeasure, decimals);

      case 'includesOffice':
        return this.getBooleanFieldValue(this.space.includesOffice);

      case 'coldStorage':
        return this.getBooleanFieldValue(this.space.coldStorage);

      case 'maxContiguous':
        return this.getFieldValue(this.space.maxContiguous, unitOfMeasure, decimals);

      case 'conferenceTimeHours':
        return this.getFieldValue(this.space.conferenceTimeHours, unitOfMeasure, decimals);

      case 'parkingCost':
        return this.getFieldValue(this.space.parkingCost, unitOfMeasure, decimals);

      case 'privateOutdoor':
        return this.getBooleanFieldValue(this.space.privateOutdoor);

      case 'printCopies':
        return this.getFieldValue(this.space.printCopies, unitOfMeasure, decimals);

      case 'sharedOutdoor':
        return this.getBooleanFieldValue(this.space.sharedOutdoor);

      case 'mainHandling':
        return this.getBooleanFieldValue(this.space.mailHandling);

      case 'parkingAvailable':
        return this.getBooleanFieldValue(this.space.parkingAvailable);

      case 'total':
        return this.getFieldValue(this.spacesService.getTotalMonthlyRent(this.space, true), unitOfMeasure, decimals);

      default:
        return this.emptyFieldValue;
    }
  }

  toogleAction(action: string) {
    switch (action) {
      case 'moreInfo':
        this.showMoreInfo = this.showMoreInfo ? this.showMoreInfo : !this.showMoreInfo;
        this.showSpaceImgs = false;
        this.showMediaKit = false;
        break;
      case 'spaceImgs':
        this.showMoreInfo = false;
        this.showSpaceImgs = this.showSpaceImgs ? this.showSpaceImgs : !this.showSpaceImgs;
        this.showMediaKit = false;
        break;
      case 'mediaKit':
        this.showMoreInfo = false;
        this.showSpaceImgs = false;
        this.showMediaKit = this.showMediaKit ? this.showMediaKit : !this.showMediaKit;
        break;
    }
  }

  findFloorTranslation(floorValue: string) {
    if (!floorValue) {
      return '';
    }
    let translationKey = null;
    if (this.isMezzanine(floorValue)) {
      translationKey = 'buildinsDetail.mezzanine';
    } else if (this.isGroundFloor(floorValue)) {
      translationKey = 'buildinsDetail.groundfloor';
    } else if (this.isRooftop(floorValue)) {
      translationKey = 'buildinsDetail.rooftop';
    }

    if (translationKey != null) {
      return this.i18n.get(translationKey);
    }

    return floorValue;
  }

  private isMezzanine(floor: string) {
    return this.isEqualsTo(floor, ['Mezzanine', 'Mezanino']);
  }

  private isGroundFloor(floor: string) {
    return this.isEqualsTo(floor, ['Ground floor', 'Térreo', 'Terreo']);
  }

  private isRooftop(floor: string) {
    return this.isEqualsTo(floor, ['Terraço', 'Rooftop']);
  }

  private isEqualsTo(floor: string, customFloor: string[]) {
    return floor && customFloor.includes(floor);
  }
}
