import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from './i18n.service';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import {
  ListingCarouselService,
  ListingService,
  ListingDetailService,
  ListPropertyService,
  CreateListingService,
  ListingFormService,
  ListingPriceService,
  ListingCleanupService
} from '@app/core/listings';
import { RegisterListingService } from '@app/core/register-listing.service';
import { ImageService } from './image.service';
import { MetaService } from './seo';
import { GeoLocationService } from './geolocation.service';
import { SearchTagsService } from './search-tags.service';
import { RegisterService } from './register.service';
import { PlatformHelperService, PropertyTypeHelperService } from './helpers';
import { InstallPromptDialogService } from './install-prompt.service';
import { MaterialModule } from '@app/shared/material.module';
import { CommonGenericService } from './common.service';
import { LayoutModule } from '@angular/cdk/layout';
import { AuthenticationService } from './auth/auth.service';

import { AccountService } from './auth/account.service';
import { ToastNotificationService } from './listings/toast-notification.service';
import { UserActivityService } from './user-activity/user-activity.service';
import { EmailService } from './mail/email.service';
import { RichTextEditorService } from './richtext-editor.service';
import { WhatsAppService } from './whatsapp.service';
import { MultiLanguageService } from './multi-language.service';
import { GenericMapService } from './generic-map.service';
import { NearbyListingSvc } from './listings/nearby-listing.service';
import { UserService } from './helpers/user.service';
import { StatusHelperService } from './helpers/status-helper.service';
import { ClassHelperService } from './helpers/classes-helper.service';
import { PopoverCtrlHelper } from './helpers/popover-ctrl-helper.service';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { MatomoService } from '@app/matomo/matomo-service';
import { UtilityService } from './helpers/utility-services';
import { RegisterBuildingService } from '@app/core/register-building.service';
import { RoutingHelperService } from './helpers/routing-helper.service';
import { ReixSpotLanguagesService } from './reixspotlanguages.service';
import { AppRouteNamesService } from './helpers/app-route-names.service';

@NgModule({
  imports: [
    HttpClientJsonpModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    LayoutModule
  ],
  exports: [],
  providers: [
    AnalyticsService,
    MatomoService,
    I18nService,
    ReixSpotLanguagesService,
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    ListingCarouselService,
    ListingService,
    ListingDetailService,
    ListPropertyService,
    AuthenticationService,
    AccountService,
    ToastNotificationService,
    UserService,
    StatusHelperService,
    AppRouteNamesService,
    RoutingHelperService,
    ClassHelperService,
    PopoverCtrlHelper,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    ImageService,
    SearchTagsService,
    MetaService,
    GeoLocationService,
    RegisterService,
    PlatformHelperService,
    PropertyTypeHelperService,
    InstallPromptDialogService,
    CommonGenericService,
    UserActivityService,
    CreateListingService,
    ListingFormService,
    ListingPriceService,
    RegisterListingService,
    UtilityService,
    EmailService,
    RichTextEditorService,
    WhatsAppService,
    MultiLanguageService,
    GenericMapService,
    ListingCleanupService,
    NearbyListingSvc,
    RegisterBuildingService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
