import { createReducer, on } from '@ngrx/store';
import * as CartService from '../services/cart.service';

import * as CartActions from '../actions/';

export const layoutFeatureKey = 'cart';

export interface CartState {
  //cart model state
  id: number;
  userId: number;
  items: any[];
  purchaseOption: any;
  total: number;
  // duplicate: boolean;
  calculations: {
    totalItemCount: number;
  };
}

const initialState: CartState = {
  id: 1,
  userId: null,
  items: [],
  purchaseOption: null,
  total: 0,
  // duplicate: false,
  calculations: {
    totalItemCount: 0
  }
};

export const reducer = createReducer(
  initialState,
  // Even thought the `state` is unused, it helps infer the return type
  on(CartActions.CreateCart, _ => initialState),
  on(CartActions.DeleteCart, _ => initialState),
  on(CartActions.GetCart, state => state),
  on(CartActions.UpdateCart, state => ({ ...state })),
  on(CartActions.SaveCart, state => state),
  on(CartActions.AddItem, (state, { payload }) => {
    return {
      ...state,
      items: [...state.items, ...payload.items]
    };
  }),
  on(CartActions.RemoveItem, (state, { payload }) => ({
    ...state,
    items: [
      ...state.items.filter((item, index) => {
        return index !== payload.index;
      })
    ]
  })),
  on(CartActions.UpdateItem, (state, { payload }) => {
    return {
      ...state,
      items: [
        ...state.items.map((item, index) => {
          if (index === payload.index) {
            return payload.item;
          }
          return item;
        })
      ]
    };
  }),
  on(CartActions.AddSelection, (state, { payload }) => {
    return {
      ...state,
      purchaseOption: payload.item
    };
  }),
  on(CartActions.ClearCart, (state, { payload }) => {
    return {
      ...state,
      items: payload.items
    };
  }),
  on(CartActions.ClearSelection, (state, { payload }) => {
    return {
      ...state,
      purchaseOption: payload.item
    };
  })
);

export const getCartState = (state: CartState) => state;
