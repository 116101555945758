import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { MatDialog, MatStepper } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { I18nService } from '@app/core/i18n.service';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { SendNotificationComponent } from '@app/shared/send-notification/send-notification.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { AlertController } from '@ionic/angular';
var CreateSpotBuildingsComponent = /** @class */ (function () {
    function CreateSpotBuildingsComponent(createSpotBuildingsService, imageUploadService, router, activatedRoute, i18NService, alertCtrl, dialog, breakpointObserver, routingHelperSrv) {
        var _this = this;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.imageUploadService = imageUploadService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.i18NService = i18NService;
        this.alertCtrl = alertCtrl;
        this.dialog = dialog;
        this.breakpointObserver = breakpointObserver;
        this.routingHelperSrv = routingHelperSrv;
        this.currentStep = 0;
        this.uploadingImages = false;
        this.imgList = [];
        this.spotBuildings = new CreateSpotBuildingsRequest();
        this.typeOffer = '';
        this.propertyType = [];
        this.entireSale = false;
        this.emitChanges = false;
        this.saveImages = true;
        this.isMobile = false;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        this.spotBuildings.enabled = false;
        this.getParamsUrl();
    }
    CreateSpotBuildingsComponent.prototype.ngOnInit = function () { };
    CreateSpotBuildingsComponent.prototype.getParamsUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.activatedRoute.params.subscribe(function (paramMap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var baseId, buildingIdParam, listingTypeParam;
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        try {
                            baseId = paramMap.buildingId;
                            buildingIdParam = paramMap.buildingId;
                            listingTypeParam = paramMap.buildingType;
                            if (buildingIdParam) {
                                this.createSpotBuildingsService
                                    .getSpotBuildingsFromBuilding(buildingIdParam, listingTypeParam, this.i18NService.getCurrentLanguage())
                                    .then(function (response) {
                                    _this.buildingDetail = response;
                                    if (_this.buildingDetail.buildingListingPhotos) {
                                        _this.imgList = _this.buildingDetail.buildingListingPhotos;
                                    }
                                });
                            }
                        }
                        catch (e) { }
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    CreateSpotBuildingsComponent.prototype.goBack = function () {
        this.stepper.previous();
        this.currentStep = this.currentStep - 1;
    };
    CreateSpotBuildingsComponent.prototype.goForward = function () {
        this.currentStep = this.currentStep + 1;
        if (this.currentStep == 2) {
            this.typeOffer = this.spotBuildings.type;
            this.entireSale = this.spotBuildings.entireSale;
            this.emitChanges = !this.emitChanges;
        }
        this.stepper.next();
    };
    CreateSpotBuildingsComponent.prototype.saveBuilding = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, photos;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.saveImages = false;
                        if (!this.buildingDetail) return [3 /*break*/, 2];
                        this.spotBuildings.id = this.buildingDetail.id;
                        _a = this;
                        return [4 /*yield*/, this.createSpotBuildingsService.updateSpotBuildings(this.spotBuildings)];
                    case 1:
                        _a.createSpotBuildingsResponse = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.createSpotBuildingsService.createSpotBuildings(this.spotBuildings)];
                    case 3:
                        _b.createSpotBuildingsResponse = _c.sent();
                        this.spotBuildings.id = this.createSpotBuildingsResponse.id;
                        _c.label = 4;
                    case 4:
                        this.saveImages = true;
                        photos = this.imageUploadService.associatePhotosToBuildingListing(this.createSpotBuildingsResponse.id, this.imgList);
                        this.goForward();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSpotBuildingsComponent.prototype.closeBuilding = function () {
        this.openModalNotification();
    };
    CreateSpotBuildingsComponent.prototype.sendNotification = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            subHeader: this.i18NService.get('notifications.sendNotification'),
                            cssClass: 'ion-alert-listings',
                            backdropDismiss: true,
                            buttons: [
                                {
                                    text: this.i18NService.get('global.revisionChanges.no'),
                                    role: 'cancel',
                                    handler: function () {
                                        _this.routingHelperSrv.navigateToSearchPage(true);
                                    }
                                },
                                {
                                    text: this.i18NService.get('global.revisionChanges.yes'),
                                    handler: function () {
                                        _this.openModalNotification();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateSpotBuildingsComponent.prototype.openModalNotification = function () {
        var _this = this;
        var notify = {
            content: '',
            spotId: this.buildingDetail ? this.buildingDetail.id : this.spotBuildings.id,
            newSpotBuilding: this.buildingDetail ? false : true,
            predefinedNotificationId: null
        };
        var dialogRef = this.dialog.open(SendNotificationComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                notify: notify
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.routingHelperSrv.navigateToSearchPage(true);
            }
        });
    };
    return CreateSpotBuildingsComponent;
}());
export { CreateSpotBuildingsComponent };
