import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { CreateListingService, ListingDetailService } from '@app/core/listings';
import { CommonGenericService } from './common.service';
import { map } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { AlertController } from '@ionic/angular';
import { I18nService } from './i18n.service';
import { Router } from '@angular/router';
import { ListingOfferType } from '../models/transaction-type.model';
var RegisterListingService = /** @class */ (function () {
    function RegisterListingService(http, listingDetailService, createService, commonService, langService, alertCtrl, i18NService, router) {
        this.http = http;
        this.listingDetailService = listingDetailService;
        this.createService = createService;
        this.commonService = commonService;
        this.langService = langService;
        this.alertCtrl = alertCtrl;
        this.i18NService = i18NService;
        this.router = router;
        this.listing = {};
        this.newImgList = [];
        this.areaUOMString = 'api/areaUOMs';
        this.currencyAreaStringUOM = 'api/currencyAreaUOMs';
        this.currencyStringUOM = 'api/currencyUOMs';
        this.createUpdateListing = 'api/listings';
        this.apiImages = 'api/images';
        this.createImageAPI = 'api/listingPhotos/create-image';
        this.listingPhotos = 'api/listingPhotos/';
        this.associatePhotosToListingAPI = 'api/listingPhotos/listing/';
        this.copyPhotosToListingAPI = 'api/listingPhotos/copy/to/listing/';
        //this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
    }
    RegisterListingService.prototype.getAreaUom = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("" + this.areaUOMString, { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.getCurrencyAreaUom = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("" + this.currencyAreaStringUOM, { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.getCurrencyUOM = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("" + this.currencyStringUOM, { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.checkRole = function (role) {
        try {
            var user = JSON.parse(localStorage.getItem('user'));
            return user && user.roles ? user.roles.includes(role) : false;
        }
        catch (e) {
            console.error('roles undefined --> ', e);
        }
    };
    RegisterListingService.prototype.uploadPhoto = function (file, caption, listingId, photoOrder, content, croppedFile, originalImageId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            return tslib_1.__generator(this, function (_a) {
                formData = new FormData();
                formData.append('photoOrder', photoOrder);
                formData.append('listingId', listingId);
                formData.append('caption', caption);
                formData.append('file', file);
                formData.append('content', content);
                formData.append('croppedFile', croppedFile);
                if (originalImageId && originalImageId != null) {
                    formData.append('originalImageId', originalImageId);
                }
                return [2 /*return*/, this.http
                        .post("" + this.createImageAPI, formData)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.uploadNewImage = function (file, caption) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData;
            return tslib_1.__generator(this, function (_a) {
                formData = new FormData();
                formData.append('caption', caption);
                formData.append('file', file);
                return [2 /*return*/, this.http
                        .post("" + this.apiImages, formData)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.deleteListingPhoto = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.delete("" + this.listingPhotos + id).toPromise()];
            });
        });
    };
    RegisterListingService.prototype.updateListingPhoto = function (listingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put("" + this.listingPhotos, listingPhoto)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.associatePhotosToListing = function (listingId, imgList) {
        if (!listingId) {
            console.error('No Listing ID Found');
            return imgList;
        }
        var listingPhotos = imgList.map(function (photo) {
            photo.listingId = listingId;
            return photo;
        });
        var photoIds = listingPhotos.map(function (img) { return img.id; });
        this.http
            .put("" + this.associatePhotosToListingAPI + listingId, photoIds)
            .pipe(map(function (val) { return val; }))
            .toPromise();
        return listingPhotos;
    };
    RegisterListingService.prototype.updateToApproval = function (listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                listing.listingStatus = { id: 1 };
                return [2 /*return*/, this.http.put("" + this.createUpdateListing, listing).toPromise()];
            });
        });
    };
    RegisterListingService.prototype.adminSaveListing = function (listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.put("" + this.createUpdateListing, listing).toPromise()];
            });
        });
    };
    RegisterListingService.prototype.uploadImages = function (imgList, previewListing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var photos, croppedSavedImage;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                photos = [];
                croppedSavedImage = null;
                Object.keys(imgList).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var listingPhoto, newListingPhoto, upload, img, croppedImage, _a, upload;
                    return tslib_1.__generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!imgList[key].image) return [3 /*break*/, 3];
                                listingPhoto = imgList[key];
                                return [4 /*yield*/, this.createCroppedImage(listingPhoto)];
                            case 1:
                                croppedSavedImage = _b.sent();
                                newListingPhoto = {
                                    orderId: idx + 1,
                                    id: listingPhoto.id,
                                    image: listingPhoto.image,
                                    originalImage: listingPhoto.originalImage,
                                    listingId: listingPhoto.listingId,
                                    croppedImage: croppedSavedImage,
                                    createdDate: new Date()
                                };
                                return [4 /*yield*/, this.updateListingPhoto(newListingPhoto)];
                            case 2:
                                upload = _b.sent();
                                photos.push(upload);
                                return [3 /*break*/, 9];
                            case 3: return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgList[key].img, imgList[key].content)];
                            case 4:
                                img = _b.sent();
                                if (!imgList[key].croppedImage) return [3 /*break*/, 6];
                                return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgList[key].croppedImage, imgList[key].content)];
                            case 5:
                                _a = _b.sent();
                                return [3 /*break*/, 7];
                            case 6:
                                _a = null;
                                _b.label = 7;
                            case 7:
                                croppedImage = _a;
                                return [4 /*yield*/, this.uploadPhoto(img, imgList[key].caption, previewListing.id, idx + 1, imgList[key].content, croppedImage, imgList[key].originalImageId)];
                            case 8:
                                upload = _b.sent();
                                photos.push(upload);
                                _b.label = 9;
                            case 9: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/, photos];
            });
        });
    };
    RegisterListingService.prototype.clearTags = function (dto) {
        if (dto.refrigerated)
            dto.refrigerated = false;
        if (dto.sluiceGate)
            dto.sluiceGate = false;
        if (dto.hasLockerRooms)
            dto.hasLockerRooms = false;
        //if (dto.hasCeilingHeight) dto.hasCeilingHeight = false;
        if (dto.heliport)
            dto.heliport = false;
        if (dto.sprinklers)
            dto.sprinklers = false;
        if (dto.electricGenerator)
            dto.electricGenerator = false;
        //if (dto.hasParkingSpaces) dto.hasParkingSpaces = false;
        if (dto.plugAndPlay)
            dto.plugAndPlay = false;
        //if (dto.finishedOut) dto.finishedOut = false;
        if (dto.furnished)
            dto.furnished = false;
        if (dto.coreAndShel)
            dto.coreAndShel = false;
        if (dto.centralAirConditioning)
            dto.centralAirConditioning = false;
        if (dto.miniSplitAirConditioning)
            dto.miniSplitAirConditioning = false;
        if (dto.bicycleRack)
            dto.bicycleRack = false;
        if (dto.leedStatus)
            dto.leedStatus = false;
        if (dto.raisedFloor)
            dto.raisedFloor = false;
        if (dto.hourSecurity)
            dto.hourSecurity = false;
        if (dto.neverUsed)
            dto.neverUsed = false;
        if (dto.hasRestrooms)
            dto.hasRestrooms = false;
        if (dto.armoredCabin)
            dto.armoredCabin = false;
        if (dto.manufacturing)
            dto.manufacturing = false;
        //if (dto.logistics) dto.logistics = false;
        if (dto.officeSpaceAvailable)
            dto.officeSpaceAvailable = false;
        //if (dto.fireProtectionSystem) dto.fireProtectionSystem = false;
        if (dto.blockConstruction)
            dto.blockConstruction = false;
        if (dto.steelConstruction)
            dto.steelConstruction = false;
        if (dto.blockAndSteelConstruction)
            dto.blockAndSteelConstruction = false;
        //if (dto.hasLoadCapacity) dto.hasLoadCapacity = false;
        //if (dto.hasDockRatio) dto.hasDockRatio = false;
        //if (dto.hasCarParkingSpaces) dto.hasCarParkingSpaces = false;
        if (dto.singleTenant)
            dto.singleTenant = false;
        if (dto.multiTenant)
            dto.multiTenant = false;
        //if (dto.hasTruckParkingSpaces) dto.hasTruckParkingSpaces = false;
        //if (dto.industrialPark) dto.industrialPark = false;
        //if (dto.industrialZone) dto.industrialZone = false;
        if (dto.truckYard)
            dto.truckYard = false;
        if (dto.crossDocking)
            dto.crossDocking = false;
        if (dto.twentyFourHourSecurity)
            dto.twentyFourHourSecurity = false;
        if (dto.coreAndShel)
            dto.coreAndShel = false;
        //if (dto.hasBaySize) dto.hasBaySize = false;
    };
    RegisterListingService.prototype.resetFeaturesToExistingListing = function (features) {
        features.map(function (t) {
            t.selected = false;
            return t;
        });
        var previousFeatures = this.createService.checkFields(features);
        this.listing = Object.assign({}, this.listing, previousFeatures);
    };
    RegisterListingService.prototype.formBuildingListing = function (form, offeredByUserId, created, listingPurchaseId, subTypes, listingType, tags, imgList, previewListing, revise) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, _a, _b, _c, originalTags, newTags, types_1, _d, photos, copyOfPhotos, listing_1, photos_1, listing_2, _e, photos_2, listing_3, _f, photos_3, error_1, message, listing, _g, photos, photos_4, listing, _h, photos_5;
            var _this = this;
            return tslib_1.__generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        this.listing = {};
                        user = JSON.parse(localStorage.getItem('user'));
                        _a = this;
                        return [4 /*yield*/, this.getAreaUom()];
                    case 1:
                        _a.areaUOM = _j.sent();
                        _b = this;
                        return [4 /*yield*/, this.getCurrencyAreaUom()];
                    case 2:
                        _b.currencyAreaUom = _j.sent();
                        _c = this;
                        return [4 /*yield*/, this.getCurrencyUOM()];
                    case 3:
                        _c.currencyUom = _j.sent();
                        this.listing.leaseableAreaUOM = this.areaUOM[0];
                        this.listing.rentableAreaUOM = this.areaUOM[0];
                        this.listing.offeringPriceUOM = this.currencyUom[0];
                        this.listing.monthlyRentUOM = this.currencyUom[0];
                        this.listing.offeringPricePerAreaUOM = this.currencyAreaUom[0];
                        this.listing.monthlyRentPerAreaUOM = this.currencyAreaUom[0];
                        //this.clearTags(this.listing);
                        created ? this.clearTags(this.previewListing) : null;
                        if (tags) {
                            originalTags = tslib_1.__assign({}, tags);
                            form.commericalType == 1001 ? (tags = tags.industrial) : (tags = tags.office);
                            newTags = [];
                            if (form.commericalType == 1001 && originalTags) {
                                this.resetFeaturesToExistingListing(originalTags.office);
                            }
                            else if (form.commericalType == 2001) {
                                this.resetFeaturesToExistingListing(originalTags.industrial);
                            }
                            this.tags = {};
                            this.tags ? (this.tags = this.createService.checkFields(tags)) : null;
                            //this.tags ? (this.tags = this.createService.removeFalseValues(this.tags)) : null; It's breaking changes made on the revision
                            this.listing = Object.assign({}, this.listing, this.tags);
                        }
                        this.listing.neighborhood = form.neighborhood;
                        // SALE
                        if (form.listingType === 'forSale') {
                            this.listing.isForLeaseSale = 'S';
                            if (form.rentableArea) {
                                this.listing.rentableArea = parseFloat(this.langService.locale === 'en-US' ? form.rentableArea : form.rentableArea.replace(/\,/g, '.'));
                            }
                            if (form.pricePer) {
                                this.listing.offeringPrice = null;
                                this.listing.offeringPricePerArea = null;
                            }
                            else {
                                if (form.totalAskingPrice) {
                                    this.listing.offeringPrice = parseFloat(this.langService.locale === 'en-US' ? form.totalAskingPrice : form.totalAskingPrice.replace(/\,/g, '.'));
                                }
                                if (form.pricePerSquare) {
                                    this.listing.offeringPricePerArea = parseFloat(this.langService.locale === 'en-US' ? form.pricePerSquare : form.pricePerSquare.replace(/\,/g, '.'));
                                }
                            }
                        }
                        else if (form.listingType === 'forRent') {
                            // LEASE
                            this.listing.isForLeaseSale = 'L';
                            if (form.leaseableArea) {
                                this.listing.leaseableArea = parseFloat(this.langService.locale === 'en-US' ? form.leaseableArea : form.leaseableArea.replace(/\,/g, '.'));
                            }
                            if (form.leaseableAreaTotal) {
                                this.listing.leaseableAreaTotal = parseFloat(this.langService.locale === 'en-US' ? form.leaseableAreaTotal : form.leaseableAreaTotal.replace(/\,/g, '.'));
                            }
                            if (form.negRentableArea) {
                                this.listing.monthlyRent = null;
                                this.listing.monthlyRentPerArea = null;
                            }
                            else {
                                if (form.monthlyRent) {
                                    this.listing.monthlyRent = parseFloat(this.langService.locale === 'en-US' ? form.monthlyRent : form.monthlyRent.replace(/\,/g, '.'));
                                }
                                if (form.monthlyRentPerArea) {
                                    this.listing.monthlyRentPerArea = parseFloat(this.langService.locale === 'en-US' ? form.monthlyRentPerArea : form.monthlyRentPerArea.replace(/\,/g, '.'));
                                }
                            }
                            if (form.negCondoFeeArea) {
                                this.listing.monthlyCondoFee = null;
                                this.listing.monthlyCondoFeePerArea = null;
                            }
                            else {
                                if (form.monthlyCondoFee) {
                                    this.listing.monthlyCondoFee = parseFloat(this.langService.locale === 'en-US' ? form.monthlyCondoFee : form.monthlyCondoFee.replace(/\,/g, '.'));
                                }
                                if (form.monthlyCondoFeePerArea) {
                                    this.listing.monthlyCondoFeePerArea = parseFloat(this.langService.locale === 'en-US'
                                        ? form.monthlyCondoFeePerArea
                                        : form.monthlyCondoFeePerArea.replace(/\,/g, '.'));
                                }
                            }
                            if (form.negIPTUArea) {
                                this.listing.monthlyIptu = null;
                                this.listing.monthlyIptuPerArea = null;
                            }
                            else {
                                if (form.monthlyIptu) {
                                    this.listing.monthlyIptu = parseFloat(this.langService.locale === 'en-US' ? form.monthlyIptu : form.monthlyIptu.replace(/\,/g, '.'));
                                }
                                if (form.monthlyIptuPerArea) {
                                    this.listing.monthlyIptuPerArea = parseFloat(this.langService.locale === 'en-US' ? form.monthlyIptuPerArea : form.monthlyIptuPerArea.replace(/\,/g, '.'));
                                }
                            }
                        }
                        else if (form.listingType === 'forCoworking') {
                            this.listing.isForLeaseSale = ListingOfferType.Coworking;
                        }
                        this.listing.comments = form.description;
                        this.listing.title = form.title;
                        this.listing.propertyName = form.propertyName;
                        this.listing.measurementStandard = form.measurementStandards;
                        this.listing.startDate = new Date();
                        form.vrTourUrl ? (this.listing.vrTourUrl = form.vrTourUrl) : (this.listing.vrTourUrl = null);
                        this.listing.isEnabled = false;
                        listingType ? (this.listing.listingType = 'PREMIUM') : (this.listing.listingType = 'PROMOTED');
                        this.listing.createdByUser = { id: user.id, company: user.company, email: user.email, login: user.login };
                        if (form.offeredByUserId) {
                            this.listing.offeredByUser = {
                                id: form.offeredByUserId.id,
                                company: form.offeredByUserId.company,
                                login: form.offeredByUserId.login
                            };
                        }
                        if (form.propertyManagementCompany) {
                            this.listing.propertyManagementCompany = form.propertyManagementCompany;
                        }
                        if (form.managerEmail) {
                            this.listing.managerEmail = form.managerEmail;
                        }
                        if (form.managerName) {
                            this.listing.managerName = form.managerName;
                        }
                        if (form.managerTelephone) {
                            this.listing.managerTelephone = form.managerTelephone;
                        }
                        if (form.coworkingEntries) {
                            Object.keys(form.coworkingEntries).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    form.coworkingEntries[key].sharedDailyByPosition
                                        ? (form.coworkingEntries[key].sharedDailyByPosition = parseFloat(form.coworkingEntries[key].sharedDailyByPosition).toFixed(2))
                                        : null;
                                    form.coworkingEntries[key].sharedMonthlyByPosition
                                        ? (form.coworkingEntries[key].sharedMonthlyByPosition = parseFloat(form.coworkingEntries[key].sharedMonthlyByPosition).toFixed(2))
                                        : null;
                                    form.coworkingEntries[key].nonSharedDailyByPosition
                                        ? (form.coworkingEntries[key].nonSharedDailyByPosition = parseFloat(form.coworkingEntries[key].nonSharedDailyByPosition).toFixed(2))
                                        : null;
                                    form.coworkingEntries[key].nonSharedMonthlyByPosition
                                        ? (form.coworkingEntries[key].nonSharedMonthlyByPosition = parseFloat(form.coworkingEntries[key].nonSharedMonthlyByPosition).toFixed(2))
                                        : null;
                                    return [2 /*return*/];
                                });
                            }); });
                            this.listing.coworkingEntries = form.coworkingEntries;
                        }
                        if (form.coworkingMaxPositions) {
                            this.listing.coworkingMaxPositions = form.coworkingMaxPositions;
                        }
                        if (subTypes) {
                            types_1 = [];
                            Object.keys(subTypes).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (subTypes[key].selected) {
                                        types_1.push({ id: subTypes[key].id, name: subTypes[key].name });
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                            this.listing.propertySubTypes = types_1;
                        }
                        this.checkValues(form);
                        if (form.actualProperty) {
                            this.listing.building = { id: form.actualProperty.id };
                        }
                        else {
                            this.listing.building = {
                                postalCode: form.zip,
                                city: { id: form.city },
                                state: { id: form.state },
                                address: form.address,
                                latitude: form.buildingLocation.marker.lat,
                                longitude: form.buildingLocation.marker.lng,
                                buildingType: { id: parseInt(form.commericalType) },
                                title: form.property
                            };
                        }
                        if (!(!created && !revise)) return [3 /*break*/, 5];
                        this.listing.listingPurchaseId = listingPurchaseId;
                        this.listing.listingStatus = { id: 7 };
                        _d = this;
                        return [4 /*yield*/, this.http.post("" + this.createUpdateListing, this.listing).toPromise()];
                    case 4:
                        _d.previewListing = _j.sent();
                        photos = this.associatePhotosToListing(this.previewListing.id, imgList);
                        copyOfPhotos = photos;
                        this.previewListing.photos = photos;
                        this.previewPhotos = copyOfPhotos;
                        return [2 /*return*/, this.previewListing];
                    case 5:
                        if (!revise) return [3 /*break*/, 17];
                        if (!(this.editActiveRevisionListing(revise) ||
                            (this.checkRole('ROLE_SPOT_ADMIN') &&
                                (revise.listingStatus.id === 4 || this.isUserAllowToEditPendingApprovalListing(revise)) &&
                                !revise.nextListingId))) return [3 /*break*/, 6];
                        revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
                        this.listing.listingType = revise.listingType;
                        listing_1 = tslib_1.__assign({}, revise, this.listing);
                        photos_1 = this.associatePhotosToListing(revise.id, imgList);
                        listing_1.photos = photos_1;
                        listing_1.listingPhotos = photos_1;
                        return [2 /*return*/, listing_1];
                    case 6:
                        if (!(revise.listingStatus.id === 8)) return [3 /*break*/, 8];
                        revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
                        this.listing.listingType = revise.listingType;
                        listing_2 = tslib_1.__assign({}, revise, this.listing);
                        _e = this;
                        return [4 /*yield*/, this.http.put("" + this.createUpdateListing, listing_2).toPromise()];
                    case 7:
                        _e.previewListing = _j.sent();
                        photos_2 = this.associatePhotosToListing(this.previewListing.id, imgList);
                        this.previewListing.photos = photos_2;
                        return [2 /*return*/, this.previewListing];
                    case 8:
                        if (!(!revise.nextListingStatus && revise.listingStatus.id !== 8 && revise.listingStatus.id !== 7)) return [3 /*break*/, 14];
                        this.listing.priorListingId = revise.id;
                        revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
                        this.listing.id = null;
                        this.listing.listingStatus = { id: 8 };
                        this.listing.listingType = revise.listingType;
                        listing_3 = tslib_1.__assign({}, revise, this.listing);
                        _j.label = 9;
                    case 9:
                        _j.trys.push([9, 12, , 13]);
                        _f = this;
                        return [4 /*yield*/, this.http.post("" + this.createUpdateListing, listing_3).toPromise()];
                    case 10:
                        _f.previewListing = _j.sent();
                        return [4 /*yield*/, this.copyPreviousPhotosFromListing(imgList, this.previewListing)];
                    case 11:
                        photos_3 = _j.sent();
                        this.previewListing.photos = photos_3;
                        return [2 /*return*/, this.previewListing];
                    case 12:
                        error_1 = _j.sent();
                        message = this.i18NService.get('global.reviseErrorMessage');
                        this.showMessage(null, message, false);
                        return [2 /*return*/, null];
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        if (revise.listingStatus.id === 7)
                            revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
                        _j.label = 15;
                    case 15:
                        this.listing.id = revise.id;
                        this.listing.listingStatus = { id: 7 };
                        this.listing.listingType = revise.listingType;
                        listing = tslib_1.__assign({}, revise, this.listing);
                        _g = this;
                        return [4 /*yield*/, this.http.put("" + this.createUpdateListing, listing).toPromise()];
                    case 16:
                        _g.previewListing = _j.sent();
                        photos = this.associatePhotosToListing(this.listing.id, imgList);
                        this.previewListing.photos = photos;
                        return [2 /*return*/, this.previewListing];
                    case 17: return [4 /*yield*/, this.listingDetailService.getListingPhotos(this.previewListing.id).toPromise()];
                    case 18:
                        photos_4 = _j.sent();
                        if (photos_4) {
                            Object.keys(photos_4).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var obj;
                                return tslib_1.__generator(this, function (_a) {
                                    obj = imgList.findIndex(function (v) {
                                        return v.id === photos_4[idx].id;
                                    });
                                    if (obj === -1) {
                                        this.http.delete("" + this.listingPhotos + photos_4[idx].id).toPromise();
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        if (previewListing.propertySubTypes) {
                            previewListing.propertySubTypes ? this.checkExistingCoworking(previewListing) : null;
                        }
                        this.listing.id = previewListing.id;
                        this.listing.listingStatus = previewListing.listingStatus;
                        listing = tslib_1.__assign({}, previewListing, this.listing);
                        if (previewListing.building) {
                            listing.building = previewListing.building;
                        }
                        _h = this;
                        return [4 /*yield*/, this.http.put("" + this.createUpdateListing, listing).toPromise()];
                    case 19:
                        _h.previewListing = _j.sent();
                        if (!imgList) return [3 /*break*/, 21];
                        return [4 /*yield*/, this.uploadImages(imgList, this.previewListing)];
                    case 20:
                        photos_5 = _j.sent();
                        this.previewListing.photos = photos_5;
                        this.previewPhotos = photos_5;
                        _j.label = 21;
                    case 21: return [2 /*return*/, this.previewListing];
                }
            });
        });
    };
    RegisterListingService.prototype.copyPreviousPhotosFromListing = function (currentPhotos, listingRevised) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var revisedPhotos;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        revisedPhotos = tslib_1.__spread(currentPhotos);
                        return [4 /*yield*/, this.copyPhotosToListing(revisedPhotos, listingRevised.id)];
                    case 1: 
                    //return await this.addPhotosToListing(revisedPhotos, listingRevised);
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RegisterListingService.prototype.copyPhotosToListing = function (photos, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .put("" + this.copyPhotosToListingAPI + listingId, photos)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    /**
     * Delete existing images
     */
    RegisterListingService.prototype.checkExistingPhotos = function (revise, imgList) {
        var _this = this;
        Object.keys(revise.listingPhotos).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var obj;
            return tslib_1.__generator(this, function (_a) {
                obj = imgList.findIndex(function (v) {
                    return v.id === revise.listingPhotos[key].id;
                });
                if (obj === -1) {
                    this.http.delete("" + this.listingPhotos + revise.listingPhotos[key].id).toPromise();
                }
                return [2 /*return*/];
            });
        }); });
    };
    RegisterListingService.prototype.checkExistingCoworking = function (revise) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var existing, current;
            return tslib_1.__generator(this, function (_a) {
                existing = revise.isForLeaseSale === ListingOfferType.Coworking;
                current = this.listing.isForLeaseSale === ListingOfferType.Coworking;
                if (existing && !current) {
                    this.listing.coworkingEntries = null;
                    this.listing.coworkingMaxPositions = null;
                }
                return [2 /*return*/];
            });
        });
    };
    RegisterListingService.prototype.addPhotosToListing = function (imgList, previewListing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var photos;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                photos = [];
                Object.keys(imgList).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var croppedImage, croppedImageId, listingPhoto, upload, img, croppedImage, _a, upload;
                    return tslib_1.__generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!imgList[key].image) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.createCroppedImage(imgList[key])];
                            case 1:
                                croppedImage = _b.sent();
                                croppedImageId = croppedImage ? croppedImage.id : null;
                                listingPhoto = {
                                    orderId: idx + 1,
                                    id: imgList[key].id,
                                    imageId: imgList[key].image.id,
                                    originalImage: imgList[key].originalImage,
                                    listingId: imgList[key].listing.id,
                                    createdDate: new Date(),
                                    croppedImageId: croppedImageId
                                };
                                return [4 /*yield*/, this.createListingPhoto(listingPhoto)];
                            case 2:
                                upload = _b.sent();
                                photos.push(upload);
                                return [3 /*break*/, 9];
                            case 3: return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgList[key].img, imgList[key].content)];
                            case 4:
                                img = _b.sent();
                                if (!imgList[key].croppedImage) return [3 /*break*/, 6];
                                return [4 /*yield*/, this.commonService.convertImageBase64ToFile(imgList[key].croppedImage, imgList[key].content)];
                            case 5:
                                _a = _b.sent();
                                return [3 /*break*/, 7];
                            case 6:
                                _a = null;
                                _b.label = 7;
                            case 7:
                                croppedImage = _a;
                                return [4 /*yield*/, this.uploadPhoto(img, imgList[key].caption, previewListing.id, idx + 1, imgList[key].content, croppedImage, imgList[key].originalImageId)];
                            case 8:
                                upload = _b.sent();
                                photos.push(upload);
                                _b.label = 9;
                            case 9: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/, photos];
            });
        });
    };
    RegisterListingService.prototype.createCroppedImage = function (originalImage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var croppedImage, croppedFile, caption;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        croppedImage = originalImage.croppedImage;
                        if (croppedImage == null) {
                            return [2 /*return*/, null];
                        }
                        if (!!croppedImage.id) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.commonService.convertImageBase64ToFile(croppedImage, originalImage.image.content)];
                    case 1:
                        croppedFile = _a.sent();
                        caption = originalImage.image.caption && originalImage.image.caption.length > 0
                            ? originalImage.image.caption
                            : originalImage.image.content;
                        return [4 /*yield*/, this.uploadNewImage(croppedFile, caption + "_cropped").then(function (createdImages) {
                                if (createdImages) {
                                    return createdImages[0];
                                }
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/, croppedImage];
                }
            });
        });
    };
    RegisterListingService.prototype.createListingPhoto = function (listingPhoto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.listingPhotos, listingPhoto)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    RegisterListingService.prototype.checkValues = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!form) {
                    return [2 /*return*/];
                }
                this.setLoadCapacity(form);
                this.setBaySize(form);
                this.setDockRatio(form);
                this.setCeilingHeight(form);
                this.setParkingSpaces(form);
                this.setTruckParkingSpaces(form);
                this.setCarParkingSpaces(form);
                return [2 /*return*/];
            });
        });
    };
    RegisterListingService.prototype.setLoadCapacity = function (form) {
        if (!form.loadCapacity || form.loadCapacity < 0) {
            this.listing.loadCapacity = null;
        }
        else {
            this.listing.loadCapacity = parseInt(form.loadCapacity);
        }
    };
    RegisterListingService.prototype.setBaySize = function (form) {
        if (!form.baySize || form.baySize < 0) {
            this.listing.baySize = null;
        }
        else {
            this.listing.baySize = parseFloat(this.langService.locale === 'en-US' ? form.baySize : form.baySize.replace(/\,/g, '.'));
        }
    };
    RegisterListingService.prototype.setDockRatio = function (form) {
        if (!form.dockRatio || form.dockRatio < 0) {
            this.listing.dockRatio = null;
        }
        else {
            this.listing.dockRatio = parseFloat(this.langService.locale === 'en-US' ? form.dockRatio : form.dockRatio.replace(/\,/g, '.'));
        }
    };
    RegisterListingService.prototype.setCeilingHeight = function (form) {
        if (!form.ceilingHeight || form.ceilingHeight < 0) {
            this.listing.ceilingHeight = null;
        }
        else {
            this.listing.ceilingHeight = parseFloat(this.langService.locale === 'en-US' ? form.ceilingHeight : form.ceilingHeight.replace(/\,/g, '.'));
        }
    };
    RegisterListingService.prototype.setParkingSpaces = function (form) {
        if (!form.parkingSpaces || form.parkingSpaces < 0) {
            this.listing.parkingSpaces = null;
        }
        else {
            this.listing.parkingSpaces = parseInt(form.parkingSpaces);
        }
    };
    RegisterListingService.prototype.setTruckParkingSpaces = function (form) {
        if (!form.truckParkingSpaces || form.truckParkingSpaces < 0) {
            this.listing.truckParkingSpaces = null;
        }
        else {
            this.listing.truckParkingSpaces = parseInt(form.truckParkingSpaces);
        }
    };
    RegisterListingService.prototype.setCarParkingSpaces = function (form) {
        if (!form.carParkingSpaces || form.carParkingSpaces < 0) {
            this.listing.carParkingSpaces = null;
        }
        else {
            this.listing.carParkingSpaces = parseInt(form.carParkingSpaces);
        }
    };
    RegisterListingService.prototype.showMessage = function (header, message, keepUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var okText, alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        okText = this.i18NService.get('global.OK');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: header,
                                message: message,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterListingService.prototype.isUserAllowToEditPendingApprovalListing = function (pListing) {
        if (!pListing) {
            return false;
        }
        return this.checkRole('ROLE_SPOT_ADMIN') && pListing.listingStatus.id === 1;
    };
    RegisterListingService.prototype.editActiveRevisionListing = function (pListing) {
        if (!pListing) {
            return false;
        }
        return (this.checkRole('ROLE_SPOT_ADMIN') &&
            pListing.listingStatus.id === 4 &&
            pListing.nextListingId &&
            pListing.nextListingStatus &&
            pListing.nextListingStatus.id === 8);
    };
    return RegisterListingService;
}());
export { RegisterListingService };
