import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CoworkingPopoverComponent } from '@app/search-navigation/components/coworking-popover';
import { RenewExtendModalComponent } from '@app/user-home/my-listing/renew-extend-modal/renew-extend-modal.component';
import { MoreFiltersSpotbuildingsComponent } from '@app/spot-buildings/components/search/more-filters/more-filters-spotbuildings.component';
import { SpotBuildingSearchRequest } from '../../models/spot-buildings/spot-building-search.model';
import { SpacesParameters } from '../../models/spot-buildings/spot-available-spaces';
import { SpaceDetailsComponent } from '../../spot-buildings/components/detail/space-details/space-details.component';
import { SpotBuildingDetailDTO, SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { RequestInformationComponent } from '../../spot-buildings/components/request-information/request-information.component';
import { SpotImageDTO } from '../../models/spot-buildings/spot-image.model';
import { imageUploadSpacesBuilding } from '@app/spot-buildings/components/create/spaces-buildings/image-upload-spaces-building/image-upload-spaces-building';
import { RequestInfoInput } from '@app/models/request-information.model';
import { AgentDTO } from '../../models/spot-buildings/agent-broker.model';
import { VideoComponent } from '../../shared/video/video.component';
import { StreamingMedia, StreamingVideoOptions } from '@awesome-cordova-plugins/streaming-media/ngx';
import { ImageGalleryComponent } from '@app/shared/image-gallery/image-gallery.component';
import { SocialMediaInput } from '../../models/social-media.model';
import { SocialMediaComponent } from '../../shared/social-media/social-media.component';

@Injectable()
export class PopoverCtrlHelper {
  constructor(private popoverCtrl: PopoverController, private streamingMedia: StreamingMedia) {}

  async createMorePopoverBuildingListing(
    ev: Event,
    checkBoxValues: any,
    searchModel: any,
    searchRequest: SpotBuildingSearchRequest
  ) {
    return this.popoverCtrl.create({
      component: MoreFiltersSpotbuildingsComponent,
      event: ev,
      cssClass: 'popover-type-search-filters',
      componentProps: {
        propType: searchModel.propertyType,
        searchDto: searchRequest,
        checkboxValues: checkBoxValues
      },
      backdropDismiss: true
    });
  }

  async openMorePopoverForBuildingListing(
    ev: Event,
    checkBoxValues: any,
    searchModel: any,
    listingSearchDto: any,
    callback?: any
  ) {
    const popover = await this.createMorePopoverBuildingListing(ev, checkBoxValues, searchModel, listingSearchDto);

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  async openCoworkingPopover(ev: Event, listingSearchDto: any, callback?: any) {
    const popover = await this.popoverCtrl.create({
      component: CoworkingPopoverComponent,
      event: ev,
      cssClass: 'coworking-popover',
      componentProps: { searchDto: listingSearchDto },
      backdropDismiss: false
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        listingSearchDto = popoverData.data;
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  async openRenewExtendListingsModal(ev: any, listingIds: any, callback: any) {
    const popover = await this.popoverCtrl.create({
      component: RenewExtendModalComponent,
      event: ev,
      cssClass: 'popover-renew-extend',
      componentProps: { listingIds: listingIds },
      backdropDismiss: false
    });

    popover.onDidDismiss().then((popoverData: any) => {
      if (popoverData.data) {
        callback(popoverData.data);
      }
    });
    return await popover.present();
  }

  async openSpacesDetailPopover(ev: Event, space: SpacesParameters, spotBuilding: SpotBuildingDTO, callback?: any) {
    const popover = await this.popoverCtrl.create({
      component: SpaceDetailsComponent,
      event: ev,
      cssClass: 'space-detail-popover',
      componentProps: { space: space, spot: spotBuilding },
      backdropDismiss: true
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
        if (popoverData.data.requestInfo) {
          const space: SpacesParameters = popoverData.data.space;
          const spot: SpotBuildingDTO = popoverData.data.spot;
          const spotTitle = popoverData.data.spotTitle;
          const requestType = popoverData.data.requestType;

          const agents: AgentDTO[] = [];
          if (space.agentDTO) {
            agents.push(space.agentDTO);
            if (space.secondAgentDTO) {
              agents.push(space.secondAgentDTO);
            }
          }

          let requestInfoInput: RequestInfoInput = {
            requestTitle: spotTitle,
            companyLogoImageId: spot.companyLogoImageId,
            agents: agents,
            spotId: spot.id,
            requestType: requestType
          };

          this.openRequestInformationPopup(popoverData.data.event, requestInfoInput);
        }
      }
    });
    return await popover.present();
  }

  async openRequestInformationPopup(ev: Event, reqInfoInput: RequestInfoInput, callback?: any) {
    const popover = await this.popoverCtrl.create({
      component: RequestInformationComponent,
      event: ev,
      cssClass: 'request-info-popover',
      componentProps: { reqInfoInput: reqInfoInput },
      backdropDismiss: true
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  async openGalleryImageComponent(
    ev: Event,
    photos: SpotImageDTO[],
    spotTitle: string,
    listingType?: string,
    description?: string,
    topImage?: string,
    callback?: any
  ) {
    const popover = await this.popoverCtrl.create({
      component: ImageGalleryComponent,
      event: ev,
      cssClass: 'gallery-img-popover',
      componentProps: {
        photos: photos,
        fromPopup: true,
        spotTitle: spotTitle,
        listingType: listingType,
        description: description,
        topImage: topImage
      },
      backdropDismiss: true
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  async openSocialMediaPopup(ev: Event, socialMediaInput: SocialMediaInput, callback?: any) {
    const popover = await this.popoverCtrl.create({
      component: SocialMediaComponent,
      event: ev,
      cssClass: 'social-media-popover',
      componentProps: { socialMediaInput: socialMediaInput },
      backdropDismiss: true
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  async openVideoTourPopup(ev: Event, videoURL: string, spotTitle: string, isMobile: boolean, callback?: any) {
    /* if (false && isMobile) {
      if (videoURL) {
        let options: StreamingVideoOptions = {
          successCallback: () => {
            console.log('Video played');
          },
          errorCallback: (e: any) => {
            console.log('Error streaming', +e);
            callback(e);
          },
          orientation: 'landscape',
          shouldAutoClose: false,
          controls: true
        };

        try {
          this.streamingMedia.playVideo(videoURL, options);
        } catch (err) {
          console.error(err);
          if (callback) {
            callback(err);
          }
        }
      }
      return;
    } */

    const popover = await this.popoverCtrl.create({
      component: VideoComponent,
      event: ev,
      cssClass: 'video-player-popup',
      componentProps: { videoURL: videoURL, fromPopup: true, spotTitle: spotTitle },
      backdropDismiss: true
    });

    popover.onDidDismiss().then(popoverData => {
      if (popoverData.data) {
        if (callback) {
          callback(popoverData.data);
        }
      }
    });
    return await popover.present();
  }

  async openImageUploadSpaceBuilding(spaceId: any, callback?: any) {
    const popover = await this.popoverCtrl.create({
      component: imageUploadSpacesBuilding,
      cssClass: 'popover-type-Space',
      componentProps: { spaceId: spaceId },
      backdropDismiss: false
    });

    popover.onDidDismiss().then(popoverData => {
      callback(true);
    });
    return await popover.present();
  }
}
