import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';

@Component({
  selector: 'app-renew-extend-modal',
  templateUrl: './renew-extend-modal.component.html',
  styleUrls: ['./renew-extend-modal.component.scss']
})
export class RenewExtendModalComponent implements OnInit {
  constructor(
    private navParams: NavParams,
    private i18NService: I18nService,
    private popoverCtrl: PopoverController,
    private listingService: ListingService
  ) {}

  listingIds: any;
  newExpirationDate: Date;
  renewExtendDescription: string;

  async ngOnInit() {
    this.listingIds = this.navParams.data.listingIds;
    let interpolateParams = { totalListings: this.listingIds ? this.listingIds.length : 0 };
    await this.listingService.countActiveAndExpiredListings(this.listingIds).then((response: any) => {
      if (response) {
        interpolateParams.totalListings = response.body.totalActiveExpiredListings;
      }
    });

    this.renewExtendDescription = this.i18NService.getMetatagTranslation(
      'renewExtendListings.description',
      interpolateParams
    );
  }

  checkDate(ev: any) {}

  closeModal(updated: boolean) {
    this.popoverCtrl.dismiss({ updated: updated });
  }

  formatDate(date: Date) {
    var d = date,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  updateNewExpirationDate() {
    if (this.newExpirationDate && this.listingIds) {
      this.listingService.setNewExpirationDateFor(this.listingIds, this.formatDate(this.newExpirationDate));
      this.closeModal(true);
    }
  }
}
