import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TurnOffNotificationService } from '@app/spot-buildings/services/turnoff-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Location, isPlatformBrowser } from '@angular/common';
import { I18nService } from '../../../core/i18n.service';

@Component({
  selector: 'app-turnoff-notifications',
  templateUrl: './turnoff-notifications.component.html',
  styleUrls: ['./turnoff-notifications.component.scss']
})
export class TurnoffNotificationsComponent implements OnInit {
  userLogin: string;
  notificationType: string;
  readyToUnsubscribe: boolean = false;
  turnOffBadRequestTxt: string;

  validationCode = 0;
  constructor(
    private i18n: I18nService,
    private turnOffService: TurnOffNotificationService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.turnOffBadRequestTxt = this.i18n.get('notifications.turnoffBadRequest');
    const params = this.activatedRoute.snapshot.params;
    const hash = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams['hash'] : null;
    const lang = params['language'];

    if (params && params['login'] && params['notificationType'] && hash && lang) {
      this.userLogin = params['login'];
      this.notificationType = params['notificationType'];
      this.location.go('/turn-off/notification/' + this.notificationType);

      this.turnOffService
        .turnOffNotification(this.userLogin, this.notificationType, hash)
        .then((result: any) => {
          this.readyToUnsubscribe = result;
        })
        .catch((error: HttpErrorResponse) => {
          if (error.status == 410) {
            this.validationCode = parseFloat(error.headers.get('validationCode'));
          }
        });
    } else {
      this.location.go('/turn-off/notification/invalid');
    }
  }
}
