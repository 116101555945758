/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-images.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "ng-lazyload-image";
import * as i5 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./preview-images.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../core/image.service";
var styles_PreviewImagesComponent = [i0.styles];
var RenderType_PreviewImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewImagesComponent, data: {} });
export { RenderType_PreviewImagesComponent as RenderType_PreviewImagesComponent };
function View_PreviewImagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-slide", [], null, null, null, i2.View_IonSlide_0, i2.RenderType_IonSlide)), i1.ɵdid(1, 49152, null, 0, i3.IonSlide, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "img", [["alt", "listing"], ["class", "img-listing-card"]], null, null, null, null, null)), i1.ɵdid(4, 1720320, null, 0, i4.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, [2, "options"]], { lazyImage: [0, "lazyImage"], defaultImage: [1, "defaultImage"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit && _v.context.$implicit.image) ? _co.getMediumImg(_co.getCroppedOrOriginalImage(_v.context.$implicit)) : _v.context.$implicit); var currVal_1 = _co.defaultImage; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_PreviewImagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { photoSlider: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "col-md-12"], ["style", "text-align: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["style", "float: left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", "/", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [["class", "closePreview"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "fa-icon", [["class", "img-icon ng-fa-icon"], ["size", "lg"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(9, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(13, 0, null, null, 20, "div", [["class", "col-md-12 images"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "ion-slides", [["id", "photoSlider"], ["pager", "true"], ["style", "margin: 0px -30px 0px -78px;"]], null, null, null, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(16, 49152, [[1, 4], ["photoSlider", 4]], 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { options: [0, "options"], pager: [1, "pager"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PreviewImagesComponent_1)), i1.ɵdid(19, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(22, 0, null, null, 4, "button", [["class", "prev"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getPreviousPhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "fa-icon", [["class", "img-icon ng-fa-icon"], ["size", "lg"], ["style", "color: white;"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(25, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "button", [["class", "next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getNextPhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "fa-icon", [["class", "img-icon ng-fa-icon"], ["size", "lg"], ["style", "color: white;"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(31, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.faTimes; var currVal_5 = "lg"; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = _co.slideOptions; var currVal_7 = "true"; _ck(_v, 16, 0, currVal_6, currVal_7); var currVal_8 = _co.photos; _ck(_v, 19, 0, currVal_8); var currVal_11 = _co.faChevronLeft; var currVal_12 = "lg"; _ck(_v, 25, 0, currVal_11, currVal_12); var currVal_15 = _co.faChevronRight; var currVal_16 = "lg"; _ck(_v, 31, 0, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pag + 1); var currVal_1 = _co.photos.length; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).title; var currVal_3 = i1.ɵnov(_v, 9).renderedIconHTML; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_9 = i1.ɵnov(_v, 25).title; var currVal_10 = i1.ɵnov(_v, 25).renderedIconHTML; _ck(_v, 24, 0, currVal_9, currVal_10); var currVal_13 = i1.ɵnov(_v, 31).title; var currVal_14 = i1.ɵnov(_v, 31).renderedIconHTML; _ck(_v, 30, 0, currVal_13, currVal_14); }); }
export function View_PreviewImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-images", [], null, null, null, View_PreviewImagesComponent_0, RenderType_PreviewImagesComponent)), i1.ɵdid(1, 114688, null, 0, i9.PreviewImagesComponent, [i10.MatDialogRef, i10.MatDialog, i11.ImageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewImagesComponentNgFactory = i1.ɵccf("app-preview-images", i9.PreviewImagesComponent, View_PreviewImagesComponent_Host_0, {}, {}, []);
export { PreviewImagesComponentNgFactory as PreviewImagesComponentNgFactory };
