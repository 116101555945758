import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { SpotSetBody, SpotSetList } from '@app/models/spot-set-list.model';
import { ReportSpacesComponent } from '@app/spot-buildings/components/detail/report-spaces/report-spaces.component';
import { SetSpacesComponent } from '@app/spot-buildings/components/detail/set-spaces/set-spaces.component';
import {
  faTrash,
  faEllipsisV,
  faEdit,
  faBookOpen,
  faSearch,
  faFilter,
  faArrowCircleDown
} from '@fortawesome/free-solid-svg-icons';
import { ToastController } from '@ionic/angular';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-spot-sets',
  templateUrl: './spot-sets.component.html',
  styleUrls: ['./spot-sets.component.scss']
})
export class SpotSetsComponent implements OnInit {
  @Output() eventSpotSet = new EventEmitter<number>();
  trash: any;
  ellipsisV: any;
  edit: any;
  bookOpen: any;
  search: any;
  filter: any;
  arrowDown: any;

  pageSize = 10;
  page = 0;
  collection = 0;
  filterSpotSet: string;
  spotSetList: SpotSetList;
  seeSearch = false;
  param: string;
  direction: string;
  isMobile: boolean = false;
  filterCount = 0;

  constructor(
    private favoriteServices: FavoriteSpotService,
    public i18nService: I18nService,
    private dialog: MatDialog,
    private toastCtrl: ToastController,
    private messageFormDialog: MessageModalService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private routingHelperSrv: RoutingHelperService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.trash = faTrash;
    this.ellipsisV = faEllipsisV;
    this.edit = faEdit;
    this.bookOpen = faBookOpen;
    this.search = faSearch;
    this.filter = faFilter;
    this.arrowDown = faArrowCircleDown;
    this.getSpotSets(0);
  }

  getSpotSets(page: number) {
    this.page = page;
    const lang = this.i18nService.getCurrentLanguage();
    const bodySpotSet: SpotSetBody = {
      sortBy: this.param,
      direction: this.direction,
      titleFilter: this.filterSpotSet
    };
    this.favoriteServices.getSpotSetFavorite(page, lang, bodySpotSet).then((value: SpotSetList) => {
      this.spotSetList = value;
      this.collection = this.spotSetList.totalElements;
      this.pageSize = this.spotSetList.size;
      this.eventSpotSet.emit(this.spotSetList.totalElements);
    });
  }

  changePage(page: number) {
    this.getSpotSets(page - 1);
  }

  searchProperty() {
    this.seeSearch = !this.seeSearch;
  }

  searchFilter() {
    if (this.filterCount == 0) {
      this.filterCount = 1;
      setTimeout(() => {
        this.getSpotSets(0);
        this.filterCount = 0;
      }, 2000);
    }
  }

  editSet(item: any) {
    let dialogRef = this.dialog.open(SetSpacesComponent, {
      width: this.isMobile ? '100%' : '600px',
      maxWidth: this.isMobile ? '100%' : '',
      height: this.isMobile ? '100%' : '',
      data: {
        title: true,
        id: item.id,
        name: item.name
      }
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.getSpotSets(0);
        this.presentToast(val);
      }
    });
  }

  createTourBook(item: any) {
    let dialogRef = this.dialog.open(ReportSpacesComponent, {
      width: this.isMobile ? '100%' : '60%',
      maxWidth: this.isMobile ? '100%' : '',
      height: this.isMobile ? '100%' : '',
      data: {
        ids: item.spaceIds,
        name: item.name
      }
    });
  }

  deleteSet(id: number) {
    let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '500px',
      data: {
        message: this.i18nService.get('favorites.spotSets.deleteSet'),
        id: id,
        apiName: '/api/spotset/'
      },
      autoFocus: true,
      restoreFocus: true
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.presentToast(this.i18nService.get('favorites.deleteMessage'));
        this.getSpotSets(0);
      }
    });
  }

  nameAscending() {
    this.param = 'name';
    this.direction = 'ASC';

    this.getSpotSets(0);
  }

  nameDescending() {
    this.param = 'name';
    this.direction = 'DESC';

    this.getSpotSets(0);
  }

  oldestFirst() {
    this.param = 'date';
    this.direction = 'ASC';
    this.getSpotSets(0);
  }

  newestFirst() {
    this.param = 'date';
    this.direction = 'DESC';
    this.getSpotSets(0);
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3500,
      animated: true,
      keyboardClose: true,
      cssClass: 'toast-alert-detail'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  propertiesText(message: string) {
    this.messageFormDialog.openDialogMessage(message);
  }

  goBrowseProperties() {
    this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
  }

  goToSpotSetSearch(item: any) {
    this.routingHelperSrv.navigateToSpotSetSearchPage(item);
  }
}
