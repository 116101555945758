import { Injectable, EventEmitter } from '@angular/core';
import { GeoLocationService } from '@app/core';
import { sep } from 'path';

@Injectable()
export class MapControlHelperService {
  constructor() {}

  ZoomControls(map: google.maps.Map) {
    const controlWrapper = document.createElement('div');
    controlWrapper.style.cursor = 'pointer';
    controlWrapper.style.textAlign = 'center';
    controlWrapper.style.width = '28px';
    controlWrapper.style.top = '10px !important';
    controlWrapper.style.display = 'flex column';
    controlWrapper.style.right = '10px';
    controlWrapper.style.height = '53px';
    controlWrapper.style.background = '#FFFFFF';
    controlWrapper.style.boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.2)';
    controlWrapper.style.borderRadius = '2px';
    controlWrapper.classList.add('zoom-map-container');

    const zoomInButton = document.createElement('div');
    zoomInButton.style.width = '100%';
    zoomInButton.style.height = '25px';
    zoomInButton.style.padding = '5px';
    /* Change this to be the .png image you want to use */
    zoomInButton.style.backgroundImage = 'url("assets/maps/zoom-in.png")';

    zoomInButton.style.backgroundPosition = 'center';
    zoomInButton.style.backgroundRepeat = 'no-repeat';
    controlWrapper.appendChild(zoomInButton);

    //Separator
    let separator = document.createElement('div');
    separator.classList.add('zoom-map-separator');
    controlWrapper.appendChild(separator);

    // Set CSS for the zoomOut
    const zoomOutButton = document.createElement('div');
    zoomOutButton.style.width = '100%';
    zoomOutButton.style.height = '25px';
    zoomOutButton.style.padding = '5px';
    /* Change this to be the .png image you want to use */
    // zoomOutButton.innerHTML = 'Zoom Out';
    zoomOutButton.style.backgroundImage = 'url("assets/maps/zoom-out.png")';
    zoomOutButton.style.backgroundPosition = 'center';
    zoomOutButton.style.backgroundRepeat = 'no-repeat';
    controlWrapper.appendChild(zoomOutButton);

    // Setup the click event listener - zoomIn
    google.maps.event.addDomListener(zoomInButton, 'click', function() {
      map.setZoom(map.getZoom() + 1);
    });

    // Setup the click event listener - zoomOut
    google.maps.event.addDomListener(zoomOutButton, 'click', function() {
      map.setZoom(map.getZoom() - 1);
    });

    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlWrapper);
  }
}
