import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { UserActivityType } from '../../models/user-activity.model';
var UserActivityService = /** @class */ (function () {
    function UserActivityService(http) {
        this.http = http;
        this.endpoint = '/api/user-activities/';
        this.listingsSearchEndpoint = '/api/user-activities/listings-search';
    }
    UserActivityService.prototype.saveUserActivity = function (userActivity) {
        try {
            userActivity.application = 'SPOT';
            return this.http.post(this.endpoint, userActivity).toPromise();
        }
        catch (error) {
            console.error(error);
        }
    };
    UserActivityService.prototype.trackListingShare = function (listingId) {
        if (listingId) {
            var userActivity = {
                module: UserActivityType.LISTING_SHARE,
                listingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackListingsSearch = function (listingSearchDTO, searchTagsDTO) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listingSearchTrackingDTO, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        listingSearchTrackingDTO = {
                            searchDTO: listingSearchDTO,
                            searchTagsDTO: searchTagsDTO
                        };
                        return [4 /*yield*/, this.http.post(this.listingsSearchEndpoint, listingSearchTrackingDTO).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserActivityService.prototype.trackVisitedPages = function (userActivityType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userActivity;
            return tslib_1.__generator(this, function (_a) {
                userActivity = {
                    module: userActivityType
                };
                this.saveUserActivity(userActivity);
                return [2 /*return*/];
            });
        });
    };
    UserActivityService.prototype.trackSpotBuildingShare = function (listingId) {
        if (listingId) {
            var userActivity = {
                module: UserActivityType.LISTING_SHARE,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackCompanyShare = function (companyId) {
        if (companyId) {
            var userActivity = {
                module: UserActivityType.COMPANY_SHARE,
                companyId: companyId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackSpotBuildingRequestVisit = function (listingId) {
        if (listingId) {
            var userActivity = {
                module: UserActivityType.LISTING_REQUEST_VISIT,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackSpotBuildingMessage = function (listingId) {
        if (listingId) {
            var userActivity = {
                module: UserActivityType.LISTING_MESSAGE,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackSpotBuildingVirtualTour = function (listingId) {
        if (listingId) {
            var userActivity = {
                module: UserActivityType.LISTING_VIRTUAL_TOUR,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackSpotBuildingFavorite = function (listingId) {
        if (listingId) {
            var userActivity = {
                module: UserActivityType.LISTING_FAVORITE,
                buildingListingId: listingId
            };
            this.saveUserActivity(userActivity);
        }
    };
    UserActivityService.prototype.trackInstallApp = function () {
        var userActivity = {
            module: UserActivityType.INSTALL_APP
        };
        this.saveUserActivity(userActivity);
    };
    UserActivityService.prototype.trackBrokerShare = function (brokerId) {
        if (brokerId) {
            var userActivity = {
                module: UserActivityType.BROKER_SHARE,
                brokerId: brokerId
            };
            this.saveUserActivity(userActivity);
        }
    };
    return UserActivityService;
}());
export { UserActivityService };
