import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { SendNotification } from '@app/models/send-notification.model';
import { not } from '@angular/compiler/src/output/output_ast';
import { NotificationRestrictionPayload } from '../../models/notifications/notification-restrictions-payload.model';
import { I18nService } from '@app/core';

@Injectable()
export class NotificationService {
  incomingNotificationsEvent = new EventEmitter<any>();

  apiNotification = 'api/notification_activity/';
  restrictionsAPI = 'api/notification_activity/restrictions/';

  constructor(private http: HttpClient, private i18nService: I18nService) {}

  getNotifications(page: number, lang: string, body: any) {
    return this.http.post(`${this.apiNotification}search?page=${page}&per_page=10&lang=${lang}`, body).toPromise();
  }

  sendNotification(notify: SendNotification) {
    return this.http.post('/api/buildingchangesnotification/', notify).toPromise();
  }

  hideNotification(id: number) {
    return this.http.delete(`${this.apiNotification}${id}`).toPromise();
  }

  turnOffNotification(turnOff: any) {
    return this.http.patch('/api/user', turnOff).toPromise();
  }

  readNotification(id: number) {
    return this.http.patch(`${this.apiNotification}${id}`, { read: true }).toPromise();
  }

  getNotificationRestrictions(): any {
    return this.http.get(`${this.restrictionsAPI}`).toPromise();
  }

  saveNotificationRestrictions(payload: NotificationRestrictionPayload) {
    return this.http.post(`${this.restrictionsAPI}`, payload).toPromise();
  }

  predefinedNotification() {
    return this.http.get('/api/predefined_notifications/').toPromise();
  }

  notificationDate(date: string) {
    let numberDays = 0;
    let currentDate = new Date();
    let date2 = new Date(date);
    let days = Math.round((currentDate.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));

    if (days < 7) {
      numberDays = days;
      if (numberDays < 1) {
        return this.i18nService.get('notifications.today');
      } else {
        return (
          numberDays +
          ' ' +
          (numberDays == 1
            ? this.i18nService.get('notifications.dayAgo')
            : this.i18nService.get('notifications.daysAgo'))
        );
      }
    }

    if (days >= 7 && days <= 30) {
      numberDays = this.calculateDays(days, 7);
      if (numberDays == 1) {
        return numberDays + ' ' + this.i18nService.get('notifications.weekAgo');
      } else {
        return numberDays + ' ' + this.i18nService.get('notifications.weeksAgo');
      }
    }

    if (days > 30 && days < 365) {
      numberDays = this.calculateDays(days, 30);
      if (numberDays == 1) {
        return numberDays + ' ' + this.i18nService.get('notifications.monthAgo');
      } else {
        return numberDays + ' ' + this.i18nService.get('notifications.monthsAgo');
      }
    } else {
      return date;
    }
  }

  calculateDays(days: number, val: number) {
    return Math.round(days / val);
  }
}
