import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ListingSearchComponent } from '@app/search-navigation/pages/listing-search/listing-search.component';
import { RegisterFormComponent } from '@app/shared/register';
import { ForgotFormComponent } from '@app/shared/forgot-password';
import { BackwardGuard, BackStepperGuard, AuthGuard } from '@app/guards';
import { ListUserComponent } from '@app/search-navigation/pages/listing-user';
import { ChangePasswordComponent } from '@app/shared/change-password';
import { PrivacyPolicyComponent } from '@app/shared/privacy-policy/privacy-policy.component';
import { TermsComponent } from '@app/shared/terms/terms.component';
import { DownloadComponent } from '@app/shared/download';
import { MoreNotificationsComponent } from '@app/shared/notifications/more-notifications/more-notifications.component';
import { SettingsNotificationComponent } from '@app/shared/settings-notification/settings-notification.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
      path: 'register',
      component: RegisterFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'register/:language',
      component: RegisterFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'forgot-password',
      component: ForgotFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Forgot Password')
      }
    },
    {
      path: 'forgot-password/:language',
      component: ForgotFormComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Forgot Password')
      }
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Change Password')
      }
    },
    {
      path: 'change-password/:language',
      component: ChangePasswordComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Change Password')
      }
    },
    {
      path: 'listing-user/:id',
      component: ListUserComponent,
      data: { title: extract('Listing User'), skipParamCheck: true }
    },
    {
      path: 'listing-user/:id/:language',
      component: ListUserComponent,
      data: { title: extract('Listing User'), skipParamCheck: true }
    },
    {
      path: 'privacy-policy',
      component: PrivacyPolicyComponent,
      data: { title: extract('Privacy Policy') }
    },
    {
      path: `${AppRouteNames.PRIVACY_POLICY_ROUTE}/:language`,
      component: PrivacyPolicyComponent,
      data: { title: extract('Privacy Policy') }
    },
    {
      path: `${AppRouteNames.PRIVACY_POLICY_ROUTE_CL}/:language`,
      component: PrivacyPolicyComponent,
      data: { title: extract('Privacy Policy') }
    },
    {
      path: `${AppRouteNames.TERMS_ROUTE}/:language`,
      component: TermsComponent,
      data: { title: extract('Terms Of Use') }
    },
    {
      path: `${AppRouteNames.TERMS_ROUTE_CL}/:language`,
      component: TermsComponent,
      data: { title: extract('Terms Of Use') }
    },
    {
      path: 'download',
      component: DownloadComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'download/:language',
      component: DownloadComponent,
      canDeactivate: [BackStepperGuard],
      data: {
        title: extract('Register New User')
      }
    },
    {
      path: 'notifications',
      component: MoreNotificationsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('User Notifications')
      }
    },
    {
      path: 'notifications/:language',
      component: MoreNotificationsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('User Notifications')
      }
    },
    {
      path: 'settings',
      component: SettingsNotificationComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('settings')
      }
    },
    {
      path: 'settings/:language',
      component: SettingsNotificationComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('settings')
      }
    }
  ])
];

//Pass child routes to Shell for reuse

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SearchNavigationRoutingModule {}
