import { createAction, props } from '@ngrx/store';

export enum CartActionTypes {
  CreateCart = '[Cart] Create Cart',
  GetCart = '[Cart] Get Cart',
  SaveCart = '[Cart] Save Cart',
  UpdateCart = '[Cart] Update Cart',
  GetItem = '[Cart] Get Item',
  AddItem = '[Cart] Add Item',
  RemoveItem = '[Cart] Remove Item',
  UpdateItem = '[Cart] Update Item'
}

export const CreateCart = createAction('[Cart] Create Cart');
export const GetCart = createAction('[Cart] Get Cart');
export const SaveCart = createAction('[Cart] Update Cart', props<{ payload: any }>());
export const DeleteCart = createAction('[Cart] Delete Cart', props<{ payload: any }>());
export const UpdateCart = createAction('[Cart] Update Cart', props<{ payload: any }>());
export const GetItem = createAction('[Cart] Get Item', props<{ payload: any }>());
export const AddItem = createAction('[Cart] Add Item', props<{ payload: any }>());
export const RemoveItem = createAction('[Cart] Remove Item', props<{ payload: any }>());
export const UpdateItem = createAction('[Cart] Update Item', props<{ payload: any }>());
export const AddSelection = createAction('[Cart] Add Selection', props<{ payload: any }>());
export const ClearCart = createAction('[Cart] Clear Cart', props<{ payload: any }>());
export const ClearSelection = createAction('[Cart] Clear Selection', props<{ payload: any }>());
