import { Injectable } from '@angular/core';
import { DetailPosition } from '@app/models';

@Injectable()
export class DetailPositionService {
  list: any[];

  constructor() {}

  get getDetailPosition() {
    return this.getCache() || this.list;
  }

  setPositionData(list: any) {
    this.list = list;
    this.cache();
  }

  getCache() {
    try {
      if (localStorage) {
        const position = localStorage.getItem('detail-position');
        return JSON.parse(position);
      }
    } catch (e) {
      console.error(e);
    }
  }

  delCache() {
    try {
      if (localStorage) localStorage.removeItem('detail-position');
    } catch (e) {
      console.error(e);
    }
  }

  cache() {
    localStorage.setItem('detail-position', JSON.stringify(this.list));
  }
}
