import { BuildingTypeDTO } from './buidingType.model';
import { BuildingSubtypeDTO } from './buildingSubtype.model';

export class BuildingDTO {
  id: number;
  address: string;
  buildingSubType: BuildingSubtypeDTO;
  buildingType: BuildingTypeDTO;
  rentableArea: number;
  status: string;
}
