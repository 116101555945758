import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonGenericService } from '@app/core/common.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-multiple-files-progress-bar',
  templateUrl: './multiple-files-progress-bar.component.html',
  styleUrls: ['./multiple-files-progress-bar.component.scss']
})
export class MultipleFilesProgressBarComponent implements OnInit {
  @Input() allImgFiles: any;
  @Input() imgList: any;
  faSuccess: any;

  imgFiles: any;

  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
    private commonService: CommonGenericService
  ) {}

  ngOnInit() {
    this.faSuccess = faCheckCircle;
    this.imgFiles = [...this.allImgFiles];
  }

  public acceptModal() {
    this.closeModal({});
  }

  public closeModal(data: any) {
    this.modalCtrl.dismiss(data);
  }

  public areImagesUploaded() {
    const countImgs = this.imgFiles.length;
    const uploadedImgs = this.allImgFiles.filter((img: any) => img.uploaded == true);
    if (uploadedImgs) {
      return uploadedImgs.length == countImgs;
    }
    return false;
  }
}
