import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BuildingLocation } from '@app/shared/maps/map/map.component';

@Injectable()
export class EventCheckerService {
  public typeBuildings$ = new BehaviorSubject<any>({});

  public updateListing$ = new BehaviorSubject<any>(null);

  public removeListing$ = new BehaviorSubject<any>(null);

  public paUpdate$ = new BehaviorSubject<any>(null);

  public updateOfficeFilters$ = new BehaviorSubject<any>(null);

  public updateIndustrialFilters$ = new BehaviorSubject<any>(null);

  public updateBuildingLocation$ = new BehaviorSubject<BuildingLocation>(null);

  constructor() {}
}
