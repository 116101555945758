import { Injectable } from '@angular/core';
import { BreakPointsType, SliderOptionsType } from '@app/models/shared/lazy-slider.model';

@Injectable()
export class LazySliderService {
  constructor() {}

  defaultSliderOpts: SliderOptionsType = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    freeModeMomentum: true,
    initialSlide: 0,
    direction: 'horizontal',
    height: '200px',
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slideVisibleClass: 'swiper-slide-visible',
    groupSliding: true,
    breakpoints: {
      1: {
        slidesPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 10
      },
      900: {
        slidesPerGroup: 2,
        slidesPerView: 2,
        spaceBetween: 20
      },
      1300: {
        slidesPerGroup: 3,
        slidesPerView: 3,
        spaceBetween: 35
      },
      1700: {
        slidesPerGroup: 4,
        slidesPerView: 4,
        spaceBetween: 40
      }
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      clickable: false,
      lockClass: 'swiper-pagination-lock',
      horizontalClass: 'lazy-slider',
      renderCustom: (_swiper: any, current: number, _total: any) => {
        let dots = '';
        for (let i: number = 1; i <= _total; i++) {
          dots += `<span class="swiper-pagination-bullet dot ${
            current === i ? 'active swiper-pagination-bullet-active' : ''
          }"></span>`;
        }
        return dots;
      }
    }
  };

  getBreakPointProps(screenSize: number, breakpoints: BreakPointsType): BreakPointsType[keyof BreakPointsType] {
    const resolutions = Object.keys(breakpoints)
      .map(r => Number(r))
      .sort((a, b) => (a < b ? a : b));

    for (let i: number = 0; i < resolutions.length; i++) {
      const [lower, upper] = resolutions.slice(i, i + 2);
      if (screenSize < lower || i === resolutions.length - 1 || (screenSize > +lower && screenSize < +upper)) {
        return breakpoints[lower];
      }
    }
  }
}
