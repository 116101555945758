import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material';
import { InstallPromptComponent, ChromeInstallComponent } from '@app/shared/install-prompt-dialog';
import { CookieService } from 'ngx-cookie-service';
import { PlatformHelperService } from './helpers';

@Injectable()
export class InstallPromptDialogService {
  dialogRef: any;
  expireTime: number = 90;
  safariBrowserDetected: boolean = false;

  /**
   * @param {MatDialog} _d
   * @param {CookieService} _cookies
   * @param {NGXLogger} _logger
   */
  constructor(
    public _d: MatDialog,
    private _cookies: CookieService,
    private platformHelperService: PlatformHelperService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  /**
   * @param {*} [data]
   */
  openDialog(data?: any, show?: boolean): void {
    if ((this.showInstallPrompt() && this.cookieExpired()) || show) {
      const dialogRef = this._d.open(InstallPromptComponent, {
        height: '580px',
        width: '500px',
        data: data || { title: 'SiiLA SPOT', icon_path: 'assets/images/ios-add-to-homescreen.gif' }
      });

      dialogRef.afterClosed().subscribe(result => {
        let timestamp = new Date();
        timestamp.setDate(timestamp.getDate() + this.expireTime);
        this._cookies.set('ios-install-interact', JSON.stringify(timestamp));
      });
    }
  }

  /**
   * Checks if cookie is expires
   * @returns {boolean} Returns if the Cookie has expired
   */
  cookieExpired() {
    try {
      const cookieValue = this._cookies.get('ios-install-interact');
      return cookieValue.length ? new Date() > new Date(JSON.parse(cookieValue)) : true;
    } catch (e) {
      console.error(e);
    }
  }

  showInstallPrompt(): boolean {
    return this.platformHelperService.shouldDisplayIosPrompt();
  }

  showChromeInstallPrompt(data: any) {
    const dialogRef = this._d.open(ChromeInstallComponent, {
      height: '400px',
      width: '400px',
      data: data || { title: 'SiiLA SPOT', icon_path: 'assets/images/brand/logo-header.png' }
    });
  }

  /**
   * @returns
   */
  close() {
    this._d.closeAll();
  }
}
