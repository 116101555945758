import { Component, OnInit, Output, EventEmitter, HostListener, OnDestroy, AfterViewChecked } from '@angular/core';
import { LoginFormDialogService } from '../login';
import { AccountService } from '@app/core/auth';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PWAService } from '../pwa-service';
import { PlatformHelperService, I18nService, ImageService } from '@app/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { InstallPromptDialogService } from '@app/core/install-prompt.service';
import { faUserCircle, faBell, faHeart, faSyncAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '@env/environment';
import { AuthenticationService } from '../../core/auth/auth.service';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { ListingDetailService, ListingService } from '@app/core/listings';
import { MatDialog } from '@angular/material';
import { RegisterFormComponent } from '../register';
import {} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import * as CartActions from '@app/store/actions';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { CommonGenericService } from '@app/core/common.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Output() toggleMenu: EventEmitter<any> = new EventEmitter();
  appInstalled$: Observable<any> = new Observable<any>();
  faUserCircle: any;
  faBell: any;
  faHeart: any;
  faSyncAlt: any;
  sideMenuOpen: boolean = false;
  deferredInstallPrompt: any;
  isIos: boolean;
  isIphone: boolean;
  isChrome: boolean;
  webKit: number;
  showBorder: boolean = false;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  currentDevice: string;
  screenWidth: any;
  userNameSubscription: Subscription;
  userName: string = '';
  messageCount: number = 0;
  favoritesCount: number = 0;
  favoritesCountSubscription: Subscription;
  msgCountSubscription: Subscription;
  private customLoginText: string;

  public loadUpdate: boolean = false;

  dialogRef: any;
  userAccount: any = {};
  downArrow: any;

  constructor(
    private loginFormDialog: LoginFormDialogService,
    private router: Router,
    private pwaService: PWAService,
    private platformHelper: PlatformHelperService,
    private alertCtrl: AlertController,
    private promptService: InstallPromptDialogService,
    private deviceDetector: DeviceDetectorService,
    private gaService$: GoogleAnalyticsService,
    private i18NService: I18nService,
    private auth: AuthenticationService,
    private accountService: AccountService,
    private location: Location,
    private messageService: MyMessageService,
    private listingDetailService: ListingDetailService,
    private dialog: MatDialog,
    private userActivityService: UserActivityService,
    private imageService: ImageService,
    private listingService: ListingService,
    private store: Store<any>,
    private commonService: CommonGenericService,
    private routingHelperSrv: RoutingHelperService
  ) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.customLoginText = this.i18NService.get('global.form.defaultSignInMessage');
    this.isIos = this.platformHelper.isIosChrome() || this.platformHelper.isIosSafari();
    this.appInstalled$ = this.pwaService.appInstalled$;
    this.faUserCircle = faUserCircle;
    this.faBell = faBell;
    this.faHeart = faHeart;
    this.faSyncAlt = faSyncAlt;
    this.downArrow = faChevronDown;
    this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
    this.isChrome = !!(window as any).chrome && (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime);
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }
    this.userNameSubscription = this.accountService.currentUser$.subscribe(user => {
      if (user) {
        this.userName = `${user.firstName} ${user.lastName}`;
        this.getFavoritesAndMessagesCount();
        if (!user.profileImage) {
          this.getUserProfile(user);
        }
      } else {
        this.userName = '';
        this.resetFavoritesAndMessagesCount();
      }
    });
  }

  private async getUserProfile(userAccount: any) {
    const userPhotos: any = await this.accountService.getUserPhotoList().toPromise();
    const image = this.accountService.getUploadedImageId(userPhotos);
    this.userAccount = {
      profile: userAccount,
      profileImage: this.getMediumImg(image.profileImageId),
      originalImage: this.getMediumImg(image.originalImageId),
      name: userAccount.firstName + ' ' + userAccount.lastName
    };
    //localStorage.setItem('user', this.userAccount);
    // this.accountService.currentUser$.next(this.userAccount);
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  ngAfterViewChecked() {
    if (localStorage.getItem('isDataAvailableReload') != null) {
      this.loadUpdate = true;
    }
  }

  public loadNewUpdate() {
    localStorage.removeItem('isDataAvailableReload');
    window.location.reload();
  }

  removeTooltips() {
    while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
      document.getElementsByTagName('mat-tooltip-component')[0].remove();
    }
  }

  getFavoritesAndMessagesCount() {
    this.listingDetailService.updateFavoritesCount();
    this.favoritesCountSubscription = this.listingDetailService.favoritesCountSubject.subscribe((val: number) => {
      this.favoritesCount = val;
    });
    this.msgCountSubscription = this.messageService.unreadMsgSubject.subscribe((val: number) => {
      this.messageCount = val;
    });
  }

  resetFavoritesAndMessagesCount() {
    this.favoritesCount = 0;
    this.messageCount = 0;
  }

  ngOnDestroy(): void {
    this.userNameSubscription.unsubscribe();
    if (this.favoritesCountSubscription) {
      this.favoritesCountSubscription.unsubscribe();
    }
    if (this.msgCountSubscription) {
      this.msgCountSubscription.unsubscribe();
    }
  }

  goToUserHome() {
    this.removeTooltips();
    return this.router.navigateByUrl('/user-home');
  }

  goToMyAccount() {
    this.removeTooltips();
    return this.router.navigateByUrl('/user-home/my-account');
  }

  goToFavorites() {
    this.removeTooltips();
    this.routingHelperSrv.navigateFavoritesPage();
  }

  isAppInstalled() {
    return this.pwaService.appInstalled$;
  }

  toggleSideMenu() {
    this.sideMenuOpen = !this.sideMenuOpen;
    this.toggleMenu.emit(this.sideMenuOpen);
  }

  promptInstallPWA() {
    this.userActivityService.trackInstallApp();
    if (this.isAppInstalled()) {
      this.pwaService.promptPWAInstall();
    }
    this.gaService$.event('install', 'App Adoption', `${this.currentDevice}`);
  }

  promptIos() {
    this.userActivityService.trackInstallApp();
    this.gaService$.event('install', 'App Adoption', `${this.currentDevice}`);
    if (this.isIphone && (this.isChrome || this.platformIsIosChrome())) {
      this.promptIosChromeDialog();
    } else {
      this.promptService.openDialog(null, true);
    }
  }

  promptIosChromeDialog() {
    this.presentAlert();
  }

  isMobile() {
    return this.deviceDetector.isMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  showH1HomeTitle() {
    return this.screenWidth >= 999;
  }

  platformIsIosChrome() {
    return this.platformHelper.isIosChrome();
  }

  platformIsIosSafari() {
    return this.platformHelper.isIosSafari();
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'SiiLA SPOT Mobile',
      cssClass: 'header-alert-prompt',
      message: 'To install the app, please open our site using the Safari browser and click install App to proceed.'
    });

    await alert.present();
  }

  openLoginDialog() {
    const loginData = {
      url: this.router.url,
      customText: this.customLoginText
    };
    this.loginFormDialog.openDialog(loginData);
  }

  back() {
    window.history.back();
  }

  goToHomePage() {
    return this.commonService.goToHomePage();
  }

  public changeBorder() {
    this.showBorder = true;
    setTimeout(() => {
      this.showBorder = false;
    }, 1000);
  }

  navigateToRegister() {
    //this.location.replaceState('/'); // clears browser history so they can't navigate with back button
    /* if (this.router.url !== '/register') {
      this.router.navigateByUrl('/register');
    } */
    this.dialog.open(RegisterFormComponent, {
      height: 'auto',
      width: '350px',
      maxWidth: 'none'
    });
  }

  checkRoute() {
    if (this.router.url !== '/register') {
      return false;
    } else {
      return true;
    }
  }

  logOut(event: any) {
    // Clear search on landing page
    this.listingService.updateModel({
      propertyType: null,
      listingType: null,
      stateId: null,
      cityId: null,
      keywordText: ''
    });
    this.auth.logout();
    this.clearBuySpotCart();
    if (this.router.url === '/') {
      this.toggleSideMenu();
    }
  }

  private clearBuySpotCart() {
    this.store.dispatch(CartActions.ClearCart({ payload: { items: [] } }));
  }

  isStandAlone() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }
}
