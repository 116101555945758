import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../core/auth/auth.service';
import { Observable } from 'rxjs';
import { LoginFormDialogService } from '@app/shared/login';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AccessGrantedDialogComponent } from '@app/shared';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  $isLoggedIn: Observable<boolean>;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private loginFormDialog: LoginFormDialogService,
    private dialog: MatDialog
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.isLoggedIn().pipe(
      map((isAuthenticated: any) => {
        if (!isAuthenticated && state.url === '/user-home') {
          return false;
        } else if (!isAuthenticated) {
          //if (environment.production || environment.spotServerUrl.includes('dev')) {
          let access = localStorage.getItem('accessGranted');
          if (!access) {
            this.router.navigate(['/']);
            //this.checkAccess();
            return false;
          } else {
            this.loginFormDialog.openDialog();
            this.router.navigate(['/']);
            return false;
          }
          /*} else {
            this.loginFormDialog.openDialog();
            this.router.navigate(['/']);
            return false;
          }*/
        }
        return true;
      })
    );
  }

  checkAccess() {
    this.dialog.open(AccessGrantedDialogComponent, { disableClose: true });
  }
}
