import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiilaChatSupportComponent } from '@app/chat/siila-chat-support.component';
import { SharedModule } from '@app/shared';
import { InViewportModule } from 'ng-in-viewport';
import { IonicModule } from '@ionic/angular';
import { SiilaChatService } from '@app/chat/siila-chat.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { AccountService } from '@app/core/auth';
import { FormsModule } from '@angular/forms';
import { ImageService } from '@app/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiilaChatViewComponent } from '@app/chat/siila-chat-view.component';

@NgModule({
  declarations: [SiilaChatSupportComponent, SiilaChatViewComponent],
  imports: [IonicModule, CommonModule, FormsModule, SharedModule, InViewportModule, TranslateModule],
  exports: [SiilaChatSupportComponent],
  providers: [SiilaChatService, SocketIOService, SiilaChatSupportService, AccountService, ImageService]
})
export class SiilaChatModule {}
