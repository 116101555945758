import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { RegisterService } from '@app/core/register.service';
import { BrokersDTO } from '@app/models/bokers.model';
import { CompanyDTO } from '@app/models/company.model';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';
import { IonicSelectableComponent } from 'ionic-selectable';
var SpotMoreBuildingsComponent = /** @class */ (function () {
    function SpotMoreBuildingsComponent(registerService) {
        this.registerService = registerService;
        this.selectCompany = null;
        this.ownerCompany = null;
        this.company = new CompanyDTO();
        this.brokers = [];
        this.offeredByUser = null;
    }
    SpotMoreBuildingsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.multiLangInit();
                        _a = this;
                        return [4 /*yield*/, this.getCompanies()];
                    case 1:
                        _a.allCompanies = _b.sent();
                        this.addressArray = this.spotBuildings.address;
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotMoreBuildingsComponent.prototype.ngOnChanges = function (changes) {
        if (this.buildingDetail) {
            this.existingComments = this.buildingDetail.comments;
            this.spotBuildings.comments = this.buildingDetail.comments;
            this.existingInvestment = this.buildingDetail.investmentOpportunity;
            this.spotBuildings.investmentOpportunity = this.buildingDetail.investmentOpportunity;
            this.ownerCompany = this.buildingDetail.offeredByCompany;
            this.spotBuildings.offeredByCompany = this.buildingDetail.offeredByCompany;
            if (this.buildingDetail.brokers && this.buildingDetail.brokers.length > 0) {
                this.selectCompany = this.buildingDetail.brokers[0].company;
            }
            else {
                this.selectCompany = this.buildingDetail.offeredByCompany;
            }
            this.getUsersByCompany(this.selectCompany.id);
            this.spotBuildings.brokers = this.buildingDetail.brokers;
            this.offeredByUser = this.buildingDetail.brokers;
            this.spotBuildings.address = this.buildingDetail.address;
            this.spotBuildings.neighborhood = this.buildingDetail.neighborhood;
        }
    };
    SpotMoreBuildingsComponent.prototype.multiLangInit = function () {
        this.descriptionFieldOptions = {
            columnLabel: 'global.registerSpot.description',
            element: { richtext: true },
            formArrayName: 'description',
            required: true
        };
        this.addressFieldOptions = {
            columnLabel: 'global.register.address',
            element: { input: true },
            formArrayName: 'address',
            required: true
        };
        this.InvestmentFieldOptions = {
            columnLabel: 'global.list-your-property.investmentOpportunity',
            element: { richtext: true },
            formArrayName: 'description2',
            required: true
        };
    };
    SpotMoreBuildingsComponent.prototype.addressChange = function (value) {
        var _this = this;
        if (value.address.length > 0) {
            var mergedObject_1 = {};
            var nullValues_1 = false;
            Object.keys(value.address).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.address[key];
                    if (obj['en'] === '' || obj['es'] === '') {
                        nullValues_1 = true;
                    }
                    else {
                        mergedObject_1 = Object.assign(mergedObject_1, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_1 ? (this.spotBuildings.address = '') : (this.spotBuildings.address = JSON.stringify(mergedObject_1));
        }
        else {
            this.spotBuildings.address = '';
        }
    };
    SpotMoreBuildingsComponent.prototype.getCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingCompanies().toPromise()];
            });
        });
    };
    SpotMoreBuildingsComponent.prototype.getUsersByCompany = function (companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.offeredByUser = '';
                        _a = this;
                        return [4 /*yield*/, this.registerService.getUsersByCompany(companyId).toPromise()];
                    case 1:
                        _a.users = _b.sent();
                        this.users.map(function (user) {
                            user.userId = user.id;
                            return user;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotMoreBuildingsComponent.prototype.selectOwnerCompany = function ($event) {
        this.company.id = $event.value.id;
        this.spotBuildings.offeredByCompany = this.company;
    };
    SpotMoreBuildingsComponent.prototype.portChange = function ($event) {
        //this.company.id = $event.value.id;
        //this.spotBuildings.offeredByCompany = this.company;
        this.getUsersByCompany($event.value.id);
        this.clearBokers();
    };
    SpotMoreBuildingsComponent.prototype.clearBokers = function () {
        this.spotBuildings.brokers = null;
        this.brokers = [];
    };
    SpotMoreBuildingsComponent.prototype.nameChange = function ($event) {
        var _this = this;
        this.clearBokers();
        $event.value.forEach(function (element) {
            var broker = new BrokersDTO();
            broker.userId = element.userId;
            _this.brokers.push(broker);
        });
        this.spotBuildings.brokers = this.brokers.length == 0 ? null : this.brokers;
    };
    SpotMoreBuildingsComponent.prototype.descriptionChange = function (value, update) {
        var _this = this;
        if (value.description.length > 0) {
            var mergedObject_2 = {};
            var nullValues_2 = false;
            Object.keys(value.description).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.description[key];
                    if (obj['en'] === '' || obj['en'] === null || obj['es'] === '') {
                        nullValues_2 = true;
                    }
                    else {
                        mergedObject_2 = Object.assign(mergedObject_2, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_2 ? (this.spotBuildings.comments = '') : (this.spotBuildings.comments = JSON.stringify(mergedObject_2));
        }
        else {
            this.spotBuildings.comments = '';
        }
    };
    SpotMoreBuildingsComponent.prototype.investmentChange = function (value, update) {
        var _this = this;
        if (value.description.length > 0) {
            var mergedObject_3 = {};
            var nullValues_3 = false;
            Object.keys(value.description).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var obj;
                return tslib_1.__generator(this, function (_a) {
                    obj = value.description[key];
                    if (obj['en'] === '' || obj['en'] === null || obj['es'] === '') {
                        nullValues_3 = true;
                    }
                    else {
                        mergedObject_3 = Object.assign(mergedObject_3, obj);
                    }
                    return [2 /*return*/];
                });
            }); });
            nullValues_3
                ? (this.spotBuildings.investmentOpportunity = '')
                : (this.spotBuildings.investmentOpportunity = JSON.stringify(mergedObject_3));
        }
        else {
            this.spotBuildings.investmentOpportunity = '';
        }
    };
    return SpotMoreBuildingsComponent;
}());
export { SpotMoreBuildingsComponent };
