import { Component, OnInit } from '@angular/core';
import { I18nService } from '@app/core/i18n.service';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private i18nService: I18nService) {}

  p2: string;
  p3: string;
  p5: string;
  p6: string;
  p7: string;
  p8: string;
  p9: string;
  p10: string;
  p11: string;

  ngOnInit() {
    this.p2 = this.i18nService.get('global.privacy-policy.paragraphs.p2');
    this.p3 = this.i18nService.get('global.privacy-policy.paragraphs.p3');
    this.p5 = this.i18nService.get('global.privacy-policy.paragraphs.p5');
    this.p6 = this.i18nService.get('global.privacy-policy.paragraphs.p6');
    this.p7 = this.i18nService.get('global.privacy-policy.paragraphs.p7');
    this.p8 = this.i18nService.get('global.privacy-policy.paragraphs.p8');
    this.p9 = this.i18nService.get('global.privacy-policy.paragraphs.p9');
    this.p10 = this.i18nService.get('global.privacy-policy.paragraphs.p10');
    this.p11 = this.i18nService.get('global.privacy-policy.paragraphs.p11');
  }
}
