<div class="flex-btn">
  <ion-button color="primary" fill="outline" (click)="dismiss()">
    <ion-icon name="close"></ion-icon>
  </ion-button>
</div>
<div>
  <!--h3 translate="global.contactModal.header" class="title center"></h3-->
  <p translate="global.contactModal.infoBr" class="subtitle center"></p>
  <button translate="global.contactModal.contactEmailBr" class="contact-btn" (click)="openEmail()"></button>
  <p class="center">
    <span translate="global.contactModal.call">Or call: </span>
    <a href="tel:+52 (55) 64 60 65 29">+52 (55) 64 60 65 29</a>
  </p>

  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16508365787"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'AW-16508365787');
  </script>
  <!-- Event snippet for Page view conversion page -->
  <script>
    gtag('event', 'conversion', {
      send_to: 'AW-16508365787/ouM_CIO2gJ8ZENvX5r89',
      value: 1.0,
      currency: 'MXN'
    });
  </script>
</div>
