import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Socket } from 'ngx-socket-io';
import { AccountService } from '@app/core/auth/account.service';
import { RxStomp } from '@stomp/rx-stomp';
import { tap } from 'rxjs/operators';
import { HttpService } from '@app/core/http';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { HttpClient } from '@angular/common/http';

export function socketFactory() {
  //return new (window as any).SockJS(environment.sockJs);
}

@Injectable()
export class SocketIOService {
  private stomp: RxStomp;
  private unreadMessagesEndpoint: string = `api/userChat/unreadConversationsFromSupport`;
  private currentUser: any;
  connected$: BehaviorSubject<any> = new BehaviorSubject(false);
  connected: boolean = false;

  constructor(
    //private socket: Socket,
    private chatSupportService: SiilaChatSupportService,
    private myMessageServices: MyMessageService,
    private http: HttpClient,
    private account: AccountService
  ) {}

  async connect() {
    /* this.setEvents();
    if (!!localStorage.getItem('user') && !!localStorage.getItem('token')) {
      const user = JSON.parse(localStorage.getItem('user'));
      const chatRefresh: any = await this.chatSupportService.refreshUserChat(user.login);
      if (!this.connected) {
        this.connected = true;
        console.log('Open socket connection');
        this.socket.connect();
        this.socket.emit('auth', {
          token: (chatRefresh || {}).token,
          user: user.login
        });
      }
      this.connected$.next(true);
    } */
  }

  setEvents() {
    /* this.socket.on('actualConnections', (connection: any) => {
      this.connected$.next(true);
    });
    this.socket.on('validatingConnection', (connection: any) => {
      console.log('validating connection --> ', connection);
    });
    this.socket.on('disconnected', (data: any) => {
      this.connected$.next(false);
      console.log('disconnect event --> ', data);
    });
    this.socket.on('message', (msg: any) => {
      this.updateUnreadMessagesByConversation(msg.conversationId);
      this.chatSupportService.appendMessages(msg);
    }); */
  }

  async updateUnreadMessagesByConversation(conversationId: String) {
    if (window.location.href.includes('my-messages') && conversationId) {
      this.myMessageServices.setUnreadCount(0);
      this.updateUnreadMessages(conversationId);
    } else {
      this.updateUnreadMessageCount();
    }
  }

  async updateUnreadMessageCount() {
    const unreadMessageInfo: any = await this.getUnreadMessagesCount().toPromise();
    this.myMessageServices.setUnreadCount(
      unreadMessageInfo && unreadMessageInfo.unreadCount ? unreadMessageInfo.unreadCount : 0
    );
  }

  async updateUnreadMessages(msgConvId?: any) {
    const { conversationId: currConvId } = this.chatSupportService.messageData.getValue();
    let conversationId = msgConvId || currConvId;
    const user = JSON.parse(localStorage.getItem('user'));
    this.chatSupportService
      .updateUnreadMessages(user.login ? user.login : user.profile.login, conversationId)
      .then((result: any) => {
        if (result.result == 'updated') {
          this.myMessageServices.setUnreadCount(0);
        }
      });
  }

  public getUnreadMessagesCount() {
    return this.http.get(`${this.unreadMessagesEndpoint}`);
  }

  public setCurrentUser() {
    this.currentUser = this.account.currentUser$.getValue();
  }

  public disconnect() {
    // TODO: Disconnect the socket!
    this.connected = false;
    //console.log('Disconnect socket');
    //this.socket.emit('disconnect');
    //this.socket.disconnect();
    //this.socket.ioSocket.disconnect();
    //this.socket.ioSocket.io.disconnect();
  }
}
