import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { FeaturedListing } from '@app/models';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AppListingSliderService {
  state: SliderState;
  private subject = new Subject<any>();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  setState(state: SliderState) {
    this.state = state;
  }

  getState(): SliderState {
    if (this.state) {
      return this.state;
    }
  }

  setSlide(action: any) {
    this.subject.next(action);
  }

  getSlide(): Observable<any> {
    return this.subject.asObservable();
  }
}

export interface SliderState {
  queryPage: number;
  loadedItems: FeaturedListing[];
  totalCount: number;
  activeIndex: number;
}
