import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
var EditCropImageModalComponent = /** @class */ (function () {
    function EditCropImageModalComponent(modalCtrl, commonService, sanitizer) {
        this.modalCtrl = modalCtrl;
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.showCroppingToggle = false;
        this.fileName = null;
        this.UPLOAD_FILE_SIZE_LIMIT = 20000000;
        this.imageCropperOptions = {};
        this.imageChangedEvent = {};
        this.canvasRotation = 0;
        this.rotation = 0;
        this.transform = {};
        /*  Image based 64 */
        this.principalImageToEdit = null;
        this.croppedImage = null;
        this.cropOn = false;
        this.removedImage = false;
        this.replacedImage = false;
        this.restoreToOriginalImage = false;
        this.croppedImageWidth = '200px';
        this.croppedImageHeight = '150px';
        this.originalImageUrl = null;
        this.validImage = true;
    }
    EditCropImageModalComponent.prototype.ngOnInit = function () {
        this.getPrincipalImage();
        this.imageCropperOptions = this.getImageCropperOptions();
        if (this.originalImageId) {
            this.originalImageUrl = "/images/" + this.originalImageId + "/thumbnail/ml";
        }
    };
    EditCropImageModalComponent.prototype.getImageCropperOptions = function () {
        return {
            maintainAspectRatio: 'true',
            cropperMinWidth: 128,
            cropperMinHeight: 128,
            resizeToWidth: 200,
            format: 'png',
            roundCropper: false,
            onlyScaleDown: true,
            aspectRatio: 4 / 3
        };
    };
    EditCropImageModalComponent.prototype.getPrincipalImage = function () {
        var _this = this;
        if (this.newImageBased64) {
            this.principalImageToEdit = this.newImageBased64;
        }
        else if (this.imageUrlToEdit) {
            this.convertToDataURL(this.imageUrlToEdit, function (dataURL) {
                _this.principalImageToEdit = dataURL;
            });
        }
    };
    EditCropImageModalComponent.prototype.restoreToOriginalImg = function () {
        var _this = this;
        if (this.originalImageUrl) {
            this.convertToDataURL(this.originalImageUrl, function (dataURL) {
                _this.principalImageToEdit = dataURL;
                _this.replacedImage = false;
                _this.restoreToOriginalImage = true;
            });
        }
    };
    EditCropImageModalComponent.prototype.convertToDataURL = function (imageUrl, callback) {
        var xhr = new XMLHttpRequest();
        this.fileName = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = function () {
            var fileReader = new FileReader();
            fileReader.onloadend = function () {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    };
    EditCropImageModalComponent.prototype.closeModal = function (data) {
        this.modalCtrl.dismiss(data);
    };
    EditCropImageModalComponent.prototype.resetFileInput = function (ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    };
    EditCropImageModalComponent.prototype.fileUploadErrorHandler = function (action) {
        var infoModalParams = {
            headerTitle: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFile' : 'global.uploadImage',
            bodyMessage: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFileError' : 'global.uploadImageSizeExceed'
        };
        this.imageChangedEvent = null;
        this.principalImageToEdit = null;
        this.fileName = null;
        this.resetFileInput(document.getElementById('inputImageFile'));
        this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
    };
    EditCropImageModalComponent.prototype.openInfoModal = function (headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalProps, infoModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalProps = {
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: 'generic-info-modal',
                            component: InfoModalComponent,
                            componentProps: {
                                headerTitle: headerTitle,
                                bodyMessage: bodyMessage,
                                data: data,
                                extraData: extraData
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) { });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    EditCropImageModalComponent.prototype.imageCropped = function (image) {
        if (image && image.cropperPosition) {
            this.croppedImageWidth = image.cropperPosition.x2 - image.cropperPosition.x1 + 'px';
            this.croppedImageHeight = image.cropperPosition.y2 - image.cropperPosition.y1 + 'px';
            this.croppedImage = image.base64;
        }
    };
    EditCropImageModalComponent.prototype.fileChangedEvent = function (event, remove, replaced) {
        var _this = this;
        this.removedImage = remove;
        this.replacedImage = replaced;
        this.validImage = false;
        if (remove) {
            this.croppedImage = null;
            this.imageChangedEvent = null;
            this.principalImageToEdit = null;
        }
        else {
            var imageExtensions = this.commonService.getValidImageExension();
            var isValidImage = this.commonService.validateExtension(imageExtensions, event.srcElement.files[0].name);
            if (!isValidImage) {
                this.fileUploadErrorHandler('invalid-file');
                return;
            }
            else if (event.srcElement.files[0].size > this.UPLOAD_FILE_SIZE_LIMIT) {
                this.fileUploadErrorHandler('exceed-limit');
                return;
            }
            this.validImage = true;
            this.imageChangedEvent = event;
            this.principalImageToEdit = event.srcElement.files[0];
            this.fileName = event.srcElement.files[0].name;
            var fileReader_1 = new FileReader();
            fileReader_1.onload = function () {
                _this.principalImageToEdit = fileReader_1.result;
            };
            fileReader_1.readAsDataURL(this.principalImageToEdit);
        }
    };
    EditCropImageModalComponent.prototype.cancel = function () {
        this.closeModal(null);
    };
    EditCropImageModalComponent.prototype.convertImageToFile = function (imgBase64) {
        return this.commonService.convertImageBase64ToFileSync(imgBase64, this.fileName);
    };
    EditCropImageModalComponent.prototype.saveImage = function () {
        var originalImageFile = this.convertImageToFile(this.principalImageToEdit);
        var croppedImageFile = this.croppedImage ? this.convertImageToFile(this.croppedImage) : null;
        this.closeModal({
            originalImage: { imgBase64: this.principalImageToEdit, file: originalImageFile },
            croppedImage: { imgBase64: this.croppedImage, file: croppedImageFile },
            wasImageCropped: this.cropOn,
            wasOriginalImgReplaced: this.replacedImage,
            originalImageId: this.originalImageId,
            wasRestoreToOriginalImage: this.restoreToOriginalImage
        });
    };
    EditCropImageModalComponent.prototype.rotateLeft = function () {
        this.canvasRotation--;
        this.flipAfterRotate();
    };
    EditCropImageModalComponent.prototype.rotateRight = function () {
        this.canvasRotation++;
        this.flipAfterRotate();
    };
    EditCropImageModalComponent.prototype.flipAfterRotate = function () {
        var flippedH = this.transform.flipH;
        var flippedV = this.transform.flipV;
        this.transform = tslib_1.__assign({}, this.transform, { flipH: flippedV, flipV: flippedH });
    };
    EditCropImageModalComponent.prototype.flipHorizontal = function () {
        this.transform = tslib_1.__assign({}, this.transform, { flipH: !this.transform.flipH });
    };
    EditCropImageModalComponent.prototype.flipVertical = function () {
        this.transform = tslib_1.__assign({}, this.transform, { flipV: !this.transform.flipV });
    };
    EditCropImageModalComponent.prototype.resetImage = function () {
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    };
    EditCropImageModalComponent.prototype.croppingImageValueChanged = function (event) { };
    EditCropImageModalComponent.prototype.showCroppedImage = function () {
        if (this.cropOn && !this.removedImage) {
            return true;
        }
        return false;
    };
    EditCropImageModalComponent.prototype.showNotCroppedImage = function () {
        var showCroppedImage = this.showCroppedImage();
        if (!showCroppedImage) {
            if (!this.imageUrlToEdit || this.imageUrlToEdit == null) {
                if (!this.principalImageToEdit || this.principalImageToEdit == null) {
                    return false;
                }
            }
            if (!this.validImage) {
                return false;
            }
            return !this.removedImage;
        }
        return !showCroppedImage;
    };
    EditCropImageModalComponent.prototype.getNotCroppedImage = function () {
        if (this.imageUrlToEdit && !this.cropOn && !this.replacedImage) {
            return this.imageUrlToEdit;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.principalImageToEdit);
    };
    EditCropImageModalComponent.prototype.showLoadOption = function () {
        return this.removedImage || !this.cropOn;
    };
    return EditCropImageModalComponent;
}());
export { EditCropImageModalComponent };
