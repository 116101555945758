<ion-content>
  <app-breadcrumb
    inViewport
    (inViewportAction)="enableAlternateBack($event)"
    [inViewportOptions]="{ thredhold: [0] }"
    [options]="breadcrumbOptions"
  >
  </app-breadcrumb>
  <ion-row
    [ngStyle]="{ 'justify-content': alternateBack ? 'space-evenly' : 'center' }"
    style="position:sticky;top:0;width:100%;text-align:center;z-index:999999;height:40px;font-size:1.1em;color:white;display:flex;align-items:center;"
    class="siila-blue-bg"
  >
    <ion-buttons style="width:100%;">
      <ion-button slot="start" *ngIf="alternateBack" icon-only (click)="goBack()" color="white">
        <ion-icon style="font-size:18px;" name="arrow-back" size="medium"></ion-icon>
      </ion-button>
      <span [ngStyle]="{ flex: alternateBack ? '1' : '1' }" style="font-size:24px;">
        {{ 'global.contactSportSupport' | translate }}
      </span>
    </ion-buttons>
  </ion-row>
  <app-siila-chat></app-siila-chat>
</ion-content>
