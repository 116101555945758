// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import env from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  serverUrl: 'https://stg.siila.com.mx',
  spotServerUrl: 'https://stg.siilaspot.com.mx',
  tdmessageUrl: 'https://stgapp.siila.com.mx:8911',
  sockJs: 'https://stgapp.siila.com.mx/chat',
  ws: 'wss://44i6e0stm6.execute-api.sa-east-1.amazonaws.com/stg',
  requirePassword: false,
  syncEnabled: false,
  defaultLanguage: 'es-MX',
  supportedLanguages: ['en-US', 'es-MX'],
  analyticsTracking: 'UA-235984370-1',
  apiURL: '/api',
  publicApiKeyPaylee: 'BeWT3k/vypkk+pbCv0DR43TM7Lb33GAY5hB2z/yRoa4=',
  baseTrackingID: '',
  agentWhatsAppTrackingID: 'UA-235984370-1',
  agentEmailTrackingID: 'UA-235984370-1',
  agentPhoneTrackingID: 'UA-235984370-1',
  installAppTrackingID: 'UA-235984370-1',
  sendEmailTrackingID: 'UA-235984370-1',
  getStartedUserRegistrationID: 'UA-235984370-1',
  googleAPIKey: 'AIzaSyD7ILit-WpiTauIV0bdY2OpeSGnNfA9jXE',
  matomoSiteId: '3',
  serverBaseUrl: 'https://stgapp.siila.com.mx/#!/',
  platformId: 'mx',
  cloudfrontURL: 'https://dnclcgcvl4f14.cloudfront.net/'
};
