import { Injectable } from '@angular/core';
import { I18nService } from '../i18n.service';
import { GenericMapService } from '../generic-map.service';
import { ActivatedRoute } from '@angular/router';
import { SpotBuildingMapDTO } from '@app/models/spot-buildings/spot-buildings-map.model';
import { ImageService } from '../image.service';
import { CommonGenericService } from '../common.service';
import { PlatformHelperService } from '@app/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { SizeIcon } from '@app/models/shared/shared-enums.model';
import { RoutingHelperService } from '../helpers/routing-helper.service';
import { FavoriteSpotService } from '../../shared/services/favorite-spot.service';
import { icon } from '@fortawesome/fontawesome-svg-core';

export interface UserGeoLocation {
  latitude: number;
  longitude: number;
}

@Injectable()
export class NearbyListingSvc {
  private DEFAULT_LNG_LAT: any = { latitude: 19.43277777777, longitude: -99.133055555556 };
  private DEFAULT_RADIUS: number = 5000;
  private DEFAULT_LISTING_DETAIL_RADIUS: number = 5000;
  defaultImage: string;
  faHeart: any;

  favIconSize: SizeIcon = SizeIcon.LG;
  private geoCoder: any;

  constructor(
    private genericMapSvc: GenericMapService,
    private i18n: I18nService,
    private imageService: ImageService,
    private commonService: CommonGenericService,
    private platformConfigHelper: PlatformHelperService,
    private routerHelper: RoutingHelperService,
    private favSpotService: FavoriteSpotService
  ) {
    this.faHeart = faHeart;
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
  }

  private findCountryByCoordinates(coordinate: any): Promise<string> {
    this.geoCoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);

    let promise: Promise<string> = new Promise((resolve, reject) => {
      this.geoCoder.geocode({ latLng: latlng }, function(results: any, status: any) {
        if (status == google.maps.GeocoderStatus.OK) {
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var b = 0; b < results[0].address_components[i].types.length; b++) {
              if (results[0].address_components[i].types[b] == 'country') {
                resolve(results[0].address_components[i].short_name);
              }
            }
          }
          resolve('nowhere');
        }
      });
    });

    return promise;
  }

  private isValidLatitude(latitude: number) {
    return latitude >= -90 && latitude <= 90;
  }

  private isValidLongitude(longitude: number) {
    return longitude >= -180 && longitude <= 180;
  }

  private hasProperty(obj: any, prop: string) {
    return obj.hasOwnProperty(prop) && !isNaN(obj[prop]);
  }

  private searchModelField(): any[] {
    return [
      'propertytype',
      'officetype',
      'sale',
      'lease',
      'keyword',
      'citystate',
      'coworkingtype',
      'coworkingpositions'
    ];
  }

  public isArray(object: any, attr: string) {
    return object[attr] && object[attr].length;
  }

  public createNearByListingInfoWindow(listing: any) {
    const infoWindowContent: string = `
     <div style="height:25px; width: fit-content; height: fit-content">
      <div style="display: flex; flex-flow:column nowrap">
        <div style="color: #043650; font-weight:600"> ${this.i18n.getTranslation(listing.titleOnListing)} </div>
        <div> ${this.i18n.getTranslation(listing.address)} </div>
        <div style="color: #ff9900; padding-top: 10px; padding-bottom: 5px; font-weight: 500">
          ${listing.listingIds.length} ${
      listing.listingIds.length === 1 ? this.i18n.get('global.singleListing') : this.i18n.get('global.pluralListing')
    }
        </div>
      <div>
    </div>`;
    return new google.maps.InfoWindow({
      content: infoWindowContent
    });
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  getCityState(listing: SpotBuildingMapDTO) {
    return `${this.i18n.getTranslation(listing.cityName)} - ${this.i18n.getTranslation(listing.stateName)}`;
  }

  private infoWindowAction(listing: any) {}

  public createSpotBuildingInfoWindow(listing: SpotBuildingMapDTO, isLoggedIn: boolean) {
    let infoWindowContent: string = '';

    if (listing) {
      const imgURL = listing.mainImgId ? this.getMediumImg(listing.mainImgId) : this.defaultImage;
      infoWindowContent = `
      <div id="spotInfoWindowContainer" (click)="openDetailsPage()"  class="spot-info-window__container grid-two-cols">
       <div style="display: flex; flex-flow:column nowrap">
            <img
            class="spot-info-window__img"
            [defaultImage]="defaultImage"
            src="${imgURL}"
            lazyLoad="${imgURL}"
          />
       </div>
       <div class="flex-col-nowrap spot-info-window__datacontainer">
            <div class="flex spot-info-window__title">
              <div class="ellipsis__text">${this.i18n.getTranslation(listing.titleOnListing)} </div>
              <div style="margin-right: 10px" class="${this.buildFavBtnClassContainer(listing)}">
                  <ion-button id="favButton"
                  class="${this.buildFavBtnClass(listing)}"
                  expand="full"
                  size="small"
                  fill="clear"
                >
                ${icon(faHeart).html}
            </ion-button>
              </div>
            </div>
            <div class="ellipsis__text spot-info-window__header">${this.i18n.getTranslation(listing.address)} </div>
            <div>
              <span class="spot-info-window__header">
                ${this.getCityState(listing)}
              </span>
            </div>

            <div class="spot-info-window__topseparator">
              <div class="${this.showIfNot(listing.promoted)}">
                <div class="flex spot-info-window__subheader" style="margin-top: 4px;">
                    <span class="nowrap bold">${this.commonService.getAvailableAreaLabel(listing)}</span>
                    <span class="ellipsis__text padding-left-10">${this.getAvailableAreaText(listing)}</span>
                </div>
                <div class="flex spot-info-window__subheader">
                    <span class="nowrap bold">${this.commonService.getAskingRentPriceLabel(listing)} </span>
                    <span class="ellipsis__text padding-left-10">${this.getAvgAskingRent(listing)}</span>
                </div>
              </div>
              <div class="${this.showIf(listing.promoted)}">
                    <div style="display: flex;align-items: center; margin-top: 5px">                        
                    </div>
                </div>
            </div>
       </div>
     </div>`;
    }

    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent
    });

    infoWindow.addListener('domready', () => {
      let favBtnElement = document.getElementById('favButton');
      favBtnElement.addEventListener('click', (event: any) => {
        event.stopImmediatePropagation();
        const spot = { ...listing, id: listing.listingIds[0] };

        this.favSpotService.setFavoriteSpot(event, spot).then((resp: any) => {
          listing.favorite = spot.favorite;
          let currentFavClass = favBtnElement.className;
          currentFavClass = currentFavClass.replace('is-favorite', '');
          currentFavClass = currentFavClass.replace('no-favorite', '');
          favBtnElement.className = `${currentFavClass} ${this.getFavClass(spot)}`;
        });
      });

      document.getElementById('spotInfoWindowContainer').addEventListener('click', (event: any) => {
        this.routerHelper.navigateTo(listing.spotURL);
      });
    });

    return infoWindow;
  }

  buildFavBtnClassContainer(listing: SpotBuildingMapDTO) {
    const customClasses = `flex-align-content-end `;
    return customClasses;
  }

  buildFavBtnClass(listing: SpotBuildingMapDTO) {
    const favClass = this.getFavClass(listing);
    const customClasses = `spot-info-window__title__favorite-button ${favClass} `;
    return customClasses;
  }

  showIfNot(condition: boolean) {
    return this.showIf(!condition);
  }

  showIf(condition: boolean) {
    return condition ? '' : 'spot-info-not-visible';
  }

  getFavClass(listing: SpotBuildingMapDTO) {
    return listing.favorite ? 'is-favorite' : 'no-favorite';
  }

  private getAvailableAreaText(spot: SpotBuildingMapDTO) {
    return this.commonService.getAvailableAreaText(spot.spaceRangesDTO, spot.type);
  }

  private getAvgAskingRent(spot: SpotBuildingMapDTO) {
    return this.commonService.getAvgAskingRentNearby(spot, spot.type);
  }

  public prevSelectedMarker(markers: any[], marker: any) {
    return markers.filter(item => {
      return item.id !== marker.id && item.isSelected;
    });
  }

  public updatePrevSelectedMarker(marker: any) {
    marker.infoWindow.close();
    marker.setIcon(this.genericMapSvc.BLUE_PIN);
    marker.set('isSelected', false);
  }

  public createMarker(listing: any, infoWindow: google.maps.InfoWindow, icon?: string, isListingDetail?: boolean) {
    let marker: google.maps.Marker = new google.maps.Marker({
      position: {
        lat: listing.lat,
        lng: listing.lng
      },
      map: null,
      icon: icon ? icon : this.genericMapSvc.BLUE_PIN,
      draggable: false
    });

    if (isListingDetail) {
      marker.set('location', {
        latitude: listing.lat,
        longitude: listing.lng,
        radius: this.DEFAULT_LISTING_DETAIL_RADIUS
      });
    }
    marker.set('infoWindow', infoWindow);
    marker.set('id', listing.id);
    marker.set('listingIds', listing.listingIds);
    return marker;
  }

  public createBasicMarker(listing: any, icon: string) {
    let marker: google.maps.Marker = new google.maps.Marker({
      position: {
        lat: listing.latitude,
        lng: listing.longitude
      },
      map: null,
      icon: icon,
      draggable: false
    });
    marker.set('id', listing.id);
    marker.set('isCurrentListing', true);
    return marker;
  }

  public get bluePin() {
    return this.genericMapSvc.BLUE_PIN;
  }

  public get orangePin() {
    return this.genericMapSvc.ORANGE_PIN;
  }

  public getNoResultMessage(resultCount: number) {
    return resultCount > 0 ? '' : this.i18n.get('global.search.noListingsFound');
  }

  public getSpotNearMeMapMode(activatedRoute: ActivatedRoute) {
    return [
      activatedRoute.snapshot.queryParams,
      activatedRoute.snapshot.queryParams.type &&
        activatedRoute.snapshot.queryParams.type.toLowerCase() === 'spotsnearme'
    ].every(condition => condition);
  }

  public getSpotRadius(propertyType?: string) {
    if (!propertyType) {
      return this.DEFAULT_RADIUS;
    }

    if (propertyType == '1001') {
      return 30000;
    } else {
      return this.DEFAULT_RADIUS;
    }
  }

  public async getUserLocationCoordinate(coordinate: any, propertyType?: string): Promise<any> {
    let geoLocation = { latitude: -1, longitude: -1 };
    let promise = new Promise((resolve, reject) => {
      geoLocation.latitude = coordinate.latitude;
      geoLocation.longitude = coordinate.longitude;
      this.findCountryByCoordinates(geoLocation).then(country => {
        const radius: number = this.getSpotRadius(propertyType);
        let coord =
          country == 'MX'
            ? {
                latitude: coordinate.latitude,
                longitude: coordinate.longitude,
                radius: radius
              }
            : Object.assign({ radius: radius }, this.DEFAULT_LNG_LAT);

        resolve(coord);
      });
    });
    return promise;
  }

  public isSpotsNearMeSearch(state: any, attr: string, stateName: string) {
    return state.hasOwnProperty(attr) && state[attr].toLowerCase() === stateName.toLowerCase();
  }

  public unsetListingSearchDTO(searchDTO: any, attrs: any[]) {
    let newDTO: any = Object.assign({}, searchDTO);
    attrs.forEach(attr => {
      newDTO[attr] = null;
    });
    return newDTO;
  }

  public setNearbySpotParam(dto: any, listings: any[]) {
    return {
      searchDTO: dto,
      listingIds: listings
    };
  }

  public hasValidCoordinate(coordinate: any, lat: string, lng: string) {
    const hasLat: boolean = this.hasProperty(coordinate, lat);
    const hasLng: boolean = this.hasProperty(coordinate, lng);
    const isValidLat: boolean = hasLat ? this.isValidLatitude(coordinate[lat]) : false;
    const isValidLng: boolean = hasLng ? this.isValidLongitude(coordinate[lng]) : false;
    return isValidLat && isValidLng;
  }

  public setRegularSearchActiveRoute(currentRoute: string, isSpotNearMe: boolean) {
    let activeRoute: string = '';
    if (isSpotNearMe !== null) {
      activeRoute = isSpotNearMe && currentRoute.toLowerCase() === '/search' ? '' : 'active-route';
    }
    return activeRoute;
  }

  public setSpotNearMeActiveRoute(currentRoute: string, isSpotNearMe: boolean) {
    let activeRoute: string = '';
    if (isSpotNearMe !== null) {
      activeRoute = isSpotNearMe && currentRoute.toLowerCase() === '/search' ? 'active-route' : '';
    }
    return activeRoute;
  }

  public resetActiveRoute(currentRoute: string, currentActiveRoute: string) {
    return currentRoute === '/' ? '' : currentActiveRoute;
  }

  public getDefaultUserCoordinate() {
    return Object.assign({ radius: 1000 }, this.DEFAULT_LNG_LAT);
  }

  public getGeoLocationErrorCode(error: any) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'global.geoLocation.permissionDenied';
      case error.POSITION_UNAVAILABLE:
        return 'global.geoLocation.positionUnavailable';
      case error.TIMEOUT:
        return 'global.geoLocation.timeout';
      case error.UNKNOWN_ERROR:
        return 'global.geoLocation.unknownError';
    }
  }

  public getSearchCriteriaState(searchState: any, stateAttr: string) {
    return searchState && searchState.hasOwnProperty(stateAttr) && searchState[stateAttr] ? searchState[stateAttr] : {};
  }

  public setNearbyListingSearchCriteria(currentSearchDto: any, listing: any) {
    return Object.assign(currentSearchDto, {
      latitude: listing.latitude,
      longitude: listing.longitude,
      listingIds: null,
      radius: this.DEFAULT_LISTING_DETAIL_RADIUS
    });
  }

  public scrollIntoViewElem(elem: any) {
    setTimeout(() => {
      elem.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  public setCheckboxesFilter(filterName: string, filter: any) {
    let copyOfFilter: any = Object.assign({}, filter);
    const isFilterPropertyExist: boolean = copyOfFilter.hasOwnProperty(filterName);
    if (isFilterPropertyExist) {
      copyOfFilter[filterName] = false;
    }
    return copyOfFilter;
  }

  public isTagFieldExist(fieldName: string): boolean {
    return [
      fieldName.toLowerCase() === 'rangeceilingheight',
      fieldName.toLowerCase() === 'baysize',
      fieldName.toLowerCase() === 'dockratio'
    ].some(condition => condition);
  }

  public getClearSearchProperty() {
    return [
      'propertySubTypes',
      'propertySubtypeId',
      'coworkingPositions',
      'coworkingType',
      'buildingTypes',
      'listingLeaseOrSale',
      'stateId',
      'cityId',
      'keyword'
    ];
  }

  public resetNearbyFilterToDefault(filters: any) {
    const permanentProps: any[] = ['latitude', 'longitude', 'radius'];
    const copyOfFilter: any = Object.assign({}, filters);
    Object.keys(copyOfFilter).forEach(key => {
      const isKeyPermanent: boolean = permanentProps.includes(key);
      if (!isKeyPermanent) {
        copyOfFilter[key] = null;
      }
    });
    return copyOfFilter;
  }

  public resetSearchModelOnChange(fieldName: string, models: any) {
    const searchModels: any[] = this.searchModelField();
    Object.keys(models).forEach(key => {
      if (searchModels.includes(fieldName.toLowerCase())) {
        models[key] = null;
      }
    });
    return models;
  }
}
function country(country: any, string: any) {
  throw new Error('Function not implemented.');
}
