/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nearby-listing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "../../../shared/directives/locale-changer/locale-changer.directive";
import * as i6 from "../../../core/i18n.service";
import * as i7 from "@angular/common";
import * as i8 from "../listing-card/listing-card.component.ngfactory";
import * as i9 from "../listing-card/listing-card.component";
import * as i10 from "../../../shared/login/login-form-dialog.service";
import * as i11 from "../../../core/image.service";
import * as i12 from "@angular/router";
import * as i13 from "../../services/detail-position.service";
import * as i14 from "../carousel/app-carousel-restore.service";
import * as i15 from "../../../core/helpers/platform-helper.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "../../../core/common.service";
import * as i18 from "../../../core/generic-map.service";
import * as i19 from "@angular/cdk/layout";
import * as i20 from "../../../core/helpers/property-type-helper.service";
import * as i21 from "ng-navigator-share";
import * as i22 from "../../../core/listings/listing-detail.service";
import * as i23 from "../../../core/auth/auth.service";
import * as i24 from "../../../core/listings/event-checker.service";
import * as i25 from "../../../core/user-activity/user-activity.service";
import * as i26 from "../../services/coworking.service";
import * as i27 from "../listing-slider/app-listing-slider.service";
import * as i28 from "./nearby-listing.component";
var styles_NearbyListingCardComponent = [i0.styles];
var RenderType_NearbyListingCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NearbyListingCardComponent, data: {} });
export { RenderType_NearbyListingCardComponent as RenderType_NearbyListingCardComponent };
function View_NearbyListingCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { listings: 0 }), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "global.singleListingAvailable"; var currVal_1 = _ck(_v, 2, 0, _co.nearbyListings.length); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NearbyListingCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { listings: 0 }), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "global.pluralListingAvailable"; var currVal_1 = _ck(_v, 2, 0, _co.nearbyListings.length); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NearbyListingCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-label", [["class", "nearby-listing-header"]], null, null, null, i3.View_IonLabel_0, i3.RenderType_IonLabel)), i1.ɵdid(1, 49152, null, 0, i4.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "span", [["localize", ""]], null, null, null, null, null)), i1.ɵdid(4, 540672, null, 0, i5.LocaleChangerDirective, [i1.ElementRef, i2.TranslateService, i1.Renderer2, i6.I18nService], { textValue: [0, "textValue"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NearbyListingCardComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\n    "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NearbyListingCardComponent_3)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedBuildingName; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.nearbyListings.length === 1); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.nearbyListings.length > 1); _ck(_v, 10, 0, currVal_2); }, null); }
function View_NearbyListingCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-listing-card", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ListingCardComponent_0, i8.RenderType_ListingCardComponent)), i1.ɵdid(1, 245760, null, 0, i9.ListingCardComponent, [i10.LoginFormDialogService, i11.ImageService, i12.Router, i13.DetailPositionService, i14.AppCarouselRestoreService, i15.PlatformHelperService, i16.MatDialog, i17.CommonGenericService, i18.GenericMapService, i19.BreakpointObserver, i20.PropertyTypeHelperService, i6.I18nService, i21.NgNavigatorShareService, i4.ToastController, i22.ListingDetailService, i23.AuthenticationService, i24.EventCheckerService, i2.TranslateService, i25.UserActivityService, i26.CoworkingService, i27.AppListingSliderService], { listing: [0, "listing"], activeListingIds: [1, "activeListingIds"], lazyLoadImages: [2, "lazyLoadImages"], searchCriteria: [3, "searchCriteria"], checkBoxValues: [4, "checkBoxValues"], searchTags: [5, "searchTags"], cardImgWithPadding: [6, "cardImgWithPadding"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.$implicit.listingIds; var currVal_2 = true; var currVal_3 = _co.searchCriteria.searchDTO; var currVal_4 = _co.searchCriteria.checkBoxValues; var currVal_5 = _co.searchTags; var currVal_6 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_NearbyListingCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "listing-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NearbyListingCardComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "nearby-listing-card"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NearbyListingCardComponent_4)), i1.ɵdid(9, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nearbyListings.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.nearbyListings; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_NearbyListingCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nearby-listing-card", [], null, null, null, View_NearbyListingCardComponent_0, RenderType_NearbyListingCardComponent)), i1.ɵdid(1, 49152, null, 0, i28.NearbyListingCardComponent, [], null, null)], null, null); }
var NearbyListingCardComponentNgFactory = i1.ɵccf("nearby-listing-card", i28.NearbyListingCardComponent, View_NearbyListingCardComponent_Host_0, { selectedBuildingName: "selectedBuildingName", nearbyListings: "nearbyListings", searchCriteria: "searchCriteria", searchTags: "searchTags" }, {}, []);
export { NearbyListingCardComponentNgFactory as NearbyListingCardComponentNgFactory };
