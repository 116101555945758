import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShellComponent } from './shell.component';
import { SharedModule } from '@app/shared';
import { MaterialModule } from '@app/shared/material.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [CommonModule, TranslateModule, IonicModule, RouterModule, SharedModule, MaterialModule, FontAwesomeModule],
  entryComponents: [ShellComponent],
  declarations: [ShellComponent],
  providers: [CookieService]
})
export class ShellModule {}
