import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '@app/core/seo';
import { ListingType } from '@app/models/transaction-type.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
var SpotDetailContainerComponent = /** @class */ (function () {
    function SpotDetailContainerComponent(activatedRoute, metaService, createSpotBuildingsService) {
        this.activatedRoute = activatedRoute;
        this.metaService = metaService;
        this.createSpotBuildingsService = createSpotBuildingsService;
        this.isSpotFetched = false;
    }
    SpotDetailContainerComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.getMetatags();
                        return [4 /*yield*/, this.getParamsUrl()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SpotDetailContainerComponent.prototype.getParamsUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.activatedRoute.params.subscribe(function (paramMap) {
                    try {
                        var buildingIdParam = paramMap.buildingId;
                        var listingTypeParam = paramMap.buildingType;
                        _this.createSpotBuildingsService
                            .getSpotBuildingsFromBuilding(buildingIdParam, listingTypeParam, paramMap.lang)
                            .then(function (response) {
                            _this.buildingDetail = response;
                            _this.isPromoted = _this.buildingDetail.listingType == ListingType.Promoted;
                            setTimeout(function () {
                                _this.isSpotFetched = true;
                            }, 300);
                        });
                    }
                    catch (error) {
                        console.error(error);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    SpotDetailContainerComponent.prototype.getMetatags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.activatedRoute.params.subscribe(function (paramMap) {
                    try {
                        var buildingIdParam = paramMap.buildingId;
                        var listingTypeParam = paramMap.buildingType;
                        _this.createSpotBuildingsService
                            .getSpotBuildingsMetadataFromBuilding(buildingIdParam, listingTypeParam)
                            .then(function (response) {
                            _this.metaService.setSpotBuildingMetaTags2(response, []);
                            window.prerenderReady = true;
                        });
                    }
                    catch (error) {
                        console.error(error);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return SpotDetailContainerComponent;
}());
export { SpotDetailContainerComponent };
