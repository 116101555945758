<ion-content class="user-profile">
  <app-breadcrumb inViewport [inViewportOptions]="{ thredhold: [0] }" [options]="breadcrumbOptions"> </app-breadcrumb>
  <div class="detail-block-background">
    <div style="max-width: 800px; background: white; margin: 0 auto; height: 100%;">
      <form [formGroup]="userProfileForm" *ngIf="userAccountInfo">
        <ion-grid>
          <ion-row>
            <ion-col class="flex-col">
              <img class="image-loaded" [src]="profileImage?.croppedImage" default="{{ imagePlaceholder }}" />
            </ion-col>
            <ion-col class="flex-col">
              <div *ngIf="userAccountInfo.companyLogoImageId" style="margin-left: 30%;margin-top: 14%;">
                <div style="float:right">
                  <ion-icon
                    name="close-circle-outline"
                    class="remove-image pointer-cursor"
                    color="primary"
                    (click)="revomeLogo()"
                  >
                  </ion-icon>
                </div>
                <img
                  class="image-loaded"
                  style="border-radius: 0%;height: 57px;width: 136px"
                  [src]="getMediumImg(userAccountInfo.companyLogoImageId)"
                  default="{{ imagePlaceholder }}"
                />
              </div>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="flex-col" style="margin-left: 15%;">
              <div class="icon inline-flex">
                <ion-icon name="camera"></ion-icon>
                <ion-label
                  class="edit-profile pointer-cursor"
                  color="secondary"
                  [translate]="'global.user.editImage'"
                  (click)="openEditImageModal()"
                >
                </ion-label>
              </div>
            </ion-col>

            <ion-col class="flex-col" style="margin-left: 15%;">
              <div class="icon inline-flex">
                <ion-icon name="camera"></ion-icon>
                <ion-label
                  *ngIf="userAccountInfo.companyLogoImageId"
                  class="edit-profile pointer-cursor"
                  color="secondary"
                  [translate]="'global.user.editLogo'"
                  (click)="openEditLogoModal()"
                >
                </ion-label>
                <ion-label
                  *ngIf="!userAccountInfo.companyLogoImageId"
                  class="edit-profile pointer-cursor"
                  color="secondary"
                  [translate]="'global.user.addLogo'"
                  (click)="openEditLogoModal()"
                >
                </ion-label>
              </div>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="flex-col"> </ion-col>
            <ion-col class="flex-col">
              <ion-label style="margin-left: 34%;font-size: 14px;" [translate]="'global.user.logoSize'"></ion-label>
            </ion-col>
          </ion-row>

          <div formGroupName="userName">
            <ion-row>
              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.firstName'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="firstName" />
              </ion-col>

              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.lastName'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="lastName" />
              </ion-col>
            </ion-row>
          </div>

          <div formGroupName="location">
            <ion-row>
              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.address'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="address" />
              </ion-col>

              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.city'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="city" />
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.state'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="state" />
              </ion-col>

              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.postalCode'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="postalCode" />
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.country'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="country" />
              </ion-col>

              <ion-col size="6">
                <ion-label color="secondary" [translate]="'global.user.profileForm.company'"></ion-label>
                <input type="text" class="form-control input-control" formControlName="company" />
              </ion-col>
            </ion-row>
          </div>

          <div formGroupName="contact">
            <ion-row>
              <ion-col class="intl-phone-number">
                <ion-label color="secondary" [translate]="'global.register.requiredPrimaryPhone'"></ion-label>
                <ngx-intl-tel-input
                  id="primaryPhone"
                  name="primaryPhone"
                  formControlName="primaryPhone"
                  class="input-control"
                  placeholder="Phone number"
                  [preferredCountries]="intlTelephoneOptions.preferredCountries"
                  [enableAutoCountrySelect]="intlTelephoneOptions.enableAutoCountrySelect"
                  [enablePlaceholder]="intlTelephoneOptions.enablePlaceholder"
                  [searchCountryFlag]="intlTelephoneOptions.searchCountryFlag"
                  [selectFirstCountry]="intlTelephoneOptions.selectFirstCountry"
                  [selectedCountryISO]="userHomeService.primaryPhoneCountryISO"
                  [maxLength]="intlTelephoneOptions.maxLength"
                  [tooltipField]="intlTelephoneOptions.tooltipField"
                  [phoneValidation]="false"
                  [separateDialCode]="intlTelephoneOptions.separateDialCode"
                >
                </ngx-intl-tel-input>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="intl-phone-number">
                <ion-label color="secondary" [translate]="'global.user.profileForm.whatsApp'"></ion-label>
                <ngx-intl-tel-input
                  id="whatsApp"
                  name="whatsApp"
                  formControlName="whatsApp"
                  class="input-control"
                  placeholder="WhatsApp"
                  [preferredCountries]="intlTelephoneOptions.preferredCountries"
                  [enableAutoCountrySelect]="intlTelephoneOptions.enableAutoCountrySelect"
                  [enablePlaceholder]="intlTelephoneOptions.enablePlaceholder"
                  [searchCountryFlag]="intlTelephoneOptions.searchCountryFlag"
                  [selectFirstCountry]="intlTelephoneOptions.selectFirstCountry"
                  [selectedCountryISO]="userHomeService.whatsAppCountryISO"
                  [maxLength]="intlTelephoneOptions.maxLength"
                  [tooltipField]="intlTelephoneOptions.tooltipField"
                  [phoneValidation]="intlTelephoneOptions.phoneValidation"
                  [separateDialCode]="intlTelephoneOptions.separateDialCode"
                >
                </ngx-intl-tel-input>
              </ion-col>
            </ion-row>
          </div>

          <div formGroupName="profileInfo">
            <ion-row>
              <ion-col size="12">
                <ion-label color="secondary" [translate]="'global.register.requiredJobTitle'"></ion-label>
                <input type="text" class="form-control input-control" maxlength="40" formControlName="jobTitle" />
              </ion-col>
            </ion-row>

            <ion-row class="control-elem" *ngIf="userAccountInfo" style="margin-bottom: 1%;">
              <ion-col>
                <intl-language-element
                  [parentForm]="userProfileForm"
                  [options]="multiLangOptions"
                  [formArrayControl]="biographyFormArray"
                  [formArrayValue]="userBiographyValue"
                >
                </intl-language-element>
              </ion-col>
            </ion-row>
          </div>

          <div class="icon inline-flex">
            <ion-icon name="unlock"></ion-icon>
            <ion-label
              class="edit-profile pointer-cursor"
              color="secondary"
              [translate]="'global.user.changePassword'"
              (click)="openUpdatePasswordModal()"
            ></ion-label>
          </div>

          <div class="icon inline-flex">
            <ion-icon name="mail-unread"></ion-icon>
            <ion-label
              class="edit-profile pointer-cursor"
              color="secondary"
              [translate]="'global.user.updateEmailAddress'"
              (click)="openUpdateEmailModal()"
            >
            </ion-label>
          </div>

          <div class="advanced-settings" formGroupName="advancedSettings" style="padding-top: 2%;">
            <ion-row>
              <ion-col>
                <div class="inline-flex-nowrap" (click)="expandCollapseAdvSettings()">
                  <ion-icon class="arrow-collapse" name="arrow-dropright" *ngIf="!expandAdvancedSettings"></ion-icon>
                  <ion-icon *ngIf="expandAdvancedSettings" class="arrow-expand" name="arrow-dropdown"></ion-icon>
                  <ion-label [translate]="'global.advancedSettings'" color="secondary"></ion-label>
                </div>

                <ul [ngClass]="advancedSettingsClass">
                  <li>
                    <ion-checkbox color="secondary" name="excludeFromCommunity" formControlName="excludeFromCommunity">
                    </ion-checkbox>
                    <ion-label [translate]="'global.user.optOutOfCommunityAndMessage'"></ion-label>
                  </li>

                  <li>
                    <ion-checkbox color="secondary" name="emailNotification" formControlName="emailNotification">
                    </ion-checkbox>
                    <ion-label [translate]="'global.user.notifyEmailMessage'"></ion-label>
                  </li>

                  <li>
                    <ion-checkbox
                      color="secondary"
                      name="excludeFromCommunityEmails"
                      formControlName="excludeFromCommunityEmails"
                    >
                    </ion-checkbox>
                    <ion-label [translate]="'global.user.outOutOfProfileNotification'"></ion-label>
                  </li>
                </ul>
              </ion-col>
            </ion-row>
          </div>
          <ion-row>
            <ion-col size="12">
              <div class="save-cancel-btn">
                <div class="none-mobile-cancel-btn mobile-cancel-btn pointer-cursor" (click)="goBackToUserHome()">
                  <span [translate]="'Cancel'"></span>
                  <ion-ripple-effect></ion-ripple-effect>
                </div>

                <div class="pointer-cursor" [ngClass]="setSubmitButtonClass()" (click)="saveUserAccount()">
                  <span translate="global.register.save"></span>
                  <ion-ripple-effect></ion-ripple-effect>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </div>
  </div>
</ion-content>
