<form [formGroup]="formData" (ngSubmit)="sendMessage()" class="main-container">
  <ion-grid>
    <ion-row>
      <ion-col>
        <span
          class="form-error"
          *ngIf="
            formData.get('email').touched &&
            (formData.get('email').errors?.required || formData.get('email').errors?.pattern)
          "
        >
          {{ 'global.form.sendMsgToAgent.emailRequired' | translate }}
        </span>
        <ion-input
          class="contact-seller-input"
          name="email"
          type="email"
          autocapitalize="off"
          autocomplete="off"
          placeholder="{{ 'global.form.sendMsgToAgent.enterYourEmail' | translate }}"
          formControlName="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
          #email
        >
        </ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <span class="form-error" *ngIf="formData.get('name').touched && formData.get('name').errors?.required">
          {{ 'global.form.sendMsgToAgent.nameRequired' | translate }}
        </span>
        <ion-input
          autofocus="true"
          class="contact-seller-input"
          name="name"
          placeholder="{{ 'global.form.sendMsgToAgent.enterYourName' | translate }}"
          formControlName="name"
          #name
        >
        </ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <span class="form-error" *ngIf="formData.get('phone').touched && formData.get('phone').errors?.required">
          {{ 'global.form.sendMsgToAgent.phoneNumberRequired' | translate }}
        </span>
        <ion-input
          autocapitalize="false"
          class="contact-seller-input"
          name="phone"
          placeholder="{{ 'global.form.sendMsgToAgent.enterYourPhoneNumber' | translate }}"
          formControlName="phone"
          #phone
        >
        </ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <span
          class="form-error"
          *ngIf="formData.get('note').touched && formData.get('note').errors && formData.get('note').errors.required"
        >
          {{ 'global.form.sendMsgToAgent.msgRequired' | translate }}
        </span>
        <ion-textarea
          class="contact-seller-input"
          name="note"
          [rows]="rowsFromTextArea()"
          type="text"
          maxlength="500"
          spellcheck="true"
          placeholder="{{ 'global.form.sendMsgToAgent.enterYourMsgForAgent' | translate }}"
          formControlName="note"
          #note
        ></ion-textarea>
      </ion-col>
    </ion-row>
    <div>
      <ion-button
        expand="full"
        shape="round"
        type="submit"
        value="submit"
        color="primary"
        [disabled]="formData.get('email').invalid || formData.get('note').invalid"
        >{{ 'global.form.sendMsgToAgent.send' | translate }}
      </ion-button>
    </div>
  </ion-grid>
</form>
