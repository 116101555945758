import { Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { I18nService } from '@app/core';

@Injectable()
export class PurchaseCreditsService {
  private getCredits: string = `api/listings/purchases`;
  private getBuildingTypes: string = `api/buildings/buildingNames?buildingTypeId=`;
  private getBuildingNamesByProperty: string = `api/buildings/propertyType/names?buildingTypeId=`;
  private adminUpdate: string = '/api/listings/adminUpdate';
  private listingUpdate: string = '/api/listings/';
  constructor(private http: HttpClient, private i18NService: I18nService) {}

  async getCurrentCredits(id: number) {
    return this.http
      .get(`api/listings/user/${id}/purchases`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async getUserPayments(id: number) {
    return this.http
      .get(`api/listings/user/${id}/payments`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async getUserListingPaymentActivity(id: number) {
    return this.http
      .get(`api/listings/user/${id}/listingActivity`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async getBuildingType(id: number) {
    return this.http
      .get(`${this.getBuildingTypes}${id}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async getBuildingNamesByPropertyType(id: number) {
    return this.http
      .get(`${this.getBuildingNamesByProperty}${id}&lang=${this.i18NService.getCurrentLanguage()}`, {
        observe: 'response'
      })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async renewListing(listingPurchaseId: any, listingId: any) {
    return this.http
      .post(`api/listings/${listingId}/spot-listing-renew?listingPurchaseId=${listingPurchaseId}`, {
        observe: 'response'
      })
      .pipe(map(val => val))
      .toPromise();
  }

  async renewDeactivated(listingPurchaseId: any, listingId: any) {
    return this.http
      .post(`api/listings/${listingId}/spot-listing-renew?listingPurchaseId=${listingPurchaseId}&deactivated=true`, {
        observe: 'response'
      })
      .pipe(map(val => val))
      .toPromise();
  }

  async adminListingUpdate(listing: any) {
    return this.http
      .post(`${this.adminUpdate}`, listing)
      .pipe(map(val => val))
      .toPromise();
  }

  async applyAction(id: any, action: any) {
    return this.http
      .post(`${this.listingUpdate}${id}/${action}`, { observe: 'response' })
      .pipe(map(val => val))
      .toPromise();
  }

  async approveListing(id: any) {
    return this.http
      .post(`${this.listingUpdate}${id}/confirm-payment`, { observe: 'response' })
      .pipe(map(val => val))
      .toPromise();
  }

  async reactivateListing(id: any) {
    return this.http
      .post(`${this.listingUpdate}${id}/reactivate`, { observe: 'response' })
      .pipe(map(val => val))
      .toPromise();
  }

  async deactivateListing(id: any) {
    return this.http
      .post(`${this.listingUpdate}${id}/deactivate`, { observe: 'response' })
      .pipe(map(val => val))
      .toPromise();
  }

  async spotDeactivate(id: any) {
    return this.http
      .post(`${this.listingUpdate}${id}/spot-deactivate`, { observe: 'response' })
      .pipe(map(val => val))
      .toPromise();
  }
}
