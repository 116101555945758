import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, QueryList, OnDestroy } from '@angular/core';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { AccountService } from '@app/core/auth';
import { SiilaChatService } from '@app/chat/siila-chat.service';
import { Socket } from 'ngx-socket-io';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { CommonGenericService } from '@app/core/common.service';
import { DeviceDetectorService } from 'ngx-device-detector';
var SiilaChatSupportComponent = /** @class */ (function () {
    function SiilaChatSupportComponent(chatSupportService, siilaChatService, account, socket, myMessagesServices, accountService, socketIO, commonSvc, deviceDetectorService) {
        var _this = this;
        this.chatSupportService = chatSupportService;
        this.siilaChatService = siilaChatService;
        this.account = account;
        this.socket = socket;
        this.myMessagesServices = myMessagesServices;
        this.accountService = accountService;
        this.socketIO = socketIO;
        this.commonSvc = commonSvc;
        this.deviceDetectorService = deviceDetectorService;
        this.messageInput = '';
        this.getCurrentUser();
        window.addEventListener('keydown', function (evt) {
            _this.enterPress(evt);
        });
    }
    SiilaChatSupportComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.messageDataSubscription = this.chatSupportService.messageData.subscribe(function (messageData) {
                            _this.messageData = messageData;
                        });
                        return [4 /*yield*/, this.chatSupportService.populateMessageData()];
                    case 1:
                        _a.sent();
                        this.loadPhotos();
                        this.socketIO.updateUnreadMessages();
                        return [2 /*return*/];
                }
            });
        });
    };
    SiilaChatSupportComponent.prototype.ngAfterViewInit = function () {
        this.ionTextAreaSubscription = this.inputMessageComponent.changes.subscribe(function (textArea) {
            textArea.last.getInputElement().then(function (result) {
                result.focus();
            });
        });
    };
    SiilaChatSupportComponent.prototype.ngOnDestroy = function () {
        this.ionTextAreaSubscription.unsubscribe();
        this.messageDataSubscription.unsubscribe();
    };
    SiilaChatSupportComponent.prototype.enterPress = function (e) {
        switch (e.keyCode) {
            case 13:
                e.preventDefault();
                this.sendMessage();
                break;
        }
    };
    SiilaChatSupportComponent.prototype.loadPhotos = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var photos, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.account.getProfilePicture('spot@siila.com.br').toPromise()];
                    case 1:
                        photos = _b.sent();
                        _a = this;
                        return [4 /*yield*/, this.chatSupportService.getUsernameInfo('spot@siila.com.br')];
                    case 2:
                        _a.spotAccountName = _b.sent();
                        if (this.spotAccountName) {
                            this.spotAccountFullname = this.spotAccountName.firstName + " " + this.spotAccountName.lastName;
                        }
                        if (photos) {
                            this.spotAccountImage = '/images/' + photos[photos.length - 1] + '/thumbnail/m';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SiilaChatSupportComponent.prototype.sendMessage = function () {
        if (this.messageInput && this.messageInput.trim().length) {
            var messageEvent = {
                conversationId: this.messageData.conversationId,
                content: this.messageInput,
                userId: this.currentUser.login,
                createdAt: new Date(),
                filename: null,
                type: 'text',
                notifyList: this.messageData.userIds
            };
            this.siilaChatService.sendMessage(messageEvent);
            this.messageInput = null;
        }
    };
    SiilaChatSupportComponent.prototype.getCurrentUser = function () {
        this.userProfileImage = localStorage.getItem('profileImage');
        this.currentUser = this.account.currentUser$.getValue();
        if (this.currentUser) {
            this.userFullname = this.currentUser.firstName + " " + this.currentUser.lastName;
        }
    };
    SiilaChatSupportComponent.prototype.scrollOnFocus = function (element) {
        var os = this.deviceDetectorService.os;
        var isAndriodIos = os.toLowerCase() === 'android' || os.toLowerCase() === 'ios';
        if (isAndriodIos) {
            element.el.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return SiilaChatSupportComponent;
}());
export { SiilaChatSupportComponent };
