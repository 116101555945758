<div class="portfolio__container" *ngIf="!isFavorite">
  <div *ngIf="title" class="portfolio__container__title">
    <span class="title"> {{ title }}</span>
  </div>

  <div class="loading__animation__section" *ngIf="isSearching() && firstTime">
    <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
  </div>

  <div id="tabsHeader" *ngIf="showHeaderSorting && !firstTime" class="header">
    <span class="header__results">{{ getHeaderResults() }} </span>
    <div class="filter header__sorting">
      <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <ion-button
          style="border-radius: 4px"
          class="btn-white sort-btn full-width noIconSort m-0"
          size="large"
          color="light"
          ngbDropdownToggle
        >
          <div class="location-label d-flex align-items-center px-0 px-md-1">
            <span class="d-none d-md-block">{{ 'global.sort' | translate }}</span>
            <img style="width: 18px;" class="m-0 m-md-2" src="assets/images/icons/sort.png" alt="" />
          </div>
        </ion-button>
        <div class="dropdown-menu sort-group widthSor" ngbDropdownMenu>
          <button
            ngbDropdownItem
            class="sort-item"
            [ngClass]="{ 'active-sort-item': isActiveSort('date', 'DESC') }"
            (click)="applySortBy('date', 'DESC')"
          >
            {{ 'global.newestToOldest' | translate }}
          </button>
          <button
            ngbDropdownItem
            class="sort-item"
            [ngClass]="{ 'active-sort-item': isActiveSort('date', 'ASC') }"
            (click)="applySortBy('date', 'ASC')"
          >
            {{ 'global.oldestToNewest' | translate }}
          </button>
          <div class="dropdown-divider"></div>
          <button class="clear-selection" (click)="clearSortSelection()" ngbDropdownItem>
            {{ 'global.clearSelection' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    [ngClass]="{ portfolio__container__tabs: hideStatesFilter, portfolio__container__tabs__states: !hideStatesFilter }"
  >
    <mat-tab-group
      (click)="$event.stopPropagation()"
      #tabGroup
      [(selectedIndex)]="tabIndex"
      mat-align-tabs="start"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab id="forLeaseTab" class="portofolio__tab" *ngIf="showForLeaseTab && !isMobile" [label]="forLeaseLbl">
        <ng-container *ngTemplateOutlet="stateFilterAndCards"></ng-container>
      </mat-tab>
      <mat-tab class="portofolio__tab" *ngIf="showForLeaseTab && isMobile" [label]="forLeaseLbl">
        <ng-container *ngTemplateOutlet="stateFilterMobile"></ng-container>
      </mat-tab>
      <mat-tab class="portofolio__tab" *ngIf="showForSaleTab && !isMobile" [label]="forSaleLbl">
        <ng-container *ngTemplateOutlet="stateFilterAndCards"></ng-container>
      </mat-tab>
      <mat-tab class="portofolio__tab" *ngIf="showForSaleTab && isMobile" [label]="forSaleLbl">
        <ng-container *ngTemplateOutlet="stateFilterMobile"></ng-container>
      </mat-tab>
      <mat-tab class="portofolio__tab" *ngIf="showForCoworkingTab && !isMobile" [label]="forCoworkingLbl">
        <ng-container *ngTemplateOutlet="stateFilterAndCards"></ng-container>
      </mat-tab>
      <mat-tab class="portofolio__tab" *ngIf="showForCoworkingTab && isMobile" [label]="forCoworkingLbl">
        <ng-container *ngTemplateOutlet="stateFilterMobile"></ng-container>
      </mat-tab>
    </mat-tab-group>

    <div *ngIf="showNoFoundSection() && !isSearching()" class="nofound__container nofound__container--margin">
      <div class="nofound__container__title">
        <fa-icon class="fav__icon" [icon]="faHeart"></fa-icon>
        <span translate="favorites.noFoundTitle"></span>
      </div>
      <div class="nofound__container__description">
        <span translate="favorites.noFoundDesc"></span>
      </div>
      <div class="nofound__container__action">
        <ion-button (click)="goToSearch()">{{ 'favorites.browseSpots' | translate }}</ion-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isFavorite" class="full-width" style="justify-content: center;margin-top: 30px;">
  <ion-segment mode="ios" class="segment-block" color="secondary">
    <ion-segment-button
      (click)="changeSpotType('L')"
      style="margin-right: 0px;"
      [checked]="currentSpotBuildingType == 'L'"
      class="segment-border"
    >
      <ion-label style="text-transform: none;">{{ forLeaseLbl }}</ion-label>
    </ion-segment-button>
    <ion-segment-button
      (click)="changeSpotType('S')"
      style="margin-left: 0px;"
      [checked]="currentSpotBuildingType == 'S'"
      class="segment-border"
    >
      <ion-label style="text-transform: none;">{{ forSaleLbl }}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <div class="row" *ngIf="isSearching()" style="justify-content: center;">
    <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
  </div>

  <ng-container>
    <div
      *ngIf="!isSearching() && !isMobile"
      [ngClass]="{ 'spot__cards__section--margintop': !hideStatesFilter }"
      class="spot__cards__section"
    >
      <div class="card-container" *ngFor="let spot of allSpotBuildings">
        <app-spot-card
          [hideFavIcon]="hideFavoriteIcon()"
          [listing]="spot"
          (favoriteChangeEvent)="favoriteChange($event)"
          [lazyLoadImages]="true"
        >
        </app-spot-card>
      </div>
    </div>

    <div *ngIf="!isSearching() && isMobile" [ngClass]="{ 'spot-cards_mobile-section': !hideStatesFilter }">
      <ion-row class="w-100">
        <ion-col size="12">
          <app-spot-card-slider
            #spotCardSlider
            [hideFavIcon]="hideFavoriteIcon()"
            (totalCountEvent)="spotsCountSlider($event)"
            (favoriteChangeEvent)="favoriteChange($event)"
            [cardContainerClass]="'px-2'"
            onlyFavorites="true"
            id="spotCardSlider"
            [spotBuildingSearchPayload]="spotBuildingSearchRequest"
          ></app-spot-card-slider>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>
  <div
    *ngIf="showNoFoundSection() && !isSearching()"
    style="border: none;"
    class="nofound__container nofound__container--margin"
  >
    <div class="nofound__container__title">
      <fa-icon class="fav__icon" [icon]="faHeart"></fa-icon>
      <span translate="favorites.noFoundTitle"></span>
    </div>
    <div class="nofound__container__description">
      <span translate="favorites.noFoundDesc"></span>
    </div>
    <div class="nofound__container__action">
      <ion-button (click)="goToSearch()">{{ 'favorites.browseSpots' | translate }}</ion-button>
    </div>
  </div>
</div>

<ng-template #stateFilterAndCards>
  <!-- Spinner Loading Animation -->
  <div class="loading__animation__section" *ngIf="isSearching()">
    <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
  </div>

  <div *ngIf="!isSearching() && !hideStatesFilter" class="city__filter__section flex-row-justify-center">
    <div
      (click)="applyCityFilter(city.id)"
      [ngClass]="{ city__filter__section__action__active: currentCityFilterId == city.id }"
      class="city__filter__section__action"
      *ngFor="let city of cityFilters"
    >
      {{ city.name }}
    </div>
  </div>

  <div
    *ngIf="!isSearching()"
    [ngClass]="{ 'spot__cards__section--margintop': !hideStatesFilter }"
    class="spot__cards__section"
  >
    <div class="card-container" *ngFor="let spot of allSpotBuildings">
      <app-spot-card
        [hideFavIcon]="hideFavoriteIcon()"
        [listing]="spot"
        (favoriteChangeEvent)="favoriteChange($event)"
        [lazyLoadImages]="true"
      >
      </app-spot-card>
    </div>
  </div>

  <div *ngIf="!isSearching()" class="more__results__section">
    <ion-button
      class="more__results__section__btn"
      fill="clear"
      *ngIf="showMoreResults()"
      (click)="showMoreResultsAction()"
    >
      <ion-icon class="dismiss-icon" name="ios-arrow-down"></ion-icon>
      <ion-text>{{ 'global.form.moreResults' | translate }}</ion-text>
    </ion-button>
  </div>
</ng-template>

<ng-template #stateFilterMobile>
  <!-- Spinner Loading Animation -->
  <div class="loading__animation__section" *ngIf="isSearching()">
    <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
  </div>

  <div *ngIf="!hideStatesFilter" style="overflow-x: auto; height: 60px;" class="city__filter__section">
    <div class="d-flex w-100">
      <div
        (click)="applyStateFilterOnMobile($event, city.id, idx)"
        *ngFor="let city of cityFilters; let idx = index"
        [ngClass]="{ city__filter__section__action__active: currentCityFilterId == city.id }"
        class="city__filter__section__action mx-1"
        style="white-space: nowrap; height: 32px;"
      >
        {{ city.name }}
      </div>
    </div>
  </div>

  <div [ngClass]="{ 'spot-cards_mobile-section': !hideStatesFilter }">
    <ion-row class="w-100">
      <ion-col size="12">
        <app-spot-card-slider
          #spotCardSlider
          [hideFavIcon]="hideFavoriteIcon()"
          (totalCountEvent)="spotsCountSlider($event)"
          (favoriteChangeEvent)="favoriteChange($event)"
          [cardContainerClass]="'px-2'"
          onlyFavorites="true"
          id="spotCardSlider"
          [spotBuildingSearchPayload]="spotBuildingSearchRequest"
        ></app-spot-card-slider>
      </ion-col>
    </ion-row>
  </div>
</ng-template>
