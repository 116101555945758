import { Injectable } from '@angular/core';

@Injectable()
export class ClassHelperService {
  selectClass: any[];

  classes = [
    { name: 'FEATURED', id: 0 },
    { name: 'PREMIUM', id: 1 },
    { name: 'PROMOTED', id: 2 },
    { name: 'BASIC', id: 3 }
  ];

  async getClassIds() {
    let arr: any = [];
    Object.keys(this.selectClass).forEach(async (key, idx) => {
      arr.push(this.selectClass[key].id);
    });
    return arr;
  }

  updateSelectedClassesBasedOnDTO(listingSearchDto: any) {
    this.selectClass = [];
    if (listingSearchDto.listingClasses) {
      if (listingSearchDto.listingClasses.length > 0) {
        (<Array<any>>listingSearchDto.listingClasses).forEach(classTag => {
          const classFound = (<Array<any>>this.classes).find(cl => cl.id == classTag);
          if (classFound) {
            (<Array<any>>this.selectClass).push(classFound);
          }
        });
      }
    }
  }

  removeClass(listingClass: any) {
    this.selectClass.forEach((item: any, index: number) => {
      if (item.id == listingClass.id) {
        this.selectClass.splice(index, 1);
        return;
      }
    });
  }
}
