/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./save-change-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./save-change-modal.component";
import * as i9 from "../../core/i18n.service";
var styles_SaveChangeModalComponent = [i0.styles];
var RenderType_SaveChangeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaveChangeModalComponent, data: {} });
export { RenderType_SaveChangeModalComponent as RenderType_SaveChangeModalComponent };
function View_SaveChangeModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "custom__content__txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customMsg; _ck(_v, 1, 0, currVal_0); }); }
function View_SaveChangeModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, 0, 14, "ion-col", [["class", "modal--pading"], ["id", "customcontent"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(4, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SaveChangeModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, 0, 7, "ion-input", [["autofocus", "true"], ["style", "border: 1px solid #ced4da; padding: 0px; padding-left: 5px !important;"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11)._handleInputEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.customInput = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonInput_0, i2.RenderType_IonInput)), i1.ɵdid(10, 49152, null, 0, i3.IonInput, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { autofocus: [0, "autofocus"], type: [1, "type"] }, null), i1.ɵdid(11, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(13, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(15, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customMsg; _ck(_v, 7, 0, currVal_0); var currVal_8 = "true"; var currVal_9 = "text"; _ck(_v, 10, 0, currVal_8, currVal_9); var currVal_10 = _co.customInput; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 15).ngClassValid; var currVal_6 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_SaveChangeModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "custom__content__txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customMsg; _ck(_v, 1, 0, currVal_0); }); }
function View_SaveChangeModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n              "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "dropdown-item full-width dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setItemSelection(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, [[4, 4]], 0, i6.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(4, null, ["\n                ", "\n              "])), (_l()(), i1.ɵted(-1, null, ["\n            "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).disabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_SaveChangeModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(3, 0, null, 0, 28, "ion-col", [["class", "modal--pading"]], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(4, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SaveChangeModalComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(9, 0, null, 0, 21, "div", [["class", "load__search__container  filter--dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 18, "div", [["class", "d-inline-block full-width"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(12, 1720320, null, 3, i6.NgbDropdown, [i1.ChangeDetectorRef, i6.NgbDropdownConfig, i4.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i6.NgbNavbar]], null, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "form-control btn_type h-100 full-width dropdown-toggle"], ["id", "dropdownMap"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 18).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i6.NgbDropdownToggle, [i6.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i6.NgbDropdownAnchor, null, [i6.NgbDropdownToggle]), (_l()(), i1.ɵted(20, null, ["\n            ", "\n          "])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(22, 0, [[2, 0]], null, 6, "div", [["aria-labelledby", "dropdownMap"], ["class", "full-width"], ["ngbDropdownMenu", ""], ["style", "overflow-y: auto; height: 90px;"]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 23).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, [[1, 4]], 1, i6.NgbDropdownMenu, [i6.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SaveChangeModalComponent_5)), i1.ɵdid(27, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customMsg; _ck(_v, 7, 0, currVal_0); var currVal_7 = _co.dropdownItems; _ck(_v, 27, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 12).isOpen(); _ck(_v, 11, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 18).dropdown.isOpen(); _ck(_v, 17, 0, currVal_2); var currVal_3 = (_co.selectedItem ? _co.selectedItem.name : ""); _ck(_v, 20, 0, currVal_3); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 23).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 23).placement; _ck(_v, 22, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_SaveChangeModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "ion-content", [["class", "save-change-modal"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(1, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 13, "ion-row", [["style", "margin-top: 10px; border-bottom: 1px solid #6c757d"]], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(4, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "div", [["style", "width: 80%;margin-bottom: 12px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["style", "font-weight: 700;font-size: 23px;  margin-left: 20px;"]], null, null, null, null, null)), i1.ɵdid(9, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, 0, 3, "div", [["style", "width: 20%;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "img", [["alt", ""], ["class", "close-icon"], ["src", "assets/images/icons/close.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissAsDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SaveChangeModalComponent_1)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SaveChangeModalComponent_3)), i1.ɵdid(22, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\n  "])), (_l()(), i1.ɵeld(24, 0, null, 0, 14, "ion-row", [], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(25, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(27, 0, null, 0, 10, "ion-col", [], null, null, null, i2.View_IonCol_0, i2.RenderType_IonCol)), i1.ɵdid(28, 49152, null, 0, i3.IonCol, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(30, 0, null, 0, 6, "div", [["class", "control-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(32, 0, null, null, 3, "ion-button", [["class", "btn-color-2"], ["style", "width: 100%;font-size: 14px;margin: 15px 0px 0px 0px;"], ["type", "submit"], ["value", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.userAction("OK") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(33, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { disabled: [0, "disabled"], type: [1, "type"] }, null), i1.ɵdid(34, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTitle; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.isForSaveSearch(); _ck(_v, 19, 0, currVal_1); var currVal_2 = _co.isForLoadSearch(); _ck(_v, 22, 0, currVal_2); var currVal_3 = _co.disableSentBtn(); var currVal_4 = "submit"; _ck(_v, 33, 0, currVal_3, currVal_4); var currVal_5 = _co.rightButtonLabel; _ck(_v, 34, 0, currVal_5); }, null); }
export function View_SaveChangeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "save-change-modal", [], null, null, null, View_SaveChangeModalComponent_0, RenderType_SaveChangeModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.SaveChangeModalComponent, [i3.ModalController, i9.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SaveChangeModalComponentNgFactory = i1.ɵccf("save-change-modal", i8.SaveChangeModalComponent, View_SaveChangeModalComponent_Host_0, { headerTitle: "headerTitle", bodyMessage: "bodyMessage", leftButtonLabel: "leftButtonLabel", rightButtonLabel: "rightButtonLabel", modalType: "modalType", dropdownItems: "dropdownItems" }, {}, []);
export { SaveChangeModalComponentNgFactory as SaveChangeModalComponentNgFactory };
