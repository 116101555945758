<span *ngIf="listing && listingStatus">
  <div>
    <ion-row>
      <ion-col size="12">
        <ion-button class="close-btn" fill="outline" color="primary" (click)="dismissAsDialog()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
  <!--Revise Listing -->
  <ion-row
    class="pointer"
    [ngClass]="{
      'no-cursor':
        (listingStatus.id !== 4 && listingStatus.id !== 6 && listingStatus.id !== 7) ||
        (listingStatus.id === 4 && listing?.listingStatus?.id === 1)
    }"
  >
    <div
      class="btn-styling pointer"
      (click)="reviseListing()"
      color="secondary"
      [ngClass]="{
        'disabled-div-color':
          (listingStatus.id !== 4 &&
            listingStatus.id !== 6 &&
            listingStatus.id !== 7 &&
            listingStatus.id !== 8 &&
            !isUserAllowToEditPendingApprovalListing()) ||
          (listingStatus.id === 4 && listing?.nextListingStatus?.id === 1)
      }"
    >
      <ion-label
        *ngIf="!isRegularUserEditingRevisionListing()"
        class="btn-heading"
        translate="global.my-list-menu.reviseListing"
      ></ion-label>
      <ion-label
        *ngIf="isRegularUserEditingRevisionListing()"
        class="btn-heading"
        translate="global.my-list-menu.openExistingRevision"
      ></ion-label>
      <span>
        <p *ngIf="listingStatus.id === 5" class="btn-descript" translate="global.my-list-menu.listingExpired"></p>
        <p *ngIf="listingStatus.id === 10" class="btn-descript" translate="global.my-list-menu.spotDeactivated"></p>
        <p
          *ngIf="listingStatus.id === 1 && !listing.priorListingId && !isUserAllowToEditPendingApprovalListing()"
          class="btn-descript"
          translate="global.my-list-menu.noIdApproval"
        ></p>
        <p
          *ngIf="
            (listingStatus.id === 4 && listing.priorListingId && listing.listingStatus.id === 1) ||
            (listingStatus.id === 1 && listing.priorListingId && !isUserAllowToEditPendingApprovalListing())
          "
          class="btn-descript"
          translate="global.my-list-menu.idApproval"
        ></p>
      </span>
      <span>
        <p
          *ngIf="
            (listingStatus.id === 4 && listing?.listingStatus?.id !== 1) ||
            listingStatus.id === 6 ||
            listingStatus.id === 7
          "
          class="btn-descript"
          translate="global.my-list-menu.reviseListingText"
        ></p>
      </span>
    </div>
  </ion-row>
  <!-- Submit For Approval -->
  <ion-row class="pointer" *ngIf="showSubmitForApprovalOption()">
    <div class="btn-styling" (click)="submitForApproval()" color="secondary">
      <ion-label class="btn-heading" translate="global.submitApproval"></ion-label>
    </div>
  </ion-row>
  <!-- Renew Listing -->
  <ion-row
    class="pointer"
    [ngClass]="{
      'no-cursor': spotAdmin || (listingStatus.id !== 4 && listingStatus.id !== 5 && listingStatus.id !== 6)
    }"
  >
    <div
      class="btn-styling"
      (click)="renewListing(listing.id)"
      color="secondary"
      [ngClass]="{
        'disabled-div-color': spotAdmin || (listingStatus.id !== 4 && listingStatus.id !== 5 && listingStatus.id !== 6)
      }"
    >
      <ion-label class="btn-heading" translate="global.my-list-menu.renewListing"></ion-label>
      <p
        class="btn-descript"
        translate="global.my-list-menu.renewBlock"
        *ngIf="listingStatus.id !== 4 && listingStatus.id !== 5 && listingStatus.id !== 6 && !spotAdmin"
      ></p>
      <p
        class="btn-descript"
        translate="global.my-list-menu.renewListingText"
        *ngIf="(listingStatus.id === 4 || listingStatus.id === 5 || listingStatus.id === 6) && !spotAdmin"
      ></p>
      <p class="btn-descript" translate="global.my-list-menu.renewListingTextSpotAdmin" *ngIf="spotAdmin"></p>
    </div>
  </ion-row>

  <ion-row class="pointer" *ngIf="listing?.listingStatus?.id === 7">
    <div class="btn-styling" color="secondary" (click)="discardDraft($event)">
      <ion-label class="btn-heading" translate="global.my-list-menu.discardDraft"></ion-label>
      <p class="btn-descript" translate="global.my-list-menu.discardDraftText"></p>
    </div>
  </ion-row>

  <ion-row class="pointer" *ngIf="listing?.listingStatus.id === 8">
    <div class="btn-styling" color="secondary" (click)="discardDraft($event)">
      <ion-label class="btn-heading" translate="global.my-list-menu.discardRevision"></ion-label>
      <p class="btn-descript" translate="global.my-list-menu.discardRevisionText"></p>
    </div>
  </ion-row>

  <ion-row class="pointer" *ngIf="listing.listingStatus.id === 6">
    <div
      class="btn-styling"
      color="secondary"
      (click)="reactivateListing($event)"
      [ngClass]="{
        'disabled-div-color': listing.listingType && listing.listingType == 'BASIC'
      }"
    >
      <ion-label class="btn-heading">{{ 'global.reActivateListing' | translate }}</ion-label>
      <p class="btn-descript">{{ 'global.reActivateListingDesc' | translate }}</p>
    </div>
  </ion-row>

  <ion-row class="pointer" *ngIf="listing.listingStatus.id === 4">
    <div class="btn-styling" color="secondary" (click)="deactivateListing($event)">
      <ion-label class="btn-heading">{{ 'global.deActivateListing' | translate }}</ion-label>
      <p class="btn-descript">{{ 'global.deActivateListingDesc' | translate }}</p>
    </div>
  </ion-row>

  <ion-row class="pointer" *ngIf="spotAdmin">
    <div class="btn-styling" color="secondary" (click)="adminUpdates($event)">
      <ion-label class="btn-heading" translate="global.my-list-menu.adminUpdates"></ion-label>
      <p class="btn-descript" translate="global.my-list-menu.adminUpdatesText"></p>
    </div>
  </ion-row>
</span>
