<ion-content #contentBlock>
  <!-- <ion-row>
    <ion-col size="12">
      <ion-icon (click)="goBack()" class="signup-icon" color="primary" name="arrow-back"></ion-icon>
    </ion-col>
  </ion-row> -->
  <app-breadcrumb inViewport [inViewportOptions]="{ thredhold: [0] }" [options]="breadcrumbOptions"> </app-breadcrumb>
  <div class="detail-block-background">
    <ion-card
      inViewport
      [inViewportOptions]="{ threshold: [0] }"
      (inViewportAction)="isHeaderVisible($event)"
      class="card-bg-color max-width-800"
      color="light"
    >
      <ion-row class="img-block">
        <ion-col class="flex-row" *ngIf="!broker?.companyLogoId && broker?.profilePhotoImageId" size="12">
          <img class="img-broker-height" [src]="getMediumImg(broker?.profilePhotoImageId)" alt="avatar" />
        </ion-col>

        <ion-col class="flex-row" *ngIf="broker?.companyLogoId && !broker?.profilePhotoImageId" size="12">
          <img class="img-company-height" [src]="getMediumImg(broker?.companyLogoId)" alt="avatar" />
        </ion-col>

        <ion-col class="flex-col" *ngIf="broker?.companyLogoId && broker?.profilePhotoImageId" size="6">
          <img class="img-broker-height" [src]="getMediumImg(broker?.profilePhotoImageId)" alt="avatar" />
        </ion-col>

        <ion-col class="flex-col" *ngIf="broker?.companyLogoId && broker?.profilePhotoImageId" size="6">
          <img class="img-company-height" [src]="getMediumImg(broker?.companyLogoId)" alt="avatar" />
        </ion-col>

        <ion-row>
          <ion-col class="flex-row" size="12">
            <p class="broker-name">{{ broker?.fullName }}</p>
          </ion-col>
          <ion-col class="flex-row" size="12">
            <p class="broker-name-2">{{ broker?.companyName }}</p>
          </ion-col>

          <ion-col class="flex-row" size="12">
            <p class="job-title">{{ broker?.jobTitle }}</p>
          </ion-col>
        </ion-row>
      </ion-row>

      <div>
        <ion-row class="broker-detail-row" style="margin-top: 20px;" *ngIf="broker?.phoneNumber">
          <div class="listing-user-details">
            <div class="width-25">
              <ion-icon class="siila-color margin-bottom-2-n" name="call"></ion-icon>
            </div>
            <div class="listing-user-details">
              <p class="text-detail">
                <span *ngIf="!brokerPhone" class="list-show info-detail padding-left-10" (click)="showBrokerPhone()">
                  {{ 'global.listing-detail.showPhoneNumber' | translate }}
                </span>
                <span
                  class="padding-left-10"
                  *ngIf="brokerPhone"
                  class="list-show-reveal border-bottom-unset info-detail"
                >
                  <a href="tel:{{ broker?.phoneNumber }}" class="add-padding">{{ broker?.phoneNumber }}</a>
                </span>
              </p>
            </div>
          </div>
        </ion-row>

        <ion-row class="broker-detail-row" *ngIf="broker?.whatsappNumber">
          <div class="listing-user-details">
            <div class="width-25">
              <ion-icon class="wa-icon-color margin-bottom-2-n" name="logo-whatsapp"></ion-icon>
            </div>
            <div class="listing-user-details">
              <p class="text-detail">
                <span
                  *ngIf="!whatsappPhone"
                  class="list-show info-detail padding-left-10"
                  (click)="showBrokerWhatsapp()"
                >
                  {{ 'global.listing-detail.showWhataspp' | translate }}
                </span>
                <span
                  class="padding-left-10"
                  *ngIf="whatsappPhone"
                  class="list-show-reveal border-bottom-unset info-detail"
                >
                  <span class="add-padding">{{ broker?.whatsappNumber }}</span>
                </span>
              </p>
            </div>
          </div>
        </ion-row>

        <ion-row class="broker-detail-sub-row" *ngIf="whatsappPhone">
          <span class="click-to-reveal padding-left-30">
            <a
              role="button"
              (click)="clickToChat()"
              translate="global.broker-detail.clickToChat.name"
              class="add-padding"
            >
            </a>
          </span>
        </ion-row>

        <ion-row class="broker-detail-row">
          <div class="listing-user-details">
            <div class="width-25">
              <ion-icon class="siila-color margin-bottom-2-n" name="mail"></ion-icon>
            </div>
            <div class="listing-user-details">
              <p class="text-detail">
                <span *ngIf="!brokerEmail" class="list-show info-detail padding-left-10" (click)="showBrokerEmail()">
                  {{ 'global.listing-detail.showEmail' | translate }}
                </span>
                <span class="padding-left-10 click-to-black border-bottom-unset info-detail" *ngIf="brokerEmail">
                  <span class="ow-break-word">{{ broker?.email }}</span>
                </span>
              </p>
            </div>
          </div>
        </ion-row>

        <ion-row class="broker-detail-sub-row" *ngIf="brokerEmail">
          <span class="click-to-reveal padding-left-30">
            <a role="button" (click)="clickToEmail($event)" translate="global.listing-detail.clickToEmail.name"> </a>
          </span>
        </ion-row>

        <ion-row class="bio-padding" *ngIf="!loading && broker?.biography">
          <p
            id="#bio"
            class="init-overflow unset-overflow"
            style="text-align: left;"
            localize
            [textValue]="broker?.biography"
          ></p>
        </ion-row>
      </div>
    </ion-card>
  </div>
  <ion-row class="listing-heading" *ngIf="listingIds?.length > 0">
    <p class="listing-text">
      {{ 'global.menu.entities.listing' | translate }}
      <span>({{ listingIds?.length }})</span>
    </p>
  </ion-row>

  <div class="search-results mobile-listing-view" [ngClass]="{ 'desktop-listing-view': !isMobile }">
    <div class="mobile-width" *ngFor="let listing of listings">
      <app-listing-card class="center" [lazyLoadImages]="true" [activeListingIds]="listingIds" [listing]="listing">
      </app-listing-card>
    </div>
  </div>
  <div class="center">
    <ion-button fill="clear" *ngIf="showMoreIcon && !loading" (click)="getMoreListings()">
      <ion-icon class="dismiss-icon" name="ios-arrow-down"></ion-icon>
      <ion-text class="text-font"> {{ 'global.form.moreResults' | translate }}</ion-text>
    </ion-button>
    <span *ngIf="loading">
      <ion-spinner name="dots" color="primary"></ion-spinner>
    </span>
  </div>
  <app-scroll-to-top (scroll)="contentBlock.scrollToTop()" [showComponent]="displayScrollToTop"></app-scroll-to-top>
</ion-content>
