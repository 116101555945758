import { Injectable } from '@angular/core';
import { SelectedFilterKey } from '../pages/listing-search/filter-selected-keys';

@Injectable()
export class ListingSearchService {
  favoritesFilterSelected: boolean = false;

  restoreBackSelectedFiltersFrom(selectedFilterKey: SelectedFilterKey, account: any) {
    const defaultListingStatus = [4]; // active
    const { id, companyId } = account;
    const defaultDTO = {
      offeredByUserId: id,
      companyId: companyId,
      listingStatuses: defaultListingStatus
    };
    if (this.checkRole(account, 'ROLE_SPOT_ADMIN')) {
      defaultDTO.offeredByUserId = null;
      defaultDTO.companyId = null;
    } else if (this.checkRole(account, 'ROLE_LISTING_ADMIN')) {
      defaultDTO.offeredByUserId = null;
    }
    localStorage.setItem(selectedFilterKey, JSON.stringify(defaultDTO));
  }

  readFilterPreferencesFrom(selectedFilterKey: SelectedFilterKey) {
    return localStorage.getItem(selectedFilterKey);
  }

  removeFilterPreferencesFrom(selectedFilterKey: SelectedFilterKey) {
    localStorage.removeItem(selectedFilterKey);
  }

  readSearchDTOFromStorage(itemName: SelectedFilterKey, defaultValue: any) {
    const searchDtoItem = itemName;
    const filterPreferencesDTO = this.readFilterPreferencesFrom(searchDtoItem);

    if (filterPreferencesDTO && filterPreferencesDTO.length) {
      return JSON.parse(filterPreferencesDTO);
    }
    return defaultValue;
  }

  readLastSearchPageFromStorage(): SelectedFilterKey {
    const lastSearchPage = localStorage.getItem('lastSearchPage');
    if (lastSearchPage && lastSearchPage == SelectedFilterKey.MY_LISTINGS) {
      return SelectedFilterKey.MY_LISTINGS;
    } else if (lastSearchPage && lastSearchPage == SelectedFilterKey.LISTING_ANALYTICS) {
      return SelectedFilterKey.LISTING_ANALYTICS;
    } else if (lastSearchPage && lastSearchPage == SelectedFilterKey.FAVORITES_SEARCH) {
      return SelectedFilterKey.FAVORITES_SEARCH;
    }
    return SelectedFilterKey.REGULAR_SEARCH;
  }

  saveLastSeenSearchPage(itemName: SelectedFilterKey) {
    localStorage.setItem('lastSearchPage', itemName);
  }

  saveSearchDtoOnStorage(itemName: SelectedFilterKey, listingSearchDto: any) {
    const listingSearchDtoCopy = { ...listingSearchDto };
    listingSearchDtoCopy.spotsNearMe = null;
    const searchDto = JSON.stringify(listingSearchDtoCopy);
    localStorage.setItem(itemName, searchDto);
  }

  findLocalStorageKeyFromSelectedFilters({
    myListingAnalytics,
    myListingPage,
    favoritesPage
  }: {
    myListingAnalytics?: boolean;
    myListingPage?: boolean;
    favoritesPage?: boolean;
  }): SelectedFilterKey {
    let searchDtoItem = SelectedFilterKey.REGULAR_SEARCH;
    if (myListingAnalytics) {
      searchDtoItem = SelectedFilterKey.LISTING_ANALYTICS;
    } else if (myListingPage) {
      searchDtoItem = SelectedFilterKey.MY_LISTINGS;
    } else if (favoritesPage) {
      searchDtoItem = SelectedFilterKey.FAVORITES_SEARCH;
    }
    return searchDtoItem;
  }

  private checkRole(user: any, role: string) {
    if (user) {
      if (user.roles.includes(role)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public findCoworkingTypeFromSearch() {
    let defaultCoworkingType = null;
    const selectedFilterKey = this.readLastSearchPageFromStorage();
    if (selectedFilterKey) {
      const searchDTO = this.readSearchDTOFromStorage(selectedFilterKey, {});
      if (searchDTO && searchDTO.coworkingType) {
        defaultCoworkingType = searchDTO.coworkingType;
      }
    }
    return defaultCoworkingType;
  }
}
