import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CartService = /** @class */ (function () {
    function CartService(http) {
        this.http = http;
    }
    CartService.prototype.loadCart = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.prototype.saveCart = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.prototype.updateCart = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.prototype.deleteCart = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.prototype.addItem = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.prototype.removeItem = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.prototype.updateItem = function () {
        return this.http.get('/api/id/carts');
    };
    CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.HttpClient)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
