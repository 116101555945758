import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

function isObjectEmpty(object: any) {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}

export function initSelectionControl(): any {
  return {
    english: false,
    spanish: false,
    portuguese: false
  };
}

export function addFormGroup(fb: FormBuilder, formControlName: string, validator?: any[], value?: any): FormGroup {
  const validators: any[] = validator && validator.length ? validator : [];
  return fb.group({
    [formControlName]: new FormControl(value ? value : '', validators)
  });
}

export function selectLanguageValue(): any {
  return {
    selectEnglish: 'en',
    selectSpanish: 'es',
    selectPortuguese: 'pt-br'
  };
}

export function setFormControlValue(formControlName: string, formData: any): any {
  return formData[formControlName];
}

export function hasOwnProperty(propName: any, formData: any) {
  const isEmptyFormArray: boolean = isObjectEmpty(formData);
  return !isEmptyFormArray ? formData.hasOwnProperty(propName) : false;
}

export function saveTemporaryFormData(currentTempData: any, formValue: any, propName: string, groupName: string) {
  let formData: any = Object.assign({}, currentTempData);
  formValue[groupName][propName].forEach((item: any) => {
    Object.keys(item).forEach(key => {
      Object.assign(formData, { [key]: item[key] });
    });
  });
  return formData;
}
