import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable()
export class ListingModalService {
  backButton: any;
  constructor(private modalCtrl: ModalController, private router: Router, private platform: Platform) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  async presentModal(modalOptions: any, callback?: any) {
    const modal = await this.modalCtrl.create(modalOptions);

    modal.onDidDismiss().then(() => {
      if (callback) {
        callback();
      }
    });
    return await modal.present();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
