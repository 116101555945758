import * as tslib_1 from "tslib";
var ClassHelperService = /** @class */ (function () {
    function ClassHelperService() {
        this.classes = [
            { name: 'FEATURED', id: 0 },
            { name: 'PREMIUM', id: 1 },
            { name: 'PROMOTED', id: 2 },
            { name: 'BASIC', id: 3 }
        ];
    }
    ClassHelperService.prototype.getClassIds = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var arr;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                arr = [];
                Object.keys(this.selectClass).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        arr.push(this.selectClass[key].id);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, arr];
            });
        });
    };
    ClassHelperService.prototype.updateSelectedClassesBasedOnDTO = function (listingSearchDto) {
        var _this = this;
        this.selectClass = [];
        if (listingSearchDto.listingClasses) {
            if (listingSearchDto.listingClasses.length > 0) {
                listingSearchDto.listingClasses.forEach(function (classTag) {
                    var classFound = _this.classes.find(function (cl) { return cl.id == classTag; });
                    if (classFound) {
                        _this.selectClass.push(classFound);
                    }
                });
            }
        }
    };
    ClassHelperService.prototype.removeClass = function (listingClass) {
        var _this = this;
        this.selectClass.forEach(function (item, index) {
            if (item.id == listingClass.id) {
                _this.selectClass.splice(index, 1);
                return;
            }
        });
    };
    return ClassHelperService;
}());
export { ClassHelperService };
