import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { ReportSpacesComponent } from '@app/spot-buildings/components/detail/report-spaces/report-spaces.component';
import { SetSpacesComponent } from '@app/spot-buildings/components/detail/set-spaces/set-spaces.component';
import { faTrash, faEllipsisV, faEdit, faBookOpen, faSearch, faFilter, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { ToastController } from '@ionic/angular';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
var SpotSetsComponent = /** @class */ (function () {
    function SpotSetsComponent(favoriteServices, i18nService, dialog, toastCtrl, messageFormDialog, breakpointObserver, router, routingHelperSrv) {
        var _this = this;
        this.favoriteServices = favoriteServices;
        this.i18nService = i18nService;
        this.dialog = dialog;
        this.toastCtrl = toastCtrl;
        this.messageFormDialog = messageFormDialog;
        this.breakpointObserver = breakpointObserver;
        this.router = router;
        this.routingHelperSrv = routingHelperSrv;
        this.eventSpotSet = new EventEmitter();
        this.pageSize = 10;
        this.page = 0;
        this.collection = 0;
        this.seeSearch = false;
        this.isMobile = false;
        this.filterCount = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    SpotSetsComponent.prototype.ngOnInit = function () {
        this.trash = faTrash;
        this.ellipsisV = faEllipsisV;
        this.edit = faEdit;
        this.bookOpen = faBookOpen;
        this.search = faSearch;
        this.filter = faFilter;
        this.arrowDown = faArrowCircleDown;
        this.getSpotSets(0);
    };
    SpotSetsComponent.prototype.getSpotSets = function (page) {
        var _this = this;
        this.page = page;
        var lang = this.i18nService.getCurrentLanguage();
        var bodySpotSet = {
            sortBy: this.param,
            direction: this.direction,
            titleFilter: this.filterSpotSet
        };
        this.favoriteServices.getSpotSetFavorite(page, lang, bodySpotSet).then(function (value) {
            _this.spotSetList = value;
            _this.collection = _this.spotSetList.totalElements;
            _this.pageSize = _this.spotSetList.size;
            _this.eventSpotSet.emit(_this.spotSetList.totalElements);
        });
    };
    SpotSetsComponent.prototype.changePage = function (page) {
        this.getSpotSets(page - 1);
    };
    SpotSetsComponent.prototype.searchProperty = function () {
        this.seeSearch = !this.seeSearch;
    };
    SpotSetsComponent.prototype.searchFilter = function () {
        var _this = this;
        if (this.filterCount == 0) {
            this.filterCount = 1;
            setTimeout(function () {
                _this.getSpotSets(0);
                _this.filterCount = 0;
            }, 2000);
        }
    };
    SpotSetsComponent.prototype.editSet = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(SetSpacesComponent, {
            width: this.isMobile ? '100%' : '600px',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                title: true,
                id: item.id,
                name: item.name
            }
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.getSpotSets(0);
                _this.presentToast(val);
            }
        });
    };
    SpotSetsComponent.prototype.createTourBook = function (item) {
        var dialogRef = this.dialog.open(ReportSpacesComponent, {
            width: this.isMobile ? '100%' : '60%',
            maxWidth: this.isMobile ? '100%' : '',
            height: this.isMobile ? '100%' : '',
            data: {
                ids: item.spaceIds,
                name: item.name
            }
        });
    };
    SpotSetsComponent.prototype.deleteSet = function (id) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: {
                message: this.i18nService.get('favorites.spotSets.deleteSet'),
                id: id,
                apiName: '/api/spotset/'
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.presentToast(_this.i18nService.get('favorites.deleteMessage'));
                _this.getSpotSets(0);
            }
        });
    };
    SpotSetsComponent.prototype.nameAscending = function () {
        this.param = 'name';
        this.direction = 'ASC';
        this.getSpotSets(0);
    };
    SpotSetsComponent.prototype.nameDescending = function () {
        this.param = 'name';
        this.direction = 'DESC';
        this.getSpotSets(0);
    };
    SpotSetsComponent.prototype.oldestFirst = function () {
        this.param = 'date';
        this.direction = 'ASC';
        this.getSpotSets(0);
    };
    SpotSetsComponent.prototype.newestFirst = function () {
        this.param = 'date';
        this.direction = 'DESC';
        this.getSpotSets(0);
    };
    SpotSetsComponent.prototype.presentToast = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert-detail'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpotSetsComponent.prototype.propertiesText = function (message) {
        this.messageFormDialog.openDialogMessage(message);
    };
    SpotSetsComponent.prototype.goBrowseProperties = function () {
        this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
    };
    SpotSetsComponent.prototype.goToSpotSetSearch = function (item) {
        this.routingHelperSrv.navigateToSpotSetSearchPage(item);
    };
    return SpotSetsComponent;
}());
export { SpotSetsComponent };
