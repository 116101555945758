import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class ListingCarouselService {
  private host: string = '/api/listing-carousel-data';
  private advertisements: string = 'api/service-provider/carousel-data';
  private numbadvertisements: string = 'api/service-provider/carousel-data?size=';

  constructor(private http: HttpClient) {}

  getCarouselByType(carouselType: string) {
    return this.http.get(`${this.host}/${carouselType}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getAdvertisements(size?: number, listingId?: number) {
    if (size) {
      return this.http
        .get(`${this.numbadvertisements}${size}` + (listingId ? `&listingId=${listingId}` : ''), {
          observe: 'response'
        })
        .pipe(map(val => val.body));
    } else {
      return this.http
        .get(`${this.advertisements}` + (listingId ? `&listingId=${listingId}` : ''), { observe: 'response' })
        .pipe(map(val => val.body));
    }
  }
}
