<ion-content #contentBlock>
  <span inViewport [inViewportOptions]="{ threshold: [0] }" (inViewportAction)="isHeaderVisible($event)"></span>
  <div class="main-content vertical-center">
    <ion-row
      ><ion-col
        ><div>{{ 'notfound.pageDoestNotExist' | translate }}</div></ion-col
      ></ion-row
    >
    <ion-row
      ><ion-col
        ><ion-label class="invalid-url">{{ wrongRoute }}</ion-label></ion-col
      ></ion-row
    >
    <ion-row>
      <ion-col size="12">
        <a [routerLink]="navigateToHomePage()">
          <ion-label translate="notfound.returnHomepage" class="pointer-cursor"></ion-label>
        </a>
        <a [routerLink]="navigateToSearchPage()">
          <ion-label translate="notfound.returnSearchListings" class="pointer-cursor"></ion-label>
        </a>
      </ion-col>
    </ion-row>
  </div>
  <app-scroll-to-top
    [showComponent]="displayScrollToTop"
    (scroll)="contentBlock.scrollToTop()"
    [target]="contentBlock"
    scroller
  >
  </app-scroll-to-top>
  <app-footer></app-footer>
</ion-content>
