import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavParams, IonInput } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { RangeSearchFiltersMeta, MinMaxSearchFiltersMeta } from '../../../models/office-search-filters.model';
import { ListPropertyService } from '@app/core/listings/list-property.service';
import { I18nService } from '@app/core';

@Component({
  selector: 'coworking-page',
  templateUrl: './coworking-popover.component.html',
  styleUrls: ['./coworking-popover.component.scss']
})
export class CoworkingPopoverComponent implements OnInit {
  propertyType: any;
  expandFilters: any;
  rangeFilters: any;
  btnFilter: boolean = false;
  minMaxvalues: any;
  rangeModelValues: any;
  staticMinMax: any;
  searchDto: any;
  loading: boolean = true;
  propertySubTypes: any = [];
  buildingType: any;
  coworkingType: any;
  coworkingPositions: any;
  selectedPropertyType: any;
  popDataCalled: boolean = false;
  @ViewChild('posInput', { static: false }) posInput: IonInput;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private listingService: ListPropertyService,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    this.getPopData();
    //this.setMinOptions(this.rangeFilters, this.searchDto);
  }

  coworkingTypeChanged(event: CustomEvent) {
    if (this.popDataCalled) {
      this.popDataCalled = false;
      return;
    }
    this.coworkingType = event.detail.value;
  }

  getPopData() {
    this.popDataCalled = true;
    if (
      this.navParams.data.searchDto.coworkingType == undefined ||
      this.navParams.data.searchDto.coworkingType == 'null' ||
      this.navParams.data.searchDto.coworkingType === ''
    ) {
      this.coworkingType = 'shared';
    } else {
      this.coworkingType = this.navParams.data.searchDto.coworkingType;
    }

    if (
      this.navParams.data.searchDto.coworkingPositions == undefined ||
      this.navParams.data.searchDto.coworkingPositions === ''
    ) {
      this.coworkingPositions = 1;
    } else {
      this.coworkingPositions = this.navParams.data.searchDto.coworkingPositions;
    }

    this.searchDto = this.navParams.data.searchDto;
    this.loading = false;
  }

  update() {
    let searchCopyDto = { ...this.searchDto };
    /*
    if (searchCopyDto.propertySubTypes) {
      searchCopyDto.propertySubtypeIds = searchCopyDto.propertySubTypes.map((t: any) => t.id);
    } else {
      searchCopyDto.propertySubtypeIds = null;
    }*/
    if (this.coworkingType && this.coworkingType != 'null') {
      searchCopyDto.coworkingType = this.coworkingType;
    } else {
      searchCopyDto.coworkingType = null;
    }
    searchCopyDto.coworkingPositions = this.coworkingPositions;

    this.popoverCtrl.dismiss(searchCopyDto);
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  clearSearch() {
    this.searchDto = this.listingService.clearFilters(this.searchDto, null);
    this.rangeModelValues = this.listingService.mapMinMaxOptions(null, this.staticMinMax);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }
}
