import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Injectable()
export class MessageModalService {
  private dialogRef: any;

  constructor(private dialog: MatDialog) {}

  openDialogMessage(message: string) {
    this.dialogRef = this.dialog.open(MessageModalComponent, {
      width: '500px',
      data: message,
      autoFocus: true,
      restoreFocus: true
    });
  }
}
