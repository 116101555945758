import { PipeTransform, Pipe, Injectable } from '@angular/core';
import { SpotBuildingsUtilService } from '../../spot-buildings/util/spot-buildings-util.service';

@Injectable()
@Pipe({ name: 'customHighlight' })
export class CustomHighlightPipe implements PipeTransform {
  constructor(private UtilSvc: SpotBuildingsUtilService) {}

  transform(text: string, search: string, highlightClass: string = ''): string {
    if (!search) return text;
    const pattern = this.UtilSvc.scapeRegexCharacters(search);
    var regex = new RegExp(pattern, 'gi');
    const normalizedText = this.UtilSvc.normalize(text);

    let match = regex.exec(normalizedText);
    if (!match) return text;
    let result = '';
    let indexPointer: number = 0;

    while (match != null) {
      const firstPiece = text.slice(indexPointer, match.index);
      indexPointer = match.index + match[0].length;
      const toBeHighlighted = text.slice(match.index, indexPointer);
      result += `${firstPiece}${this.UtilSvc.highlightWrap(toBeHighlighted, highlightClass)}`;
      match = regex.exec(normalizedText);
    }
    result += text.slice(indexPointer);
    return result;
  }
}
