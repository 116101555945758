import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile-modal.component.html',
  styleUrls: ['./complete-profile-modal.component.scss']
})
export class CompleteProfileComponent {
  constructor(private cpDialogRef: MatDialogRef<CompleteProfileComponent>, private router: Router) {}

  goToMyAccount() {
    this.router.navigate(['/user-home/my-account']);
    this.cpDialogRef.close();
  }

  closeModal() {
    this.cpDialogRef.close();
  }
}
