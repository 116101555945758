/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-policy-review.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../privacy-policy/privacy-policy.component.ngfactory";
import * as i4 from "../../privacy-policy/privacy-policy.component";
import * as i5 from "../../../core/i18n.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i8 from "@ionic/angular";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./privacy-policy-review.component";
var styles_PrivacyPolicyReviewComponent = [i0.styles];
var RenderType_PrivacyPolicyReviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyPolicyReviewComponent, data: {} });
export { RenderType_PrivacyPolicyReviewComponent as RenderType_PrivacyPolicyReviewComponent };
export function View_PrivacyPolicyReviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "privacy-policy-review"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "privacy-policy-review__policy-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "privacy-policy", [], null, null, null, i3.View_PrivacyPolicyComponent_0, i3.RenderType_PrivacyPolicyComponent)), i1.ɵdid(6, 114688, null, 0, i4.PrivacyPolicyComponent, [i5.I18nService], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(9, 0, null, null, 8, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "ion-button", [["color", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePpReview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_IonButton_0, i7.RenderType_IonButton)), i1.ɵdid(13, 49152, null, 0, i8.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(14, 0, ["\n      ", "\n    "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(16, 1), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 6, 0); var currVal_0 = "primary"; _ck(_v, 13, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 14, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("global.privacy-policy.dialog.close")))); _ck(_v, 14, 0, currVal_1); }); }
export function View_PrivacyPolicyReviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "privacy-policy-review", [], null, null, null, View_PrivacyPolicyReviewComponent_0, RenderType_PrivacyPolicyReviewComponent)), i1.ɵdid(1, 49152, null, 0, i10.PrivacyPolicyReviewComponent, [i6.MatDialogRef], null, null)], null, null); }
var PrivacyPolicyReviewComponentNgFactory = i1.ɵccf("privacy-policy-review", i10.PrivacyPolicyReviewComponent, View_PrivacyPolicyReviewComponent_Host_0, {}, {}, []);
export { PrivacyPolicyReviewComponentNgFactory as PrivacyPolicyReviewComponentNgFactory };
