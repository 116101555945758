import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateListingService, ListingDetailService } from '@app/core/listings';
import { CommonGenericService } from './common.service';
import { map } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { AlertController } from '@ionic/angular';
import { I18nService } from './i18n.service';
import { Router } from '@angular/router';
import { ListingOfferType } from '../models/transaction-type.model';

@Injectable()
export class RegisterListingService {
  listing: any = {};
  newImgList: any = [];
  building: any;
  tags: any;
  previewListing: any;
  currencyUom: any;
  areaUOM: any;
  currencyAreaUom: any;
  areaUOMString: any = 'api/areaUOMs';
  currencyAreaStringUOM: any = 'api/currencyAreaUOMs';
  currencyStringUOM: any = 'api/currencyUOMs';
  createUpdateListing: any = 'api/listings';
  apiImages: string = 'api/images';
  createImageAPI: any = 'api/listingPhotos/create-image';
  listingPhotos: any = 'api/listingPhotos/';
  associatePhotosToListingAPI = 'api/listingPhotos/listing/';
  copyPhotosToListingAPI = 'api/listingPhotos/copy/to/listing/';
  previewPhotos: any;
  spotAdmin: any;
  constructor(
    private http: HttpClient,
    private listingDetailService: ListingDetailService,
    private createService: CreateListingService,
    private commonService: CommonGenericService,
    private langService: LanguageService,
    private alertCtrl: AlertController,
    private i18NService: I18nService,
    private router: Router
  ) {
    //this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
  }

  async getAreaUom() {
    return this.http
      .get(`${this.areaUOMString}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async getCurrencyAreaUom() {
    return this.http
      .get(`${this.currencyAreaStringUOM}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async getCurrencyUOM() {
    return this.http
      .get(`${this.currencyStringUOM}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  checkRole(role: string) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles ? user.roles.includes(role) : false;
    } catch (e) {
      console.error('roles undefined --> ', e);
    }
  }

  async uploadPhoto(
    file: any,
    caption: any,
    listingId: any,
    photoOrder: any,
    content: any,
    croppedFile: any,
    originalImageId: any
  ) {
    const formData = new FormData();
    formData.append('photoOrder', photoOrder);
    formData.append('listingId', listingId);
    formData.append('caption', caption);
    formData.append('file', file);
    formData.append('content', content);
    formData.append('croppedFile', croppedFile);

    if (originalImageId && originalImageId != null) {
      formData.append('originalImageId', originalImageId);
    }
    return this.http
      .post(`${this.createImageAPI}`, formData)
      .pipe(map(val => val))
      .toPromise();
  }

  async uploadNewImage(file: any, caption: any) {
    const formData = new FormData();
    formData.append('caption', caption);
    formData.append('file', file);

    return this.http
      .post(`${this.apiImages}`, formData)
      .pipe(map(val => val))
      .toPromise();
  }

  async deleteListingPhoto(id: any) {
    return this.http.delete(`${this.listingPhotos}${id}`).toPromise();
  }

  async updateListingPhoto(listingPhoto: any) {
    return this.http
      .put(`${this.listingPhotos}`, listingPhoto)
      .pipe(map(val => val))
      .toPromise();
  }

  associatePhotosToListing(listingId: any, imgList: any) {
    if (!listingId) {
      console.error('No Listing ID Found');
      return imgList;
    }

    let listingPhotos = imgList.map((photo: any) => {
      photo.listingId = listingId;
      return photo;
    });

    const photoIds = listingPhotos.map((img: any) => img.id);
    this.http
      .put(`${this.associatePhotosToListingAPI}${listingId}`, photoIds)
      .pipe(map(val => val))
      .toPromise();

    return listingPhotos;
  }

  async updateToApproval(listing: any) {
    listing.listingStatus = { id: 1 };
    return this.http.put(`${this.createUpdateListing}`, listing).toPromise();
  }

  async adminSaveListing(listing: any) {
    return this.http.put(`${this.createUpdateListing}`, listing).toPromise();
  }

  async uploadImages(imgList: any, previewListing: any) {
    let photos: any = [];
    let croppedSavedImage: any = null;
    Object.keys(imgList).forEach(async (key, idx) => {
      // Existing image into database
      if (imgList[key].image) {
        const listingPhoto = imgList[key];
        croppedSavedImage = await this.createCroppedImage(listingPhoto);
        const newListingPhoto: any = {
          orderId: idx + 1,
          id: listingPhoto.id,
          image: listingPhoto.image,
          originalImage: listingPhoto.originalImage,
          listingId: listingPhoto.listingId,
          croppedImage: croppedSavedImage,
          createdDate: new Date()
        };
        let upload = await this.updateListingPhoto(newListingPhoto);
        photos.push(upload);
      } else {
        let img = await this.commonService.convertImageBase64ToFile(imgList[key].img, imgList[key].content);
        const croppedImage = imgList[key].croppedImage
          ? await this.commonService.convertImageBase64ToFile(imgList[key].croppedImage, imgList[key].content)
          : null;
        let upload = await this.uploadPhoto(
          img,
          imgList[key].caption,
          previewListing.id,
          idx + 1,
          imgList[key].content,
          croppedImage,
          imgList[key].originalImageId
        );
        photos.push(upload);
      }
    });
    return photos;
  }

  clearTags(dto: any) {
    if (dto.refrigerated) dto.refrigerated = false;
    if (dto.sluiceGate) dto.sluiceGate = false;
    if (dto.hasLockerRooms) dto.hasLockerRooms = false;
    //if (dto.hasCeilingHeight) dto.hasCeilingHeight = false;
    if (dto.heliport) dto.heliport = false;
    if (dto.sprinklers) dto.sprinklers = false;
    if (dto.electricGenerator) dto.electricGenerator = false;
    //if (dto.hasParkingSpaces) dto.hasParkingSpaces = false;
    if (dto.plugAndPlay) dto.plugAndPlay = false;
    //if (dto.finishedOut) dto.finishedOut = false;
    if (dto.furnished) dto.furnished = false;
    if (dto.coreAndShel) dto.coreAndShel = false;
    if (dto.centralAirConditioning) dto.centralAirConditioning = false;
    if (dto.miniSplitAirConditioning) dto.miniSplitAirConditioning = false;
    if (dto.bicycleRack) dto.bicycleRack = false;
    if (dto.leedStatus) dto.leedStatus = false;
    if (dto.raisedFloor) dto.raisedFloor = false;
    if (dto.hourSecurity) dto.hourSecurity = false;
    if (dto.neverUsed) dto.neverUsed = false;
    if (dto.hasRestrooms) dto.hasRestrooms = false;
    if (dto.armoredCabin) dto.armoredCabin = false;
    if (dto.manufacturing) dto.manufacturing = false;
    //if (dto.logistics) dto.logistics = false;
    if (dto.officeSpaceAvailable) dto.officeSpaceAvailable = false;
    //if (dto.fireProtectionSystem) dto.fireProtectionSystem = false;
    if (dto.blockConstruction) dto.blockConstruction = false;
    if (dto.steelConstruction) dto.steelConstruction = false;
    if (dto.blockAndSteelConstruction) dto.blockAndSteelConstruction = false;
    //if (dto.hasLoadCapacity) dto.hasLoadCapacity = false;
    //if (dto.hasDockRatio) dto.hasDockRatio = false;
    //if (dto.hasCarParkingSpaces) dto.hasCarParkingSpaces = false;
    if (dto.singleTenant) dto.singleTenant = false;
    if (dto.multiTenant) dto.multiTenant = false;
    //if (dto.hasTruckParkingSpaces) dto.hasTruckParkingSpaces = false;
    //if (dto.industrialPark) dto.industrialPark = false;
    //if (dto.industrialZone) dto.industrialZone = false;
    if (dto.truckYard) dto.truckYard = false;
    if (dto.crossDocking) dto.crossDocking = false;
    if (dto.twentyFourHourSecurity) dto.twentyFourHourSecurity = false;
    if (dto.coreAndShel) dto.coreAndShel = false;
    //if (dto.hasBaySize) dto.hasBaySize = false;
  }

  resetFeaturesToExistingListing(features: any[]) {
    features.map((t: any) => {
      t.selected = false;
      return t;
    });
    const previousFeatures = this.createService.checkFields(features);
    this.listing = Object.assign({}, this.listing, previousFeatures);
  }

  async formBuildingListing(
    form: any,
    offeredByUserId: any,
    created: any,
    listingPurchaseId: any,
    subTypes: any,
    listingType: any,
    tags?: any,
    imgList?: any,
    previewListing?: any,
    revise?: any
  ) {
    this.listing = {};
    let user = JSON.parse(localStorage.getItem('user'));
    this.areaUOM = await this.getAreaUom();
    this.currencyAreaUom = await this.getCurrencyAreaUom();
    this.currencyUom = await this.getCurrencyUOM();
    this.listing.leaseableAreaUOM = this.areaUOM[0];
    this.listing.rentableAreaUOM = this.areaUOM[0];
    this.listing.offeringPriceUOM = this.currencyUom[0];
    this.listing.monthlyRentUOM = this.currencyUom[0];
    this.listing.offeringPricePerAreaUOM = this.currencyAreaUom[0];
    this.listing.monthlyRentPerAreaUOM = this.currencyAreaUom[0];
    //this.clearTags(this.listing);
    created ? this.clearTags(this.previewListing) : null;

    if (tags) {
      const originalTags = { ...tags };
      form.commericalType == 1001 ? (tags = tags.industrial) : (tags = tags.office);
      // Set false to all features from the other property type. (In case it has changed)
      let newTags: any = [];
      if (form.commericalType == 1001 && originalTags) {
        this.resetFeaturesToExistingListing(originalTags.office);
      } else if (form.commericalType == 2001) {
        this.resetFeaturesToExistingListing(originalTags.industrial);
      }

      this.tags = {};
      this.tags ? (this.tags = this.createService.checkFields(tags)) : null;
      //this.tags ? (this.tags = this.createService.removeFalseValues(this.tags)) : null; It's breaking changes made on the revision
      this.listing = Object.assign({}, this.listing, this.tags);
    }

    this.listing.neighborhood = form.neighborhood;

    // SALE
    if (form.listingType === 'forSale') {
      this.listing.isForLeaseSale = 'S';
      if (form.rentableArea) {
        this.listing.rentableArea = parseFloat(
          this.langService.locale === 'en-US' ? form.rentableArea : form.rentableArea.replace(/\,/g, '.')
        );
      }
      if (form.pricePer) {
        this.listing.offeringPrice = null;
        this.listing.offeringPricePerArea = null;
      } else {
        if (form.totalAskingPrice) {
          this.listing.offeringPrice = parseFloat(
            this.langService.locale === 'en-US' ? form.totalAskingPrice : form.totalAskingPrice.replace(/\,/g, '.')
          );
        }
        if (form.pricePerSquare) {
          this.listing.offeringPricePerArea = parseFloat(
            this.langService.locale === 'en-US' ? form.pricePerSquare : form.pricePerSquare.replace(/\,/g, '.')
          );
        }
      }
    } else if (form.listingType === 'forRent') {
      // LEASE
      this.listing.isForLeaseSale = 'L';
      if (form.leaseableArea) {
        this.listing.leaseableArea = parseFloat(
          this.langService.locale === 'en-US' ? form.leaseableArea : form.leaseableArea.replace(/\,/g, '.')
        );
      }

      if (form.leaseableAreaTotal) {
        this.listing.leaseableAreaTotal = parseFloat(
          this.langService.locale === 'en-US' ? form.leaseableAreaTotal : form.leaseableAreaTotal.replace(/\,/g, '.')
        );
      }

      if (form.negRentableArea) {
        this.listing.monthlyRent = null;
        this.listing.monthlyRentPerArea = null;
      } else {
        if (form.monthlyRent) {
          this.listing.monthlyRent = parseFloat(
            this.langService.locale === 'en-US' ? form.monthlyRent : form.monthlyRent.replace(/\,/g, '.')
          );
        }

        if (form.monthlyRentPerArea) {
          this.listing.monthlyRentPerArea = parseFloat(
            this.langService.locale === 'en-US' ? form.monthlyRentPerArea : form.monthlyRentPerArea.replace(/\,/g, '.')
          );
        }
      }

      if (form.negCondoFeeArea) {
        this.listing.monthlyCondoFee = null;
        this.listing.monthlyCondoFeePerArea = null;
      } else {
        if (form.monthlyCondoFee) {
          this.listing.monthlyCondoFee = parseFloat(
            this.langService.locale === 'en-US' ? form.monthlyCondoFee : form.monthlyCondoFee.replace(/\,/g, '.')
          );
        }
        if (form.monthlyCondoFeePerArea) {
          this.listing.monthlyCondoFeePerArea = parseFloat(
            this.langService.locale === 'en-US'
              ? form.monthlyCondoFeePerArea
              : form.monthlyCondoFeePerArea.replace(/\,/g, '.')
          );
        }
      }

      if (form.negIPTUArea) {
        this.listing.monthlyIptu = null;
        this.listing.monthlyIptuPerArea = null;
      } else {
        if (form.monthlyIptu) {
          this.listing.monthlyIptu = parseFloat(
            this.langService.locale === 'en-US' ? form.monthlyIptu : form.monthlyIptu.replace(/\,/g, '.')
          );
        }
        if (form.monthlyIptuPerArea) {
          this.listing.monthlyIptuPerArea = parseFloat(
            this.langService.locale === 'en-US' ? form.monthlyIptuPerArea : form.monthlyIptuPerArea.replace(/\,/g, '.')
          );
        }
      }
    } else if (form.listingType === 'forCoworking') {
      this.listing.isForLeaseSale = ListingOfferType.Coworking;
    }

    this.listing.comments = form.description;
    this.listing.title = form.title;
    this.listing.propertyName = form.propertyName;
    this.listing.measurementStandard = form.measurementStandards;
    this.listing.startDate = new Date();
    form.vrTourUrl ? (this.listing.vrTourUrl = form.vrTourUrl) : (this.listing.vrTourUrl = null);
    this.listing.isEnabled = false;
    listingType ? (this.listing.listingType = 'PREMIUM') : (this.listing.listingType = 'PROMOTED');
    this.listing.createdByUser = { id: user.id, company: user.company, email: user.email, login: user.login };
    if (form.offeredByUserId) {
      this.listing.offeredByUser = {
        id: form.offeredByUserId.id,
        company: form.offeredByUserId.company,
        login: form.offeredByUserId.login
      };
    }
    if (form.propertyManagementCompany) {
      this.listing.propertyManagementCompany = form.propertyManagementCompany;
    }
    if (form.managerEmail) {
      this.listing.managerEmail = form.managerEmail;
    }
    if (form.managerName) {
      this.listing.managerName = form.managerName;
    }
    if (form.managerTelephone) {
      this.listing.managerTelephone = form.managerTelephone;
    }
    if (form.coworkingEntries) {
      Object.keys(form.coworkingEntries).forEach(async (key, idx) => {
        form.coworkingEntries[key].sharedDailyByPosition
          ? (form.coworkingEntries[key].sharedDailyByPosition = parseFloat(
              form.coworkingEntries[key].sharedDailyByPosition
            ).toFixed(2))
          : null;
        form.coworkingEntries[key].sharedMonthlyByPosition
          ? (form.coworkingEntries[key].sharedMonthlyByPosition = parseFloat(
              form.coworkingEntries[key].sharedMonthlyByPosition
            ).toFixed(2))
          : null;
        form.coworkingEntries[key].nonSharedDailyByPosition
          ? (form.coworkingEntries[key].nonSharedDailyByPosition = parseFloat(
              form.coworkingEntries[key].nonSharedDailyByPosition
            ).toFixed(2))
          : null;
        form.coworkingEntries[key].nonSharedMonthlyByPosition
          ? (form.coworkingEntries[key].nonSharedMonthlyByPosition = parseFloat(
              form.coworkingEntries[key].nonSharedMonthlyByPosition
            ).toFixed(2))
          : null;
      });
      this.listing.coworkingEntries = form.coworkingEntries;
    }
    if (form.coworkingMaxPositions) {
      this.listing.coworkingMaxPositions = form.coworkingMaxPositions;
    }

    if (subTypes) {
      let types: any = [];
      Object.keys(subTypes).forEach(async (key, idx) => {
        if (subTypes[key].selected) {
          types.push({ id: subTypes[key].id, name: subTypes[key].name });
        }
      });
      this.listing.propertySubTypes = types;
    }
    this.checkValues(form);
    if (form.actualProperty) {
      this.listing.building = { id: form.actualProperty.id };
    } else {
      this.listing.building = {
        postalCode: form.zip,
        city: { id: form.city },
        state: { id: form.state },
        address: form.address,
        latitude: form.buildingLocation.marker.lat,
        longitude: form.buildingLocation.marker.lng,
        buildingType: { id: parseInt(form.commericalType) },
        title: form.property
      };
    }

    //NEW LISTING
    if (!created && !revise) {
      this.listing.listingPurchaseId = listingPurchaseId;
      this.listing.listingStatus = { id: 7 };
      this.previewListing = await this.http.post(`${this.createUpdateListing}`, this.listing).toPromise();

      let photos = this.associatePhotosToListing(this.previewListing.id, imgList);

      let copyOfPhotos = photos;
      this.previewListing.photos = photos;
      this.previewPhotos = copyOfPhotos;
      return this.previewListing;
    } else if (revise) {
      // ACTIVE ADMIN NO REVISION STATUS
      if (
        this.editActiveRevisionListing(revise) ||
        (this.checkRole('ROLE_SPOT_ADMIN') &&
          (revise.listingStatus.id === 4 || this.isUserAllowToEditPendingApprovalListing(revise)) &&
          !revise.nextListingId)
      ) {
        revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
        this.listing.listingType = revise.listingType;
        //revise.listingPhotos ? this.checkExistingPhotos(revise, imgList) : null;
        let listing = { ...revise, ...this.listing };

        //let photos = await this.uploadImages(imgList, listing);
        let photos = this.associatePhotosToListing(revise.id, imgList);

        listing.photos = photos;
        listing.listingPhotos = photos;
        return listing;
      }
      // REVISION STATUS
      else if (revise.listingStatus.id === 8) {
        revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
        this.listing.listingType = revise.listingType;
        let listing = { ...revise, ...this.listing };
        this.previewListing = await this.http.put(`${this.createUpdateListing}`, listing).toPromise();
        //revise.listingPhotos ? this.checkExistingPhotos(revise, imgList) : null;
        //let photos = await this.uploadImages(imgList, this.previewListing);
        let photos = this.associatePhotosToListing(this.previewListing.id, imgList);
        this.previewListing.photos = photos;
        return this.previewListing;
      }
      // NEW REVISION STATUS -todo
      else if (!revise.nextListingStatus && revise.listingStatus.id !== 8 && revise.listingStatus.id !== 7) {
        this.listing.priorListingId = revise.id;
        revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
        this.listing.id = null;
        this.listing.listingStatus = { id: 8 };
        this.listing.listingType = revise.listingType;

        let listing = { ...revise, ...this.listing };
        try {
          this.previewListing = await this.http.post(`${this.createUpdateListing}`, listing).toPromise();
          let photos = await this.copyPreviousPhotosFromListing(imgList, this.previewListing);
          this.previewListing.photos = photos;
          return this.previewListing;
        } catch (error) {
          const message = this.i18NService.get('global.reviseErrorMessage');
          this.showMessage(null, message, false);
          return null;
        }
      }
      // DRAFT STATUS
      else if (revise.listingStatus.id === 7) revise.propertySubTypes ? this.checkExistingCoworking(revise) : null;
      this.listing.id = revise.id;
      this.listing.listingStatus = { id: 7 };
      this.listing.listingType = revise.listingType;
      let listing = { ...revise, ...this.listing };
      this.previewListing = await this.http.put(`${this.createUpdateListing}`, listing).toPromise();
      //revise.listingPhotos ? this.checkExistingPhotos(revise, imgList) : null;
      //let photos = await this.uploadImages(imgList, this.previewListing);
      let photos = this.associatePhotosToListing(this.listing.id, imgList);
      this.previewListing.photos = photos;
      return this.previewListing;
    } else {
      let photos: any = await this.listingDetailService.getListingPhotos(this.previewListing.id).toPromise();
      if (photos) {
        Object.keys(photos).forEach(async (key, idx) => {
          let obj = imgList.findIndex((v: any) => {
            return v.id === photos[idx].id;
          });
          if (obj === -1) {
            this.http.delete(`${this.listingPhotos}${photos[idx].id}`).toPromise();
          }
        });
      }
      if (previewListing.propertySubTypes) {
        previewListing.propertySubTypes ? this.checkExistingCoworking(previewListing) : null;
      }

      this.listing.id = previewListing.id;
      this.listing.listingStatus = previewListing.listingStatus;
      //this.listing.building = previewListing.building;
      let listing = { ...previewListing, ...this.listing };
      if (previewListing.building) {
        listing.building = previewListing.building;
      }

      this.previewListing = await this.http.put(`${this.createUpdateListing}`, listing).toPromise();
      if (imgList) {
        let photos = await this.uploadImages(imgList, this.previewListing);
        this.previewListing.photos = photos;
        this.previewPhotos = photos;
      }
      return this.previewListing;
    }
  }

  async copyPreviousPhotosFromListing(currentPhotos: any[], listingRevised: any) {
    const revisedPhotos = [...currentPhotos];
    //return await this.addPhotosToListing(revisedPhotos, listingRevised);
    return await this.copyPhotosToListing(revisedPhotos, listingRevised.id);
  }

  async copyPhotosToListing(photos: any[], listingId: any) {
    return this.http
      .put(`${this.copyPhotosToListingAPI}${listingId}`, photos)
      .pipe(map(val => val))
      .toPromise();
  }

  /**
   * Delete existing images
   */
  checkExistingPhotos(revise: any, imgList: any) {
    Object.keys(revise.listingPhotos).forEach(async (key, idx) => {
      let obj = imgList.findIndex((v: any) => {
        return v.id === revise.listingPhotos[key].id;
      });
      if (obj === -1) {
        this.http.delete(`${this.listingPhotos}${revise.listingPhotos[key].id}`).toPromise();
      }
    });
  }

  async checkExistingCoworking(revise: any) {
    let existing = revise.isForLeaseSale === ListingOfferType.Coworking;
    let current = this.listing.isForLeaseSale === ListingOfferType.Coworking;

    if (existing && !current) {
      this.listing.coworkingEntries = null;
      this.listing.coworkingMaxPositions = null;
    }
  }

  async addPhotosToListing(imgList: any, previewListing: any) {
    let photos: any = [];
    Object.keys(imgList).forEach(async (key, idx) => {
      if (imgList[key].image) {
        const croppedImage = await this.createCroppedImage(imgList[key]);
        const croppedImageId = croppedImage ? croppedImage.id : null;

        let listingPhoto: any = {
          orderId: idx + 1,
          id: imgList[key].id,
          imageId: imgList[key].image.id,
          originalImage: imgList[key].originalImage,
          listingId: imgList[key].listing.id,
          createdDate: new Date(),
          croppedImageId: croppedImageId
        };
        let upload = await this.createListingPhoto(listingPhoto);
        photos.push(upload);
      } else {
        let img = await this.commonService.convertImageBase64ToFile(imgList[key].img, imgList[key].content);
        const croppedImage = imgList[key].croppedImage
          ? await this.commonService.convertImageBase64ToFile(imgList[key].croppedImage, imgList[key].content)
          : null;
        let upload = await this.uploadPhoto(
          img,
          imgList[key].caption,
          previewListing.id,
          idx + 1,
          imgList[key].content,
          croppedImage,
          imgList[key].originalImageId
        );
        photos.push(upload);
      }
    });
    return photos;
  }

  async createCroppedImage(originalImage: any) {
    const croppedImage = originalImage.croppedImage;
    if (croppedImage == null) {
      return null;
    }
    if (!croppedImage.id) {
      const croppedFile = await this.commonService.convertImageBase64ToFile(croppedImage, originalImage.image.content);
      const caption =
        originalImage.image.caption && originalImage.image.caption.length > 0
          ? originalImage.image.caption
          : originalImage.image.content;
      return await this.uploadNewImage(croppedFile, `${caption}_cropped`).then((createdImages: any) => {
        if (createdImages) {
          return createdImages[0];
        }
      });
    } else {
      return croppedImage;
    }
  }

  async createListingPhoto(listingPhoto: any) {
    return this.http
      .post(`${this.listingPhotos}`, listingPhoto)
      .pipe(map(val => val))
      .toPromise();
  }

  async checkValues(form: any) {
    if (!form) {
      return;
    }
    this.setLoadCapacity(form);
    this.setBaySize(form);
    this.setDockRatio(form);
    this.setCeilingHeight(form);
    this.setParkingSpaces(form);
    this.setTruckParkingSpaces(form);
    this.setCarParkingSpaces(form);
  }

  setLoadCapacity(form: any) {
    if (!form.loadCapacity || form.loadCapacity < 0) {
      this.listing.loadCapacity = null;
    } else {
      this.listing.loadCapacity = parseInt(form.loadCapacity);
    }
  }
  setBaySize(form: any) {
    if (!form.baySize || form.baySize < 0) {
      this.listing.baySize = null;
    } else {
      this.listing.baySize = parseFloat(
        this.langService.locale === 'en-US' ? form.baySize : form.baySize.replace(/\,/g, '.')
      );
    }
  }
  setDockRatio(form: any) {
    if (!form.dockRatio || form.dockRatio < 0) {
      this.listing.dockRatio = null;
    } else {
      this.listing.dockRatio = parseFloat(
        this.langService.locale === 'en-US' ? form.dockRatio : form.dockRatio.replace(/\,/g, '.')
      );
    }
  }
  setCeilingHeight(form: any) {
    if (!form.ceilingHeight || form.ceilingHeight < 0) {
      this.listing.ceilingHeight = null;
    } else {
      this.listing.ceilingHeight = parseFloat(
        this.langService.locale === 'en-US' ? form.ceilingHeight : form.ceilingHeight.replace(/\,/g, '.')
      );
    }
  }
  setParkingSpaces(form: any) {
    if (!form.parkingSpaces || form.parkingSpaces < 0) {
      this.listing.parkingSpaces = null;
    } else {
      this.listing.parkingSpaces = parseInt(form.parkingSpaces);
    }
  }
  setTruckParkingSpaces(form: any) {
    if (!form.truckParkingSpaces || form.truckParkingSpaces < 0) {
      this.listing.truckParkingSpaces = null;
    } else {
      this.listing.truckParkingSpaces = parseInt(form.truckParkingSpaces);
    }
  }
  setCarParkingSpaces(form: any) {
    if (!form.carParkingSpaces || form.carParkingSpaces < 0) {
      this.listing.carParkingSpaces = null;
    } else {
      this.listing.carParkingSpaces = parseInt(form.carParkingSpaces);
    }
  }

  async showMessage(header: string, message: string, keepUrl?: boolean) {
    const okText = this.i18NService.get('global.OK');
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [okText]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  isUserAllowToEditPendingApprovalListing(pListing: any): boolean {
    if (!pListing) {
      return false;
    }
    return this.checkRole('ROLE_SPOT_ADMIN') && pListing.listingStatus.id === 1;
  }

  editActiveRevisionListing(pListing: any): boolean {
    if (!pListing) {
      return false;
    }
    return (
      this.checkRole('ROLE_SPOT_ADMIN') &&
      pListing.listingStatus.id === 4 &&
      pListing.nextListingId &&
      pListing.nextListingStatus &&
      pListing.nextListingStatus.id === 8
    );
  }
}
