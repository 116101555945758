
    <div class="flex-col">
      <div class="flex-row" [ngClass]="{ 'flex-wrap': handsetMode }">
        <input style="width:100%; border: unset;" type="text" [(ngModel)]="accessPass" />
      </div>
      <mat-divider></mat-divider>
      <div class="flex-row" style="justify-content:space-evenly; padding-top:20px;">
        <button
          class="siila-submit login-btn"
          (click)="access()"
          [disabled]="check()"
          style="width:100%"
          mat-raised-button
        >
          <span translate="access">Access</span>
        </button>
      </div>
    </div>
  