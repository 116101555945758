import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '../image.service';
import { BehaviorSubject } from 'rxjs';
import { validatePhoneNumber } from '../../user-home/my-account/phone-custom-validation';
var UserHomeService = /** @class */ (function () {
    function UserHomeService(http, imageService) {
        this.http = http;
        this.imageService = imageService;
        this.profileImage$ = new BehaviorSubject({});
        this.updateProfileImageUrl = 'api/resizedimage';
    }
    UserHomeService.prototype.santizeContactNumber = function (rawFormData) {
        var formPrimaryPhoneNumber = rawFormData.get('contact').get('primaryPhone').value;
        var formWhatsAppPhoneNumber = rawFormData.get('contact').get('whatsApp').value;
        var userPhones = [];
        if (formPrimaryPhoneNumber) {
            var obj = {
                phoneType: 'PHONE_NUMBER',
                phoneNumber: formPrimaryPhoneNumber.internationalNumber && formPrimaryPhoneNumber.length > 0
                    ? formPrimaryPhoneNumber.internationalNumber
                    : formPrimaryPhoneNumber.number,
                isoCode: formPrimaryPhoneNumber.countryCode
            };
            userPhones.push(obj);
        }
        if (formWhatsAppPhoneNumber) {
            var obj = {
                phoneType: 'WHATSAPP',
                phoneNumber: formWhatsAppPhoneNumber.internationalNumber,
                isoCode: formWhatsAppPhoneNumber.countryCode
            };
            userPhones.push(obj);
        }
        return userPhones;
    };
    UserHomeService.prototype.santizeUserBiography = function (rawFormData) {
        var biographyList = rawFormData.get('profileInfo').get('biography').value;
        var biography = biographyList.reduce(function (accumulator, item) {
            accumulator = Object.assign(accumulator, item);
            return accumulator;
        }, {});
        return JSON.stringify(biography);
    };
    UserHomeService.prototype.sanitizeUserProfile = function (rawUserProfile, userFormProfile) {
        Object.keys(userFormProfile).forEach(function (key) {
            rawUserProfile[key] = userFormProfile[key];
        });
        return rawUserProfile;
    };
    UserHomeService.prototype.santizeUserProfileForm = function (rawFormData) {
        return {
            jobTitle: rawFormData.get('profileInfo').get('jobTitle').value,
            firstName: rawFormData.get('userName').get('firstName').value,
            lastName: rawFormData.get('userName').get('lastName').value,
            address: rawFormData.get('location').get('address').value,
            city: rawFormData.get('location').get('city').value,
            state: rawFormData.get('location').get('state').value,
            postalCode: rawFormData.get('location').get('postalCode').value,
            country: rawFormData.get('location').get('country').value,
            userPhones: this.santizeContactNumber(rawFormData),
            biography: this.santizeUserBiography(rawFormData),
            excludeFromCommunity: rawFormData.get('advancedSettings').get('excludeFromCommunity').value,
            emailNotification: rawFormData.get('advancedSettings').get('emailNotification').value,
            excludeFromCommunityEmails: rawFormData.get('advancedSettings').get('excludeFromCommunityEmails').value
        };
    };
    UserHomeService.prototype.createUserProfileForm = function () {
        return new FormGroup({
            languages: new FormControl(''),
            profileInfo: new FormGroup({
                jobTitle: new FormControl('', Validators.required),
                biography: new FormArray([])
            }),
            userName: new FormGroup({
                firstName: new FormControl('', Validators.required),
                lastName: new FormControl('', Validators.required)
            }),
            location: new FormGroup({
                address: new FormControl(''),
                city: new FormControl(''),
                state: new FormControl(''),
                postalCode: new FormControl(''),
                country: new FormControl(''),
                company: new FormControl({ value: '', disabled: true })
            }),
            contact: new FormGroup({
                primaryPhone: new FormControl('', [Validators.required, validatePhoneNumber(false)]),
                whatsApp: new FormControl('', [validatePhoneNumber(true)])
            }),
            advancedSettings: new FormGroup({
                excludeFromCommunity: new FormControl(),
                emailNotification: new FormControl(),
                excludeFromCommunityEmails: new FormControl()
            })
        });
    };
    UserHomeService.prototype.createChangePasswordForm = function () {
        return new FormGroup({
            currentPassword: new FormControl(''),
            newPassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
            confirmNewPassword: new FormControl('', Validators.required)
        });
    };
    UserHomeService.prototype.getContactNumber = function (userAccount, phoneType) {
        var contactNumber = userAccount.phones.filter(function (item) {
            return (item.phoneType && item.phoneType.toLowerCase() === phoneType.toLowerCase()) ||
                (!item.phoneType && phoneType === 'PHONE_NUMBER');
        });
        return contactNumber.length ? contactNumber[0].phoneNumber : '';
    };
    UserHomeService.prototype.getEmailUpdateErrorMessage = function (error) {
        var error406 = {
            header: 'global.user.emailAlreadyTaken',
            bodyMessage: 'global.user.message.updateEmailAlreadyExistError'
        };
        var genericError = {
            header: 'global.user.updateEmailAddress',
            bodyMessage: 'global.user.message.updateEmailGenericError'
        };
        return error.status === 406 ? error406 : genericError;
    };
    UserHomeService.prototype.getEmailUpdateSuccessMessage = function () {
        return {
            header: 'global.user.updateEmailAddress',
            bodyMessage: 'global.user.message.updateEmailSuccessfully'
        };
    };
    UserHomeService.prototype.getPasswordChangeErrorMessage = function (error) {
        var error400 = {
            header: 'global.user.changePassword',
            bodyMessage: 'global.user.message.incorrectCurrentPassword'
        };
        var genericError = {
            header: 'global.user.changePassword',
            bodyMessage: 'global.user.message.updatePasswordGenericError'
        };
        return error.status === 400 ? error400 : genericError;
    };
    UserHomeService.prototype.getPasswordChangeSuccessMessage = function () {
        return {
            header: 'global.user.changePassword',
            bodyMessage: 'global.user.message.updatePasswordSuccessfully'
        };
    };
    UserHomeService.prototype.getModalProp = function (component, componentProps, cssClass) {
        return {
            backdropDismiss: false,
            showBackdrop: true,
            cssClass: cssClass,
            component: component,
            componentProps: componentProps
        };
    };
    UserHomeService.prototype.getImageCropperOptions = function () {
        return {
            maintainAspectRatio: 'true',
            cropperMinWidth: 128,
            cropperMinHeight: 128,
            resizeToWidth: 200,
            format: 'png',
            roundCropper: true,
            onlyScaleDown: true
        };
    };
    UserHomeService.prototype.getLogoCropperOptions = function () {
        return {
            maintainAspectRatio: 'true',
            resizeToWidth: 136,
            format: 'png',
            onlyScaleDown: false
        };
    };
    UserHomeService.prototype.getUploadImageInfoModalParam = function (isSuccess, data) {
        var params = isSuccess
            ? {
                headerTitle: 'global.user.editImage',
                bodyMessage: 'global.user.message.imageProfileEditSuccessfully',
                action: 'updateProfileImageSuccess',
                extraData: data
            }
            : {
                headerTitle: 'global.user.editImage',
                bodyMessage: 'global.user.message.imageProfileEditError',
                action: 'updateProfileImageError'
            };
        return params;
    };
    UserHomeService.prototype.updateUserProfileImage = function (login, file, newPhoto) {
        var payload = new FormData();
        payload.append('caption', login + "_profile_photo_resize"); // If it changes, we need to made some changes on the API as well.
        payload.append('file', file);
        payload.append('newPhoto', newPhoto);
        payload.append('application', 'SPOT');
        return this.http.post(this.updateProfileImageUrl, payload);
    };
    UserHomeService.prototype.uploadUserProfileImage = function (login, file) {
        var payload = new FormData();
        payload.append('caption', login + "_profile_photo");
        payload.append('file', file);
        return this.http.post(this.updateProfileImageUrl, payload);
    };
    UserHomeService.prototype.setProfileImageProp = function (image) {
        return {
            croppedImage: this.imageService.mediumThumbnail(image.profileImageId),
            originalImage: this.imageService.mediumThumbnail(image.originalImageId)
        };
    };
    UserHomeService.prototype.setUserAccountProp = function (account, prop) {
        return {
            profile: account,
            profileImage: this.imageService.mediumThumbnail(prop.profileImageId),
            originalImage: this.imageService.mediumThumbnail(prop.originalImageId)
        };
    };
    UserHomeService.prototype.addBiographyFormGroup = function (fb, currentSelectedLanguage, value, formControlName) {
        var _a;
        var formControlPropName = formControlName ? formControlName : currentSelectedLanguage;
        return fb.group((_a = {},
            _a[formControlPropName] = new FormControl(value ? value : '', Validators.required),
            _a));
    };
    UserHomeService.prototype.getContactCountriesISO = function (userPhones) {
        this.primaryPhoneCountryISO = this.getContactISO('PHONE_NUMBER', userPhones);
        this.whatsAppCountryISO = this.getContactISO('WHATSAPP', userPhones);
    };
    UserHomeService.prototype.getContactISO = function (contactType, contacts) {
        var ISO = contacts.filter(function (item) { return item.phoneType && item.phoneType.toLowerCase() === contactType.toLowerCase(); });
        return ISO.length && ISO[0].isoCode ? ISO[0].isoCode.toLowerCase() : '';
    };
    UserHomeService.prototype.getMultiLangOption = function () {
        return {
            element: { richtext: true },
            formArrayName: 'biography',
            formGroupName: 'profileInfo',
            placeholder: 'global.user.biographyPlaceholder',
            columnLabel: 'global.user.profileForm.biography',
            isRegister: false
        };
    };
    return UserHomeService;
}());
export { UserHomeService };
