import { Injectable } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ImageService } from '../image.service';
import { CommonGenericService } from '../common.service';
import { LanguageService } from '../language.service';
import { I18nService } from '../i18n.service';
import { ListingOfferType } from '../../models/transaction-type.model';

@Injectable()
export class ListingFormService {
  private host: string = 'api/listings/purchase-options';

  constructor(
    private fb: FormBuilder,
    private _ts: TranslateService,
    private imageService: ImageService,
    private commonService: CommonGenericService,
    private langService: LanguageService,
    private i18nService: I18nService
  ) {}

  getI18nValue(textValue: string) {
    try {
      return this.i18nService.getTranslation(textValue);
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  private convertToDataURL(imageUrl: string, callback: any) {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    let filename = this.commonService.getFileNameFromPath(imageUrl);

    xhr.onload = () => {
      let fileReader = new FileReader();
      fileReader.onloadend = () => {
        callback(fileReader.result);
      };
      fileReader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', imageUrl);
    xhr.responseType = 'blob';
    xhr.send();
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  checkListingPhotos(listing: any) {
    let imgList: any = [];
    Object.keys(listing.listingPhotos).forEach(key => {
      let path: any = this.getMediumImg(listing.listingPhotos[key].image.id);
      this.convertToDataURL(path, (dataURI: any) => {
        if (dataURI) {
          imgList.push(listing.listingPhotos[key]);
        }
      });
    });
    return imgList;
  }

  createInitForm(measurementStandard: any) {
    let standard: any = measurementStandard.filter((e: any) => {
      if (e.id === 9202) {
        return e;
      }
    });
    let forms: any = [];
    let listingTypeForm = this.fb.group({
      listingType: ['forRent', Validators.compose([Validators.required])],
      commericalType: [1001, Validators.compose([Validators.required])],
      subType: [null]
    });
    forms.push(listingTypeForm);
    let detailsForm = this.fb.group({
      propertyName: ['', Validators.compose([Validators.required])],
      property: [null],
      listed: [false],
      propertySet: [false],
      actualProperty: [null]
    });

    forms.push(detailsForm);
    let addressForm = this.fb.group({
      address: ['', Validators.compose([Validators.required])],
      state: [null, Validators.compose([Validators.required])],
      city: [null, Validators.compose([Validators.required])],
      buildingLocation: [null, Validators.compose([Validators.required])],
      zip: ['']
    });
    forms.push(addressForm);
    let moreDetailsForm = this.fb.group({
      offeredByUserId: [null],
      selectCompany: [null],
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      neighborhood: [null, Validators.compose([Validators.required])],
      propertyManagementCompany: [null],
      managerName: [null],
      managerEmail: [null],
      managerTelephone: [null]
    });
    forms.push(moreDetailsForm);
    let listingPricingForm = this.fb.group({
      pricePer: [false],
      pricePerSquare: [''],
      rentableArea: [null],
      percentInterestOffered: [100],
      currentOccupancy: [null],
      negRentableArea: [false],
      negCondoFeeArea: [false],
      negIPTUArea: [false],
      monthlyRent: ['', Validators.compose([Validators.required])],
      monthlyRentPerArea: [''],
      leaseableArea: ['', Validators.compose([Validators.required])],
      leaseableAreaTotal: [''],
      monthlyCondoFee: [''],
      monthlyCondoFeePerArea: [''],
      monthlyIptu: [''],
      monthlyIptuPerArea: [''],
      totalAskingPrice: [null],
      totalAsking: [false],
      measurementStandards: [standard[0], Validators.compose([Validators.required])],
      coworkingMaxPositions: [null],
      coworkingEntries: [null]
    });
    forms.push(listingPricingForm);
    let listingFeaturesForm = this.fb.group({
      ceilingHeight: [null],
      vrTourUrl: [null],
      selectCompany: [null],
      parkingSpaces: [null],
      //restrooms: [null],
      tags: [null],
      loadCapacity: [null],
      carParkingSpaces: [null],
      truckParkingSpaces: [null],
      dockRatio: [null],
      baySize: [null]
    });
    forms.push(listingFeaturesForm);
    return forms;
  }

  reinitForm(listing: any, building: any, measurementStandard: any) {
    let standard: any = measurementStandard.filter((e: any) => {
      if (listing.measurementStandard) {
        if (e.id === listing.measurementStandard.id) {
          return e;
        }
      } else {
        if (e.id === 9202) {
          return e;
        }
      }
    });

    let buildingName: any = {
      id: building.id,
      name: this.getI18nValue(building.title)
    };
    if (listing.coworkingEntries) {
      Object.keys(listing.coworkingEntries).forEach((key, idx) => {
        listing.coworkingEntries[key].sharedDailyByPosition = listing.coworkingEntries[key].sharedDailyByPosition
          ? listing.coworkingEntries[key].sharedDailyByPosition.toFixed(2)
          : null;

        listing.coworkingEntries[key].sharedMonthlyByPosition = listing.coworkingEntries[key].sharedMonthlyByPosition
          ? listing.coworkingEntries[key].sharedMonthlyByPosition.toFixed(2)
          : null;

        listing.coworkingEntries[key].nonSharedDailyByPosition = listing.coworkingEntries[key].nonSharedDailyByPosition
          ? listing.coworkingEntries[key].nonSharedDailyByPosition.toFixed(2)
          : null;

        listing.coworkingEntries[key].nonSharedMonthlyByPosition = listing.coworkingEntries[key]
          .nonSharedMonthlyByPosition
          ? listing.coworkingEntries[key].nonSharedMonthlyByPosition.toFixed(2)
          : null;
      });
    }
    let forms: any = [];
    let listingTypeForm = this.fb.group({
      listingType: [
        listing.isForLeaseSale === 'S' || listing.isForLeaseSale === 'SALE'
          ? 'forSale'
          : listing.isForLeaseSale === 'L' || listing.isForLeaseSale === 'LEASE'
          ? 'forRent'
          : listing.isForLeaseSale === ListingOfferType.Coworking || listing.isForLeaseSale === 'COWORKING'
          ? 'forCoworking'
          : '',
        Validators.compose([Validators.required])
      ],
      commericalType: [building.buildingType.id === 1001 ? 1001 : '2001', Validators.compose([Validators.required])],
      subType: [null],
      loadedSubTypes: [listing.propertySubTypes ? listing.propertySubTypes : null]
    });
    forms.push(listingTypeForm);
    let detailsForm = this.fb.group({
      propertyName: [this.getI18nValue(building.title), Validators.compose([Validators.required])],
      property: [buildingName],
      listed: [false],
      propertySet: [true],
      actualProperty: [buildingName]
    });

    forms.push(detailsForm);
    let addressForm = this.fb.group({
      address: [this.getI18nValue(listing.address), Validators.compose([Validators.required])],
      state: [null, Validators.compose([Validators.required])],
      city: [null, Validators.compose([Validators.required])],
      buildingLocation: [null, Validators.compose([Validators.required])],
      zip: ['']
    });
    forms.push(addressForm);
    let moreDetailsForm = this.fb.group({
      offeredByUserId: [listing.offeredByUser],
      selectCompany: [listing.offeredByUser ? listing.offeredByUser.company : null],
      title: [listing.title, Validators.compose([Validators.required])],
      description: [listing.comments, Validators.compose([Validators.required])],
      neighborhood: [listing.neighborhood ? listing.neighborhood : null, Validators.compose([Validators.required])],
      propertyManagementCompany: [listing.propertyManagementCompany ? listing.propertyManagementCompany : null],
      managerName: [listing.managerName ? listing.managerName : null],
      managerEmail: [listing.managerEmail ? listing.managerEmail : null],
      managerTelephone: [listing.managerTelephone ? listing.managerTelephone : null]
    });
    forms.push(moreDetailsForm);
    let listingPricingForm = this.fb.group({
      pricePer: [!listing.offeringPrice ? true : false],
      pricePerSquare: [
        listing.offeringPricePerArea
          ? this.langService.locale === 'en-US'
            ? listing.offeringPricePerArea.toFixed(2)
            : listing.offeringPricePerArea.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      rentableArea: [
        listing.rentableArea
          ? this.langService.locale === 'en-US'
            ? listing.rentableArea.toFixed(2)
            : listing.rentableArea.toFixed(2).replace(/\./g, ',')
          : null
      ],
      percentInterestOffered: [listing.percentInterestOffered ? listing.percentInterestOffered : null],
      currentOccupancy: [listing.currentOccupancy ? listing.currentOccupancy : null],
      negRentableArea: [!listing.monthlyRent ? true : false],
      negCondoFeeArea: [!listing.monthlyCondoFee ? true : false],
      negIPTUArea: [!listing.monthlyIptu ? true : false],
      monthlyRent: [
        listing.monthlyRent
          ? this.langService.locale === 'en-US'
            ? listing.monthlyRent.toFixed(2)
            : listing.monthlyRent.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      monthlyRentPerArea: [
        listing.monthlyRentPerArea
          ? this.langService.locale === 'en-US'
            ? listing.monthlyRentPerArea.toFixed(2)
            : listing.monthlyRentPerArea.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      leaseableArea: [
        listing.leaseableArea
          ? this.langService.locale === 'en-US'
            ? listing.leaseableArea.toFixed(2)
            : listing.leaseableArea.toFixed(2).replace(/\./g, ',')
          : null
      ],
      leaseableAreaTotal: [
        listing.leaseableAreaTotal
          ? this.langService.locale === 'en-US'
            ? listing.leaseableAreaTotal.toFixed(2)
            : listing.leaseableAreaTotal.toFixed(2).replace(/\./g, ',')
          : null
      ],
      monthlyCondoFee: [
        listing.monthlyCondoFee
          ? this.langService.locale === 'en-US'
            ? listing.monthlyCondoFee.toFixed(2)
            : listing.monthlyCondoFee.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      monthlyCondoFeePerArea: [
        listing.monthlyCondoFeePerArea
          ? this.langService.locale === 'en-US'
            ? listing.monthlyCondoFeePerArea.toFixed(2)
            : listing.monthlyCondoFeePerArea.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      monthlyIptu: [
        listing.monthlyIptu
          ? this.langService.locale === 'en-US'
            ? listing.monthlyIptu.toFixed(2)
            : listing.monthlyIptu.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      monthlyIptuPerArea: [
        listing.monthlyIptuPerArea
          ? this.langService.locale === 'en-US'
            ? listing.monthlyIptuPerArea.toFixed(2)
            : listing.monthlyIptuPerArea.toFixed(2).replace(/\./g, ',')
          : ''
      ],
      totalAskingPrice: [
        listing.offeringPrice
          ? this.langService.locale === 'en-US'
            ? listing.offeringPrice.toFixed(2)
            : listing.offeringPrice.toFixed(2).replace(/\./g, ',')
          : null
      ],
      totalAsking: [false],
      measurementStandards: [standard[0], Validators.compose([Validators.required])],
      coworkingMaxPositions: [listing.coworkingMaxPositions ? listing.coworkingMaxPositions : null],
      coworkingEntries: [listing.coworkingEntries ? listing.coworkingEntries : null]
    });
    forms.push(listingPricingForm);
    let listingFeaturesForm = this.fb.group({
      ceilingHeight: [
        listing.ceilingHeight
          ? this.langService.locale === 'en-US'
            ? listing.ceilingHeight.toFixed(2)
            : listing.ceilingHeight.toFixed(2).replace(/\./g, ',')
          : null
      ],
      vrTourUrl: [listing.vrTourUrl ? listing.vrTourUrl : null],
      parkingSpaces: [listing.parkingSpaces ? listing.parkingSpaces : null],
      restrooms: [listing.restrooms ? listing.restrooms : null],
      tags: [null],
      loadCapacity: [listing.loadCapacity ? listing.loadCapacity : null],
      carParkingSpaces: [listing.carParkingSpaces > 0 ? listing.carParkingSpaces : null],
      truckParkingSpaces: [listing.truckParkingSpaces > 0 ? listing.truckParkingSpaces : null],
      dockRatio: [
        listing.dockRatio
          ? this.langService.locale === 'en-US'
            ? listing.dockRatio.toFixed(2)
            : listing.dockRatio.toFixed(2).replace(/\./g, ',')
          : null
      ],
      baySize: [
        listing.baySize
          ? this.langService.locale === 'en-US'
            ? listing.baySize.toFixed(2)
            : listing.baySize.toFixed(2).replace(/\./g, ',')
          : null
      ]
    });
    forms.push(listingFeaturesForm);
    return forms;
  }
}
