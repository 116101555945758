import { MatDialog } from '@angular/material';
import { AcceptCancelDataModel } from './accept-cancel.model';
import { AppAcceptCancelModalComponent } from './app-accept-cancel-modal.component';

export class AppAcceptCancelModalService {
  constructor(private dialog: MatDialog) {}

  openDialog(data?: AcceptCancelDataModel) {
    return this.dialog.open(AppAcceptCancelModalComponent, {
      width: '315px',
      data: data || null
    });
  }
}
