import { Component, OnInit, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-wrong-route',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  wrongRoute: string;
  displayScrollToTop: boolean;

  constructor(private location: Location, private routingHelperSrv: RoutingHelperService) {}

  ngOnInit() {
    document.head.innerHTML += '<meta name="prerender-status-code" content="404">';
    if (window.history.state && window.history.state.badURL) {
      this.wrongRoute = window.history.state.badURL;
    } else {
      this.wrongRoute = window.location.href;
    }

    this.location.go('/not-found');
  }

  navigateToHomePage() {
    return ['/'];
  }

  navigateToSearchPage() {
    return [`/${this.routingHelperSrv.getSpotSearchURL()}`];
  }

  isHeaderVisible(event: any) {
    this.displayScrollToTop = event.visible ? false : true;
  }
}
