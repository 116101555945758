import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { NotificationUser } from '@app/models/notification-user.model';
import { SearchNotification } from '@app/models/search-notification.model';
import { SpotSetBody } from '@app/models/spot-set-list.model';
import { NotificationService } from '@app/shared/services/notification.service';
import { turnOff } from '@app/shared/util/params';
import { faEllipsisH, faBellSlash, faEyeSlash, faSearch, faFilter, faCircle } from '@fortawesome/free-solid-svg-icons';
import { PageChangedEvent, PaginationComponent } from 'ngx-bootstrap';

@Component({
  selector: 'app-more-notifications',
  templateUrl: './more-notifications.component.html',
  styleUrls: ['./more-notifications.component.scss']
})
export class MoreNotificationsComponent implements OnInit {
  @ViewChild('spotBuildingPagination', { static: false }) paginator: PaginationComponent;

  notifications: SearchNotification;
  showShortDesciption = true;
  faEllipsisH: any;
  faBellSlash: any;
  faEyeSlash: any;
  search: any;
  filter: any;
  faCircle: any;

  itemsPerPage = 5;
  currentPage: number = 1;
  filterNotification: string;

  seeSearch = false;
  pageSize = 10;
  page = 0;
  collection = 0;
  isMobile: boolean = false;
  param: string;
  direction: string;
  filterCount = 0;
  turnOffParams = turnOff;

  constructor(
    private i18nService: I18nService,
    private notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private routingHelperSrv: RoutingHelperService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.faEllipsisH = faEllipsisH;
    this.faBellSlash = faBellSlash;
    this.faEyeSlash = faEyeSlash;
    this.faCircle = faCircle;
    this.search = faSearch;
    this.filter = faFilter;
    this.getNotifications(0);
  }

  getNotifications(page: number) {
    this.page = page + 1;
    const lang = this.i18nService.getCurrentLanguage();
    const bodySpotSet: SpotSetBody = {
      sortBy: this.param,
      direction: this.direction,
      titleFilter: this.filterNotification
    };
    this.notificationService.getNotifications(page, lang, bodySpotSet).then((value: SearchNotification) => {
      this.notifications = value;
      this.collection = this.notifications.totalElements;
      this.pageSize = this.notifications.size;
    });
  }

  getContent(content: string) {
    return this.i18nService.getTranslation(content);
  }

  searchNotification() {
    this.seeSearch = !this.seeSearch;
  }

  searchFilter() {
    if (this.filterCount == 0) {
      this.filterCount = 1;
      setTimeout(() => {
        this.getNotifications(0);
        this.filterCount = 0;
      }, 2000);
    }
  }

  changePage(page: number) {
    this.page = page;
    this.getNotifications(this.page - 1);
  }

  nameAscending() {
    this.param = 'name';
    this.direction = 'ASC';

    this.getNotifications(0);
  }

  nameDescending() {
    this.param = 'name';
    this.direction = 'DESC';

    this.getNotifications(0);
  }

  oldestFirst() {
    this.param = 'date';
    this.direction = 'ASC';
    this.getNotifications(0);
  }

  newestFirst() {
    this.param = 'date';
    this.direction = 'DESC';
    this.getNotifications(0);
  }
  goBrowseProperties() {
    this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
  }

  hideNotification(id: number) {
    this.notificationService.hideNotification(id).then(() => {
      this.getNotifications(0);
    });
  }
  turnOffNotification(turnOff: any) {
    this.notificationService.turnOffNotification(turnOff).then(val => {
      this.getNotifications(0);
    });
  }
  goToActionLink(notify: any) {
    if (notify.read) {
      window.open(notify.actionLink, 'blank');
    } else {
      this.notificationService.readNotification(notify.id).then(() => {
        window.open(notify.actionLink, 'blank');
      });
    }
  }
}
