import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from '../i18n.service';
import { ListingOfferType } from '@app/models/transaction-type.model';
var ListingService = /** @class */ (function () {
    function ListingService(http, i18n) {
        this.http = http;
        this.i18n = i18n;
        this.featuredListingIdsEndpoint = "/api/_search/listings/featured/fast/all";
        this.listingByGeopositionEndpoint = "api/listings/by-coordinates";
        this.activeListingIdsEndpoint = "/api/_search/listings/active/fast/all";
        this.listingDetailsEndpoint = "/api/_search/listings/fast/detail";
        this.activeListingDetailsEndpoint = "/api/_search/listings/active/fast/detail";
        this.searchDtoFromUrlEndpoint = "/api/listings/search-dto-by-url/";
        this.urlFromSearchDtoEndpoint = "/api/listings/url-by-search-dto";
        this.countryStatesEndpoint = "/api/states/with-listings";
        this.citiesEndpoint = "/api/cities/with-listings";
        this.allCitiesByStateEndpoint = "/api/citysByState";
        this.citiesByStateEndpoint = "/api/cities-by-state/with-listings";
        this.citiesWithListingsEndpoint = "/api/cities-with-listings";
        this.listingSearchEndpoint = "/api/listing/searchListing/fast";
        this.listingIdsSearchEndpoint = "/api/listing/searchListingIds/fast";
        this.cityDetailEndpoint = "/api/citys/";
        this.marketSpotEndpoint = "/api/lookUpTables/market/spot/";
        this.stateDetailEndpoint = "/api/countryStates/";
        this.buildingsEndpoint = 'api/buildings';
        this.nearbySpotListingEndpoint = "/api/listing/searchListing/buildings/map";
        this.searchNearByListingEndpoint = "api/listing/searchListing/fast/map";
        this.listingAnalytics = '/api/listing/activities-tracking/all';
        this.setNewExpirationDateAPI = '/api/listings/renew-and-extend';
        this.isValidToRenewAndExtendListingsAPI = '/api/listings/renew-and-extend/isValid';
        this.countActiveAndExpiredListingsAPI = '/api/listings/renew-and-extend/count';
        this.checkDuplicateBuildingLocationAPI = 'api/building/check/duplicate/location';
        this.modelSource = new BehaviorSubject(null);
        this.currentModel = this.modelSource.asObservable();
        this.spotNearMeFilter = new BehaviorSubject(null);
        this.currentPage = 1;
        this.pageSize = 5;
        this.listings = [];
        this.activeListingIds = [];
    }
    Object.defineProperty(ListingService.prototype, "getCurrentPage", {
        get: function () {
            return this.currentPage;
        },
        enumerable: true,
        configurable: true
    });
    ListingService.prototype.updateModel = function (model) {
        this.modelSource.next(model);
    };
    ListingService.prototype.setSearchModel = function (model) {
        this.searchModel = model;
    };
    ListingService.prototype.getSearchModel = function () {
        return this.searchModel;
    };
    ListingService.prototype.getListingAnalytics = function (listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("" + this.listingAnalytics, listingIds).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.deleteDraft = function (id) {
        return this.http.delete("api/listings/" + id + "/delete-listing").pipe(map(function (val) { return val; }));
    };
    ListingService.prototype.getBuilding = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.buildingsEndpoint + "/" + id).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getBuildingToRevise = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.buildingsEndpoint + "/revise/" + id).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getFeaturedListingIds = function (cached) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(cached && this.activeListingIds.length)) return [3 /*break*/, 1];
                        return [2 /*return*/, this.activeListingIds];
                    case 1: return [4 /*yield*/, this.http.get(this.featuredListingIdsEndpoint).toPromise()];
                    case 2:
                        res = _a.sent();
                        this.activeListingIds = res;
                        return [2 /*return*/, res];
                }
            });
        });
    };
    ListingService.prototype.getActiveListingIds = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.activeListingIdsEndpoint).toPromise()];
                    case 1:
                        res = _a.sent();
                        this.activeListingIds = res;
                        return [2 /*return*/, res];
                }
            });
        });
    };
    ListingService.prototype.getDefaultActiveListings = function (pageSize) {
        var _this = this;
        return this.http
            .get(this.activeListingDetailsEndpoint + "?page=1&per_page=" + (pageSize || this.pageSize), {
            observe: 'response'
        })
            .pipe(map(function (val) {
            return {
                headers: val.headers,
                body: val.body
            };
        }))
            .toPromise()
            .then(function (response) {
            var listings = response.body;
            _this.appendListings(listings);
            return listings;
        });
    };
    ListingService.prototype.getListingDetails = function (pageSize, activeIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var urlSearchParams;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('per_page', "" + (pageSize || this.pageSize));
                        return [4 /*yield*/, this.http
                                .post(this.listingDetailsEndpoint + "?" + urlSearchParams, this.activeListingIds)
                                .toPromise()
                                .then(function (listings) {
                                _this.appendListings(listings);
                                return listings;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getActiveListingDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var urlSearchParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('per_page', '100');
                        return [4 /*yield*/, this.http.get(this.activeListingDetailsEndpoint + urlSearchParams.toString()).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getListingsSearchDto = function (searchUrl, keyword, buildingName) {
        var urlSearchParams = new URLSearchParams();
        if (keyword) {
            urlSearchParams.append('keyword', keyword);
            searchUrl += '?' + urlSearchParams.toString();
        }
        if (buildingName) {
            urlSearchParams.append('buildingName', buildingName);
            searchUrl += '?' + urlSearchParams.toString();
        }
        return this.http.get(this.searchDtoFromUrlEndpoint + searchUrl).toPromise();
    };
    ListingService.prototype.getUrlFromListingsSearchDto = function (searchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.urlFromSearchDtoEndpoint, this.handlingCoworkingPropertyType(searchDto))
                            .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.searchMyListings = function (searchDto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.listingSearchEndpoint, searchDto, { observe: 'response' })
                            .pipe(map(function (val) {
                            return {
                                headers: val.headers,
                                body: val.body
                            };
                        }))
                            .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.searchListings = function (searchDto, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var urlSearchParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('page', page.toString());
                        urlSearchParams.append('per_page', '10');
                        urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
                        return [4 /*yield*/, this.http
                                .post(this.listingSearchEndpoint + '?' + urlSearchParams, searchDto, { observe: 'response' })
                                .pipe(map(function (val) {
                                return {
                                    headers: val.headers,
                                    body: val.body
                                };
                            }))
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.searchListingIds = function (searchDto, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var urlSearchParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('page', page.toString());
                        urlSearchParams.append('per_page', '10');
                        urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
                        return [4 /*yield*/, this.http
                                .post(this.listingIdsSearchEndpoint + '?' + urlSearchParams, this.handlingCoworkingPropertyType(searchDto), {
                                observe: 'response'
                            })
                                .pipe(map(function (val) {
                                return {
                                    headers: val.headers,
                                    body: val.body
                                };
                            }))
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.countryStatesEndpoint).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getState = function (stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.stateDetailEndpoint + stateId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getCity = function (cityId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.cityDetailEndpoint + cityId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getMarket = function (marketId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.marketSpotEndpoint + marketId + '/' + this.i18n.getCurrentLanguage()).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Get All cities with buildings
     * @param stateId
     */
    ListingService.prototype.getCitiesByState = function (stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.citiesByStateEndpoint + "?stateId=" + stateId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getAllCitiesByState = function (stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.allCitiesByStateEndpoint + "?stateId=" + stateId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("" + this.citiesEndpoint).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.removeListingCredits = function (listingPurchaseId, credits) {
        return this.http
            .post('api/listing-purchase/' + listingPurchaseId + '/remove-credits?credits=' + credits, {})
            .pipe(map(function (val) { return val; }));
    };
    ListingService.prototype.queryByPage = function (page, pageSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.listingDetailsEndpoint + "?page=" + page + "&per_page=" + (pageSize || this.pageSize), this.activeListingIds)
                            .toPromise()
                            .then(function (response) {
                            var listings = response;
                            _this.appendListings(listings);
                            return listings;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getListingsByPage = function (page, pageSize, listingIds, coworkingPositions, coworkingType, searchId, myAnalytics) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, detailsURL;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = '';
                        if (coworkingPositions) {
                            params += '&coworkingPositions=' + coworkingPositions;
                        }
                        if (coworkingType) {
                            params += '&coworkingType=' + coworkingType;
                        }
                        detailsURL = this.listingDetailsEndpoint + "?page=" + page + "&per_page=" + pageSize + params + "&includeMetrics=" + myAnalytics;
                        if (searchId) {
                            detailsURL = detailsURL.concat("&searchTrackingId=" + searchId);
                        }
                        return [4 /*yield*/, this.http
                                .post(detailsURL, listingIds)
                                .toPromise()
                                .then(function (response) {
                                var listings = response;
                                return listings;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.appendListings = function (listings) {
        this.listings = tslib_1.__spread(this.listings, listings);
    };
    ListingService.prototype.fetchPage = function (page) {
        var _this = this;
        this.currentPage = page;
        return {
            pageQuery$: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.queryByPage(page)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            }); },
            nextPage: page + 1
        };
    };
    ListingService.prototype.getListingsByGeoposition = function (coordinates, radius) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchRadius;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchRadius = radius || 10;
                        return [4 /*yield*/, this.http
                                .get(this.listingByGeopositionEndpoint + "?latitude=" + coordinates.lat + "&longitude=" + coordinates.lng + "&radius=" + searchRadius)
                                .toPromise()];
                    case 1: //km
                    return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.searchNearByListing = function (searchDto, pageNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var urlSearchParams;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('page', pageNumber.toString());
                        urlSearchParams.append('per_page', '2000');
                        urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
                        return [4 /*yield*/, this.http
                                .post(this.nearbySpotListingEndpoint + '?' + urlSearchParams, this.handlingCoworkingPropertyType(searchDto), {
                                observe: 'response'
                            })
                                .pipe(map(function (val) {
                                return {
                                    headers: val.headers,
                                    body: val.body
                                };
                            }))
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.getAllNearByPropertyListing = function (searchDTO) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.searchNearByListingEndpoint, searchDTO, { observe: 'response' })
                            .pipe(map(function (value) {
                            return {
                                headers: value.headers,
                                body: value.body
                            };
                        }))
                            .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListingService.prototype.defaultCityState = function () {
        return ['pernambuco', 'são paulo', 'rio de janeiro', 'minas gerais', 'belo horizonte'];
    };
    ListingService.prototype.getDefaultCities = function (cities, defaultCities) {
        var _this = this;
        return cities.filter(function (item) {
            var itemName = _this.i18n.getTranslation(item.name).toLowerCase();
            return defaultCities.includes(itemName);
        });
    };
    ListingService.prototype.getDefaultStates = function (cities, states, defaultLocations) {
        var _this = this;
        return states.filter(function (item) {
            var itemName = _this.i18n.getTranslation(item.name).toLowerCase();
            var isExist = _this.isCityExist(cities, itemName);
            return !isExist && defaultLocations.includes(itemName);
        });
    };
    ListingService.prototype.isCityExist = function (cities, city) {
        var _this = this;
        return (cities.findIndex(function (item) {
            var itemName = _this.i18n.getTranslation(item.name).toLowerCase();
            return itemName === city.toLowerCase();
        }) !== -1);
    };
    ListingService.prototype.indexOfLocation = function (locations, locationName) {
        var _this = this;
        return locations.findIndex(function (item) { return _this.i18n.getTranslation(item.name).toLowerCase() === locationName.toLowerCase(); });
    };
    ListingService.prototype.sortLocation = function (locations) {
        var locationsCopy = locations.slice();
        var indexBeloHorizonte = this.indexOfLocation(locationsCopy, 'belo horizonte');
        var indexSaoPaulo = this.indexOfLocation(locationsCopy, 'são paulo');
        var beloHorizonte = Object.assign({}, locationsCopy[indexBeloHorizonte]);
        var saoPaulo = Object.assign({}, locationsCopy[indexSaoPaulo]);
        locationsCopy[indexBeloHorizonte] = saoPaulo;
        locationsCopy[indexSaoPaulo] = beloHorizonte;
        return locationsCopy;
    };
    ListingService.prototype.filterCityState = function (cities, states) {
        var defaultLocation = this.defaultCityState();
        var defaultCities = this.getDefaultCities(cities, defaultLocation);
        var defaultStates = this.getDefaultStates(defaultCities, states, defaultLocation);
        var sortedCityState = this.sortLocation(tslib_1.__spread(defaultCities, defaultStates));
        return sortedCityState;
    };
    ListingService.prototype.setLocationFilter = function (location) {
        var props = {};
        if (location.hasOwnProperty('countryState')) {
            props = {
                cityId: location.id,
                stateId: location.countryState.id
            };
        }
        else if (location.hasOwnProperty('country')) {
            props = {
                cityId: null,
                stateId: location.id
            };
        }
        else {
            props = {
                cityId: null,
                stateId: null
            };
        }
        return props;
    };
    ListingService.prototype.getCurrentLocation = function (currentLocation, locations) {
        var locationId = currentLocation.cityId ? currentLocation.cityId : currentLocation.stateId;
        var selectedLocation = locations.filter(function (item) { return item.id === locationId; });
        return selectedLocation.length ? selectedLocation[0] : 'other';
    };
    ListingService.prototype.getSelectedCompany = function (companies, companyId) {
        return companies.filter(function (company) { return company.id === companyId; });
    };
    ListingService.prototype.getSelectedAgent = function (agents, offeredByUserId) {
        return agents.filter(function (agent) { return agent.id === offeredByUserId; });
    };
    ListingService.prototype.setNewExpirationDateFor = function (listingIds, newExpirationDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post(this.setNewExpirationDateAPI + "/" + newExpirationDate, listingIds, { observe: 'response' })
                        .pipe(map(function (value) {
                        return {
                            headers: value.headers,
                            body: value.body
                        };
                    }))
                        .toPromise()];
            });
        });
    };
    ListingService.prototype.countActiveAndExpiredListings = function (listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.countActiveAndExpiredListingsAPI, listingIds, { observe: 'response' })
                        .pipe(map(function (value) {
                        return {
                            headers: value.headers,
                            body: value.body
                        };
                    }))
                        .toPromise()];
            });
        });
    };
    ListingService.prototype.isValidToRenewAndExtendListings = function (listingIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.isValidToRenewAndExtendListingsAPI, listingIds, { observe: 'response' })
                        .pipe(map(function (value) {
                        return {
                            headers: value.headers,
                            body: value.body
                        };
                    }))
                        .toPromise()];
            });
        });
    };
    ListingService.prototype.checkDuplicateBuildingLocation = function (buildingLocation) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.checkDuplicateBuildingLocationAPI, {
                        address: buildingLocation.address,
                        cityId: buildingLocation.cityId,
                        latitude: buildingLocation.marker.lat,
                        longitude: buildingLocation.marker.lng
                    }, { observe: 'response' })
                        .pipe(map(function (value) {
                        return {
                            headers: value.headers,
                            body: value.body
                        };
                    }))
                        .toPromise()];
            });
        });
    };
    ListingService.prototype.handlingCoworkingPropertyType = function (listingSearchDto) {
        var copySearchDTOForURL = tslib_1.__assign({}, listingSearchDto);
        if (listingSearchDto.buildingTypes && listingSearchDto.buildingTypes.includes(ListingOfferType.Coworking)) {
            copySearchDTOForURL.buildingTypes = null;
        }
        return copySearchDTOForURL;
    };
    return ListingService;
}());
export { ListingService };
