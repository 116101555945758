<mat-card *ngIf="!isPromoted" class="listing-card spot-building-cards" style="cursor: pointer;">
  <a class="router-link-seo listing-card-link d-flex flex-column" (click)="openCard(listing.id, $event, false)">
    <!-- Sliders Imgs -->
    <div class="image-container">
      <div id="listing-card-slides" class="image-height-card">
        <div *ngIf="!hideFavIcon" id="favoriteFloating" [class]="favoriteContainerClass">
          <div class="favorite__floating__section">
            <app-favorite-spot [spot]="listing" (favoriteChange)="favoriteChange($event)" [size]="favIconSize">
            </app-favorite-spot>
          </div>
        </div>
        <!-- Sliders Mobile -->
        <ion-slides
          class="slider-width-card"
          (ionSlideTransitionEnd)="getNextPhoto(listing.id, $event)"
          #photoSlider
          id="mobilePhotosSlider"
          *ngIf="listing.buildingListingPhotos"
        >
          <ion-slide *ngFor="let photo of listing.buildingListingPhotos; let i = index">
            <img
              *ngIf="i > 0"
              (click)="openCard(listing.id, $event, false)"
              [alt]="spotName"
              [title]="spotName"
              [defaultImage]="defaultImage"
              class="img-listing-card"
              [lazyLoad]="photo && photo.image ? getLargeImg(getCroppedOrOriginalImage(photo)) : photo"
            />

            <img
              *ngIf="i == 0"
              (click)="openCard(listing.id, $event, false)"
              [alt]="spotName"
              [title]="spotName"
              class="img-listing-card"
              [default]="defaultImage"
              [src]="photo && photo.image ? getLargeImg(getCroppedOrOriginalImage(photo)) : photo"
            />
          </ion-slide>
        </ion-slides>

        <div *ngIf="listing.premium" id="floatingPagination" class="premium__floating">
          <div class="premium__floating__section">
            <img style="width: 22px;" src="assets/images/icons/stars.svg" alt="" />
            <label class="premium__floating__text">{{ 'global.gold' | translate }}</label>
          </div>
        </div>
        <div *ngIf="listing.featured" id="floatingPagination" class="premium__floating">
          <div class="premium__floating__section">
            <img style="width: 22px;" src="assets/images/icons/auto_awesome.svg" alt="" />
            <label class="premium__floating__text">{{ 'global.platinum' | translate }}</label>
          </div>
        </div>

        <div
          class="image-dots"
          *ngIf="listing.buildingListingPhotos && listing.buildingListingPhotos.length > 1 && isMobile"
        >
          <span
            class="dot"
            [ngClass]="{ active: i == currentPhotoIndex }"
            *ngFor="let photo of listing.buildingListingPhotos; let i = index"
            (click)="slideToIndex($event, i)"
          >
          </span>
        </div>
      </div>
    </div>

    <!-- Card Information Desktop -->
    <div class="flex-col-nowrap flex-grow-1" (click)="openCard(listing.id, $event)">
      <ion-row>
        <div class="grid-card-container full-width">
          <!-- Header-->
          <div id="header" class="flex flex-column header">
            <div class="building-title-new" localize [textValue]="listing.buildingTitle"></div>
            <div class="flex">
              <span class="card-subheader">
                {{ getSubHeaderCardInfo() }}
              </span>
            </div>
          </div>

          <div class="card-info-separator"></div>

          <div class="spot-building-main-info spot__company__card__data">
            <div class="price__title" style="white-space: nowrap;">
              <span>{{ 'buildinsDetail.propertyType' | translate }}:</span>
            </div>
            <div class="price__title">
              <span>{{ commonService.getAvailableAreaLabel(listing) }}</span>
            </div>

            <div class="type__value">
              <span>{{ propertyType | translate }}</span>
            </div>
            <div class="type__value">
              <span>{{ getAvailableAreaText(listing) }}</span>
            </div>

            <div class="price__title">
              <span>{{ 'buildinsDetail.listingType' | translate }}:</span>
            </div>
            <div class="price__title">
              <span> {{ commonService.getAskingRentPriceLabel(listing) }} </span>
            </div>

            <div class="type__value">
              <span>{{ spotType | translate }}</span>
            </div>
            <div class="type__value">
              <span>{{ getAvgAskingRent() }}</span>
            </div>
          </div>

          <div class="flex full-width logo__fav_section">
            <div class="flex">
              <img
                style="width: 100px; object-fit: cover;"
                [src]="getCompanyLogoImg(listing.companyLogoImageId)"
                default="assets/no-company-logo.png"
              />
            </div>

            <div class="flex-align-content-end align-items-center"></div>
          </div>
        </div>
      </ion-row>
    </div>
  </a>

  <!-- Images Buttons Navigation -->
  <button (click)="getPreviousPhoto()" class="stdBackBtn" *ngIf="showPrevNextNav() && isMobile">
    <ion-icon name="arrow-back"></ion-icon>
  </button>

  <button (click)="getNextPhoto(listing.id, $event)" class="stdNextBtn" *ngIf="showPrevNextNav() && isMobile">
    <ion-icon name="arrow-forward"> </ion-icon>
  </button>
</mat-card>

<!-- isPromoted -->
<mat-card *ngIf="isPromoted" class="listing-card spot-building-cards" style="cursor: pointer;border-style: dashed;">
  <a *ngIf="promotedCard == 1" class="router-link-seo listing-card-link d-flex flex-column">
    <!-- Sliders Imgs -->
    <div class="image-container">
      <div id="listing-card-slides" class="image-height-card">
        <!-- isPromoted -->
        <ion-slides *ngIf="isPromoted" class="slider-width-card">
          <ion-slide>
            <img class="img-listing-card" src="assets/images/imgPromoted.png" alt="" />
          </ion-slide>
        </ion-slides>

        <!-- isPromoted -->
        <div *ngIf="isPromoted" id="floatingPagination" class="premium__floating">
          <div class="premium__floating__section">
            <img style="width: 22px;" src="assets/images/icons/stars.svg" alt="" />
            <label class="premium__floating__text">{{ 'global.gold' | translate }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Card Information Desktop -->
    <div class="flex-col-nowrap flex-grow-1">
      <ion-row>
        <div class="grid-card-container full-width">
          <!-- Header-->

          <!-- isPromoted -->
          <div *ngIf="isPromoted" id="header" class="flex flex-column header">
            <span class="building-title-new">Nave Eagle Ottawa</span>
            <div class="flex">
              <span class="card-subheader">
                Cesáreo Santos de León s/n, El Jarudo
              </span>
            </div>
          </div>

          <div class="card-info-separator"></div>

          <!-- isPromoted -->
          <div *ngIf="isPromoted" class="spot-building-main-info spot__company__card__data">
            <div class="price__title">
              <span>{{ 'buildinsDetail.propertyType' | translate }}:</span>
            </div>
            <div class="price__title">
              <span>{{ 'global.availableArea' | translate }}:</span>
            </div>

            <div class="type__value">
              <span>Nave Industrial</span>
            </div>
            <div class="type__value">
              <span>13,150.00 m²</span>
            </div>

            <div class="price__title">
              <span>{{ 'buildinsDetail.listingType' | translate }}:</span>
            </div>
            <div class="price__title">
              <span>{{ 'buildinsDetail.askingRentCard' | translate }}: </span>
            </div>

            <div class="type__value">
              <span>Venta</span>
            </div>
            <div class="type__value">
              <span> $27,520 - $410,200</span>
            </div>
          </div>

          <div class="flex full-width logo__fav_section" style="height: 51px;">
            <div class="flex">
              <!--    isPromoted -->
              <img *ngIf="isPromoted" style="width: 100px; object-fit: cover;" src="assets/images/logoCard.png" />
            </div>

            <div class="flex-align-content-end align-items-center"></div>
          </div>
        </div>
      </ion-row>
    </div>
  </a>

  <a *ngIf="promotedCard == 2" style="margin-top: 65%;" class="router-link-seo listing-card-link d-flex flex-column">
    <div class="row">
      <div class="col-md-12">
        <img style="margin: 0 auto;" src="assets/images/promotedCard.png" alt="" />
      </div>
      <div class="col-md-12">
        <span>{{ 'buildinsDetail.yourListingGoHere' | translate }}</span>
      </div>
    </div>
  </a>

  <a *ngIf="promotedCard == 3" style="margin-top: 65%;" class="router-link-seo listing-card-link d-flex flex-column">
    <div class="row">
      <div class="col-md-12">
        <img style="margin: 0 auto;" src="assets/images/promotedCard.png" alt="" />
      </div>
      <div class="col-md-12">
        <span>{{ 'buildinsDetail.yourListingGoHere' | translate }}</span>
      </div>
    </div>
  </a>
</mat-card>
