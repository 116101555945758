import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '@app/company/services/company.service';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { LocationService } from '@app/shared/services/location.service';
import { SpotBuildingsSearchService } from '@app/spot-buildings/services/spot-buildings-search.service';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { BrokersDTO } from '../../models/bokers.model';
import { BrokersService } from '../services/brokers.service';
import { AgentDTO } from '../../models/spot-buildings/agent-broker.model';
import { SocialMediaInput } from '@app/models/social-media.model';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material';
import { IDNameDTO } from '@app/models/id-name.model';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { StateDTO } from '@app/models/state.model';
import { RequestInfoInput, RequestInformationType } from '@app/models/request-information.model';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { SpotBuildingSearchRequest } from '@app/models/spot-buildings/spot-building-search.model';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { MetaService } from '@app/core/seo';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { BrokerMetatag } from '@app/models/spot-buildings/spot-buildings-types';

@Component({
  selector: 'app-broker-details',
  templateUrl: './broker-details.component.html',
  styleUrls: ['./broker-details.component.scss']
})
export class BrokerDetailsComponent implements OnInit {
  displayScrollToTop: boolean = false;
  isMobile: boolean;
  screenWidth: number;
  visibleAgentCards: number;
  searching: boolean = false;
  sharedIcon: any;
  defaultImage: any;
  brokerId: number;
  spotBuildingNames: string[];
  brokerDTO: AgentDTO;
  spotBuildingSearchRequest: SpotBuildingSearchRequest;
  brokerBIO: string;
  brokerHeaderURL: string;
  brokerDetailsURL: any;
  showEmail: boolean = true;
  showWhatsapp: boolean = true;
  currentDevice: string;
  isDesktop: string;
  isMobilePhone: string;
  isTablet: string;
  cookieAcceptanceName: string = 'SIILA-cookie-usage-acceptance';

  constructor(
    private activatedRoute: ActivatedRoute,
    private i18NService: I18nService,
    private breakpointObserver: BreakpointObserver,
    private brokersService: BrokersService,
    private platformConfigHelper: PlatformHelperService,
    private imageService: ImageService,
    private popOverCtrlHelper: PopoverCtrlHelper,
    private routingHelper: RoutingHelperService,
    private metaService: MetaService,
    private userActivityService: UserActivityService,
    private analyticsService: AnalyticsService,
    private deviceDetector: DeviceDetectorService,
    private _location: Location
  ) {}

  async ngOnInit() {
    this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
    this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
    this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
    if (this.isDesktop.length) {
      this.currentDevice = this.isDesktop;
    } else if (this.isMobilePhone.length) {
      this.currentDevice = this.isMobilePhone;
    } else if (this.isTablet.length) {
      this.currentDevice = this.isTablet;
    }

    this.screenWidth = window.innerWidth;
    this.sharedIcon = faShareAlt;
    this.findTotalAgentCards();
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    this.getParamsUrl();
  }

  async getParamsUrl() {
    this.activatedRoute.params.subscribe(async paramMap => {
      try {
        this.brokerId = paramMap.brokerId;
        this.brokersService.getMetadataById(this.brokerId).then((brokerMetadata: BrokerMetatag) => {
          this.metaService.setBrokerMetaTagsLight(brokerMetadata);
          (window as any).prerenderReady = true;
        });
        this.registerUserActivity(UserActivityType.PAGEVIEW_BROKER, this.brokerId);
        this.spotBuildingSearchRequest = { brokerId: this.brokerId };
        this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM'];

        this.brokerDetailsURL = await this.brokersService.getBrokerURL(this.brokerId);
        if (this.brokerDetailsURL) {
          if (this.i18NService.currentUrlContainsCurrentLanguage()) {
            this.brokerDetailsURL.detailsURL = this.i18NService.addCurrentLanguageToPath(
              this.brokerDetailsURL.detailsURL
            );
          }
          this._location.go(this.brokerDetailsURL.detailsURL);
        }

        this.brokersService.getById(this.brokerId).then((broker: any) => {
          this.brokerDTO = broker;
          this.brokerBIO = this.brokerDTO.bio ? this.i18NService.getTranslation(this.brokerDTO.bio) : null;
          this.brokersService.getSpotTitlesByBrokerId(this.brokerId).then((result: any) => {
            this.spotBuildingNames = result;
            //this.metaService.setBrokerMetaTags(this.brokerDTO, this.brokerBIO, this.spotBuildingNames);
          });

          let brokerHeader = 'Header_0.png';
          if (this.brokerDTO.brokerHeader) {
            brokerHeader = this.brokerDTO.brokerHeader;
          }
          this.brokerHeaderURL = `../../../assets/images/broker-headers/${brokerHeader}`;
        });
      } catch (error) {
        console.error('Error : ', error);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.findTotalAgentCards();
  }

  navigateToCompany() {
    if (this.brokerDTO.companyProfile) {
      this.routingHelper.navigateToCompanyDetailsPage(this.brokerDTO.companyId);
    }
  }

  isFooterVisible(event: any) {
    this.displayScrollToTop = event.visible;
  }

  findTotalAgentCards() {
    this.visibleAgentCards = 3;
    if (this.screenWidth < 1024) {
      this.visibleAgentCards = 1;
    } else if (this.screenWidth < 1200) {
      this.visibleAgentCards = 2;
    }
    //this.updateDots();
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  private buildTitleMetatag(broker: AgentDTO) {
    let metaTagTitle = '';

    const brokerTitleTag = broker.firstNameAndLastName;

    metaTagTitle = `${brokerTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(brokerTitleTag);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  private setBrokerMetaTags() {
    const detailsMetaTags = this.metaService.buildBrokerMetatag(this.brokerDTO, this.spotBuildingNames);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = this.brokerBIO ? this.removeHTMLCode(this.i18NService.getTranslation(this.brokerBIO)) : '';
    const locale = this.i18NService.getCurrentLocale();
    const brokerTitle = this.i18NService.getTranslation(this.brokerDTO.firstNameAndLastName);
    const fullDetailsURL = window.location.href;

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [brokerTitle] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          this.brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.brokerDTO.photoId)}`)
            : null
      },
      {
        name: 'image',
        content:
          this.brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.brokerDTO.photoId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [brokerTitle] },
      {
        name: 'twitter:image',
        content:
          this.brokerDTO.photoId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.brokerDTO.photoId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.metaService.setDynamicTags(tags, fullTitleText);
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }

  sendMessage(event: any) {
    let sentEmailTo: string[] = [];
    sentEmailTo.push(this.brokerDTO.userEmail);
    let reqInfoInput: RequestInfoInput = {
      requestTitle: this.brokerDTO.firstNameAndLastName,
      brokerRequest: true,
      sentEmailTo: sentEmailTo,
      requestType: RequestInformationType.REQ_BROKER
    };
    this.popOverCtrlHelper.openRequestInformationPopup(event, reqInfoInput);
  }

  shareCompany(event: any) {
    const url = window.location.href;
    const sharedTitle = this.brokerDTO.firstNameAndLastName;
    const modalTitle = this.i18NService.get('shareBrokerProfile');

    const input: SocialMediaInput = { url, sharedTitle, modalTitle };
    this.userActivityService.trackBrokerShare(this.brokerDTO.brokerId);

    this.popOverCtrlHelper.openSocialMediaPopup(event, input);
  }

  getDefaultTranslation(translationArtifact: any) {
    try {
      return this.i18NService.getTranslation(translationArtifact);
    } catch (e) {
      console.error(e);
    }
  }

  openEmail(event: any) {
    event.stopImmediatePropagation();
    if (!this.showEmail) {
      window.location.href = `mailto:${this.brokerDTO.userEmail}`;
    }
    this.showEmail = false;
    this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL, this.brokerId);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    return false;
  }

  openWhatsapp(event: any) {
    event.stopImmediatePropagation();
    this.showWhatsapp = false;

    this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP, this.brokerId);
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    return false;
  }

  registerUserActivity(userActivityType: UserActivityType, brokerId: number) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: brokerId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }

  checkCookiesUsageAcceptance() {
    let cookieAcceptance: boolean = false;
    cookieAcceptance = !!window.localStorage.getItem(this.cookieAcceptanceName);
    return cookieAcceptance;
  }
}
