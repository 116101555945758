import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FeaturedListing } from '@app/models/featured-listing.model';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from '../i18n.service';
import * as MyListingModel from '@app/models/my-listing.model';
import { BuildingLocation } from '@app/shared/maps/map/map.component';
import { ListingOfferType } from '@app/models/transaction-type.model';

@Injectable()
export class ListingService {
  private featuredListingIdsEndpoint: string = `/api/_search/listings/featured/fast/all`;
  private listingByGeopositionEndpoint: string = `api/listings/by-coordinates`;
  private activeListingIdsEndpoint: string = `/api/_search/listings/active/fast/all`;
  private listingDetailsEndpoint: string = `/api/_search/listings/fast/detail`;
  private activeListingDetailsEndpoint: string = `/api/_search/listings/active/fast/detail`;
  private searchDtoFromUrlEndpoint: string = `/api/listings/search-dto-by-url/`;
  private urlFromSearchDtoEndpoint: string = `/api/listings/url-by-search-dto`;
  private countryStatesEndpoint: string = `/api/states/with-listings`;
  private citiesEndpoint: string = `/api/cities/with-listings`;
  private allCitiesByStateEndpoint: string = `/api/citysByState`;
  private citiesByStateEndpoint: string = `/api/cities-by-state/with-listings`;
  private citiesWithListingsEndpoint: string = `/api/cities-with-listings`;
  private listingSearchEndpoint: string = `/api/listing/searchListing/fast`;
  private listingIdsSearchEndpoint: string = `/api/listing/searchListingIds/fast`;
  private cityDetailEndpoint: string = `/api/citys/`;
  private marketSpotEndpoint: string = `/api/lookUpTables/market/spot/`;
  private stateDetailEndpoint: string = `/api/countryStates/`;
  private buildingsEndpoint: string = 'api/buildings';
  private nearbySpotListingEndpoint: string = `/api/listing/searchListing/buildings/map`;
  private searchNearByListingEndpoint: string = `api/listing/searchListing/fast/map`;
  private listingAnalytics: string = '/api/listing/activities-tracking/all';
  private setNewExpirationDateAPI: string = '/api/listings/renew-and-extend';
  private isValidToRenewAndExtendListingsAPI: string = '/api/listings/renew-and-extend/isValid';
  private countActiveAndExpiredListingsAPI: string = '/api/listings/renew-and-extend/count';
  private checkDuplicateBuildingLocationAPI: string = 'api/building/check/duplicate/location';
  private modelSource = new BehaviorSubject(null);
  currentModel = this.modelSource.asObservable();
  public spotNearMeFilter = new BehaviorSubject<boolean>(null);

  currentPage: number = 1;
  pageSize: number = 5;
  listings: FeaturedListing[] = [];
  activeListingIds: any[] = [];
  searchModel: any;
  propertySubTypesFromHomepage: any[];
  coworkingFilterFromHomepage: any;

  constructor(private http: HttpClient, private i18n: I18nService) {}

  public get getCurrentPage(): number {
    return this.currentPage;
  }

  updateModel(model: any) {
    this.modelSource.next(model);
  }

  setSearchModel(model: any) {
    this.searchModel = model;
  }

  getSearchModel() {
    return this.searchModel;
  }

  async getListingAnalytics(listingIds: any) {
    return await this.http.post(`${this.listingAnalytics}`, listingIds).toPromise();
  }

  deleteDraft(id: number) {
    return this.http.delete(`api/listings/${id}/delete-listing`).pipe(map(val => val));
  }

  async getBuilding(id: number) {
    return await this.http.get(`${this.buildingsEndpoint}/${id}`).toPromise();
  }

  async getBuildingToRevise(id: number) {
    return await this.http.get(`${this.buildingsEndpoint}/revise/${id}`).toPromise();
  }

  async getFeaturedListingIds(cached?: boolean) {
    if (cached && this.activeListingIds.length) {
      return this.activeListingIds;
    } else {
      const res: any = await this.http.get(this.featuredListingIdsEndpoint).toPromise();
      this.activeListingIds = res;
      return res;
    }
  }

  async getActiveListingIds() {
    const res: any = await this.http.get(this.activeListingIdsEndpoint).toPromise();
    this.activeListingIds = res;
    return res;
  }

  getDefaultActiveListings(pageSize: number): Promise<any> {
    return this.http
      .get(this.activeListingDetailsEndpoint + `?page=1&per_page=` + (pageSize || this.pageSize), {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise()
      .then((response: any) => {
        const listings = response.body;
        this.appendListings(listings);

        return listings;
      });
  }

  async getListingDetails(pageSize: number, activeIds?: any[]) {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('per_page', `${pageSize || this.pageSize}`);
    return await this.http
      .post(`${this.listingDetailsEndpoint}?${urlSearchParams}`, this.activeListingIds)
      .toPromise()
      .then((listings: any[]) => {
        this.appendListings(listings);
        return listings;
      });
  }

  async getActiveListingDetails() {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('per_page', '100');
    return await this.http.get(this.activeListingDetailsEndpoint + urlSearchParams.toString()).toPromise();
  }

  getListingsSearchDto(searchUrl: string, keyword: string, buildingName: string) {
    let urlSearchParams = new URLSearchParams();
    if (keyword) {
      urlSearchParams.append('keyword', keyword);
      searchUrl += '?' + urlSearchParams.toString();
    }
    if (buildingName) {
      urlSearchParams.append('buildingName', buildingName);
      searchUrl += '?' + urlSearchParams.toString();
    }
    return this.http.get(this.searchDtoFromUrlEndpoint + searchUrl).toPromise();
  }

  async getUrlFromListingsSearchDto(searchDto: any) {
    return await this.http
      .post(this.urlFromSearchDtoEndpoint, this.handlingCoworkingPropertyType(searchDto))
      .toPromise();
  }

  async searchMyListings(searchDto: any) {
    return await this.http
      .post(this.listingSearchEndpoint, searchDto, { observe: 'response' })
      .pipe(
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise();
  }

  async searchListings(searchDto: any, page: number): Promise<any> {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('page', page.toString());
    urlSearchParams.append('per_page', '10');
    urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
    return await this.http
      .post(this.listingSearchEndpoint + '?' + urlSearchParams, searchDto, { observe: 'response' })
      .pipe(
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise();
  }

  async searchListingIds(searchDto: any, page: number): Promise<any> {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('page', page.toString());
    urlSearchParams.append('per_page', '10');
    urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
    return await this.http
      .post(this.listingIdsSearchEndpoint + '?' + urlSearchParams, this.handlingCoworkingPropertyType(searchDto), {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise();
  }

  async getStates() {
    return await this.http.get(this.countryStatesEndpoint).toPromise();
  }

  async getState(stateId: number): Promise<any> {
    return await this.http.get(this.stateDetailEndpoint + stateId).toPromise();
  }

  async getCity(cityId: number): Promise<any> {
    return await this.http.get(this.cityDetailEndpoint + cityId).toPromise();
  }

  async getMarket(marketId: number): Promise<any> {
    return await this.http.get(this.marketSpotEndpoint + marketId + '/' + this.i18n.getCurrentLanguage()).toPromise();
  }

  /**
   * Get All cities with buildings
   * @param stateId
   */
  async getCitiesByState(stateId: number) {
    return await this.http.get(`${this.citiesByStateEndpoint}?stateId=${stateId}`).toPromise();
  }

  async getAllCitiesByState(stateId: number) {
    return await this.http.get(`${this.allCitiesByStateEndpoint}?stateId=${stateId}`).toPromise();
  }

  async getCities() {
    return await this.http.get(`${this.citiesEndpoint}`).toPromise();
  }

  removeListingCredits(listingPurchaseId: number, credits: number) {
    return this.http
      .post('api/listing-purchase/' + listingPurchaseId + '/remove-credits?credits=' + credits, {})
      .pipe(map(val => val));
  }

  async queryByPage(page: number, pageSize?: number) {
    return await this.http
      .post(`${this.listingDetailsEndpoint}?page=${page}&per_page=${pageSize || this.pageSize}`, this.activeListingIds)
      .toPromise()
      .then((response: FeaturedListing[]) => {
        const listings = response;
        this.appendListings(listings);
        return listings;
      });
  }

  async getListingsByPage(
    page: number,
    pageSize: number,
    listingIds: any[],
    coworkingPositions: number,
    coworkingType: string,
    searchId: number,
    myAnalytics?: boolean
  ) {
    let params = '';
    if (coworkingPositions) {
      params += '&coworkingPositions=' + coworkingPositions;
    }
    if (coworkingType) {
      params += '&coworkingType=' + coworkingType;
    }
    let detailsURL = `${this.listingDetailsEndpoint}?page=${page}&per_page=${pageSize}${params}&includeMetrics=${myAnalytics}`;
    if (searchId) {
      detailsURL = detailsURL.concat(`&searchTrackingId=${searchId}`);
    }
    return await this.http
      .post(detailsURL, listingIds)
      .toPromise()
      .then((response: any[]) => {
        const listings = response;
        return listings;
      });
  }

  public appendListings(listings: any[]) {
    this.listings = [...this.listings, ...listings];
  }

  fetchPage(page: number) {
    this.currentPage = page;
    return {
      pageQuery$: async () => {
        return await this.queryByPage(page);
      },
      nextPage: page + 1
    };
  }

  async getListingsByGeoposition(coordinates: any, radius: number) {
    const searchRadius = radius || 10; //km
    return await this.http
      .get(
        `${this.listingByGeopositionEndpoint}?latitude=${coordinates.lat}&longitude=${coordinates.lng}&radius=${searchRadius}`
      )
      .toPromise();
  }

  async searchNearByListing(searchDto: any, pageNumber: number) {
    let urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('page', pageNumber.toString());
    urlSearchParams.append('per_page', '2000');
    urlSearchParams.append('lang', this.i18n.getCurrentLanguage());
    return await this.http
      .post(this.nearbySpotListingEndpoint + '?' + urlSearchParams, this.handlingCoworkingPropertyType(searchDto), {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return {
            headers: val.headers,
            body: val.body
          };
        })
      )
      .toPromise();
  }

  async getAllNearByPropertyListing(searchDTO: any) {
    return await this.http
      .post(this.searchNearByListingEndpoint, searchDTO, { observe: 'response' })
      .pipe(
        map(value => {
          return {
            headers: value.headers,
            body: value.body
          };
        })
      )
      .toPromise();
  }

  private defaultCityState() {
    return ['pernambuco', 'são paulo', 'rio de janeiro', 'minas gerais', 'belo horizonte'];
  }

  private getDefaultCities(cities: any[], defaultCities: any[]): any[] {
    return cities.filter((item: any) => {
      const itemName: string = this.i18n.getTranslation(item.name).toLowerCase();
      return defaultCities.includes(itemName);
    });
  }

  private getDefaultStates(cities: any[], states: any[], defaultLocations: any[]) {
    return states.filter((item: any) => {
      const itemName: string = this.i18n.getTranslation(item.name).toLowerCase();
      const isExist: boolean = this.isCityExist(cities, itemName);
      return !isExist && defaultLocations.includes(itemName);
    });
  }

  private isCityExist(cities: any[], city: string): boolean {
    return (
      cities.findIndex((item: any) => {
        const itemName: string = this.i18n.getTranslation(item.name).toLowerCase();
        return itemName === city.toLowerCase();
      }) !== -1
    );
  }

  private indexOfLocation(locations: any[], locationName: string) {
    return locations.findIndex(
      (item: any) => this.i18n.getTranslation(item.name).toLowerCase() === locationName.toLowerCase()
    );
  }

  private sortLocation(locations: any[]) {
    const locationsCopy: any[] = locations.slice();
    const indexBeloHorizonte: number = this.indexOfLocation(locationsCopy, 'belo horizonte');
    const indexSaoPaulo: number = this.indexOfLocation(locationsCopy, 'são paulo');
    const beloHorizonte: any = Object.assign({}, locationsCopy[indexBeloHorizonte]);
    const saoPaulo: any = Object.assign({}, locationsCopy[indexSaoPaulo]);
    locationsCopy[indexBeloHorizonte] = saoPaulo;
    locationsCopy[indexSaoPaulo] = beloHorizonte;
    return locationsCopy;
  }

  public filterCityState(cities: any[], states: any[]) {
    const defaultLocation: any[] = this.defaultCityState();
    const defaultCities: any[] = this.getDefaultCities(cities, defaultLocation);
    const defaultStates: any[] = this.getDefaultStates(defaultCities, states, defaultLocation);
    const sortedCityState: any[] = this.sortLocation([...defaultCities, ...defaultStates]);
    return sortedCityState;
  }

  public setLocationFilter(location: any) {
    let props: any = {};
    if (location.hasOwnProperty('countryState')) {
      props = {
        cityId: location.id,
        stateId: location.countryState.id
      };
    } else if (location.hasOwnProperty('country')) {
      props = {
        cityId: null,
        stateId: location.id
      };
    } else {
      props = {
        cityId: null,
        stateId: null
      };
    }
    return props;
  }

  public getCurrentLocation(currentLocation: any, locations: any[]) {
    const locationId: number = currentLocation.cityId ? currentLocation.cityId : currentLocation.stateId;
    const selectedLocation: any = locations.filter((item: any) => item.id === locationId);
    return selectedLocation.length ? selectedLocation[0] : 'other';
  }

  public getSelectedCompany(companies: Array<MyListingModel.MyListingCompany>, companyId: number) {
    return companies.filter((company: any) => company.id === companyId);
  }

  public getSelectedAgent(agents: any, offeredByUserId: number) {
    return agents.filter((agent: any) => agent.id === offeredByUserId);
  }

  public async setNewExpirationDateFor(listingIds: Array<number>, newExpirationDate: any) {
    return this.http
      .post(`${this.setNewExpirationDateAPI}/${newExpirationDate}`, listingIds, { observe: 'response' })
      .pipe(
        map(value => {
          return {
            headers: value.headers,
            body: value.body
          };
        })
      )
      .toPromise();
  }

  public async countActiveAndExpiredListings(listingIds: Array<number>) {
    return this.http
      .post(`${this.countActiveAndExpiredListingsAPI}`, listingIds, { observe: 'response' })
      .pipe(
        map(value => {
          return {
            headers: value.headers,
            body: value.body
          };
        })
      )
      .toPromise();
  }

  public async isValidToRenewAndExtendListings(listingIds: Array<number>) {
    return this.http
      .post(`${this.isValidToRenewAndExtendListingsAPI}`, listingIds, { observe: 'response' })
      .pipe(
        map(value => {
          return {
            headers: value.headers,
            body: value.body
          };
        })
      )
      .toPromise();
  }

  public async checkDuplicateBuildingLocation(buildingLocation: BuildingLocation) {
    return this.http
      .post(
        `${this.checkDuplicateBuildingLocationAPI}`,
        {
          address: buildingLocation.address,
          cityId: buildingLocation.cityId,
          latitude: buildingLocation.marker.lat,
          longitude: buildingLocation.marker.lng
        },
        { observe: 'response' }
      )
      .pipe(
        map(value => {
          return {
            headers: value.headers,
            body: value.body
          };
        })
      )
      .toPromise();
  }

  public handlingCoworkingPropertyType(listingSearchDto: any) {
    let copySearchDTOForURL = { ...listingSearchDto };
    if (listingSearchDto.buildingTypes && listingSearchDto.buildingTypes.includes(ListingOfferType.Coworking)) {
      copySearchDTOForURL.buildingTypes = null;
    }
    return copySearchDTOForURL;
  }
}
