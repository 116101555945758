<ion-row *ngIf="listing">
  <div class="full-width">
    <ion-row>
      <ion-col size="12">
        <ion-button class="close-btn right" fill="outline" color="primary" (click)="cancel()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

  <ion-row>
    <ion-row style="margin-top: 10px; margin-bottom: 10px; margin-left: 25px; display: inline-block;">
      <ion-label style="padding-bottom: 5px; color: #043650;"
        >{{ 'global.adminUpdates.listingClass' | translate }}:
      </ion-label>
      <ion-row [ngClass]="{ 'no-events': listing.listingType === 'BASIC' }" style="border: 1px solid lightgrey;">
        <span class="width-full my-listing-company">
          <ionic-selectable
            style="width: 250px; height: 40px;"
            [isMultiple]="false"
            [(ngModel)]="listingClass"
            [items]="classes"
            itemValueField="id"
            itemTextField="name"
            [hasVirtualScroll]="true"
            placeholder="{{ 'global.adminUpdates.listingClass' | translate }}"
            closeButtonText="{{ 'Cancel' | translate }}"
            #statusComponent
          >
          </ionic-selectable>
        </span>
      </ion-row>
    </ion-row>

    <!-- <ion-row style="margin-top: 10px; margin-bottom: 10px; margin-left: 25px;; display: inline-block;">
        <ion-label style="padding-bottom: 5px; color: #043650;">Listing Status:</ion-label>
        <ion-row style="border: 1px solid lightgrey;">
          <span class="width-full my-listing-company">
            <ionic-selectable style="width: 250px; height: 40px;" [isMultiple]="false" [(ngModel)]="listingStatus"
              [items]="statuses" itemValueField="id" itemTextField="status" [hasVirtualScroll]="true" placeholder="Status"
              #statusComponent>
            </ionic-selectable>
          </span>
        </ion-row>
      </ion-row> -->

    <ion-row style="margin-top: 10px; margin-bottom: 20px; margin-left: 25px; display: inline-block;">
      <ion-label style="padding-bottom: 5px; color: #043650;"
        >{{ 'global.adminUpdates.expirationDate' | translate }}:
      </ion-label>
      <ion-row
        [ngClass]="{
          'no-events':
            listing.listingStatus?.id !== 4 &&
            listing.listingStatus?.id !== 5 &&
            listing.listingStatus?.id !== 8 &&
            listing.listingStatus?.id !== 1
        }"
      >
        <span class="width-full date-picker" style="position: relative;">
          <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
          <input
            matInput
            [ngClass]="{
              'red-border':
                checkIfDate() &&
                (listing.listingStatus?.id === 4 || listing.listingStatus?.id === 8 || listing.listingStatus?.id === 1)
            }"
            [matDatepicker]="picker"
            cdkFocusInitial
            [(ngModel)]="expirationDate"
            (ngModelChange)="checkDate($event)"
            placeholder="{{ 'global.adminUpdates.chooseDate' | translate }}"
          />
          <mat-datepicker #picker></mat-datepicker>
        </span>
      </ion-row>
    </ion-row>
  </ion-row>

  <!-- ADD RADIO BUTTONS FOR SELECTION-->
  <ion-col class="border-bottom-sep" size="12">
    <ion-button
      class="right"
      color="primary"
      fill="solid"
      [disabled]="
        checkIfDate() &&
        (listing.listingStatus?.id === 4 ||
          listing.listingStatus?.id === 5 ||
          listing.listingStatus?.id === 8 ||
          listing.listingStatus?.id === 1)
      "
      (click)="update()"
    >
      {{ 'global.adminUpdates.save' | translate }}
    </ion-button>
    <ion-button class="right margin-right-20" color="secondary" fill="outline" (click)="cancel()" translate="Cancel">
    </ion-button>
  </ion-col>

  <ion-row class="row-margin" *ngIf="listing?.listingStatus.id !== 10">
    <p class="center-text">{{ 'global.adminUpdates.adminText' | translate }}</p>
    <button ion-button class="browse-button" (click)="displayAlert()">
      {{ 'global.adminUpdates.removeListing' | translate }}
    </button>
  </ion-row>

  <ion-row class="row-margin" *ngIf="listing?.listingStatus.id === 10 && !listing?.nextListingId">
    <p class="center-text">{{ 'global.adminUpdates.activateText' | translate }}</p>
    <br />
    <button ion-button class="browse-button" (click)="displayActivateAlert()">
      {{ 'global.adminUpdates.activateListing' | translate }}
    </button>
  </ion-row>
</ion-row>
