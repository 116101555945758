import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbOptions } from '@app/shared';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { PopoverController, NavParams, AlertController } from '@ionic/angular';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';

@Component({
  selector: 'admin-updates',
  templateUrl: './admin-updates.component.html',
  styleUrls: ['./admin-updates.component.scss']
})
export class AdminUpdatesComponent {
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  listingClass: any;
  expirationDate: Date;
  renewalChoice: any = 'activate';
  creditId: any;
  listingId: any;
  statuses: any;
  classes: any;
  listing: any;
  listingStatus: any;
  constructor(
    private router: Router,
    private purchaseCreditsService: PurchaseCreditsService,
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
    private registerService: RegisterService,
    private _ts: TranslateService,
    private creditService: PurchaseCreditsService,
    private _eventChecker: EventCheckerService,
    private alertCtrl: AlertController,
    private purchaseService: PurchaseCreditsService,
    private i18nService: I18nService,
    private listingDetailService: ListingDetailService
  ) {}

  ngOnInit() {
    this.getStatuses();
  }

  async getStatuses() {
    this.classes = [{ name: 'FEATURED', id: 0 }, { name: 'PREMIUM', id: 1 }, { name: 'PROMOTED', id: 2 }];
    this.statuses = await this.getStatusType();
    this.statuses = await this.getStatusTranslations(this.statuses);
    this.listing = this.navParams.data.listing;
    this.setAdminValues(this.listing);
  }

  async getStatusType() {
    return this.registerService.getListingStatus().toPromise();
  }

  async getStatusTranslations(statuses: any) {
    Object.keys(statuses).forEach(async (key, idx) => {
      statuses[key].status = this.getI18nValue(statuses[key].status);
    });
    return statuses;
  }

  getI18nValue(textValue: string) {
    try {
      if (textValue && JSON.parse(textValue)) {
        return this.i18nService.getTranslation(textValue);
      } else {
        return '';
      }
    } catch (e) {
      console.error('failed to parse locale string -> listings search');
    }
  }

  getPopData() {}

  async setAdminValues(listing: any) {
    let index = this.classes.findIndex((v: any) => {
      return v.name === listing.listingType;
    });
    this.listingClass = this.classes[index];
    /*let statusIndex = this.statuses.findIndex((v: any) => {
      return v.id === listing.listingStatus.id;
    });
    this.listingStatus = this.statuses[statusIndex];*/
    this.expirationDate = listing.endDate ? new Date(listing.endDate) : null;
  }

  checkDate(ev: any) {}

  choiceSelection(event: any) {
    this.renewalChoice = event.detail.value;
  }

  async displayAlert(approval: any) {
    const alert = await this.alertCtrl.create({
      header: this._ts.instant('global.removeListing'),
      subHeader: this._ts.instant('global.removeListingText'),
      cssClass: 'ion-alert-listings',
      backdropDismiss: true,
      buttons: [
        {
          text: this._ts.instant('Cancel'),
          role: 'Cancel',
          handler: () => {}
        },
        {
          text: this._ts.instant('Ok'),
          handler: () => {
            this.spotDeactivate();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  async numDaysBetween(d1: Date, d2: Date) {
    let diff = d1.getTime() - d2.getTime();
    return diff / (1000 * 60 * 60 * 24);
  }

  async displayActivateAlert(approval: any) {
    let expired: boolean;
    let d1 = new Date();
    let d2 = new Date(this.listing.endDate);
    let differenceDate = await this.numDaysBetween(d1, d2);
    differenceDate > 0 ? (expired = true) : (expired = false);
    const alert = await this.alertCtrl.create({
      header: this._ts.instant('global.adminUpdates.activateListing'),
      subHeader: expired
        ? this._ts.instant('global.adminUpdates.activateExpired')
        : !this.listing.priorListingId
        ? this._ts.instant('global.adminUpdates.activate')
        : this._ts.instant('global.adminUpdates.activateRevision'),
      cssClass: 'ion-alert-listings',
      backdropDismiss: true,
      buttons: [
        {
          text: this._ts.instant('Cancel'),
          role: 'Cancel',
          handler: () => {}
        },
        {
          text: this._ts.instant('Ok'),
          handler: () => {
            this.updateReactivatedListing();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  async updateReactivatedListing() {
    let listing = await this.purchaseService.reactivateListing(this.listing.id);
    if (listing) {
      this._eventChecker.updateListing$.next(listing);
      this.popoverCtrl.dismiss();
    }
  }

  checkIfDate() {
    if (this.expirationDate) {
      if (
        this.expirationDate instanceof Date &&
        Object.prototype.toString.call(this.expirationDate) === '[object Date]'
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async spotDeactivate() {
    let listing = await this.purchaseCreditsService.spotDeactivate(
      this.listing.status.id === 8 ? this.listing.priorListingId : this.listing.id
    );
    this._eventChecker.updateListing$.next(listing);
    if (this.listing.nextListingStatus && this.listing.nextListingStatus.id === 1) {
      this._eventChecker.removeListing$.next(this.listing.nextListingId);
    }
    if (this.listing.listingStatus.id === 1) {
      this._eventChecker.removeListing$.next(this.listing.id);
      let response: any = await this.listingDetailService.getListingDetails(this.listing.priorListingId).toPromise();
      let updatedListing: any = response.listing;
      updatedListing.listingPhotos = [];
      updatedListing.buildingTitle = updatedListing.title;
      updatedListing.cityName = updatedListing.building.city.name;
      updatedListing.buildingTitle = updatedListing.building.title;
      updatedListing.buildingTypeId = updatedListing.building.buildingType.id;
      updatedListing.buildingId = updatedListing.building.id;
      updatedListing.buildingAddress = updatedListing.building.address;
      this._eventChecker.paUpdate$.next(updatedListing);
    }
    this.popoverCtrl.dismiss();
  }

  async update() {
    this.listing.listingType = this.listingClass.id;
    this.listing.endDate = this.expirationDate;
    let update = await this.purchaseCreditsService.adminListingUpdate(this.listing);
    this._eventChecker.updateListing$.next(update);
    this.popoverCtrl.dismiss();
    if (
      this.listing.listingStatus.id === 4 &&
      this.listing.nextListingStatus &&
      this.listing.nextListingStatus.id === 4
    ) {
      let updatedListing = await this.listingDetailService.getListingDetails(this.listing.nextListingId);
      this._eventChecker.paUpdate$.next(updatedListing);
    }
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }
}
