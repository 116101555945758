<div class="row content-border">
  <div class="col-md-12 content-filter">
    <div class="col-md-8 title-div">
      <span>{{ 'favorites.savedSearches.title' | translate }}</span>
    </div>
    <div class="col-md-4 filter-div">
      <fa-icon
        style="font-size: 14px;
          color: white;
          position: absolute;
          margin: 24px 0px 0px 0px; cursor: pointer; position: inherit;"
        class="arrow-icon "
        [icon]="search"
        size="lg"
        (click)="searchProperty()"
      >
      </fa-icon>
      <input
        *ngIf="seeSearch"
        (keyup)="searchFilter()"
        (blur)="searchFilter()"
        style="width: 90%;
             margin: 19px -10px 18px 10px;
          background: #00334D;
          border: none;
          border-bottom: 1px solid white;
          color: white;"
        type="text"
        [(ngModel)]="filterTourBook"
      />
      <button
        class="btn-profile"
        mat-button
        [matMenuTriggerFor]="filterSet"
        style="
          color: white;
          margin-top: 6px;"
      >
        <fa-icon style="font-size: 11px;" class="arrow-icon " [icon]="filter" size="lg"> </fa-icon>
      </button>
      <mat-menu #filterSet="matMenu">
        <button mat-menu-item (click)="nameAscending()">
          {{ 'favorites.nameAscending' | translate }}
        </button>
        <button mat-menu-item (click)="nameDescending()">
          {{ 'favorites.nameDescending' | translate }}
        </button>
        <button mat-menu-item (click)="oldestFirst()">
          {{ 'favorites.oldestFirst' | translate }}
        </button>
        <button mat-menu-item (click)="newestFirst()">
          {{ 'favorites.newestFirst' | translate }}
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="col-md-12" style="padding: 0px">
    <table *ngIf="!isMobile" class="table responsivegene">
      <thead class="thead-light" style="font-size: 14px;">
        <tr>
          <th>{{ 'favorites.savedSearches.bookTitle' | translate }}</th>
          <th>{{ 'favorites.savedSearches.listingType' | translate }}</th>
          <th>{{ 'favorites.savedSearches.features' | translate }}</th>

          <th>{{ 'favorites.savedSearches.modified' | translate }}</th>
          <th>{{ 'favorites.spotSets.actions' | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="savedSearchesList?.content">
        <tr *ngFor="let item of savedSearchesList?.content">
          <td>
            {{ item.name }}
          </td>
          <td>
            {{ item.listingType }}
          </td>
          <td>
            <span matTooltip [matTooltip]="item.allFeaturesTooltip" matTooltipClass="">
              {{ item.features }}
            </span>
          </td>
          <td>
            {{ item.createdDate | date: 'dd/MM/yyyy' }}
          </td>
          <td>
            <button class="btn-profile" mat-button [matMenuTriggerFor]="menu">
              <fa-icon style="font-size: 10px;" class="arrow-icon " [icon]="ellipsisV" size="lg"> </fa-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="searchSpotSearch(item)">
                <fa-icon class="top-icon" style=" margin-right: 10px;" [icon]="search"></fa-icon>
                {{ 'favorites.savedSearches.search' | translate }}
              </button>
              <button style="color: #DC3545" mat-menu-item (click)="deleteSpotSearch(item.id)">
                <fa-icon class="top-icon" [icon]="trash" style=" margin-right: 10px;"></fa-icon>
                {{ 'favorites.spotSets.delete' | translate }}
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      *ngIf="savedSearchesList?.content.length === 0"
      class="col-md-12"
      style="text-align: center;margin: 50px 0px 50px 1px;"
    >
      <div class="col-md-12">
        <strong style="color: #6C757D;font-size: 27px;">{{ 'favorites.savedSearches.message1' | translate }}</strong>
      </div>
      <div class="col-md-12">
        <span style="color: #6C757D;" [innerHTML]="'favorites.savedSearches.message2' | translate"></span>
      </div>
      <div class="col-md-12" style="margin-top: 30px;">
        <ion-button
          style="text-transform: none !important;"
          (click)="goToSearchPage()"
          class="btn-color-2 see-more-space"
        >
          <span style="text-transform: none !important;">{{ 'favorites.savedSearches.btnBrowse' | translate }}</span>
        </ion-button>
      </div>
    </div>
    <div *ngIf="isMobile && savedSearchesList?.content">
      <div *ngFor="let item of savedSearchesList?.content">
        <div class="wrapper">
          <div class="color-colum">
            {{ 'favorites.savedSearches.bookTitle' | translate }}
          </div>
          <div class="color-font">
            <span> {{ item.name }}</span>
          </div>
          <div class="color-colum">
            {{ 'favorites.savedSearches.listingType' | translate }}
          </div>
          <div class="color-font ">
            <span> {{ item.listingType }}</span>
          </div>
          <div class="color-colum">
            {{ 'favorites.savedSearches.features' | translate }}
          </div>
          <div class="color-font">
            <span matTooltip [matTooltip]="item.allFeaturesTooltip" matTooltipClass="">
              {{ item.features }}
            </span>
          </div>
          <div class="color-colum">
            {{ 'favorites.savedSearches.modified' | translate }}
          </div>
          <div class="color-font">
            <span> {{ item.createdDate | date: 'dd/MM/yyyy' }}</span>
          </div>
        </div>
        <div>
          <ion-button mat-button [matMenuTriggerFor]="menu" class="btn-color-2" style="width: 99%;font-size: 14px;">
            <div
              class="col-md-6"
              style="text-align: left;
                padding: 0px;"
            >
              <strong>{{ 'favorites.moreActions' | translate }}</strong>
            </div>
            <div
              class="col-md-6"
              style="text-align: right;
                padding: 0px;"
            >
              <img src="assets/icons/arrowDown.svg" alt="" />
            </div>
          </ion-button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="searchSpotSearch(item)">
              <fa-icon class="top-icon" style=" margin-right: 10px;" [icon]="search"></fa-icon>
              {{ 'favorites.savedSearches.edit' | translate }}
            </button>
            <button style="color: #DC3545" mat-menu-item (click)="deleteSpotSearch(item.id)">
              <fa-icon class="top-icon" [icon]="trash" style=" margin-right: 10px;"></fa-icon>
              {{ 'favorites.spotSets.delete' | translate }}
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div
      class="col-md-12"
      style="padding: 0px; background:#F8F9FA;display: flex;justify-content: end;border-top: 1px solid #E5E5E5;"
    >
      <ngb-pagination
        style="margin: 17px 10px 0px 0px;"
        (pageChange)="changePage($event)"
        [pageSize]="pageSize"
        [collectionSize]="collection"
        [(page)]="page"
        aria-label="Default pagination"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
