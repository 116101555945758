<ion-row>
  <div class="full-width">
    <ion-row>
      <ion-col size="12">
        <ion-button class="close-btn right" fill="outline" color="primary" (click)="cancel()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

  <ion-col class="padding-both" size="12">
    <div class="full-width">
      <h5 class="margin-top-10" style="white-space: nowrap;">Renewal Confirmation</h5>
    </div>

    <p>
      Your listing will be renewed as a <b>{{ listingClass ? 'PREMIUM' : 'PROMOTED' }}</b> listing, and will expire on
      <b>{{ expirationDate | date }}</b>
    </p>
  </ion-col>
  <ion-row class="full-width" *ngIf="listing?.listingStatus?.id === 6">
    <b>
      <p class="padding-both">The listing is currently deactivated. Do you wish to re-activate it at this time?</p>
    </b>
    <ion-list class="full-width">
      <ion-radio-group class="full-width" (ionSelect)="choiceSelection($event)" [(ngModel)]="renewalChoice">
        <ion-item lines="none">
          <ion-label class="alignment-label">Re-Activate Listing</ion-label>
          <ion-radio slot="start" value="activate" checked></ion-radio>
        </ion-item>
        <ion-item lines="none">
          <ion-label class="alignment-label">Renew and Keep Deactivated</ion-label>
          <ion-radio slot="start" value="deactivate"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </ion-row>

  <!-- ADD RADIO BUTTONS FOR SELECTION-->
  <ion-col size="12">
    <ion-button class="right" color="primary" fill="solid" (click)="renew()">Renew</ion-button>
    <ion-button class="right" color="secondary" fill="outline" (click)="cancel()" translate="Cancel"></ion-button>
  </ion-col>
</ion-row>
