import * as tslib_1 from "tslib";
var LazySliderService = /** @class */ (function () {
    function LazySliderService() {
        this.defaultSliderOpts = {
            slidesPerView: 1,
            slidesPerGroup: 1,
            freeModeMomentum: true,
            initialSlide: 0,
            direction: 'horizontal',
            height: '200px',
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            slideVisibleClass: 'swiper-slide-visible',
            groupSliding: true,
            breakpoints: {
                1: {
                    slidesPerGroup: 1,
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                900: {
                    slidesPerGroup: 2,
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1300: {
                    slidesPerGroup: 3,
                    slidesPerView: 3,
                    spaceBetween: 35
                },
                1700: {
                    slidesPerGroup: 4,
                    slidesPerView: 4,
                    spaceBetween: 40
                }
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'custom',
                clickable: false,
                lockClass: 'swiper-pagination-lock',
                horizontalClass: 'lazy-slider',
                renderCustom: function (_swiper, current, _total) {
                    var dots = '';
                    for (var i = 1; i <= _total; i++) {
                        dots += "<span class=\"swiper-pagination-bullet dot " + (current === i ? 'active swiper-pagination-bullet-active' : '') + "\"></span>";
                    }
                    return dots;
                }
            }
        };
    }
    LazySliderService.prototype.getBreakPointProps = function (screenSize, breakpoints) {
        var resolutions = Object.keys(breakpoints)
            .map(function (r) { return Number(r); })
            .sort(function (a, b) { return (a < b ? a : b); });
        for (var i = 0; i < resolutions.length; i++) {
            var _a = tslib_1.__read(resolutions.slice(i, i + 2), 2), lower = _a[0], upper = _a[1];
            if (screenSize < lower || i === resolutions.length - 1 || (screenSize > +lower && screenSize < +upper)) {
                return breakpoints[lower];
            }
        }
    };
    return LazySliderService;
}());
export { LazySliderService };
