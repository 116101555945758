import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent implements OnInit {
  @Input('showComponent') showComponent: boolean = false;
  @Input('options') options: ScrollToTopOptions;
  @Input() displayBlock?: boolean = false;
  @Output() scroll: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  scrollToTop() {
    this.options && this.options.searchLanding ? this.scrollToSearchLanding() : this.scroll.emit();
  }

  scrollToSearchLanding() {
    const searchLander = document.getElementById('search-lander');
    if (searchLander) searchLander.scrollIntoView();
  }
}

export interface ScrollToTopOptions {
  searchLanding?: boolean;
}
