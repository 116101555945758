export enum ListingOfferType {
  Lease = 'L',
  Sale = 'S',
  Coworking = 'C'
}

export enum ListingType {
  Featured = 'FEATURED',
  Premium = 'PREMIUM',
  Promoted = 'PROMOTED',
  Basic = 'BASIC'
}

export interface TransactionTypeMeta {
  id: number;
  name: string;
  type: ListingOfferType;
}

export const HOME_TRANSACTION_TYPES: TransactionTypeMeta[] = [
  {
    id: 0,
    name: 'global.list-your-property.spotsForLease',
    type: ListingOfferType.Lease
  },
  {
    id: 1,
    name: 'global.list-your-property.spotsForCoworking',
    type: ListingOfferType.Coworking
  },
  {
    id: 2,
    name: 'global.list-your-property.spotsForSale',
    type: ListingOfferType.Sale
  }
];

export const TRANSACTION_TYPES: TransactionTypeMeta[] = [
  {
    id: 0,
    name: 'global.list-your-property.forLease',
    type: ListingOfferType.Lease
  },
  {
    id: 1,
    name: 'global.list-your-property.forCoworking',
    type: ListingOfferType.Coworking
  },
  {
    id: 2,
    name: 'global.list-your-property.forSale',
    type: ListingOfferType.Sale
  }
];
