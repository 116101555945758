import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18nService, PlatformHelperService } from '@app/core';
import { ToastController } from '@ionic/angular';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import { faTrash, faEllipsisV, faEdit, faSearch, faFilter, faArrowCircleDown, faFileDownload, faShare } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { DownloadFileService } from '../services/downloadFile.service';
import { Router } from '@angular/router';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
var SavedSearchDashboardComponent = /** @class */ (function () {
    function SavedSearchDashboardComponent(favoriteServices, i18nService, dialog, toastCtrl, messageFormDialog, breakpointObserver, downloadFileService, platformHelpService, routingHelperSrv, router) {
        var _this = this;
        this.favoriteServices = favoriteServices;
        this.i18nService = i18nService;
        this.dialog = dialog;
        this.toastCtrl = toastCtrl;
        this.messageFormDialog = messageFormDialog;
        this.breakpointObserver = breakpointObserver;
        this.downloadFileService = downloadFileService;
        this.platformHelpService = platformHelpService;
        this.routingHelperSrv = routingHelperSrv;
        this.router = router;
        this.changeStepOutput = new EventEmitter();
        this.pageSize = 10;
        this.page = 0;
        this.collection = 0;
        this.seeSearch = false;
        this.isMobile = false;
        this.filterCount = 0;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    SavedSearchDashboardComponent.prototype.ngOnInit = function () {
        this.trash = faTrash;
        this.ellipsisV = faEllipsisV;
        this.edit = faEdit;
        this.search = faSearch;
        this.filter = faFilter;
        this.arrowDown = faArrowCircleDown;
        this.fileDownload = faFileDownload;
        this.share = faShare;
        this.getSavedSearches(0);
    };
    SavedSearchDashboardComponent.prototype.getSavedSearches = function (page) {
        var _this = this;
        this.page = page;
        var lang = this.i18nService.getCurrentLanguage();
        var bodySpotSet = {
            sortBy: this.param,
            direction: this.direction,
            titleFilter: this.filterTourBook
        };
        this.favoriteServices.getSavedSearches(page, lang, bodySpotSet).then(function (value) {
            _this.savedSearchesList = value;
            _this.collection = _this.savedSearchesList.totalElements;
            _this.pageSize = _this.savedSearchesList.size;
            _this.setSpotFeatures();
        });
    };
    SavedSearchDashboardComponent.prototype.addFeatureToSavedSearch = function (featured, savedSearch) {
        if (!savedSearch.featuresCount) {
            savedSearch.featuresCount = 0;
        }
        if (!savedSearch.allFeatures) {
            savedSearch.allFeatures = [];
        }
        savedSearch.allFeatures.push(this.i18nService.get(featured));
        savedSearch.featuresCount++;
    };
    SavedSearchDashboardComponent.prototype.fillUpFeatures = function (savedSearch) {
        if (savedSearch && savedSearch.allFeatures && savedSearch.allFeatures.length > 0) {
            savedSearch.allFeatures = savedSearch.allFeatures.sort(function (a, b) { return a.localeCompare(b); });
            savedSearch.allFeaturesTooltip = savedSearch.allFeatures.join(', ');
            var tmp = tslib_1.__spread(savedSearch.allFeatures);
            if (tmp.length > 3) {
                tmp = tmp.slice(0, 3);
            }
            savedSearch.features = tmp.join(', ');
        }
        else {
            savedSearch.features = 'N/A';
        }
    };
    SavedSearchDashboardComponent.prototype.setSpotFeatures = function () {
        var e_1, _a;
        if (this.savedSearchesList) {
            var allSavedSearches = this.savedSearchesList.content;
            try {
                for (var allSavedSearches_1 = tslib_1.__values(allSavedSearches), allSavedSearches_1_1 = allSavedSearches_1.next(); !allSavedSearches_1_1.done; allSavedSearches_1_1 = allSavedSearches_1.next()) {
                    var savedSearch = allSavedSearches_1_1.value;
                    var filterDTO = savedSearch.filterDTO;
                    if (filterDTO) {
                        if (filterDTO.bicycleRack) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.bicycleRack', savedSearch);
                        }
                        if (filterDTO.fiberOptic) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.fiberOptic', savedSearch);
                        }
                        if (filterDTO.bulletProofCabin) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.armoredCabin', savedSearch);
                        }
                        if (filterDTO.centralAirConditioning) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.centralAirConditioning', savedSearch);
                        }
                        if (filterDTO.electricGenerator) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.electricGenerator', savedSearch);
                        }
                        if (filterDTO.heliport) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.heliport', savedSearch);
                        }
                        if (filterDTO.leedStatus) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.sustentability', savedSearch);
                        }
                        if (filterDTO.sluiceGate) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.sluiceGate', savedSearch);
                        }
                        if (filterDTO.singleTenant) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.singleTenant', savedSearch);
                        }
                        if (filterDTO.sprinklers) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.sprinklers', savedSearch);
                        }
                        if (filterDTO.truckYard) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.truckYard', savedSearch);
                        }
                        if (filterDTO.twentyFourHourSecurity) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.twentyFourHourSecurity', savedSearch);
                        }
                        //End Features
                        // Spot Features
                        if (filterDTO.blockAndSteelConstruction) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.blockAndSteelConstruction', savedSearch);
                        }
                        if (filterDTO.coreAndShellCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.coreAndShel', savedSearch);
                        }
                        if (filterDTO.crossDocking) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.crossDocking', savedSearch);
                        }
                        if (filterDTO.datacenterCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.dataCenter', savedSearch);
                        }
                        if (filterDTO.forRetailUseConditionOffice) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.forRetail', savedSearch);
                        }
                        if (filterDTO.forRetailUseConditionIndustrial) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.forRetail', savedSearch);
                        }
                        if (filterDTO.flexCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.flex', savedSearch);
                        }
                        if (filterDTO.fullFloorCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.fullFloor', savedSearch);
                        }
                        if (filterDTO.includeColdStorage) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.includeColdStorage', savedSearch);
                        }
                        if (filterDTO.manufacturingCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.manufacturing', savedSearch);
                        }
                        if (filterDTO.logisticsCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.logistics', savedSearch);
                        }
                        if (filterDTO.plugAndPlayConditionOffice) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.plugAndPlay', savedSearch);
                        }
                        if (filterDTO.preBuildCondition) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.preBuild', savedSearch);
                        }
                        if (filterDTO.skyLightsSpace) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.skyLights', savedSearch);
                        }
                        if (filterDTO.steelConstruction) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.steelConstruction', savedSearch);
                        }
                        if (filterDTO.subLease) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.subLease', savedSearch);
                        }
                        if (filterDTO.petFriendly) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.petFriendly', savedSearch);
                        }
                        if (filterDTO.plugAndPlayConditionCoworking) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.plugAndPlay', savedSearch);
                        }
                        if (filterDTO.furnishedConditionCoworking) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.furnished', savedSearch);
                        }
                        if (filterDTO.barista) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.barista', savedSearch);
                        }
                        if (filterDTO.enhancedCleaning) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.enhancedCleaning', savedSearch);
                        }
                        if (filterDTO.parkingAvailable) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.parking', savedSearch);
                        }
                        if (filterDTO.conferenceRoom) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.conferenceRoom', savedSearch);
                        }
                        if (filterDTO.privateOutdoorSpace) {
                            this.addFeatureToSavedSearch('global.addtSearchFilters.privateOutdoorSpace', savedSearch);
                        }
                    }
                    this.fillUpFeatures(savedSearch);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (allSavedSearches_1_1 && !allSavedSearches_1_1.done && (_a = allSavedSearches_1.return)) _a.call(allSavedSearches_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    SavedSearchDashboardComponent.prototype.changePage = function (page) {
        this.getSavedSearches(page - 1);
    };
    SavedSearchDashboardComponent.prototype.searchProperty = function () {
        this.seeSearch = !this.seeSearch;
    };
    SavedSearchDashboardComponent.prototype.searchFilter = function () {
        var _this = this;
        if (this.filterCount == 0) {
            this.filterCount = 1;
            setTimeout(function () {
                _this.getSavedSearches(0);
                _this.filterCount = 0;
            }, 2000);
        }
    };
    SavedSearchDashboardComponent.prototype.nameAscending = function () {
        this.param = 'name';
        this.direction = 'ASC';
        this.getSavedSearches(0);
    };
    SavedSearchDashboardComponent.prototype.nameDescending = function () {
        this.param = 'name';
        this.direction = 'DESC';
        this.getSavedSearches(0);
    };
    SavedSearchDashboardComponent.prototype.oldestFirst = function () {
        this.param = 'date';
        this.direction = 'ASC';
        this.getSavedSearches(0);
    };
    SavedSearchDashboardComponent.prototype.newestFirst = function () {
        this.param = 'date';
        this.direction = 'DESC';
        this.getSavedSearches(0);
    };
    SavedSearchDashboardComponent.prototype.presentToast = function (message) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: message,
                            duration: 3500,
                            animated: true,
                            keyboardClose: true,
                            cssClass: 'toast-alert-detail'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SavedSearchDashboardComponent.prototype.propertiesText = function (message) {
        this.messageFormDialog.openDialogMessage(message);
    };
    SavedSearchDashboardComponent.prototype.deleteSpotSearch = function (id) {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDeleteComponent, {
            width: '500px',
            data: {
                message: this.i18nService.get('favorites.savedSearches.deleteTour'),
                id: id,
                apiName: '/api/spot-search/'
            },
            autoFocus: true,
            restoreFocus: true
        });
        dialogRef.afterClosed().subscribe(function (val) {
            if (val) {
                _this.presentToast(_this.i18nService.get('favorites.deleteSavedSearchMessage'));
                _this.getSavedSearches(0);
            }
        });
    };
    SavedSearchDashboardComponent.prototype.goToSearchPage = function () {
        this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
    };
    SavedSearchDashboardComponent.prototype.searchSpotSearch = function (savedSearch) {
        return this.routingHelperSrv.navigateToSavedSearchPage(savedSearch);
    };
    return SavedSearchDashboardComponent;
}());
export { SavedSearchDashboardComponent };
