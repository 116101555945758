<ion-content #contentBlock>
  <app-breadcrumb
    inViewport
    [ngClass]="{ 'apply-blue-background': screenWidth >= 1200 }"
    [hideButtons]="screenWidth >= 1200 ? false : true"
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="isHeaderVisible($event)"
    [options]="breadcrumbNavOptions"
  >
    <div class="listing-filters-container">
      <ion-row>
        <ion-col size-xl="4" size-xs="0">
          <div class="find-spot-block" *ngIf="screenWidth >= 1200">
            <h1 class="search-h1-title">
              <p class="welcome-spot-text">{{ 'global.menu.desktop.findSpot' | translate }}</p>
              <p class="spot-search-text">{{ 'global.menu.desktop.findSpotText' | translate }}</p>
            </h1>
          </div>
        </ion-col>
        <ion-col size-xl="8" size-xs="12">
          <div class="listing-filters">
            <ion-toolbar>
              <div class="input-container keyword-search-default input-top-container container-margin">
                <div class="keyword-search align-center webkit-center">
                  <div class="keyword-input">
                    <ion-input
                      class="widthInput"
                      [(ngModel)]="searchModel.keywordText"
                      type="text"
                      (keyup.enter)="searchListingsChangeListener()"
                      placeholder="{{ 'global.menu.searchPlaceholder' | translate }}"
                      spellcheck="false"
                    >
                    </ion-input>
                    <button ion-button class="browse-button" (click)="searchListingsChangeListener()">
                      <fa-icon class="search-icon" [icon]="faSearch" size="md"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ion-toolbar>

            <ion-toolbar secondaryToolbar>
              <div class="search-filter-container">
                <ion-button
                  class="listing-type-select"
                  (click)="openProptypePopover($event)"
                  [color]="setButtonColor(1200)"
                >
                  <div
                    class="location-label"
                    style="text-transform: initial;"
                    [ngClass]="{ white: screenWidth < 1200 }"
                  >
                    {{ 'global.menu.entities.buildingType' | translate }}
                  </div>
                </ion-button>

                <ion-button
                  [disabled]="!showListingTypeBtn()"
                  class="listing-type-select"
                  [color]="setButtonColor(1200)"
                >
                  <div
                    class="location-label"
                    style="text-transform: initial;"
                    [ngClass]="{ white: screenWidth < 1200 }"
                  >
                    {{ 'global.menu.entities.listingType' | translate }}
                  </div>
                </ion-button>

                <ion-button class="listing-type-select" [color]="setButtonColor(1200)">
                  <div class="location-label" [ngClass]="{ white: screenWidth < 1200 }">
                    {{ 'global.search.location' | translate }}
                  </div>
                </ion-button>

                <ion-button class="listing-type-select" [color]="setButtonColor(1200)" id="filter">
                  <div class="location-label" [ngClass]="{ white: screenWidth < 1200 }">
                    {{ 'global.search.moreFilters' | translate }}
                  </div>
                </ion-button>
              </div>
            </ion-toolbar>

            <ion-toolbar secondaryToolbar *ngIf="myListingPage || myListingAnalytics">
              <my-listing-filter
                [screenWidth]="screenWidth"
                [agents]="users"
                [isSpotAdmin]="spotAdmin"
                [isListingAdmin]="listingAdmin"
                [listingCompanies]="allCompanies"
                [selectCompany]="selectCompany"
                [selectAgent]="selectUser"
                [listingSearchDto]="listingSearchDto"
                (updateFilter)="updateMyListingFilter($event)"
              >
              </my-listing-filter>
            </ion-toolbar>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </app-breadcrumb>

  <div
    class="input-container input-container-custom"
    *ngIf="
      searchTagService.getSearchTags()?.length > 0 ||
      selectCompany ||
      selectUser?.length > 0 ||
      statusHelperService.selectStatus?.length ||
      classHelperService.selectClass?.length > 0 ||
      (myListingAnalytics && analyticsData) ||
      myListingPage
    "
  >
    <ion-row class="full-width">
      <ion-header>
        <ion-toolbar class="align-center webkit-center tag-no-top-padding">
          <ion-row class="search-tags">
            <div *ngFor="let searchTag of searchTagService.getSearchTags(); let i = index">
              <ion-chip class="search-tag" color="primary" *ngIf="searchTag">
                <ion-text *ngIf="!searchTag.max" style="font-size: 1em;">{{ searchTag.name | translate }}</ion-text>
                <ion-text *ngIf="searchTag.min || searchTag.max" style="font-size: 1em;">
                  {{ searchTag.name | translate }} :
                  {{
                    searchTag.min
                      | localNumber
                        : (!searchTagService.checkTagNames(searchTag) || searchTag.min === 0 ? '1.0-0' : '1.2-2')
                  }}
                  -
                  {{ searchTag.max | localNumber: (!searchTagService.checkTagNames(searchTag) ? '1.0-0' : '1.2-2') }}
                  <span *ngIf="searchTag.showPlus">+</span>
                </ion-text>
                <ion-icon name="close" (click)="removeTag(searchTag.fieldName, i)"></ion-icon>
              </ion-chip>
            </div>
            <div *ngIf="selectCompany">
              <ion-chip class="search-tag" color="primary">
                <ion-text style="font-size: 1em;">{{ selectCompany.name | translate }}</ion-text>
                <ion-icon name="close" *ngIf="spotAdmin" (click)="removeMyListingTag(selectCompany)"> </ion-icon>
              </ion-chip>
            </div>

            <div *ngIf="selectUser">
              <ion-chip class="search-tag" color="primary">
                <ion-text style="font-size: 1em;">{{ selectUser.firstNameAndLastName | translate }}</ion-text>
                <ion-icon name="close" *ngIf="spotAdmin || listingAdmin" (click)="removeMyUserTag(selectUser)">
                </ion-icon>
              </ion-chip>
            </div>

            <div *ngFor="let status of statusHelperService.selectStatus; let i = index">
              <ion-chip class="search-tag" color="primary" *ngIf="status">
                <ion-text style="font-size: 1em;">{{ status.status | translate }}</ion-text>
                <ion-icon name="close" (click)="removeMyStatusTag(status)"></ion-icon>
              </ion-chip>
            </div>

            <div *ngFor="let listingClass of classHelperService.selectClass; let i = index">
              <ion-chip class="search-tag" color="primary" *ngIf="listingClass">
                <ion-text style="font-size: 1em;">{{ listingClass.name | translate }}</ion-text>
                <ion-icon name="close" (click)="removeMyClassTag(listingClass)"></ion-icon>
              </ion-chip>
            </div>

            <ion-chip
              *ngIf="
                searchTagService.getSearchTags()?.length > 0 ||
                classHelperService.selectClass?.length > 0 ||
                selectUser ||
                selectCompany ||
                statusHelperService.selectStatus?.length > 0
              "
              (click)="clearCompany(); clearSearch()"
            >
              <ion-text style="font-size: 1em;"> {{ 'ClearSearch' | translate }}</ion-text>
            </ion-chip>

            <div *ngIf="myListingPage && spotAdmin" class="renew-extend-search-container">
              <ion-button
                fill="solid"
                color="primary"
                (click)="openRenewExtendListingsModal($event)"
                [disabled]="disabledExtendListingsBtn"
              >
                {{ 'global.search.renewExtendListings' | translate }}
              </ion-button>
            </div>
          </ion-row>
        </ion-toolbar>
        <div
          style="max-width: 500px; margin: 0 auto;"
          *ngIf="myListingAnalytics && analyticsData && allListingIds && !searching"
        >
          <listing-analytics-info
            style="width: 100%;"
            [listingCount]="allListingIds.length"
            [listingAnalytics]="analyticsData"
          >
          </listing-analytics-info>
        </div>
      </ion-header>
    </ion-row>
  </div>

  <div style="display: flex; flex-direction: row; justify-content: center;" *ngIf="searching">
    <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
  </div>
  <div class="input-container input-container-map" *ngIf="!searching">
    <ion-toolbar secondaryToolbar style="height:50px">
      <div class="search-map-container justify-between" style="height:35px">
        <ion-buttons slot="start">
          <ion-button color="secondary" style="pointer-events: none;">
            <ion-text *ngIf="allListingIds && !isSpotNearMeSearch"
              >{{ 'global.menu.options.listings' | translate }} ({{
                showTotalActiveListingCounts()
                  ? totalActiveListingsCount
                  : (allListingIds.length | localNumber: '1.0-0')
              }})
            </ion-text>
            <ion-text *ngIf="!allListingIds && !isSpotNearMeSearch" class="bar-text">
              {{ 'global.menu.options.listings' | translate }} (0)
            </ion-text>
            <ion-text *ngIf="isSpotNearMeSearch" class="bar-text"
              >{{ 'global.menu.options.listings' | translate }} ({{ totalListingsCount }})</ion-text
            >
          </ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button color="secondary" (click)="toggleMap()">
            <ion-text *ngIf="!displayMap">{{ 'global.search.viewMap' | translate }}</ion-text>
            <img
              alt="map image"
              style="width:25px; height:25px; margin-left:5px"
              *ngIf="!displayMap"
              [src]="mapToggleIcons.map.imageUrl"
            />
            <ion-text *ngIf="displayMap">{{ 'global.search.viewList' | translate }}</ion-text>
            <img
              style="width:30px; height:25px; padding-left:5px"
              *ngIf="displayMap"
              [src]="mapToggleIcons.list.imageUrl"
            />
          </ion-button>
        </ion-buttons>
      </div>
    </ion-toolbar>
  </div>

  <div class="input-container input-container-custom" *ngIf="!searching">
    <!-- ListView -->
    <div class="search-results" *ngIf="!displayMap && listings?.length && !searching">
      <div class="mobile-width mobile-padding" *ngFor="let listing of listings">
        <app-listing-card
          [activeListingIds]="allListingIds"
          [myListingPage]="myListingPage ? myListingPage : null"
          [myListingAnalytics]="myListingAnalytics ? myListingAnalytics : null"
          [listing]="listing"
          [localStorageKey]="localStorageKey"
          [searchCriteria]="listingSearchDto"
          [checkBoxValues]="checkBoxValues"
          [searchTags]="searchTagService.getSearchTags()"
          [lazyLoadImages]="true"
          [defaultCoworkingType]="listingSearchDto.coworkingType"
        >
        </app-listing-card>
      </div>
    </div>

    <div *ngIf="showNoResultMessage()">
      <ion-text class="no-listings-found">{{ noListingsFoundMessage }}</ion-text>
    </div>

    <!-- MapView -->
    <div class="map-freestyle" *ngIf="displayMap">
      <div [ngClass]="mapViewClass()" *ngIf="listingMarkers.length && !hasGeoLocationError">
        <app-full-screen-map-wrapper>
          <app-duplomap
            [options]="mapOptions"
            [markers]="listingMarkers"
            [clientPosition]="clientPosition"
            [isSpotNearMe]="isSpotNearMeSearch"
            (geopositionEmitter)="searchByGeoposition($event)"
          >
          </app-duplomap>
        </app-full-screen-map-wrapper>
      </div>

      <div #uniqueListing [ngClass]="listCardViewClass()" *ngIf="displayMap && showUniquePropertyListing">
        <div class="label-header">
          <ion-label>
            <span localize [textValue]="selectedBuilding.title"></span>

            <span
              *ngIf="allMatchingListing.length === 1"
              [translate]="'global.singleListingAvailable'"
              [translateParams]="{ listings: allMatchingListing.length }"
            >
            </span>

            <span
              *ngIf="allMatchingListing.length > 1"
              [translate]="'global.pluralListingAvailable'"
              [translateParams]="{ listings: allMatchingListing.length }"
            >
            </span>
          </ion-label>
        </div>

        <div class="listing-card-container">
          <app-listing-card
            *ngFor="let listing of allMatchingListing"
            class="listing-card"
            [activeListingIds]="allListingIds"
            [listing]="listing"
            [searchCriteria]="listingSearchDto"
            [checkBoxValues]="checkBoxValues"
            [searchTags]="searchTagService.getSearchTags()"
            [lazyLoadImages]="true"
          >
          </app-listing-card>
        </div>
      </div>

      <div class="map-display" *ngIf="hasGeoLocationError && isSpotNearMeSearch">
        <ion-text color="secondary">
          <h5 class="h2-special" [translate]="geoLocationErrorCode"></h5>
        </ion-text>
      </div>
    </div>

    <ion-button
      class="more-results-tb"
      [ngClass]="{ 'mobile-margin-tb': screenWidth < 501 }"
      fill="clear"
      *ngIf="showMoreIcon && !searching && !displayMap"
      (click)="getMoreListings()"
    >
      <ion-icon class="dismiss-icon" name="ios-arrow-down"></ion-icon>
      <ion-text>{{ 'global.form.moreResults' | translate }}</ion-text>
    </ion-button>

    <span *ngIf="searchingMoreListings">
      <ion-spinner id="moreListingsSpinner" name="dots" color="primary"></ion-spinner>
    </span>

    <app-row-header
      style="width: 100%"
      *ngIf="(allListingIds && allListingIds.length > 0 && !searching) || showNoResultMessage()"
      [providers]="true"
      title="{{ 'global.serviceProviders' | translate }}"
    >
    </app-row-header>
  </div>

  <ion-select
    #listingSelectType
    style="font-size: 1.3em;"
    [(ngModel)]="searchModel.listingType"
    [cancelText]=""
    [hidden]="true"
    interface="popover"
    [interfaceOptions]="selectOptions"
    placeholder="Listing Type"
    okText=""
  >
    <ion-select-option [selected]="true" value="">{{ 'global.all' | translate }}</ion-select-option>
    <ion-select-option [value]="option.type" *ngFor="let option of transactionTypes">
      {{ option.name | translate }}
    </ion-select-option>
  </ion-select>

  <ion-select
    #propertySelectType
    style="font-size: 1.3em;"
    [(ngModel)]="searchModel.propertyType"
    interface="popover"
    (ionChange)="checkPropertyType()"
    [interfaceOptions]="selectOptions"
    [hidden]="true"
    [cancelText]=""
    placeholder="Property Type"
    okText=""
  >
    <ion-select-option [selected]="true" value="">{{ 'global.all' | translate }}</ion-select-option>
    <ion-select-option [value]="option.id" *ngFor="let option of propertyTypes">
      {{ option.name | translate }}
    </ion-select-option>
  </ion-select>

  <app-scroll-to-top
    [showComponent]="displayScrollToTop"
    (scroll)="contentBlock.scrollToTop()"
    [target]="contentBlock"
    scroller
  >
  </app-scroll-to-top>
  <app-footer></app-footer>
</ion-content>
