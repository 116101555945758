var SearchUrlService = /** @class */ (function () {
    function SearchUrlService() {
    }
    Object.defineProperty(SearchUrlService.prototype, "getSearchUrl", {
        get: function () {
            return this.getCache() || this.url;
        },
        enumerable: true,
        configurable: true
    });
    SearchUrlService.prototype.setSearchUrl = function (url) {
        this.url = url;
        this.cache();
    };
    SearchUrlService.prototype.getCache = function () {
        try {
            if (localStorage) {
                var position = localStorage.getItem('search-filters');
                return JSON.parse(position);
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    SearchUrlService.prototype.delCache = function () {
        try {
            if (localStorage)
                localStorage.removeItem('search-filters');
        }
        catch (e) {
            console.error(e);
        }
    };
    SearchUrlService.prototype.cache = function () {
        localStorage.setItem('search-filters', JSON.stringify(this.url));
    };
    return SearchUrlService;
}());
export { SearchUrlService };
