<ion-row
  style="position: sticky;
    top: 40px;
    background:  white;
    height: 100px;
    padding: 5px;
    display:flex;
    justify-content:center;
    border-bottom:1px solid lightgrey;"
>
  <p
    style="margin-left:5px;height:100%;width:100%;font-size:19px;display:flex;justify-content:center;align-items:center; text-align:center; margin-right:5px"
  >
    {{ 'global.messagesHelp' | translate }}
  </p>
</ion-row>
<ion-row
  style="width:100%;padding-bottom:100px;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;padding-right:10px; margin-top: -25px;"
>
  <ion-row style="text-align:center;" class="no-messages-label" *ngIf="!messages || !messages.length">
    <ion-label style="display:flex;align-items:center;justify-content:center;" color="primary">{{
      'global.noMessages' | translate
    }}</ion-label>
  </ion-row>
  <ion-row
    id="message-container"
    *ngFor="let message of messages; let i = index"
    style="width:100%;background:white;border:lightgrey solid 1px;margin:5px;max-width:800px;"
  >
    <div style="display:flex;width:100%;font-size:1em;">
      <div style="display:flex;width:100%;font-size:1em;padding:5px;">
        <div style="flex:1;display:flex;">
          <img height="50" width="50" *ngIf="message.userId !== currentUser.login" [src]="spotAccountImage" />
          <img
            height="50"
            width="50"
            style="margin-right:5px;"
            *ngIf="message.userId === currentUser.login"
            [src]="userProfileImage"
          />
          <div style="flex-direction:column;justify-content:space-between;height:30px;">
            <div
              style="margin-left:15px;font-size:16px;font-weight:600;margin-top:8px;"
              *ngIf="message.userId !== currentUser.login"
            >
              {{ spotAccountName }}
            </div>
            <div
              style="margin-left:15px;font-size:16px;font-weight:600;margin-top:8px;"
              *ngIf="message.userId === currentUser.login"
            >
              {{ userFullname }}
            </div>
            <div style="margin-left:15px;font-size:12px;">{{ message.createdAt | date: 'M/d/yy h:mm a' }}</div>
          </div>
        </div>
      </div>
    </div>
    <p [id]="i" style="text-indent:5px;width:100%;padding-top:5px;max-width:800px;" [innerHtml]="message.content"></p>
  </ion-row>
  <span id="scrollHere"></span>
</ion-row>
