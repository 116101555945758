import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthenticationService, AccountService } from '@app/core/auth';
import { I18nService } from '@app/core';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private account: AccountService, private i18nService: I18nService) {}

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error ? error.error.error : '';
        const userHasKeepConnected = this.account.getCurrentUser && this.account.getCurrentUser.keepConnected;
        const shouldRefreshToken = error.status === 401 && errorMessage === 'invalid_token' && userHasKeepConnected;

        if (shouldRefreshToken) {
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(req)))
            );
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            return this.auth.refreshAccessToken().pipe(
              switchMap((token: any) => {
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(token);
                const newReq = this.addAuthenticationToken(req);
                return next.handle(newReq);
              }),
              catchError((err: any) => {
                console.error(this.i18nService.get('TokenExpiration.keepConnectedExpiredMsg'));
                this.refreshTokenInProgress = false;
                this.auth.logout();
                return throwError(error);
              })
            );
          }
        }
        return throwError(error);
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    const hasAccessToken = this.auth.hasToken();

    if (!hasAccessToken) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${JSON.parse(this.auth.getToken()).access_token}`
      }
    });
  }
}
