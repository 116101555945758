<ion-grid class="mb-5" *ngIf="!loading">
  <ion-row>
    <ion-col style="padding: 16px;">
      <ion-label
        ><b>{{ 'global.coworking.positions' | translate }}</b></ion-label
      >
    </ion-col>
  </ion-row>
  <ion-row style="padding: 0px 12px 0px 12px;">
    <ion-col size="7">
      <ion-label style="line-height: 41px; font-size: 16px;">{{
        'global.coworking.positionsNeeded' | translate
      }}</ion-label>
    </ion-col>
    <ion-col>
      <ion-input
        #posInput
        type="number"
        style="border: 1px solid; padding: 0px; padding-left: 5px !important;"
        [(ngModel)]="coworkingPositions"
        autofocus="true"
      >
      </ion-input>
    </ion-col>
  </ion-row>
  <ion-list>
    <ion-radio-group [(ngModel)]="coworkingType" class="top-search-btn" style="font-size: 1.3em;">
      <ion-list-header>
        <ion-label
          ><b>{{ 'global.coworking.coworkingType' | translate }}</b></ion-label
        >
      </ion-list-header>

      <ion-item>
        <ion-label style="font-size: 16px;">{{ 'global.coworking.sharedSpace' | translate }}</ion-label>
        <ion-radio slot="end" value="shared" selected (ionSelect)="coworkingTypeChanged($event)"></ion-radio>
      </ion-item>

      <ion-item>
        <ion-label style="font-size: 16px;">{{ 'global.coworking.privateSpace' | translate }}</ion-label>
        <ion-radio slot="end" value="private" (ionSelect)="coworkingTypeChanged($event)"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-grid>

<ion-row class="fixed-ion-btnrow" *ngIf="!loading">
  <ion-col size="12">
    <ion-button class="float-right" color="light" fill="clear" (click)="update()" translate="Update"></ion-button>
    <ion-button class="float-right" color="light" fill="clear" (click)="cancel()" translate="Cancel"></ion-button>
  </ion-col>
</ion-row>
