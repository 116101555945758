import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContactFormComponent } from './contact-form.component';

@Injectable()
export class ContactDialogService {
  private dialogRef: any;

  constructor(private dialog: MatDialog) {}

  openDialog(data?: any) {
    this.dialogRef = this.dialog.open(ContactFormComponent, {
      //height: '325px',
      width: '550px',
      data: data ? data : null
    });

    // this.dialogRef.afterClosed().subscribe((result: any) => {
    // });
  }
}
