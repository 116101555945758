import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
var VideoComponent = /** @class */ (function () {
    function VideoComponent(navParams, popoverCtrl) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        this.youtubeUrls = ['youtube.com', 'youtu.be'];
        this.showVideoComponent = true;
        this.loadingVideo = true;
        this.fromPopup = false;
    }
    VideoComponent.prototype.ngAfterViewInit = function () {
        this.loadingVideo = false;
    };
    VideoComponent.prototype.onLoad = function (id) {
        var videoNotFound = false;
        var videoIFrame = document.getElementById('videoTourIFrame' + id);
        if (videoIFrame && videoIFrame.contentDocument) {
            var prerenderStatusCodeElement = videoIFrame.contentDocument.getElementsByName('prerender-status-code');
            if (prerenderStatusCodeElement) {
                if (prerenderStatusCodeElement[0]) {
                    if (prerenderStatusCodeElement[0].content == '404') {
                        videoNotFound = true;
                    }
                }
            }
        }
        this.showVideoComponent = !videoNotFound;
        if (!this.loadingVideo && this.showVideoComponent) {
            document.getElementById('videoTourIFrame').style.visibility = 'visible';
            document.getElementById('videoTourIFrame' + id).style.visibility = 'visible';
        }
    };
    VideoComponent.prototype.ngOnInit = function () {
        var e_1, _a, e_2, _b;
        if (this.navParams && this.navParams.data) {
            if (this.navParams.data.fromPopup) {
                this.videoURL = this.navParams.data.videoURL;
                this.fromPopup = this.navParams.data.fromPopup;
                this.spotTitle = this.navParams.data.spotTitle;
                this.closeIcon = faTimes;
            }
        }
        if (this.videoURL != null) {
            this.videosURLEmbedded = this.videoURL.split('\n');
            if (this.videosURLEmbedded.length == 1) {
                this.videoURLEmbedded = this.videosURLEmbedded[0].trim();
                try {
                    for (var _c = tslib_1.__values(this.youtubeUrls), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var youtubeUrl = _d.value;
                        if (this.videosURLEmbedded[0].includes(youtubeUrl)) {
                            this.videoURLEmbedded = this.getYoutubeURL(this.videosURLEmbedded[0].trim());
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            else {
                this.videoURLEmbedded = this.videosURLEmbedded[0].trim();
                this.videoURLEmbedded2 = this.videosURLEmbedded[1].trim();
                try {
                    for (var _e = tslib_1.__values(this.youtubeUrls), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var youtubeUrl = _f.value;
                        if (this.videosURLEmbedded[0].includes(youtubeUrl)) {
                            this.videoURLEmbedded = this.getYoutubeURL(this.videosURLEmbedded[0].trim());
                        }
                        if (this.videosURLEmbedded[1].includes(youtubeUrl)) {
                            this.videoURLEmbedded2 = this.getYoutubeURL(this.videosURLEmbedded[1].trim());
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
    };
    VideoComponent.prototype.getYoutubeURL = function (url) {
        var match = url.match(this.youtubeRegExp);
        if (match && match[2] && match[2].split(' ').length === 1) {
            return "//www.youtube.com/embed/" + match[2];
        }
        else {
            return this.getURLFromVideoWithIframeAttributes(url);
        }
    };
    VideoComponent.prototype.getURLFromVideoWithIframeAttributes = function (videoURL) {
        var e_3, _a;
        var youtubeVideo = null;
        var iframeAttributes = videoURL.split(' ');
        if (iframeAttributes && iframeAttributes.length > 0) {
            try {
                for (var iframeAttributes_1 = tslib_1.__values(iframeAttributes), iframeAttributes_1_1 = iframeAttributes_1.next(); !iframeAttributes_1_1.done; iframeAttributes_1_1 = iframeAttributes_1.next()) {
                    var attr = iframeAttributes_1_1.value;
                    if (attr.includes('youtube.com')) {
                        var iframeAttrMatch = attr.match(this.youtubeRegExp);
                        if (iframeAttrMatch && iframeAttrMatch.length >= 2) {
                            var embededVideo = iframeAttrMatch[2].replace(/\W/g, '');
                            youtubeVideo = "//www.youtube.com/embed/" + embededVideo;
                            break;
                        }
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (iframeAttributes_1_1 && !iframeAttributes_1_1.done && (_a = iframeAttributes_1.return)) _a.call(iframeAttributes_1);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        return youtubeVideo;
    };
    VideoComponent.prototype.closeModal = function () {
        this.popoverCtrl.dismiss();
    };
    return VideoComponent;
}());
export { VideoComponent };
