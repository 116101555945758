<!-- 
  markup with a container nesting informational text and the SVG asset 
SVG included inline to directly animate parts of it through CSS
  -->
<div class="container">
  <h1>Oops!</h1>
  <h2>Not finished here yet!</h2>
  <h3>Livestream of progress below</h3>

  <svg
    class="monkey-hammer-hat"
    xmlns="http://www.w3.org/2000/svg"
    width="359.997"
    height="330"
    viewBox="0 0 95.249 87.313"
  >
    <g transform="matrix(.99747 0 0 1.05652 -16.368 -190.133)">
      <!-- give an identifier to the group to be affected in css -->
      <g id="arm" transform="matrix(1.01003 -.0263 .02138 1.00115 -3.841 .738)">
        <path
          d="M59.068 203.392l5.855 4.981c-6.396 13.585-4.114 13.727-24.452 31.094l-5.855-4.981c23.116-18.726 17.356-17.124 24.452-31.094z"
          fill="blue"
        />
        <g transform="matrix(.256 -.1843 .19541 .27145 -14.22 217.94)">
          <path
            d="M98.444 104.74h12.85c.876 0 1.556.762 1.581 1.709l1.255 47.609c.025.946-.705 1.709-1.58 1.709H97.19c-.876 0-1.606-.763-1.581-1.71l1.254-47.608c.025-.947.705-1.71 1.581-1.71z"
            fill="#d40000"
          />
          <rect width="42.711" height="25.324" x="82.399" y="90.247" rx="12" ry="12" fill="#ccc" />
          <path
            d="M75.334 93.498h4.78c.686 0 3.532 4.973 3.532 11.15 0 6.177-2.846 11.15-3.532 11.15h-4.78c-.686 0-.91-4.973-.91-11.15 0-6.177.224-11.15.91-11.15zm34.356-2.117s26.226-5.48 33.57 10.583c4.587 10.037-16.576-11.717-31.67 3.402-9.404 9.42-1.9-13.985-1.9-13.985z"
            fill="#ccc"
          />
        </g>
        <rect
          transform="matrix(.7685 -.63985 .59545 .80339 0 0)"
          width="8.809"
          height="4.186"
          x="-115.885"
          y="200.809"
          rx="2.354"
          ry="3.405"
          fill="#ffd42a"
        />
        <rect
          transform="matrix(.7685 -.63985 .59545 .80339 0 0)"
          ry="3.405"
          rx="2.354"
          y="204.167"
          x="-115.885"
          height="4.186"
          width="8.809"
          fill="#ffd42a"
        />
        <rect
          transform="matrix(.7685 -.63985 .59545 .80339 0 0)"
          width="8.809"
          height="4.186"
          x="-115.885"
          y="207.524"
          rx="2.354"
          ry="3.405"
          fill="#ffd42a"
        />
      </g>
      <ellipse
        cx="51.954"
        cy="201.259"
        rx="13.129"
        ry="15.176"
        transform="matrix(.99875 -.0499 .04548 .99897 0 0)"
        fill="purple"
      />
      <path
        d="M56.247 183.58s8.997 1.961 9.353 2.426c.356.466-5.901 1.91-5.901 1.91zm-1.664 31.088l8.202-1.102-3.674-2.397z"
        fill="purple"
      />
      <rect
        transform="matrix(.97342 -.22902 .22568 .9742 0 0)"
        ry="3.008"
        rx="2.093"
        y="261.402"
        x="24.628"
        height="3.697"
        width="7.833"
        fill="#ffd42a"
      />
      <rect
        width="7.715"
        height="2.516"
        x="-4.571"
        y="260.813"
        rx="2.062"
        ry="2.047"
        transform="matrix(.9882 -.1532 .33165 .9434 0 0)"
        fill="#ffd42a"
      />
      <rect
        transform="matrix(.9833 -.18204 .28242 .9593 0 0)"
        ry="2.403"
        rx="2.072"
        y="256.702"
        x="8.649"
        height="2.955"
        width="7.754"
        fill="#ffd42a"
      />
      <path
        d="M69.218 242.928l9.28.444c-2.757 3.757-7.053 10.015-3.133 16.836l-10.199 1.728c-2.934-6.94.477-14.683 4.053-19.008z"
        fill="purple"
      />
      <path
        d="M60.578 238.905l8.64 4.023c-7.156 3.3-13.52 11.696-9.217 19.162l-6.99-2.38c-4.06-11.135 6.116-21.705 7.567-20.805z"
        fill="purple"
      />
      <g fill="#ccaa22">
        <path
          d="M55.15 258.13c5.297.756 9.882.998 9.882 2.237 0 1.24-4.407 2.237-9.881 2.237-5.476 0-10.048-1.137-10.048-2.237 0-1.975 1.87-3.402 10.047-2.236z"
        />
        <path d="M45.103 260.208h19.93v2.396h-18.67c-1.396-.87-1.26-2.167-1.26-2.396z" />
      </g>
      <g fill="#ffd42a">
        <path
          d="M66.395 258.13c4.808.756 8.97.998 8.97 2.237 0 1.24-4 2.237-8.97 2.237-4.97 0-9.12-1.137-9.12-2.237 0-1.975 1.697-3.402 9.12-2.236z"
        />
        <path d="M57.274 260.208h18.09s-.243 2.396-1.006 2.396h-15.94c-1.267-.87-1.144-2.167-1.144-2.396z" />
      </g>
      <ellipse
        cx="18.001"
        cy="240.417"
        rx="14.895"
        ry="17.459"
        transform="matrix(.97389 -.22702 .20778 .97818 0 0)"
        fill="purple"
      />
      <path
        d="M62.021 205.446c4.981-4.194 14.054 9.313 19.173 17.99 6.094 10.334-1.978 22.923-10.081 24.81-8.104 1.89-20.464-5.92-19.173-17.99 1.746-16.322 2.904-18.767 10.081-24.81z"
        fill="purple"
      />
      <ellipse cx="58.967" cy="222.56" rx="6.278" ry="9.211" transform="skewY(9.515) scale(.98624 1)" fill="#cc3dcc" />
      <path
        d="M68.156 244.035s18.66-8.324 18.66-28.347c0-20.024 27.59-19.35 22.612.109-1.934 7.563-12.584 8.133-12.584-2.544"
        fill="none"
        stroke="purple"
        stroke-width="3.764"
        stroke-linecap="round"
      />
      <path d="M68.804 206.92c4.183 2.958 6.055 6.108 7.932 8.987l.088 24.336h-8.02z" fill="#cc3dcc" />
      <path
        d="M76.824 239.49h-8.02s-2.282 12.703 16.982 12.703l-1.664-7.981s-7.298 1.657-7.298-4.721z"
        fill="#cc3dcc"
      />
      <rect
        width="29.815"
        height="18.256"
        x="24.975"
        y="198.882"
        rx="10.141"
        ry="14.636"
        transform="matrix(.99875 -.0499 .04548 .99897 0 0)"
        fill="#ffd42a"
      />
      <ellipse
        cx="31.914"
        cy="210.883"
        rx="2.113"
        ry="2.188"
        transform="matrix(.99875 -.0499 .04548 .99897 0 0)"
        fill="purple"
      />
      <ellipse
        cx="45.8"
        cy="195.208"
        rx="8.828"
        ry="9.364"
        transform="matrix(.99875 -.0499 .04548 .99897 0 0)"
        fill="#ffd42a"
      />
      <ellipse
        ry="1.286"
        rx="1.242"
        cy="194.333"
        cx="41.696"
        transform="matrix(.99875 -.0499 .04548 .99897 0 0)"
        fill="purple"
      />
      <ellipse
        cx="45.47"
        cy="194.74"
        rx="1.242"
        ry="1.286"
        transform="matrix(.99875 -.0499 .04548 .99897 0 0)"
        fill="purple"
      />
      <path d="M42.978 197.04s2.945-.028 2.756-4.174l2.714 4.85z" fill="#ffd42a" />
      <g fill="#f60000">
        <path
          d="M65.91 182.22c5.745 2.615 10.975 4.163 10.198 6.321-.777 2.16-6.576 2.815-13.003 1.471-6.427-1.343-10.607-4.107-10.197-6.32.664-3.584 4.136-5.509 13.003-1.472z"
        />
        <path d="M53.008 183.413l23.2 4.85-1.503 4.174-23.2-4.85z" />
      </g>
      <path
        d="M69.697 191.916l6.186-2.005c2.835-.918 4.451 2.47 4.893 6.062.442 3.592-.33 6.916-3.217 7.545l-6.265 1.365c-2.885.628-5.626-2.18-6.07-5.77-.441-3.593 1.638-6.279 4.473-7.197z"
        fill="#ffd42a"
      />
      <g transform="matrix(.54937 -.31515 .30076 .57546 29.409 114.37)">
        <circle r="8.221" cy="144.987" cx="-1.73" fill="#ffd42a" />
        <path d="M-9.95 144.987a8.221 8.221 0 0 1 8.22-8.22 8.221 8.221 0 0 1 8.221 8.22h-8.22z" fill="#ffbb15" />
      </g>
      <ellipse
        ry="1.991"
        rx="1.892"
        cy="159.136"
        cx="115.054"
        transform="matrix(.96514 .26174 -.26748 .96356 0 0)"
        fill="#f60000"
      />
      <path d="M64.973 190.395l-17.346-3.316s-4.018-12.64 17.346 3.316z" fill="#c30000" />
    </g>
  </svg>
</div>
