import { Injectable } from '@angular/core';
import { SelectedFilterKey } from '../pages/listing-search/filter-selected-keys';
import { ListingSearchService } from './listing-search.service';
import { I18nService } from '@app/core';

@Injectable()
export class CoworkingService {
  constructor(private listingSearchService: ListingSearchService, private i18n: I18nService) {}

  public getSharedPrivatePositionsText(listing: any, showCoworkingPrivate?: boolean) {
    if (!listing) {
      return '';
    }

    let translationKey = '';
    if (listing.coworkingPositions && listing.coworkingPositions > 1) {
      if (listing.coworkingShared && !showCoworkingPrivate) {
        translationKey = 'global.sharedPositions';
      } else {
        translationKey = 'global.privatePositions';
      }
    } else {
      if (listing.coworkingShared && !showCoworkingPrivate) {
        translationKey = 'global.sharedPosition';
      } else {
        translationKey = 'global.privatePosition';
      }
    }

    return this.i18n.get(translationKey);
  }
}
