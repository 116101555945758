<div class="row margin-div settings_container" style="margin-top: 50px;">
  <div class="col-md-12 border-title">
    <img *ngIf="isMobile" style="margin: 0px 8px 8px 0px;" src="assets/icons/back.svg" alt="" />
    <strong *ngIf="isMobile" style="font-size: 24px"
      >{{ 'notifications.settings' | translate }}:
      <p class="font-400" style="margin-left: 10px;margin-top: 3px;">
        {{ 'notifications.generalManage' | translate }}
      </p>
    </strong>
    <strong *ngIf="!isMobile" style="font-size: 24px;float: left;margin-left: 134px;"
      >{{ 'notifications.settings' | translate }}:
      <p class="font-400" style="float:right ;margin-left: 10px;margin-top: 3px;">
        {{ 'notifications.generalManage' | translate }}
      </p>
    </strong>
  </div>
  <div class="width-text">
    <div class="col-md-12 padding_separator mt-2">
      <span class="font-600">{{ 'notifications.preferences' | translate }}</span>
    </div>
    <div class="col-md-12 padding_separator mt-2">
      <label *ngIf="!isMobile" class="switch">
        <input type="checkbox" [(ngModel)]="favorites" (change)="turnOffNotification('FAVORITES')" />
        <span class="slider round"></span>
      </label>
      <span class="font-600 ">{{ 'notifications.favorites' | translate }}</span>
      <div [ngClass]="{ 'flex-col-nowrap ': !isMobile }" style="display: flex;">
        <p class="font-400 margin-l">{{ 'notifications.favoritesNotify' | translate }}</p>
        <label *ngIf="isMobile" class="switch">
          <input type="checkbox" [(ngModel)]="favorites" (change)="turnOffNotification('FAVORITES')" />
          <span class="slider round"></span>
        </label>
        <div *ngIf="!isMobile" style="margin-left: 45px" class="">
          <div class="flex">
            <div class="form-check spotapp">
              <input
                class="form-check-input"
                [disabled]="!favorites"
                type="checkbox"
                id="appFavCheck"
                [checked]="!userRestrictions.favorites.excludeSpotApp"
                (change)="saveUserRestrictions('appFavCheck', $event.target.checked)"
              />
              <label class="form-check-label" for="appFavCheck">
                {{ 'notifications.spotApp' | translate }}
              </label>
            </div>
            <div style="margin-left: 20px;" class="form-check emailcheck">
              <input
                [checked]="!userRestrictions.favorites.excludeEmail"
                (change)="saveUserRestrictions('emailFavCheck', $event.target.checked)"
                class="form-check-input"
                [disabled]="!favorites"
                type="checkbox"
                id="emailFavCheck"
              />
              <label class="form-check-label" for="emailFavCheck">
                {{ 'notifications.email' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 padding_separator  mt-2">
      <label *ngIf="!isMobile" class="switch">
        <input type="checkbox" [(ngModel)]="recommendation" (change)="turnOffNotification('RECOMMENDATION')" />
        <span class="slider round"></span>
      </label>
      <span class="font-600">{{ 'notifications.recommendations' | translate }}</span>
      <div [ngClass]="{ 'flex-col-nowrap ': !isMobile }" style="display: flex;">
        <p class="font-400 margin-l">{{ 'notifications.recommendationsNotify' | translate }}</p>
        <label *ngIf="isMobile" class="switch">
          <input type="checkbox" [(ngModel)]="recommendation" (change)="turnOffNotification('RECOMMENDATION')" />
          <span class="slider round"></span>
        </label>
        <div *ngIf="!isMobile" style="margin-left: 45px" class="">
          <div class="flex">
            <div class="form-check spotapp">
              <input
                class="form-check-input"
                type="checkbox"
                [disabled]="!recommendation"
                [checked]="!userRestrictions.recommendation.excludeSpotApp"
                (click)="saveUserRestrictions('appRecomCheck', $event.target.checked)"
                id="appRecomCheck"
              />
              <label class="form-check-label" for="appRecomCheck">
                {{ 'notifications.spotApp' | translate }}
              </label>
            </div>
            <div style="margin-left: 20px;" class="form-check emailcheck">
              <input
                class="form-check-input"
                type="checkbox"
                id="emailRecomCheck"
                [disabled]="!recommendation"
                [checked]="!userRestrictions.recommendation.excludeEmail"
                (change)="saveUserRestrictions('emailRecomCheck', $event.target.checked)"
              />
              <label class="form-check-label" for="emailRecomCheck">
                {{ 'notifications.email' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12  padding_separator mt-2">
      <label *ngIf="!isMobile" class="switch">
        <input type="checkbox" [(ngModel)]="spotSet" (change)="turnOffNotification('SPOTSET')" />
        <span class="slider round"></span>
      </label>
      <span class="font-600">{{ 'notifications.spotSets' | translate }}</span>
      <div [ngClass]="{ 'flex-col-nowrap ': !isMobile }" style="display: flex;">
        <p class="font-400 margin-l">{{ 'notifications.spotNotify' | translate }}</p>
        <label *ngIf="isMobile" class="switch">
          <input type="checkbox" [(ngModel)]="spotSet" (change)="turnOffNotification('SPOTSET')" />
          <span class="slider round"></span>
        </label>
        <div *ngIf="!isMobile" style="margin-left: 45px" class="">
          <div class="flex">
            <div class="form-check spotapp">
              <input
                class="form-check-input"
                type="checkbox"
                id="appspotSetCheck"
                [disabled]="!spotSet"
                [checked]="!userRestrictions.spotSet.excludeSpotApp"
                (change)="saveUserRestrictions('appspotSetCheck', $event.target.checked)"
              />
              <label class="form-check-label" for="appspotSetCheck">
                {{ 'notifications.spotApp' | translate }}
              </label>
            </div>
            <div style="margin-left: 20px;" class="form-check emailcheck">
              <input
                class="form-check-input"
                type="checkbox"
                id="emailSpotSetCheck"
                [disabled]="!spotSet"
                [checked]="!userRestrictions.spotSet.excludeEmail"
                (change)="saveUserRestrictions('emailSpotSetCheck', $event.target.checked)"
              />
              <label class="form-check-label" for="emailSpotSetCheck">
                {{ 'notifications.email' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
