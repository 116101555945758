import * as tslib_1 from "tslib";
import { SelectedFilterKey } from '../pages/listing-search/filter-selected-keys';
var ListingSearchService = /** @class */ (function () {
    function ListingSearchService() {
        this.favoritesFilterSelected = false;
    }
    ListingSearchService.prototype.restoreBackSelectedFiltersFrom = function (selectedFilterKey, account) {
        var defaultListingStatus = [4]; // active
        var id = account.id, companyId = account.companyId;
        var defaultDTO = {
            offeredByUserId: id,
            companyId: companyId,
            listingStatuses: defaultListingStatus
        };
        if (this.checkRole(account, 'ROLE_SPOT_ADMIN')) {
            defaultDTO.offeredByUserId = null;
            defaultDTO.companyId = null;
        }
        else if (this.checkRole(account, 'ROLE_LISTING_ADMIN')) {
            defaultDTO.offeredByUserId = null;
        }
        localStorage.setItem(selectedFilterKey, JSON.stringify(defaultDTO));
    };
    ListingSearchService.prototype.readFilterPreferencesFrom = function (selectedFilterKey) {
        return localStorage.getItem(selectedFilterKey);
    };
    ListingSearchService.prototype.removeFilterPreferencesFrom = function (selectedFilterKey) {
        localStorage.removeItem(selectedFilterKey);
    };
    ListingSearchService.prototype.readSearchDTOFromStorage = function (itemName, defaultValue) {
        var searchDtoItem = itemName;
        var filterPreferencesDTO = this.readFilterPreferencesFrom(searchDtoItem);
        if (filterPreferencesDTO && filterPreferencesDTO.length) {
            return JSON.parse(filterPreferencesDTO);
        }
        return defaultValue;
    };
    ListingSearchService.prototype.readLastSearchPageFromStorage = function () {
        var lastSearchPage = localStorage.getItem('lastSearchPage');
        if (lastSearchPage && lastSearchPage == SelectedFilterKey.MY_LISTINGS) {
            return SelectedFilterKey.MY_LISTINGS;
        }
        else if (lastSearchPage && lastSearchPage == SelectedFilterKey.LISTING_ANALYTICS) {
            return SelectedFilterKey.LISTING_ANALYTICS;
        }
        else if (lastSearchPage && lastSearchPage == SelectedFilterKey.FAVORITES_SEARCH) {
            return SelectedFilterKey.FAVORITES_SEARCH;
        }
        return SelectedFilterKey.REGULAR_SEARCH;
    };
    ListingSearchService.prototype.saveLastSeenSearchPage = function (itemName) {
        localStorage.setItem('lastSearchPage', itemName);
    };
    ListingSearchService.prototype.saveSearchDtoOnStorage = function (itemName, listingSearchDto) {
        var listingSearchDtoCopy = tslib_1.__assign({}, listingSearchDto);
        listingSearchDtoCopy.spotsNearMe = null;
        var searchDto = JSON.stringify(listingSearchDtoCopy);
        localStorage.setItem(itemName, searchDto);
    };
    ListingSearchService.prototype.findLocalStorageKeyFromSelectedFilters = function (_a) {
        var myListingAnalytics = _a.myListingAnalytics, myListingPage = _a.myListingPage, favoritesPage = _a.favoritesPage;
        var searchDtoItem = SelectedFilterKey.REGULAR_SEARCH;
        if (myListingAnalytics) {
            searchDtoItem = SelectedFilterKey.LISTING_ANALYTICS;
        }
        else if (myListingPage) {
            searchDtoItem = SelectedFilterKey.MY_LISTINGS;
        }
        else if (favoritesPage) {
            searchDtoItem = SelectedFilterKey.FAVORITES_SEARCH;
        }
        return searchDtoItem;
    };
    ListingSearchService.prototype.checkRole = function (user, role) {
        if (user) {
            if (user.roles.includes(role)) {
                return true;
            }
            else {
                return false;
            }
        }
        return false;
    };
    ListingSearchService.prototype.findCoworkingTypeFromSearch = function () {
        var defaultCoworkingType = null;
        var selectedFilterKey = this.readLastSearchPageFromStorage();
        if (selectedFilterKey) {
            var searchDTO = this.readSearchDTOFromStorage(selectedFilterKey, {});
            if (searchDTO && searchDTO.coworkingType) {
                defaultCoworkingType = searchDTO.coworkingType;
            }
        }
        return defaultCoworkingType;
    };
    return ListingSearchService;
}());
export { ListingSearchService };
