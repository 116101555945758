import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SocialMediaProviders, textFromHtml, shareSocialMedia } from '@app/core/helpers/share-helper';
import { SocialMediaInput } from '@app/models/social-media.model';
import { NavParams, PopoverController } from '@ionic/angular';
import { NgNavigatorShareService } from 'ng-navigator-share';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {
  socialMediaLinks: any[];
  input: SocialMediaInput;
  @ViewChild('toolTip', { static: false }) el: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    private ngNavigatorShareService: NgNavigatorShareService,
    private navParams: NavParams,
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    this.input = this.navParams.data.socialMediaInput;
    /*
    this.socialMediaLinks = [
      {
        img: '../../../assets/icons/copy.png',
        providerId: 'copy',
        link: this.document.location.href,
        options: {
          title: 'Copy'
        }
      },
      {
        img: '../../../assets/icons/whatsapp.png',
        providerId: 'whatsapp',
        link: this.document.location.href,
        options: {
          title: null
        }
      },
      {
        img: '../../../assets/icons/mail.png',
        providerId: 'email',
        link: 'mailto:',
        options: {
          mailTo: '',
          subject: null,
          body: null
        }
      },
      {
        img: '../../../assets/icons/facebook.png',
        providerId: 'facebook',
        link: 'https://web.facebook.com/SiiLA.BR/',
        options: {
          title: null
        }
      },
      {
        img: '../../../assets/icons/in.png',
        providerId: 'linkedin',
        link: 'https://www.linkedin.com/company/siilabrasil',
        options: {
          title: null
        }
      },
      {
        img: '../../../assets/icons/share.png',
        providerId: 'share',
        link: this.document.location.href,
        options: {
          title: null
        }
      }
    ];
    */
    this.socialMediaLinks = [
      {
        img: '../../../assets/icons/in.png',
        providerId: 'linkedin',
        link: 'https://www.linkedin.com/company/siilabrasil',
        options: {
          title: null
        }
      },
      {
        img: '../../../assets/icons/whatsapp.png',
        providerId: 'whatsapp',
        link: this.input.url, //this.document.location.href
        options: {
          title: null
        }
      },
      {
        img: '../../../assets/icons/facebook.png',
        providerId: 'facebook',
        link: 'https://web.facebook.com/SiiLA.BR/',
        options: {
          title: null
        }
      }
    ];
  }

  shareLink(socialLink: any) {
    const title = this.input.sharedTitle;
    const content = this.input.description ? this.input.description : '';
    const url = this.input.url;

    switch (socialLink.providerId) {
      case SocialMediaProviders.Facebook:
      case SocialMediaProviders.LinkedIn:
        socialLink.options.body = textFromHtml(content);
        socialLink.options.url = url;
        socialLink.options.title = title;
        shareSocialMedia(socialLink);
        break;
      case SocialMediaProviders.Email:
        socialLink.options.subject = 'Company: ' + title;
        socialLink.options.body = encodeURIComponent(textFromHtml(`${content}\n\n<br><br><a href="${url}">${url}</a>`));
        shareSocialMedia(socialLink);
        break;
      case SocialMediaProviders.Copy:
        navigator.clipboard.writeText(socialLink.link);
        /*  const elem = document.createElement('input');
         elem.value = socialLink.link;
         document.body.appendChild(elem);
         elem.select();
         document.execCommand('copy');
         document.body.removeChild(elem); */
        this.renderer.setAttribute(this.el.nativeElement, 'style', 'display: block');
        setTimeout(() => {
          this.renderer.setAttribute(this.el.nativeElement, 'style', 'display: none');
        }, 2000);
        break;
      case SocialMediaProviders.Whatsapp:
        window.open(`https://api.whatsapp.com/send?text=${title}%20${socialLink.link}`, '_blank');
        break;
      case SocialMediaProviders.Share:
        this.ngNavigatorShareService
          .share({
            title: title,
            text: '',
            url: socialLink.link
          })
          .then((response: any) => {})
          .catch((error: any) => {});
        break;
      default:
        break;
    }
  }

  closeModal() {
    this.popoverCtrl.dismiss();
  }

  copyURLToClipboard() {
    const data = {
      img: '../../../assets/icons/copy.png',
      providerId: 'copy',
      link: this.getSharedURL(),
      options: {
        title: 'Copy'
      }
    };

    this.shareLink(data);
  }

  getSharedURL = () => this.input.url;
}
