import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { I18nService, PlatformHelperService } from '@app/core';
import { SpotSetBody } from '@app/models/spot-set-list.model';
import { SavedSearchDTO, SavedSearchPaginated } from '@app/models/saved-search-dashboard.model';
import { ToastController } from '@ionic/angular';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { MessageModalService } from '../services/message-modal.service';
import {
  faTrash,
  faEllipsisV,
  faEdit,
  faSearch,
  faFilter,
  faArrowCircleDown,
  faFileDownload,
  faShare
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { DownloadFileService } from '../services/downloadFile.service';
import { ShareEmailComponent } from '../share-email/share-email.component';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { EditTourBookComponent } from '../edit-tour-book/edit-tour-book.component';
import { BuildingFeaturesDTO } from '../../models/spot-buildings/spot-buildings-features.model';
import { SelectedFilterKey } from '../../search-navigation/pages/listing-search/filter-selected-keys';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';

@Component({
  selector: 'app-saved-search-dashboard',
  templateUrl: './saved-search-dashboard.component.html',
  styleUrls: ['./saved-search-dashboard.component.scss']
})
export class SavedSearchDashboardComponent implements OnInit {
  @Output() changeStepOutput = new EventEmitter<number>();
  @Input() countSpotSet: number;

  trash: any;
  ellipsisV: any;
  edit: any;
  search: any;
  filter: any;
  arrowDown: any;
  fileDownload: any;
  share: any;

  pageSize = 10;
  page = 0;
  collection = 0;
  filterTourBook: string;
  seeSearch = false;
  param: string;
  direction: string;
  isMobile: boolean = false;
  filterCount = 0;
  savedSearchesList: SavedSearchPaginated;
  spotFeaturesDTO: BuildingFeaturesDTO;

  constructor(
    private favoriteServices: FavoriteSpotService,
    public i18nService: I18nService,
    private dialog: MatDialog,
    private toastCtrl: ToastController,
    private messageFormDialog: MessageModalService,
    private breakpointObserver: BreakpointObserver,
    private downloadFileService: DownloadFileService,
    private platformHelpService: PlatformHelperService,
    private routingHelperSrv: RoutingHelperService,
    private router: Router
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.trash = faTrash;
    this.ellipsisV = faEllipsisV;
    this.edit = faEdit;
    this.search = faSearch;
    this.filter = faFilter;
    this.arrowDown = faArrowCircleDown;
    this.fileDownload = faFileDownload;
    this.share = faShare;

    this.getSavedSearches(0);
  }

  getSavedSearches(page: number) {
    this.page = page;
    const lang = this.i18nService.getCurrentLanguage();
    const bodySpotSet: SpotSetBody = {
      sortBy: this.param,
      direction: this.direction,
      titleFilter: this.filterTourBook
    };
    this.favoriteServices.getSavedSearches(page, lang, bodySpotSet).then((value: any) => {
      this.savedSearchesList = value;
      this.collection = this.savedSearchesList.totalElements;
      this.pageSize = this.savedSearchesList.size;
      this.setSpotFeatures();
    });
  }

  private addFeatureToSavedSearch(featured: string, savedSearch: SavedSearchDTO) {
    if (!savedSearch.featuresCount) {
      savedSearch.featuresCount = 0;
    }
    if (!savedSearch.allFeatures) {
      savedSearch.allFeatures = [];
    }
    savedSearch.allFeatures.push(this.i18nService.get(featured));
    savedSearch.featuresCount++;
  }

  private fillUpFeatures(savedSearch: SavedSearchDTO) {
    if (savedSearch && savedSearch.allFeatures && savedSearch.allFeatures.length > 0) {
      savedSearch.allFeatures = savedSearch.allFeatures.sort((a, b) => a.localeCompare(b));
      savedSearch.allFeaturesTooltip = savedSearch.allFeatures.join(', ');

      let tmp = [...savedSearch.allFeatures];
      if (tmp.length > 3) {
        tmp = tmp.slice(0, 3);
      }
      savedSearch.features = tmp.join(', ');
    } else {
      savedSearch.features = 'N/A';
    }
  }

  private setSpotFeatures() {
    if (this.savedSearchesList) {
      const allSavedSearches = this.savedSearchesList.content;
      for (let savedSearch of allSavedSearches) {
        const filterDTO = savedSearch.filterDTO;
        if (filterDTO) {
          if (filterDTO.bicycleRack) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.bicycleRack', savedSearch);
          }

          if (filterDTO.fiberOptic) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.fiberOptic', savedSearch);
          }

          if (filterDTO.bulletProofCabin) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.armoredCabin', savedSearch);
          }

          if (filterDTO.centralAirConditioning) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.centralAirConditioning', savedSearch);
          }

          if (filterDTO.electricGenerator) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.electricGenerator', savedSearch);
          }

          if (filterDTO.heliport) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.heliport', savedSearch);
          }

          if (filterDTO.leedStatus) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.sustentability', savedSearch);
          }

          if (filterDTO.sluiceGate) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.sluiceGate', savedSearch);
          }

          if (filterDTO.singleTenant) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.singleTenant', savedSearch);
          }

          if (filterDTO.sprinklers) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.sprinklers', savedSearch);
          }

          if (filterDTO.truckYard) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.truckYard', savedSearch);
          }

          if (filterDTO.twentyFourHourSecurity) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.twentyFourHourSecurity', savedSearch);
          }

          //End Features

          // Spot Features

          if (filterDTO.blockAndSteelConstruction) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.blockAndSteelConstruction', savedSearch);
          }
          if (filterDTO.coreAndShellCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.coreAndShel', savedSearch);
          }
          if (filterDTO.crossDocking) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.crossDocking', savedSearch);
          }

          if (filterDTO.datacenterCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.dataCenter', savedSearch);
          }
          if (filterDTO.forRetailUseConditionOffice) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.forRetail', savedSearch);
          }
          if (filterDTO.forRetailUseConditionIndustrial) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.forRetail', savedSearch);
          }
          if (filterDTO.flexCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.flex', savedSearch);
          }

          if (filterDTO.fullFloorCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.fullFloor', savedSearch);
          }
          if (filterDTO.includeColdStorage) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.includeColdStorage', savedSearch);
          }
          if (filterDTO.manufacturingCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.manufacturing', savedSearch);
          }
          if (filterDTO.logisticsCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.logistics', savedSearch);
          }

          if (filterDTO.plugAndPlayConditionOffice) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.plugAndPlay', savedSearch);
          }
          if (filterDTO.preBuildCondition) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.preBuild', savedSearch);
          }
          if (filterDTO.skyLightsSpace) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.skyLights', savedSearch);
          }

          if (filterDTO.steelConstruction) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.steelConstruction', savedSearch);
          }
          if (filterDTO.subLease) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.subLease', savedSearch);
          }
          if (filterDTO.petFriendly) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.petFriendly', savedSearch);
          }

          if (filterDTO.plugAndPlayConditionCoworking) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.plugAndPlay', savedSearch);
          }
          if (filterDTO.furnishedConditionCoworking) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.furnished', savedSearch);
          }
          if (filterDTO.barista) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.barista', savedSearch);
          }

          if (filterDTO.enhancedCleaning) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.enhancedCleaning', savedSearch);
          }
          if (filterDTO.parkingAvailable) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.parking', savedSearch);
          }
          if (filterDTO.conferenceRoom) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.conferenceRoom', savedSearch);
          }
          if (filterDTO.privateOutdoorSpace) {
            this.addFeatureToSavedSearch('global.addtSearchFilters.privateOutdoorSpace', savedSearch);
          }
        }
        this.fillUpFeatures(savedSearch);
      }
    }
  }

  changePage(page: number) {
    this.getSavedSearches(page - 1);
  }

  searchProperty() {
    this.seeSearch = !this.seeSearch;
  }
  searchFilter() {
    if (this.filterCount == 0) {
      this.filterCount = 1;
      setTimeout(() => {
        this.getSavedSearches(0);
        this.filterCount = 0;
      }, 2000);
    }
  }

  nameAscending() {
    this.param = 'name';
    this.direction = 'ASC';

    this.getSavedSearches(0);
  }

  nameDescending() {
    this.param = 'name';
    this.direction = 'DESC';

    this.getSavedSearches(0);
  }

  oldestFirst() {
    this.param = 'date';
    this.direction = 'ASC';
    this.getSavedSearches(0);
  }

  newestFirst() {
    this.param = 'date';
    this.direction = 'DESC';
    this.getSavedSearches(0);
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 3500,
      animated: true,
      keyboardClose: true,
      cssClass: 'toast-alert-detail'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  propertiesText(message: string) {
    this.messageFormDialog.openDialogMessage(message);
  }

  deleteSpotSearch(id: number) {
    let dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '500px',
      data: {
        message: this.i18nService.get('favorites.savedSearches.deleteTour'),
        id: id,
        apiName: '/api/spot-search/'
      },
      autoFocus: true,
      restoreFocus: true
    });

    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.presentToast(this.i18nService.get('favorites.deleteSavedSearchMessage'));
        this.getSavedSearches(0);
      }
    });
  }

  goToSearchPage() {
    this.routingHelperSrv.navigateToDefaultListingPropertySearchURL();
  }

  searchSpotSearch(savedSearch: SavedSearchDTO) {
    return this.routingHelperSrv.navigateToSavedSearchPage(savedSearch);
  }
}
