<ion-card class="company__agent__card">
  <div class="flex-row-justify-center company__agent__card__photo__section">
    <img
      style=" border-radius: 50%;
                min-width: 180px;
                max-width: 180px;
                height: 180px;
                object-fit: cover;"
      [src]="getLargeImg(agentDTO.photoId)"
      default="assets/images/default-profile-photo.png"
    />
  </div>
  <ion-card-header class="flex-row-justify-center header__section">
    <div class="flex-col-wrap">
      <ion-card-title class="agent__title"> {{ agentDTO.firstNameAndLastName }}</ion-card-title>
      <ion-card-subtitle class="agent__subtitle">{{ agentDTO.jobTitle }}</ion-card-subtitle>
    </div>
  </ion-card-header>
  <ion-card-content>
    <div class="company__agent__card__content__info">
      <div class="company__agent__card__content__info__icon">
        <img src="../../../assets/icons/new location_pin.svg" />
      </div>
      <div class="company__agent__card__content__info__text">{{ agentDTO.stateName }}</div>

      <div class="company__agent__card__content__info__icon">
        <img src="../../../assets/icons/email.svg" />
      </div>
      <div class="company__agent__card__content__info__link">
        <a *ngIf="showEmail" (click)="openEmail($event)" class="add-padding">{{
          'global.listing-detail.showEmail' | translate
        }}</a>
        <a *ngIf="!showEmail" (click)="openEmail($event)" class="add-padding">{{ agentDTO.userEmail }}</a>
      </div>

      <div *ngIf="agentDTO.phoneNumber" class="company__agent__card__content__info__icon">
        <img src="../../../assets/icons/whatsapp.png" />
      </div>
      <div *ngIf="agentDTO.phoneNumber" class="company__agent__card__content__info__link">
        <a *ngIf="showWhatsapp" (click)="openWhatasapp($event)">
          {{ 'global.listing-detail.showWhataspp' | translate }}</a
        >
        <a *ngIf="!showWhatsapp" (click)="openWhatasapp($event)" href="tel:{{ agentDTO.phoneNumber }}">{{
          agentDTO.phoneNumber
        }}</a>
      </div>
    </div>
  </ion-card-content>
</ion-card>
