import * as tslib_1 from "tslib";
import { BuildingFeaturesDTO } from '@app/models/spot-buildings/spot-buildings-features.model';
var SpotBuildingsUtilService = /** @class */ (function () {
    function SpotBuildingsUtilService() {
        this.arr = new BuildingFeaturesDTO();
    }
    SpotBuildingsUtilService.prototype.getAmenitiesBuildings = function (type, offer, isEntire) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var industrialFilters, industrialFilters, buildingsOfficeFilters, buildingsOfficeFilters, buildingsOfficeFilters;
            return tslib_1.__generator(this, function (_a) {
                if (type === 'industrialBuildings') {
                    if (offer == 'L') {
                        industrialFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                            { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                            { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                            { selected: false, name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard' },
                            { selected: false, name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking' },
                            { selected: false, name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.blockAndSteelConstruction',
                                fieldName: 'blockAndSteelConstruction'
                            },
                            { selected: false, name: 'global.addtSearchFilters.blockConstruction', fieldName: 'blockConstruction' },
                            { selected: false, name: 'global.addtSearchFilters.steelConstruction', fieldName: 'steelConstruction' },
                            { selected: false, name: 'global.addtSearchFilters.waterHydrant', fieldName: 'waterHydrant' },
                            { selected: false, name: 'global.addtSearchFilters.withinIndustrialPark', fieldName: 'withinIndustrialPark' }
                        ];
                        return [2 /*return*/, industrialFilters];
                    }
                    else {
                        industrialFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                            { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                            { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                            { selected: false, name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard' },
                            { selected: false, name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking' },
                            { selected: false, name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.blockAndSteelConstruction',
                                fieldName: 'blockAndSteelConstruction'
                            },
                            { selected: false, name: 'global.addtSearchFilters.blockConstruction', fieldName: 'blockConstruction' },
                            { selected: false, name: 'global.addtSearchFilters.steelConstruction', fieldName: 'steelConstruction' },
                            { selected: false, name: 'global.addtSearchFilters.bulletProofCabin', fieldName: 'bulletProofCabin' },
                            { selected: false, name: 'global.addtSearchFilters.waterHydrant', fieldName: 'waterHydrant' },
                            { selected: false, name: 'global.addtSearchFilters.withinIndustrialPark', fieldName: 'withinIndustrialPark' }
                        ];
                        if (isEntire) {
                            industrialFilters.push({
                                selected: false,
                                name: 'global.addtSearchFilters.includesOffice',
                                fieldName: 'includesOffice'
                            }, {
                                selected: false,
                                name: 'global.addtSearchFilters.includesRetail',
                                fieldName: 'includesRetail'
                            }, {
                                selected: false,
                                name: 'global.addtSearchFilters.coldStorage',
                                fieldName: 'coldStorage'
                            }, {
                                selected: false,
                                name: 'global.addtSearchFilters.fullBuildOut',
                                fieldName: 'fullBuildOut'
                            }, {
                                selected: false,
                                name: 'global.addtSearchFilters.skyLights',
                                fieldName: 'skyLights'
                            });
                        }
                        return [2 /*return*/, industrialFilters];
                    }
                }
                if (type === 'officeBuildings') {
                    if (offer == 'L') {
                        buildingsOfficeFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                            { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                            { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
                            { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.centralAirConditioning',
                                fieldName: 'centralAirConditioning'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.petFriendly',
                                fieldName: 'petFriendly'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.accessToShowers',
                                fieldName: 'accessToShowers'
                            }
                        ];
                        return [2 /*return*/, buildingsOfficeFilters];
                    }
                    else {
                        if (offer == 'C') {
                            buildingsOfficeFilters = [
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                    fieldName: 'twentyFourHourSecurity'
                                },
                                { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                                { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                                { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                                { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
                                { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.centralAirConditioning',
                                    fieldName: 'centralAirConditioning'
                                },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.petFriendly',
                                    fieldName: 'petFriendly'
                                },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.mailAndPackage',
                                    fieldName: 'mailAndPackage'
                                },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.enhancedCleaning',
                                    fieldName: 'enhancedCleaning'
                                },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.barista',
                                    fieldName: 'barista'
                                },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.cafeteria',
                                    fieldName: 'cafeteria'
                                },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.accessToShowers',
                                    fieldName: 'accessToShowers'
                                }
                            ];
                            return [2 /*return*/, buildingsOfficeFilters];
                        }
                        else {
                            buildingsOfficeFilters = [
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                    fieldName: 'twentyFourHourSecurity'
                                },
                                { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                                { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                                { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                                { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                                { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                                { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                                { selected: false, name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic' },
                                {
                                    selected: false,
                                    name: 'global.addtSearchFilters.centralAirConditioning',
                                    fieldName: 'centralAirConditioning'
                                }
                            ];
                            return [2 /*return*/, buildingsOfficeFilters];
                        }
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    SpotBuildingsUtilService.prototype.checkFields = function (allFields) {
        var _this = this;
        this.arr = new BuildingFeaturesDTO();
        Object.keys(allFields).forEach(function (key) {
            if (allFields[key].fieldName === 'twentyFourHourSecurity') {
                _this.arr.twentyFourHourSecurity = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'electricGenerator') {
                _this.arr.electricGenerator = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'heliport') {
                _this.arr.heliport = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'sprinklers') {
                _this.arr.sprinklers = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'leedStatus') {
                _this.arr.leedStatus = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'singleTenant') {
                _this.arr.singleTenant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'multiTenant') {
                _this.arr.multiTenant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'bicycleRack') {
                _this.arr.bicycleRack = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'fiberOptic') {
                _this.arr.fiberOptic = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'centralAirConditioning') {
                _this.arr.centralAirConditioning = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'petFriendly') {
                _this.arr.petFriendly = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'truckYard') {
                _this.arr.truckYard = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'crossDocking') {
                _this.arr.crossDocking = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'sluiceGate') {
                _this.arr.sluiceGate = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'blockAndSteelConstruction') {
                _this.arr.blockAndSteelConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'blockConstruction') {
                _this.arr.blockConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'steelConstruction') {
                _this.arr.steelConstruction = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'includesOffice') {
                _this.arr.includesOffice = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'includesRetail') {
                _this.arr.includesRetail = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'coldStorage') {
                _this.arr.coldStorage = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'fullBuildOut') {
                _this.arr.fullBuildOut = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'skyLights') {
                _this.arr.skyLights = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'bulletProofCabin') {
                _this.arr.bulletProofCabin = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'mailAndPackage') {
                _this.arr.mailPackageHandling = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'enhancedCleaning') {
                _this.arr.enhancedCleaning = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'barista') {
                _this.arr.barista = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'cafeteria') {
                _this.arr.cafeteria = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'waterHydrant') {
                _this.arr.waterHydrant = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'withinIndustrialPark') {
                _this.arr.withinIndustrialPark = allFields[key].selected;
            }
            if (allFields[key].fieldName === 'accessToShowers') {
                _this.arr.accessToShowers = allFields[key].selected;
            }
        });
        var filters = this.arr;
        return filters;
    };
    SpotBuildingsUtilService.prototype.highlightWrap = function (s, className) {
        if (className === void 0) { className = ''; }
        return "<span class=\"highlight " + className + "\">" + s + "</span>";
    };
    SpotBuildingsUtilService.prototype.normalize = function (s) {
        return s.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    };
    SpotBuildingsUtilService.prototype.scapeRegexCharacters = function (search) {
        var _this = this;
        if (search === void 0) { search = ''; }
        var pattern = search
            .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
            .split(' ')
            .filter(function (t) {
            return t.length > 0;
        })
            .map(function (word) { return _this.normalize(word); })
            .join('|');
        return pattern;
    };
    return SpotBuildingsUtilService;
}());
export { SpotBuildingsUtilService };
