import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { PrivacyPolicyReviewComponent } from './privacy-policy-review/privacy-policy-review.component';

interface PrivacyPolicyData {
  date: string;
  acceptPrivacyPolicy: () => Observable<object>;
  openPrivacyReview: () => MatDialogRef<PrivacyPolicyReviewComponent>;
}

@Component({
  selector: 'privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.scss']
})
export class PrivacyPolicyDialogComponent {
  date: string;
  acceptPrivacyPolicy: () => Observable<object>;
  openPrivacyReview: () => MatDialogRef<PrivacyPolicyReviewComponent>;
  acceptedPolicy: boolean = false;
  loading: boolean = false;
  toastPresent: boolean = false;

  constructor(
    private ppDialogRef: MatDialogRef<PrivacyPolicyDialogComponent>,
    private accountService: AccountService,
    private auth: AuthenticationService,
    private toastCtrl: ToastController,
    private i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA) public data: PrivacyPolicyData
  ) {
    this.date = data.date;
    this.acceptPrivacyPolicy = data.acceptPrivacyPolicy;
    this.openPrivacyReview = data.openPrivacyReview;
  }

  async onDismiss() {
    this.ppDialogRef.close();
    await this.auth.logout();
  }

  async showPrivacyPolicyErr() {
    if (!this.toastPresent) {
      this.toastPresent = true;
      const toast = await this.toastCtrl.create({
        message: this.i18nService.get('global.privacy-policy.dialog.error'),
        duration: 3000,
        animated: true,
        keyboardClose: true,
        cssClass: 'toast-error'
      });
      toast.onDidDismiss().then(() => {
        this.toastPresent = false;
      });
      return await toast.present();
    }
  }

  onSubmit() {
    this.loading = true;
    this.acceptPrivacyPolicy().subscribe(
      () => {
        this.loading = false;
        const user = {
          ...this.accountService.getCurrentUser,
          ppAcceptedVersion: this.date
        };
        localStorage.setItem('user', JSON.stringify(user));
        this.accountService.currentUser$.next(user);
        this.ppDialogRef.close();
      },
      error => {
        this.loading = false;
        this.showPrivacyPolicyErr();
        console.error('There was an error accepting privacy policy: ', error);
      }
    );
  }
}
