<ion-content>
  <!-- Mobile -->
  <div *ngIf="isMobile" class="col-md-12" style="overflow: auto;height: 85vh; overflow-x: hidden;">
    <div
      class="row"
      style="background: #00334D;min-height: 76px;padding: 40px 18px 0px 18px; position: fixed;
    z-index: 2;width: 100%;"
    >
      <div style="float: left;width: 80%;">
        <label
          style="color: white; font-size: 20px;
        font-weight: 700;"
        >
          {{ spotTitle }}
        </label>
      </div>

      <div style="float: right;width: 20%;">
        <img
          class="close-icon"
          style="width: 14px;height: 14px;float: right;
        margin-top: 10px;"
          (click)="closeModal()"
          src="assets/images/icons/closeWhite.png"
          alt=""
        />
      </div>
    </div>

    <div class="col-md-12" style="padding: 0px;margin: 108px 0px 10px 0px;">
      <div *ngIf="!isForCoworking">
        <label class="header-title"> {{ getPriceTitle() }}</label>
        <label class="header-value">{{ getPriceValue() }} </label>
      </div>

      <div *ngIf="!isForCoworking">
        <label class="header-title"> {{ getAvailableSpaceTitle() }}</label>
        <label class="header-value">{{ getAvailableSpaceValue() }} </label>
      </div>

      <div *ngIf="!isForCoworking">
        <label class="header-title"> {{ getPricePerAreaTitle() }}</label>
        <label class="header-value">{{ getPricePerAreaValue() }} </label>
      </div>

      <div *ngIf="isForCoworking && space.positions">
        <label class="header-title"> {{ getCoworkingPositionsTitle() }}</label>
        <label class="header-value">{{ getCoworkingPositions() }} </label>
      </div>

      <div *ngIf="isForCoworking">
        <label class="header-title"> {{ getMonthlyCostTitle() }}</label>
        <label class="header-value">{{ getMonthlyCostValue() }} </label>
      </div>
    </div>

    <ion-row class="row-border"></ion-row>

    <!--Toogles-->
    <div class="flex full-width toogles-container">
      <button
        class="spaceimgs-btn"
        [ngClass]="{ 'active-toogle': showSpaceImgs }"
        translate="spaceDetail.spaceImages"
        (click)="toogleAction('spaceImgs')"
      ></button>
      <button
        class="moreinfo-btn"
        [ngClass]="{ 'active-toogle': showMoreInfo }"
        translate="spaceDetail.moreInfo"
        (click)="toogleAction('moreInfo')"
      ></button>
      <button
        class="media-kit-btn"
        [ngClass]="{ 'active-toogle': showMediaKit }"
        translate="spaceDetail.mediaKit"
        (click)="toogleAction('mediaKit')"
      ></button>
    </div>

    <!--Space Images -->
    <div *ngIf="showSpaceImgs" style="margin-bottom: 62px;">
      <app-image-gallery [topImage]="'10px'" [photos]="spacePhotos"> </app-image-gallery>
    </div>

    <!-- More Info -->
    <div *ngIf="showMoreInfo && investmentOpportunity">
      <label class="details_title" translate="spaceDetail.investmentOpportunity"></label>
      <span style="font-size: 16px;color: #212529;" [innerHTML]="investmentOpportunity"></span>
    </div>
    <div *ngIf="showMoreInfo" style="margin-top: 16px;">
      <label class="details_title" translate="spaceDetail.spotDesc"></label>
      <span style="font-size: 16px;color: #212529;" [innerHTML]="spaceDescription"></span>
    </div>
    <!-- Property Details-->
    <div *ngIf="showMoreInfo" class="section-separator" style="margin-bottom: 86px;">
      <label class="details_title"> {{ 'spaceDetail.propertyDetails' | translate }}</label>

      <!-- Details Table -->
      <table class="table table-borderless details_table">
        <thead>
          <tr class="key_facts">
            <th scope="col" colspan="4"><label translate="spaceDetail.keyFacts"></label></th>
          </tr>
        </thead>
        <tbody class="details_body_table" *ngFor="let detailRow of detailsTableRows">
          <tr>
            <td>
              <label class="property_key" [translate]="detailRow.col1.translate"></label>
            </td>
            <td>
              <label class="property_value">{{ detailRow.col1.value }}</label>
            </td>
          </tr>
        </tbody>
        <tbody class="details_body_table" *ngFor="let detailRow of detailsTableRows">
          <tr *ngIf="detailRow.col2">
            <td [ngClass]="{ 'price-bg-color': detailRow.col2.priceClass }">
              <label class="property_key " [translate]="detailRow.col2.translate"></label>
            </td>
            <td [ngClass]="{ 'price-bg-color': detailRow.col2.priceClass }">
              <label class="property_value ">{{ detailRow.col2.value }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--Media Kit-->
    <div *ngIf="showMediaKit" class="media-kit-container-mobile" style="text-align: center;margin-bottom: 78px;">
      <label class="details_title" translate="spaceDetail.scheduleTour"></label>
      <img *ngIf="isForIndustrial" src="../../../assets/Industrial Building.H03.2k-lr3.png" />
      <img *ngIf="isForOffice" src="../../../assets/Building.H03.2k-lr2.png" />
      <img *ngIf="isForCoworking" src="../../../assets/Building.H031.png" />
    </div>

    <div *ngIf="showMoreInfo || showSpaceImgs" class="request-information section-separatorn fixed-button">
      <ion-button
        (click)="requestInfoAction($event)"
        style="color: #331f00;--color-hover: white;--background: #ff9900;"
        class="full-width request-info-btn btn-color-2"
      >
        <span>{{ 'spaceDetail.requestInfo' | translate }}</span>
      </ion-button>
    </div>

    <div *ngIf="showMediaKit" class="section-separator fixed-button" style="width: 100%;">
      <!-- <button class="tour-btn btn-white">
        <span>Request TOUR</span>
      </button> -->
      <button class="tour-btn btn-white" (click)="openVideoTour($event)" style="width: 100%;" *ngIf="space.vrTourUrl">
        <span> {{ 'spaceDetail.virtualTOUR' | translate }}</span>
      </button>
      <ion-button
        (click)="requestInfoAction($event)"
        style="margin-top: 10px;width: 100%;"
        class="request-info-btn btn-color-2"
      >
        <span>{{ 'spaceDetail.requestInfo' | translate }}</span>
      </ion-button>
    </div>
  </div>

  <!-- Desktop -->
  <div *ngIf="!isMobile" class="flex main-container">
    <div class="flex">
      <label class="building-title"> {{ spotTitle }} </label>
      <div class="flex-align-content-end">
        <img
          class="close-icon"
          style="width: 14px;height: 14px;"
          (click)="closeModal()"
          src="assets/images/icons/close.png"
          alt=""
        />
      </div>
    </div>

    <div class="header-grid">
      <div class="flex" *ngIf="!isForCoworking">
        <label class="header-title"> {{ getPriceTitle() }}</label>
        <label class="header-value">{{ getPriceValue() }} </label>
      </div>

      <div class="flex" *ngIf="!isForCoworking">
        <label class="header-title"> {{ getAvailableSpaceTitle() }}</label>
        <label class="header-value">{{ getAvailableSpaceValue() }} </label>
      </div>

      <div class="flex" *ngIf="!isForCoworking">
        <label class="header-title"> {{ getPricePerAreaTitle() }}</label>
        <label class="header-value">{{ getPricePerAreaValue() }} </label>
      </div>

      <div class="flex" *ngIf="isForCoworking && space.positions">
        <label class="header-title"> {{ getCoworkingPositionsTitle() }}</label>
        <label class="header-value">{{ getCoworkingPositions() }} </label>
      </div>

      <div class="flex" *ngIf="isForCoworking">
        <label class="header-title"> {{ getMonthlyCostTitle() }}</label>
        <label class="header-value">{{ getMonthlyCostValue() }} </label>
      </div>
    </div>

    <!--Toogles-->
    <div class="flex full-width toogles-container">
      <button
        class="spaceimgs-btn"
        [ngClass]="{ 'active-toogle': showSpaceImgs }"
        translate="spaceDetail.spaceImages"
        (click)="toogleAction('spaceImgs')"
      ></button>
      <button
        class="moreinfo-btn"
        [ngClass]="{ 'active-toogle': showMoreInfo }"
        translate="spaceDetail.moreInfo"
        (click)="toogleAction('moreInfo')"
      ></button>
      <button
        class="media-kit-btn"
        [ngClass]="{ 'active-toogle': showMediaKit }"
        translate="spaceDetail.mediaKit"
        (click)="toogleAction('mediaKit')"
      ></button>
    </div>

    <!--Space Images -->
    <div [ngClass]="{ 'margin-top-gallery-img': !spacePhotos || spacePhotos.length == 0 }" *ngIf="showSpaceImgs">
      <app-image-gallery nestedFloating="true" [photos]="spacePhotos"> </app-image-gallery>
    </div>

    <!-- More Info -->
    <div *ngIf="showMoreInfo && investmentOpportunity">
      <label class="details_title" translate="spaceDetail.investmentOpportunity"></label>
      <span class="investment__opportunity" [innerHTML]="investmentOpportunity"></span>
    </div>
    <div *ngIf="showMoreInfo">
      <label class="details_title" translate="spaceDetail.spotDesc"></label>
      <span class="space_description" [innerHTML]="spaceDescription"></span>
    </div>
    <!-- Property Details-->
    <div *ngIf="showMoreInfo" class="section-separator">
      <label class="details_title"> {{ 'spaceDetail.propertyDetails' | translate }}</label>

      <!-- Details Table -->
      <table class="table table-borderless details_table">
        <thead>
          <tr class="key_facts">
            <th scope="col" colspan="4"><label translate="spaceDetail.keyFacts"></label></th>
          </tr>
        </thead>
        <tbody class="details_body_table">
          <tr *ngFor="let detailRow of detailsTableRows">
            <td>
              <label class="property_key" [translate]="detailRow.col1.translate"></label>
            </td>
            <td>
              <label class="property_value">{{ detailRow.col1.value }}</label>
            </td>
            <td *ngIf="detailRow.col2" [ngClass]="{ 'price-bg-color': detailRow.col2.priceClass }">
              <label class="property_key " [translate]="detailRow.col2.translate"></label>
            </td>
            <td *ngIf="detailRow.col2" [ngClass]="{ 'price-bg-color': detailRow.col2.priceClass }">
              <label class="property_value ">{{ detailRow.col2.value }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--Media Kit-->
    <div *ngIf="showMediaKit" class="media-kit-container">
      <img *ngIf="isForIndustrial" class="media-kit-img" src="../../../assets/Industrial Building.H03.2k-lr3.png" />
      <img *ngIf="isForOffice" class="media-kit-img" src="../../../assets/Building.H03.2k-lr2.png" />
      <img *ngIf="isForCoworking" class="media-kit-img" src="../../../assets/Building.H031.png" />
    </div>

    <div *ngIf="showMoreInfo || showSpaceImgs" class="request-information section-separator">
      <ion-button (click)="requestInfoAction($event)" class="full-width request-info-btn btn-color-2">
        <span>{{ 'spaceDetail.requestInfo' | translate }}</span>
      </ion-button>
    </div>

    <div *ngIf="showMediaKit && !space.vrTourUrl" class="media-kit-actions section-separator">
      <button
        style="margin-bottom: 3px !important; width: 49% !important;"
        (click)="requestTourAction($event)"
        class="tour-btn btn-white"
      >
        <span>{{ 'spaceDetail.requestVisit' | translate }}</span>
      </button>
      <!-- <button disabled="true" style="margin-bottom: 3px !important;" class="tour-btn btn-white">
        <span>Virtual TOUR</span>
      </button> -->
      <ion-button
        (click)="requestInfoAction($event)"
        class="request-info-btn btn-color-2"
        style="width: 49% !important;"
      >
        <span>{{ 'spaceDetail.requestInfo' | translate }}</span>
      </ion-button>
    </div>

    <div *ngIf="showMediaKit && space.vrTourUrl" class="media-kit-actions section-separator">
      <button style="margin-bottom: 3px !important;" (click)="requestTourAction($event)" class="tour-btn btn-white">
        <span>{{ 'spaceDetail.requestVisit' | translate }}</span>
      </button>
      <button style="margin-bottom: 3px !important;" class="tour-btn btn-white" (click)="openVideoTour($event)">
        <span>{{ 'spaceDetail.virtualTOUR' | translate }}</span>
      </button>
      <ion-button (click)="requestInfoAction($event)" class="request-info-btn btn-color-2">
        <span>{{ 'spaceDetail.requestInfo' | translate }}</span>
      </ion-button>
    </div>
  </div>
</ion-content>
