import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from '@app/core/auth';
import { I18nService } from '@app/core';
var CanDeactivateGuard = /** @class */ (function () {
    function CanDeactivateGuard(alertCtrl, router, auth, i18nService) {
        var _this = this;
        this.alertCtrl = alertCtrl;
        this.router = router;
        this.auth = auth;
        this.i18nService = i18nService;
        this.validate = true;
        this.activeSession = true;
        this.$isLoggedIn = this.auth.$isLoginSubject;
        this.$isLoggedIn.subscribe(function (val) {
            _this.activeSession = val;
        });
    }
    CanDeactivateGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.nextState = nextState;
                        this.currentComponent = component;
                        if (!(this.activeSession && component && !component.canDeactivate() && this.validate)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.discardConfirmMessage()];
                    case 1:
                        _a.sent();
                        this.validate = true;
                        return [3 /*break*/, 3];
                    case 2: return [2 /*return*/, true];
                    case 3: return [2 /*return*/, false];
                }
            });
        });
    };
    CanDeactivateGuard.prototype.discardConfirmMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var unsavedChangeHeaderLabel, unsavedChangeTxt, cancelTxt, discardTxt, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        unsavedChangeHeaderLabel = this.i18nService.get('global.unsavedChangeHeaderLabel');
                        unsavedChangeTxt = this.i18nService.get('global.unsavedChangeBodyText');
                        cancelTxt = this.i18nService.get('global.unsavedCancel');
                        discardTxt = this.i18nService.get('global.unsavedDiscardChanges');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: unsavedChangeHeaderLabel,
                                subHeader: unsavedChangeTxt,
                                cssClass: 'ion-alert-listings',
                                buttons: [
                                    {
                                        text: cancelTxt,
                                        role: 'Cancel',
                                        handler: function () {
                                            //console.log('Cancelar')
                                        }
                                    },
                                    {
                                        text: discardTxt,
                                        handler: function () {
                                            _this.router.navigateByUrl(_this.i18nService.getHomeSPOTURL());
                                            _this.validate = false;
                                        }
                                    }
                                ]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            //this.router.navigateByUrl('/user-home/my-listings');
                            _this.validate = true;
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return CanDeactivateGuard;
}());
export { CanDeactivateGuard };
