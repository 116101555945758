import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from '../../spot-buildings/services/base-service';
import { cloneDeep } from 'lodash';
import { SpotBuildingsUtilService } from '../../spot-buildings/util/spot-buildings-util.service';
var HomeSearchService = /** @class */ (function (_super) {
    tslib_1.__extends(HomeSearchService, _super);
    function HomeSearchService(http, UtilSvc) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.UtilSvc = UtilSvc;
        _this.searchData = {};
        _this.emptyData = {
            state: [],
            city: [],
            neighborhood: [],
            market: []
        };
        _this.getHomeSearchApi = function (propTypeId, spotBuildingType, lang) {
            return "api/buildinglisting/location/names/" + propTypeId + "/" + spotBuildingType + "/" + lang;
        };
        return _this;
    }
    HomeSearchService.prototype.getData = function (searchParams, cacheKey, fromServer) {
        var _this = this;
        if (fromServer === void 0) { fromServer = false; }
        if (!this.searchData[cacheKey] || fromServer) {
            var propTypeId = searchParams.propTypeId, spotBuildingType = searchParams.spotBuildingType, lang = searchParams.lang;
            return this.http
                .get(this.getHomeSearchApi(propTypeId, spotBuildingType, lang))
                .pipe(map(function (response) {
                if (response) {
                    var formattedData = _this.parseData(response);
                    _this.searchData[cacheKey] = formattedData;
                    return formattedData;
                }
                return cloneDeep(_this.emptyData);
            }))
                .toPromise();
        }
        return new Promise(function (res) { return res(_this.searchData[cacheKey]); });
    };
    HomeSearchService.prototype.parseData = function (data) {
        //console.log('Data: ', data);
        return data.reduce(function (acc, item) {
            var _a;
            if (acc.market == undefined) {
                acc.market = [];
            }
            var parsedItem = tslib_1.__assign({}, item, { type: item.type.toLowerCase() });
            acc = tslib_1.__assign({}, acc, (_a = {}, _a[parsedItem.type] = parsedItem.name.includes('Rio de Janeiro') || parsedItem.name.includes('São Paulo')
                ? [parsedItem].concat(acc[parsedItem.type])
                : acc[parsedItem.type].concat(parsedItem), _a));
            return acc;
        }, cloneDeep(this.emptyData));
    };
    HomeSearchService.prototype.getFirstItems = function (data, tab, keyword, limit) {
        if (keyword === void 0) { keyword = ''; }
        if (limit === void 0) { limit = 20; }
        var result = cloneDeep(this.emptyData);
        if (!keyword)
            return result;
        if (tab !== 'all') {
            result[tab] = this.filterByType(data[tab], keyword, limit);
            return result;
        }
        var itemCounter = 0;
        var propertyTypes = Object.keys(data);
        for (var i = 0; i < propertyTypes.length; i++) {
            var itemsOnType = data[propertyTypes[i]];
            var filtered = this.filterByType(itemsOnType, keyword, limit - itemCounter);
            result[propertyTypes[i]] = filtered;
            itemCounter += filtered.length;
        }
        return result;
    };
    HomeSearchService.prototype.filterByType = function (items, keyword, max) {
        var itemCounter = 0;
        var result = [];
        for (var i = 0; i < items.length; i++) {
            if (itemCounter >= max)
                continue;
            var normalized = this.UtilSvc.normalize(items[i].name);
            var pattern = this.UtilSvc.scapeRegexCharacters(keyword);
            var reg = new RegExp(pattern, 'gi');
            if (normalized.match(reg)) {
                result = result.concat(items[i]);
                itemCounter++;
            }
        }
        return result;
    };
    return HomeSearchService;
}(BaseService));
export { HomeSearchService };
