import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {
  public removeHTMLCodeFrom(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }
}
