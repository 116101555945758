import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { I18nService } from '@app/core';
var PurchaseCreditsService = /** @class */ (function () {
    function PurchaseCreditsService(http, i18NService) {
        this.http = http;
        this.i18NService = i18NService;
        this.getCredits = "api/listings/purchases";
        this.getBuildingTypes = "api/buildings/buildingNames?buildingTypeId=";
        this.getBuildingNamesByProperty = "api/buildings/propertyType/names?buildingTypeId=";
        this.adminUpdate = '/api/listings/adminUpdate';
        this.listingUpdate = '/api/listings/';
    }
    PurchaseCreditsService.prototype.getCurrentCredits = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("api/listings/user/" + id + "/purchases", { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.getUserPayments = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("api/listings/user/" + id + "/payments", { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.getUserListingPaymentActivity = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("api/listings/user/" + id + "/listingActivity", { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.getBuildingType = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("" + this.getBuildingTypes + id, { observe: 'response' })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.getBuildingNamesByPropertyType = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .get("" + this.getBuildingNamesByProperty + id + "&lang=" + this.i18NService.getCurrentLanguage(), {
                        observe: 'response'
                    })
                        .pipe(map(function (val) { return val.body; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.renewListing = function (listingPurchaseId, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("api/listings/" + listingId + "/spot-listing-renew?listingPurchaseId=" + listingPurchaseId, {
                        observe: 'response'
                    })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.renewDeactivated = function (listingPurchaseId, listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("api/listings/" + listingId + "/spot-listing-renew?listingPurchaseId=" + listingPurchaseId + "&deactivated=true", {
                        observe: 'response'
                    })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.adminListingUpdate = function (listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.adminUpdate, listing)
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.applyAction = function (id, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.listingUpdate + id + "/" + action, { observe: 'response' })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.approveListing = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.listingUpdate + id + "/confirm-payment", { observe: 'response' })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.reactivateListing = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.listingUpdate + id + "/reactivate", { observe: 'response' })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.deactivateListing = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.listingUpdate + id + "/deactivate", { observe: 'response' })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    PurchaseCreditsService.prototype.spotDeactivate = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http
                        .post("" + this.listingUpdate + id + "/spot-deactivate", { observe: 'response' })
                        .pipe(map(function (val) { return val; }))
                        .toPromise()];
            });
        });
    };
    return PurchaseCreditsService;
}());
export { PurchaseCreditsService };
