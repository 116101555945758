import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { FavoritesComponent } from './favorites.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
var ɵ0 = { title: extract('dashboard'), shouldReuse: false }, ɵ1 = { title: extract('dashboard'), shouldReuse: false }, ɵ2 = { title: extract('dashboard'), shouldReuse: false }, ɵ3 = { title: extract('dashboard'), shouldReuse: false };
var routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    Shell.childRoutesRunningGuardsResolversAlways([
        {
            path: "" + AppRouteNames.MY_SPOTS_ROUTE,
            component: FavoritesComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ0
        },
        {
            path: AppRouteNames.MY_SPOTS_ROUTE + "/:language",
            component: FavoritesComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ1
        },
        {
            path: "" + AppRouteNames.MY_SPOTS_ROUTE_CL,
            component: FavoritesComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ2
        },
        {
            path: AppRouteNames.MY_SPOTS_ROUTE_CL + "/:language",
            component: FavoritesComponent,
            canDeactivate: [BackwardGuard],
            data: ɵ3
        }
    ])
];
var FavoritesRoutingModule = /** @class */ (function () {
    function FavoritesRoutingModule() {
    }
    return FavoritesRoutingModule;
}());
export { FavoritesRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
