import { Component, OnInit, Input } from '@angular/core';
import { NavParams, AlertController } from '@ionic/angular';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { I18nService } from '@app/core';
import { ListingAgentEmailRequest } from '@app/models/email-requests.model';
import { UserActivityType, UserActivity } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { EmailService } from '@app/core/mail/email.service';
import { AccountService } from '@app/core/auth/account.service';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';

@Component({
  selector: 'app-agent-notification',
  templateUrl: './agent-notification.component.html',
  styleUrls: ['./agent-notification.component.scss']
})
export class AgentNotificationComponent implements OnInit {
  formData: FormGroup;
  noteFormCtrl: any;

  popupCtrl: any;
  defaultMsg: string;
  brokerId: number;
  listingId: number;
  pageURLFrom: string;

  constructor(
    private navParams: NavParams,
    private userActivityService: UserActivityService,
    private emailService: EmailService,
    private alertCtrl: AlertController,
    private i18nService: I18nService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getPopData();
  }

  createForm() {
    this.formData = new FormGroup({
      name: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
      note: new FormControl('', Validators.required)
    });

    this.noteFormCtrl = this.formData.controls['note'];
  }

  getPopData() {
    if (this.navParams.data.propType === '') {
      this.defaultMsg = null;
    } else {
      this.defaultMsg = this.navParams.data.defaultMsg;
      this.noteFormCtrl.setValue(this.defaultMsg);
      this.listingId = this.navParams.data.listingId;
      this.brokerId = this.navParams.data.brokerId;
      this.popupCtrl = this.navParams.data.popupCtrl;
      this.pageURLFrom = this.navParams.data.pageURLFrom;
    }
    const currentUser = this.accountService.getCurrentUser;

    if (currentUser != null) {
      this.formData.controls['name'].setValue(`${currentUser.firstName} ${currentUser.lastName}`);
      this.formData.controls['email'].setValue(`${currentUser.email}`);
      const phones: Array<any> = currentUser.phones;
      if (phones != null && phones.length > 0) {
        let phone = phones.find(phone => phone.phoneType === 'PHONE_NUMBER');
        if (phone != null && phone.phoneNumber.length > 0) {
          this.formData.controls['phone'].setValue(phone.phoneNumber);
        } else {
          phone = phones.find(phone => phone.phoneType === 'WHATSAPP');
          if (phone != null) {
            this.formData.controls['phone'].setValue(phone.phoneNumber);
          }
        }
      }
    }
  }

  sendMessage() {
    if (this.formData.invalid) {
      this.showAlert('Please enter the required fields');
      return;
    } else {
      // send contact email
      this.registerUserActivity(UserActivityType.BROKER_SENDMESSAGE);
      this.sendEmailNotification();
    }
  }

  registerUserActivity(userActivityType: UserActivityType) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: this.brokerId,
      listingId: this.listingId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }

  sendEmailNotification() {
    const listingAgentEmailRequest: ListingAgentEmailRequest = {
      name: this.formData.value.name,
      phone: this.formData.value.phone,
      email: this.formData.value.email,
      message: this.noteFormCtrl.value,
      listingId: this.listingId,
      listingAgentId: this.brokerId,
      pageURLFrom: this.pageURLFrom
    };
    const wasEmailSent = this.emailService.sendListingMessage(listingAgentEmailRequest);
    const alertMsg = wasEmailSent
      ? this.i18nService.get('global.form.sendMsgToAgent.emailSuccessMessage')
      : this.i18nService.get('global.form.sendMsgToAgent.emailFailureMessage');
    this.showAlert(alertMsg);
  }

  async showAlert(alertMsg: string) {
    const alert = await this.alertCtrl
      .create({
        header: this.i18nService.get('global.form.sendMsgToAgent.notification'),
        subHeader: alertMsg,
        buttons: [
          {
            text: this.i18nService.get('global.form.sendMsgToAgent.ok'),
            handler: () => {
              if (this.formData.valid) {
                this.popupCtrl.dismiss();
              }
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
  }

  rowsFromTextArea() {
    if (this.noteFormCtrl.invalid && this.formData.controls['email'].invalid) {
      return 4;
    } else if (this.noteFormCtrl.invalid || this.formData.controls['email'].invalid) {
      return 6;
    }
    return 7;
  }
}
