import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { I18nService, ImageService, PlatformHelperService, PropertyTypeHelperService } from '@app/core';
import { ListingDetailService } from '@app/core/listings';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';
import { IonSlides } from '@ionic/angular';
import { faChevronRight, faChevronLeft, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { ServiceProvider } from '@app/models/service-provider';
import {
  SpacesParameters,
  SpaceCondition,
  SpaceFilterCondition,
  SpaceConditionsTypes
} from '@app/models/spot-buildings/spot-available-spaces';
import { CommonGenericService } from '@app/core/common.service';
import { SpaceRangesDTO } from '@app/models/spot-buildings/spot-buildings-types';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { parseInt } from 'lodash';
import { environment } from '@env/environment';
import { SpotImageDTO } from '@app/models/spot-buildings/spot-image.model';
import { SpotBuildingSpaceService } from '../../services/spaces.service';
import { SpacesFilterDTO, IndustrialLeaseType } from '../../../models/spot-buildings/spot-available-spaces';
import { SpacePhotoDTO } from '../../../models/spot-buildings/space-photo.model';
import { RequestInfoInput, RequestInformationType } from '../../../models/request-information.model';
import { AgentDTO } from '../../../models/spot-buildings/agent-broker.model';
import { BrokersDTO } from '../../../models/bokers.model';
import { LocalNumberPipe } from '@app/shared/pipes/number-pipe';
import { RoutingHelperService } from '../../../core/helpers/routing-helper.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '@app/core/auth';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { KoworkingTypes } from '@app/models/koworking-types.model';
import { SpotBuildingSearchRequestType } from '@app/models/spot-buildings/spot-building-search.model';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
import { SizeIcon } from '../../../models/shared/shared-enums.model';
import { MetaService } from '@app/core/seo';
import { SocialMediaInput } from '@app/models/social-media.model';
import { HomePageAppService } from '../../../shared/services/homepageapp.service';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';

@Component({
  selector: 'buildings-detail-promoted',
  templateUrl: './buildings-detail-promoted.component.html',
  styleUrls: ['./buildings-detail-promoted.component.scss']
})
export class BuildingsDetailPromotedComponent implements OnInit {
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;
  buildingDetail: SpotBuildingDTO;
  sbSliderFilters: SpotBuildingSearchRequestType;
  dialogRef: any;
  isMobile: boolean = false;
  sticky: boolean = false;
  maxSticky: boolean = false;
  public mapOptions: any = {};
  public markers: any[] = [];
  private ORANGE_PIN: string = this.nearbyListingSvc.orangePin;
  faChevronRight: any;
  faChevronLeft: any;
  services: ServiceProvider[] = [];
  spacesParameters: SpacesParameters[] = [];
  originalSpaces: SpacesParameters[] = [];
  showShortDesciption = true;
  position1 = 0;
  position2 = 0;
  positionTop = 0;
  @Input() spotBuildingInput: SpotBuildingDTO;
  cont = 0;
  features = 0;
  hiddeMoreFeatures = false;

  topPosition = 0;
  floatingDivHeight = 0;
  p3 = 0;

  investmentOpportunity: string;
  capRate: string;
  percentageLeased: string;
  majorTenants: string[];
  spacesFilter: SpacesFilterDTO;
  description: string;
  SpotPhotos: SpotImageDTO[] = [];
  currentCoworkingTypeFilter: CoworkingSpaceType;
  user: any;
  isAdmin = false;

  showShortSpace = true;
  hiddeMoreSpaces = false;
  defaultImage: string;

  contactEmail: boolean = false;
  whatsappPhone: boolean = false;
  contactEmail2: boolean = false;
  whatsappPhone2: boolean = false;
  isLoggedIn: boolean;
  loginSubscription: Subscription;
  countSubscribe: number;
  currentDevice: string;

  filter: number = 0;
  spacesCoworking: SpacesParameters[] = [];
  coworkingTypes: KoworkingTypes = new KoworkingTypes();
  filter1: boolean = false;
  filter2: boolean = false;
  filter3: boolean = false;
  filter4: boolean = false;

  seeSuit: boolean = true;
  seePositions: boolean = true;
  favSizeIcon: SizeIcon = SizeIcon.LG;
  spaceOffMarket = [1, 2, 3, 4, 5, 6];
  sharedIcon: any;
  vacantAreaList: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private createSpotBuildingsService: CreateSpotBuildingsService,
    private imgService: ImageService,
    private i18NService: I18nService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private nearbyListingSvc: NearbyListingSvc,
    private spotBuildingsService: SpotBuildingsService,
    public propertyHelperService: PropertyTypeHelperService,
    private commonService: CommonGenericService,
    private popOverCtrlHelper: PopoverCtrlHelper,
    private router: Router,
    private spacesService: SpotBuildingSpaceService,
    private _decimalPipe: LocalNumberPipe,
    private platformConfigHelper: PlatformHelperService,
    public routingHelperSrv: RoutingHelperService,
    private auth: AuthenticationService,
    private metaService: MetaService,
    private userActivityService: UserActivityService,
    private analyticsService: AnalyticsService,
    private homepageAppSrv: HomePageAppService
  ) {
    this.faChevronRight = faChevronRight;
    this.faChevronLeft = faChevronLeft;
  }

  ngOnInit(): void {
    this.sharedIcon = faShareAlt;
    this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
    this.mapOptions = {
      singleMarker: false,
      ignoreZoom: false
    };

    this.getParamsUrl();
    this.getSpacesFilter();
    window.addEventListener('scroll', this.scrollEvent, true);

    this.isAdmin = this.checkSpotAdmin();
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
  }

  ngAfterViewInit(): void {
    // Wait until the view is initialized before checking the query param
    this.activatedRoute.queryParams.subscribe(params => {
      const scrollInto = params['scrollIntoVac'];
      if (scrollInto) {
        this.scrollToDiv();
      }
    });
  }

  private getSpacesFilter() {
    const navigation = this.router.getCurrentNavigation();

    if (navigation && navigation.extras) {
      if (navigation.extras.state) {
        const localStorageKey = navigation.extras.state.localStorageKey;
        const state = navigation.extras.state;
      }
    } else {
      this.spacesFilter = this.spacesService.getSpacesFilter();
      this.spacesService.saveSpacesFilter(undefined);
    }
    this.currentCoworkingTypeFilter = this.spacesFilter ? this.spacesFilter.coworkingType : null;
  }

  private generateNearByMarker() {
    const currentListingMarker: google.maps.Marker = this.nearbyListingSvc.createBasicMarker(
      this.buildingDetail.building,
      this.ORANGE_PIN
    );

    this.markers = [currentListingMarker];
  }

  scrollEvent = (event: any): void => {
    if (!this.isMobile) {
      if (this.cont == 0) {
        this.cont++;
        this.position1 = document.getElementById('position1').getBoundingClientRect().top - 155;
      }

      if (event.srcElement.scrollTop > this.position1) {
        if (
          event.srcElement.scrollTop > this.position1 &&
          document.getElementById('position2').getBoundingClientRect().top >
            document.getElementById('position3').getBoundingClientRect().height + 190
        ) {
          this.sticky = true;
          this.maxSticky = false;
        } else {
          if (
            document.getElementById('position2').getBoundingClientRect().top <=
            document.getElementById('position3').getBoundingClientRect().height + 190
          ) {
            this.sticky = false;
            this.maxSticky = true;
          }
        }
      } else {
        this.sticky = false;
        this.maxSticky = false;
      }
    }
  };

  cssSticky() {
    return {
      position: 'fixed',
      top: '195px',
      overflow: 'hidden',
      'z-index': '10'
    };
  }

  cssMaxSticky() {
    return {
      position: 'absolute',
      bottom: '-10px'
    };
  }

  cssImgServiceMobile() {
    return {
      width: window.screen.width - 80 + 'px',
      height: ((window.screen.width - 80) / 4) * 3 + 'px'
    };
  }

  private buildTitleMetatag(listing: any) {
    let metaTagTitle = '';

    // Listing Type
    const listingTypeKey =
      listing.type === 'L'
        ? 'global.list-your-property.lease'
        : listing.type === 'S'
        ? 'global.list-your-property.sale'
        : listing.type === 'C'
        ? 'global.list-your-property.coworking'
        : '';
    const listingTypeTag = this.commonService.capitalizeFirstLetter(this.i18NService.get(listingTypeKey).toLowerCase());

    // Property Type
    let propertyTypeTag = '';
    const propertyTypeKey = this.propertyHelperService.getPropertyTypeTranslationName(listing.building.buildingType.id);
    propertyTypeTag = this.i18NService.get(propertyTypeKey);

    const propertyTypeTitleTag = propertyTypeTag;

    // Building Name
    const buildingName = this.i18NService.getTranslation(listing.propertyName);

    metaTagTitle = `${buildingName} | ${listingTypeTag} | ${propertyTypeTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(buildingName);
    keywordsMetaTags.push(listingTypeTag);
    keywordsMetaTags.push(propertyTypeTag);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  registerUserActivity(userActivityType: UserActivityType, brokerId: number, listingId: number) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: brokerId,
      buildingListingId: listingId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }

  private async getParamsUrl() {
    try {
      this.buildingDetail = this.spotBuildingInput;
      if (this.buildingDetail.building.status != 'Active') {
        // redirect to SPOT home page
        window.location.href = '/en';
      }
      this.buildingDetail.propertyName = this.i18NService.getTranslation(this.buildingDetail.propertyName);
      this.buildingDetail.address = this.i18NService.getTranslation(this.buildingDetail.address);
      this.buildingDetail.building.address = this.i18NService.getTranslation(this.buildingDetail.building.address);
      this.registerUserActivity(UserActivityType.PAGEVIEW_LISTING, null, this.buildingDetail.id);
      this.vacantAreaList = await this.spotBuildingsService.getBuildingRentRolls(this.buildingDetail.building.id);
      this.spotBuildingsService.goToSpotDetailsURL(this.buildingDetail.id);

      if (window.screen.width < 1000) {
        this.isMobile = true;
      }
      this.sbSliderFilters = this.getSpotBuildingSliderFilters(this.buildingDetail);
      if (this.buildingDetail.comments) {
        this.description = this.i18NService.getTranslation(this.buildingDetail.comments);
      }

      this.buildingDetail.building.buildingType.name =
        this.buildingDetail.building.buildingType.id == 1001
          ? this.propertyHelperService.getPropertyTypeTranslationName(1001)
          : this.i18NService.getTranslation(this.buildingDetail.building.buildingType.name);

      if (this.buildingDetail.building.buildingSubType) {
        this.buildingDetail.building.buildingSubType.name = this.i18NService.getTranslation(
          this.buildingDetail.building.buildingSubType.name
        );
      }

      if (this.buildingDetail.industrialStatus) {
        this.buildingDetail.industrialStatus.name = this.i18NService.getTranslation(
          this.buildingDetail.industrialStatus.name
        );
      }
      this.generateNearByMarker();
      this.countFeatures();

      if (this.buildingDetail && this.buildingDetail.entireSale) {
        if (this.buildingDetail.investmentOpportunity) {
          this.investmentOpportunity = this.i18NService.getTranslation(this.buildingDetail.investmentOpportunity);
        }

        this.capRate = this.buildingDetail.capRate
          ? this.commonService.formatNumberTo(this.buildingDetail.capRate, 2)
          : '0.00';
        this.percentageLeased = this.buildingDetail.percentageLeased
          ? this.commonService.formatNumberTo(this.buildingDetail.percentageLeased)
          : '0.00';
        if (this.buildingDetail.majorTenants && this.buildingDetail.majorTenants.length > 0) {
          this.majorTenants = this.buildingDetail.majorTenants.split(',');
          if (this.majorTenants.length > 3) {
            this.majorTenants = this.majorTenants.slice(0, 3);
          }
        }
      }

      if (this.buildingDetail.buildingListingPhotos) {
        this.SpotPhotos = this.buildingDetail.buildingListingPhotos.map(blp => {
          const photo: SpotImageDTO = {
            id: blp.id,
            croppedImageId: blp.croppedImage ? blp.croppedImage.id : null,
            imageId: blp.image ? blp.image.id : null,
            orderId: blp.orderId,
            originalImageId: blp.originalImage ? blp.originalImage.id : null
          };
          return photo;
        });
      }

      //this.metaService.setSpotBuildingMetaTags(this.buildingDetail, this.SpotPhotos);
      //this.setSpotBuildingMetaTags();

      // this.spotBuildingsService.getServiceProvider().then((response: ServiceProvider[]) => {
      //   this.services = response;

      //   this.services.forEach((item: ServiceProvider) => {
      //     item.serviceCategory = this.i18NService.getTranslation(item.serviceCategory);
      //     item.services = this.removeHTMLCode(this.i18NService.getTranslation(item.services));
      //   });
      // });

      this.createSpotBuildingsService
        .getSpaceConditions(this.buildingDetail.id)
        .then((response: SpacesParameters[]) => {
          this.spacesParameters = response;

          if (!this.spacesParameters || this.spacesParameters.length == 0) {
            return;
          } else {
            if (this.spacesParameters.length > 3) {
              this.hiddeMoreSpaces = false;
            } else {
              this.hiddeMoreSpaces = true;
              this.showShortSpace = false;
            }
          }

          this.originalSpaces = [...response];
          let selected: any[] = [];

          const filters: SpaceFilterCondition = {
            totalAreaFilter: (space: SpacesParameters) =>
              this.betweenFilter(space.area, this.spacesFilter.totalAreaIni, this.spacesFilter.totalAreaEnd),
            totalAskingPriceFilter: (space: SpacesParameters) =>
              this.betweenFilter(
                space.askingPrice,
                this.spacesFilter.totalAskingPriceIni,
                this.spacesFilter.totalAskingPriceEnd
              ),
            askingPricePerAreaFilter: (space: SpacesParameters) =>
              this.betweenPerAreaFilter(
                space.askingPrice,
                space.area,
                this.spacesFilter.askingPricePerAreaMin,
                this.spacesFilter.askingPricePerAreaMax
              ),
            totalmonthlyRentFilter: (space: SpacesParameters) =>
              this.betweenFilter(
                this.spacesService.getTotalMonthlyRent(space),
                this.spacesFilter.totalMonthlyRentIni,
                this.spacesFilter.totalMonthlyRentEnd
              ),

            monthlyRentPerAreaFilter: (space: SpacesParameters) =>
              this.betweenPerAreaFilter(
                space.monthlyAskingRent,
                space.area,
                this.spacesFilter.monthlyRentPerAreaMin,
                this.spacesFilter.monthlyRentPerAreaMax
              ),

            includeColdStorageFilter: (space: SpacesParameters) =>
              this.booleanFilter(space.coldStorage, this.spacesFilter.includeColdStorage),
            skyLightsSpaceFilter: (space: SpacesParameters) =>
              this.booleanFilter(space.skyLights, this.spacesFilter.skyLightsSpace),
            subLeaseFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.industrialLeaseType,
                this.spacesFilter.subLease ? IndustrialLeaseType.SUBLEASE : null
              ),
            dataCenterConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.DATA_CENTER
              ),
            forRetailUseConditionIndustrialFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.FOR_RETAIL_USE_I
              ),
            flexConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FLEX),
            manufacturingConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.MANUFACTURING
              ),
            logisticsConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.LOGISTICS),
            preBuildConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.PREBUILD),
            plugAndPlayConditionOfficeFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.PLUG_AND_PLAY_O
              ),
            fullFloorConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FULL_FLOOR),
            forRetailUseConditionOfficeFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.FOR_RETAIL_USE_O
              ),
            coreAndShellConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.CORE_AND_SHEEL
              ),
            furnishedConditionFilter: (space: SpacesParameters) =>
              this.equalsFilter(space.spaceCondition ? space.spaceCondition.id : null, SpaceConditionsTypes.FURNISHED),
            plugAndPlayConditionCoworkingFilter: (space: SpacesParameters) =>
              this.equalsFilter(
                space.spaceCondition ? space.spaceCondition.id : null,
                SpaceConditionsTypes.PLUG_AND_PLAY_C
              ),
            positionsFilter: (space: SpacesParameters) =>
              this.greaterOrEqualFilter(space.positions, this.spacesFilter.positions),
            availabilityDateFilter: (space: SpacesParameters) =>
              this.betweenDatesFilter(space.availabilityDate, null, this.spacesFilter.availabilityDate),
            parkingAvailableFilter: (space: SpacesParameters) =>
              this.booleanFilter(space.parkingAvailable, this.spacesFilter.parkingAvailable),
            privateOutdoorFilter: (space: SpacesParameters) =>
              this.booleanFilter(space.privateOutdoor, this.spacesFilter.privateOutdoorSpace),
            conferenceRoomFilter: (space: SpacesParameters) =>
              this.booleanFilter(space.conferenceRoomAccess, this.spacesFilter.conferenceRoom),
            monthlyCostPerPersonConditionFilter: (space: SpacesParameters) =>
              this.betweenFilter(
                space.monthlyCost,
                this.spacesFilter.monthlyCostPerPersonMin,
                this.spacesFilter.monthlyCostPerPersonMax
              ),
            coworkingTypeFilter: (space: SpacesParameters) =>
              this.equalsFilter(space.coworkingType, this.currentCoworkingTypeFilter)
          };

          if (this.spacesFilter) {
            if (this.spacesFilter.totalAreaIni && this.spacesFilter.totalAreaEnd) {
              selected.push(filters.totalAreaFilter);
            }
            if (this.spacesFilter.totalAskingPriceIni && this.spacesFilter.totalAskingPriceEnd) {
              selected.push(filters.totalAskingPriceFilter);
            }
            if (this.spacesFilter.totalMonthlyRentIni && this.spacesFilter.totalMonthlyRentEnd) {
              selected.push(filters.totalmonthlyRentFilter);
            }
            if (this.spacesFilter.askingPricePerAreaMin && this.spacesFilter.askingPricePerAreaMax) {
              selected.push(filters.askingPricePerAreaFilter);
            }
            if (this.spacesFilter.monthlyRentPerAreaMin && this.spacesFilter.monthlyRentPerAreaMax) {
              selected.push(filters.monthlyRentPerAreaFilter);
            }

            // Coworking
            if (this.spacesFilter.positions) {
              selected.push(filters.positionsFilter);
            }
            if (this.spacesFilter.availabilityDate) {
              selected.push(filters.availabilityDateFilter);
            }
            if (this.spacesFilter.conferenceRoom) {
              selected.push(filters.conferenceRoomFilter);
            }
            if (this.spacesFilter.parkingAvailable) {
              selected.push(filters.parkingAvailableFilter);
            }
            if (this.spacesFilter.privateOutdoorSpace) {
              selected.push(filters.privateOutdoorFilter);
            }
            if (this.spacesFilter.monthlyCostPerPersonMin != null && this.spacesFilter.monthlyCostPerPersonMin > 0) {
              if (this.spacesFilter.monthlyCostPerPersonMax != null && this.spacesFilter.monthlyCostPerPersonMax > 0) {
                selected.push(filters.monthlyCostPerPersonConditionFilter);
              }
            }

            // Flags
            if (this.spacesFilter.includeColdStorage) {
              selected.push(filters.includeColdStorageFilter);
            }
            if (this.spacesFilter.skyLightsSpace) {
              selected.push(filters.skyLightsSpaceFilter);
            }
            if (this.spacesFilter.subLease) {
              selected.push(filters.subLeaseFilter);
            }

            // Conditions
            if (this.spacesFilter.datacenterCondition) {
              selected.push(filters.dataCenterConditionFilter);
            }

            if (this.spacesFilter.forRetailUseConditionIndustrial) {
              selected.push(filters.forRetailUseConditionIndustrialFilter);
            }

            if (this.spacesFilter.flexCondition) {
              selected.push(filters.flexConditionFilter);
            }

            if (this.spacesFilter.manufacturingCondition) {
              selected.push(filters.manufacturingConditionFilter);
            }

            if (this.spacesFilter.logisticsCondition) {
              selected.push(filters.logisticsConditionFilter);
            }

            if (this.spacesFilter.preBuildCondition) {
              selected.push(filters.preBuildConditionFilter);
            }

            if (this.spacesFilter.plugAndPlayConditionOffice) {
              selected.push(filters.plugAndPlayConditionOfficeFilter);
            }

            if (this.spacesFilter.fullFloorCondition) {
              selected.push(filters.fullFloorConditionFilter);
            }

            if (this.spacesFilter.forRetailUseConditionOffice) {
              selected.push(filters.forRetailUseConditionOfficeFilter);
            }

            if (this.spacesFilter.coreAndShellCondition) {
              selected.push(filters.coreAndShellConditionFilter);
            }

            if (this.spacesFilter.furnishedConditionCoworking) {
              selected.push(filters.furnishedConditionFilter);
            }

            if (this.spacesFilter.plugAndPlayConditionCoworking) {
              selected.push(filters.plugAndPlayConditionCoworkingFilter);
            }

            if (this.currentCoworkingTypeFilter) {
              selected.push(filters.coworkingTypeFilter);
            }
          }

          if (selected && selected.length > 0) {
            if (this.isForCoworking()) {
              this.spacesParameters = this.spacesParameters.filter(space => selected.every(f => f(space)));
            } else {
              this.spacesParameters = this.spacesParameters.filter(space => selected.some(f => f(space)));
            }
          }

          if (this.buildingDetail.type == 'C') {
            const filterItem = this.findIndexFromCurrentCoworkingSpaceTypeFilter();
            this.coworkingFilter(filterItem, true);
          }
        });
    } catch (e) {
      console.error('Error : ', e);
    }
  }

  private setSpotBuildingMetaTags() {
    const detailsMetaTags = this.buildTitleMetatag(this.buildingDetail);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = this.buildingDetail.comments
      ? this.removeHTMLCode(this.i18NService.getTranslation(this.buildingDetail.comments))
      : '';
    const locale = this.i18NService.getCurrentLocale();
    const listingTitle = this.i18NService.getTranslation(this.buildingDetail.title);
    const fullDetailsURL = window.location.href;

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [listingTitle] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          this.SpotPhotos && this.SpotPhotos.length
            ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  this.SpotPhotos[0].croppedImageId ? this.SpotPhotos[0].croppedImageId : this.SpotPhotos[0].imageId
                )}`
              )
            : null
      },
      {
        name: 'image',
        content:
          this.SpotPhotos && this.SpotPhotos.length
            ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  this.SpotPhotos[0].croppedImageId ? this.SpotPhotos[0].croppedImageId : this.SpotPhotos[0].imageId
                )}`
              )
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [listingTitle] },
      {
        name: 'twitter:image',
        content:
          this.SpotPhotos && this.SpotPhotos.length
            ? encodeURI(
                `${environment.spotServerUrl}${this.getMediumImg(
                  this.SpotPhotos[0].croppedImageId ? this.SpotPhotos[0].croppedImageId : this.SpotPhotos[0].imageId
                )}`
              )
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.metaService.setDynamicTags(tags, fullTitleText);
  }

  findIndexFromCurrentCoworkingSpaceTypeFilter() {
    const coworkingSpaceTypeFilter = this.currentCoworkingTypeFilter;
    let filterItem = 0;
    if (coworkingSpaceTypeFilter) {
      switch (coworkingSpaceTypeFilter) {
        case CoworkingSpaceType.PRIVATE_OFFICE:
          filterItem = 1;
          break;
        case CoworkingSpaceType.DEDICATED_WORKSPACE:
          filterItem = 2;
          break;
        case CoworkingSpaceType.OPEN_WORKSPACE:
          filterItem = 3;
          break;
        case CoworkingSpaceType.FULL_FLOOR_OFFICES:
          filterItem = 4;
          break;
      }
    }
    return filterItem;
  }

  betweenFilter = (value: number, minValue: number, maxValue: number): boolean => {
    return value >= minValue && value <= maxValue;
  };

  greaterOrEqualFilter = (value: number, maxValue: number): boolean => {
    return value >= maxValue;
  };

  betweenDatesFilter = (value: Date, minValue: Date, maxValue: Date): boolean => {
    if (minValue == null) {
      return value <= maxValue;
    }

    return value >= minValue && value <= maxValue;
  };

  betweenPerAreaFilter = (price: number, area: number, minValue: number, maxValue: number): boolean => {
    return area && area > 0 && price / area >= minValue && price / area <= maxValue;
  };

  equalsFilter = (fieldValue: any, expectedValue: any) =>
    expectedValue && fieldValue ? fieldValue == expectedValue : true;

  booleanFilter = (fieldValue: boolean, expectedValue: boolean) => (expectedValue ? fieldValue == expectedValue : true);

  outSideSearch() {
    this.spacesParameters = this.originalSpaces;
    if (this.isForCoworking()) {
      const coworkingTypeFilterId = this.findIndexFromCurrentCoworkingSpaceTypeFilter();
      this.coworkingFilter(coworkingTypeFilterId, true);
    }
  }

  getAvailableAreaText() {
    return this.commonService.getAvailableAreaText(this.buildingDetail.spaceRangesDTO);
  }

  getAskingRent() {
    return this.commonService.getAskingRent(this.buildingDetail);
  }

  getAskingRentPerArea() {
    return this.commonService.getAskingRentPerArea(this.buildingDetail);
  }

  getAskingPrice() {
    return this.commonService.getAskingPrice(this.buildingDetail);
  }

  getAskingPricePerArea() {
    return this.commonService.getAskingPricePerArea(this.buildingDetail);
  }

  getMonthlyRentPerPerson() {
    return this.commonService.getMonthlyRentPerPerson(this.buildingDetail);
  }

  getPositions() {
    return this.commonService.getPositions(this.buildingDetail.spaceRangesDTO);
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }

  openCard2(): void {
    this.dialogRef = this.dialog.open(PreviewImagesComponent, {
      height: '78%',
      width: '60%',
      data: this.buildingDetail,
      panelClass: 'custom-modalbox'
    });
  }

  openGalleryImg(event: Event): void {
    let photos: SpotImageDTO[] = [];
    if (this.buildingDetail.buildingListingPhotos) {
      photos = this.buildingDetail.buildingListingPhotos.map(blp => {
        const photo: SpotImageDTO = {
          id: blp.id,
          croppedImageId: blp.croppedImage ? blp.croppedImage.id : null,
          imageId: blp.image ? blp.image.id : null,
          orderId: blp.orderId,
          originalImageId: blp.originalImage ? blp.originalImage.id : null
        };
        return photo;
      });
    }
    this.popOverCtrlHelper.openGalleryImageComponent(event, photos, this.buildingDetail.propertyName);
  }

  getMediumImg(id: number) {
    return this.imgService.mediumThumbnail(id);
  }

  getCroppedOrOriginalImage(buildinListingPhoto: any) {
    if (buildinListingPhoto) {
      if (buildinListingPhoto.croppedImage && buildinListingPhoto.croppedImage.id) {
        return buildinListingPhoto.croppedImage.id;
      }
      return buildinListingPhoto.image.id;
    }
  }

  public async getPreviousPhoto() {
    const index = await this.photoSlider.getActiveIndex();

    this.photoSlider.slidePrev();
  }

  public async getNextPhoto() {
    const index = await this.photoSlider.getActiveIndex();

    this.photoSlider.slideNext();
  }

  goBack() {
    this.routingHelperSrv.navigateToSearchPage(true);
  }

  seeMoreAction(event: any, space: SpacesParameters) {
    this.popOverCtrlHelper.openSpacesDetailPopover(event, space, this.buildingDetail);
  }

  alterDescriptionText() {
    this.showShortDesciption = !this.showShortDesciption;
  }

  alterSpaceText() {
    this.showShortSpace = !this.showShortSpace;
  }

  openBuilding() {
    let url = `${environment.serverBaseUrl}building/${this.buildingDetail.building.id}`;
    window.open(url);
  }

  openRequestInfo(pEvent: any, informationType: number) {
    let brokers: AgentDTO[] = [];
    let sentEmailTo: string[] = [];

    if (this.buildingDetail.brokers) {
      this.buildingDetail.brokers.forEach((broker: BrokersDTO) => {
        const agentBroker: AgentDTO = {
          firstNameAndLastName: broker.firstNameAndLastName,
          phoneNumber: broker.phoneNumber,
          photoId: broker.photoId,
          userEmail: broker.userEmail
        };
        brokers.push(agentBroker);
        sentEmailTo.push(broker.userEmail);
      });
    }

    const requestInfoInput: RequestInfoInput = {
      requestTitle: this.buildingDetail.propertyName + ' (' + this.buildingDetail.building.id + ')',
      companyLogoImageId: this.buildingDetail.companyLogoImageId,
      agents: brokers,
      sentEmailTo: sentEmailTo,
      spotId: this.buildingDetail.id,
      requestType: RequestInformationType.REQ_INFO
    };

    this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
  }

  requestTourAction(pEvent: any) {
    let brokers: AgentDTO[] = [];
    let sentEmailTo: string[] = [];

    if (this.buildingDetail.brokers) {
      this.buildingDetail.brokers.forEach((broker: BrokersDTO) => {
        const agentBroker: AgentDTO = {
          firstNameAndLastName: broker.firstNameAndLastName,
          phoneNumber: broker.phoneNumber,
          photoId: broker.photoId,
          userEmail: broker.userEmail
        };
        brokers.push(agentBroker);
        sentEmailTo.push(broker.userEmail);
      });
    }

    const requestInfoInput: RequestInfoInput = {
      requestTitle: this.buildingDetail.propertyName + ' (' + this.buildingDetail.building.id + ')',
      companyLogoImageId: this.buildingDetail.companyLogoImageId,
      agents: brokers,
      sentEmailTo: sentEmailTo,
      spotId: this.buildingDetail.id,
      requestType: RequestInformationType.REQ_TOUR
    };

    this.popOverCtrlHelper.openRequestInformationPopup(pEvent, requestInfoInput);
  }

  openVideoTour(pEvent: any) {
    if (this.buildingDetail.vrTourUrl) {
      this.userActivityService.trackSpotBuildingVirtualTour(this.buildingDetail.id);
      this.popOverCtrlHelper.openVideoTourPopup(
        pEvent,
        this.buildingDetail.vrTourUrl,
        this.buildingDetail.propertyName,
        this.isMobile,
        (error: string) => {
          console.error('Callback from video tour: ', error);
          this.spotBuildingsService.logError(error);
        }
      );
    }
  }

  countFeatures() {
    if (this.buildingDetail.twentyFourHourSecurity) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.bicycleRack) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.fiberOptic) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.blockAndSteelConstruction) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.blockConstruction) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.centralAirConditioning) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.crossDocking) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.electricGenerator) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.heliport) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.multiTenant) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.petFriendly) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.singleTenant) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.sluiceGate) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.sprinklers) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.steelConstruction) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.leedStatus) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.truckYard) {
      this.features = this.features + 1;
    }

    if (this.buildingDetail.mailPackageHandling) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.enhancedCleaning) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.barista) {
      this.features = this.features + 1;
    }
    if (this.buildingDetail.cafeteria) {
      this.features = this.features + 1;
    }

    if (!this.isMobile && this.features <= 6) {
      this.hiddeMoreFeatures = true;
    } else {
      if (this.isMobile && this.features <= 3) {
        this.hiddeMoreFeatures = true;
      } else {
        this.hiddeMoreFeatures = false;
      }
    }
  }

  validateDecimal(value: number, type?: number) {
    if (value == 0 || value == null) {
      return this.i18NService.get('global.list-your-property.negotiable');
    }

    if (type) {
      return type == 1
        ? `${this.buildingDetail.preferredCurrency} ` + this.transform(value)
        : this.transform(value) + `${this.buildingDetail.preferredCurrency}` + '/m²';
    } else {
      return this.transform(value);
    }
  }

  transform(value: number) {
    if (value % 1 == 0) {
      return this._decimalPipe.transform(value, `1.0-0`);
    } else {
      return this._decimalPipe.transform(value, `1.2-2`);
    }
  }

  editBuilding() {
    window.open(
      `/spot-buildings/create/${
        this.buildingDetail.type == 'L' ? 'lease' : this.buildingDetail.type == 'C' ? 'coworking' : 'sale'
      }/${this.buildingDetail.building.id}`,
      '_self'
    );
  }

  checkSpotAdmin() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }

  openCompanyDetails(event: any) {
    this.routingHelperSrv.navigateToCompanyDetailsPage(this.buildingDetail.offeredByCompany.id);
  }

  /*  @HostListener('window:scroll')
   scrollUp() {
     window.scrollTo(0, 0);
   } */

  showContactEmail(notSubscribe: any = null, broker: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }
    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(2);
        return;
      }
    }

    if (broker) {
      this.contactEmail2 = true;
    } else {
      this.contactEmail = true;
    }

    /*  this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL); */
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
  }

  showBrokerWhatsapp(notSubscribe: any = null, broker: any = null) {
    if (!this.isLoggedIn) {
      if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
        this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
        this.countSubscribe = this.countSubscribe + 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      } else {
        this.countSubscribe = 1;
        sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
      }
    }

    if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
      if (!localStorage.getItem('emailSubscribed')) {
        this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
        this.openShowMoreLogInModal(1);
        return;
      }
    }

    if (broker) {
      this.whatsappPhone2 = true;
    } else {
      this.whatsappPhone = true;
    }
    /* this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP); */
    this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
  }

  openShowMoreLogInModal(id: number) {
    const subscribeDialog = this.dialog.open(SubscribeModalComponent, {
      width: '400px',
      panelClass: 'my-custom-dialog-class',
      data: id
    });

    subscribeDialog.afterClosed().subscribe(val => {
      if (val != undefined) {
        switch (val) {
          case 1:
            this.showBrokerWhatsapp(val);
            break;
          case 2:
            this.showContactEmail(val);
            break;
          default:
            return null;
        }
      }
    });
  }

  isForCoworking() {
    return this.buildingDetail.type == 'C';
  }

  coworkingFilter(filter: number, validateFilters: boolean = false) {
    const spaces = JSON.parse(JSON.stringify(this.spacesParameters));
    this.seeSuit = true;
    this.seePositions = true;

    if (validateFilters) {
      this.filter1 =
        this.spacesParameters.filter(val => {
          return val.coworkingType == this.coworkingTypes.privateOffice;
        }).length > 0
          ? true
          : false;

      this.filter2 =
        this.spacesParameters.filter(val => {
          return val.coworkingType == this.coworkingTypes.dedicatedWorkspace;
        }).length > 0
          ? true
          : false;

      this.filter3 =
        this.spacesParameters.filter(val => {
          return val.coworkingType == this.coworkingTypes.openWorkspace;
        }).length > 0
          ? true
          : false;

      this.filter4 =
        this.spacesParameters.filter(val => {
          return val.coworkingType == this.coworkingTypes.fullFloorOffices;
        }).length > 0
          ? true
          : false;
    }

    if (filter == 1 || (filter == 0 && this.filter1)) {
      this.spacesCoworking = spaces.filter((val: any) => {
        return val.coworkingType == this.coworkingTypes.privateOffice;
      });

      this.spacesCoworking = this.spacesCoworking.filter(val => {
        return (val.coworkingType = '{"es": "buildinsDetail.privateOffice", "en":"buildinsDetail.privateOffice"}');
      });
      this.filter = 1;
      return;
    }

    if (filter == 2 || (filter == 0 && this.filter2)) {
      this.spacesCoworking = spaces.filter((val: any) => {
        return val.coworkingType == this.coworkingTypes.dedicatedWorkspace;
      });

      this.spacesCoworking = this.spacesCoworking.filter(val => {
        return (val.coworkingType =
          '{"es": "buildinsDetail.dedicatedWorkspace", "en":"buildinsDetail.dedicatedWorkspace"}');
      });
      this.filter = 2;
      return;
    }

    if (filter == 3 || (filter == 0 && this.filter3)) {
      this.spacesCoworking = spaces.filter((val: any) => {
        return val.coworkingType == this.coworkingTypes.openWorkspace;
      });

      this.spacesCoworking = this.spacesCoworking.filter(val => {
        return (val.coworkingType = '{"es": "buildinsDetail.openWorkspace", "en":"buildinsDetail.openWorkspace"}');
      });
      this.filter = 3;
      this.seeSuit = false;
      return;
    }

    if (filter == 4 || (filter == 0 && this.filter4)) {
      this.spacesCoworking = spaces.filter((val: any) => {
        return val.coworkingType == this.coworkingTypes.fullFloorOffices;
      });

      this.spacesCoworking = this.spacesCoworking.filter(val => {
        return (val.coworkingType =
          '{"es": "buildinsDetail.fullFloorOffices", "en":"buildinsDetail.fullFloorOffices"}');
      });
      this.filter = 4;
      this.seeSuit = false;
      this.seePositions = false;

      return;
    }

    if (this.spacesCoworking.length > 3) {
      this.hiddeMoreSpaces = false;
      this.showShortSpace = true;
    } else {
      this.hiddeMoreSpaces = true;
      this.showShortSpace = false;
    }
  }

  getSpotBuildingSliderFilters(spotBuilding: SpotBuildingDTO): SpotBuildingSearchRequestType {
    let filters: SpotBuildingSearchRequestType = {
      listingClasses: ['FEATURED', 'PREMIUM']
    };
    if (!spotBuilding || !spotBuilding.offeredByCompany) return filters;

    filters.companyId = this.buildingDetail.offeredByCompany.id || null;
    return filters;
  }

  goToSiilaSolutions(link: number) {
    if (link == 1) {
      window.open(this.homepageAppSrv.getMarketAnalyticsURL(), '_blank');
    }
    if (link == 2) {
      window.open(this.homepageAppSrv.getIndicesURL(), '_blank');
    }
    if (link == 3) {
      window.open(this.homepageAppSrv.getFiiDataProURL(), '_blank');
    }
  }

  goToMarketAnalytics() {
    window.open(`${environment.serverUrl}/property/${this.buildingDetail.building.id}/`, '_blank');
  }

  cssToolbar() {
    if (this.isMobile) {
      return {
        position: 'unset'
      };
    } else {
      return {
        position: 'fixed'
      };
    }
  }

  shareCompany(event: any) {
    const url = window.location.href;
    const sharedTitle = this.buildingDetail.propertyName;
    this.userActivityService.trackSpotBuildingShare(this.buildingDetail.id);
    const modalTitle = this.i18NService.get('buildinsDetail.shareProperty');
    const input: SocialMediaInput = { url, sharedTitle, modalTitle };

    this.popOverCtrlHelper.openSocialMediaPopup(event, input);
  }

  getParkingLotType() {
    const lang = this.i18NService.getCurrentLanguage();

    const parkingLotTypeVal = this.buildingDetail.parkingLotType;
    if (parkingLotTypeVal) {
      const key = 'global.parking-lot-type.' + parkingLotTypeVal.toLowerCase();
      return this.i18NService.get(key);
    }

    return '';
  }

  openMarket() {
    window.open(`${environment.serverUrl}/marketanalytics/lang/${this.i18NService.getCurrentLanguage()}`);
  }
  openInvestor() {
    window.open(this.homepageAppSrv.getInstitutionalInvestorsURL());
  }
  openProduct() {
    window.open(`${environment.serverUrl}/products/lang/${this.i18NService.getCurrentLanguage()}`);
  }
  openTenant() {
    window.open(this.homepageAppSrv.getTenantsOccupiersURL());
  }

  openOwners() {
    window.open(this.homepageAppSrv.getOwnersDevelopersURL());
  }

  async scrollToDiv(): Promise<void> {
    // Wait for 3 seconds (3000 milliseconds) before scrolling
    await this.delay(1200);

    const element = document.getElementById('vacantAreaDiv');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // Helper function to introduce a delay
  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
