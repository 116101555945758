import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MyMessageService {
  private unreadCount: number;
  public unreadMsgSubject = new BehaviorSubject<number>(0);

  getUnreadMessages() {
    if (!this.unreadCount) {
      return 0;
    }
    return this.unreadCount;
  }

  setUnreadCount(count: number) {
    this.unreadMsgSubject.next(count);
    this.unreadCount = count;
  }
}
