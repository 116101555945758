import { Component } from '@angular/core';
import { BreadcrumbOptions } from '@app/shared';

@Component({
  selector: 'listing-analytics',
  templateUrl: './listing-analytics.component.html',
  styleUrls: ['./listing-analytics.component.scss']
})
export class ListingAnalyticsComponent {
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
}
