import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { ToastController } from '@ionic/angular';
import { I18nService } from '@app/core';
var PrivacyPolicyDialogComponent = /** @class */ (function () {
    function PrivacyPolicyDialogComponent(ppDialogRef, accountService, auth, toastCtrl, i18nService, data) {
        this.ppDialogRef = ppDialogRef;
        this.accountService = accountService;
        this.auth = auth;
        this.toastCtrl = toastCtrl;
        this.i18nService = i18nService;
        this.data = data;
        this.acceptedPolicy = false;
        this.loading = false;
        this.toastPresent = false;
        this.date = data.date;
        this.acceptPrivacyPolicy = data.acceptPrivacyPolicy;
        this.openPrivacyReview = data.openPrivacyReview;
    }
    PrivacyPolicyDialogComponent.prototype.onDismiss = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ppDialogRef.close();
                        return [4 /*yield*/, this.auth.logout()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PrivacyPolicyDialogComponent.prototype.showPrivacyPolicyErr = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.toastPresent) return [3 /*break*/, 3];
                        this.toastPresent = true;
                        return [4 /*yield*/, this.toastCtrl.create({
                                message: this.i18nService.get('global.privacy-policy.dialog.error'),
                                duration: 3000,
                                animated: true,
                                keyboardClose: true,
                                cssClass: 'toast-error'
                            })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () {
                            _this.toastPresent = false;
                        });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PrivacyPolicyDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        this.loading = true;
        this.acceptPrivacyPolicy().subscribe(function () {
            _this.loading = false;
            var user = tslib_1.__assign({}, _this.accountService.getCurrentUser, { ppAcceptedVersion: _this.date });
            localStorage.setItem('user', JSON.stringify(user));
            _this.accountService.currentUser$.next(user);
            _this.ppDialogRef.close();
        }, function (error) {
            _this.loading = false;
            _this.showPrivacyPolicyErr();
            console.error('There was an error accepting privacy policy: ', error);
        });
    };
    return PrivacyPolicyDialogComponent;
}());
export { PrivacyPolicyDialogComponent };
