import { Component, Input, OnInit } from '@angular/core';
import { InfoModalComponent } from '@app/shared/info-modal/info-modal.component';
import { CommonGenericService } from '@app/core/common.service';
import { MultipleFilesProgressBarComponent } from '@app/shared/multiple-files-progress-bar/multiple-files-progress-bar.component';
import { OverlayEventDetail } from '@ionic/core';
import {
  EditCropImageModalComponent,
  EditImageResponse
} from '@app/shared/edit-crop-image-modal/edit-crop-image-modal.component';
import { ImageUploadService } from '@app/shared/services/image-upload.service';
import { ImageService } from '@app/core';
import { EditCropImageSaveModalComponent } from '@app/shared/edit-crop-image-save-modal/edit-crop-image-save-modal.component';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class imageUploadComponent implements OnInit {
  @Input('imgList') imgList: any;
  @Input('saveImgImmediately') saveImgImmediately?: boolean;
  @Input('uploadingImages') uploadingImages: boolean;
  imageFiles: any;
  imgCount: number;

  constructor(
    private commonService: CommonGenericService,
    private imageUploadService: ImageUploadService,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {}

  fileChangeEvent(event: any, remove: boolean): void {
    const files = event.currentTarget.files;

    if (files.length > 20) {
      const infoModalParams = {
        headerTitle: 'global.uploadImage',
        bodyMessage: 'global.limitImagesSize'
      };
      this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
    } else {
      this.imageFiles = [...files];
      this.openMultipleFilesModal().then((r: any) => {
        this.saveAllFilesToImages();
      });
    }
  }

  private async openInfoModal(headerTitle: string, bodyMessage: string, data?: any, extraData?: any) {
    const modalProps: any = {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'generic-info-modal',
      component: InfoModalComponent,
      componentProps: {
        headerTitle: headerTitle,
        bodyMessage: bodyMessage,
        data: data,
        extraData: extraData
      }
    };
    const infoModal = await this.commonService.createModal(modalProps);
    infoModal.onWillDismiss().then((result: any) => {});
    return infoModal.present();
  }

  async openMultipleFilesModal() {
    const componentProps: any = {
      allImgFiles: this.imageFiles,
      imgList: this.imgList
    };
    const cssClass: string = 'multiple-images-modal';
    const modalProps: any = this.getModalProp(MultipleFilesProgressBarComponent, componentProps, cssClass);
    const editImageModal = await this.commonService.createModal(modalProps);

    editImageModal.onDidDismiss().then((popoverData: OverlayEventDetail<EditImageResponse>) => {
      this.imageFiles = [];
      this.clearFileInput(document.getElementById('inputFile'));
      if (this.imgCount != 0) {
        this.openEditSaveImage();
      }
    });

    return editImageModal.present();
  }

  async openEditSaveImage() {
    const componentProps: any = {
      imgList: this.imgList,
      imgCount: this.imgCount
    };
    const cssClass: string = 'edit-crop-image-modal';
    const modalProps: any = this.getModalProp(EditCropImageSaveModalComponent, componentProps, cssClass);
    const editImageModal = await this.commonService.createModal(modalProps);

    editImageModal.onDidDismiss().then(resp => {
      Object.keys(this.imgList).forEach(i => {
        if (!this.imgList[i].croppedImage.id) {
          this.addCroppedImgtoListingPhoto(this.imgList[i]).then(savedBuildingListingPhoto => {
            this.uploadingImages = false;
            if (savedBuildingListingPhoto) {
              this.imgList[i] = savedBuildingListingPhoto;
            }
          });
        }
      });
    });

    return await editImageModal.present();
  }

  public getModalProp(component: any, componentProps: any, cssClass?: string) {
    return {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: cssClass,
      component: component,
      componentProps: componentProps
    };
  }

  clearFileInput(ctrl: any) {
    try {
      ctrl.value = null;
    } catch (ex) {}
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  private async saveAllFilesToImages() {
    const previousSizeImgList = this.imgList.length;
    this.imgCount = 0;
    Object.keys(this.imageFiles).forEach(i => {
      const file = this.imageFiles[i];
      let mimeType = file.type;
      if (mimeType.match(/image\/*/) === null || mimeType.includes('webp')) {
        let message = 'Only images are supported.';
        this.imageFiles[i].errorMsg = message;
        this.imageFiles[i].uploaded = true;
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          const uploadedImg = {
            img: reader.result,
            content: file.name,
            caption: '',
            photoOrder: parseInt(i) + previousSizeImgList + 1
          };
          this.saveImgToDatabase(uploadedImg).then((buildinglistingPhoto: any) => {
            this.imgList.push(buildinglistingPhoto);
            this.imageFiles[i].uploaded = true;
            this.imgCount++;
            // this.imgList = this.sortListingPhotosByOrderId(this.imgList);
          });
        };
      }
    });
    //this.imgList = this.sortListingPhotosByOrderId(this.imgList);
  }

  sortListingPhotosByOrderId(buildinglistingPhotos: any) {
    if (buildinglistingPhotos == null) {
      return null;
    }
    return (<Array<any>>buildinglistingPhotos).sort((photo1, photo2) => (photo1.orderId > photo2.orderId ? 1 : -1));
  }

  async saveImgToDatabase(imgUploaded: any, buildinglistingPhotoId?: number) {
    let buildinglistingPhoto = await this.uploadPhotoWithoutListing(imgUploaded, buildinglistingPhotoId);
    return buildinglistingPhoto;
  }

  async uploadPhotoWithoutListing(imgToUpload: any, buildinglistingPhotoId: any) {
    const formData = new FormData();
    if (!imgToUpload.caption) {
      imgToUpload.caption = '';
    }
    let imgToFile = await this.commonService.convertImageBase64ToFile(imgToUpload.img, imgToUpload.content);

    let croppedFile = null;
    if (imgToUpload.croppedImage) {
      croppedFile = await this.commonService.convertImageBase64ToFile(imgToUpload.croppedImage, imgToUpload.content);
    }

    formData.append('caption', imgToUpload.caption);
    formData.append('photoOrder', imgToUpload.photoOrder);
    formData.append('file', imgToFile);
    formData.append('content', imgToUpload.content);
    formData.append('croppedFile', croppedFile);
    if (buildinglistingPhotoId) {
      formData.append('buildinglistingPhotoId', buildinglistingPhotoId);
    }

    if (imgToUpload.originalImageId && imgToUpload.originalImageId != null) {
      formData.append('originalImageId', imgToUpload.originalImageId);
    }

    return this.imageUploadService.createImage(formData);
  }

  private isNewImage(imgToEdit: any) {
    return imgToEdit.img && !imgToEdit.image;
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  getImage(img: any) {
    if (this.isNewImage(img)) {
      if (img.croppedImage) {
        return img.croppedImage;
      } else {
        return img.img;
      }
    } else {
      if (img.croppedImage) {
        if (img.croppedImage.id) {
          return this.getMediumImg(img.croppedImage.id);
        } else {
          return img.croppedImage;
        }
      }
      return this.getMediumImg(img.image.id);
    }
  }

  getImageContent(img: any) {
    if (!img) {
      return '';
    }

    return this.isNewImage(img) ? img.content : img.image.content;
  }

  doReorder(ev: any) {
    if (this.imgList.length === 1) {
      ev.detail.complete();
      return;
    }

    if (this.imgList.length <= ev.detail.to) {
      const itemMove = this.imgList.splice(ev.detail.from, 1)[0];
      this.imgList.splice(ev.detail.to, 0, itemMove);
      ev.detail.complete();
      return;
    }
    Object.keys(this.imgList).forEach(key => {
      if (typeof this.imgList[key] === 'undefined') {
        delete this.imgList[key];
      }
    });
    const itemMove = this.imgList.splice(ev.detail.from, 1)[0];
    this.imgList.splice(ev.detail.to, 0, itemMove);

    this.imgList.forEach((listingPhoto: any, index: number) => {
      const newOrderId = index + 1;
      if (listingPhoto.orderId != newOrderId) {
        this.updateOrderOrCaptionFrom(listingPhoto.id, undefined, newOrderId);
        this.imgList[index].orderId = newOrderId;
      }
    });
    ev.detail.complete();
  }

  async updateOrderOrCaptionFrom(buildingListingPhotoId: number, caption?: string, orderId?: number) {
    return this.imageUploadService.updateOrderOrCaptionFrom(buildingListingPhotoId, caption, orderId);
  }

  removeImage(ev: any, idx: number) {
    if (this.saveImgImmediately) {
      this.imageUploadService.deleteBuildingListingPhoto(this.imgList[idx]);
    }
    this.imgList.splice(idx, 1);
  }

  public async openEditImageModal(imgIndex: number) {
    this.uploadingImages = true;
    const imgToEdit = this.imgList[imgIndex];
    let newImageBased64 = null;
    let originalImageId = null;
    let imageUrlToEdit = null;
    let fileName = null;
    const isNewImage = this.isNewImage(imgToEdit);

    if (!isNewImage) {
      const imgId = imgToEdit.image.id;
      if (imgToEdit.originalImage) {
        originalImageId = imgToEdit.originalImage.id;
      }
      fileName = imgToEdit.image.content;
      imageUrlToEdit = `/images/${imgId}/thumbnail/ml`;
      if (imgToEdit.croppedImage) {
        imageUrlToEdit = null;
        if (imgToEdit.croppedImage.id) {
          const croppedImgId = imgToEdit.croppedImage.id;
          imageUrlToEdit = `/images/${croppedImgId}/thumbnail/ml`;
        } else {
          newImageBased64 = imgToEdit.croppedImage;
        }
      }
    } else {
      newImageBased64 = imgToEdit.croppedImage ? imgToEdit.croppedImage : imgToEdit.img;
      fileName = imgToEdit.content;
      if (imgToEdit.originalImageId) {
        originalImageId = imgToEdit.originalImageId;
      }
    }
    const componentProps: any = {
      newImageBased64: newImageBased64,
      originalImageId: originalImageId,
      imageUrlToEdit: imageUrlToEdit,
      showCroppingToggle: true,
      fileName: fileName,
      imgList: this.imgList
    };
    const cssClass: string = 'edit-crop-image-modal';
    const modalProps: any = this.getModalProp(EditCropImageModalComponent, componentProps, cssClass);
    const editImageModal = await this.commonService.createModal(modalProps);

    editImageModal.onDidDismiss().then((popoverData: OverlayEventDetail<EditImageResponse>) => {
      if (popoverData.data) {
        const wasImageCropped = popoverData.data.wasImageCropped;
        const newCroppedImage = popoverData.data.croppedImage;
        const newOriginalImage = popoverData.data.originalImage;
        const wasOriginalImgReplaced = popoverData.data.wasOriginalImgReplaced;
        const wasRestoreToOriginalImage = popoverData.data.wasRestoreToOriginalImage;
        const originalImageId = popoverData.data.originalImageId;
        if (newOriginalImage) {
          const content =
            newOriginalImage.file.name && newOriginalImage.file.name != 'null'
              ? newOriginalImage.file.name
              : 'New Image';
          if (isNewImage) {
            this.imgList[imgIndex].img = newOriginalImage.imgBase64;
            this.imgList[imgIndex].content = content;
            this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
            this.imgList[imgIndex].originalImageId = originalImageId;
          } else {
            const caption = imgToEdit.image.caption;
            const originalImgId = imgToEdit.originalImage ? imgToEdit.originalImage.id : null;
            if (wasOriginalImgReplaced) {
              let imgToUpload = {
                img: newOriginalImage.imgBase64,
                content: content,
                caption: caption,
                croppedImage: newCroppedImage.imgBase64,
                originalImageId: originalImgId,
                photoOrder: this.imgList[imgIndex].orderId
              };
              this.saveImgToDatabase(imgToUpload, this.imgList[imgIndex].id).then(BuildingListingPhoto => {
                this.uploadingImages = false;
                this.imgList[imgIndex] = BuildingListingPhoto;
              });
              //this.imgList[imgIndex]
            } else {
              // Save Cropped Image
              this.imgList[imgIndex].croppedImage = newCroppedImage.imgBase64;
              this.addCroppedImgtoListingPhoto(this.imgList[imgIndex]).then(savedBuildingListingPhoto => {
                this.uploadingImages = false;
                if (savedBuildingListingPhoto) {
                  this.imgList[imgIndex] = savedBuildingListingPhoto;
                }
              });

              if (wasRestoreToOriginalImage && this.imgList[imgIndex].originalImage) {
                this.imgList[imgIndex].image = this.imgList[imgIndex].originalImage;
              }
            }
          }
        }
      }
    });

    return await editImageModal.present();
  }

  public async captionChangeListener(imgIndex: number, event: any) {
    event.stopImmediatePropagation();
    const newCaption = event.target.value;
    if (newCaption && this.imgList[imgIndex].caption != newCaption) {
      this.imgList[imgIndex].caption = newCaption;
      this.updateOrderOrCaptionFrom(this.imgList[imgIndex].id, newCaption);
    }
  }

  async addCroppedImgtoListingPhoto(buildingListingPhoto: any) {
    const croppedImage = buildingListingPhoto.croppedImage;
    if (croppedImage == null) {
      return null;
    }
    if (!croppedImage.id) {
      const croppedFile = await this.commonService.convertImageBase64ToFile(
        croppedImage,
        buildingListingPhoto.image.content
      );
      const caption =
        buildingListingPhoto.image.caption && buildingListingPhoto.image.caption.length > 0
          ? buildingListingPhoto.image.caption
          : buildingListingPhoto.image.content;
      return await this.imageUploadService
        .uploadCroppedImage(croppedFile, `${caption}_cropped`, buildingListingPhoto.id)
        .then((createdImages: any) => {
          return createdImages;
        });
    } else {
      return croppedImage;
    }
  }
}
