import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-industrial-filters',
  templateUrl: './industrial-filters.component.html',
  styleUrls: ['./industrial-filters.component.scss']
})
export class IndustrialFilterComponent implements OnInit {
  @Input('propertyType') propertyType: any;
  @Input('searchDto') searchDto: any;

  constructor() {}

  ngOnInit(): void {}
}
