import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import {
  SpaceDetailsTableField,
  SpacesFilterDTO,
  SpacesParameters
} from '../../models/spot-buildings/spot-available-spaces';

@Injectable()
export class SpotBuildingSpaceService extends BaseService {
  SPACES_FILTER_KEY: string = 'spaces_filter';

  saveSpacesFilter(spacesFilter: SpacesFilterDTO) {
    if (spacesFilter) {
      sessionStorage.setItem(this.SPACES_FILTER_KEY, JSON.stringify(spacesFilter));
      return;
    }
    sessionStorage.removeItem(this.SPACES_FILTER_KEY);
  }

  getSpacesFilter(): SpacesFilterDTO {
    const savedFilter = sessionStorage.getItem(this.SPACES_FILTER_KEY);
    return savedFilter && savedFilter != 'undefined' && savedFilter != 'null' ? JSON.parse(savedFilter) : null;
  }

  getTotalMonthlyRent(space: SpacesParameters, includeCondoIptu?: boolean) {
    if (space.totalMonthlyRent && !includeCondoIptu) {
      return space.totalMonthlyRent;
    }

    let total = 0;
    if (space && includeCondoIptu) {
      total += space.monthlyAskingRent ? space.monthlyAskingRent : 0;
      total += space.monthlyCondo ? space.monthlyCondo : 0;
      total += space.monthlyIptu ? space.monthlyIptu : 0;
    }
    return total;
  }
}
