var DetailPositionService = /** @class */ (function () {
    function DetailPositionService() {
    }
    Object.defineProperty(DetailPositionService.prototype, "getDetailPosition", {
        get: function () {
            return this.getCache() || this.list;
        },
        enumerable: true,
        configurable: true
    });
    DetailPositionService.prototype.setPositionData = function (list) {
        this.list = list;
        this.cache();
    };
    DetailPositionService.prototype.getCache = function () {
        try {
            if (localStorage) {
                var position = localStorage.getItem('detail-position');
                return JSON.parse(position);
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    DetailPositionService.prototype.delCache = function () {
        try {
            if (localStorage)
                localStorage.removeItem('detail-position');
        }
        catch (e) {
            console.error(e);
        }
    };
    DetailPositionService.prototype.cache = function () {
        localStorage.setItem('detail-position', JSON.stringify(this.list));
    };
    return DetailPositionService;
}());
export { DetailPositionService };
