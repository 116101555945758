import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, IonInput, AlertController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
var RemoveCreditsPopoverComponent = /** @class */ (function () {
    function RemoveCreditsPopoverComponent(navParams, popoverCtrl, listingService, alertCtrl, i18nService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.btnFilter = false;
        this.loading = true;
        this.propertySubTypes = [];
        this.popDataCalled = false;
    }
    RemoveCreditsPopoverComponent.prototype.ngOnInit = function () {
        this.getPopData();
        //this.setMinOptions(this.rangeFilters, this.searchDto);
    };
    RemoveCreditsPopoverComponent.prototype.coworkingTypeChanged = function (event) {
        if (this.popDataCalled) {
            this.popDataCalled = false;
            return;
        }
        this.coworkingType = event.detail.value;
    };
    RemoveCreditsPopoverComponent.prototype.getPopData = function () {
        this.popDataCalled = true;
        this.coworkingType = 'shared';
        this.listingPurchaseId = this.navParams.data.listingPurchaseId;
        this.maxCredits = this.navParams.data.availableCredits;
        this.credits = 1;
    };
    RemoveCreditsPopoverComponent.prototype.remove = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paymentProcessedHeader, paymentProcessedSubHeader, paymentProcessedOk, alert_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.credits > this.maxCredits)) return [3 /*break*/, 3];
                        paymentProcessedHeader = this.i18nService.get('global.my-payment.cannotRemoveCredits');
                        paymentProcessedSubHeader = this.i18nService.get('global.my-payment.cannotRemoveCreditsBody');
                        paymentProcessedOk = this.i18nService.get('global.purchase-options.paymentProcessedOK');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: paymentProcessedHeader,
                                subHeader: paymentProcessedSubHeader,
                                buttons: [paymentProcessedOk]
                            })];
                    case 1:
                        alert_1 = _a.sent();
                        return [4 /*yield*/, alert_1.present()];
                    case 2:
                        _a.sent();
                        this.popoverCtrl.dismiss({});
                        return [2 /*return*/];
                    case 3:
                        this.listingService
                            .removeListingCredits(this.listingPurchaseId, this.credits)
                            .toPromise()
                            .then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var paymentProcessedHeader, paymentProcessedSubHeader, paymentProcessedOk, alert;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        paymentProcessedHeader = this.i18nService.get('global.my-payment.creditsRemoved');
                                        paymentProcessedSubHeader = this.i18nService.get('global.my-payment.creditsRemovedBody');
                                        paymentProcessedOk = this.i18nService.get('global.purchase-options.paymentProcessedOK');
                                        return [4 /*yield*/, this.alertCtrl.create({
                                                header: paymentProcessedHeader,
                                                subHeader: paymentProcessedSubHeader,
                                                buttons: [paymentProcessedOk]
                                            })];
                                    case 1:
                                        alert = _a.sent();
                                        return [4 /*yield*/, alert.present()];
                                    case 2:
                                        _a.sent();
                                        this.popoverCtrl.dismiss({});
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    RemoveCreditsPopoverComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    RemoveCreditsPopoverComponent.prototype.validateCredits = function () {
        //if (this.credits > this.maxCredits) {
        //  this.credits = 1;
        //}
    };
    RemoveCreditsPopoverComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    };
    return RemoveCreditsPopoverComponent;
}());
export { RemoveCreditsPopoverComponent };
