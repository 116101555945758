import { Injectable } from '@angular/core';

@Injectable()
export class ListingCleanupService {
  constructor() {}

  initCoworkingFields() {
    let user: any = localStorage.getItem('user');
    let maximumPositions: any = [{ maximumPositions: null }];
    let coworkingInfo: any = [
      {
        id: null,
        listingId: null,
        positionIni: null,
        sharedDailyByPosition: null,
        sharedMonthlyByPosition: null,
        nonSharedDailyByPosition: null,
        nonSharedMonthlyByPosition: null,
        createdByUserId: user.id,
        createdDate: new Date()
      }
    ];
    let obj: any = {
      maximumPositions: maximumPositions,
      coworkingInfo: coworkingInfo
    };
    return obj;
  }

  nullCoworkingFields() {
    let user: any = localStorage.getItem('user');
    let coworkingInfo: any = [
      {
        id: null,
        listingId: null,
        positionIni: null,
        sharedDailyByPosition: null,
        sharedMonthlyByPosition: null,
        nonSharedDailyByPosition: null,
        nonSharedMonthlyByPosition: null,
        createdByUserId: user.id,
        createdDate: new Date()
      }
    ];
    return coworkingInfo;
  }

  multiLangInit() {
    let titleFieldOptions: any = {
      columnLabel: 'global.registerSpot.title',
      element: { input: true },
      formArrayName: 'title',
      required: true
    };

    let descriptionFieldOptions: any = {
      columnLabel: 'global.registerSpot.description',
      element: { textarea: true },
      formArrayName: 'description',
      required: true
    };
    let obj: any = {
      descriptionFieldOptions: descriptionFieldOptions,
      titleFieldOptions: titleFieldOptions
    };
    return obj;
  }

  checkRole(role: any) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  checkCowork(coworkingInfo: any) {
    let setAbove: any;
    let error: any;
    Object.keys(coworkingInfo).forEach((key, idx) => {
      if (coworkingInfo[key].positionIni > 0) {
        setAbove = true;
      } else {
        error = true;
      }
    });
    if (error) {
      return true;
    } else {
      return false;
    }
  }
}
