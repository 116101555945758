import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { SpotBuildingDetailDTO, SpotBuildingDTO } from '../../models/spot-buildings/spot-buildings.model';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '../../core/auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FavoriteSpotService } from '../services/favorite-spot.service';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { SizeIcon } from '../../models/shared/shared-enums.model';
import { FavoriteChange } from '@app/models/shared/shared-interfaces.model';

@Component({
  selector: 'app-favorite-spot',
  templateUrl: './favorite-spot.component.html',
  styleUrls: ['./favorite-spot.component.scss']
})
export class FavoriteSpotComponent implements OnInit {
  @Input('spot') spot: SpotBuildingDTO;
  @Input('size') size?: SizeIcon;
  @Input('includeLabel') includeLabel?: any;
  @Output() favoriteChange = new EventEmitter<FavoriteChange>();

  faHeart: any;
  isLoggedIn: boolean;
  loginSubscription: Subscription;
  customLoginText: Array<string>;
  translateSubscription: Subscription;

  constructor(
    private loginFormDialog: LoginFormDialogService,
    private favSpotService: FavoriteSpotService,
    private router: Router,
    private _ts: TranslateService,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.faHeart = faHeart;
    this.size = this.size ? this.size : SizeIcon.MD;
    this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
    this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res: Array<string>) => {
      this.customLoginText = res;
    });
  }

  isFavoriteListing() {
    return this.spot ? this.spot.favorite : false;
  }

  async setFavoriteLisitng(event: any) {
    event.stopPropagation();
    if (!this.isLoggedIn) {
      this.openLoginDialog()
        .afterClosed()
        .subscribe(() => {
          if (this.isLoggedIn) {
            this.toggleFavorite();
          }
        });
    } else {
      this.toggleFavorite();
    }
  }

  toggleFavorite() {
    this.favSpotService.toggleFavoriteListing(this.spot, this.spot.favorite || false).subscribe(() => {
      this.favoriteChange.emit({ id: this.spot.id, isFavorite: this.spot.favorite });
    });
  }

  openLoginDialog() {
    const currentRoute: string = this.router.url;
    return this.loginFormDialog.openDialog({
      url: currentRoute,
      customText: this.customLoginText
    });
  }

  isMdSize() {
    return this.size == SizeIcon.MD;
  }

  isLgSize() {
    return this.size == SizeIcon.LG;
  }
}
