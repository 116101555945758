import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'privacy-policy-review',
  templateUrl: './privacy-policy-review.component.html',
  styleUrls: ['./privacy-policy-review.component.scss']
})
export class PrivacyPolicyReviewComponent {
  constructor(private ppReviewRef: MatDialogRef<PrivacyPolicyReviewComponent>) {}

  closePpReview() {
    this.ppReviewRef.close();
  }
}
