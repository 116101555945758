<button
  style="min-width: 30px !important; top: -5px; right: -4px;"
  class="btn-profile"
  mat-button
  [matMenuTriggerFor]="notification"
>
  <fa-icon
    [ngClass]="{ 'bell-ring fa-shake': ringBellListener(), bell: !ringBellListener() }"
    class="default-bell"
    [icon]="faBell"
    size="lg"
  ></fa-icon>
</button>

<mat-menu #notification="matMenu" xPosition="after">
  <div [ngStyle]="{ height: notifications?.content.length > 5 ? '400px' : 'auto' }">
    <div class="show-less" [ngStyle]="{ height: notifications?.content.length > 5 ? 'auto' : 'auto' }">
      <div (click)="$event.stopPropagation()" mat-menu-item class="menu-item">
        <div style="display: flex;justify-content: space-around;">
          <div>
            <label for=""
              ><strong>{{ 'notification' | translate }}</strong></label
            >
          </div>
          {{ 'showUnread' | translate }}
          <label class="switch">
            <input type="checkbox" [(ngModel)]="unread" (change)="getUnread()" />
            <span class="slider round"></span>
          </label>
          <div>
            <img src="assets/images/icons/gear.svg" style="cursor: pointer;" alt="" (click)="goToSettings()" />
          </div>
        </div>
      </div>
      <div (click)="$event.stopPropagation()" class="menu-item" *ngFor="let notification of notifications?.content">
        <div class="notification-menu-item-section">
          <div class="dotcontentcontainer" (click)="goToActionLink(notification)">
            <div class="status_cell">
              <fa-icon
                style="font-size: 12px; color: #ff9900;"
                *ngIf="!notification.read"
                class="icon-size"
                [icon]="faCircle"
              ></fa-icon>
            </div>
            <div class="content_cell" style="white-space: initial;margin-top: 5px;">
              <span class="notification-text" [innerHTML]="getContent(notification.content)"></span>
              <span class="current">{{ notificationService.notificationDate(notification.createdDate) }}</span>
            </div>
          </div>

          <div class="menuoptionsdot">
            <button mat-button [matMenuTriggerFor]="hideOptions">
              <fa-icon style="font-size: 14px;" class="arrow-icon " [icon]="faEllipsisH" size="lg"> </fa-icon>
            </button>
            <mat-menu #hideOptions="matMenu" xPosition="before">
              <button mat-menu-item (click)="hideNotification(notification.id)">
                <fa-icon class="arrow-icon hide-icons" [icon]="faEyeSlash" size="lg"> </fa-icon
                >{{ 'hideNotification' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div style="text-align: center;padding-top: 20px">
        <a class="show-a" type="button" (click)="goToNotification()">
          {{ 'buildinsDetail.seeMore' | translate }}
        </a>
      </div>
    </div>
  </div>
</mat-menu>
