/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./siila-chat.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./siila-chat-view.component.ngfactory";
import * as i5 from "./siila-chat-view.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./siila-chat-support.component";
import * as i9 from "./siila-chat-support.service";
import * as i10 from "./siila-chat.service";
import * as i11 from "../core/auth/account.service";
import * as i12 from "ngx-socket-io";
import * as i13 from "../user-home/my-message/my-message.service";
import * as i14 from "./socket-io.service";
import * as i15 from "../core/common.service";
import * as i16 from "ngx-device-detector";
var styles_SiilaChatSupportComponent = [i0.styles];
var RenderType_SiilaChatSupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiilaChatSupportComponent, data: {} });
export { RenderType_SiilaChatSupportComponent as RenderType_SiilaChatSupportComponent };
function View_SiilaChatSupportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "ion-row", [["style", "height:100%;position:relative;"]], null, null, null, i2.View_IonRow_0, i2.RenderType_IonRow)), i1.ɵdid(1, 49152, null, 0, i3.IonRow, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "siila-chat-view", [["style", "width:100%;background:rgba(54, 94, 115)"]], null, null, null, i4.View_SiilaChatViewComponent_0, i4.RenderType_SiilaChatViewComponent)), i1.ɵdid(4, 4833280, null, 0, i5.SiilaChatViewComponent, [], { messages: [0, "messages"], userFullname: [1, "userFullname"], userProfileImage: [2, "userProfileImage"], spotAccountImage: [3, "spotAccountImage"], currentUser: [4, "currentUser"], spotAccountName: [5, "spotAccountName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, 0, 20, "div", [["class", "new-message-input"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 17, "div", [["class", "message-input-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "ion-textarea", [["autofocus", "true"], ["class", "inset-border"], ["spellcheck", "true"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "ionFocus"], [null, "ionBlur"], [null, "ionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13)._handleBlurEvent() !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13)._handleInputEvent($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.messageInput = $event) !== false);
        ad = (pd_2 && ad);
    } if (("ionFocus" === en)) {
        var pd_3 = (_co.scrollOnFocus(i1.ɵnov(_v, 12)) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_IonTextarea_0, i2.RenderType_IonTextarea)), i1.ɵdid(12, 49152, [[1, 4], ["inputMessage", 4]], 0, i3.IonTextarea, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { autofocus: [0, "autofocus"], spellcheck: [1, "spellcheck"] }, null), i1.ɵdid(13, 16384, null, 0, i3.TextValueAccessor, [i1.ElementRef], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextValueAccessor]), i1.ɵdid(15, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(17, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(20, 0, null, null, 5, "ion-button", [["color", "secondary"], ["style", "z-index:999999;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(21, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], disabled: [1, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(23, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "send"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(24, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageData.messages; var currVal_1 = _co.userFullname; var currVal_2 = _co.userProfileImage; var currVal_3 = _co.spotAccountImage; var currVal_4 = _co.currentUser; var currVal_5 = _co.spotAccountFullname; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_13 = "true"; var currVal_14 = "true"; _ck(_v, 12, 0, currVal_13, currVal_14); var currVal_15 = _co.messageInput; _ck(_v, 15, 0, currVal_15); var currVal_16 = "secondary"; var currVal_17 = !_co.messageInput; _ck(_v, 21, 0, currVal_16, currVal_17); var currVal_18 = "light"; var currVal_19 = "send"; _ck(_v, 24, 0, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_6 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 17).ngClassValid; var currVal_11 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_SiilaChatSupportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { inputMessageComponent: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SiilaChatSupportComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SiilaChatSupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-siila-chat", [], null, null, null, View_SiilaChatSupportComponent_0, RenderType_SiilaChatSupportComponent)), i1.ɵdid(1, 4440064, null, 0, i8.SiilaChatSupportComponent, [i9.SiilaChatSupportService, i10.SiilaChatService, i11.AccountService, i12.Socket, i13.MyMessageService, i11.AccountService, i14.SocketIOService, i15.CommonGenericService, i16.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiilaChatSupportComponentNgFactory = i1.ɵccf("app-siila-chat", i8.SiilaChatSupportComponent, View_SiilaChatSupportComponent_Host_0, {}, {}, []);
export { SiilaChatSupportComponentNgFactory as SiilaChatSupportComponentNgFactory };
