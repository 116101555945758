<div
  style="min-height: 150px; max-height: 150px;"
  class="main__container spot-info-window__mobilecontainer grid-two-cols"
>
  <div style="display: flex; flex-flow:column nowrap" class="main__container__image">
    <img (click)="openCard(spot.id, $event, false)" class="windowmobile__img" [src]="getSpotImg(spot)" />
  </div>
  <div class="flex-col-nowrap spot-info-window__datacontainer">
    <div class="flex spot-info-window__title">
      <div class="ellipsis__text">{{ i18n.getTranslation(spot.title) }}</div>
      <div style="margin-right: 20px" class="flex-align-content-end">
        <ion-button
          id="favButton"
          [class]="buildFavBtnClass(spot)"
          (click)="favoriteListener($event, spot)"
          fill="clear"
          style="height: 16px; width: 16px; visibility: visible;"
        >
          <fa-icon class="icon-size" [icon]="faHeart"></fa-icon>
        </ion-button>
      </div>
    </div>
    <div class="ellipsis__text spot-info-window__header">{{ i18n.getTranslation(spot.buildingAddress) }}</div>
    <div>
      <span class="spot-info-window__header">
        {{ getCityState(spot) }}
      </span>
    </div>

    <div class="spot-info-window__topseparator">
      <div class="flex spot-info-window__subheader" style="margin-top: 4px;">
        <span class="nowrap info-title">{{ commonService.getAvailableAreaLabel(spot) }} </span>
        <span class="ellipsis__text padding-left-10">{{ getAvailableAreaText(spot) }}</span>
      </div>

      <div class="flex spot-info-window__subheader">
        <span class="nowrap info-title">{{ commonService.getAskingRentPriceLabel(spot) }} </span>
        <span class="ellipsis__text padding-left-10">{{ getAvgAskingRent(spot) }}</span>
      </div>
    </div>
  </div>
</div>
