import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, AfterViewChecked } from '@angular/core';
import { LoginFormDialogService } from '../login';
import { AccountService } from '@app/core/auth';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PWAService } from '../pwa-service';
import { PlatformHelperService, I18nService, ImageService } from '@app/core';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { InstallPromptDialogService } from '@app/core/install-prompt.service';
import { faUserCircle, faBell, faHeart, faSyncAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AuthenticationService } from '../../core/auth/auth.service';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { ListingDetailService, ListingService } from '@app/core/listings';
import { MatDialog } from '@angular/material';
import { RegisterFormComponent } from '../register';
import { Store } from '@ngrx/store';
import * as CartActions from '@app/store/actions';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { CommonGenericService } from '@app/core/common.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(loginFormDialog, router, pwaService, platformHelper, alertCtrl, promptService, deviceDetector, gaService$, i18NService, auth, accountService, location, messageService, listingDetailService, dialog, userActivityService, imageService, listingService, store, commonService, routingHelperSrv) {
        this.loginFormDialog = loginFormDialog;
        this.router = router;
        this.pwaService = pwaService;
        this.platformHelper = platformHelper;
        this.alertCtrl = alertCtrl;
        this.promptService = promptService;
        this.deviceDetector = deviceDetector;
        this.gaService$ = gaService$;
        this.i18NService = i18NService;
        this.auth = auth;
        this.accountService = accountService;
        this.location = location;
        this.messageService = messageService;
        this.listingDetailService = listingDetailService;
        this.dialog = dialog;
        this.userActivityService = userActivityService;
        this.imageService = imageService;
        this.listingService = listingService;
        this.store = store;
        this.commonService = commonService;
        this.routingHelperSrv = routingHelperSrv;
        this.toggleMenu = new EventEmitter();
        this.appInstalled$ = new Observable();
        this.sideMenuOpen = false;
        this.showBorder = false;
        this.userName = '';
        this.messageCount = 0;
        this.favoritesCount = 0;
        this.loadUpdate = false;
        this.userAccount = {};
        this.screenWidth = window.innerWidth;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customLoginText = this.i18NService.get('global.form.defaultSignInMessage');
        this.isIos = this.platformHelper.isIosChrome() || this.platformHelper.isIosSafari();
        this.appInstalled$ = this.pwaService.appInstalled$;
        this.faUserCircle = faUserCircle;
        this.faBell = faBell;
        this.faHeart = faHeart;
        this.faSyncAlt = faSyncAlt;
        this.downArrow = faChevronDown;
        this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent) ? true : false;
        this.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
        if (this.isDesktop.length) {
            this.currentDevice = this.isDesktop;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
        this.userNameSubscription = this.accountService.currentUser$.subscribe(function (user) {
            if (user) {
                _this.userName = user.firstName + " " + user.lastName;
                _this.getFavoritesAndMessagesCount();
                if (!user.profileImage) {
                    _this.getUserProfile(user);
                }
            }
            else {
                _this.userName = '';
                _this.resetFavoritesAndMessagesCount();
            }
        });
    };
    HeaderComponent.prototype.getUserProfile = function (userAccount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userPhotos, image;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountService.getUserPhotoList().toPromise()];
                    case 1:
                        userPhotos = _a.sent();
                        image = this.accountService.getUploadedImageId(userPhotos);
                        this.userAccount = {
                            profile: userAccount,
                            profileImage: this.getMediumImg(image.profileImageId),
                            originalImage: this.getMediumImg(image.originalImageId),
                            name: userAccount.firstName + ' ' + userAccount.lastName
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    HeaderComponent.prototype.ngAfterViewChecked = function () {
        if (localStorage.getItem('isDataAvailableReload') != null) {
            this.loadUpdate = true;
        }
    };
    HeaderComponent.prototype.loadNewUpdate = function () {
        localStorage.removeItem('isDataAvailableReload');
        window.location.reload();
    };
    HeaderComponent.prototype.removeTooltips = function () {
        while (document.getElementsByTagName('mat-tooltip-component').length > 0) {
            document.getElementsByTagName('mat-tooltip-component')[0].remove();
        }
    };
    HeaderComponent.prototype.getFavoritesAndMessagesCount = function () {
        var _this = this;
        this.listingDetailService.updateFavoritesCount();
        this.favoritesCountSubscription = this.listingDetailService.favoritesCountSubject.subscribe(function (val) {
            _this.favoritesCount = val;
        });
        this.msgCountSubscription = this.messageService.unreadMsgSubject.subscribe(function (val) {
            _this.messageCount = val;
        });
    };
    HeaderComponent.prototype.resetFavoritesAndMessagesCount = function () {
        this.favoritesCount = 0;
        this.messageCount = 0;
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.userNameSubscription.unsubscribe();
        if (this.favoritesCountSubscription) {
            this.favoritesCountSubscription.unsubscribe();
        }
        if (this.msgCountSubscription) {
            this.msgCountSubscription.unsubscribe();
        }
    };
    HeaderComponent.prototype.goToUserHome = function () {
        this.removeTooltips();
        return this.router.navigateByUrl('/user-home');
    };
    HeaderComponent.prototype.goToMyAccount = function () {
        this.removeTooltips();
        return this.router.navigateByUrl('/user-home/my-account');
    };
    HeaderComponent.prototype.goToFavorites = function () {
        this.removeTooltips();
        this.routingHelperSrv.navigateFavoritesPage();
    };
    HeaderComponent.prototype.isAppInstalled = function () {
        return this.pwaService.appInstalled$;
    };
    HeaderComponent.prototype.toggleSideMenu = function () {
        this.sideMenuOpen = !this.sideMenuOpen;
        this.toggleMenu.emit(this.sideMenuOpen);
    };
    HeaderComponent.prototype.promptInstallPWA = function () {
        this.userActivityService.trackInstallApp();
        if (this.isAppInstalled()) {
            this.pwaService.promptPWAInstall();
        }
        this.gaService$.event('install', 'App Adoption', "" + this.currentDevice);
    };
    HeaderComponent.prototype.promptIos = function () {
        this.userActivityService.trackInstallApp();
        this.gaService$.event('install', 'App Adoption', "" + this.currentDevice);
        if (this.isIphone && (this.isChrome || this.platformIsIosChrome())) {
            this.promptIosChromeDialog();
        }
        else {
            this.promptService.openDialog(null, true);
        }
    };
    HeaderComponent.prototype.promptIosChromeDialog = function () {
        this.presentAlert();
    };
    HeaderComponent.prototype.isMobile = function () {
        return this.deviceDetector.isMobile();
    };
    HeaderComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
    };
    HeaderComponent.prototype.showH1HomeTitle = function () {
        return this.screenWidth >= 999;
    };
    HeaderComponent.prototype.platformIsIosChrome = function () {
        return this.platformHelper.isIosChrome();
    };
    HeaderComponent.prototype.platformIsIosSafari = function () {
        return this.platformHelper.isIosSafari();
    };
    HeaderComponent.prototype.presentAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: 'SiiLA SPOT Mobile',
                            cssClass: 'header-alert-prompt',
                            message: 'To install the app, please open our site using the Safari browser and click install App to proceed.'
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.openLoginDialog = function () {
        var loginData = {
            url: this.router.url,
            customText: this.customLoginText
        };
        this.loginFormDialog.openDialog(loginData);
    };
    HeaderComponent.prototype.back = function () {
        window.history.back();
    };
    HeaderComponent.prototype.goToHomePage = function () {
        return this.commonService.goToHomePage();
    };
    HeaderComponent.prototype.changeBorder = function () {
        var _this = this;
        this.showBorder = true;
        setTimeout(function () {
            _this.showBorder = false;
        }, 1000);
    };
    HeaderComponent.prototype.navigateToRegister = function () {
        //this.location.replaceState('/'); // clears browser history so they can't navigate with back button
        /* if (this.router.url !== '/register') {
          this.router.navigateByUrl('/register');
        } */
        this.dialog.open(RegisterFormComponent, {
            height: 'auto',
            width: '350px',
            maxWidth: 'none'
        });
    };
    HeaderComponent.prototype.checkRoute = function () {
        if (this.router.url !== '/register') {
            return false;
        }
        else {
            return true;
        }
    };
    HeaderComponent.prototype.logOut = function (event) {
        // Clear search on landing page
        this.listingService.updateModel({
            propertyType: null,
            listingType: null,
            stateId: null,
            cityId: null,
            keywordText: ''
        });
        this.auth.logout();
        this.clearBuySpotCart();
        if (this.router.url === '/') {
            this.toggleSideMenu();
        }
    };
    HeaderComponent.prototype.clearBuySpotCart = function () {
        this.store.dispatch(CartActions.ClearCart({ payload: { items: [] } }));
    };
    HeaderComponent.prototype.isStandAlone = function () {
        return window.matchMedia('(display-mode: standalone)').matches;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
