import * as tslib_1 from "tslib";
import { createReducer, on } from '@ngrx/store';
import * as CartActions from '../actions/';
export var layoutFeatureKey = 'cart';
var initialState = {
    id: 1,
    userId: null,
    items: [],
    purchaseOption: null,
    total: 0,
    // duplicate: false,
    calculations: {
        totalItemCount: 0
    }
};
var ɵ0 = function (_) { return initialState; }, ɵ1 = function (_) { return initialState; }, ɵ2 = function (state) { return state; }, ɵ3 = function (state) { return (tslib_1.__assign({}, state)); }, ɵ4 = function (state) { return state; }, ɵ5 = function (state, _a) {
    var payload = _a.payload;
    return tslib_1.__assign({}, state, { items: tslib_1.__spread(state.items, payload.items) });
}, ɵ6 = function (state, _a) {
    var payload = _a.payload;
    return (tslib_1.__assign({}, state, { items: tslib_1.__spread(state.items.filter(function (item, index) {
            return index !== payload.index;
        })) }));
}, ɵ7 = function (state, _a) {
    var payload = _a.payload;
    return tslib_1.__assign({}, state, { items: tslib_1.__spread(state.items.map(function (item, index) {
            if (index === payload.index) {
                return payload.item;
            }
            return item;
        })) });
}, ɵ8 = function (state, _a) {
    var payload = _a.payload;
    return tslib_1.__assign({}, state, { purchaseOption: payload.item });
}, ɵ9 = function (state, _a) {
    var payload = _a.payload;
    return tslib_1.__assign({}, state, { items: payload.items });
}, ɵ10 = function (state, _a) {
    var payload = _a.payload;
    return tslib_1.__assign({}, state, { purchaseOption: payload.item });
};
export var reducer = createReducer(initialState, 
// Even thought the `state` is unused, it helps infer the return type
on(CartActions.CreateCart, ɵ0), on(CartActions.DeleteCart, ɵ1), on(CartActions.GetCart, ɵ2), on(CartActions.UpdateCart, ɵ3), on(CartActions.SaveCart, ɵ4), on(CartActions.AddItem, ɵ5), on(CartActions.RemoveItem, ɵ6), on(CartActions.UpdateItem, ɵ7), on(CartActions.AddSelection, ɵ8), on(CartActions.ClearCart, ɵ9), on(CartActions.ClearSelection, ɵ10));
export var getCartState = function (state) { return state; };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
