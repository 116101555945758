<!-- <toaster-notification></toaster-notification> -->

<div
  class="row"
  style="    margin: -12px -24px 0px -24px;
border-bottom: 1px solid #6c757d;"
>
  <div style="width: 60%;margin-bottom: 12px;">
    <span style="font-weight: 700;font-size: 23px;  margin-left: 20px;">{{ 'login' | translate }}</span>
  </div>
  <div style="width: 40%;">
    <img class="close-icon" (click)="dismissAsDialog()" src="assets/images/icons/close.png" alt="" />
  </div>
</div>

<br />

<div
  *ngIf="!error && !forgot"
  style="width: 100%;
text-align: center;"
>
  <span class="font-16">{{ 'global.register.welcomeSpot' | translate }}</span>
  <br />
  <span class="font-14">{{ 'global.register.welcomeSpotTxt' | translate }}</span>
</div>

<div
  *ngIf="error"
  style="width: 100%;
text-align: center;"
>
  <span class="font-16" style="color: #DC3545;">
    <img style="margin-top: -5px;" src="assets/images/icons/wrong.png" alt="" />
    {{ 'global.form.wrongCredentials' | translate }}</span
  >
</div>

<div
  *ngIf="forgot && !error"
  style="width: 100%;
text-align: center;"
>
  <span class="font-16" style="color: #198754;">
    <img style="margin-top: -5px;" src="assets/images/icons/done.png" alt="" />
    {{ 'global.register.forgotPassword' | translate }}</span
  >
</div>

<form id="login-form" class="login-form" name="loginForm" autocomplete="on">
  <input
    matInput
    md-autofocus
    autocomplete="on"
    placeholder="{{ 'global.form.email' | translate }}"
    class="form-control"
    id="input0"
    name="email"
    [formControl]="emailCtrl"
    required
    autocomplete="email"
  />

  <input
    placeholder="{{ 'global.form.password' | translate }}"
    [type]="hide ? 'password' : 'text'"
    style="margin-top: 10px;"
    class="form-control"
    id="input1"
    name="password"
    [formControl]="passwordCtrl"
    required
  />
  <img
    (click)="hide = !hide"
    style="position: absolute; margin: -24px 0px 0px 216px;cursor: pointer;"
    src="assets/images/icons/seePassword.png"
    alt=""
  />

  <br />

  <a (click)="navigateToForgot()" style="color: #0D6EFD;cursor: pointer;margin-top: 10px;">{{
    'global.form.forgotPassword' | translate
  }}</a>

  <ion-button
    [disabled]="passwordCtrl.hasError('required') || (emailCtrl.hasError('email') || emailCtrl.hasError('required'))"
    (click)="submit()"
    class="btn-color-2"
    style="width: 98%;font-size: 14px;"
  >
    <span>{{ 'login' | translate }}</span>
  </ion-button>
</form>
<ion-row>
  <ion-col size="12" class="register-now">
    <span class="font-14">{{ 'global.form.newToSpot' | translate }}</span>

    <span class="forgot-pass" style="margin-bottom: 0px;">
      <b>
        <a (click)="navigateToRegister()" class="a-links">
          {{ 'global.form.createAccount' | translate }}
        </a>
      </b>
    </span>
  </ion-col>
</ion-row>
<ion-row class="continue-unlogged">
  <div
    class="font-14"
    style="width: 100%;
text-align: center"
  >
    <span style="font-size: 10px;"
      >{{ 'global.form.byLogging' | translate }}
      <a class="a-links" (click)="goToSiilaTermsPage()">{{ 'global.menu.options.terms' | translate }}</a>
      {{ 'global.form.and' | translate }}
      <a class="a-links" (click)="goToSiilaPrivacyPolicyPage()">{{
        'global.menu.options.privacyPolicy' | translate
      }}</a>
      {{ 'global.form.doSpot' | translate }}.</span
    >
  </div>
</ion-row>
