/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./turnoff-notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./turnoff-notifications.component";
import * as i5 from "../../../core/i18n.service";
import * as i6 from "../../services/turnoff-notification.service";
import * as i7 from "@angular/router";
var styles_TurnoffNotificationsComponent = [i0.styles];
var RenderType_TurnoffNotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TurnoffNotificationsComponent, data: {} });
export { RenderType_TurnoffNotificationsComponent as RenderType_TurnoffNotificationsComponent };
function View_TurnoffNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "margin-top: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("notifications.turnoffOK")); _ck(_v, 3, 0, currVal_0); }); }
function View_TurnoffNotificationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "margin-top: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("notifications.turnoffExpired")); _ck(_v, 3, 0, currVal_0); }); }
function View_TurnoffNotificationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "margin-top: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.turnOffBadRequestTxt; _ck(_v, 2, 0, currVal_0); }); }
function View_TurnoffNotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TurnoffNotificationsComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TurnoffNotificationsComponent_4)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.validationCode == 100); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.validationCode != 100); _ck(_v, 6, 0, currVal_1); }, null); }
function View_TurnoffNotificationsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.turnOffBadRequestTxt; _ck(_v, 2, 0, currVal_0); }); }
export function View_TurnoffNotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["style", "display: flex; width: 100%; justify-content: center; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TurnoffNotificationsComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TurnoffNotificationsComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TurnoffNotificationsComponent_5)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readyToUnsubscribe; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.validationCode > 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.readyToUnsubscribe; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_TurnoffNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-turnoff-notifications", [], null, null, null, View_TurnoffNotificationsComponent_0, RenderType_TurnoffNotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i4.TurnoffNotificationsComponent, [i5.I18nService, i6.TurnOffNotificationService, i7.ActivatedRoute, i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TurnoffNotificationsComponentNgFactory = i1.ɵccf("app-turnoff-notifications", i4.TurnoffNotificationsComponent, View_TurnoffNotificationsComponent_Host_0, {}, {}, []);
export { TurnoffNotificationsComponentNgFactory as TurnoffNotificationsComponentNgFactory };
