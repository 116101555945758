import * as tslib_1 from "tslib";
import { I18nService } from '../i18n.service';
import * as i0 from "@angular/core";
import * as i1 from "../i18n.service";
export var CustomSpotSearchType;
(function (CustomSpotSearchType) {
    CustomSpotSearchType["MARKET"] = "MARKET";
    CustomSpotSearchType["STATE"] = "STATE";
    CustomSpotSearchType["CITY"] = "CITY";
})(CustomSpotSearchType || (CustomSpotSearchType = {}));
var AppRouteNames = /** @class */ (function () {
    function AppRouteNames() {
    }
    AppRouteNames.MARKET_URL_PARAM = 'market';
    AppRouteNames.MARKET_URL_PARAM_CL = 'mercado';
    AppRouteNames.SEARCH = 'search';
    AppRouteNames.SEARCH_COUNTRYLANG = 'resultado';
    AppRouteNames.LEASE_URL_PARAM = 'lease';
    AppRouteNames.LEASE_URL_PARAM_CL = 'renta';
    AppRouteNames.SALE_URL_PARAM = 'sale';
    AppRouteNames.SALE_URL_PARAM_CL = 'venta';
    AppRouteNames.OFFICE_URL_PARAM = 'office';
    AppRouteNames.OFFICE_URL_PARAM_CL = 'oficina';
    AppRouteNames.INDUSTRIAL_URL_PARAM_CL = 'industrial';
    AppRouteNames.INDUSTRIAL_URL_PARAM = 'warehouse';
    AppRouteNames.SAVED_SEARCH_URL_PARAM = 'saved-search';
    AppRouteNames.SAVED_SEARCH_URL_PARAM_CL = 'salvar-busqueda';
    AppRouteNames.FILTER_URL_PARAM = 'filter';
    AppRouteNames.FILTER_URL_PARAM_CL = 'filtro';
    AppRouteNames.SPOTSET_URL_PARAM = 'spotset';
    AppRouteNames.SPOTSET_URL_PARAM_CL = 'busqueda';
    AppRouteNames.COMPANY_ROUTE = 'company';
    AppRouteNames.COMPANY_ROUTE_CL = 'empresa';
    AppRouteNames.USR_PROFILE_ROUTE = 'profile';
    AppRouteNames.USR_PROFILE_ROUTE_CL = 'perfil';
    AppRouteNames.TERMS_ROUTE = 'terms-of-use';
    AppRouteNames.TERMS_ROUTE_CL = 'terminos-de-uso';
    AppRouteNames.PRIVACY_POLICY_ROUTE = 'privacy-policy';
    AppRouteNames.PRIVACY_POLICY_ROUTE_CL = 'politica-de-privacidad';
    AppRouteNames.MY_SPOTS_ROUTE = 'my-spots';
    AppRouteNames.MY_SPOTS_ROUTE_CL = 'mis-spots';
    return AppRouteNames;
}());
export { AppRouteNames };
var AppRouteNamesService = /** @class */ (function () {
    function AppRouteNamesService(i18n) {
        var e_1, _a;
        var _this = this;
        this.i18n = i18n;
        this.getSearchRouteName = function () {
            var searchRouteName = '';
            if (_this.i18n.isLangEN()) {
                searchRouteName = AppRouteNames.SEARCH;
            }
            else {
                searchRouteName = AppRouteNames.SEARCH_COUNTRYLANG;
            }
            return searchRouteName;
        };
        this.getDefaultInitSearchRouteName = function () {
            var lease = "" + AppRouteNames.LEASE_URL_PARAM_CL;
            var office = "" + AppRouteNames.OFFICE_URL_PARAM_CL;
            if (_this.i18n.isLangEN()) {
                lease = "" + AppRouteNames.LEASE_URL_PARAM;
                office = "" + AppRouteNames.OFFICE_URL_PARAM;
            }
            return "/" + lease + "/" + office;
        };
        this.getSavedSearchRouteName = function () {
            return _this.i18n.isLangEN() ? AppRouteNames.SAVED_SEARCH_URL_PARAM : AppRouteNames.SAVED_SEARCH_URL_PARAM_CL;
        };
        this.getFilterRouteName = function () {
            return _this.i18n.isLangEN() ? AppRouteNames.FILTER_URL_PARAM : AppRouteNames.FILTER_URL_PARAM_CL;
        };
        this.getSpotSetRouteName = function () {
            return _this.i18n.isLangEN() ? AppRouteNames.SPOTSET_URL_PARAM : AppRouteNames.SPOTSET_URL_PARAM_CL;
        };
        this.getCompanyRouteName = function () { return (_this.i18n.isLangEN() ? AppRouteNames.COMPANY_ROUTE : AppRouteNames.COMPANY_ROUTE_CL); };
        this.getTermsRouteName = function () { return (_this.i18n.isLangEN() ? AppRouteNames.TERMS_ROUTE : AppRouteNames.TERMS_ROUTE_CL); };
        this.getPrivacyPolyRouteName = function () {
            return _this.i18n.isLangEN() ? AppRouteNames.PRIVACY_POLICY_ROUTE : AppRouteNames.PRIVACY_POLICY_ROUTE_CL;
        };
        this.getMySpotsRouteName = function () { return (_this.i18n.isLangEN() ? AppRouteNames.MY_SPOTS_ROUTE : AppRouteNames.MY_SPOTS_ROUTE_CL); };
        this.getMarketRoutePathName = function () {
            return _this.i18n.isLangEN() ? AppRouteNames.MARKET_URL_PARAM : AppRouteNames.MARKET_URL_PARAM_CL;
        };
        this.routeNamesDictionary = new Map();
        this.routeNamesDictionaryCL = new Map();
        this.routeNamesDictionary.set(AppRouteNames.MY_SPOTS_ROUTE, AppRouteNames.MY_SPOTS_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.USR_PROFILE_ROUTE, AppRouteNames.USR_PROFILE_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.SEARCH, AppRouteNames.SEARCH_COUNTRYLANG);
        this.routeNamesDictionary.set(AppRouteNames.MARKET_URL_PARAM, AppRouteNames.MARKET_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.LEASE_URL_PARAM, AppRouteNames.LEASE_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.OFFICE_URL_PARAM, AppRouteNames.OFFICE_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.SAVED_SEARCH_URL_PARAM, AppRouteNames.SAVED_SEARCH_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.FILTER_URL_PARAM, AppRouteNames.FILTER_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.SPOTSET_URL_PARAM, AppRouteNames.SPOTSET_URL_PARAM_CL);
        this.routeNamesDictionary.set(AppRouteNames.COMPANY_ROUTE, AppRouteNames.COMPANY_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.TERMS_ROUTE, AppRouteNames.TERMS_ROUTE_CL);
        this.routeNamesDictionary.set(AppRouteNames.PRIVACY_POLICY_ROUTE, AppRouteNames.PRIVACY_POLICY_ROUTE_CL);
        try {
            for (var _b = tslib_1.__values(this.routeNamesDictionary.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), english = _d[0], cl = _d[1];
                this.routeNamesDictionaryCL.set(cl, english);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    AppRouteNamesService.prototype.removeLeadingAndTrailingSlashes = function (route) {
        return route.replace(/^\/|\/$/g, '');
    };
    AppRouteNamesService.prototype.findRouteAlternative = function (routeName) {
        var tmpRouteName = this.i18n.removeLanguageFromURL(routeName);
        routeName = this.removeLeadingAndTrailingSlashes(tmpRouteName);
        var newRoute = null;
        if (this.i18n.isLangEN()) {
            newRoute = this.translateSentence(this.routeNamesDictionaryCL, routeName);
        }
        else {
            newRoute = this.translateSentence(this.routeNamesDictionary, routeName);
        }
        if (!newRoute) {
            newRoute = tmpRouteName;
        }
        return this.i18n.addCurrentLanguageToPath(newRoute);
    };
    AppRouteNamesService.prototype.translateSentence = function (translationDictionary, sentence) {
        var joinCh = '/';
        var words = sentence.split(joinCh);
        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            if (translationDictionary.has(word)) {
                words[i] = translationDictionary.get(word);
            }
        }
        return words.join(joinCh);
    };
    AppRouteNamesService.prototype.findCustomSearchType = function (url) {
        var result = undefined;
        var urlPathParams = url.split('/');
        if (url.startsWith("/" + this.getSearchRouteName())) {
            if (urlPathParams.length > 5) {
                if (url.includes(this.getMarketRoutePathName())) {
                    result = CustomSpotSearchType.MARKET;
                }
                else if (urlPathParams.length == 7) {
                    result = CustomSpotSearchType.CITY;
                }
                else if (urlPathParams.length == 6) {
                    result = CustomSpotSearchType.STATE;
                }
            }
        }
        return result;
    };
    AppRouteNamesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppRouteNamesService_Factory() { return new AppRouteNamesService(i0.ɵɵinject(i1.I18nService)); }, token: AppRouteNamesService, providedIn: "root" });
    return AppRouteNamesService;
}());
export { AppRouteNamesService };
