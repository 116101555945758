import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en-US';
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }

    // Register locale data since only the en-US locale data comes with Angular
    switch (culture) {
      case 'es-MX': {
        registerLocaleData(localeES);
        break;
      }
    }
  }
}
