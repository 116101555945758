import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core';
import { BaseService } from './base-service';
import { map } from 'rxjs/operators';

@Injectable()
export class TurnOffNotificationService extends BaseService {
  private turnOffAPI = `/api/user/turnoff/notifications`;

  constructor(private http: HttpClient, private i18n: I18nService) {
    super();
  }

  turnOffNotification(login: string, notificationType: string, hash: string) {
    const requestDto = { login, notificationType, hash };

    return this.http
      .post(this.turnOffAPI, requestDto, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }
}
