import * as tslib_1 from "tslib";
import { ListingOfferType } from '@app/models/transaction-type.model';
import { CommonGenericService } from './common.service';
import { ListingService } from './listings';
import { I18nService } from './i18n.service';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
var SearchTagsService = /** @class */ (function () {
    function SearchTagsService(commonService, listingService, i18NService) {
        this.commonService = commonService;
        this.listingService = listingService;
        this.i18NService = i18NService;
        this.searchTags = [];
    }
    SearchTagsService.prototype.getSearchTags = function () {
        return tslib_1.__spread(this.searchTags);
    };
    SearchTagsService.prototype.clearSearchTags = function () {
        this.searchTags = [];
    };
    SearchTagsService.prototype.checkTagNames = function (tag) {
        if (tag.fieldName === 'rangeCeilingHeight' || tag.fieldName === 'baySize' || tag.fieldName === 'dockRatio') {
            return true;
        }
        else {
            return false;
        }
    };
    SearchTagsService.prototype.checkRangeTags = function (fieldName, dto, checkboxValues) {
        var minmax = JSON.parse(localStorage.getItem('minMax'));
        if (fieldName === 'rangeCeilingHeight') {
            (dto.ceilingHeightMin = null), (dto.ceilingHeightMax = null), (checkboxValues.ceilingHeight = false);
            return dto;
        }
        if (fieldName === 'baySize') {
            (dto.baySizeMin = null), (dto.baySizeMax = null), (checkboxValues.baySize = false);
            return dto;
        }
        if (fieldName === 'dockRatio') {
            (dto.dockRatioMin = null), (dto.dockRatioMax = null), (checkboxValues.dockRatio = false);
            return dto;
        }
        if (fieldName === 'loadCap') {
            (dto.loadCapacityMin = null), (dto.loadCapacityMax = null), (checkboxValues.loadCap = false);
            return dto;
        }
        if (fieldName === 'parkingSpots') {
            (dto.parkingSpacesMin = null), (dto.parkingSpacesMax = null), (checkboxValues.parkingSpots = false);
            return dto;
        }
        /*if (fieldName === 'lockerRooms') {
          (dto.lockerRoomsMin = null), (dto.lockerRoomsMax = null);
          return dto;
        }
        if (fieldName === 'restrooms') {
          (dto.restroomsMin = null), (dto.restroomsMax = null);
          return dto;
        }*/
        if (fieldName === 'truckParkingSpaces') {
            (dto.truckParkingSpacesMin = null),
                (dto.truckParkingSpacesMax = null),
                (checkboxValues.truckParkingSpaces = false);
            return dto;
        }
        if (fieldName === 'offeringPrice') {
            (dto.offeringPriceIni = null), (dto.offeringPriceEnd = null), (checkboxValues.offeringPrice = false);
            return dto;
        }
        if (fieldName === 'offeringPricePerArea') {
            (dto.offeringPricePerAreaIni = null),
                (dto.offeringPricePerAreaEnd = null),
                (checkboxValues.offeringPricePerArea = false);
            return dto;
        }
        if (fieldName === 'parkingRatio') {
            (dto.parkingRatioMin = null), (dto.parkingRatioMax = null), (checkboxValues.parkingRatio = false);
            return dto;
        }
        if (fieldName === 'parkingSpaces') {
            (dto.parkingSpacesMin = null), (dto.parkingSpacesMax = null), (checkboxValues.parkingSpaces = false);
            return dto;
        }
        if (fieldName === 'rentableArea') {
            (dto.rentableAreaIni = null), (dto.rentableAreaEnd = null), (checkboxValues.rentableArea = false);
            return dto;
        }
        if (fieldName === 'monthlyRent') {
            (dto.monthlyRentMin = null), (dto.monthlyRentMax = null), (checkboxValues.monthlyRent = false);
            return dto;
        }
        if (fieldName === 'monthlyRentPerArea') {
            (dto.monthlyRentPerAreaMin = null),
                (dto.monthlyRentPerAreaMax = null),
                (checkboxValues.monthlyRentPerArea = false);
            return dto;
        }
        if (fieldName === 'totalArea') {
            (dto.totalAreaIni = null), (dto.totalAreaEnd = null);
            return dto;
        }
        if (fieldName === 'landArea') {
            (dto.landAreaIni = null), (dto.landAreaEnd = null);
            return dto;
        }
        if (fieldName === 'totalAskingPrice') {
            (dto.totalAskingPriceIni = null), (dto.totalAskingPriceEnd = null);
            return dto;
        }
        if (fieldName === 'askingPricePerArea') {
            (dto.askingPricePerAreaMin = null), (dto.askingPricePerAreaMax = null);
            return dto;
        }
        if (fieldName === 'propertySize') {
            (dto.propertySizeIni = null), (dto.propertySizeEnd = null);
            return dto;
        }
        if (fieldName === 'totalMonthlyRent') {
            (dto.totalMonthlyRentIni = null), (dto.totalMonthlyRentEnd = null);
            return dto;
        }
        if (fieldName === 'numberFloor') {
            (dto.numberFloorIni = null), (dto.numberFloorEnd = null);
            return dto;
        }
        if (fieldName === 'yearBuilt') {
            (dto.yearBuiltIni = null), (dto.yearBuiltEnd = null);
            return dto;
        }
        if (fieldName === 'yearRenovated') {
            (dto.yearRenovatedIni = null), (dto.yearRenovatedEnd = null);
            return dto;
        }
        if (fieldName === 'location') {
            dto.location = null;
            return dto;
        }
        if (fieldName === 'monthlyCostPerPerson') {
            (dto.monthlyCostPerPersonMin = null), (dto.monthlyCostPerPersonMax = null);
            return dto;
        }
        if (fieldName === 'kva') {
            (dto.kvaMin = null), (dto.kvaMax = null), (checkboxValues.kva = false);
            return dto;
        }
        return false;
    };
    SearchTagsService.prototype.clearSearchTagsFromDTO = function (dto) {
        var minmax = JSON.parse(localStorage.getItem('minMax'));
        if (dto.refrigerated)
            dto.refrigerated = false;
        if (dto.sluiceGate)
            dto.sluiceGate = false;
        if (dto.hasLockerRooms)
            dto.hasLockerRooms = false;
        //if (dto.hasCeilingHeight) dto.hasCeilingHeight = false;
        if (dto.heliport)
            dto.heliport = false;
        if (dto.sprinklers)
            dto.sprinklers = false;
        if (dto.electricGenerator)
            dto.electricGenerator = false;
        //if (dto.hasParkingSpaces) dto.hasParkingSpaces = false;
        if (dto.plugAndPlayConditionOffice)
            dto.plugAndPlayConditionOffice = false;
        //if (dto.finishedOut) dto.finishedOut = false;
        if (dto.coreAndShellCondition)
            dto.coreAndShellCondition = false;
        if (dto.centralAirConditioning)
            dto.centralAirConditioning = false;
        if (dto.miniSplitAirConditioning)
            dto.miniSplitAirConditioning = false;
        if (dto.bicycleRack)
            dto.bicycleRack = false;
        if (dto.fiberOptic)
            dto.fiberOptic = false;
        if (dto.leedStatus)
            dto.leedStatus = false;
        if (dto.raisedFloor)
            dto.raisedFloor = false;
        if (dto.hourSecurity)
            dto.hourSecurity = false;
        if (dto.neverUsed)
            dto.neverUsed = false;
        if (dto.hasRestrooms)
            dto.hasRestrooms = false;
        if (dto.armoredCabin)
            dto.armoredCabin = false;
        if (dto.manufacturingCondition)
            dto.manufacturingCondition = false;
        //if (dto.logistics) dto.logistics = false;
        if (dto.officeSpaceAvailable)
            dto.officeSpaceAvailable = false;
        //if (dto.fireProtectionSystem) dto.fireProtectionSystem = false;
        if (dto.blockConstruction)
            dto.blockConstruction = false;
        if (dto.steelConstruction)
            dto.steelConstruction = false;
        if (dto.blockAndSteelConstruction)
            dto.blockAndSteelConstruction = false;
        if (dto.skyLightsSpace)
            dto.skyLightsSpace = false;
        //if (dto.hasLoadCapacity) dto.hasLoadCapacity = false;
        //if (dto.hasDockRatio) dto.hasDockRatio = false;
        //if (dto.hasCarParkingSpaces) dto.hasCarParkingSpaces = false;
        if (dto.singleTenant)
            dto.singleTenant = false;
        if (dto.multiTenant)
            dto.multiTenant = false;
        //if (dto.hasTruckParkingSpaces) dto.hasTruckParkingSpaces = false;
        //if (dto.industrialPark) dto.industrialPark = false;
        //if (dto.industrialZone) dto.industrialZone = false;
        if (dto.truckYard)
            dto.truckYard = false;
        if (dto.crossDocking)
            dto.crossDocking = false;
        if (dto.twentyFourHourSecurity)
            dto.twentyFourHourSecurity = false;
        if (dto.coreAndShel)
            dto.coreAndShel = false;
        if (dto.bulletProofCabin)
            dto.bulletProofCabin = false;
        if (dto.datacenterCondition)
            dto.datacenterCondition = false;
        if (dto.forRetailUseConditionOffice)
            dto.forRetailUseConditionOffice = false;
        if (dto.forRetailUseConditionIndustrial)
            dto.forRetailUseConditionIndustrial = false;
        if (dto.flexCondition)
            dto.flexCondition = false;
        if (dto.fullFloorCondition)
            dto.fullFloorCondition = false;
        if (dto.includeColdStorage)
            dto.includeColdStorage = false;
        if (dto.logisticsCondition)
            dto.logisticsCondition = false;
        if (dto.preBuildCondition)
            dto.preBuildCondition = false;
        if (dto.steelConstruction)
            dto.steelConstruction = false;
        if (dto.subLease)
            dto.subLease = false;
        if (dto.furnishedConditionCoworking)
            dto.furnishedConditionCoworking = false;
        if (dto.barista)
            dto.barista = false;
        if (dto.enhancedCleaning)
            dto.enhancedCleaning = false;
        if (dto.petFriendly)
            dto.petFriendly = false;
        if (dto.parkingAvailable)
            dto.parkingAvailable = false;
        if (dto.conferenceRoom)
            dto.conferenceRoom = false;
        if (dto.privateOutdoorSpace)
            dto.privateOutdoorSpace = false;
        if (dto.plugAndPlayConditionCoworking)
            dto.plugAndPlayConditionCoworking = false;
        if (dto.waterHydrant)
            dto.waterHydrant = false;
        if (dto.withinIndustrialPark)
            dto.withinIndustrialPark = false;
        if (dto.accessToShowers)
            dto.accessToShowers = false;
        //if (dto.hasBaySize) dto.hasBaySize = false;
        dto.ceilingHeightMin = null;
        dto.ceilingHeightMax = null;
        dto.baySizeMin = null;
        dto.baySizeMax = null;
        dto.dockRatioMin = null;
        dto.dockRatioMax = null;
        dto.loadCapacityMin = null;
        dto.loadCapacityMax = null;
        //dto.lockerRoomsMin = null;
        //dto.lockerRoomsMax = null;
        dto.truckParkingSpacesMin = null;
        dto.truckParkingSpacesMax = null;
        dto.offeringPriceIni = null;
        dto.offeringPriceEnd = null;
        dto.offeringPricePerAreaIni = null;
        dto.offeringPricePerAreaEnd = null;
        dto.parkingRatioMin = null;
        dto.parkingRatioMax = null;
        dto.parkingSpacesMin = null;
        dto.parkingSpacesMax = null;
        dto.rentableAreaIni = null;
        dto.rentableAreaEnd = null;
        dto.monthlyRentMin = null;
        dto.monthlyRentMax = null;
        dto.monthlyRentPerAreaMin = null;
        dto.monthlyRentPerAreaMax = null;
        dto.propertySubTypes = null;
        dto.propertySubtypeIds = null;
        dto.listingClasses = null;
        dto.listingStatuses = null;
        dto.askingPricePerAreaMin = null;
        dto.askingPricePerAreaMax = null;
        dto.landAreaIni = null;
        dto.landAreaEnd = null;
        dto.numberFloorIni = null;
        dto.numberFloorEnd = null;
        dto.totalAreaIni = null;
        dto.totalAreaEnd = null;
        dto.totalAskingPriceIni = null;
        dto.totalAskingPriceEnd = null;
        dto.totalMonthlyRentIni = null;
        dto.totalMonthlyRentEnd = null;
        dto.yearBuiltIni = null;
        dto.yearBuiltEnd = null;
        dto.yearRenovatedIni = null;
        dto.yearRenovatedEnd = null;
        dto.propertySizeIni = null;
        dto.propertySizeEnd = null;
        dto.location = null;
        dto.monthlyCostPerPersonMax = null;
        dto.monthlyCostPerPersonMin = null;
        dto.availabilityDate = null;
        dto.coworkingType = null;
        dto.positions = null;
        dto.kvaMax = null;
        dto.kvaMin = null;
        this.searchTags = [];
        return dto;
    };
    SearchTagsService.prototype.addCoworkingTags = function (filters, arr) {
        // coworking will have the tags for positions and space
        if (true) {
            var count = 1;
            var interpolationParams = { count: 1 };
            if (filters.coworkingPositions && filters.coworkingPositions > 0) {
                count = filters.coworkingPositions;
                interpolationParams.count = filters.coworkingPositions;
            }
            /* const positionTagName = this.i18NService.get('global.coworking.positionsTag', interpolationParams);
            arr.push({ name: positionTagName, fieldName: 'coworkingPositions', fieldValue: count }); */
            if (filters.coworkingType) {
                var fName = 'All';
                if (filters.coworkingType == 'shared') {
                    fName = 'Shared Space';
                }
                else if (filters.coworkingType == 'private') {
                    fName = 'Private Space';
                }
                //if (fName != 'All') {
                //  arr.push({ name: fName, fieldName: 'coworkingType' });
                //}
            }
        }
    };
    SearchTagsService.prototype.updateSearchTags = function (filters, spotsNearMe) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var arr, minmax, stateId, cityId, marketId, spotSetTagName, stateValue, _a, _b, _c, cityValue, _d, _e, _f, cityState, marketValue, _g, _h, _j, _k, obj;
            var _this = this;
            return tslib_1.__generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        arr = [];
                        minmax = JSON.parse(localStorage.getItem('minMax'));
                        stateId = filters.stateId;
                        cityId = filters.cityId;
                        marketId = filters.fakeMarketId;
                        spotSetTagName = 'SPOT Set:';
                        if (!stateId) return [3 /*break*/, 2];
                        _c = (_b = this.i18NService).getTranslation;
                        return [4 /*yield*/, this.listingService.getState(stateId)];
                    case 1:
                        _a = _c.apply(_b, [(_l.sent()).name]);
                        return [3 /*break*/, 3];
                    case 2:
                        _a = null;
                        _l.label = 3;
                    case 3:
                        stateValue = _a;
                        if (!cityId) return [3 /*break*/, 5];
                        _f = (_e = this.i18NService).getTranslation;
                        return [4 /*yield*/, this.listingService.getCity(cityId)];
                    case 4:
                        _d = _f.apply(_e, [(_l.sent()).name]);
                        return [3 /*break*/, 6];
                    case 5:
                        _d = null;
                        _l.label = 6;
                    case 6:
                        cityValue = _d;
                        cityState = (stateValue && cityValue) || cityValue ? cityValue : stateValue;
                        if (!marketId) return [3 /*break*/, 8];
                        _h = this.i18NService.get('global.market') + ": ";
                        _k = (_j = this.i18NService).getTranslation;
                        return [4 /*yield*/, this.listingService.getMarket(marketId)];
                    case 7:
                        _g = _h + _k.apply(_j, [(_l.sent()).name]);
                        return [3 /*break*/, 9];
                    case 8:
                        _g = null;
                        _l.label = 9;
                    case 9:
                        marketValue = _g;
                        if (filters.buildingTypes && filters.buildingTypes[0] == 1001) {
                            arr.push({ name: 'global.list-your-property.industrial', fieldName: 'propertyType' });
                        }
                        if (filters.buildingTypes &&
                            filters.buildingTypes[0] == 2001 &&
                            filters.listingLeaseOrSale[0] !== ListingOfferType.Coworking) {
                            arr.push({ name: 'global.list-your-property.office', fieldName: 'officeType' });
                        }
                        if (this.isCoworkingPropertyType(filters)) {
                            arr.push({ name: 'global.list-your-property.coworking', fieldName: 'coworkingPropertyType' });
                        }
                        if (filters.onlyFavorites) {
                            arr.push({ name: 'global.list-your-property.favorites', fieldName: 'onlyFavorites' });
                        }
                        if ((filters.listingLeaseOrSale && filters.listingLeaseOrSale[0] === ListingOfferType.Coworking) ||
                            this.isCoworkingPropertyType(filters)) {
                            this.addCoworkingTags(filters, arr);
                        }
                        if (filters.propertySubTypes) {
                            filters.propertySubTypes.forEach(function (item, index) {
                                //  Show the correct name based on the selected language
                                var propertySubTypeName = item.name;
                                if (item.translationNames) {
                                    propertySubTypeName = _this.i18NService.getTranslation(item.translationNames);
                                }
                                arr.push({ name: propertySubTypeName, fieldName: item.id + '&&' + propertySubTypeName });
                            });
                        }
                        if (spotsNearMe) {
                            arr.push({ name: 'global.spotsNearMe', fieldName: 'spotsNearMe' });
                        }
                        obj = {
                            propertySubTypes: filters.propertySubTypes && filters.propertySubTypes.length > 0
                                ? arr.push({ name: filters.propertySubTypes[0].name, fieldName: 'propertySubTypes' })
                                : null,
                            lease: filters.listingLeaseOrSale && filters.listingLeaseOrSale[0] === 'L'
                                ? arr.push({ name: 'global.list-your-property.forLease', fieldName: 'lease' })
                                : null,
                            sale: filters.listingLeaseOrSale && filters.listingLeaseOrSale[0] === 'S'
                                ? arr.push({ name: 'global.list-your-property.forSale', fieldName: 'sale' })
                                : null,
                            coworking: filters.listingLeaseOrSale &&
                                filters.listingLeaseOrSale[0] === ListingOfferType.Coworking &&
                                !this.isCoworkingPropertyType(filters)
                                ? arr.push({ name: 'global.list-your-property.forCoworking', fieldName: 'coworking' })
                                : null,
                            coworkingType: filters.listingLeaseOrSale &&
                                filters.listingLeaseOrSale[0] === ListingOfferType.Coworking &&
                                filters.coworkingType
                                ? arr.push({
                                    name: this.i18NService.get('global.coworking.coworkingType') +
                                        ': ' +
                                        this.mapCoworkingType(filters.coworkingType),
                                    fieldName: 'coworkingType'
                                })
                                : null,
                            positions: filters.positions
                                ? arr.push({
                                    name: this.i18NService.get('buildinsDetail.numberPositions') + ': ' + filters.positions,
                                    fieldName: 'positions'
                                })
                                : null,
                            location: filters.location ? arr.push({ name: filters.location, fieldName: 'location' }) : null,
                            cityState: cityState ? arr.push({ name: cityState, fieldName: 'cityState' }) : null,
                            neighborhood: filters.neighborhood ? arr.push({ name: filters.neighborhood, fieldName: 'neighborhood' }) : null,
                            keyword: filters.keyword ? arr.push({ name: filters.keyword, fieldName: 'keyword' }) : null,
                            market: marketValue ? arr.push({ name: marketValue, fieldName: 'market' }) : null,
                            spotSet: filters.spotSetName
                                ? arr.push({ name: spotSetTagName + " " + filters.spotSetName, fieldName: 'spotSet' })
                                : null,
                            buildingName: filters.buildingName
                                ? arr.push({
                                    name: this.i18NService.get('buildinsDetail.building') + ': ' + filters.buildingName,
                                    fieldName: 'buildingName'
                                })
                                : null,
                            ceilingHeight: (filters.ceilingHeightMax &&
                                (filters.ceilingHeightMin !== minmax.ceilingHeightMin ||
                                    filters.ceilingHeightMax !== minmax.ceilingHeightMax)) ||
                                (!filters.ceilingHeightMax && filters.ceilingHeightMin && filters.ceilingHeightMin !== minmax.ceilingHeightMin)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.ceilingHeight',
                                    fieldName: 'rangeCeilingHeight',
                                    min: filters.ceilingHeightMin,
                                    max: filters.ceilingHeightMax ? filters.ceilingHeightMax : minmax.ceilingHeightMax,
                                    showPlus: filters.ceilingHeightMax === minmax.ceilingHeightMax || !filters.ceilingHeightMax ? true : false
                                })
                                : null,
                            baySize: filters.baySizeMax && (filters.baySizeMin !== minmax.baySizeMin || filters.baySizeMax !== minmax.baySizeMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.baySize',
                                    fieldName: 'baySize',
                                    min: filters.baySizeMin,
                                    max: filters.baySizeMax
                                })
                                : null,
                            dockRatio: filters.dockRatioMax &&
                                (filters.dockRatioMin !== minmax.dockRatioMin || filters.dockRatioMax !== minmax.dockRatioMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.dockRatio',
                                    fieldName: 'dockRatio',
                                    min: filters.dockRatioMin,
                                    max: filters.dockRatioMax
                                })
                                : null,
                            loadCapacity: filters.loadCapacityMax &&
                                (filters.loadCapacityMin !== minmax.loadCapacityMin || filters.loadCapacityMax !== minmax.loadCapacityMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.loadCap',
                                    fieldName: 'loadCap',
                                    min: filters.loadCapacityMin,
                                    max: filters.loadCapacityMax
                                })
                                : null,
                            parkingSpots: filters.parkingSpacesMax &&
                                (filters.parkingSpacesMin !== minmax.parkingSpacesMin || filters.parkingSpacesMax !== minmax.parkingSpacesMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.parkingSpots',
                                    fieldName: 'parkingSpots',
                                    min: filters.parkingSpacesMin,
                                    max: filters.parkingSpacesMax
                                })
                                : null,
                            /*lockerRooms:
                              filters.lockerRoomsMax &&
                              (filters.lockerRoomsMin !== minmax.lockerRoomsMin || filters.lockerRoomsMax !== minmax.lockerRoomsMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.lockerRooms',
                                    fieldName: 'lockerRooms',
                                    min: filters.lockerRoomsMin,
                                    max: filters.lockerRoomsMax
                                  })
                                : null,*/
                            // restrooms:
                            //   filters.numRestroomsMax &&
                            //   (filters.numRestroomsMin !== minmax.numRestroomsMin || filters.numRestroomsMax !== minmax.numRestroomsMax)
                            //     ? arr.push({
                            //         name: 'global.addtSearchFilters.restrooms',
                            //         fieldName: 'restrooms',
                            //         min: filters.numRestroomsMin,
                            //         max: filters.numRestroomsMax
                            //       })
                            //     : null,
                            truckParkingSpaces: filters.truckParkingSpacesMax &&
                                (filters.truckParkingSpacesMin !== minmax.truckParkingSpacesMin ||
                                    filters.truckParkingSpacesMax !== minmax.truckParkingSpacesMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.trckParkingSpaces',
                                    fieldName: 'truckParkingSpaces',
                                    min: filters.truckParkingSpacesMin,
                                    max: filters.truckParkingSpacesMax
                                })
                                : null,
                            offeringPrice: (filters.offeringPriceEnd &&
                                (filters.offeringPriceIni !== minmax.offeringPriceIni ||
                                    filters.offeringPriceEnd !== minmax.offeringPriceEnd)) ||
                                (!filters.offeringPriceEnd && filters.offeringPriceIni && filters.offeringPriceIni !== minmax.offeringPriceIni)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.offeringPrice',
                                    fieldName: 'offeringPrice',
                                    min: filters.offeringPriceIni,
                                    max: filters.offeringPriceEnd ? filters.offeringPriceEnd : minmax.offeringPriceEnd,
                                    showPlus: filters.offeringPriceEnd === minmax.offeringPriceEnd || !filters.offeringPriceEnd ? true : false
                                })
                                : null,
                            offeringPricePerArea: (filters.offeringPricePerAreaEnd &&
                                (filters.offeringPricePerAreaIni !== minmax.offeringPricePerAreaIni ||
                                    filters.offeringPricePerAreaEnd !== minmax.offeringPricePerAreaEnd)) ||
                                (!filters.offeringPricePerAreaEnd &&
                                    filters.offeringPricePerAreaIni &&
                                    filters.offeringPricePerAreaIni !== minmax.offeringPricePerAreaIni)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.offeringPricePerArea',
                                    fieldName: 'offeringPricePerArea',
                                    min: filters.offeringPricePerAreaIni,
                                    max: filters.offeringPricePerAreaEnd ? filters.offeringPricePerAreaEnd : minmax.offeringPricePerAreaEnd,
                                    showPlus: filters.offeringPricePerAreaEnd === minmax.offeringPricePerAreaEnd || !filters.offeringPricePerAreaEnd
                                        ? true
                                        : false
                                })
                                : null,
                            monthlyRent: (filters.monthlyRentMax &&
                                (filters.monthlyRentMin !== minmax.monthlyRentMin || filters.monthlyRentMax !== minmax.monthlyRentMax)) ||
                                (!filters.monthlyRentMax && filters.monthlyRentMin && filters.monthlyRentMin !== minmax.monthlyRentMin)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.monthlyRent',
                                    fieldName: 'monthlyRent',
                                    min: filters.monthlyRentMin,
                                    max: filters.monthlyRentMax ? filters.monthlyRentMax : minmax.monthlyRentMax,
                                    showPlus: filters.monthlyRentMax === minmax.monthlyRentMax || !filters.monthlyRentMax ? true : false
                                })
                                : null,
                            /* monthlyRentPerArea:
                              (filters.monthlyRentPerAreaMax &&
                                (filters.monthlyRentPerAreaMin !== minmax.monthlyRentPerAreaMin ||
                                  filters.monthlyRentPerAreaMax !== minmax.monthlyRentPerAreaMax)) ||
                                (!filters.monthlyRentPerAreaMax &&
                                  filters.monthlyRentPerAreaMin &&
                                  filters.monthlyRentPerAreaMin !== minmax.monthlyRentPerAreaMin)
                                ? arr.push({
                                  name: 'global.addtSearchFilters.monthlyRentPerArea',
                                  fieldName: 'monthlyRentPerArea',
                                  min: filters.monthlyRentPerAreaMin,
                                  max: filters.monthlyRentPerAreaMax ? filters.monthlyRentPerAreaMax : minmax.monthlyRentPerAreaMax,
                                  showPlus:
                                    filters.monthlyRentPerAreaMax === minmax.monthlyRentPerAreaMax || !filters.monthlyRentPerAreaMax
                                      ? true
                                      : false
                                })
                                : null, */
                            parkingRatio: filters.parkingRatioMax &&
                                (filters.parkingRatioMin !== minmax.parkingRatioMin || filters.parkingRatioMax !== minmax.parkingRatioMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.parkingRatio',
                                    fieldName: 'parkingRatio',
                                    min: filters.parkingRatioMin,
                                    max: filters.parkingRatioMax
                                })
                                : null,
                            /*
                            parkingSpaces:
                              filters.parkingSpacesMax &&
                              (filters.parkingSpacesMin !== minmax.parkingSpacesMin || filters.parkingSpacesMax !== minmax.parkingSpacesMax)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.parkingSpaces',
                                    fieldName: 'parkingSpaces',
                                    min: filters.parkingSpacesMin,
                                    max: filters.parkingSpacesMax
                                  })
                                : null,
                                */
                            rentableArea: (filters.rentableAreaEnd &&
                                (Math.floor(filters.rentableAreaIni) !== Math.floor(minmax.rentableAreaIni) ||
                                    Math.ceil(filters.rentableAreaEnd) !== Math.ceil(minmax.rentableAreaEnd))) ||
                                (!filters.rentableAreaEnd && filters.rentableAreaIni && filters.rentableAreaIni !== minmax.rentableAreaIni)
                                ? arr.push({
                                    name: 'global.addtSearchFilters.rentableArea',
                                    fieldName: 'rentableArea',
                                    min: filters.rentableAreaIni,
                                    max: filters.rentableAreaEnd ? filters.rentableAreaEnd : minmax.rentableAreaEnd,
                                    showPlus: filters.rentableAreaEnd === minmax.rentableAreaEnd || !filters.rentableAreaEnd ? true : false
                                })
                                : null,
                            totalArea: filters.totalAreaEnd &&
                                (filters.totalAreaIni !== minmax.totalAreaIni || filters.totalAreaEnd !== minmax.totalAreaEnd)
                                ? arr.push({
                                    name: 'buildinsDetail.availableSpace',
                                    fieldName: 'totalArea',
                                    min: filters.totalAreaIni,
                                    max: filters.totalAreaEnd
                                })
                                : null,
                            landArea: filters.landAreaEnd &&
                                (filters.landAreaIni !== minmax.landAreaMin || filters.landAreaEnd !== minmax.landAreaMax)
                                ? arr.push({
                                    name: 'global.list-your-property.totalLanArea',
                                    fieldName: 'landArea',
                                    min: filters.landAreaIni,
                                    max: filters.landAreaEnd
                                })
                                : null,
                            totalAskingPrice: filters.totalAskingPriceEnd &&
                                (filters.totalAskingPriceIni !== minmax.totalAskingPriceIni ||
                                    filters.totalAskingPriceEnd !== minmax.totalAskingPriceEnd)
                                ? arr.push({
                                    name: 'buildinsDetail.askingPrice',
                                    fieldName: 'totalAskingPrice',
                                    min: filters.totalAskingPriceIni,
                                    max: filters.totalAskingPriceEnd
                                })
                                : null,
                            askingPricePerArea: filters.askingPricePerAreaMax &&
                                (filters.askingPricePerAreaMin !== minmax.askingPricePerAreaMin ||
                                    filters.askingPricePerAreaMax !== minmax.askingPricePerAreaMax)
                                ? arr.push({
                                    name: 'buildinsDetail.askingPriceRm',
                                    fieldName: 'askingPricePerArea',
                                    min: filters.askingPricePerAreaMin,
                                    max: filters.askingPricePerAreaMax
                                })
                                : null,
                            propertySize: filters.propertySizeEnd &&
                                (filters.propertySizeIni !== minmax.propertySizeIni || filters.propertySizeEnd !== minmax.propertySizeEnd)
                                ? arr.push({
                                    name: 'global.list-your-property.propertySize',
                                    fieldName: 'propertySize',
                                    min: filters.propertySizeIni,
                                    max: filters.propertySizeEnd
                                })
                                : null,
                            monthlyRentPerArea: filters.monthlyRentPerAreaMax &&
                                (filters.monthlyRentPerAreaMin !== minmax.monthlyRentPerAreaMin ||
                                    filters.monthlyRentPerAreaMax !== minmax.monthlyRentPerAreaMax)
                                ? arr.push({
                                    name: 'buildinsDetail.monthlyRent',
                                    fieldName: 'monthlyRentPerArea',
                                    min: filters.monthlyRentPerAreaMin,
                                    max: filters.monthlyRentPerAreaMax
                                })
                                : null,
                            totalMonthlyRent: filters.totalMonthlyRentEnd &&
                                (filters.totalMonthlyRentIni !== minmax.totalMonthlyRentMin ||
                                    filters.totalMonthlyRentEnd !== minmax.totalMonthlyRentMax)
                                ? arr.push({
                                    name: 'buildinsDetail.totalMonthlyRent',
                                    fieldName: 'totalMonthlyRent',
                                    min: filters.totalMonthlyRentIni,
                                    max: filters.totalMonthlyRentEnd
                                })
                                : null,
                            numberFloor: filters.numberFloorEnd &&
                                (filters.numberFloorIni !== minmax.numberFloorIni || filters.numberFloorEnd !== minmax.numberFloorEnd)
                                ? arr.push({
                                    name: 'global.list-your-property.floors',
                                    fieldName: 'numberFloor',
                                    min: filters.numberFloorIni,
                                    max: filters.numberFloorEnd
                                })
                                : null,
                            monthlyCostPerPerson: filters.monthlyCostPerPersonMax &&
                                (filters.monthlyCostPerPersonMin !== minmax.monthlyCostPerPersonMin ||
                                    filters.monthlyCostPerPersonMax !== minmax.monthlyCostPerPersonMax)
                                ? arr.push({
                                    name: 'buildinsDetail.monthlyCostPerson',
                                    fieldName: 'monthlyCostPerPerson',
                                    min: filters.monthlyCostPerPersonMin,
                                    max: filters.monthlyCostPerPersonMax
                                })
                                : null,
                            availabilityDate: filters.availabilityDate
                                ? arr.push({
                                    name: this.i18NService.get('buildinsDetail.availabilityDate') + ': ' + filters.availabilityDate,
                                    fieldName: 'availabilityDate'
                                })
                                : null,
                            // Other Features
                            armoredCabin: filters.armoredCabin
                                ? arr.push({ name: 'global.addtSearchFilters.armoredCabin', fieldName: 'armoredCabin', otherFeatures: true })
                                : null,
                            manufacturingCondition: filters.manufacturingCondition
                                ? arr.push({
                                    name: 'global.addtSearchFilters.manufacturing',
                                    fieldName: 'manufacturingCondition',
                                    otherFeatures: true
                                })
                                : null,
                            logisticsCondition: filters.logisticsCondition
                                ? arr.push({ name: 'global.addtSearchFilters.logistics', fieldName: 'logisticsCondition', otherFeatures: true })
                                : null,
                            officeSpaceAvailable: filters.officeSpaceAvailable
                                ? arr.push({
                                    name: 'global.addtSearchFilters.officeSpaceAvailable',
                                    fieldName: 'officeSpaceAvailable',
                                    otherFeatures: true
                                })
                                : null,
                            /*fireProtectionSystem: filters.fireProtectionSystem
                              ? arr.push({ name: 'global.addtSearchFilters.fireProtectionSystem', fieldName: 'fireProtectionSystem' , otherFeatures: true})
                              : null,*/
                            blockConstruction: filters.blockConstruction
                                ? arr.push({
                                    name: 'global.addtSearchFilters.blockConstruction',
                                    fieldName: 'blockConstruction',
                                    otherFeatures: true
                                })
                                : null,
                            steelConstruction: filters.steelConstruction
                                ? arr.push({
                                    name: 'global.addtSearchFilters.steelConstruction',
                                    fieldName: 'steelConstruction',
                                    otherFeatures: true
                                })
                                : null,
                            /*hasLoadCapacity: filters.loadCapacity
                              ? arr.push({ name: 'global.addtSearchFilters.hasLoadCapacity', fieldName: 'hasLoadCapacity', value: filters.loadCapacity, otherFeatures: true  })
                              : null,
                            hasDockRatio: filters.dockRatio
                              ? arr.push({ name: 'global.addtSearchFilters.hasDockRatio', fieldName: 'hasDockRatio', value: filters.dockRatio.toFixed(2) , otherFeatures: true })
                              : null,*/
                            blockAndSteelConstruction: filters.blockAndSteelConstruction
                                ? arr.push({
                                    name: 'global.addtSearchFilters.blockAndSteelConstruction',
                                    fieldName: 'blockAndSteelConstruction',
                                    otherFeatures: true
                                })
                                : null,
                            skyLightsSpace: filters.skyLightsSpace
                                ? arr.push({
                                    name: 'global.addtSearchFilters.skyLights',
                                    fieldName: 'skyLightsSpace',
                                    otherFeatures: true
                                })
                                : null,
                            multiTenant: filters.multiTenant
                                ? arr.push({ name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant', otherFeatures: true })
                                : null,
                            singleTenant: filters.singleTenant
                                ? arr.push({ name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant', otherFeatures: true })
                                : null,
                            /*hasCarParkingSpaces: filters.carParkingSpaces
                              ? arr.push({ name: 'global.addtSearchFilters.carParkingSpaces', fieldName: 'hasCarParkingSpaces', value: filters.carParkingSpaces , otherFeatures: true })
                              : null,
                            hasTruckParkingSpaces: filters.truckParkingSpaces
                              ? arr.push({ name: 'global.addtSearchFilters.truckParkingSpaces', fieldName: 'hasTruckParkingSpaces', value: filters.truckParkingSpaces , otherFeatures: true })
                              : null,*/
                            /*industrialPark: filters.industrialPark
                              ? arr.push({ name: 'global.addtSearchFilters.industrialPark', fieldName: 'industrialPark', otherFeatures: true })
                              : null,
                            industrialZone: filters.industrialZone
                              ? arr.push({ name: 'global.addtSearchFilters.industrialZone', fieldName: 'industrialZone' , otherFeatures: true})
                              : null,*/
                            truckYard: filters.truckYard
                                ? arr.push({ name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard', otherFeatures: true })
                                : null,
                            crossDocking: filters.crossDocking
                                ? arr.push({ name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking', otherFeatures: true })
                                : null,
                            refrigerated: filters.refrigerated
                                ? arr.push({ name: 'global.addtSearchFilters.refrigerated', fieldName: 'refrigerated', otherFeatures: true })
                                : null,
                            sluiceGate: filters.sluiceGate
                                ? arr.push({ name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate', otherFeatures: true })
                                : null,
                            hasLockerRooms: filters.hasLockerRooms
                                ? arr.push({ name: 'global.addtSearchFilters.lockerRoom', fieldName: 'hasLockerRooms', otherFeatures: true })
                                : null,
                            twentyFourHourSecurity: filters.twentyFourHourSecurity
                                ? arr.push({
                                    name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                    fieldName: 'twentyFourHourSecurity',
                                    otherFeatures: true
                                })
                                : null,
                            /*hasCeilingHeight: filters.ceilingHeight
                              ? arr.push({ name: 'global.addtSearchFilters.hasCeilingHeight', fieldName: 'hasCeilingHeight', value: filters.ceilingHeight.toFixed(2), otherFeatures: true })
                      : null,*/
                            heliport: filters.heliport
                                ? arr.push({ name: 'global.addtSearchFilters.heliport', fieldName: 'heliport', otherFeatures: true })
                                : null,
                            sprinklers: filters.sprinklers
                                ? arr.push({ name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers', otherFeatures: true })
                                : null,
                            electricGenerator: filters.electricGenerator
                                ? arr.push({
                                    name: 'global.addtSearchFilters.electricGenerator',
                                    fieldName: 'electricGenerator',
                                    otherFeatures: true
                                })
                                : null,
                            /*hasParkingSpaces: filters.parkingSpaces
                              ? arr.push({ name: 'global.addtSearchFilters.hasParkingSpaces', fieldName: 'hasParkingSpaces', value: filters.parkingSpaces , otherFeatures: true})
                              : null,*/
                            plugAndPlayConditionOffice: filters.plugAndPlayConditionOffice
                                ? arr.push({
                                    name: 'global.addtSearchFilters.plugAndPlay',
                                    fieldName: 'plugAndPlayConditionOffice',
                                    otherFeatures: true
                                })
                                : null,
                            /*finishedOut: filters.finishedOut
                              ? arr.push({ name: 'global.addtSearchFilters.finishedOut', fieldName: 'finishedOut' , otherFeatures: true})
                              : null,*/
                            furnished: filters.furnishedConditionCoworking
                                ? arr.push({
                                    name: 'global.addtSearchFilters.furnished',
                                    fieldName: 'furnishedConditionCoworking',
                                    otherFeatures: true
                                })
                                : null,
                            coreAndShel: filters.coreAndShel
                                ? arr.push({ name: 'global.addtSearchFilters.coreAndShel', fieldName: 'coreAndShel', otherFeatures: true })
                                : null,
                            centralAirConditioning: filters.centralAirConditioning
                                ? arr.push({
                                    name: 'global.addtSearchFilters.centralAirConditioning',
                                    fieldName: 'centralAirConditioning',
                                    otherFeatures: true
                                })
                                : null,
                            miniSplitAirConditioning: filters.miniSplitAirConditioning
                                ? arr.push({
                                    name: 'global.addtSearchFilters.miniSplitAirConditioning',
                                    fieldName: 'miniSplitAirConditioning',
                                    otherFeatures: true
                                })
                                : null,
                            bicycleRack: filters.bicycleRack
                                ? arr.push({ name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack', otherFeatures: true })
                                : null,
                            fiberOptic: filters.fiberOptic
                                ? arr.push({ name: 'global.addtSearchFilters.fiberOptic', fieldName: 'fiberOptic', otherFeatures: true })
                                : null,
                            leedStatus: filters.leedStatus
                                ? arr.push({ name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus', otherFeatures: true })
                                : null,
                            raisedFloor: filters.raisedFloor
                                ? arr.push({ name: 'global.addtSearchFilters.raisedFloor', fieldName: 'raisedFloor', otherFeatures: true })
                                : null,
                            hourSecurity: filters.hourSecurity
                                ? arr.push({ name: 'global.addtSearchFilters.hourSecurity', fieldName: 'hourSecurity', otherFeatures: true })
                                : null,
                            neverUsed: filters.neverUsed
                                ? arr.push({ name: 'global.addtSearchFilters.neverUsed', fieldName: 'neverUsed', otherFeatures: true })
                                : null,
                            hasRestrooms: filters.hasRestrooms
                                ? arr.push({ name: 'global.addtSearchFilters.restroom', fieldName: 'hasRestrooms', otherFeatures: true })
                                : null,
                            bulletProofCabin: filters.bulletProofCabin
                                ? arr.push({
                                    name: 'global.addtSearchFilters.armoredCabin',
                                    fieldName: 'bulletProofCabin',
                                    otherFeatures: true
                                })
                                : null,
                            datacenterCondition: filters.datacenterCondition
                                ? arr.push({
                                    name: 'global.addtSearchFilters.dataCenter',
                                    fieldName: 'datacenterCondition',
                                    otherFeatures: true
                                })
                                : null,
                            flexCondition: filters.flexCondition
                                ? arr.push({ name: 'global.addtSearchFilters.flex', fieldName: 'flexCondition', otherFeatures: true })
                                : null,
                            forRetailUseConditionIndustrial: filters.forRetailUseConditionIndustrial
                                ? arr.push({
                                    name: 'global.addtSearchFilters.forRetail',
                                    fieldName: 'forRetailUseConditionIndustrial',
                                    otherFeatures: true
                                })
                                : null,
                            includeColdStorage: filters.includeColdStorage
                                ? arr.push({
                                    name: 'global.addtSearchFilters.includeStorage',
                                    fieldName: 'includeColdStorage',
                                    otherFeatures: true
                                })
                                : null,
                            coreAndShellCondition: filters.coreAndShellCondition
                                ? arr.push({
                                    name: 'global.addtSearchFilters.coreAndShel',
                                    fieldName: 'coreAndShellCondition',
                                    otherFeatures: true
                                })
                                : null,
                            fullFloorCondition: filters.fullFloorCondition
                                ? arr.push({ name: 'global.addtSearchFilters.fullFloor', fieldName: 'fullFloorCondition', otherFeatures: true })
                                : null,
                            forRetailUseConditionOffice: filters.forRetailUseConditionOffice
                                ? arr.push({
                                    name: 'global.addtSearchFilters.forRetail',
                                    fieldName: 'forRetailUseConditionOffice',
                                    otherFeatures: true
                                })
                                : null,
                            preBuildCondition: filters.preBuildCondition
                                ? arr.push({ name: 'global.addtSearchFilters.preBuild', fieldName: 'preBuildCondition', otherFeatures: true })
                                : null,
                            subLease: filters.subLease
                                ? arr.push({ name: 'global.addtSearchFilters.subLease', fieldName: 'subLease', otherFeatures: true })
                                : null,
                            inactive: filters.spotStatus == 'INACTIVE'
                                ? arr.push({ name: 'global.addtSearchFilters.inactive', fieldName: 'spotStatus', otherFeatures: true })
                                : null,
                            yearBuilt: filters.yearBuiltIni || filters.yearBuiltEnd
                                ? arr.push({
                                    name: 'global.list-your-property.yearBuilt',
                                    fieldName: 'yearBuilt',
                                    min: filters.yearBuiltIni ? filters.yearBuiltIni : 0,
                                    max: filters.yearBuiltEnd ? filters.yearBuiltEnd : 0
                                })
                                : null,
                            yearRenovated: filters.yearRenovatedIni || filters.yearRenovatedEnd
                                ? arr.push({
                                    name: 'global.list-your-property.yearRenovated',
                                    fieldName: 'yearRenovated',
                                    min: filters.yearRenovatedIni ? filters.yearRenovatedIni : 0,
                                    max: filters.yearRenovatedEnd ? filters.yearRenovatedEnd : 0
                                })
                                : null,
                            spotTitlesOrAddresses: filters.spotTitlesOrAddresses && filters.spotTitlesOrAddresses.length > 0
                                ? arr.push({
                                    name: filters.spotTitlesOrAddresses.length > 2
                                        ? ": " + filters.spotTitlesOrAddresses[0].name + ", " + filters.spotTitlesOrAddresses[1].name + ", " + filters.spotTitlesOrAddresses[2].name
                                        : filters.spotTitlesOrAddresses.length > 1
                                            ? ":  " + filters.spotTitlesOrAddresses[0].name + ", " + filters.spotTitlesOrAddresses[1].name
                                            : ": " + filters.spotTitlesOrAddresses[0].name,
                                    fieldName: 'spotTitlesOrAddresses'
                                })
                                : null,
                            barista: filters.barista
                                ? arr.push({
                                    name: 'global.addtSearchFilters.barista',
                                    fieldName: 'barista',
                                    otherFeatures: true
                                })
                                : null,
                            enhancedCleaning: filters.enhancedCleaning
                                ? arr.push({
                                    name: 'global.addtSearchFilters.enhancedCleaning',
                                    fieldName: 'enhancedCleaning',
                                    otherFeatures: true
                                })
                                : null,
                            parkingAvailable: filters.parkingAvailable
                                ? arr.push({
                                    name: 'global.addtSearchFilters.parking',
                                    fieldName: 'parkingAvailable',
                                    otherFeatures: true
                                })
                                : null,
                            petFriendly: filters.petFriendly
                                ? arr.push({
                                    name: 'global.addtSearchFilters.petFriendly',
                                    fieldName: 'petFriendly',
                                    otherFeatures: true
                                })
                                : null,
                            conferenceRoom: filters.conferenceRoom
                                ? arr.push({
                                    name: 'global.addtSearchFilters.conferenceRoom',
                                    fieldName: 'conferenceRoom',
                                    otherFeatures: true
                                })
                                : null,
                            privateOutdoorSpace: filters.privateOutdoorSpace
                                ? arr.push({
                                    name: 'global.addtSearchFilters.privateOutdoorSpace',
                                    fieldName: 'privateOutdoorSpace',
                                    otherFeatures: true
                                })
                                : null,
                            plugAndPlayConditionCoworking: filters.plugAndPlayConditionCoworking
                                ? arr.push({
                                    name: 'global.addtSearchFilters.plugAndPlay',
                                    fieldName: 'plugAndPlayConditionCoworking',
                                    otherFeatures: true
                                })
                                : null,
                            currencyType: filters.currencyType
                                ? arr.push({ name: filters.currencyType, fieldName: filters.currencyType })
                                : null,
                            waterHydrant: filters.waterHydrant
                                ? arr.push({
                                    name: 'global.addtSearchFilters.waterHydrant',
                                    fieldName: 'waterHydrant',
                                    otherFeatures: true
                                })
                                : null,
                            withinIndustrialPark: filters.withinIndustrialPark
                                ? arr.push({
                                    name: 'global.addtSearchFilters.withinIndustrialPark',
                                    fieldName: 'withinIndustrialPark',
                                    otherFeatures: true
                                })
                                : null,
                            accessToShowers: filters.accessToShowers
                                ? arr.push({
                                    name: 'global.addtSearchFilters.accessToShowers',
                                    fieldName: 'accessToShowers',
                                    otherFeatures: true
                                })
                                : null,
                            kva: filters.kvaMax && (filters.kvaMin !== minmax.kvaMin || filters.kvaMax !== minmax.kvaMax)
                                ? arr.push({
                                    name: 'global.list-your-property.kva',
                                    fieldName: 'kva',
                                    min: filters.kvaMin,
                                    max: filters.kvaMax
                                })
                                : null
                        };
                        this.searchTags = arr;
                        return [2 /*return*/, tslib_1.__spread(arr)];
                }
            });
        });
    };
    SearchTagsService.prototype.removeTagFromModels = function (fieldName, index, searchModel, listingSearchDto, checkboxValues) {
        this.removeTagFromSearchModel(fieldName, index, searchModel);
        this.removeTagFromSearchDTO(listingSearchDto, fieldName, checkboxValues);
    };
    /**
     * Remove the tag from the search model passed as parameter.
     * Remove the tag from the searchTags array object based on the index number.
     */
    SearchTagsService.prototype.removeTagFromSearchModel = function (fieldName, index, searchModel) {
        if (fieldName === 'propertyType' || fieldName === 'officeType' || fieldName === 'coworkingPropertyType') {
            searchModel.propertyType = null;
            searchModel.propertySubTypes = null;
            searchModel.propertySubtypeIds = null;
            this.listingService.propertySubTypesFromHomepage = [];
        }
        if (fieldName === 'sale' || fieldName === 'lease' || this.isCoworkingTag(fieldName)) {
            searchModel.listingType = null;
        }
        if (fieldName === 'keyword') {
            searchModel.keywordText = null;
        }
        if (fieldName === 'market') {
            searchModel.marketIds = null;
            searchModel.fakeMarketId = null;
        }
        if (fieldName === 'spotSet') {
            searchModel.spotSetId = null;
            searchModel.spotSetName = null;
        }
        if (fieldName === 'buildingName') {
            searchModel.buildingName = null;
        }
        if (fieldName === 'cityState') {
            searchModel.cityId = null;
            searchModel.stateId = null;
        }
        if (fieldName === 'neighborhood') {
            searchModel.neighborhood = null;
        }
        if (fieldName === 'coworkingType' || this.isCoworkingTag(fieldName)) {
            searchModel.coworkingType = null;
        }
        if (fieldName === 'positions' || this.isCoworkingTag(fieldName)) {
            searchModel.coworkingPositions = null;
        }
        if (fieldName === 'USD' || fieldName === 'MXN') {
            searchModel.currencyType = null;
        }
        this.searchTags.splice(index, 1);
    };
    SearchTagsService.prototype.cleanCoworkingTagsFromSearchDTO = function (listingSearchDto) {
        // remove coworking related tags
        listingSearchDto['coworkingType'] = null;
        listingSearchDto['coworkingPositions'] = null;
    };
    SearchTagsService.prototype.isCoworkingTag = function (fieldName) {
        return fieldName === 'coworking' || fieldName === 'coworkingPropertyType';
    };
    SearchTagsService.prototype.removeTagFromSearchDTO = function (listingSearchDto, fieldName, checkboxValues) {
        if (fieldName.split('&&').length > 1) {
            var id_1 = fieldName.split('&&')[0];
            listingSearchDto.propertySubTypes.forEach(function (item, index) {
                if (item.id == id_1) {
                    listingSearchDto.propertySubTypes.splice(index, 1);
                    return;
                }
            });
            if (listingSearchDto.propertySubTypes) {
                listingSearchDto.propertySubtypeIds = listingSearchDto.propertySubTypes.map(function (t) { return t.id; });
            }
            else {
                listingSearchDto.propertySubtypeIds = null;
            }
        }
        if (fieldName === 'buildingName') {
            listingSearchDto.buildingId = null;
            listingSearchDto.buildingName = null;
            return listingSearchDto;
        }
        if (fieldName === 'spotSet') {
            listingSearchDto.spotSetId = null;
            listingSearchDto.spotSetName = null;
        }
        if (fieldName === 'propertyType' || fieldName === 'officeType' || fieldName === 'coworkingPropertyType') {
            listingSearchDto.buildingTypes = [];
            listingSearchDto.propertySubTypes = null;
            listingSearchDto.propertySubtypeIds = null;
            if (fieldName !== 'coworkingPropertyType') {
                return listingSearchDto;
            }
        }
        if (fieldName === 'sale' || fieldName === 'lease' || this.isCoworkingTag(fieldName)) {
            if (this.isCoworkingTag(fieldName)) {
                this.cleanCoworkingTagsFromSearchDTO(listingSearchDto);
            }
            this.deleteSearchTagsBasedOnListingType(listingSearchDto, checkboxValues);
            listingSearchDto.listingLeaseOrSale = [];
            return listingSearchDto;
        }
        if (fieldName === 'cityState') {
            listingSearchDto.cityId = null;
            listingSearchDto.stateId = null;
            return listingSearchDto;
        }
        if (fieldName === 'neighborhood') {
            listingSearchDto.neighborhood = null;
            return listingSearchDto;
        }
        if (fieldName.toLocaleLowerCase() === 'spotsnearme') {
            var attrToBeRemove = ['latitude', 'longitude', 'radius'];
            listingSearchDto = this.commonService.removeObjectAttr(listingSearchDto, attrToBeRemove);
        }
        if (fieldName == 'coworkingType') {
            listingSearchDto['coworkingType'] = null;
            return listingSearchDto;
        }
        if (fieldName == 'positions') {
            listingSearchDto['positions'] = null;
            return listingSearchDto;
        }
        if (fieldName == 'keyword') {
            listingSearchDto.keyword = undefined;
            return listingSearchDto;
        }
        if (fieldName == 'market') {
            listingSearchDto.marketIds = undefined;
            listingSearchDto.fakeMarketId = undefined;
            return listingSearchDto;
        }
        if (checkboxValues) {
            if (this.checkRangeTags(fieldName, listingSearchDto, checkboxValues)) {
                return listingSearchDto;
            }
        }
        if (fieldName == 'spotTitlesOrAddresses') {
            listingSearchDto['spotTitlesOrAddresses'] = null;
            return listingSearchDto;
        }
        if (fieldName === 'USD' || fieldName === 'MXN') {
            listingSearchDto.currencyType = null;
            return listingSearchDto;
        }
        listingSearchDto[fieldName] = false;
        return listingSearchDto;
    };
    /**
     * It is not necessary for some fields to be shown for a specific type of listing.
     */
    SearchTagsService.prototype.deleteSearchTagsBasedOnListingType = function (listingSearchDto, checkBoxValues) {
        var tagsToBeRemoved = [];
        var listingType = listingSearchDto.listingLeaseOrSale && listingSearchDto.listingLeaseOrSale.length > 0
            ? listingSearchDto.listingLeaseOrSale[0]
            : null;
        if (listingType == null) {
            return;
        }
        if (listingType == ListingOfferType.Sale) {
            listingSearchDto = this.removeTagFromSearchDTO(listingSearchDto, 'monthlyRent', checkBoxValues);
            listingSearchDto = this.removeTagFromSearchDTO(listingSearchDto, 'monthlyRentPerArea', checkBoxValues);
            tagsToBeRemoved = ['monthlyRent', 'monthlyRentPerArea'];
        }
        else if (listingType == ListingOfferType.Lease) {
            listingSearchDto = this.removeTagFromSearchDTO(listingSearchDto, 'offeringPrice', checkBoxValues);
            listingSearchDto = this.removeTagFromSearchDTO(listingSearchDto, 'offeringPricePerArea', checkBoxValues);
            listingSearchDto.offeringPriceIni = null;
            listingSearchDto.offeringPricePerAreaIni = null;
            tagsToBeRemoved = ['totalPrice', 'totalPricePerArea'];
        }
        else if (listingType == ListingOfferType.Coworking) {
            tagsToBeRemoved = ['coworkingPositions'];
            this.cleanCoworkingTagsFromSearchDTO(listingSearchDto);
        }
        this.searchTags = this.searchTags.filter(function (tag) {
            return !tagsToBeRemoved.includes(tag.fieldName);
        });
    };
    SearchTagsService.prototype.getMetatagTitleBasedOnCurrentTags = function () {
        var _this = this;
        var cityStateTag = 'cityState';
        var officeType = 'officeType';
        var leaseType = 'lease';
        var industrialType = 'propertyType';
        var saleType = 'sale';
        var interpolationParams = { state: '', propType: '', listingType: '' };
        var mainFields = [cityStateTag, officeType, leaseType, saleType, industrialType];
        var title = this.i18NService.getMetatagTranslation('metatags.searchPage.title.single');
        var filteredTags = this.searchTags.filter(function (tag) { return mainFields.includes(tag.fieldName); });
        if (filteredTags) {
            filteredTags.forEach(function (tag) {
                switch (tag.fieldName) {
                    case cityStateTag:
                        interpolationParams.state = tag.name;
                        break;
                    case officeType:
                    case industrialType: {
                        var propertyType = _this.i18NService.getMetatagTranslation(tag.name);
                        interpolationParams.propType = propertyType;
                        break;
                    }
                    case leaseType:
                    case saleType: {
                        var listingType = _this.i18NService.getMetatagTranslation(tag.name);
                        interpolationParams.listingType = _this.commonService.capitalizeFirstLetter(listingType);
                        break;
                    }
                }
            });
            var titleKey = null;
            if (interpolationParams.state && interpolationParams.propType && interpolationParams.listingType) {
                titleKey = 'metatags.searchPage.title.statePropertyTypeListingType';
            }
            else if (interpolationParams.state && interpolationParams.listingType) {
                titleKey = 'metatags.searchPage.title.stateListingType';
            }
            else if (interpolationParams.state && interpolationParams.propType) {
                titleKey = 'metatags.searchPage.title.statePropType';
            }
            else if (interpolationParams.state) {
                titleKey = 'metatags.searchPage.title.singleState';
            }
            if (titleKey) {
                title = this.i18NService.getMetatagTranslation(titleKey, interpolationParams);
            }
        }
        return title;
    };
    SearchTagsService.prototype.isCoworkingPropertyType = function (filters) {
        return filters.buildingTypes && filters.buildingTypes[0] == ListingOfferType.Coworking;
    };
    SearchTagsService.prototype.mapCoworkingType = function (coworkingType) {
        if (CoworkingSpaceType.PRIVATE_OFFICE == coworkingType) {
            return this.i18NService.get('buildinsDetail.privateOffice');
        }
        if (CoworkingSpaceType.DEDICATED_WORKSPACE == coworkingType) {
            return this.i18NService.get('buildinsDetail.dedicatedWorkspace');
        }
        if (CoworkingSpaceType.OPEN_WORKSPACE == coworkingType) {
            return this.i18NService.get('buildinsDetail.openWorkspace');
        }
        if (CoworkingSpaceType.FULL_FLOOR_OFFICES == coworkingType) {
            return this.i18NService.get('buildinsDetail.fullFloorOffices');
        }
    };
    return SearchTagsService;
}());
export { SearchTagsService };
