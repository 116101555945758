<div id="videoContainer" [ngClass]="{ 'video-popup-container': fromPopup }">
  <div class="video-popup-header" *ngIf="fromPopup">
    <label class="building-title"> {{ spotTitle }} </label>
    <div class="flex-align-content-end">
      <fa-icon class="close-icon" (click)="closeModal()" [icon]="closeIcon"></fa-icon>
    </div>
  </div>

  <ion-row *ngIf="showVideoComponent && videoURLEmbedded" id="videoTour" class="aspect-ratio-16-9">
    <iframe
      id="videoTourIFrame"
      #videoTourIFrame
      (load)="onLoad('')"
      width="100%"
      [src]="videoURLEmbedded | secureDom"
      frameborder="0"
      fs="1"
      allowfullscreen
      style="position:absolute;top:0;left:0;height: 100%;visibility: hidden;"
    ></iframe>
  </ion-row>
  <br />

  <ion-row *ngIf="showVideoComponent && videoURLEmbedded2" id="videoTour2" class="aspect-ratio-16-9">
    <iframe
      id="videoTourIFrame2"
      #videoTourIFrame2
      (load)="onLoad('2')"
      width="100%"
      [src]="videoURLEmbedded2 | secureDom"
      frameborder="0"
      fs="1"
      allowfullscreen
      style="position:absolute;top:0;left:0;height: 100%;visibility: hidden;"
    ></iframe>
  </ion-row>
  <ion-row *ngIf="!showVideoComponent">
    <label class="comments-field" translate="global.videoNotFound">Video not found</label>
  </ion-row>
</div>
