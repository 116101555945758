import * as tslib_1 from "tslib";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { BrokersService } from '../services/brokers.service';
import { RequestInformationType } from '@app/models/request-information.model';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { MetaService } from '@app/core/seo';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
var BrokerDetailsComponent = /** @class */ (function () {
    function BrokerDetailsComponent(activatedRoute, i18NService, breakpointObserver, brokersService, platformConfigHelper, imageService, popOverCtrlHelper, routingHelper, metaService, userActivityService, analyticsService, deviceDetector, _location) {
        this.activatedRoute = activatedRoute;
        this.i18NService = i18NService;
        this.breakpointObserver = breakpointObserver;
        this.brokersService = brokersService;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.popOverCtrlHelper = popOverCtrlHelper;
        this.routingHelper = routingHelper;
        this.metaService = metaService;
        this.userActivityService = userActivityService;
        this.analyticsService = analyticsService;
        this.deviceDetector = deviceDetector;
        this._location = _location;
        this.displayScrollToTop = false;
        this.searching = false;
        this.showEmail = true;
        this.showWhatsapp = true;
        this.cookieAcceptanceName = 'SIILA-cookie-usage-acceptance';
    }
    BrokerDetailsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
                this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
                this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
                if (this.isDesktop.length) {
                    this.currentDevice = this.isDesktop;
                }
                else if (this.isMobilePhone.length) {
                    this.currentDevice = this.isMobilePhone;
                }
                else if (this.isTablet.length) {
                    this.currentDevice = this.isTablet;
                }
                this.screenWidth = window.innerWidth;
                this.sharedIcon = faShareAlt;
                this.findTotalAgentCards();
                this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
                    _this.isMobile = result.matches;
                });
                this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
                this.getParamsUrl();
                return [2 /*return*/];
            });
        });
    };
    BrokerDetailsComponent.prototype.getParamsUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.activatedRoute.params.subscribe(function (paramMap) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _a, error_1;
                    var _this = this;
                    return tslib_1.__generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                this.brokerId = paramMap.brokerId;
                                this.brokersService.getMetadataById(this.brokerId).then(function (brokerMetadata) {
                                    _this.metaService.setBrokerMetaTagsLight(brokerMetadata);
                                    window.prerenderReady = true;
                                });
                                this.registerUserActivity(UserActivityType.PAGEVIEW_BROKER, this.brokerId);
                                this.spotBuildingSearchRequest = { brokerId: this.brokerId };
                                this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM'];
                                _a = this;
                                return [4 /*yield*/, this.brokersService.getBrokerURL(this.brokerId)];
                            case 1:
                                _a.brokerDetailsURL = _b.sent();
                                if (this.brokerDetailsURL) {
                                    if (this.i18NService.currentUrlContainsCurrentLanguage()) {
                                        this.brokerDetailsURL.detailsURL = this.i18NService.addCurrentLanguageToPath(this.brokerDetailsURL.detailsURL);
                                    }
                                    this._location.go(this.brokerDetailsURL.detailsURL);
                                }
                                this.brokersService.getById(this.brokerId).then(function (broker) {
                                    _this.brokerDTO = broker;
                                    _this.brokerBIO = _this.brokerDTO.bio ? _this.i18NService.getTranslation(_this.brokerDTO.bio) : null;
                                    _this.brokersService.getSpotTitlesByBrokerId(_this.brokerId).then(function (result) {
                                        _this.spotBuildingNames = result;
                                        //this.metaService.setBrokerMetaTags(this.brokerDTO, this.brokerBIO, this.spotBuildingNames);
                                    });
                                    var brokerHeader = 'Header_0.png';
                                    if (_this.brokerDTO.brokerHeader) {
                                        brokerHeader = _this.brokerDTO.brokerHeader;
                                    }
                                    _this.brokerHeaderURL = "../../../assets/images/broker-headers/" + brokerHeader;
                                });
                                return [3 /*break*/, 3];
                            case 2:
                                error_1 = _b.sent();
                                console.error('Error : ', error_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    BrokerDetailsComponent.prototype.onResize = function (event) {
        this.screenWidth = event.target.innerWidth;
        this.findTotalAgentCards();
    };
    BrokerDetailsComponent.prototype.navigateToCompany = function () {
        if (this.brokerDTO.companyProfile) {
            this.routingHelper.navigateToCompanyDetailsPage(this.brokerDTO.companyId);
        }
    };
    BrokerDetailsComponent.prototype.isFooterVisible = function (event) {
        this.displayScrollToTop = event.visible;
    };
    BrokerDetailsComponent.prototype.findTotalAgentCards = function () {
        this.visibleAgentCards = 3;
        if (this.screenWidth < 1024) {
            this.visibleAgentCards = 1;
        }
        else if (this.screenWidth < 1200) {
            this.visibleAgentCards = 2;
        }
        //this.updateDots();
    };
    BrokerDetailsComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    BrokerDetailsComponent.prototype.buildTitleMetatag = function (broker) {
        var metaTagTitle = '';
        var brokerTitleTag = broker.firstNameAndLastName;
        metaTagTitle = brokerTitleTag + " | SiiLA SPOT";
        // KeyWord Metatag
        var keywordsMetaTag = '';
        var keywordsMetaTags = [];
        keywordsMetaTags.push(brokerTitleTag);
        keywordsMetaTag = keywordsMetaTags.join();
        return { metaTagTitle: metaTagTitle, keywordsMetaTag: keywordsMetaTag };
    };
    BrokerDetailsComponent.prototype.setBrokerMetaTags = function () {
        var detailsMetaTags = this.metaService.buildBrokerMetatag(this.brokerDTO, this.spotBuildingNames);
        var fullTitleText = detailsMetaTags.metaTagTitle;
        var keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
        var descText = this.brokerBIO ? this.removeHTMLCode(this.i18NService.getTranslation(this.brokerBIO)) : '';
        var locale = this.i18NService.getCurrentLocale();
        var brokerTitle = this.i18NService.getTranslation(this.brokerDTO.firstNameAndLastName);
        var fullDetailsURL = window.location.href;
        var tags = [
            //change to be same as title
            { name: 'name', content: encodeURIComponent(fullTitleText) },
            //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
            { name: 'og:locale', content: [locale] },
            { name: 'keywords', content: keywordsMetaTag },
            { name: 'title', content: [fullTitleText] },
            {
                name: 'mobile-web-app-title',
                content: encodeURIComponent(fullTitleText)
            },
            { name: 'description', content: [descText] },
            { name: 'author', content: 'SiiLA' },
            { name: 'og:url', content: "" + encodeURI(fullDetailsURL) },
            { name: 'og:site_name', content: 'SiiLA SPOT' },
            { name: 'og:type', content: 'article' },
            { name: 'og:title', content: [brokerTitle] },
            { name: 'og:description', property: 'og:description', content: descText },
            { name: 'og:site_url', content: "" + encodeURI(fullDetailsURL) },
            {
                name: 'og:image',
                property: 'og:image',
                content: this.brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.brokerDTO.photoId))
                    : null
            },
            {
                name: 'image',
                content: this.brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.brokerDTO.photoId))
                    : null
            },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:title', content: [brokerTitle] },
            {
                name: 'twitter:image',
                content: this.brokerDTO.photoId != undefined
                    ? encodeURI("" + environment.spotServerUrl + this.getMediumImg(this.brokerDTO.photoId))
                    : null
            },
            { name: 'twitter:description', content: descText }
        ];
        this.metaService.setDynamicTags(tags, fullTitleText);
    };
    BrokerDetailsComponent.prototype.removeHTMLCode = function (htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    };
    BrokerDetailsComponent.prototype.sendMessage = function (event) {
        var sentEmailTo = [];
        sentEmailTo.push(this.brokerDTO.userEmail);
        var reqInfoInput = {
            requestTitle: this.brokerDTO.firstNameAndLastName,
            brokerRequest: true,
            sentEmailTo: sentEmailTo,
            requestType: RequestInformationType.REQ_BROKER
        };
        this.popOverCtrlHelper.openRequestInformationPopup(event, reqInfoInput);
    };
    BrokerDetailsComponent.prototype.shareCompany = function (event) {
        var url = window.location.href;
        var sharedTitle = this.brokerDTO.firstNameAndLastName;
        var modalTitle = this.i18NService.get('shareBrokerProfile');
        var input = { url: url, sharedTitle: sharedTitle, modalTitle: modalTitle };
        this.userActivityService.trackBrokerShare(this.brokerDTO.brokerId);
        this.popOverCtrlHelper.openSocialMediaPopup(event, input);
    };
    BrokerDetailsComponent.prototype.getDefaultTranslation = function (translationArtifact) {
        try {
            return this.i18NService.getTranslation(translationArtifact);
        }
        catch (e) {
            console.error(e);
        }
    };
    BrokerDetailsComponent.prototype.openEmail = function (event) {
        event.stopImmediatePropagation();
        if (!this.showEmail) {
            window.location.href = "mailto:" + this.brokerDTO.userEmail;
        }
        this.showEmail = false;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL, this.brokerId);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
        return false;
    };
    BrokerDetailsComponent.prototype.openWhatsapp = function (event) {
        event.stopImmediatePropagation();
        this.showWhatsapp = false;
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP, this.brokerId);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
        return false;
    };
    BrokerDetailsComponent.prototype.registerUserActivity = function (userActivityType, brokerId) {
        var userActivity = {
            module: userActivityType,
            brokerId: brokerId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    BrokerDetailsComponent.prototype.checkCookiesUsageAcceptance = function () {
        var cookieAcceptance = false;
        cookieAcceptance = !!window.localStorage.getItem(this.cookieAcceptanceName);
        return cookieAcceptance;
    };
    return BrokerDetailsComponent;
}());
export { BrokerDetailsComponent };
