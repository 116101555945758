import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RegisterBuildingService {
  propertyName: any = null;
  comments: any = null;
  buildingId: any = null;
  offeredByCompany: any = null;

  constructor(private http: HttpClient) {}

  clearDetail() {
    this.propertyName = null;
    this.buildingId = null;
  }
}
