<ion-content no-bounce class="register-ion-grid">
  <ion-row class="register-ionic-row" style="overflow-y: auto;">
    <h4 class="signup-heading">
      Change your password
    </h4>

    <form [formGroup]="registerForm" class="width-100" name="registerForm" autocomplete="off">
      <div formGroupName="emailGroup">
        <ion-input
          id="password"
          class="input-border"
          name="password"
          type="password"
          [ngClass]="{
            'red-border': !registerForm.get('emailGroup.password').pristine && registerForm.errors?.passwordLength
          }"
          min="6"
          formControlName="password"
          placeholder="{{ 'global.register.password' | translate }}"
        >
        </ion-input>

        <ion-input
          id="confirmPass"
          class="input-border"
          type="password"
          min="6"
          [ngClass]="{
            'red-border': !registerForm.get('emailGroup.confirmPass').pristine && registerForm.errors?.passwordMatch
          }"
          name="confirmPass"
          formControlName="confirmPass"
          placeholder="{{ 'global.register.confirmPassword' | translate }}"
        >
        </ion-input>
      </div>

      <div class="flex-row align-end">
        <button
          class="siila-submit login-btn block-btn"
          mat-raised-button
          (click)="changePassword()"
          [disabled]="
            registerForm.errors?.required || registerForm.errors?.passwordLength || registerForm.errors?.passwordMatch
          "
        >
          CHANGE PASSWORD
        </button>
      </div>
    </form>
  </ion-row>
</ion-content>
