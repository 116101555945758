import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class PlatformHelperService {
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  isIosSafari = () => {
    return (
      this.deviceDetectorService.os.toLowerCase() === 'ios' &&
      this.deviceDetectorService.browser.toLowerCase() === 'safari'
    );
  };

  isIosChrome = () => {
    return (
      this.deviceDetectorService.os.toLowerCase() === 'ios' &&
      this.deviceDetectorService.browser.toLowerCase() === 'chrome'
    );
  };

  isInStandaloneMode = () =>
    ('standalone' in window.navigator && (window.navigator as any).standalone) ||
    window.matchMedia('(display-mode: standalone)').matches;

  shouldDisplayIosPrompt = () => {
    return this.isIosSafari() && !this.isInStandaloneMode() && !this.isIosChrome();
  };

  shouldDisplayChromePrompt = () => {
    return this.isIosChrome() && !this.isInStandaloneMode();
  };

  isDesktopMode = () => {
    return this.deviceDetectorService.isDesktop();
  };

  Defaults = () => {
    return {
      imagePlaceholder: 'assets/placeholder-image.png'
    };
  };
}
