import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class DownloadFileService {
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  downloadPDF(apiName: string, param: string, isSafariIos?: boolean) {
    if (isSafariIos || window.matchMedia('(display-mode: standalone)').matches) {
      if (this.deviceDetectorService.os.toLowerCase() === 'android') {
        var link = document.createElement('a');
        link.href = apiName + param;
        link.target = '_blank';
        link.dispatchEvent(new MouseEvent('click'));
      } else {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', apiName + param, true);
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
          if (this.status == 200) {
            var myBlob = this.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(myBlob);
            link.target = '_blank';
            link.download = param;
            link.click();
          }
        };
      }

      xhr.send();
    } else {
      var link = document.createElement('a');
      link.href = apiName + param;
      //link.download = fileName;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    }
  }
}
