import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base-service';
import { SpotSearchDTO } from '../../models/spot-search.model';
import { CommonGenericService } from '../../core/common.service';

@Injectable()
export class SpotSearchService extends BaseService {
  private spotSearchAPI: string = '/api/spot-search/';

  constructor(private http: HttpClient) {
    super();
  }

  async save(dto: SpotSearchDTO) {
    return await this.http
      .post(this.spotSearchAPI, dto, {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return val.body;
        })
      )
      .toPromise();
  }

  async update(dto: SpotSearchDTO) {
    return await this.http
      .put(this.spotSearchAPI, dto, {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return val.body;
        })
      )
      .toPromise();
  }

  async getAll() {
    return await this.http.get(this.spotSearchAPI).toPromise();
  }
}
