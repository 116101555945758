<div class="listing-header">
  <ion-label class="nearby-listing-header" *ngIf="nearbyListings.length">
    <span localize [textValue]="selectedBuildingName"></span>
    <span
      *ngIf="nearbyListings.length === 1"
      [translate]="'global.singleListingAvailable'"
      [translateParams]="{ listings: nearbyListings.length }"
    >
    </span>

    <span
      *ngIf="nearbyListings.length > 1"
      [translate]="'global.pluralListingAvailable'"
      [translateParams]="{ listings: nearbyListings.length }"
    >
    </span>
  </ion-label>
</div>

<div class="nearby-listing-card">
  <app-listing-card
    *ngFor="let nearbyListing of nearbyListings"
    [activeListingIds]="nearbyListing.listingIds"
    [listing]="nearbyListing"
    [searchCriteria]="searchCriteria.searchDTO"
    [searchTags]="searchTags"
    [checkBoxValues]="searchCriteria.checkBoxValues"
    [lazyLoadImages]="true"
    [cardImgWithPadding]="true"
  >
  </app-listing-card>
</div>
