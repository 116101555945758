<mat-card class="mat-card" (click)="openDetailNews(article.id, article)">
  <img
    mat-card-image
    style="margin-bottom: 15px !important; aspect-ratio: 4/3;"
    alt="story"
    [src]="article?.headerImage?.fullPath ? headerImageNews(article?.headerImage?.fullPath) : defaultImage"
  />
  <div class="blue-spacer"></div>
  <p class="article-title">{{ translateText(article.headline) }}</p>
  <div class="bottom">
    <p class="source-date">
      {{ formatDate(article.publishDate, 'MM/dd/yyyy') }}
    </p>
  </div>
</mat-card>
