/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spot-detail-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../detail/buildings-detail.component.ngfactory";
import * as i3 from "../detail/buildings-detail.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/create-spot-buildings.service";
import * as i6 from "../../../core/image.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../core/i18n.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../core/listings/nearby-listing.service";
import * as i11 from "../../services/spot-buildings.service";
import * as i12 from "../../../core/helpers/property-type-helper.service";
import * as i13 from "../../../core/common.service";
import * as i14 from "../../../core/helpers/popover-ctrl-helper.service";
import * as i15 from "../../services/spaces.service";
import * as i16 from "../../../shared/pipes/number-pipe";
import * as i17 from "../../../core/helpers/platform-helper.service";
import * as i18 from "../../../core/helpers/routing-helper.service";
import * as i19 from "../../../core/auth/auth.service";
import * as i20 from "../../../core/seo/meta.service";
import * as i21 from "../../../google-analytics/analytics-service";
import * as i22 from "ngx-device-detector";
import * as i23 from "../../../core/user-activity/user-activity.service";
import * as i24 from "../../../shared/services/homepageapp.service";
import * as i25 from "../../../shared/login/login-form-dialog.service";
import * as i26 from "@ionic/angular";
import * as i27 from "../detail-promoted/buildings-detail-promoted.component.ngfactory";
import * as i28 from "../detail-promoted/buildings-detail-promoted.component";
import * as i29 from "@angular/cdk/layout";
import * as i30 from "./spot-detail-container.component";
var styles_SpotDetailContainerComponent = [i0.styles];
var RenderType_SpotDetailContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpotDetailContainerComponent, data: {} });
export { RenderType_SpotDetailContainerComponent as RenderType_SpotDetailContainerComponent };
function View_SpotDetailContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "buildings-detail", [], null, null, null, i2.View_BuildingsDetailComponent_0, i2.RenderType_BuildingsDetailComponent)), i1.ɵdid(3, 114688, null, 0, i3.BuildingsDetailComponent, [i4.ActivatedRoute, i5.CreateSpotBuildingsService, i6.ImageService, i7.DatePipe, i8.I18nService, i9.MatDialog, i10.NearbyListingSvc, i11.SpotBuildingsService, i12.PropertyTypeHelperService, i13.CommonGenericService, i14.PopoverCtrlHelper, i4.Router, i15.SpotBuildingSpaceService, i16.LocalNumberPipe, i17.PlatformHelperService, i18.RoutingHelperService, i19.AuthenticationService, i20.MetaService, i21.AnalyticsService, i22.DeviceDetectorService, i23.UserActivityService, i24.HomePageAppService, i25.LoginFormDialogService, i26.ToastController], { spotBuildingInput: [0, "spotBuildingInput"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buildingDetail; _ck(_v, 3, 0, currVal_0); }, null); }
function View_SpotDetailContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "buildings-detail-promoted", [], null, null, null, i27.View_BuildingsDetailPromotedComponent_0, i27.RenderType_BuildingsDetailPromotedComponent)), i1.ɵdid(3, 4308992, null, 0, i28.BuildingsDetailPromotedComponent, [i4.ActivatedRoute, i5.CreateSpotBuildingsService, i6.ImageService, i8.I18nService, i9.MatDialog, i29.BreakpointObserver, i10.NearbyListingSvc, i11.SpotBuildingsService, i12.PropertyTypeHelperService, i13.CommonGenericService, i14.PopoverCtrlHelper, i4.Router, i15.SpotBuildingSpaceService, i16.LocalNumberPipe, i17.PlatformHelperService, i18.RoutingHelperService, i19.AuthenticationService, i20.MetaService, i23.UserActivityService, i21.AnalyticsService, i24.HomePageAppService], { spotBuildingInput: [0, "spotBuildingInput"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    IsPromoted!\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buildingDetail; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SpotDetailContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "spotdetailcontainer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotDetailContainerComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpotDetailContainerComponent_2)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSpotFetched && !_co.isPromoted); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.isSpotFetched && _co.isPromoted); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_SpotDetailContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spot-detail-container", [], null, null, null, View_SpotDetailContainerComponent_0, RenderType_SpotDetailContainerComponent)), i1.ɵdid(1, 114688, null, 0, i30.SpotDetailContainerComponent, [i4.ActivatedRoute, i20.MetaService, i5.CreateSpotBuildingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpotDetailContainerComponentNgFactory = i1.ɵccf("app-spot-detail-container", i30.SpotDetailContainerComponent, View_SpotDetailContainerComponent_Host_0, {}, {}, []);
export { SpotDetailContainerComponentNgFactory as SpotDetailContainerComponentNgFactory };
