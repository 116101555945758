import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
  Output
} from '@angular/core';

import {} from 'googlemaps';
import * as MarkerClusterer from '@google/markerclusterer';
import { MapControlHelperService } from '../../helpers';
import { GeoLocationService } from '@app/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonGenericService } from '@app/core/common.service';
import { GenericMapService } from '@app/core/generic-map.service';

@Component({
  selector: 'app-duplomap',
  templateUrl: './duplo-map.component.html',
  styleUrls: ['./duplo-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DuploMapComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('duploMap', { static: false }) mapElement: ElementRef;
  @Input() markers: any;
  @Input() options: any;
  @Input() clientPosition: any;
  @Input() mapTypeToggle: boolean;
  @Input() showInCard?: boolean;
  @Input() building?: any;
  @Input() isSpotNearMe: boolean;

  @Output() geopositionEmitter?: EventEmitter<any> = new EventEmitter<any>();
  @Output() callbackEmitter?: EventEmitter<any> = new EventEmitter<any>();

  map: any;
  mapProperties: any;
  clusterer: any;
  trafficLayer: any;
  bounds: google.maps.LatLngBounds;
  isMobile: boolean;

  private bluePin: string = `\/assets/maps/newlocationdefault2.png`;
  private orangePin: string = `\/assets/maps/newlocationactive2.png`;
  private DEFAULT_INIT_ZOOM: number = 14;
  private isMarkerFromListingDetail: boolean = false;

  constructor(
    private mapControlService: MapControlHelperService,
    private geolocationService: GeoLocationService,
    private breakpointObserver: BreakpointObserver,
    private commonService: CommonGenericService,
    private commonMapSvc: GenericMapService
  ) {}

  ngOnInit(): void {
    this.bounds = new google.maps.LatLngBounds();
    this.mapProperties = {
      center: { lat: 19.42847, lng: -99.20066 },
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 7,
      tilt: 45,
      minZoom: 3,
      maxZoom: 18,
      rotateControl: true,
      gestureHandling: 'cooperative',
      streetViewControl: true,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID],
        position: google.maps.ControlPosition.TOP_LEFT
      }
    };
    this.detectScreenSizeBreakpoint();
  }

  ngAfterViewInit(): void {
    this.initAndRenderMap();
  }

  ngOnChanges(changes: any): void {
    if (changes.markers && !changes.markers.firstChange) {
      this.clearMarkers(changes.markers.previousValue);
      this.markers = changes.markers.currentValue;
      this.ngAfterViewInit();
    }
  }

  clearMarkers(markers: any[]) {
    if (!markers) {
      return;
    }

    markers.forEach(m => {
      m.setMap(null);
    });
  }

  renderMap() {
    setTimeout(() => {
      this.drawMarkers();
    }, 500);
  }

  drawMarkers() {
    const isSingleMarker: boolean = this.hasSingleMarker();
    this.bounds = new google.maps.LatLngBounds();
    if (this.markers) {
      if (this.markers instanceof Array) {
        const nearbyMarkers: any[] = this.commonMapSvc.getAllNearbyListing(this.markers, 'isCurrentListing');
        this.markers.forEach(m => {
          m.setMap(this.map);
          this.bounds.extend(m.getPosition());
        });
        this.createCluster(nearbyMarkers);
      } else {
        if (this.options.singleMarker && this.markers.lat && this.markers.lng) {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(this.markers),
            map: this.map,
            icon: this.building ? this.orangePin : this.bluePin
          });
          if (this.building) {
            let currentActiveInfoWindow: any = this.commonService.createMapInfoWindow(this.building);
            currentActiveInfoWindow.set('marker', marker);
            currentActiveInfoWindow.open(marker.getMap(), marker);
            google.maps.event.addListener(marker, 'click', () => {
              currentActiveInfoWindow.open(marker.getMap(), marker);
            });
          }
          this.bounds.extend(marker.getPosition());
        }
      }

      if (!this.options.ignoreZoom) {
        if (isSingleMarker) {
          this.map.fitBounds(this.bounds, 0);
          this.setZoomForSingleMarker();
        } else {
          this.setMapFitBounds();
        }
      } else {
        this.map.fitBounds(this.bounds, 0);
      }
    }
  }

  setupControls(map: google.maps.Map) {
    if (!this.isMobile && !this.showInCard) {
      this.mapControlService.ZoomControls(map);
    }

    if (this.options.geopositionControl) {
      //this.GeoLocationControls(map, this.geopositionEmitter);
    }
  }

  rotate90() {
    const heading = this.map.getHeading() || 0;
    this.map.setHeading(heading + 90);
  }

  autoRotate() {
    if (this.map.getTilt() !== 0) {
      setInterval(() => {
        this.rotate90();
      }, 10000);
    }
  }

  markerInfowindow() {}

  setZoom(zoom: number) {
    this.map.setZoom(zoom);
  }

  GeoLocationControls(map: google.maps.Map, eventEmitter?: EventEmitter<any>) {
    const currentPosition = this.geolocationService.positions.getValue().coords;
    const controlWrapper = document.createElement('div');
    controlWrapper.style.cursor = 'pointer';
    controlWrapper.style.backgroundColor = '#043650';
    controlWrapper.style.borderBottomRightRadius = '5px';
    controlWrapper.style.width = '50px';
    controlWrapper.style.height = '50px';
    controlWrapper.classList.add('standard-shadow');

    const centerGeoLocation = document.createElement('div');
    centerGeoLocation.style.height = '50px';
    centerGeoLocation.style.width = '50px';
    centerGeoLocation.style.backgroundPosition = 'cover';
    centerGeoLocation.style.backgroundRepeat = 'no-repeat';
    centerGeoLocation.style.backgroundImage = 'url("assets/maps/icons8-location-update-50.png")';

    controlWrapper.appendChild(centerGeoLocation);

    // Setup the click event listener - zoomIn
    google.maps.event.addDomListener(centerGeoLocation, 'click', function() {
      const { latitude, longitude } = currentPosition;
      if (currentPosition) {
        this.userMarker = new google.maps.Marker({
          map: map,
          position: new google.maps.LatLng(latitude, longitude)
        });
        eventEmitter.emit(this.userMarker);
      }
    });

    map.controls[google.maps.ControlPosition.LEFT_TOP].push(controlWrapper);
  }

  private detectScreenSizeBreakpoint() {
    this.breakpointObserver.observe([Breakpoints.Handset, '(max-width: 700px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  private setMapControl() {
    if (this.showInCard) {
      this.mapProperties.streetViewControl = false;
      this.mapProperties.mapTypeControl = false;
      this.mapProperties.rotateControl = false;
    }
  }

  private setZoomForSingleMarker() {
    setTimeout(() => {
      const zoomOutLevel = this.isSpotNearMe ? 4 : 8;
      const currentZoomLevel = this.map.getZoom();
      const zoomLevelAfterZoomOut =
        currentZoomLevel > zoomOutLevel ? currentZoomLevel - zoomOutLevel : currentZoomLevel;
      this.map.setZoom(zoomLevelAfterZoomOut);
    }, 300);
  }

  private setZoomLevel() {
    const calculatedZoomLevel = this.commonMapSvc.calculateZoomLevel(this.map, this.bounds);
    const normalizeZoomLevel =
      calculatedZoomLevel > this.DEFAULT_INIT_ZOOM ? this.DEFAULT_INIT_ZOOM : calculatedZoomLevel;
    this.map.setZoom(normalizeZoomLevel);
  }

  private setMapFitBounds() {
    this.fitMapBound();
    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      setTimeout(() => {
        this.setZoomLevel();
      }, 300);

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
        setTimeout(() => {
          this.isMarkerFromListingDetail = this.commonMapSvc.isListingDetailMarker(this.markers, 'isCurrentListing');

          if (this.isMarkerFromListingDetail) {
            this.map.panTo(this.markers[0].getPosition());
          } else {
            this.map.fitBounds(this.bounds, 0);
          }

          google.maps.event.addDomListenerOnce(this.map, 'bounds_changed', () => {
            if (this.map.getZoom() == 0) {
              this.setZoom(5);
            }
            const currentZoomLevel: number = this.map.getZoom();
            this.setInRangeZoomLevel(currentZoomLevel);
          });
        }, 300);
      });
    });
  }

  private setInRangeZoomLevel(zoomLevel: number) {
    const MINIMUM_ZOOM_LEVEL: number = 16;
    const MAX_ZOOM_LEVEL: number = 19;

    if (this.isMarkerFromListingDetail) {
      if (zoomLevel > MAX_ZOOM_LEVEL) {
        this.map.setZoom(MINIMUM_ZOOM_LEVEL);
      } else {
        const calcZoomLevel: number = this.commonMapSvc.calcNearbyListingZoomLevel(zoomLevel, MINIMUM_ZOOM_LEVEL);
        this.map.setZoom(calcZoomLevel);
      }
    } else {
      if (zoomLevel > MAX_ZOOM_LEVEL) {
        this.map.setZoom(this.DEFAULT_INIT_ZOOM);
      }
    }
  }

  private initAndRenderMap() {
    this.setMapControl();
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    setTimeout(() => {
      /* this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.map); */
      this.setupControls(this.map);
      this.drawMapMarker();
      if (this.commonMapSvc.isListingDetailMarker(this.markers, 'isCurrentListing')) {
        this.setZoomWithTimer(13);
      }
    }, 500);
  }

  setZoomWithTimer(zoom: number) {
    setTimeout(() => {
      this.map.setZoom(zoom);
    }, 3000);
  }

  private hasSingleMarker(): boolean {
    return [this.options.singleMarker, this.markers instanceof Array && this.markers.length === 1].some(
      condition => condition
    );
  }

  private createCluster(markerList: any) {
    const maxZoom: number = this.hasSingleMarker() ? 10 : 15;
    if (this.clusterer) {
      this.clusterer.clearMarkers();
    }
    this.clusterer = new MarkerClusterer(this.map, markerList, {
      maxZoom: maxZoom,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  }

  private drawMapMarker() {
    if (this.isSpotNearMe) {
      this.drawNearByListingMarker();
    } else {
      this.drawMarkers();
    }
  }

  private fitMapBound() {
    if (this.isSpotNearMe) {
      const clientLatLng: any = new google.maps.LatLng(this.clientPosition.latitude, this.clientPosition.longitude);
      const locationBound: google.maps.LatLngBounds = new google.maps.LatLngBounds(clientLatLng);
      this.map.setCenter(locationBound.getCenter());
    } else {
      this.map.setCenter(this.bounds.getCenter());
    }
  }

  private drawNearByListingMarker() {
    const isSingleMarker: boolean = this.hasSingleMarker();
    this.bounds = new google.maps.LatLngBounds();

    if (!this.markers) {
      return;
    }

    this.markers.forEach((marker: any) => {
      marker.setMap(this.map);
      this.bounds.extend(marker.getPosition());
    });

    this.createCluster(this.markers);

    if (isSingleMarker) {
      this.map.fitBounds(this.bounds, 0);
      this.setZoomForSingleMarker();
    } else {
      this.setMapFitBounds();
    }
  }
}

export interface MapMarker {
  lat: number;
  lng: number;
  color?: string;
}
