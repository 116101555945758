<div
  app-app-top-banner
  [buttonText]="'acceptCookies.accept'"
  (onButtonClick)="acceptCookieUsage()"
  [visible]="!cookieAcceptanceAccepted"
  [ngClass]="{ 'cookies-mobile': isMobile }"
>
  <p class="no-margin">
    <span>{{ 'acceptCookies.message' | translate | translateStringCutter: 0 }}</span
    ><a [routerLink]="['/privacy-policy']" class="siila-blue-color">{{
      'acceptCookies.message' | translate | translateStringCutter: 1
    }}</a
    ><span>{{ 'acceptCookies.message' | translate | translateStringCutter: 2 }}</span>
  </p>
</div>
<app-header class="header-border" (toggleMenu)="toggleSideMenu($event)"></app-header>
<mat-sidenav-container style="height:100%;">
  <mat-sidenav class="width-menu" #drawer mode="over">
    <!-- Sidenav Display area -->
    <div style="height: 100vh;">
      <ul style="list-style: none;margin-left: -10px;">
        <li class="menu_hover" (click)="goToHomepage()">
          <a [translate]="'global.menu.home'"><img src="assets/icons/home.png" /> Home</a>
        </li>

        <li class="menu_hover" *ngIf="checkSpotAdmin()" [routerLink]="['/spot-buildings/create']">
          <a [translate]="'global.menu.createSpot'"><img src="assets/icons/news.png" alt="" /> Create Spot?</a>
        </li>

        <li (click)="goToFavorites()" class="menu_hover" *ngIf="hasToken">
          <a [translate]="'global.list-your-property.dashboard'"
            ><img src="assets/icons/starDashboard.png" alt="" /> SPOT dashboard</a
          >
        </li>

        <li class="menu_hover" (click)="goToSiilaSpot()">
          <a [translate]="'global.menu.whySpot'"><img src="assets/icons/location_on.svg" alt="" /> Why Spot?</a>
        </li>

        <li class="menu_hover" (click)="goToSiiLaHomePage()">
          <a [translate]="'global.menu.siila'"><img src="assets/icons/siila.png" alt="" /> SiiLA</a>
        </li>

        <li class="menu_hover" (click)="goToSiilaProductsPage()">
          <a [translate]="'global.menu.products'"><img src="assets/icons/subscription.png" alt="" /> Products</a>
        </li>

        <li class="menu_hover" (click)="goToSiilaNews()">
          <a [translate]="'global.menu.siilaNews'"><img src="assets/icons/news.png" alt="" /> INSIGHTS</a>
        </li>

        <li class="menu_hover" (click)="presentContactDialog()">
          <a [translate]="'global.menu.contactUs'"><img src="assets/icons/contact.png" alt="" /> Contact Us</a>
        </li>

        <ion-row style="border: 1px solid #00334D;margin: 15px 16px 15px -16px;"></ion-row>

        <li style="cursor: pointer; padding: 10px 0px 10px 5px;">
          <div class="language-menu">
            <img style="padding: 0px 0px 8px 0px;" src="assets/icons/language.png" alt="" />
            <button class="selected-language" mat-flat-button [matMenuTriggerFor]="languageSelectionMenu">
              <ion-row no-padding>
                <ion-col size="10" no-padding>
                  <span class="link-alike start" style="color: #6c757d;">{{ getCurrentLanguageDesc() }}</span>
                </ion-col>
                <ion-col size="2">
                  <ion-icon class="ion-icon-color end" color="secondary" name="ios-arrow-down"></ion-icon>
                </ion-col>
              </ion-row>
            </button>
            <mat-menu #languageSelectionMenu>
              <button
                mat-menu-item
                class="link-alike"
                [ngClass]="isItemActive('en-US')"
                (click)="setLanguage('en-US')"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active-route"
              >
                <span class="language-item" translate="navLinks.english"></span>
              </button>

              <button
                mat-menu-item
                class="link-alike"
                [ngClass]="isItemActive('es-MX')"
                (click)="setLanguage('es-MX')"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active-route"
              >
                <span class="language-item" translate="navLinks.spanish"></span>
              </button>
            </mat-menu>
          </div>
        </li>

        <ion-row *ngIf="hasToken" style="border: 1px solid #00334D;margin: 15px 16px 15px -16px;"></ion-row>

        <li *ngIf="hasToken" (click)="logOut($event)" style="cursor: pointer; padding: 10px 0px 10px 8px;">
          <a style="color: #DC3545;font-weight: 600;"
            ><img src="assets/icons/Logout.png" alt="" />
            <span style="margin-left: 3px;">
              {{ 'global.form.logOut' | translate }}
            </span></a
          >
        </li>
      </ul>
    </div>

    <div class="menu_login">
      <div *ngIf="!hasToken">
        <button
          class="btn-color-1 btn-border"
          (click)="openLoginDialog()"
          style="margin-top: 14px;cursor: pointer;margin-left: 4px;"
        >
          {{ 'global.menu.login' | translate }}
        </button>
        <ion-button (click)="navigateToRegister()" class="btn-color-2" style="margin-top: -7px; max-width: 136px;">
          {{ 'global.menu.createAccount' | translate }}
        </ion-button>
      </div>

      <div *ngIf="hasToken">
        <div style="float: left;width: 30%;">
          <ion-avatar class="profile-img">
            <img class="image-loaded" [src]="userAccount?.profileImage" default="{{ imagePlaceholder }}" />
          </ion-avatar>
        </div>
        <div style="float: left;margin-top: 8px;width: 70%;">
          <span (click)="goToUserHome()" style="font-weight: 700;cursor: pointer;">{{ userAccount?.name }}</span>
          <br />
          <a (click)="goToMyAccount()" style="color: #0D6EFD;cursor: pointer;">My Account</a>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [ngStyle]="{ 'margin-top': isMobile && !cookieAcceptanceAccepted ? '20vh' : '' }">
    <!-- Router content area -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
