import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { AuthGuard, BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  Shell.childRoutesRunningGuardsResolversAlways([
    {
      path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId`,
      component: CompanyDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Company Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.COMPANY_ROUTE}/:name/:companyId/:language`,
      component: CompanyDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Company Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.COMPANY_ROUTE_CL}/:name/:companyId`,
      component: CompanyDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Company Detail'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.COMPANY_ROUTE_CL}/:name/:companyId/:language`,
      component: CompanyDetailsComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('Company Detail'), shouldReuse: false }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CompanyRoutingModule {}
