import { NgModule } from '@angular/core';
import { NotFoundRoutingModule } from './not-found-routing.module';
import { NotFoundComponent } from '@app/not-found/not-found.component';
import { SharedModule } from '@app/shared/shared.module';
import { ShellModule } from '@app/shell/shell.module';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InViewportModule } from 'ng-in-viewport';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreModule } from '@app/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [NotFoundComponent],
  entryComponents: [NotFoundComponent],
  exports: [NotFoundComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SharedModule,
    InViewportModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CoreModule,
    TranslateModule,
    ShellModule,
    SharedModule,
    NotFoundRoutingModule
  ]
})
export class NotFoundModule {}
