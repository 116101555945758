<ion-item class="standard-shadow" [ngClass]="{ 'unset-cap': sameCharacters }" style="border-top:1px solid gray;">
  <ion-label
    *ngIf="!providers"
    class="header-label capitalize"
    [ngClass]="{ 'unset-cap': sameCharacters }"
    [hidden]="!title"
    >{{ title }}</ion-label
  >
  <div class="flex-box" *ngIf="providers">
    <p class="header-label transform bold-weight" [hidden]="!title">{{ 'global.providerHeader' | translate }}</p>
    <p class="header-label-desc transform" [hidden]="!title">{{ 'global.providerSubtitle' | translate }}</p>
  </div>
</ion-item>
