import { environment } from '@env/environment';
import * as i0 from "@angular/core";
var MatomoService = /** @class */ (function () {
    function MatomoService() {
    }
    MatomoService.prototype.init = function () {
        var _this = this;
        fetch('https://api.ipify.org?format=json')
            .then(function (response) { return response.json(); })
            .then(function (data) {
            var user = JSON.parse(localStorage.getItem('user'));
            var userId = !user || user == undefined ? 'ANONYMOUS ' + data.ip : user.email + ' ' + data.ip;
            _this.configTrackingID(environment.matomoSiteId, userId);
        })
            .catch(function (error) {
            console.error('Couldnt get user ip');
        });
    };
    MatomoService.prototype.addUserIdToGA = function () {
        //(window as any).gtag('set', { 'user_id': userId });
        //this.gaService.event('User', 'Set User ID', userId);
    };
    MatomoService.prototype.configTrackingID = function (siteId, userId) {
        try {
            var matomoScript = document.createElement('script'); //  _paq.push(['trackPageView']);
            matomoScript.innerHTML = "\n        var _paq = window._paq = window._paq || [];\n        _paq.push(['setUserId', '" + userId + "']);\n        _paq.push(['enableLinkTracking']);\n        (function() {\n          var u = \"https://piwik.siila.com/\";\n          _paq.push(['setTrackerUrl', u+'matomo.php']);\n          _paq.push(['setSiteId', '" + siteId + "']);\n          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];\n          g.async = true; g.src = u+'matomo.js'; s.parentNode.insertBefore(g,s);\n        })();\n      ";
            document.head.appendChild(matomoScript);
            //console.log(`${siteId} configuration: OK`);
        }
        catch (ex) {
            console.error("Error appending Matomo script: " + siteId);
            console.error(ex);
        }
    };
    MatomoService.prototype.trackPageView = function () {
        try {
            var matomoScript = document.createElement('script');
            matomoScript.innerHTML = "\n        var _paq = window._paq = window._paq || [];\n        _paq.push(['trackPageView']);\n      ";
            document.head.appendChild(matomoScript);
            //console.log(`Track page view: OK`);
        }
        catch (ex) {
            console.error("Error appending Matomo script: " + ex);
        }
    };
    MatomoService.prototype.trackPageView2 = function (pageTitle) {
        try {
            var currentUrl = location.href;
            var matomoScript = document.createElement('script');
            matomoScript.innerHTML = "\n        var _paq = window._paq = window._paq || [];\n        _paq.push(['setCustomUrl', '" + currentUrl + "']);\n        _paq.push(['setDocumentTitle', '" + pageTitle + "']);\n        _paq.push(['trackPageView']);\n      ";
            document.head.appendChild(matomoScript);
            //console.log(`Track page view: OK: ${currentUrl} ${pageTitle} `);
        }
        catch (ex) {
            console.error("Error appending Matomo script: " + ex);
        }
    };
    MatomoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MatomoService_Factory() { return new MatomoService(); }, token: MatomoService, providedIn: "root" });
    return MatomoService;
}());
export { MatomoService };
