import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { SpotImageDTO } from '@app/models/spot-buildings/spot-image.model';
import { faChevronLeft, faChevronRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IonSlides, PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  galleryImgs: any;
  @Input('photos') photos: SpotImageDTO[];
  @Input('spotTitle') spotTitle: string;
  @Input('listingType') listingType: string = null;
  @Input('description') description: string = null;
  @Input('nestedFloating') nestedFloating: boolean = false;
  @Input('topImage') topImage?: string;

  noPhotos: boolean;
  defaultImage: string;
  currentPhotoIndex: number = 0;
  prevCurrentPhotoIndex: number = 0;
  leftArrow: any;
  rightArrow: any;
  sliderPreviewOptions: any;
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;
  @ViewChild('previewSlider', { static: false }) previewSlider: IonSlides;

  fromPopup: boolean = false;
  closeIcon: any;
  isMobile: boolean = false;

  constructor(
    private i18n: I18nService,
    private popoverCtrl: PopoverController,
    private platformConfigHelper: PlatformHelperService,
    private imageService: ImageService,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    this.closeIcon = faTimesCircle;
    this.noPhotos = false;

    if (window.screen.width < 1000) {
      this.isMobile = true;
    }

    if (this.navParams && this.navParams.data) {
      if (this.navParams.data.fromPopup) {
        this.fromPopup = true;
        this.photos = this.navParams.data.photos;
      }
      if (this.navParams.data.topImage) {
        this.topImage = this.navParams.data.topImage;
      }
    }

    if (!this.photos || this.photos.length == 0) {
      this.galleryImgs = [];
      this.galleryImgs.push(this.defaultImage);
      this.noPhotos = true;
    } else {
      this.galleryImgs = [...this.photos];
    }

    this.leftArrow = faChevronLeft;
    this.rightArrow = faChevronRight;

    this.sliderPreviewOptions = {
      slidesPerView: 5,
      spaceBetween: 16
    };
  }

  public getCurrentFromSlider() {
    return `${this.currentPhotoIndex + 1} ${this.i18n.get('global.of')} ${this.photos.length}`;
  }

  public isStartOfSlide(): boolean {
    return this.currentPhotoIndex <= 0;
  }

  public isEndOfSlide(): boolean {
    return this.currentPhotoIndex === this.galleryImgs.length - 1;
  }

  getCroppedOrOriginalImage(spotImg: SpotImageDTO) {
    if (spotImg) {
      if (spotImg.croppedImageId) {
        return spotImg.croppedImageId;
      }
      return spotImg.imageId;
    }
  }

  slideToIndex(event: Event, index: number) {
    event.stopPropagation();
    this.currentPhotoIndex += index;
    this.syncSliders();
  }

  syncSlidersFromPreview(event: Event, index: number) {
    event.stopPropagation();
    this.currentPhotoIndex = index;
    this.syncSliders();
  }

  syncSliders() {
    this.photoSlider.slideTo(this.currentPhotoIndex);
    this.previewSlider.slideTo(this.currentPhotoIndex);
  }

  public getPreviousPhoto() {
    if (this.currentPhotoIndex === 0) {
      this.photoSlider.slideTo(this.galleryImgs.length);
    } else {
      this.photoSlider.slidePrev();
    }
    this.updatePhotoIndex();
  }

  public updatePhotoIndex() {
    this.photoSlider.getActiveIndex().then(index => {
      this.currentPhotoIndex = index;
      this.syncSliders();
    });
  }

  public getNextPhoto($event: Event) {
    this.photoSlider.getActiveIndex().then(index => {
      if (index < this.currentPhotoIndex) {
        this.currentPhotoIndex = index;
        this.syncSliders();
      } else {
        if ($event.type === 'click') {
          this.photoSlider.slideNext();
          this.updatePhotoIndex();
        } else {
          this.updatePhotoIndex();
        }
      }
    });
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  closeModal() {
    this.popoverCtrl.dismiss();
  }

  cssImgMobile() {
    return {
      width: window.screen.width + 'px',
      height: (window.screen.width / 4) * 3 + 'px',
      'margin-top': this.topImage
    };
  }
}
