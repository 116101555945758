<div class="renew-extend-container">
  <div class="renew-extend-title">
    <label translate="renewExtendListings.title"></label>
  </div>

  <div class="renew-extend-section">
    <p>{{ renewExtendDescription }}</p>

    <div id="expirationDateSection" class="expiration-date-section">
      <ion-label class="expiration-date-lbl">{{ 'renewExtendListings.newExpirationDate' | translate }}: </ion-label>
      <div>
        <span class="width-full date-picker" style="position: relative;">
          <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
          <input
            class="new-expiration-date-input"
            matInput
            [matDatepicker]="picker"
            cdkFocusInitial
            [(ngModel)]="newExpirationDate"
            (ngModelChange)="checkDate($event)"
            placeholder="{{ 'global.adminUpdates.chooseDate' | translate }}"
          />
          <mat-datepicker #picker></mat-datepicker>
        </span>
      </div>
    </div>
  </div>

  <div id="buttonContainer" class="buttons-container">
    <div class="flex-row-nowrap">
      <ion-button translate="Cancel" class="cancel-btn" (click)="closeModal(false)"></ion-button>
      <ion-button
        translate="Update"
        (click)="updateNewExpirationDate()"
        class="next-btn"
        [disabled]="!listingIds || listingIds.length == 0 || !newExpirationDate"
      ></ion-button>
    </div>
  </div>
</div>
