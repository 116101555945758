import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImageService } from '@app/core/image.service';
import { faTimes, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IonSlides } from '@ionic/angular';
var PreviewImagesComponent = /** @class */ (function () {
    function PreviewImagesComponent(data, dialog, imageService) {
        this.data = data;
        this.dialog = dialog;
        this.imageService = imageService;
        this.pag = 0;
        this.slideOptions = {
            initialSlide: 0,
            speed: 400
        };
        this.faTimes = faTimes;
        this.faChevronRight = faChevronRight;
        this.faChevronLeft = faChevronLeft;
        if (this.data._containerInstance._config.data) {
            this.listing = this.data._containerInstance._config.data;
        }
        if (this.listing.listingPhotos) {
            this.photos = this.listing.listingPhotos;
        }
        if (this.listing.buildingListingPhotos) {
            this.photos = this.listing.buildingListingPhotos;
        }
    }
    PreviewImagesComponent.prototype.ngOnInit = function () { };
    PreviewImagesComponent.prototype.getCroppedOrOriginalImage = function (listingPhoto) {
        if (listingPhoto) {
            if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
                return listingPhoto.croppedImage.id;
            }
            return listingPhoto.image.id;
        }
    };
    PreviewImagesComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    PreviewImagesComponent.prototype.getPreviousPhoto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.photoSlider.getActiveIndex()];
                    case 1:
                        index = _a.sent();
                        if (index === 0) {
                            this.photoSlider.slideTo(this.photos.length);
                            this.pag = this.photos.length - 1;
                        }
                        else {
                            this.pag--;
                            this.photoSlider.slidePrev();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewImagesComponent.prototype.getNextPhoto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.photoSlider.getActiveIndex()];
                    case 1:
                        index = _a.sent();
                        if (index + 1 === this.photos.length) {
                            this.photoSlider.slideTo(0);
                            this.pag = 0;
                        }
                        else {
                            this.pag++;
                            this.photoSlider.slideNext();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PreviewImagesComponent.prototype.close = function () {
        this.dialog.closeAll();
    };
    return PreviewImagesComponent;
}());
export { PreviewImagesComponent };
