export interface PropertyTypeMeta {
  id: any;
  name: string;
}

export const PROPERTY_TYPES: PropertyTypeMeta[] = [
  {
    id: 1001,
    name: 'global.list-your-property.industrial'
  },
  {
    id: 2001,
    name: 'global.list-your-property.office'
  }
];
