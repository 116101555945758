import * as tslib_1 from "tslib";
import { AccountService } from '@app/core/auth/account.service';
import { SiilaChatSupportService } from '@app/chat/siila-chat-support.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { HttpClient } from '@angular/common/http';
export function socketFactory() {
    //return new (window as any).SockJS(environment.sockJs);
}
var SocketIOService = /** @class */ (function () {
    function SocketIOService(
    //private socket: Socket,
    chatSupportService, myMessageServices, http, account) {
        this.chatSupportService = chatSupportService;
        this.myMessageServices = myMessageServices;
        this.http = http;
        this.account = account;
        this.unreadMessagesEndpoint = "api/userChat/unreadConversationsFromSupport";
        this.connected$ = new BehaviorSubject(false);
        this.connected = false;
    }
    SocketIOService.prototype.connect = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    SocketIOService.prototype.setEvents = function () {
        /* this.socket.on('actualConnections', (connection: any) => {
          this.connected$.next(true);
        });
        this.socket.on('validatingConnection', (connection: any) => {
          console.log('validating connection --> ', connection);
        });
        this.socket.on('disconnected', (data: any) => {
          this.connected$.next(false);
          console.log('disconnect event --> ', data);
        });
        this.socket.on('message', (msg: any) => {
          this.updateUnreadMessagesByConversation(msg.conversationId);
          this.chatSupportService.appendMessages(msg);
        }); */
    };
    SocketIOService.prototype.updateUnreadMessagesByConversation = function (conversationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (window.location.href.includes('my-messages') && conversationId) {
                    this.myMessageServices.setUnreadCount(0);
                    this.updateUnreadMessages(conversationId);
                }
                else {
                    this.updateUnreadMessageCount();
                }
                return [2 /*return*/];
            });
        });
    };
    SocketIOService.prototype.updateUnreadMessageCount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var unreadMessageInfo;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getUnreadMessagesCount().toPromise()];
                    case 1:
                        unreadMessageInfo = _a.sent();
                        this.myMessageServices.setUnreadCount(unreadMessageInfo && unreadMessageInfo.unreadCount ? unreadMessageInfo.unreadCount : 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    SocketIOService.prototype.updateUnreadMessages = function (msgConvId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currConvId, conversationId, user;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                currConvId = this.chatSupportService.messageData.getValue().conversationId;
                conversationId = msgConvId || currConvId;
                user = JSON.parse(localStorage.getItem('user'));
                this.chatSupportService
                    .updateUnreadMessages(user.login ? user.login : user.profile.login, conversationId)
                    .then(function (result) {
                    if (result.result == 'updated') {
                        _this.myMessageServices.setUnreadCount(0);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    SocketIOService.prototype.getUnreadMessagesCount = function () {
        return this.http.get("" + this.unreadMessagesEndpoint);
    };
    SocketIOService.prototype.setCurrentUser = function () {
        this.currentUser = this.account.currentUser$.getValue();
    };
    SocketIOService.prototype.disconnect = function () {
        // TODO: Disconnect the socket!
        this.connected = false;
        //console.log('Disconnect socket');
        //this.socket.emit('disconnect');
        //this.socket.disconnect();
        //this.socket.ioSocket.disconnect();
        //this.socket.ioSocket.io.disconnect();
    };
    return SocketIOService;
}());
export { SocketIOService };
