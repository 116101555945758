import * as tslib_1 from "tslib";
import { BaseService } from './base-service';
import { SpacesFilterDTO, SpacesParameters } from '../../models/spot-buildings/spot-available-spaces';
var SpotBuildingSpaceService = /** @class */ (function (_super) {
    tslib_1.__extends(SpotBuildingSpaceService, _super);
    function SpotBuildingSpaceService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.SPACES_FILTER_KEY = 'spaces_filter';
        return _this;
    }
    SpotBuildingSpaceService.prototype.saveSpacesFilter = function (spacesFilter) {
        if (spacesFilter) {
            sessionStorage.setItem(this.SPACES_FILTER_KEY, JSON.stringify(spacesFilter));
            return;
        }
        sessionStorage.removeItem(this.SPACES_FILTER_KEY);
    };
    SpotBuildingSpaceService.prototype.getSpacesFilter = function () {
        var savedFilter = sessionStorage.getItem(this.SPACES_FILTER_KEY);
        return savedFilter && savedFilter != 'undefined' && savedFilter != 'null' ? JSON.parse(savedFilter) : null;
    };
    SpotBuildingSpaceService.prototype.getTotalMonthlyRent = function (space, includeCondoIptu) {
        if (space.totalMonthlyRent && !includeCondoIptu) {
            return space.totalMonthlyRent;
        }
        var total = 0;
        if (space && includeCondoIptu) {
            total += space.monthlyAskingRent ? space.monthlyAskingRent : 0;
            total += space.monthlyCondo ? space.monthlyCondo : 0;
            total += space.monthlyIptu ? space.monthlyIptu : 0;
        }
        return total;
    };
    return SpotBuildingSpaceService;
}(BaseService));
export { SpotBuildingSpaceService };
