/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scroll-top.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "./scroll-top.component";
var styles_ScrollToTopComponent = [i0.styles];
var RenderType_ScrollToTopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScrollToTopComponent, data: {} });
export { RenderType_ScrollToTopComponent as RenderType_ScrollToTopComponent };
function View_ScrollToTopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "scroll-to-top": 0, "scroll-to-top-block": 1 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "ion-fab-button", [["class", "scroll-btn"], ["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonFabButton_0, i3.RenderType_IonFabButton)), i1.ɵdid(6, 49152, null, 0, i4.IonFabButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-icon", [["name", "arrow-up"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, !_co.displayBlock, _co.displayBlock); _ck(_v, 2, 0, currVal_0); var currVal_1 = "secondary"; _ck(_v, 6, 0, currVal_1); var currVal_2 = "arrow-up"; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_ScrollToTopComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScrollToTopComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showComponent; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ScrollToTopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scroll-to-top", [], null, null, null, View_ScrollToTopComponent_0, RenderType_ScrollToTopComponent)), i1.ɵdid(1, 114688, null, 0, i5.ScrollToTopComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollToTopComponentNgFactory = i1.ɵccf("app-scroll-to-top", i5.ScrollToTopComponent, View_ScrollToTopComponent_Host_0, { showComponent: "showComponent", options: "options", displayBlock: "displayBlock" }, { scroll: "scroll" }, []);
export { ScrollToTopComponentNgFactory as ScrollToTopComponentNgFactory };
