<form class="multi-language-elem" [formGroup]="multiLangForm">
  <ion-row>
    <ion-col class="language-control">
      <div>
        <ion-label [translate]="options.columnLabel"></ion-label>
      </div>
      <div class="flex-row-justify-end-nowrap">
        <div class="controls" [ngClass]="elemOrder(english)" (click)="selectLanguageElement('en', english)">
          <ion-checkbox #english color="secondary" value="en" mode="ios" [checked]="languageModel.en"> </ion-checkbox>
          <ion-label class="control-item" color="secondary" [translate]="'global.languageChoice.english'"></ion-label>
        </div>

        <div class="controls" [ngClass]="elemOrder(spanish)" (click)="selectLanguageElement('es', spanish)">
          <ion-checkbox #spanish color="secondary" value="es" mode="ios" [checked]="languageModel.es"></ion-checkbox>
          <ion-label class="control-item" color="secondary" [translate]="'global.languageChoice.spanish'"></ion-label>
        </div>

        <!--  <div class="controls" [ngClass]="elemOrder(portuguese)" (click)="selectLanguageElement('pt-br', portuguese)">
          <ion-checkbox #portuguese color="secondary" value="pt-br" mode="ios" [checked]="languageModel['pt-br']">
          </ion-checkbox>
          <ion-label class="control-item" color="secondary" [translate]="'global.languageChoice.portuguese'">
          </ion-label>
        </div> -->
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="flex-column">
      <div
        class="language-label"
        [ngClass]="orderControlElem(i)"
        [formArrayName]="options.formArrayName"
        *ngFor="let langElem of languageElemList.controls; let i = index"
      >
        <div [formGroupName]="i">
          <div class="language-label">
            <ion-label [translate]="getSelectLanguage(i, false)"></ion-label>
          </div>
          <div [ngStyle]="{ 'input-control': options.element.richtext }">
            <input
              *ngIf="options.element.input"
              type="text"
              class="form-control input-control normal-font"
              [formControlName]="getSelectLanguage(i, true)"
              (keyup)="onElemChange(false)"
            />

            <textarea
              *ngIf="options.element.textarea"
              rows="5"
              cols="5"
              class="form-control input-control normal-font"
              [formControlName]="getSelectLanguage(i, true)"
              (keyup)="onElemChange(false)"
            >
            </textarea>

            <quill-editor
              *ngIf="options.element.richtext"
              class="quill-container normal-font"
              [styles]="editorStyle"
              [formControlName]="getSelectLanguage(i, true)"
              [modules]="richTextEditorOption"
              (onContentChanged)="onElemChange(false)"
              [placeholder]="insertTextHere"
            >
            </quill-editor>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>
</form>
