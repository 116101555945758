<mat-card
  class="listing-card spot-building-cards"
  [ngClass]="{
    'mobile-height': isMobile
  }"
  style="cursor: pointer;"
>
  <a class="router-link-seo listing-card-link d-flex flex-column" (click)="openCard(listing.id, $event)">
    <ion-row>
      <ion-col size="5">
        <div class="image-container">
          <div id="listing-card-slides" class="image-height-card">
            <ion-slides
              class="slider-width-card"
              (ionSlideTransitionEnd)="getNextPhoto(listing.id, $event)"
              #photoSlider
              id="mobilePhotosSlider"
            >
              <ion-slide *ngFor="let photo of listing.buildingListingPhotos; let i = index">
                <img
                  *ngIf="i > 0"
                  (click)="openCard(listing.id, $event, false)"
                  [alt]="spotName"
                  [title]="spotName"
                  class="img-listing-card"
                  [defaultImage]="defaultImage"
                  [lazyLoad]="photo && photo.image ? getMediumImg(getCroppedOrOriginalImage(photo)) : photo"
                />

                <img
                  *ngIf="i == 0"
                  (click)="openCard(listing.id, $event, false)"
                  [alt]="spotName"
                  [title]="spotName"
                  class="img-listing-card"
                  [default]="defaultImage"
                  [src]="photo && photo.image ? getMediumImg(getCroppedOrOriginalImage(photo)) : photo"
                />
              </ion-slide>
            </ion-slides>

            <div
              class="image-dots"
              *ngIf="listing.buildingListingPhotos && listing.buildingListingPhotos.length > 1 && photosSet && isMobile"
            >
              <span
                class="dot"
                [ngClass]="{ active: i == currentPhotoIndex }"
                *ngFor="let photo of listing.buildingListingPhotos; let i = index"
                (click)="slideToIndex($event, i)"
              >
              </span>
            </div>
          </div>
        </div>
      </ion-col>

      <ion-col size="7">
        <ion-row class="full-height">
          <div class="flex-col-nowrap full-width card-info px-2">
            <div class="flex-col-nowrap">
              <div class="flex">
                <div class="building-title-regular-card" localize [textValue]="listing.buildingTitle"></div>

                <app-favorite-spot class="flex-align-content-end" [spot]="listing"></app-favorite-spot>
              </div>

              <div class="card-subheader-regular-card" localize [textValue]="listing.buildingAddress"></div>
              <!-- <span class="card-subheader-regular-card">
                {{ getCityState() }}
              </span> -->
            </div>

            <div class="card-info-separator"></div>

            <div class="">
              <div class="flex price-card-info">
                <span class="spot-type-title">{{ propertyTypeLbl }}</span>
                <span class="main-info-gap spot-type-value">{{ propertyType | translate }}</span>
              </div>
              <div class="flex price-card-info">
                <span class="spot-type-title"> {{ listingTypeLbl }} </span>
                <span class="main-info-gap spot-type-value">{{ spotBuildingTypeTranslation | translate }}</span>
              </div>
            </div>
          </div>
        </ion-row>
      </ion-col>
    </ion-row>
  </a>

  <!-- Images Buttons Navigation -->
  <button (click)="getPreviousPhoto()" class="stdBackBtn" *ngIf="showPrevNextNav() && isMobile">
    <ion-icon name="arrow-back"></ion-icon>
  </button>

  <button (click)="getNextPhoto(listing.id, $event)" class="stdNextBtn" *ngIf="showPrevNextNav() && isMobile">
    <ion-icon name="arrow-forward"> </ion-icon>
  </button>
</mat-card>
