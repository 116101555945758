import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss']
})
export class AppTabButtonComponent {
  @Input() label: string;
  @Input() isActive: boolean;
  @Input() value: any;
  @Output() onClick = new EventEmitter<string>();

  onTabClick() {
    this.onClick.emit(this.value);
  }
}
