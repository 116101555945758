<ion-content class="edit-image-modal">
  <ion-row>
    <ion-col>
      <div class="header-col">
        <h4 color="secondary" [translate]="'global.editImage'"></h4>
        <ion-icon class="close-btn pointer-cursor" color="primary" name="close-circle-outline" (click)="cancel()">
        </ion-icon>
      </div>
    </ion-col>
  </ion-row>

  <ion-row class="cropping">
    <ion-col size="6" class="flex-row replace-image" *ngIf="showLoadOption()">
      <button mat-raised-button (click)="inputFile.click()" class="replace-btn">
        {{ 'ReplaceImage' | translate }}
      </button>
      <input
        #inputFile
        id="inputImageFile"
        type="file"
        class="form-control input-file"
        (change)="fileChangedEvent($event, false, true)"
        style="visibility:hidden; width: 0px"
      />
    </ion-col>
    <ion-col size="6" class="flex-row cropping" *ngIf="showCroppingToggle">
      <ion-label translate="global.cropImage" class="crop-image-label"></ion-label>
      <ion-toggle
        disabled="false"
        [(ngModel)]="cropOn"
        autofocus="true"
        (ionChange)="croppingImageValueChanged($event)"
      ></ion-toggle>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="showNotCroppedImage()">
    <ion-col size="12" size-sm="8" offset-sm="2" text-center>
      <img class="img-contain" [src]="getNotCroppedImage()" />
    </ion-col>
  </ion-row>

  <div class="flex-row" *ngIf="showCroppedImage()">
    <ion-row class="rotate-icon-row">
      <ion-col size="8" class="rotate-icon">
        <img src="../../../assets/images/icons/rotate-left.png" (click)="rotateLeft()" />
        <img src="../../../assets/images/icons/rotate-right.png" (click)="rotateRight()" />
        <img src="../../../assets/images/icons/flip-horizontal.png" (click)="flipHorizontal()" />
        <img src="../../../assets/images/icons/flip-vertical.png" (click)="flipVertical()" />
      </ion-col>
      <ion-col size="4">
        <ion-label
          translate="global.restoreOriginal"
          class="restore-image-label"
          (click)="restoreToOriginalImg()"
        ></ion-label>
      </ion-col>
    </ion-row>

    <ion-row class="flex-col-nowrap">
      <div class="image-cropper-container">
        <image-cropper
          class="max-height"
          [imageChangedEvent]="imageChangedEvent"
          [imageBase64]="principalImageToEdit"
          [maintainAspectRatio]="imageCropperOptions.maintainAspectRatio"
          [cropperMinWidth]="imageCropperOptions.cropperMinWidth"
          [cropperMinHeight]="imageCropperOptions.cropperMinHeight"
          [format]="imageCropperOptions.format"
          [roundCropper]="imageCropperOptions.roundCropper"
          [onlyScaleDown]="imageCropperOptions.onlyScaleDown"
          (imageCropped)="imageCropped($event)"
          [aspectRatio]="4 / 3"
          backgroundColor="#000000"
          imageQuality="100"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
        >
        </image-cropper>
      </div>
    </ion-row>
  </div>

  <ion-row>
    <ion-col>
      <div class="control-btn">
        <ion-button class="cancel-btn" [translate]="'Cancel'" (click)="cancel()"></ion-button>
        <ion-button
          color="secondary"
          [translate]="'global.saveChanges'"
          (click)="saveImage()"
          [disabled]="!principalImageToEdit"
        ></ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
