import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base-service';
var SpotSearchService = /** @class */ (function (_super) {
    tslib_1.__extends(SpotSearchService, _super);
    function SpotSearchService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.spotSearchAPI = '/api/spot-search/';
        return _this;
    }
    SpotSearchService.prototype.save = function (dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.spotSearchAPI, dto, {
                            observe: 'response'
                        })
                            .pipe(map(function (val) {
                            return val.body;
                        }))
                            .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpotSearchService.prototype.update = function (dto) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .put(this.spotSearchAPI, dto, {
                            observe: 'response'
                        })
                            .pipe(map(function (val) {
                            return val.body;
                        }))
                            .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SpotSearchService.prototype.getAll = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.spotSearchAPI).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return SpotSearchService;
}(BaseService));
export { SpotSearchService };
