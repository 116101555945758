import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { RegisterFormComponent } from '@app/shared/register';
import { ForgotFormComponent } from '@app/shared/forgot-password';
import { BackStepperGuard, AuthGuard } from '@app/guards';
import { ListUserComponent } from '@app/search-navigation/pages/listing-user';
import { ChangePasswordComponent } from '@app/shared/change-password';
import { PrivacyPolicyComponent } from '@app/shared/privacy-policy/privacy-policy.component';
import { TermsComponent } from '@app/shared/terms/terms.component';
import { DownloadComponent } from '@app/shared/download';
import { MoreNotificationsComponent } from '@app/shared/notifications/more-notifications/more-notifications.component';
import { SettingsNotificationComponent } from '@app/shared/settings-notification/settings-notification.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';
var ɵ0 = {
    title: extract('Register New User')
}, ɵ1 = {
    title: extract('Register New User')
}, ɵ2 = {
    title: extract('Forgot Password')
}, ɵ3 = {
    title: extract('Forgot Password')
}, ɵ4 = {
    title: extract('Change Password')
}, ɵ5 = {
    title: extract('Change Password')
}, ɵ6 = { title: extract('Listing User'), skipParamCheck: true }, ɵ7 = { title: extract('Listing User'), skipParamCheck: true }, ɵ8 = { title: extract('Privacy Policy') }, ɵ9 = { title: extract('Privacy Policy') }, ɵ10 = { title: extract('Privacy Policy') }, ɵ11 = { title: extract('Terms Of Use') }, ɵ12 = { title: extract('Terms Of Use') }, ɵ13 = {
    title: extract('Register New User')
}, ɵ14 = {
    title: extract('Register New User')
}, ɵ15 = {
    title: extract('User Notifications')
}, ɵ16 = {
    title: extract('User Notifications')
}, ɵ17 = {
    title: extract('settings')
}, ɵ18 = {
    title: extract('settings')
};
var routes = [
    Shell.childRoutes([
        { path: '', redirectTo: '/', pathMatch: 'full' },
        {
            path: 'register',
            component: RegisterFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ0
        },
        {
            path: 'register/:language',
            component: RegisterFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ1
        },
        {
            path: 'forgot-password',
            component: ForgotFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ2
        },
        {
            path: 'forgot-password/:language',
            component: ForgotFormComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ3
        },
        {
            path: 'change-password',
            component: ChangePasswordComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ4
        },
        {
            path: 'change-password/:language',
            component: ChangePasswordComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ5
        },
        {
            path: 'listing-user/:id',
            component: ListUserComponent,
            data: ɵ6
        },
        {
            path: 'listing-user/:id/:language',
            component: ListUserComponent,
            data: ɵ7
        },
        {
            path: 'privacy-policy',
            component: PrivacyPolicyComponent,
            data: ɵ8
        },
        {
            path: AppRouteNames.PRIVACY_POLICY_ROUTE + "/:language",
            component: PrivacyPolicyComponent,
            data: ɵ9
        },
        {
            path: AppRouteNames.PRIVACY_POLICY_ROUTE_CL + "/:language",
            component: PrivacyPolicyComponent,
            data: ɵ10
        },
        {
            path: AppRouteNames.TERMS_ROUTE + "/:language",
            component: TermsComponent,
            data: ɵ11
        },
        {
            path: AppRouteNames.TERMS_ROUTE_CL + "/:language",
            component: TermsComponent,
            data: ɵ12
        },
        {
            path: 'download',
            component: DownloadComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ13
        },
        {
            path: 'download/:language',
            component: DownloadComponent,
            canDeactivate: [BackStepperGuard],
            data: ɵ14
        },
        {
            path: 'notifications',
            component: MoreNotificationsComponent,
            canActivate: [AuthGuard],
            data: ɵ15
        },
        {
            path: 'notifications/:language',
            component: MoreNotificationsComponent,
            canActivate: [AuthGuard],
            data: ɵ16
        },
        {
            path: 'settings',
            component: SettingsNotificationComponent,
            canActivate: [AuthGuard],
            data: ɵ17
        },
        {
            path: 'settings/:language',
            component: SettingsNotificationComponent,
            canActivate: [AuthGuard],
            data: ɵ18
        }
    ])
];
//Pass child routes to Shell for reuse
var SearchNavigationRoutingModule = /** @class */ (function () {
    function SearchNavigationRoutingModule() {
    }
    return SearchNavigationRoutingModule;
}());
export { SearchNavigationRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
