<ion-row class="full-width listing-type-select" style="margin-top: 50px;">
  <multi-language-elem
    style="width: 100%"
    (onValueChange)="addressChange($event)"
    [formArrayValue]="spotBuildings.address"
    #title
    [options]="addressFieldOptions"
  >
  </multi-language-elem>
</ion-row>

<ion-row class="full-width" style="margin-top: 10px;" *ngIf="propertyType[0] != 1001">
  <ion-label class="full-width"
    >{{ 'global.list-your-property.neighborhood' | translate }} <span class="blue">*</span>
  </ion-label>
  <ion-row class="full-width listing-type-select">
    <ion-input
      class="input-border"
      id="neighborhood"
      maxlength="30"
      name="neighborhood"
      type="text"
      [(ngModel)]="spotBuildings.neighborhood"
    >
    </ion-input>
  </ion-row>
</ion-row>
<ion-row *ngIf="spotBuildings.entireSale" class="full-width" id="detail-scroll" style="margin-top: 10px;">
  <multi-language-elem
    #description
    [formArrayValue]="existingInvestment"
    (onValueChange)="investmentChange($event)"
    [options]="InvestmentFieldOptions"
  >
  </multi-language-elem>
</ion-row>
<ion-row class="full-width" id="detail-scroll" style="margin-top: 10px;">
  <multi-language-elem
    #description
    [formArrayValue]="existingComments"
    (onValueChange)="descriptionChange($event)"
    [options]="descriptionFieldOptions"
  >
  </multi-language-elem>
</ion-row>

<ion-row
  class="full-width margin-top-5"
  style="margin-top: 10px;
  margin-bottom: 10px;"
>
  <ion-col size="12">
    <ion-label>{{ 'global.my-list-menu.companyOwning' | translate }}</ion-label>
    <span class="width-full my-listing-agent">
      <ionic-selectable
        style="border: 1px solid lightgrey;"
        [items]="allCompanies"
        itemValueField="id"
        [disabled]="false"
        itemTextField="name"
        [(ngModel)]="ownerCompany"
        [hasVirtualScroll]="true"
        placeholder="{{ 'global.my-list-menu.ownerCompany' | translate }}"
        [canSearch]="true"
        (onChange)="selectOwnerCompany($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #portComponent
      >
      </ionic-selectable>
    </span>
  </ion-col>
</ion-row>

<ion-row
  class="full-width margin-top-5"
  style="margin-top: 10px;
  margin-bottom: 10px;"
>
  <ion-col size="12">
    <ion-label>{{ 'global.my-list-menu.company' | translate }} *</ion-label>
    <span class="width-full my-listing-agent">
      <ionic-selectable
        style="border: 1px solid lightgrey;"
        [items]="allCompanies"
        itemValueField="id"
        [disabled]="false"
        itemTextField="name"
        [(ngModel)]="selectCompany"
        [hasVirtualScroll]="true"
        placeholder="{{ 'global.my-list-menu.company' | translate }}"
        [canSearch]="true"
        (onChange)="portChange($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #portComponent
      >
      </ionic-selectable>
    </span>
  </ion-col>
</ion-row>

<ion-row
  *ngIf="selectCompany"
  class="full-width margin-top-5"
  style="margin-top: 10px;
  margin-bottom: 10px;"
>
  <ion-col size="12">
    <ion-label>{{ 'global.my-list-menu.agents' | translate }} *</ion-label>
    <span class="width-full my-listing-agent">
      <ionic-selectable
        style="border: 1px solid lightgrey;"
        [(ngModel)]="offeredByUser"
        [items]="users"
        itemValueField="userId"
        itemTextField="firstNameAndLastName"
        [hasVirtualScroll]="true"
        placeholder="Agent"
        [canSearch]="true"
        [isMultiple]="true"
        (onChange)="nameChange($event)"
        searchPlaceholder="{{ 'global.menu.search' | translate }}"
        closeButtonText="{{ 'Cancel' | translate }}"
        #agentComponent
      >
      </ionic-selectable>
    </span>
  </ion-col>
</ion-row>
