<div class="cover-image"></div>
<div class="image input-container in">
  <ion-row style="width:100%; ">
    <ion-col [size]="12" class="type-input-select" style="z-index:990;">
      <div Class="spot-home-box">
        <div class="download-form-container">
          <h5 class="title" *ngIf="!isDesk">{{ 'global.siilaSpot' | translate }}</h5>
          <h5 class="title" *ngIf="isDesk">{{ 'global.siilaSpotDesk' | translate }}</h5>
          <p style="color: #11334c" *ngIf="!isDesk">{{ 'global.siilaSpotInstall' | translate }}</p>
          <p style="color: #11334c" *ngIf="isDesk">{{ 'global.siilaSpotInstallDesk' | translate }}</p>
          <ion-button
            color="secondary"
            fill="solid"
            class="install-app"
            *ngIf="!(appInstalled$ | async) && !isIos"
            (click)="promptInstallPWA()"
          >
            {{ 'global.menu.installApp' | translate }}
          </ion-button>
          <ion-button
            color="secondary"
            fill="solid"
            class="install-app"
            (click)="promptIos()"
            *ngIf="isIos && !(appInstalled$ | async)"
          >
            {{ 'global.menu.installApp' | translate }}
          </ion-button>
          <ion-button
            color="secondary"
            fill="solid"
            class="install-app"
            (click)="promptIsInstalled()"
            *ngIf="appInstalled$ | async"
          >
            {{ 'global.menu.openApp' | translate }}
          </ion-button>
        </div>
      </div>
    </ion-col>
  </ion-row>
</div>
<app-footer></app-footer>
