import * as tslib_1 from "tslib";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService, AccountService } from '@app/core/auth';
import { ToastController } from '@ionic/angular';
import { I18nService } from '@app/core';
var HttpConfigInterceptor = /** @class */ (function () {
    function HttpConfigInterceptor(auth, account, toastCtrl, i18nService) {
        this.auth = auth;
        this.account = account;
        this.toastCtrl = toastCtrl;
        this.i18nService = i18nService;
        this.toastPresent = false;
    }
    HttpConfigInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var isRequestingToken = req.url.includes('token');
        var token = this.auth.getToken();
        if (token && !isRequestingToken) {
            try {
                req = req.clone({
                    headers: req.headers.set('Authorization', 'Bearer ' + JSON.parse(token)['access_token'])
                });
            }
            catch (error) { }
        }
        return next.handle(req).pipe(catchError(function (error) {
            var errorMessage = error.error ? error.error.error : '';
            var userHasKeepConnected = _this.account.getCurrentUser && _this.account.getCurrentUser.keepConnected;
            var shouldRefreshToken = error.status === 401 && errorMessage === 'invalid_token' && userHasKeepConnected;
            if (isRequestingToken) {
                var grantTypeParam = (req.body.updates || []).find(function (_a) {
                    var param = _a.param;
                    return param === 'grant_type';
                });
                var isRefreshingToken = (grantTypeParam || {}).value === 'refresh_token';
                if (isRefreshingToken) {
                    console.error(_this.i18nService.get('TokenExpiration.failRefreshToken'));
                    _this.auth.logout();
                }
                return throwError(error);
            }
            if (error.status === 504 || error.status === 503) {
                _this.showTimeoutErr();
                return throwError(error);
            }
            if (error.status === 401 && !shouldRefreshToken) {
                if (_this.auth.hasToken() || _this.account.getCurrentUser) {
                    _this.auth.logout();
                    return throwError(error);
                }
                return throwError(error);
            }
            return throwError(error);
        }));
    };
    HttpConfigInterceptor.prototype.showTimeoutErr = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.toastPresent) return [3 /*break*/, 3];
                        this.toastPresent = true;
                        return [4 /*yield*/, this.toastCtrl.create({
                                message: this.i18nService.get('global.user.message.timeoutError'),
                                duration: 3000,
                                animated: true,
                                keyboardClose: true,
                                cssClass: 'toast-alert'
                            })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () {
                            _this.toastPresent = false;
                        });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return HttpConfigInterceptor;
}());
export { HttpConfigInterceptor };
