import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { ListingService } from '@app/core/listings';
import { SearchTagsService } from '@app/core/search-tags.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
var NearbyListingMapComponent = /** @class */ (function () {
    function NearbyListingMapComponent(breakpointObserver, nearbyListingSvc, listingSearchService, listingSvc, searchTagSvc) {
        this.breakpointObserver = breakpointObserver;
        this.nearbyListingSvc = nearbyListingSvc;
        this.listingSearchService = listingSearchService;
        this.listingSvc = listingSvc;
        this.searchTagSvc = searchTagSvc;
        this.ORANGE_PIN = this.nearbyListingSvc.orangePin;
        this.BLUE_PIN = this.nearbyListingSvc.bluePin;
        this.mapOptions = {};
        this.markers = [];
        this.filterStyle = 'filter-tag-container';
        this.isIos = false;
        this.getSelectPropertyListing = new EventEmitter();
        this.refetchListing = new EventEmitter();
        this.subscribe();
    }
    NearbyListingMapComponent.prototype.ngOnInit = function () {
        if (!this.searchTags) {
            this.findSearchTagsFromLocalStorage();
        }
        this.mapOptions = {
            singleMarker: false,
            ignoreZoom: false
        };
    };
    NearbyListingMapComponent.prototype.findSearchTagsFromLocalStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedFilterKey, searchDTO;
            return tslib_1.__generator(this, function (_a) {
                selectedFilterKey = this.listingSearchService.readLastSearchPageFromStorage();
                if (selectedFilterKey) {
                    searchDTO = this.listingSearchService.readSearchDTOFromStorage(selectedFilterKey, {});
                    this.updateSearchTags(searchDTO);
                }
                return [2 /*return*/];
            });
        });
    };
    NearbyListingMapComponent.prototype.ngOnChanges = function (changes) {
        var isListingChange = this.isListingChange(changes);
        if (isListingChange) {
            this.generateNearByMarker();
        }
    };
    NearbyListingMapComponent.prototype.subscribe = function () {
        var _this = this;
        this.listingSvc.currentModel.subscribe(function (model) {
            _this.searchModel = model;
        });
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
            _this.filterStyle = _this.isMobile ? 'filter-tag-mobile' : 'filter-tag-container';
        });
    };
    NearbyListingMapComponent.prototype.isListingChange = function (changes) {
        return [changes['listings'] && changes['listings'].currentValue && changes['listings'].currentValue.length].every(function (condition) { return condition; });
    };
    NearbyListingMapComponent.prototype.setMarkerEvent = function (markers, marker) {
        var _this = this;
        marker.addListener('click', function () {
            var prevSelectedMarker = _this.nearbyListingSvc.prevSelectedMarker(markers, marker);
            if (prevSelectedMarker.length) {
                prevSelectedMarker[0].infoWindow.close();
                prevSelectedMarker[0].set('isSelected', false);
            }
            marker.infoWindow.open(marker.getMap(), marker);
            marker.set('isSelected', true);
            marker.infoWindow.addListener('closeclick', function () {
                marker.set('isSelected', false);
                _this.getSelectPropertyListing.emit({ selectedBuilding: null, listings: [] });
            });
            _this.getBuildingListing(marker);
        });
    };
    NearbyListingMapComponent.prototype.generateNearByMarker = function () {
        var _this = this;
        var currentListingMarker = this.nearbyListingSvc.createBasicMarker(this.currentListing.building, this.ORANGE_PIN);
        var listingMarkers = this.listings.reduce(function (accumulator, item) {
            var isLatLngValid = _this.nearbyListingSvc.hasValidCoordinate(item, 'lat', 'lng');
            if (isLatLngValid && item.id !== _this.currentListing.building.id) {
                var infoWindow = _this.nearbyListingSvc.createNearByListingInfoWindow(item);
                var marker = _this.nearbyListingSvc.createMarker(item, infoWindow, _this.BLUE_PIN, true);
                marker.set('buildingTitle', item.titleOnListing);
                _this.setMarkerEvent(accumulator, marker);
                accumulator.push(marker);
            }
            return accumulator;
        }, []);
        this.markers = tslib_1.__spread([currentListingMarker], listingMarkers);
    };
    NearbyListingMapComponent.prototype.getBuildingListing = function (selectedBuilding) {
        var _this = this;
        var searchDto = this.nearbyListingSvc.setNearbyListingSearchCriteria(this.searchCriteria.searchDTO, selectedBuilding.location);
        searchDto.listingIds = selectedBuilding.listingIds;
        this.listingSvc.getAllNearByPropertyListing(searchDto).then(function (result) {
            _this.getSelectPropertyListing.emit({
                listings: result.body.listings,
                selectedBuilding: selectedBuilding.buildingTitle
            });
        });
    };
    NearbyListingMapComponent.prototype.resetSearchFilter = function (searchDTO, checkBoxValues) {
        var searchProps = this.nearbyListingSvc.getClearSearchProperty();
        var checkboxFilters = Object.assign({}, checkBoxValues);
        searchProps.forEach(function (prop) {
            searchDTO[prop] = null;
        });
        Object.keys(checkboxFilters).forEach(function (key) {
            checkBoxValues[key] = false;
        });
        this.listingSvc.updateModel('clearsearch');
        this.refetchListing.emit({
            searchDTO: searchDTO,
            checkBoxValues: checkboxFilters
        });
    };
    NearbyListingMapComponent.prototype.updateSearchTags = function (searchDTO) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.searchTagSvc.updateSearchTags(searchDTO, false)];
                    case 1:
                        _a.searchTags = _b.sent();
                        return [2 /*return*/, this.searchTags];
                }
            });
        });
    };
    NearbyListingMapComponent.prototype.setListingFilter = function (filters, fieldName) {
        var copyOfFilter = Object.assign({}, filters);
        var isLisingTypeArray = this.nearbyListingSvc.isArray(copyOfFilter, 'listingLeaseOrSale');
        if (fieldName.toLowerCase() === 'propertytype' || fieldName.toLowerCase() === 'officetype') {
            copyOfFilter['propertySubTypes'] = null;
            copyOfFilter['propertySubtypeIds'] = null;
            this.listingSvc.propertySubTypesFromHomepage = [];
        }
        copyOfFilter.listingLeaseOrSale = isLisingTypeArray ? copyOfFilter.listingLeaseOrSale : null;
        copyOfFilter.listingIds = null;
        return copyOfFilter;
    };
    NearbyListingMapComponent.prototype.isTagFieldExist = function (fieldName) {
        return [
            fieldName.toLowerCase() === 'rangeceilingheight',
            fieldName.toLowerCase() === 'baysize',
            fieldName.toLowerCase() === 'dockratio'
        ].some(function (condition) { return condition; });
    };
    NearbyListingMapComponent.prototype.getMinRangeLocale = function (tag) {
        var isTagExist = this.nearbyListingSvc.isTagFieldExist(tag.fieldName);
        return !isTagExist || tag.min < 1 ? '1.0-0' : '1.2-2';
    };
    NearbyListingMapComponent.prototype.getMaxRangeLocale = function (tag) {
        var isTagExist = this.nearbyListingSvc.isTagFieldExist(tag.fieldName);
        return !isTagExist ? '1.0-0' : '1.2-2';
    };
    NearbyListingMapComponent.prototype.hasMinMaxRange = function (tag) {
        return tag.min || tag.max;
    };
    NearbyListingMapComponent.prototype.getNewListing = function (index, fieldName) {
        var _this = this;
        var checkBoxValues = this.nearbyListingSvc.setCheckboxesFilter(fieldName, this.searchCriteria.checkBoxValues);
        var searchDTO = this.searchTagSvc.removeTagFromSearchDTO(this.searchCriteria.searchDTO, fieldName, checkBoxValues);
        searchDTO = this.setListingFilter(searchDTO, fieldName);
        var selectedFilterKey = this.listingSearchService.readLastSearchPageFromStorage();
        this.listingSearchService.saveSearchDtoOnStorage(selectedFilterKey, searchDTO);
        this.searchModel.updateModelFromListingDetail = true;
        this.searchModel = this.nearbyListingSvc.resetSearchModelOnChange(fieldName, this.searchModel);
        this.listingSvc.updateModel(this.searchModel);
        this.searchTags.splice(index, 1);
        this.updateSearchTags(searchDTO).then(function (updatedTags) {
            _this.refetchListing.emit({
                searchDTO: searchDTO,
                checkBoxValues: checkBoxValues,
                searchTags: updatedTags
            });
        });
    };
    NearbyListingMapComponent.prototype.clearSearch = function () {
        var searchDTO = this.nearbyListingSvc.resetNearbyFilterToDefault(this.searchCriteria.searchDTO);
        searchDTO = this.searchTagSvc.clearSearchTagsFromDTO(searchDTO);
        this.listingSearchService.saveSearchDtoOnStorage(SelectedFilterKey.REGULAR_SEARCH, searchDTO);
        this.searchTags = [];
        this.resetSearchFilter(searchDTO, this.searchCriteria.checkBoxValues);
    };
    NearbyListingMapComponent.prototype.isRestricted = function (tagName) {
        return [tagName.toLowerCase() === 'positions: 1'].some(function (condition) { return condition; });
    };
    return NearbyListingMapComponent;
}());
export { NearbyListingMapComponent };
