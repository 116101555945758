import { Injectable, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { RegisterFormComponent } from '@app/shared/register/register-form.component';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class BackStepperGuard implements CanDeactivate<any> {
  private isPop: boolean;
  //   @ViewChild('stepper', { static: true }) stepper: MatStepper;
  constructor(private platform: Platform, private router: Router, private location: Location) {}

  async canDeactivate(
    component: RegisterFormComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Promise<boolean> {
    if (!this.platform.is('mobileweb')) {
      return true;
    }

    const currentNavigation = this.router.getCurrentNavigation();
    const backButtonPressed = currentNavigation.trigger === 'popstate';

    if (!backButtonPressed || this.isPop) {
      return true;
    }
    this.location.go(currentState.url);

    if (component.isCompleted && component.lastStep) {
      component.lastStep = false;
      component.stepper.previous();
      return false;
    }

    if (component.isCompleted && !component.lastStep) {
      component.isCompleted = false;
      component.stepper.previous();
      return false;
    }
  }
}
