import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonGenericService } from '@app/core/common.service';
import { HttpClient } from '@angular/common/http';
var MultipleFilesProgressBarComponent = /** @class */ (function () {
    function MultipleFilesProgressBarComponent(modalCtrl, http, commonService) {
        this.modalCtrl = modalCtrl;
        this.http = http;
        this.commonService = commonService;
    }
    MultipleFilesProgressBarComponent.prototype.ngOnInit = function () {
        this.faSuccess = faCheckCircle;
        this.imgFiles = tslib_1.__spread(this.allImgFiles);
    };
    MultipleFilesProgressBarComponent.prototype.acceptModal = function () {
        this.closeModal({});
    };
    MultipleFilesProgressBarComponent.prototype.closeModal = function (data) {
        this.modalCtrl.dismiss(data);
    };
    MultipleFilesProgressBarComponent.prototype.areImagesUploaded = function () {
        var countImgs = this.imgFiles.length;
        var uploadedImgs = this.allImgFiles.filter(function (img) { return img.uploaded == true; });
        if (uploadedImgs) {
            return uploadedImgs.length == countImgs;
        }
        return false;
    };
    return MultipleFilesProgressBarComponent;
}());
export { MultipleFilesProgressBarComponent };
