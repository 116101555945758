import { Component } from '@angular/core';

@Component({
  selector: 'app-spot-card-placeholder',
  templateUrl: './spot-card-placeholder.component.html',
  styleUrls: ['./spot-card-placeholder.component.scss']
})
export class SpotCardPlaceholderComponent {
  constructor() {}
}
