<mat-card
  class="listing-card"
  [ngClass]="{
    'desktop-mat-card': myListingPage,
    'height-620': myListingAnalytics,
    'mobile-height': isMobile && !fromCarousel,
    'mobile-carousel-height': fromCarousel && (isMobile || screenWidth < 500)
  }"
  style="cursor: pointer;"
>
  <a class="router-link-seo listing-card-link d-flex flex-column">
    <div class="region-bar region-bar-default" [ngClass]="{ 'my-listing-100-width': myListingPage }">
      <span class="region-bar__listing-title">{{ listing.neighborhood }}</span>
      <span
        *ngIf="listing.listingType === 'FEATURED' || listing.listingType === 'PREMIUM'"
        class="premium-label premium-label-outline"
        id="premium"
      >
        <ion-chip class="premium-chip">
          <fa-icon class="listing-icon" [icon]="faIcon" size="md"></fa-icon>
          <ion-label
            *ngIf="listing.listingType === 'FEATURED'"
            color="light"
            [translate]="'global.list-property.featuredUpperCase'"
          ></ion-label>
          <ion-label
            *ngIf="listing.listingType === 'PREMIUM'"
            color="light"
            [translate]="'global.list-property.premiumUpperCase'"
          >
          </ion-label>
        </ion-chip>
      </span>
      <ion-button
        [ngClass]="{ 'is-favorite': isFavoriteListing() }"
        class="region-bar__favorite-button"
        expand="full"
        size="small"
        fill="clear"
        (click)="setFavoriteLisitng($event)"
      >
        <fa-icon [icon]="faHeart" size="lg"></fa-icon>
      </ion-button>
    </div>

    <div
      id="listing-card-slides"
      [ngClass]="{ 'image-height-card': !cardImgWithPadding, 'image-details-height-card': cardImgWithPadding }"
      class=""
      *ngIf="!myListingPage && !myListingAnalytics"
    >
      <ion-slides
        *ngIf="isMobile"
        [options]="slideOptions"
        (ionSlideTransitionEnd)="getNextPhoto(listing.id, $event)"
        #photoSlider
        id="photoSlider"
      >
        <ion-slide *ngFor="let photo of listing.listingPhotos; let i = index">
          <img
            *ngIf="i > 0"
            (click)="openCard(listing.id, $event, false)"
            alt="listing"
            class="img-listing-card"
            [defaultImage]="defaultImage"
            [lazyLoad]="photo && photo.image ? getMediumImg(getCroppedOrOriginalImage(photo)) : photo"
          />

          <img
            *ngIf="i == 0"
            (click)="openCard(listing.id, $event, false)"
            alt="listing"
            class="img-listing-card"
            [default]="defaultImage"
            [src]="photo && photo.image ? getMediumImg(getCroppedOrOriginalImage(photo)) : photo"
          />
        </ion-slide>
      </ion-slides>

      <ion-slide *ngIf="!isMobile">
        <img
          *ngIf="listing.listingPhotos.length > 0"
          (click)="openCard2(0)"
          alt="listing"
          class="img-listing-card"
          [defaultImage]="defaultImage"
          [lazyLoad]="
            listing.listingPhotos[0] && listing.listingPhotos[0].image
              ? getMediumImg(getCroppedOrOriginalImage(listing.listingPhotos[0]))
              : listing.listingPhotos[0]
          "
        />

        <img
          *ngIf="listing.listingPhotos.length == 0"
          (click)="openCard2(0)"
          alt="listing"
          class="img-listing-card"
          [default]="defaultImage"
          [src]="
            listing.listingPhotos[0] && listing.listingPhotos[0].image
              ? getMediumImg(getCroppedOrOriginalImage(listing.listingPhotos[0]))
              : listing.listingPhotos[0]
          "
        />
      </ion-slide>

      <div
        class="image-dots"
        *ngIf="listing.listingPhotos && listing.listingPhotos.length > 1 && photosSet && isMobile"
      >
        <span
          class="dot"
          [ngClass]="{ active: i == currentPhotoIndex }"
          *ngFor="let photo of listing.listingPhotos; let i = index"
          (click)="slideToIndex($event, i)"
        >
        </span>
      </div>
    </div>

    <ion-slides (ionSlideTransitionEnd)="changeSlide($event)" (click)="openCard(listing.id, $event)" *ngIf="isMobile">
      <div
        class="flex-col-nowrap flex-grow-1"
        [ngClass]="{ 'set-height-desktop': myListingPage || myListingAnalytics }"
      >
        <ion-row>
          <ion-col class="flex-row-nowrap" size="9">
            <div
              [ngClass]="{
                'prop-sub-type': !isLoadFromMain,
                'main-sub-type': isLoadFromMain,
                'mobile-sub-type': hasSubtypeOnMobile(),
                'wrap-col-2': screenWidth > 500
              }"
              style="margin-left: 5px;"
            >
              <span class="heading-color">{{ propertyType | translate }}</span>
              <span
                class="heading-color"
                *ngIf="listing?.isForLeaseSale === 'L'"
                [translate]="'global.listing-card.forLease'"
                [translateParams]="{ colon: addColon(listing?.propertySubTypes) }"
              >
              </span>

              <span
                class="heading-color"
                *ngIf="listing?.isForLeaseSale === 'S'"
                [translate]="'global.listing-card.forSale'"
                [translateParams]="{ colon: addColon(listing?.propertySubTypes) }"
              ></span>

              <span
                class="heading-color"
                *ngIf="listing?.isForLeaseSale === 'C'"
                [translate]="'global.listing-card.forCoworking'"
                [translateParams]="{ colon: addColon(listing?.propertySubTypes) }"
              ></span>

              <span class="heading-color" *ngIf="!myListingPage">{{ listingSubtypes }}</span>
              <span class="heading-color" *ngIf="myListingPage">{{ listingSubtypes }}</span>
            </div>
          </ion-col>

          <ion-col class="d-flex flex-column align-items-end" size="3">
            <span class="share-inline" (click)="shareLink($event, listing.id)">
              <fa-icon class="img-icon" [icon]="faShareAltSquare" size="lg"></fa-icon>
            </span>
            <span class="external-link d-none d-sm-block" (click)="openCard(listing.id, $event, true)">
              <fa-icon class="img-icon" [icon]="faExternalLinkAlt" size="md"></fa-icon>
            </span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="flex-col-nowrap" size="12">
            <div class="flex-col-nowrap " [ngClass]="{ 'title-col-1': screenWidth > 500 }">
              <div class="building-title-new" localize [textValue]="listing.buildingTitle"></div>
            </div>
            <div class="flex-justify-between" [ngClass]="{ 'height-60': screenWidth > 500 }" style="margin-top: 5px;">
              <div class="listing-address-city">
                <div class="building-address-new" localize [textValue]="listing.buildingAddress"></div>
                <div class="building-city-state-new no-margin-bottom" localize [textValue]="listing.cityName"></div>
              </div>
              <div
                class="view-map-inline view-map-container"
                (click)="viewMap($event)"
                *ngIf="!myListingPage && !myListingAnalytics"
              >
                <p color="secondary" class="view-map-label" [translate]="'global.search.viewMap'"></p>
                <img class="view-map-icon" [src]="'assets/images/icons/google-map-225.png'" />
              </div>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12" class="pricing-flex" *ngIf="(listing.listingType || '').toLowerCase() != 'basic'">
            <p *ngIf="listing.isForLeaseSale === 'S' && !listing.coworking" class="pricing-header">
              {{ getMeasurementValue() }}
            </p>
            <p
              *ngIf="listing.isForLeaseSale === 'S' && !listing.coworking"
              class="pricing-header"
              translate="global.listingPricing.offeringPrice"
            ></p>
            <p *ngIf="listing.isForLeaseSale === 'L' && !listing.coworking" class="pricing-header">
              {{ getMeasurementValue() }}
            </p>
            <p
              *ngIf="listing.isForLeaseSale === 'L' && !listing.coworking"
              class="pricing-header"
              translate="global.coworking.pricePerMonth"
            ></p>
            <p
              *ngIf="listing.coworkingMaxPositions"
              class="pricing-header"
              translate="global.coworking.positionsAvail"
            ></p>
            <p *ngIf="listing.coworkingMaxPositions" class="pricing-header" translate="global.coworking.pricing"></p>
          </ion-col>

          <ion-col
            class="flex-col-wrap remove-top-padding"
            [ngClass]="{ 'height-100': screenWidth > 500 }"
            *ngIf="listing.coworkingMaxPositions && listing.listingType.toLowerCase() != 'basic'"
            size="12"
          >
            <div class="flex-col-nowrap">
              <div>
                <div class="flex-justify-left ext-details-coworking" style="float: left; padding-left: 5px;">
                  <span *ngIf="listing.coworkingMaxPositions > 1">
                    {{ 'global.coworking.upTo' | translate }}
                    {{ listing.coworkingMaxPositions | localNumber: '1.0-0' }}
                    {{ 'global.positions' | translate }}</span
                  >
                  <span *ngIf="listing.coworkingMaxPositions == 1">
                    {{ 'global.coworking.upTo' | translate }}
                    {{ listing.coworkingMaxPositions | localNumber: '1.0-0' }} {{ 'global.position' | translate }}</span
                  >
                </div>
                <div class="flex-justify-right" style="float: right;">
                  <span *ngIf="listing.coworkingPositions" class="ext-details-coworking">
                    <b>{{ listing.coworkingPositions | localNumber: '1.0-0' }}</b>
                    {{ sharedPrivatePositionsText }}
                  </span>
                </div>
              </div>

              <div class="flex-justify-right">
                <span class="ext-details-coworking" *ngIf="listing.coworkingDailyPrice"
                  >{{ 'global.coworking.dailyFrom' | translate }} R$
                  {{ listing.coworkingDailyPrice | localNumber: '1.0-0' }}</span
                >
                <span class="ext-details-coworking" *ngIf="!listing.coworkingDailyPrice">{{
                  'global.coworking.dailyNegotiable' | translate
                }}</span>
              </div>

              <div class="flex-justify-right">
                <span class="ext-details-coworking" *ngIf="listing.coworkingMonthlyPrice"
                  >{{ 'global.coworking.monthlyFrom' | translate }} R$
                  {{ listing.coworkingMonthlyPrice | localNumber: '1.0-0' }}</span
                >
                <span class="ext-details-coworking" *ngIf="!listing.coworkingMonthlyPrice">{{
                  'global.coworking.monthlyNegotiable' | translate
                }}</span>
              </div>
            </div>
          </ion-col>

          <ion-col
            class="flex-col-wrap"
            [ngClass]="{ 'height-100': screenWidth > 500 }"
            size="12"
            *ngIf="(listing.listingType || '').toLowerCase() == 'basic'"
          >
            <span>
              <p class="comments-field">{{ 'global.listing-detail.basic.click' | translate }}</p>
            </span>
          </ion-col>

          <ion-col
            class="flex-col-wrap remove-top-padding flex-grow-1"
            [ngClass]="{ 'height-100': screenWidth > 500 }"
            size="12"
            *ngIf="(listing.listingType || '').toLowerCase() != 'basic' && !listing.coworking"
          >
            <div
              class="listing-card-details w-100 d-flex  flex-nowrap"
              *ngIf="listing.isForLeaseSale === 'S' && !listing.coworking"
            >
              <div class="listing-card-details__area">
                <div *ngIf="listing.isForLeaseSale === 'S'" class="new-listing-area">
                  {{ listing.rentableArea | localNumber: '1.0-0' }} m<sup>2</sup>
                </div>
              </div>

              <div class="listing-card-details__pricing flex-grow-1">
                <div
                  *ngIf="listing.isForLeaseSale === 'S' && listing.offeringPrice && listing.offeringPrice != 0"
                  class="new-listing-price flex-justify-right"
                >
                  <span class="nowrap">R$ {{ listing.offeringPrice | localNumber: '1.0-0' }}</span>
                  <span
                    class="helper-text align-self-center ml-1"
                    *ngIf="listing && listing.offeringPrice && listing.rentableArea"
                  >
                    ({{ listing.offeringPrice / listing.rentableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                  </span>
                </div>

                <div
                  *ngIf="listing.isForLeaseSale === 'S' && (!listing.offeringPrice || listing.offeringPrice == 0)"
                  class="new-listing-price"
                >
                  {{ 'global.list-property.negotiable' | translate }}
                </div>
              </div>
            </div>

            <div
              class="listing-card-details w-100 d-flex  flex-nowrap"
              *ngIf="listing.isForLeaseSale === 'L' && !listing.coworking"
            >
              <div class="listing-card-details__area">
                <div *ngIf="listing.isForLeaseSale === 'L'" class="new-listing-area">
                  <div>
                    {{ listing.leasableArea | localNumber: '1.0-2' }} m<sup>2</sup>
                    <span *ngIf="listing.leaseableAreaTotal && listing.leasableArea !== listing.leaseableAreaTotal">
                      -</span
                    >
                  </div>
                  <span *ngIf="listing.leaseableAreaTotal && listing.leasableArea !== listing.leaseableAreaTotal"
                    >{{ listing.leaseableAreaTotal | localNumber: '1.0-2' }} m<sup>2</sup>
                  </span>
                </div>
              </div>

              <div class="listing-card-details__pricing flex-grow-1">
                <div
                  *ngIf="listing.isForLeaseSale === 'L' && (!listing.monthlyRent || listing.monthlyRent == 0)"
                  class="new-listing-price flex-justify-right"
                >
                  {{ 'global.list-property.negotiable' | translate }}
                </div>

                <div
                  *ngIf="
                    listing.isForLeaseSale === 'L' &&
                    listing.monthlyRent &&
                    listing.monthlyRent != 0 &&
                    this.screenWidth < 500
                  "
                  class="new-listing-price unset-margin-bottom flex-justify-right"
                >
                  <span class="nowrap">R$ {{ listing.monthlyRent | localNumber: '1.0-0' }}</span>
                  <span
                    class="helper-text align-self-center ml-1"
                    *ngIf="listing && listing.monthlyRent && listing.leasableArea"
                  >
                    ({{ listing.monthlyRent / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                  </span>
                </div>

                <!-- Price Options -->
                <div class="new-listing-price flex-col-nowrap" *ngIf="listing?.isForLeaseSale === 'L'">
                  <div
                    *ngIf="
                      listing.isForLeaseSale === 'L' &&
                      listing.monthlyRent &&
                      listing.monthlyRent != 0 &&
                      this.screenWidth > 500
                    "
                    class="new-listing-price unset-margin-bottom flex-justify-right nowrap"
                  >
                    <span class="nowrap">R$ {{ listing.monthlyRent | localNumber: '1.0-0' }}</span>
                    <span
                      class="helper-text align-self-center ml-1"
                      *ngIf="listing && listing.monthlyRent && listing.leasableArea"
                    >
                      ({{ listing.monthlyRent / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                    </span>
                  </div>

                  <ng-container *ngIf="!hasCoworkingSubType(listing || {})">
                    <div class="" *ngIf="listing?.isForLeaseSale === 'L' && listing?.monthlyCondoFee">
                      <span class="ext-details flex-justify-right nowrap">
                        Condo: R$ {{ listing?.monthlyCondoFee | localNumber: '1.0-0' }}
                        <span class="helper-text align-self-center ml-1" *ngIf="listing && listing.leasableArea">
                          ({{ listing.monthlyCondoFee / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                        </span>
                      </span>
                    </div>

                    <div
                      *ngIf="
                        listing?.isForLeaseSale === 'L' && !listing?.monthlyCondoFee && !isRentCondoIPTUNegotiables()
                      "
                    >
                      <span class="ext-details flex-justify-right">
                        Condo: {{ 'global.list-property.negotiable' | translate }}
                      </span>
                    </div>

                    <div class="flex-row-justify-right" *ngIf="listing?.isForLeaseSale === 'L' && listing?.monthlyIptu">
                      <span class="ext-details nowrap">
                        IPTU: R$ {{ listing?.monthlyIptu | localNumber: '1.0-0' }}
                        <span class="helper-text align-self-center ml-1" *ngIf="listing && listing.leasableArea">
                          ({{ listing.monthlyIptu / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                        </span>
                      </span>
                    </div>

                    <div
                      class="flex-row-justify-right nowrap"
                      *ngIf="listing?.isForLeaseSale === 'L' && !listing?.monthlyIptu && !isRentCondoIPTUNegotiables()"
                    >
                      <span class="ext-details">IPTU: {{ 'global.list-property.negotiable' | translate }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ion-col>

          <ion-col class="flex-col-wrap broker-section" size="12">
            <div id="companyLogoMobile" class="company-logo-container">
              <div class="presented-by">
                <div class="presented-by-lines" *ngIf="listing.offeredByUser">
                  {{ listing.offeredByUser.firstNameAndLastName }}
                </div>
                <div class="presented-by-lines" *ngIf="listing.offeredByUser && !isCompanySameAsAUser(listing)">
                  {{ listing.offeredByUser.company.name }}
                </div>
              </div>

              <div class="listing-company-logo">
                <img
                  style="height:50px;padding-bottom: 13px; object-fit: contain;"
                  [src]="getCompanyLogoImg(listing.companyLogoImageId)"
                  alt="company logo"
                  default="assets/default_image.jpg"
                />
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-slides>
    <div
      class="flex-col-nowrap flex-grow-1"
      *ngIf="!isMobile"
      (click)="openCard(listing.id, $event)"
      [ngClass]="{ 'set-height-desktop': myListingPage || myListingAnalytics }"
    >
      <ion-row>
        <ion-col class="flex-row-nowrap" size="9">
          <div
            [ngClass]="{
              'prop-sub-type': !isLoadFromMain,
              'main-sub-type': isLoadFromMain,
              'mobile-sub-type': hasSubtypeOnMobile(),
              'wrap-col-2': screenWidth > 500
            }"
            style="margin-left: 5px;"
          >
            <span class="heading-color">{{ propertyType | translate }}</span>
            <span
              class="heading-color"
              *ngIf="listing?.isForLeaseSale === 'L'"
              [translate]="'global.listing-card.forLease'"
              [translateParams]="{ colon: addColon(listing?.propertySubTypes) }"
            >
            </span>

            <span
              class="heading-color"
              *ngIf="listing?.isForLeaseSale === 'S'"
              [translate]="'global.listing-card.forSale'"
              [translateParams]="{ colon: addColon(listing?.propertySubTypes) }"
            ></span>

            <span
              class="heading-color"
              *ngIf="listing?.isForLeaseSale === 'C'"
              [translate]="'global.listing-card.forCoworking'"
              [translateParams]="{ colon: addColon(listing?.propertySubTypes) }"
            ></span>

            <span class="heading-color" *ngIf="!myListingPage">{{ listingSubtypes }}</span>
            <span class="heading-color" *ngIf="myListingPage">{{ listingSubtypes }}</span>
          </div>
        </ion-col>

        <ion-col class="d-flex flex-column align-items-end" size="3">
          <span class="share-inline" (click)="shareLink($event, listing.id)">
            <fa-icon class="img-icon" [icon]="faShareAltSquare" size="lg"></fa-icon>
          </span>
          <span class="external-link d-none d-sm-block" (click)="openCard(listing.id, $event, true)">
            <fa-icon class="img-icon" [icon]="faExternalLinkAlt" size="md"></fa-icon>
          </span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col class="flex-col-nowrap" size="12">
          <div class="flex-col-nowrap " [ngClass]="{ 'title-col-1': screenWidth > 500 }">
            <div class="building-title-new" localize [textValue]="listing.buildingTitle"></div>
          </div>
          <div class="flex-justify-between" [ngClass]="{ 'height-60': screenWidth > 500 }" style="margin-top: 5px;">
            <div class="listing-address-city">
              <div class="building-address-new" localize [textValue]="listing.buildingAddress"></div>
              <div class="building-city-state-new no-margin-bottom" localize [textValue]="listing.cityName"></div>
            </div>
            <div
              class="view-map-inline view-map-container"
              (click)="viewMap($event)"
              *ngIf="!myListingPage && !myListingAnalytics"
            >
              <p color="secondary" class="view-map-label" [translate]="'global.search.viewMap'"></p>
              <img class="view-map-icon" [src]="'assets/images/icons/google-map-225.png'" />
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" class="pricing-flex" *ngIf="(listing.listingType || '').toLowerCase() != 'basic'">
          <p *ngIf="listing.isForLeaseSale === 'S' && !listing.coworking" class="pricing-header">
            {{ getMeasurementValue() }}
          </p>
          <p
            *ngIf="listing.isForLeaseSale === 'S' && !listing.coworking"
            class="pricing-header"
            translate="global.listingPricing.offeringPrice"
          ></p>
          <p *ngIf="listing.isForLeaseSale === 'L' && !listing.coworking" class="pricing-header">
            {{ getMeasurementValue() }}
          </p>
          <p
            *ngIf="listing.isForLeaseSale === 'L' && !listing.coworking"
            class="pricing-header"
            translate="global.coworking.pricePerMonth"
          ></p>
          <p
            *ngIf="listing.coworkingMaxPositions"
            class="pricing-header"
            translate="global.coworking.positionsAvail"
          ></p>
          <p *ngIf="listing.coworkingMaxPositions" class="pricing-header" translate="global.coworking.pricing"></p>
        </ion-col>

        <ion-col
          class="flex-col-wrap remove-top-padding"
          [ngClass]="{ 'height-100': screenWidth > 500 }"
          *ngIf="listing.coworkingMaxPositions && listing.listingType.toLowerCase() != 'basic'"
          size="12"
        >
          <div class="flex-col-nowrap">
            <div>
              <div class="flex-justify-left ext-details-coworking" style="float: left; padding-left: 5px;">
                <span *ngIf="listing.coworkingMaxPositions > 1">
                  {{ 'global.coworking.upTo' | translate }} {{ listing.coworkingMaxPositions | localNumber: '1.0-0' }}
                  {{ 'global.positions' | translate }}</span
                >
                <span *ngIf="listing.coworkingMaxPositions == 1">
                  {{ 'global.coworking.upTo' | translate }} {{ listing.coworkingMaxPositions | localNumber: '1.0-0' }}
                  {{ 'global.position' | translate }}</span
                >
              </div>
              <div class="flex-justify-right" style="float: right;">
                <span *ngIf="listing.coworkingPositions" class="ext-details-coworking">
                  <b>{{ listing.coworkingPositions | localNumber: '1.0-0' }}</b>
                  {{ sharedPrivatePositionsText }}
                </span>
              </div>
            </div>

            <div class="flex-justify-right">
              <span class="ext-details-coworking" *ngIf="listing.coworkingDailyPrice"
                >{{ 'global.coworking.dailyFrom' | translate }} R$
                {{ listing.coworkingDailyPrice | localNumber: '1.0-0' }}</span
              >
              <span class="ext-details-coworking" *ngIf="!listing.coworkingDailyPrice">{{
                'global.coworking.dailyNegotiable' | translate
              }}</span>
            </div>

            <div class="flex-justify-right">
              <span class="ext-details-coworking" *ngIf="listing.coworkingMonthlyPrice"
                >{{ 'global.coworking.monthlyFrom' | translate }} R$
                {{ listing.coworkingMonthlyPrice | localNumber: '1.0-0' }}</span
              >
              <span class="ext-details-coworking" *ngIf="!listing.coworkingMonthlyPrice">{{
                'global.coworking.monthlyNegotiable' | translate
              }}</span>
            </div>
          </div>
        </ion-col>

        <ion-col
          class="flex-col-wrap"
          [ngClass]="{ 'height-100': screenWidth > 500 }"
          size="12"
          *ngIf="(listing.listingType || '').toLowerCase() == 'basic'"
        >
          <span>
            <p class="comments-field">{{ 'global.listing-detail.basic.click' | translate }}</p>
          </span>
        </ion-col>

        <ion-col
          class="flex-col-wrap remove-top-padding flex-grow-1"
          [ngClass]="{ 'height-100': screenWidth > 500 }"
          size="12"
          *ngIf="(listing.listingType || '').toLowerCase() != 'basic' && !listing.coworking"
        >
          <div
            class="listing-card-details w-100 d-flex  flex-nowrap"
            *ngIf="listing.isForLeaseSale === 'S' && !listing.coworking"
          >
            <div class="listing-card-details__area">
              <div *ngIf="listing.isForLeaseSale === 'S'" class="new-listing-area">
                {{ listing.rentableArea | localNumber: '1.0-0' }} m<sup>2</sup>
              </div>
            </div>

            <div class="listing-card-details__pricing flex-grow-1">
              <div
                *ngIf="listing.isForLeaseSale === 'S' && listing.offeringPrice && listing.offeringPrice != 0"
                class="new-listing-price flex-justify-right"
              >
                <span class="nowrap">R$ {{ listing.offeringPrice | localNumber: '1.0-0' }}</span>
                <span
                  class="helper-text align-self-center ml-1"
                  *ngIf="listing && listing.offeringPrice && listing.rentableArea"
                >
                  ({{ listing.offeringPrice / listing.rentableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                </span>
              </div>

              <div
                *ngIf="listing.isForLeaseSale === 'S' && (!listing.offeringPrice || listing.offeringPrice == 0)"
                class="new-listing-price"
              >
                {{ 'global.list-property.negotiable' | translate }}
              </div>
            </div>
          </div>

          <div
            class="listing-card-details w-100 d-flex  flex-nowrap"
            *ngIf="listing.isForLeaseSale === 'L' && !listing.coworking"
          >
            <div class="listing-card-details__area">
              <div *ngIf="listing.isForLeaseSale === 'L'" class="new-listing-area">
                <div>
                  {{ listing.leasableArea | localNumber: '1.0-2' }} m<sup>2</sup>
                  <span *ngIf="listing.leaseableAreaTotal && listing.leasableArea !== listing.leaseableAreaTotal">
                    -</span
                  >
                </div>
                <span *ngIf="listing.leaseableAreaTotal && listing.leasableArea !== listing.leaseableAreaTotal"
                  >{{ listing.leaseableAreaTotal | localNumber: '1.0-2' }} m<sup>2</sup>
                </span>
              </div>
            </div>

            <div class="listing-card-details__pricing flex-grow-1">
              <div
                *ngIf="listing.isForLeaseSale === 'L' && (!listing.monthlyRent || listing.monthlyRent == 0)"
                class="new-listing-price flex-justify-right"
              >
                {{ 'global.list-property.negotiable' | translate }}
              </div>

              <div
                *ngIf="
                  listing.isForLeaseSale === 'L' &&
                  listing.monthlyRent &&
                  listing.monthlyRent != 0 &&
                  this.screenWidth < 500
                "
                class="new-listing-price unset-margin-bottom flex-justify-right"
              >
                <span class="nowrap">R$ {{ listing.monthlyRent | localNumber: '1.0-0' }}</span>
                <span
                  class="helper-text align-self-center ml-1"
                  *ngIf="listing && listing.monthlyRent && listing.leasableArea"
                >
                  ({{ listing.monthlyRent / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                </span>
              </div>

              <!-- Price Options -->
              <div class="new-listing-price flex-col-nowrap" *ngIf="listing?.isForLeaseSale === 'L'">
                <div
                  *ngIf="
                    listing.isForLeaseSale === 'L' &&
                    listing.monthlyRent &&
                    listing.monthlyRent != 0 &&
                    this.screenWidth > 500
                  "
                  class="new-listing-price unset-margin-bottom flex-justify-right nowrap"
                >
                  <span class="nowrap">R$ {{ listing.monthlyRent | localNumber: '1.0-0' }}</span>
                  <span
                    class="helper-text align-self-center ml-1"
                    *ngIf="listing && listing.monthlyRent && listing.leasableArea"
                  >
                    ({{ listing.monthlyRent / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                  </span>
                </div>

                <ng-container *ngIf="!hasCoworkingSubType(listing || {})">
                  <div class="" *ngIf="listing?.isForLeaseSale === 'L' && listing?.monthlyCondoFee">
                    <span class="ext-details flex-justify-right nowrap">
                      Condo: R$ {{ listing?.monthlyCondoFee | localNumber: '1.0-0' }}
                      <span class="helper-text align-self-center ml-1" *ngIf="listing && listing.leasableArea">
                        ({{ listing.monthlyCondoFee / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                      </span>
                    </span>
                  </div>

                  <div
                    *ngIf="
                      listing?.isForLeaseSale === 'L' && !listing?.monthlyCondoFee && !isRentCondoIPTUNegotiables()
                    "
                  >
                    <span class="ext-details flex-justify-right">
                      Condo: {{ 'global.list-property.negotiable' | translate }}
                    </span>
                  </div>

                  <div class="flex-row-justify-right" *ngIf="listing?.isForLeaseSale === 'L' && listing?.monthlyIptu">
                    <span class="ext-details nowrap">
                      IPTU: R$ {{ listing?.monthlyIptu | localNumber: '1.0-0' }}
                      <span class="helper-text align-self-center ml-1" *ngIf="listing && listing.leasableArea">
                        ({{ listing.monthlyIptu / listing.leasableArea | localNumber: '1.2-2' }}/m<sup>2</sup>)
                      </span>
                    </span>
                  </div>

                  <div
                    class="flex-row-justify-right nowrap"
                    *ngIf="listing?.isForLeaseSale === 'L' && !listing?.monthlyIptu && !isRentCondoIPTUNegotiables()"
                  >
                    <span class="ext-details">IPTU: {{ 'global.list-property.negotiable' | translate }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ion-col>

        <ion-col class="flex-col-wrap broker-section" size="12">
          <div id="companyLogo" class="company-logo-container">
            <div class="presented-by">
              <div class="presented-by-lines" *ngIf="listing.offeredByUser">
                {{ listing.offeredByUser.firstNameAndLastName }}
              </div>
              <div class="presented-by-lines" *ngIf="listing.offeredByUser && !isCompanySameAsAUser(listing)">
                {{ listing.offeredByUser.company.name }}
              </div>
            </div>

            <div class="listing-company-logo">
              <img
                style="height:50px;padding-bottom: 13px; object-fit: contain;"
                [src]="getCompanyLogoImg(listing.companyLogoImageId)"
                alt="company logo"
                default="assets/default_image.jpg"
              />
            </div>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <div
      class="modify-listing-flex bottom-position"
      [ngClass]="{ 'analytics-height': myListingAnalytics }"
      (click)="openEditListingMenu($event, listing)"
      *ngIf="myListingPage"
    >
      <ion-row style="display: flex; flex-direction: column;">
        <ion-row style="display: flex; flex-direction: row; height: 25px;">
          <ion-col size="6">
            <p class="my-listing-text">
              Status: <span localize [textValue]="listing.listingStatus.status"></span>
              <span
                *ngIf="
                  listing &&
                  listing.nextListingStatus &&
                  (listing.nextListingStatus?.id === 1 || listing.nextListingStatus?.id === 8)
                "
                >/ <span localize [textValue]="listing.nextListingStatus.status"></span>
              </span>
            </p>
          </ion-col>

          <ion-col size="6">
            <p class="my-listing-text" style="float:right;">ID: {{ listing.id }}</p>
          </ion-col>
        </ion-row>

        <ion-row style="display: flex; flex-direction: row; height: 25px;" *ngIf="listing.endDate">
          <ion-col size="12">
            <p class="my-listing-text">
              {{ 'global.listing-card.listingExpire' | translate }}: {{ listing.expirationDate | date }}
            </p>
          </ion-col>
        </ion-row>

        <ion-row style="display: flex; flex-direction: row; height: 25px;">
          <ion-col size="12">
            <p class="my-listing-text uppercase">
              {{ 'global.listing-card.class' | translate }}: {{ listing.listingType.toLowerCase() }}
            </p>
          </ion-col>
        </ion-row>
      </ion-row>

      <ion-row style="margin-top:30px; cursor: pointer;">
        <ion-col size="12">
          <p class="my-listing-text">
            <ion-icon name="create"></ion-icon> {{ 'global.listing-card.modify' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </div>

    <div
      class="modify-listing-flex bottom-position"
      [ngClass]="{ 'height-270': myListingAnalytics }"
      *ngIf="myListingAnalytics"
    >
      <ion-row style="display: flex; flex-direction: column;">
        <ion-row style="display: flex; flex-direction: row; height: 25px;">
          <ion-col size="6">
            <p class="my-listing-text">
              Status: <span localize [textValue]="listing.listingStatus.status"></span>
              <span
                *ngIf="
                  (listing && listing.nextListingStatus && listing.nextListingStatus?.id === 1) ||
                  listing.nextListingStatus?.id === 8
                "
                >/ <span localize [textValue]="listing.nextListingStatus.status"></span>
              </span>
            </p>
          </ion-col>

          <ion-col size="6">
            <p class="my-listing-text" style="float:right;">ID: {{ listing.id }}</p>
          </ion-col>
        </ion-row>

        <ion-row style="display: flex; flex-direction: row; height: 25px;" *ngIf="listing.endDate">
          <ion-col size="12">
            <p class="my-listing-text">
              {{ 'global.listing-card.listingExpire' | translate }}: {{ listing.endDate | date }}
            </p>
          </ion-col>
        </ion-row>

        <ion-row style="display: flex; flex-direction: row; height: 25px;">
          <ion-col size="12">
            <p class="my-listing-text uppercase">
              {{ 'global.listing-card.class' | translate }}: {{ listing.listingType.toLowerCase() }}
            </p>
          </ion-col>
        </ion-row>
      </ion-row>

      <div style="max-width: 500px;" *ngIf="myListingAnalytics">
        <listing-analytics-info style="width: 100%;" [listing]="listing" [listingAnalytics]="analyticsData">
        </listing-analytics-info>
      </div>
    </div>
  </a>

  <button (click)="getPreviousPhoto()" class="stdBackBtn" *ngIf="showPrevNextNav() && !myListingAnalytics && isMobile">
    <ion-icon name="arrow-back"></ion-icon>
  </button>

  <button
    (click)="getNextPhoto(listing.id, $event)"
    class="stdNextBtn"
    *ngIf="showPrevNextNav() && !myListingAnalytics && isMobile"
  >
    <ion-icon name="arrow-forward"> </ion-icon>
  </button>
</mat-card>
