<ion-button *ngIf="isIos" color="secondary" class="fullscreen-button" (click)="goFullScreen()">
  <ion-icon color="light" name="expand"></ion-icon>
</ion-button>
<ng-template [cdkPortalOutlet]="templatePortal"></ng-template>
<ng-template #templatePortalContent>
  <div *ngIf="isIos && fullscreenMode" class="close-fullscreen" [ngClass]="{ 'top-spacing': isApp }">
    <ion-button color="secondary" class="close-fullscreen__button" (click)="exitFullScreen()">
      <ion-icon color="light" name="contract"></ion-icon>
    </ion-button>
  </div>
  <ng-content></ng-content>
</ng-template>
