import { Injectable } from '@angular/core';
import { PlatformHelperService } from './helpers/platform-helper.service';

@Injectable()
export class WhatsAppService {
  whatsAppAPI: string = 'https://wa.me';

  private _window: any;
  constructor(private platformHelperService: PlatformHelperService) {}

  async sendMessageViaWhataspp(phoneNumber: string, defaultMsg: string) {
    //const fullPhoneInternationalFormat = phoneNumber.replace(/[+()\s-]+/g, "");
    const fullPhoneInternationalFormat = phoneNumber.replace(/[(\s\D+)]/g, '');
    const encodedText = encodeURIComponent(defaultMsg);
    const phone = Number(fullPhoneInternationalFormat);
    const whatsAppLink = this.buildWhatasppLink(phone, encodedText);
    const whatsAppWindow = window.open(whatsAppLink, '_system');
    setTimeout(() => {
      if (!this.platformHelperService.isDesktopMode()) {
        whatsAppWindow.close();
      }
    }, 1000);
  }

  private buildWhatasppLink(phoneNumber: number, preFilledMsg: string) {
    return `${this.whatsAppAPI}/${phoneNumber}?text=${preFilledMsg}&time=${new Date()}`;
  }
}
