import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { I18nService } from '@app/core';
import { AuthenticationService } from '@app/core/auth/auth.service';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { LoginFormDialogService } from '../login';

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss']
})
export class SubscribeModalComponent implements OnInit {
  lang = this.i18nService.getCurrentLanguage();
  emailCtrl: FormControl = new FormControl('', Validators.compose([Validators.email]));
  userName: FormControl = new FormControl('', Validators.compose([Validators.required]));
  userLastName: FormControl = new FormControl('', Validators.compose([Validators.required]));
  checkMail: boolean;
  subscribed: boolean;
  private customLoginText: string;

  constructor(
    private subscribeDialog: MatDialogRef<SubscribeModalComponent>,
    private i18nService: I18nService,
    private listingService: ListingDetailService,
    private auth: AuthenticationService,
    private data: MatDialogRef<any>,
    private loginFormDialog: LoginFormDialogService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.subscribed = false;
  }

  ngOnInit(): void {
    this.customLoginText = this.i18nService.get('global.form.defaultSignInMessage');
  }

  subscribe() {
    this.checkMail = false;
    if (this.emailCtrl.status == 'INVALID' || this.emailCtrl.value == '') {
      this.checkMail = true;
    }
    if (this.emailCtrl.valid) {
      let email;
      email = {
        userName: `${this.userName.value} ${this.userLastName.value}`,
        email: this.emailCtrl.value
      };
      this.listingService
        .subscription(email)
        .toPromise()
        .then((result: any) => {
          if (result) {
            localStorage.setItem('emailSubscribed', this.emailCtrl.value);
            this.subscribed = true;
          }
        });
    }
  }

  dismissAsDialog() {
    this.subscribeDialog.close(this.data._containerInstance._config.data);
  }

  openLoginDialog() {
    this.dialog.closeAll();
    const loginData = {
      url: this.router.url,
      customText: this.customLoginText
    };
    this.loginFormDialog.openDialog(loginData);
  }

  navigateToRegister() {
    this.dialog.closeAll();
    //this.location.replaceState('/'); // clears browser history so they can't navigate with back button
    if (this.router.url !== '/register') {
      this.router.navigateByUrl('/register');
    }
  }
}
