import * as tslib_1 from "tslib";
import { HttpService } from '@app/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonGenericService } from '@app/core/common.service';
var SiilaChatSupportService = /** @class */ (function () {
    //Makes api requests to conversation service
    function SiilaChatSupportService(http, commonSvc) {
        this.http = http;
        this.commonSvc = commonSvc;
        this.messageData = new BehaviorSubject({
            messages: []
        });
    }
    SiilaChatSupportService.prototype.populateMessageData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSupportMessages(0, 10).then(function (messageData) {
                            _this.messageData.next(messageData);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SiilaChatSupportService.prototype.appendMessages = function (newMessage) {
        var currentMessageData = this.messageData.getValue();
        currentMessageData.messages = tslib_1.__spread(currentMessageData.messages, [newMessage]);
        currentMessageData.messages = this.commonSvc.sortDateDesc(currentMessageData.messages, 'createdAt');
        this.messageData.next(currentMessageData);
    };
    SiilaChatSupportService.prototype.getSupportMessages = function (index, count) {
        var _this = this;
        return this.http
            .get("/api/conversationWithSupport?messageIndex=" + (index || 0) + "&messageCount=" + (count || 10))
            .pipe(map(function (messageData) { return (tslib_1.__assign({}, messageData, { messages: messageData.messages ? _this.commonSvc.sortDateDesc(messageData.messages, 'createdAt') : [] })); }))
            .toPromise();
    };
    SiilaChatSupportService.prototype.refreshUserChat = function (userId) {
        return this.http.get("/api/userChat/" + userId + "/refresh").toPromise();
    };
    SiilaChatSupportService.prototype.getUsernameInfo = function (userId) {
        return this.http.get("api/users/fast/" + userId).toPromise();
    };
    SiilaChatSupportService.prototype.updateUnreadMessages = function (userId, conversationId) {
        return this.http.put("api/conversation/" + conversationId + "/unread/" + userId, {}).toPromise();
    };
    return SiilaChatSupportService;
}());
export { SiilaChatSupportService };
