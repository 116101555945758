<ion-content>
  <ion-row class="title-main" style="text-align:center">
    <h3 class="no-margin" translate="global.privacy-policy.title"></h3>
  </ion-row>
  <ion-row class="title">
    <h4 translate="global.privacy-policy.paragraphs.p1"></h4>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="p2"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s3"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p3"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s4"></h6>
  </ion-row>
  <ion-row class="text">
    <p translate="global.privacy-policy.paragraphs.p4"></p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s5"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p5"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s6"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p6"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s7"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p7"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s8"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p8"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s9"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p9"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s10"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p10"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h6 class="no-margin" translate="global.privacy-policy.subtitles.s11"></h6>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="p11"></span>
    </p>
  </ion-row>
</ion-content>
