/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-accept-cancel-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./app-accept-cancel-modal.component";
var styles_AppAcceptCancelModalComponent = [i0.styles];
var RenderType_AppAcceptCancelModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppAcceptCancelModalComponent, data: {} });
export { RenderType_AppAcceptCancelModalComponent as RenderType_AppAcceptCancelModalComponent };
export function View_AppAcceptCancelModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "accept-cancel-modal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ion-icon", [["class", "close-icon"], ["name", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal("close") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["matDialogTitle", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(6, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [["class", "mat-dialog-content"], ["matDialogContent", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i4.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "actions mat-dialog-actions"], ["matDialogActions", ""]], null, null, null, null, null)), i1.ɵdid(13, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "ion-button", [["class", "close-button"], ["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(16, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(17, 0, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "ion-button", [["class", "close-button"], ["color", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAcceptClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(20, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(21, 0, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "close"; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); var currVal_4 = "secondary"; _ck(_v, 16, 0, currVal_4); var currVal_6 = "primary"; _ck(_v, 20, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 6).id; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.data.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.data.body; _ck(_v, 9, 0, currVal_3); var currVal_5 = _co.data.cancelText; _ck(_v, 17, 0, currVal_5); var currVal_7 = _co.data.acceptText; _ck(_v, 21, 0, currVal_7); }); }
export function View_AppAcceptCancelModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-app-accept-cancel-modal", [], null, null, null, View_AppAcceptCancelModalComponent_0, RenderType_AppAcceptCancelModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.AppAcceptCancelModalComponent, [i4.MAT_DIALOG_DATA, i4.MatDialogRef], null, null)], null, null); }
var AppAcceptCancelModalComponentNgFactory = i1.ɵccf("app-app-accept-cancel-modal", i5.AppAcceptCancelModalComponent, View_AppAcceptCancelModalComponent_Host_0, {}, {}, []);
export { AppAcceptCancelModalComponentNgFactory as AppAcceptCancelModalComponentNgFactory };
