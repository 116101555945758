import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SearchNavigationRoutingModule } from './search-navigation.routing.module';
import { SearchLandingComponent } from '@app/search-navigation/components/search-landing/search-landing.component';
import { AppListingSlider } from '@app/search-navigation/components/listing-slider/app-listing-slider.component';
import { AppListingSliderService } from '@app/search-navigation/components/listing-slider/app-listing-slider.service';
import { ListingCardComponent } from '@app/search-navigation/components/listing-card/listing-card.component';
import { ListingSearchComponent } from '@app/search-navigation/pages/listing-search/listing-search.component';
import { SharedModule } from '@app/shared';
import { SearchCardComponent } from './components/search-card/search-card.component';
import { ShellModule } from '@app/shell/shell.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListingModalComponent } from './components/listing-modal';
import { ListPropertyService } from '@app/core/listings';
import { DetailPositionService } from './services/detail-position.service';
import { ListingModalService } from './services/listing-modal.service';
import { ListUserComponent } from './pages/listing-user';
import { RangeFilterComponent } from './components/search-filters/range-filters';
import { OfficeFilterComponent } from './components/search-filters/office-filters';
import { IndustrialFilterComponent } from './components/search-filters/industrial-filters';
import { PremiumAgentsComponent } from './components/premium-agents';
import { SearchUrlService } from './services/search-url.service';
import { AppCarouselRestoreService } from './components/carousel';
import { InViewportModule } from 'ng-in-viewport';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image'; // <-- include intersectionObserverPreset
import { SearchNavigationReuseStrategy } from './search-navigation-reuse.route';
import { RouteReuseStrategy } from '@angular/router';
import { onIdentifyEffectsKey } from '@ngrx/effects/src/lifecycle_hooks';
import { IonicModule } from '@ionic/angular';
import { MetaModule } from '@ngx-meta/core';
import { AgentNotificationComponent } from './components/agent-notification/agent-notification.component';
import { CoworkingPopoverComponent } from './components/coworking-popover';
import { RemoveCreditsPopoverComponent } from './components/remove-credits-popover';
import { BuildingRentRollsService } from './services/building-rent-rolls.service';
import { NearbyListingCardComponent } from './components/nearby-listing-card/nearby-listing.component';
import { NearbyListingMapComponent } from './components/nearby-listing-map/nearby-listing-map.component';
import { ListingSearchService } from './services/listing-search.service';
import { MyListingFilterComponent } from './components/my-listing-filter/my-listing-filter.component';
import { CoworkingService } from './services/coworking.service';

// registerLocaleData(localePt, 'pt-BR');

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SharedModule,
    InViewportModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CoreModule,
    TranslateModule,
    ShellModule,
    SearchNavigationRoutingModule,
    MetaModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    })
  ],
  entryComponents: [
    ListingModalComponent,
    CoworkingPopoverComponent,
    RemoveCreditsPopoverComponent,
    AgentNotificationComponent
  ],
  declarations: [
    ListingModalComponent,
    SearchLandingComponent,
    SearchCardComponent,
    // AppListingSlider,
    ListingCardComponent,
    ListingSearchComponent,
    CoworkingPopoverComponent,
    RemoveCreditsPopoverComponent,
    RangeFilterComponent,
    OfficeFilterComponent,
    IndustrialFilterComponent,
    PremiumAgentsComponent,
    ListUserComponent,
    AgentNotificationComponent,
    NearbyListingCardComponent,
    NearbyListingMapComponent,
    MyListingFilterComponent
  ],
  exports: [
    ListingCardComponent,
    ListingSearchComponent,
    RangeFilterComponent,
    OfficeFilterComponent,
    IndustrialFilterComponent
    // AppListingSlider
  ],
  providers: [
    DetailPositionService,
    ListPropertyService,
    AppCarouselRestoreService,
    ListingModalService,
    SearchUrlService,
    ListingSearchService,
    CoworkingService,
    BuildingRentRollsService,
    AppListingSliderService,
    { provide: RouteReuseStrategy, useClass: SearchNavigationReuseStrategy }
    // { provide: LOCALE_ID, useValue: 'pt-BR' }
  ]
})
export class SearchNavigationModule {}
