import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { I18nService } from '@app/core/i18n.service';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { map } from 'rxjs/operators';
import { StateDTO } from '../../models/state.model';

@Injectable()
export class LocationService {
  private statesAPI = `/api/states`;
  private statesByCompanyAndTypeAPI = `/api/states/company/`;
  private citiesEndpoint: string = `/api/cities/with-listings`;
  private countryStatesEndpoint: string = `/api/states/with-listings`;
  private stateByCountryEndpoint: string = '/api/statesByCountry/properties?buildingTypeId=';
  private citiesByStateEndpoint: string = '/api/citiesByMultipleStates/properties?buildingTypeId=';
  private statesWithSpotsEndpoint: string = '/api/statesByCountry/spots?buildingTypeId=';
  private citiesByStateWithSpotsEndpoint: string = '/api/citiesByMultipleStates/spots?buildingTypeId=';

  constructor(private http: HttpClient) {}

  async getStatesByIds(stateIds: number[]) {
    return await this.http
      .post(this.statesAPI, stateIds)
      .toPromise()
      .then((response: any[]) => {
        const listings = response;
        return listings;
      });
  }

  async getStatesByCompanyAndSpotBuildingType(companyId: number, spotType: string) {
    return await this.http.get(`${this.statesByCompanyAndTypeAPI}${companyId}/${spotType}`).toPromise();
  }

  async getStatesByBrokerIdAndSpotBuildingType(brokerId: number, spotType: string) {
    return await this.http
      .get(`${this.statesByCompanyAndTypeAPI}${brokerId}/${spotType}?brokerId=${brokerId}`)
      .toPromise();
  }

  async getCities() {
    return await this.http.get(`${this.citiesEndpoint}`).toPromise();
  }

  async getStates() {
    return await this.http.get(this.countryStatesEndpoint).toPromise();
  }

  async getStatesByCountry(buildingTypeId: number) {
    return await this.http.get(`${this.stateByCountryEndpoint}${buildingTypeId}&countryId=2001`).toPromise();
  }

  async getcitiesByState(buildingTypeId: number, stateId: number) {
    return await this.http.get(`${this.citiesByStateEndpoint}${buildingTypeId}&stateIds=${stateId}`).toPromise();
  }

  async getStatesWithSpots(buildingTypeId: number, spotType: string, propertySubTypeId?: number) {
    if (!propertySubTypeId) {
      return await this.http
        .get(`${this.statesWithSpotsEndpoint}${buildingTypeId}&spotType=${spotType}&countryId=2001`)
        .toPromise();
    }

    return await this.http
      .get(
        `${this.statesWithSpotsEndpoint}${buildingTypeId}&spotType=${spotType}&countryId=2001&propertySubtypeId=${propertySubTypeId}`
      )
      .toPromise();
  }

  async getCitiesByStatesWithSpots(
    buildingTypeId: number,
    stateId: number,
    spotType: string,
    propertySubtypeId?: number
  ) {
    if (!propertySubtypeId)
      return await this.http
        .get(`${this.citiesByStateWithSpotsEndpoint}${buildingTypeId}&stateIds=${stateId}&spotType=${spotType}`)
        .toPromise();

    return await this.http
      .get(
        `${this.citiesByStateWithSpotsEndpoint}${buildingTypeId}&stateIds=${stateId}&spotType=${spotType}&propertySubtypeId=${propertySubtypeId}`
      )
      .toPromise();
  }

  putRioAndSaoPauloOnTop(states: StateDTO[]) {
    if (states && states.length > 1) {
      for (var i = 0; i < states.length; ++i) {
        var state = states[i];
        if (state.name.includes('Rio de Janeiro') || state.name.includes('São Paulo')) {
          states.splice(i, 1);
          if (states[0].name.includes('São Paulo')) {
            states.splice(1, 0, state);
          } else {
            states.splice(0, 0, state);
          }
        }
      }
    }

    return states;
  }
}
