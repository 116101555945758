import { Deserializable } from './deserialize.model';

export enum LeaseType {
  Lease = 'lease',
  Sale = 'sale'
}

export class FeaturedListing implements Deserializable {
  id: number;
  buildingAddress: string;
  buildingTitle: string;
  cityName: string;
  companyLogoImageId: number;
  featured: boolean;
  isForLeaseSale: LeaseType;
  latitude: number;
  longitutde: number;
  leasableArea: number;
  listingPhotos: any[];
  mainImageId: number;
  offeredbyUser: any;
  offeringPrice: number;
  offeringPricePerArea: number;
  offeringPricePerAreaUSD: number;
  offeringPriceUSD: number;
  regionBgColor: string;
  regionName: string;
  regionTextColor: string;
  rentableArea: number;
  stateName: string;
  title: string;
  favorite: boolean;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
