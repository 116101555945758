import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FavoriteSpotService } from '../services/favorite-spot.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {
  constructor(
    public messageDialog: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private favoriteServices: FavoriteSpotService
  ) {}

  closeModal() {
    this.messageDialog.close();
  }

  closeModalConfirm() {
    this.favoriteServices.deleteAction(this.data.id, this.data.apiName).then(() => {
      this.messageDialog.close(true);
    });
  }
}
