import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18nService } from '@app/core';
import { SpacesParameters } from '@app/models/spot-buildings/spot-available-spaces';
import { SpotSet } from '@app/models/spotSet';
import { MessageModalService } from '@app/shared/services/message-modal.service';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { AlertController, IonInput } from '@ionic/angular';

@Component({
  selector: 'app-set-spaces',
  templateUrl: './set-spaces.component.html',
  styleUrls: ['./set-spaces.component.scss']
})
export class SetSpacesComponent implements OnInit, AfterViewInit {
  @ViewChild('spaceInput', { static: false }) spaceInput: ElementRef<HTMLInputElement>;
  spacesParameters: SpacesParameters[];
  spotSet: SpotSet = new SpotSet();
  listSpotSet: SpotSet[] = [];
  setSpotSpace: SpotSet = new SpotSet();
  addSetSpot: boolean = false;
  replaceSetSpot: boolean = false;
  removeSetSpot: boolean = false;
  step: number = 0;
  isMobile: boolean = false;
  isEdit = false;
  spotSetTitle: string = '';

  constructor(
    private dialog: MatDialogRef<SetSpacesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spotBuildingsService: SpotBuildingsService,
    private alertCtrl: AlertController,
    private i18nService: I18nService,
    private messageFormDialog: MessageModalService
  ) {}

  ngOnInit() {
    if (window.screen.width < 1000) {
      this.isMobile = true;
    }

    if (this.data.id) {
      this.isEdit = true;
      this.spotSetTitle = this.data.name;
    } else {
      this.spacesParameters = this.data.data;
      this.getSpotsSet();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.spaceInput.nativeElement.focus();
    }, 800);
  }

  close() {
    this.dialog.close();
  }

  changeStep(step: any) {
    this.step = step.index;
  }

  async showMessage(header: string, message: string, keepUrl?: boolean) {
    const okText = this.i18nService.get('global.OK');
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [okText]
    });
    alert.onDidDismiss().then(() => {});
    await alert.present();
  }

  createSpotSet() {
    let ids: number[] = [];
    this.spacesParameters.forEach(param => {
      ids.push(param.id);
    });
    this.spotSet.spotSetValue = `{\"spaceIds\":[${ids}]}`;

    this.spotBuildingsService.setSpotSpace(this.spotSet).then(val => {
      const message = this.i18nService.get('global.changesSaved');
      this.dialog.close(message);
    });
  }

  getSpotsSet() {
    this.spotBuildingsService.getSpotSet().then((val: SpotSet[]) => {
      this.listSpotSet = val.sort((a, b) => (a.name > b.name ? 1 : -1));
    });
  }

  updateSetSpot() {
    if (this.addSetSpot) {
      let spaceIds = Object.values(JSON.parse(this.setSpotSpace.spotSetValue));
      let ids: number[] = spaceIds[0] as number[];
      this.spacesParameters.forEach(param => {
        ids.push(param.id);
      });
      this.setSpotSpace.spotSetValue = `{\"spaceIds\":[${ids}]}`;

      this.spotBuildingsService.putSpotSet(this.setSpotSpace).then(val => {
        if (val == 200) {
          const message = this.i18nService.get('global.changesSaved');
          this.dialog.close(message);
        } else {
          const message = this.i18nService.get('global.errorSetSpaces');
          this.messageFormDialog.openDialogMessage(message);
        }
      });
    }

    if (this.replaceSetSpot) {
      let ids: number[] = [];
      this.spacesParameters.forEach(param => {
        ids.push(param.id);
      });
      this.setSpotSpace.spotSetValue = `{\"spaceIds\":[${ids}]}`;
      this.setSpotSpace.action = 'ADD';
      this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(val => {
        if (val == 200) {
          const message = this.i18nService.get('global.changesSaved');
          this.dialog.close(message);
        } else {
          const message = this.i18nService.get('global.errorSetSpaces');
          this.messageFormDialog.openDialogMessage(message);
        }
      });
    }

    if (this.removeSetSpot) {
      let ids: number[] = [];
      this.spacesParameters.forEach(param => {
        ids.push(param.id);
      });
      this.setSpotSpace.spotSetValue = `{\"spaceIds\":[${ids}]}`;
      this.setSpotSpace.action = 'REMOVE';
      this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(val => {
        if (val == 200) {
          const message = this.i18nService.get('global.changesSaved');
          this.dialog.close(message);
        } else {
          const message = this.i18nService.get('global.errorSetSpaces');
          this.messageFormDialog.openDialogMessage(message);
        }
      });
    }
  }

  onChangeOpt(opt: number) {
    if (opt == 1) {
      this.addSetSpot = true;
      this.removeSetSpot = false;
      this.replaceSetSpot = false;
    }

    if (opt == 2) {
      this.addSetSpot = false;
      this.replaceSetSpot = true;
      this.removeSetSpot = false;
    }

    if (opt == 3) {
      this.addSetSpot = false;
      this.replaceSetSpot = false;
      this.removeSetSpot = true;
    }
  }

  ubdateSpotSetTitle() {
    this.setSpotSpace.name = this.spotSetTitle;
    this.setSpotSpace.id = this.data.id;
    this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(val => {
      if (val == 200) {
        const message = this.i18nService.get('global.changesSaved');
        this.dialog.close(message);
      } else {
        const message = this.i18nService.get('global.errorSetSpaces');
        this.messageFormDialog.openDialogMessage(message);
      }
    });
  }
}
