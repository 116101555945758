<header class="col-md-12 title">
  <div style="padding-top: 20px;padding-left: 10px;">
    <span style="font-size: 18px;font-weight: 800;">{{ 'favorites.tourBooks.bookTitle' | translate }}</span>
    <img *ngIf="isMobile" class="close-icon" (click)="close()" src="assets/images/icons/closeWhite.png" alt="" />
    <img *ngIf="!isMobile" class="close-icon" (click)="close()" src="assets/images/icons/close.png" alt="" />
  </div>
</header>
<div>
  <div style="padding: 24px;">
    <div class="col-md-12">
      <h6 style="margin-top: 20px;">
        <b>{{ 'favorites.tourBooks.titleEdit' | translate }}</b>
      </h6>
    </div>
    <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
      <input
        #spaceInput
        id="spaceInput"
        name="spaceInput"
        type="text"
        [(ngModel)]="tourBookTitle"
        class="form-control"
        [placeholder]="'buildinsDetail.title' | translate"
      />
    </div>
  </div>
</div>
<div class="col-md-12 mobile-btn" style="background: #F8F9FA;">
  <button *ngIf="isMobile" (click)="close()" style="margin-top: 12px;" class="btn-color-1 btn-border btn-mobile">
    {{ 'Cancel' | translate }}
  </button>

  <ion-button
    *ngIf="!isMobile"
    style="margin-top: 12px;"
    [disabled]="tourBookTitle == ''"
    class="btn-color-2 btn-mobile"
    (click)="editTitleTourBook()"
  >
    {{ 'Update' | translate }}
  </ion-button>
  <ion-button
    *ngIf="isMobile"
    style="margin-top: 12px;"
    [ngClass]="{
      'btn-color-mobile': tourBookTitle == ''
    }"
    [disabled]="tourBookTitle == ''"
    class="btn-color-2 btn-mobile"
    (click)="editTitleTourBook()"
  >
    {{ 'Update' | translate }}
  </ion-button>
  <button *ngIf="!isMobile" (click)="close()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
    {{ 'Cancel' | translate }}
  </button>
</div>
