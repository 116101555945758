import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { CommonGenericService } from '@app/core/common.service';
import * as MyListingModel from '@app/models/my-listing.model';
import { PopoverController } from '@ionic/angular';
var MyListingFilterComponent = /** @class */ (function () {
    function MyListingFilterComponent(commonSvc, popoverCtrl) {
        this.commonSvc = commonSvc;
        this.popoverCtrl = popoverCtrl;
        this.updateFilter = new EventEmitter();
    }
    MyListingFilterComponent.prototype.setButtonColor = function (width) {
        return this.screenWidth >= width ? 'light' : 'secondary';
    };
    MyListingFilterComponent.prototype.overrideColor = function (width) {
        return this.screenWidth >= width ? 'filter-btn-color' : 'filter-btn-white';
    };
    MyListingFilterComponent.prototype.onModalDismiss = function (modal) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                modal.onWillDismiss().then(function (result) {
                    _this.updateFilter.emit(result.data);
                });
                return [2 /*return*/];
            });
        });
    };
    return MyListingFilterComponent;
}());
export { MyListingFilterComponent };
