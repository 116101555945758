import { Directive, HostListener, Input, ElementRef, Inject } from '@angular/core';

@Directive({
  selector: '[scroller]'
})
export class ScrollingDirective {
  @Input('target') target?: any;

  constructor(private childComponent: ElementRef) {}

  private setScrollToTop(delta: number) {
    let elem: any = document.getElementsByTagName('MAT-SIDENAV-CONTENT');
    let elemToUse: any;
    for (let i = 0; i < elem.length; i++) {
      if (elem[i].scrollTop > 0) {
        elemToUse = elem[i];
      }
    }
    elemToUse.scrollTop = elemToUse.scrollTop - delta;
  }

  private scrollToTarget(delta: number) {
    this.target.el.shadowRoot.firstChild.scrollTop = this.target.el.shadowRoot.firstChild.scrollTop - delta;
  }

  @HostListener('mousewheel', ['$event']) scrolling(evt: any) {
    let delta: number = evt.detail ? parseInt(evt.detail) * -120 : parseInt(evt.wheelDelta);
    if (this.target) {
      this.scrollToTarget(delta);
    } else {
      this.setScrollToTop(delta);
    }
  }
}
