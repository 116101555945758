import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {
  SpotBuildingSearchRequest,
  SpotBuildingSearchRequestType
} from '@app/models/spot-buildings/spot-building-search.model';
import { SpotBuildingsSearchService } from '@app/spot-buildings/services/spot-buildings-search.service';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { BreakPointsType, SliderOptionsType } from '@app/models/shared/lazy-slider.model';

@Injectable()
export class SpotCardSliderService {
  constructor(private spotBuildingSearchService: SpotBuildingsSearchService) {}

  sliderBreakPoints: BreakPointsType = {
    1: {
      slidesPerGroup: 1,
      slidesPerView: 1,
      spaceBetween: 0,
      slideNextClass: 'preview-next-margin',
      slidePrevClass: 'preview-prev-margin'
    },
    900: {
      slidesPerGroup: 2,
      slidesPerView: 2,
      spaceBetween: 10
    },
    1300: {
      slidesPerGroup: 3,
      slidesPerView: 3,
      spaceBetween: 10
    }
  };

  defaultSliderOpts: SliderOptionsType = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
    slideVisibleClass: 'swiper-slide-visible',
    grabCursor: false,
    observer: true,
    preventInteractionOnTransition: true,
    breakpoints: this.sliderBreakPoints,
    pagination: false
  };

  defaultSearchRequest: SpotBuildingSearchRequestType = {
    //listingClasses: ['FEATURED'],
    isFeaturedWithoutCheckingDate: true,
    randomOrder: true
  };

  async searchSpotsByCompany(page: number, pageSize: number, payload: SpotBuildingSearchRequest) {
    const { body: ids }: { body: number[] } = await this.findAllSpotBuildingIds(payload);
    const spotBuildings = await this.getSpotBuildingsPaginated(ids, page, pageSize);
    return { spotBuildingIds: ids, spotBuildings };
  }

  searchAllSpotIdsByCompany(payload: SpotBuildingSearchRequest) {
    return this.spotBuildingSearchService.searchSpotBuildingIds(payload, 1);
  }

  searchSpotsByCompanyAsync(spotIds: number[], page: number, pageSize: number) {
    return this.spotBuildingSearchService.getSpotBuildingDetails(page, pageSize, spotIds);
  }

  async findAllSpotBuildingIds(payload: SpotBuildingSearchRequestType) {
    const result = await this.spotBuildingSearchService.searchSpotBuildingIds(payload, 1);
    return result as { headers: HttpHeaders; body: number[] };
  }

  async getSpotBuildingsPaginated(spotIds: number[], page: number, pageSize: number) {
    let spotBuildingList: SpotBuildingDetailDTO[] = [];
    if (!spotIds || spotIds.length === 0) return spotBuildingList;
    spotBuildingList = await this.spotBuildingSearchService.getSpotBuildingDetails(page, pageSize, spotIds);
    return spotBuildingList;
  }
}
