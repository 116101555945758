import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LocalNumberPipe } from '@app/shared/pipes/number-pipe';
import { ShellModule } from '@app/shell/shell.module';
import { BaseService } from '@app/spot-buildings/services/base-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetaModule } from '@ngx-meta/core';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { PaginationModule } from 'ngx-bootstrap';
import { QuillModule } from 'ngx-quill';
import { CompanyRoutingModule } from './company.routing.module';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyService } from './services/company.service';
import { ImageGalleryComponent } from '../shared/image-gallery/image-gallery.component';
import { SpotBuildingsModule } from '../spot-buildings/spot-buildings.module';
import { MaterialModule } from '../shared/material.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CompanyAgentCardComponent } from './company-agent-card/company-agent-card.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    InViewportModule,
    IonicModule,
    MetaModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    ShellModule,
    TranslateModule,
    CompanyRoutingModule,
    SpotBuildingsModule,
    FontAwesomeModule,
    PaginationModule.forRoot(),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    NgbModule,
    MaterialModule,
    MatTabsModule
  ],
  entryComponents: [CompanyDetailsComponent, ImageGalleryComponent, CompanyAgentCardComponent],
  declarations: [CompanyDetailsComponent, CompanyAgentCardComponent],
  exports: [],
  providers: [BaseService, LocalNumberPipe, CompanyService]
})
export class CompanyModule {}
