/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./spot-card-placeholder.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "./spot-card-placeholder.component";
var styles_SpotCardPlaceholderComponent = [i0.styles];
var RenderType_SpotCardPlaceholderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpotCardPlaceholderComponent, data: {} });
export { RenderType_SpotCardPlaceholderComponent as RenderType_SpotCardPlaceholderComponent };
export function View_SpotCardPlaceholderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-card", [["class", "placeholder-card mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [["class", "image-room"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, 0, 0, "div", [["class", "info-room"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "ion-spinner", [["name", "dots"]], null, null, null, i4.View_IonSpinner_0, i4.RenderType_IonSpinner)), i1.ɵdid(8, 49152, null, 0, i5.IonSpinner, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "dots"; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_SpotCardPlaceholderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spot-card-placeholder", [], null, null, null, View_SpotCardPlaceholderComponent_0, RenderType_SpotCardPlaceholderComponent)), i1.ɵdid(1, 49152, null, 0, i6.SpotCardPlaceholderComponent, [], null, null)], null, null); }
var SpotCardPlaceholderComponentNgFactory = i1.ɵccf("app-spot-card-placeholder", i6.SpotCardPlaceholderComponent, View_SpotCardPlaceholderComponent_Host_0, {}, {}, []);
export { SpotCardPlaceholderComponentNgFactory as SpotCardPlaceholderComponentNgFactory };
