/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loading-spinner.component";
var styles_LoadingSpinnerComponent = [i0.styles];
var RenderType_LoadingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingSpinnerComponent, data: {} });
export { RenderType_LoadingSpinnerComponent as RenderType_LoadingSpinnerComponent };
export function View_LoadingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["id", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "app-loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 3, ":svg:svg", [["class", "spinner"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_LoadingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-spinner", [], null, null, null, View_LoadingSpinnerComponent_0, RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoadingSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingSpinnerComponentNgFactory = i1.ɵccf("app-loading-spinner", i2.LoadingSpinnerComponent, View_LoadingSpinnerComponent_Host_0, {}, {}, []);
export { LoadingSpinnerComponentNgFactory as LoadingSpinnerComponentNgFactory };
