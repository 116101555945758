import {
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
  Action,
  combineReducers,
  ActionReducerMap
} from '@ngrx/store';
import { environment } from '@env/environment';
import * as fromRouter from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';

import * as fromCart from '../reducers/cart.reducer';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

// import * as fromLayout from ';
import { InjectionToken } from '@angular/core';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  [fromCart.layoutFeatureKey]: fromCart.CartState;
  router: fromRouter.RouterReducerState<any>;
}

/**
 * LocalStorageSync reducers
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['Cart'] })(reducer);
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    [fromCart.layoutFeatureKey]: fromCart.reducer,
    router: fromRouter.routerReducer
    // localStorageSyncReducer: localStorageSyncReducer
  })
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.groupEnd();

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

/**
 * Layout Reducers
 */
// export const getLayoutState = createFeatureSelector<State, fromLayout.State>(
//   'layout'
// );

// export const getShowSidenav = createSelector(
//   // getLayoutState,
//   // fromLayout.getShowSidenav
