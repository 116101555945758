<ion-content>
  <ion-toolbar class="stepperTop">
    <ion-buttons slot="start">
      <ion-button class="back-button" ion-button icon-only (click)="goBack()">
        <ion-icon color="primary" name="md-arrow-back" size="medium"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div style="background: white; margin: 0 auto;">
    <ion-row class="logo-wrapper">
      <div style="position: relative;">
        <ion-button
          (click)="goForward()"
          *ngIf="currentStep === 0"
          class="btn-color-2 float-right bottom-btn"
          [disabled]="!spotBuildings.propertyName || !spotBuildings.listingType"
        >
          {{ 'global.list-your-property.next' | translate }}
        </ion-button>

        <ion-button
          (click)="goForward()"
          *ngIf="currentStep === 1"
          class="btn-color-2 float-right bottom-btn"
          [disabled]="
            !spotBuildings.offeredByCompany ||
            !spotBuildings.comments ||
            !spotBuildings.brokers ||
            !spotBuildings.address ||
            (spotBuildings.type == 'C' ? !spotBuildings.neighborhood : false)
          "
        >
          {{ 'global.list-your-property.next' | translate }}
        </ion-button>
        <ion-button (click)="goForward()" *ngIf="currentStep === 2" class="btn-color-2 float-right bottom-btn">
          {{ 'global.list-your-property.next' | translate }}
        </ion-button>
        <ion-button
          (click)="saveBuilding()"
          *ngIf="currentStep === 3 && !buildingDetail"
          [disabled]="!imgList.length || !saveImages"
          class="btn-color-2 float-right bottom-btn"
        >
          {{ 'global.list-your-property.next' | translate }}
        </ion-button>

        <ion-button
          (click)="saveBuilding()"
          *ngIf="currentStep === 3 && buildingDetail"
          class="btn-color-2 float-right bottom-btn"
          [disabled]="!imgList.length || !saveImages"
        >
          {{ 'global.list-your-property.next' | translate }}
        </ion-button>

        <h4 class="heading" style="margin-bottom: 30px;">
          {{ 'global.my-list-menu.registerYour' | translate }}
          <img class="spot-img" src="assets/SPOT-Full-Logo.png" />
          {{ 'global.my-list-menu.building' | translate }}
        </h4>
        <p class="reg-font">
          {{ 'global.my-list-menu.buildingRegistrationPage' | translate }} {{ currentStep + 1 }}
          {{ 'global.of' | translate }} 5
        </p>
        <p *ngIf="currentStep === 2" class="reg-font">
          {{ 'global.my-list-menu.enterMoreInfoBuilding' | translate }}
        </p>

        <span style="padding-left: 17px; padding-right: 17px;;" *ngIf="currentStep === 0">
          <p class="reg-font">
            {{ 'global.my-list-menu.selectBuildingFromList' | translate }}
          </p>
        </span>
      </div>
    </ion-row>
    <ion-row>
      <mat-horizontal-stepper
        style=" height: 100px;display: contents;"
        [ngClass]="{ 'unset-stepper-padding': currentStep === 6 }"
        [linear]="true"
        [selectedIndex]="currentStep"
        #stepper
      >
        <mat-step>
          <spot-buildings-detail
            [propertyType]="propertyType"
            [buildingDetail]="buildingDetail"
            [spotBuildings]="spotBuildings"
          >
          </spot-buildings-detail>
          <ion-button
            mat-raised-button
            matStepperNext
            (click)="goForward()"
            class="btn-color-2 float-right bottom-btn2"
            [disabled]="!spotBuildings.propertyName || !spotBuildings.listingType"
          >
            {{ 'global.list-your-property.next' | translate }}
          </ion-button>
        </mat-step>
        <mat-step>
          <spot-more-buildings
            [propertyType]="propertyType"
            [buildingDetail]="buildingDetail"
            [spotBuildings]="spotBuildings"
          ></spot-more-buildings>
          <ion-button
            mat-raised-button
            matStepperNext
            (click)="goForward()"
            class="btn-color-2 float-right bottom-btn2"
            [disabled]="
              !spotBuildings.offeredByCompany ||
              !spotBuildings.comments ||
              !spotBuildings.brokers ||
              !spotBuildings.address ||
              (spotBuildings.type == 'C' ? !spotBuildings.neighborhood : false)
            "
          >
            {{ 'global.list-your-property.next' | translate }}
          </ion-button>
        </mat-step>
        <mat-step>
          <spot-buildings-features
            [buildingDetail]="buildingDetail"
            [propertyType]="propertyType"
            [emitChanges]="emitChanges"
            [typeOffer]="typeOffer"
            [entireSale]="entireSale"
            [spotBuildings]="spotBuildings"
          ></spot-buildings-features>
          <ion-button
            mat-raised-button
            matStepperNext
            (click)="goForward()"
            class="btn-color-2 float-right bottom-btn2"
          >
            {{ 'global.list-your-property.next' | translate }}
          </ion-button>
        </mat-step>
        <mat-step>
          <app-image-upload [uploadingImages]="uploadingImages" [saveImgImmediately]="true" [imgList]="imgList">
          </app-image-upload>
          <ion-button
            *ngIf="!buildingDetail"
            mat-raised-button
            matStepperNext
            (click)="saveBuilding()"
            [disabled]="!imgList.length || !saveImages"
            class="btn-color-2 float-right bottom-btn2"
          >
            {{ 'global.list-your-property.next' | translate }}
          </ion-button>
          <ion-button
            *ngIf="buildingDetail"
            mat-raised-button
            matStepperNext
            (click)="saveBuilding()"
            [disabled]="!imgList.length || !saveImages"
            class="btn-color-2 float-right bottom-btn2"
          >
            {{ 'global.list-your-property.next' | translate }}
          </ion-button>
        </mat-step>
        <mat-step>
          <spot-spaces-buildings
            *ngIf="!spotBuildings.entireSale && !spotBuildings.offMarket"
            [SpotBuildingsResponse]="createSpotBuildingsResponse"
          >
          </spot-spaces-buildings>
          <!--  <button mat-raised-button matStepperNext (click)="goForward()" class="next-btn float-right bottom-btn">
            {{ 'global.list-your-property.next' | translate }}
          </button> -->
          <ion-button
            mat-raised-button
            matStepperNext
            (click)="closeBuilding()"
            class="btn-color-2 float-right bottom-btn2"
          >
            {{ 'global.list-your-property.save' | translate }}
          </ion-button>
        </mat-step>
      </mat-horizontal-stepper>
    </ion-row>
  </div>
</ion-content>
