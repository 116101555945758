export interface FavoriteChange {
  id: number;
  isFavorite: boolean;
}

export interface SaveModalActions {
  leftButton: string;
  rightButton: string;
}

export enum SaveModalTypes {
  SAVE_SEARCH = 'SAVE_SEARCH',
  LOAD_SEARCH = 'LOAD_SEARCH'
}
