/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./contact-form.component";
import * as i6 from "@angular/material/dialog";
var styles_ContactFormComponent = [i0.styles];
var RenderType_ContactFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactFormComponent, data: {} });
export { RenderType_ContactFormComponent as RenderType_ContactFormComponent };
export function View_ContactFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "flex-btn"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "ion-button", [["color", "primary"], ["fill", "outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(3, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], fill: [1, "fill"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "ion-icon", [["name", "close"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(6, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(10, 0, null, null, 23, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "subtitle center"], ["translate", "global.contactModal.infoBr"]], null, null, null, null, null)), i1.ɵdid(14, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "contact-btn"], ["translate", "global.contactModal.contactEmailBr"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(19, 0, null, null, 8, "p", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "span", [["translate", "global.contactModal.call"]], null, null, null, null, null)), i1.ɵdid(22, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Or call: "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "a", [["href", "tel:+52 (55) 64 60 65 29"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+52 (55) 64 60 65 29"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "outline"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "close"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "global.contactModal.infoBr"; _ck(_v, 14, 0, currVal_3); var currVal_4 = "global.contactModal.contactEmailBr"; _ck(_v, 17, 0, currVal_4); var currVal_5 = "global.contactModal.call"; _ck(_v, 22, 0, currVal_5); }, null); }
export function View_ContactFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-form", [], null, null, null, View_ContactFormComponent_0, RenderType_ContactFormComponent)), i1.ɵdid(1, 114688, null, 0, i5.ContactFormComponent, [i6.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactFormComponentNgFactory = i1.ɵccf("app-contact-form", i5.ContactFormComponent, View_ContactFormComponent_Host_0, {}, {}, []);
export { ContactFormComponentNgFactory as ContactFormComponentNgFactory };
