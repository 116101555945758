<ion-content>
  <ion-row class="title-main" style="text-align:center">
    <h3 class="no-margin" translate="global.terms.title"></h3>
  </ion-row>

  <ion-row class="title" style="margin-top: -30px;">
    <h5 translate="global.terms.paragraphs.termsOfUse"></h5>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="termsOfUse2"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.ownership"></h4>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="ownership"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.limitations"></h4>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="limitations"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.indemnification"></h4>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="indemnification"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.jurisdiction"></h4>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="jurisdiction"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.policies"></h4>
  </ion-row>

  <ion-row class="text">
    <p>
      <span [innerHtml]="policies"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.services"></h4>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="services"></span>
    </p>
  </ion-row>

  <ion-row class="title">
    <h4 class="no-margin" translate="global.terms.subtitles.information"></h4>
  </ion-row>
  <ion-row class="text">
    <p>
      <span [innerHtml]="information"></span>
    </p>
  </ion-row>
</ion-content>
