import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {
  @Input() headerTitle: string;
  @Input() bodyMessage: string;
  @Input() data: any;
  @Input() extraData: any;

  constructor(private modalController: ModalController) {}

  closeModal() {
    this.modalController.dismiss({
      closeModal: true,
      action: this.data,
      extraData: this.extraData
    });
  }
}
