<div
  class="row"
  style="    margin: -12px -24px 0px -24px;
border-bottom: 1px solid #6c757d;"
>
  <div style="width: 80%;margin-bottom: 12px;">
    <span style="font-weight: 700;font-size: 23px;  margin-left: 20px;">Reset Password</span>
  </div>
  <div style="width: 20%;">
    <img class="close-icon" (click)="dismissAsDialog(false)" src="assets/images/icons/close.png" alt="" />
  </div>
</div>

<div class="forgot-text">
  <span>{{ 'global.register.resetPassword' | translate }}</span>
</div>

<form [formGroup]="registerForm" style="margin-top: 15px;" class="width-100" name="registerForm" autocomplete="off">
  <div formGroupName="emailGroup">
    <ion-input
      id="email"
      class="form-control"
      name="email"
      (ionChange)="trimValue(registerForm.controls.emailGroup.value.email)"
      formControlName="email"
      placeholder="{{ 'global.register.emailAddress' | translate }}"
    >
    </ion-input>
  </div>

  <div class="flex-row align-end" style="margin-top: 15px;">
    <ion-button
      [disabled]="!registerForm?.controls.emailGroup.valid"
      (click)="sendEmail()"
      class="btn-color-2"
      style="width: 100%;font-size: 14px;margin: 15px 0px 0px 0px;"
    >
      <span>{{ 'global.register.sendEmail' | translate }}</span>
    </ion-button>
  </div>
</form>
