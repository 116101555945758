import { Injectable } from '@angular/core';
import { I18nService } from '../i18n.service';
import { RegisterService } from '../register.service';

@Injectable()
export class StatusHelperService {
  // public declaration
  selectStatus: any[];
  statuses: any[];

  // private declaration
  private statusType: any;

  constructor(private i18NService: I18nService, private registerService: RegisterService) {}

  updateSelectedStatusesBasedOnDTO(listingSearchDto: any) {
    this.selectStatus = [];

    if (listingSearchDto.listingStatuses && this.statuses) {
      if (listingSearchDto.listingStatuses.length > 0) {
        (<Array<any>>listingSearchDto.listingStatuses).forEach(statusTag => {
          const statusFound = (<Array<any>>this.statuses).find(status => status.id == statusTag);
          if (statusFound) {
            (<Array<any>>this.selectStatus).push(statusFound);
          }
        });
      }
    }

    return this.selectStatus;
  }

  /**
   // tslint:disable-next-line: jsdoc-format
   * Get the current selected status Ids
   */
  async getStatusIds() {
    let arr: any = [];
    Object.keys(this.selectStatus).forEach(async (key, idx) => {
      arr.push(this.selectStatus[key].id);
    });
    return arr;
  }

  removeStatus(status: any) {
    this.selectStatus.forEach((item: any, index: number) => {
      if (item.id == status.id) {
        this.selectStatus.splice(index, 1);
        return;
      }
    });
  }

  /**
   * Returns translated statuses.
   */
  getStatuses() {
    return this.getStatusType().then(statusType => {
      this.statuses = statusType.map((item: any) => {
        const clonedStatus = { status: item.status };
        const clonedItem = { ...item, status: this.i18NService.getTranslation(clonedStatus.status) };
        return clonedItem;
      });
    });
  }

  private async getStatusType() {
    if (!this.statusType) {
      this.statusType = await this.registerService.getListingStatus().toPromise();
    }
    return this.statusType;
  }
}
