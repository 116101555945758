/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tab-button.component";
var styles_AppTabButtonComponent = [i0.styles];
var RenderType_AppTabButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppTabButtonComponent, data: {} });
export { RenderType_AppTabButtonComponent as RenderType_AppTabButtonComponent };
export function View_AppTabButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "tab-button rounded-top d-inline-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTabClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab-button rounded-top d-inline-block"; var currVal_1 = _ck(_v, 3, 0, _co.isActive); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 6, 0, currVal_2); }); }
export function View_AppTabButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-button", [], null, null, null, View_AppTabButtonComponent_0, RenderType_AppTabButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.AppTabButtonComponent, [], null, null)], null, null); }
var AppTabButtonComponentNgFactory = i1.ɵccf("app-tab-button", i3.AppTabButtonComponent, View_AppTabButtonComponent_Host_0, { label: "label", isActive: "isActive", value: "value" }, { onClick: "onClick" }, []);
export { AppTabButtonComponentNgFactory as AppTabButtonComponentNgFactory };
