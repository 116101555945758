import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbOptions } from '@app/shared';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { PopoverController, NavParams } from '@ionic/angular';
import { EventCheckerService } from '@app/core/listings';

@Component({
  selector: 'renew-modal',
  templateUrl: './renew-modal.component.html',
  styleUrls: ['./renew-modal.component.scss']
})
export class RenewModalComponent {
  breadcrumbOptions: BreadcrumbOptions = {
    navigateHome: false,
    usePlatformLocation: true
  };
  listingClass: any;
  expirationDate: any;
  renewalChoice: any = 'activate';
  creditId: any;
  listingId: any;
  listing: any;
  constructor(
    private router: Router,
    private purchaseCreditsService: PurchaseCreditsService,
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
    private _eventChecker: EventCheckerService
  ) {}

  ngOnInit() {
    this.getPopData();
  }

  getPopData() {
    this.expirationDate = this.navParams.data.credit.listingExpirationDate;
    this.listingClass = this.navParams.data.credit.premium;
    this.creditId = this.navParams.data.credit.id;
    this.listingId = this.navParams.data.listingId;
    this.listing = this.navParams.data.listing;
  }

  choiceSelection(event: any) {
    this.renewalChoice = event.detail.value;
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  async renew() {
    this.popoverCtrl.dismiss();
    if (this.renewalChoice === 'activate') {
      let listing = await this.purchaseCreditsService.renewListing(this.creditId, this.listingId);
      this._eventChecker.updateListing$.next(listing);
    } else {
      let listing = await this.purchaseCreditsService.renewDeactivated(this.creditId, this.listingId);
      this._eventChecker.updateListing$.next(listing);
    }
    this._eventChecker.updateListing$.next('refresh');
    this.router.navigateByUrl('/user-home/my-listings');
  }

  redirectTo(uri: any) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }
}
