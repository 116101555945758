import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavParams, IonInput, AlertController } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { RangeSearchFiltersMeta, MinMaxSearchFiltersMeta } from '../../../models/office-search-filters.model';
import { ListPropertyService } from '@app/core/listings/list-property.service';
import { I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';

@Component({
  selector: 'coworking-page',
  templateUrl: './remove-credits-popover.component.html',
  styleUrls: ['./remove-credits-popover.component.scss']
})
export class RemoveCreditsPopoverComponent implements OnInit {
  propertyType: any;
  expandFilters: any;
  rangeFilters: any;
  btnFilter: boolean = false;
  minMaxvalues: any;
  rangeModelValues: any;
  staticMinMax: any;
  searchDto: any;
  loading: boolean = true;
  propertySubTypes: any = [];
  buildingType: any;
  coworkingType: any;
  coworkingPositions: any;
  selectedPropertyType: any;
  popDataCalled: boolean = false;
  maxCredits: number;
  credits: number;
  listingPurchaseId: number;
  @ViewChild('posInput', { static: false }) posInput: IonInput;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private listingService: ListingService,
    private alertCtrl: AlertController,
    private i18nService: I18nService
  ) {}

  ngOnInit(): void {
    this.getPopData();
    //this.setMinOptions(this.rangeFilters, this.searchDto);
  }

  coworkingTypeChanged(event: CustomEvent) {
    if (this.popDataCalled) {
      this.popDataCalled = false;
      return;
    }
    this.coworkingType = event.detail.value;
  }

  getPopData() {
    this.popDataCalled = true;

    this.coworkingType = 'shared';
    this.listingPurchaseId = this.navParams.data.listingPurchaseId;
    this.maxCredits = this.navParams.data.availableCredits;
    this.credits = 1;
  }

  async remove() {
    if (this.credits > this.maxCredits) {
      let paymentProcessedHeader = this.i18nService.get('global.my-payment.cannotRemoveCredits');
      let paymentProcessedSubHeader = this.i18nService.get('global.my-payment.cannotRemoveCreditsBody');
      let paymentProcessedOk = this.i18nService.get('global.purchase-options.paymentProcessedOK');
      const alert = await this.alertCtrl.create({
        header: paymentProcessedHeader,
        subHeader: paymentProcessedSubHeader,
        buttons: [paymentProcessedOk]
      });
      await alert.present();
      this.popoverCtrl.dismiss({});
      return;
    }
    this.listingService
      .removeListingCredits(this.listingPurchaseId, this.credits)
      .toPromise()
      .then(async (result: any) => {
        let paymentProcessedHeader = this.i18nService.get('global.my-payment.creditsRemoved');
        let paymentProcessedSubHeader = this.i18nService.get('global.my-payment.creditsRemovedBody');
        let paymentProcessedOk = this.i18nService.get('global.purchase-options.paymentProcessedOK');
        const alert = await this.alertCtrl.create({
          header: paymentProcessedHeader,
          subHeader: paymentProcessedSubHeader,
          buttons: [paymentProcessedOk]
        });
        await alert.present();
        this.popoverCtrl.dismiss({});
      });
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  validateCredits() {
    //if (this.credits > this.maxCredits) {
    //  this.credits = 1;
    //}
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }
}
