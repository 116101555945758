import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-office-filters',
  templateUrl: './office-filters.component.html',
  styleUrls: ['./office-filters.component.scss']
})
export class OfficeFilterComponent implements OnInit {
  @Input('propertyType') propertyType: any;
  @Input('searchDto') searchDto: any;

  constructor() {}

  ngOnInit(): void {}
}
