import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, map, catchError, filter } from 'rxjs/operators';
import { ToastController, AlertController } from '@ionic/angular';
import { I18nService } from './i18n.service';
import { element } from 'protractor';

@Injectable()
export class RegisterService {
  private emailHost: string = 'api/account/existing-email/';
  private forgotPasswordEndpoint: string = 'api/forgot-password';
  private changePasswordEndpoint: string = 'api/account/forced-change-password';
  private listingHost: string = 'api/companies/with-listings-access';
  private registerHost: string = 'api/account/listings-app/sign-up';
  private imgHost: string = 'api/images/base64';
  private offeredByUserApi: string = 'api/users/allOfSameCompany';
  private status: string = 'api/listingStatuss';
  private usersByCompany: string = 'api/companys/get-users-by-company/';
  private measurement: string = 'api/measurementStandards';
  private subType: string = 'api/lookUpTables/building-types/';
  private headers: any = {
    headers: new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Accept', 'application/json')
  };
  private keyForChangePassword: String;

  constructor(
    private http: HttpClient,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private i18NService: I18nService
  ) {}

  getEmailUnique(email: string) {
    return this.http.get(`${this.emailHost}${email}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getListingCompanies() {
    return this.http.get(`${this.listingHost}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getUsers() {
    return this.http.get(`${this.offeredByUserApi}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getListingStatus() {
    return this.http.get(`${this.status}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getUsersByCompany(id: number) {
    return this.http.get(`${this.usersByCompany}${id}/active`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getMeasurementStandard() {
    return this.http.get(`${this.measurement}`, { observe: 'response' }).pipe(map(val => val.body));
  }

  getBuildingSubTypes(id: number) {
    return this.http.get(`${this.subType}${id}/property-subtypes`, { observe: 'response' }).pipe(map(val => val.body));
  }

  register(payload: any) {
    let httpParams = new HttpParams();
    Object.keys(payload).forEach(function(key) {
      if (key === 'userPhones') {
        httpParams = httpParams.append(key, JSON.stringify(payload[key]));
      } else {
        httpParams = httpParams.append(key, payload[key]);
      }
    });
    return this.http.post(`${this.registerHost}`, httpParams, this.headers).pipe(map(val => val));
  }

  uploadUserImage(payload: any) {
    return this.http.post(`${this.imgHost}`, payload).pipe(map(val => val));
  }

  formatUserDetails(registerObj: any, croppedObj?: any, uncroppedObj?: any) {
    let phoneNumber = registerObj.phoneNumber ? registerObj.phoneNumber.nationalNumber : null;
    let phoneNumberIso = registerObj.phoneNumber ? registerObj.phoneNumber.countryCode : null;
    let whatsappNumber = registerObj.whatsappNumber ? registerObj.whatsappNumber.nationalNumber : null;
    let whatsappNumberIso = registerObj.whatsappNumber ? registerObj.whatsappNumber.countryCode : null;
    let postalCode = registerObj.postalCode;
    let selectedComp = registerObj.companySelected.toString();

    if (selectedComp == '') {
      registerObj.companyId = registerObj.dropdown.id ? registerObj.dropdown.id : null;
      registerObj.companyName = registerObj.dropdown.name ? registerObj.dropdown.name : null;
    } else {
      registerObj.companyId = null;
      registerObj.companyName = selectedComp;
    }

    registerObj.userPhones = [];
    if (phoneNumber) {
      let obj = { phoneType: 'PHONE_NUMBER', phoneNumber: phoneNumber, isoCode: phoneNumberIso };
      registerObj.userPhones.push(obj);
    }
    if (whatsappNumber) {
      let obj = { phoneType: 'WHATSAPP', phoneNumber: whatsappNumber, isoCode: whatsappNumberIso };
      registerObj.userPhones.push(obj);
    }
    if (postalCode) {
      registerObj.postalCode = postalCode;
    }

    delete registerObj['confirmPass'];
    delete registerObj['dropdown'];
    delete registerObj['companySelected'];

    Object.keys(registerObj).forEach(key => {
      if (
        key !== 'whatsApp' &&
        (registerObj[key] === null || (isNaN(parseFloat(registerObj[key])) && !registerObj[key].length))
      ) {
        delete registerObj[key];
      }
    });
    if (croppedObj) {
      registerObj.originalImageId = croppedObj.id;
    }
    if (uncroppedObj) {
      registerObj.resizedImageId = uncroppedObj.id;
    }

    return registerObj;
  }

  async presentToast() {
    const toast = await this.toastCtrl.create({
      message: 'Email is already in use.',
      duration: 3500,
      animated: true,
      color: 'danger',
      keyboardClose: true,
      cssClass: 'toast-alert'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  async showAlert(title: string, message: string, onDismiss: () => void = () => {}) {
    const okText = this.i18NService.get('global.OK');
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: message,
      buttons: [okText]
    });
    alert.onDidDismiss().then(onDismiss);
    alert.present();
  }

  async showUploadErr() {
    const toast = await this.toastCtrl.create({
      message: 'File size exceeded. Please try again.',
      duration: 3500,
      animated: true,
      color: 'danger',
      keyboardClose: true,
      cssClass: 'toast-alert'
    });
    toast.onDidDismiss().then(() => {});
    return await toast.present();
  }

  public forgotPassword(body: any) {
    return this.http.post(`${this.forgotPasswordEndpoint}`, body).toPromise();
  }

  public changePassword(body: any) {
    return this.http.post(`${this.changePasswordEndpoint}`, body).toPromise();
  }

  setKeyForChangePassword(key: String) {
    this.keyForChangePassword = key;
  }

  getKeyForChangePassword() {
    return this.keyForChangePassword;
  }

  async getAmenities(type: any) {
    if (type === 'industrial') {
      let industrialFilters: any = [
        {
          selected: false,
          name: 'global.addtSearchFilters.twentyFourHourSecurity',
          fieldName: 'twentyFourHourSecurity'
        },
        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
        { selected: false, name: 'global.addtSearchFilters.armoredCabin', fieldName: 'armoredCabin' },
        { selected: false, name: 'global.addtSearchFilters.manufacturing', fieldName: 'manufacturing' },
        //{ selected: false, name: 'global.addtSearchFilters.logistics', fieldName: 'logistics' },
        { selected: false, name: 'global.addtSearchFilters.officeSpaceAvailable', fieldName: 'officeSpaceAvailable' },
        //{ selected: false, name: 'global.addtSearchFilters.fireProtectionSystem', fieldName: 'fireProtectionSystem' },
        { selected: false, name: 'global.addtSearchFilters.blockConstruction', fieldName: 'blockConstruction' },
        { selected: false, name: 'global.addtSearchFilters.steelConstruction', fieldName: 'steelConstruction' },
        {
          selected: false,
          name: 'global.addtSearchFilters.blockAndSteelConstruction',
          fieldName: 'blockAndSteelConstruction'
        },
        {
          selected: false,
          name: 'global.addtSearchFilters.skyLights',
          fieldName: 'skyLights'
        },
        { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
        { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
        //{ selected: false, name: 'global.addtSearchFilters.industrialPark', fieldName: 'industrialPark' },
        //{ selected: false, name: 'global.addtSearchFilters.industrialZone', fieldName: 'industrialZone' },
        { selected: false, name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard' },
        { selected: false, name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking' },
        { selected: false, name: 'global.addtSearchFilters.refrigerated', fieldName: 'refrigerated' },
        { selected: false, name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate' },
        { selected: false, name: 'global.addtSearchFilters.lockerRoom', fieldName: 'hasLockerRooms' }
      ];
      return industrialFilters;
    }
    if (type === 'office') {
      let officeFilters: any = [
        {
          selected: false,
          name: 'global.addtSearchFilters.twentyFourHourSecurity',
          fieldName: 'twentyFourHourSecurity'
        },
        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
        { selected: false, name: 'global.addtSearchFilters.plugAndPlay', fieldName: 'plugAndPlay' },
        //{ selected: false, name: 'global.addtSearchFilters.finishedOut', fieldName: 'finishedOut' },
        { selected: false, name: 'global.addtSearchFilters.furnished', fieldName: 'furnished' },
        { selected: false, name: 'global.addtSearchFilters.coreAndShel', fieldName: 'coreAndShel' },
        {
          selected: false,
          name: 'global.addtSearchFilters.centralAirConditioning',
          fieldName: 'centralAirConditioning'
        },
        {
          selected: false,
          name: 'global.addtSearchFilters.miniSplitAirConditioning',
          fieldName: 'miniSplitAirConditioning'
        },
        { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
        { selected: false, name: 'global.addtSearchFilters.leedStatus', fieldName: 'leedStatus' },
        { selected: false, name: 'global.addtSearchFilters.raisedFloor', fieldName: 'raisedFloor' },
        { selected: false, name: 'global.addtSearchFilters.neverUsed', fieldName: 'neverUsed' }
      ];
      return officeFilters;
    }
  }

  async getAmenitiesBuildings(type: any, offer: string) {
    if (type === 'industrialBuildings') {
      let industrialFilters: any = [];
      return industrialFilters;
    }
    if (type === 'officeBuildings') {
      if (offer == 'L') {
        let buildingsOfficeFilters: any = [
          {
            selected: false,
            name: 'global.addtSearchFilters.twentyFourHourSecurity',
            fieldName: 'twentyFourHourSecurity'
          },
          { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
          { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
          { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },

          { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
          { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
          { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },

          { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
          {
            selected: false,
            name: 'global.addtSearchFilters.centralAirConditioning',
            fieldName: 'centralAirConditioning'
          },
          {
            selected: false,
            name: 'global.addtSearchFilters.petFriendly',
            fieldName: 'petFriendly'
          }
        ];
        return buildingsOfficeFilters;
      } else {
        let buildingsOfficeFilters: any = [
          {
            selected: false,
            name: 'global.addtSearchFilters.twentyFourHourSecurity',
            fieldName: 'twentyFourHourSecurity'
          },
          { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
          { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
          { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },

          { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
          { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
          { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
          {
            selected: false,
            name: 'global.addtSearchFilters.centralAirConditioning',
            fieldName: 'centralAirConditioning'
          }
        ];
        return buildingsOfficeFilters;
      }
    }
  }

  selectExistingFeatures(listing: any, officeFilters: any, industrialFilters: any) {
    Object.keys(listing).forEach(key => {
      if (industrialFilters) {
        if (listing[key] === true) {
          let result = industrialFilters.find((filter: any) => filter.fieldName === key);
          if (result) {
            result.selected = true;
          }
        }
      } else {
        if (listing[key] === true) {
          let result = officeFilters.find((filter: any) => filter.fieldName === key);
          if (result) {
            result.selected = true;
          }
        }
      }
    });
    if (officeFilters) {
      return officeFilters;
    } else {
      return industrialFilters;
    }
  }

  selectExistingFeaturesFromBuilding(building: any, officeFilters: any, industrialFilters: any) {
    Object.keys(building).forEach(key => {
      if (industrialFilters) {
        if (
          building[key] === true &&
          (key === 'twentyFourHourSecurity' ||
            key === 'armoredCabin' ||
            key === 'manufacturing' ||
            key === 'electricGenerator' ||
            key === 'sprinklers' ||
            key === 'truckYard' ||
            key === 'crossDocking' ||
            key === 'heliport')
        ) {
          let result = industrialFilters.find((filter: any) => filter.fieldName === key);
          if (result) {
            result.selected = true;
          }
        }
      } else {
        if (
          building[key] === true &&
          (key === 'twentyFourHourSecurity' ||
            key === 'centralAirConditioning' ||
            key === 'miniSplitAirConditioning' ||
            key === 'electricGenerator' ||
            key === 'sprinklers' ||
            key === 'bicycleRack' ||
            key === 'leedStatus' ||
            key === 'heliport')
        ) {
          let result = officeFilters.find((filter: any) => filter.fieldName === key);
          if (result) {
            result.selected = true;
          }
        }
      }
    });
    if (officeFilters) {
      return officeFilters;
    } else {
      return industrialFilters;
    }
  }
}
