import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
var AccountService = /** @class */ (function () {
    function AccountService(http, platformId) {
        this.http = http;
        this.platformId = platformId;
        this.currentUser$ = new BehaviorSubject(this.getCurrentUser);
        this.seenPersonalDataFlagKey = 'seenPersonalDataPopup';
        this.endpoint = "api/account";
        this.externalIpEndpoint = "api/account/ip";
        this.unreadMessagesEndpoint = "api/userChat/unreadConversationsFromSupport";
        this.publicProfilePhoto = 'api/account/photoByUserLogin';
        this.keepConnectedEndpoint = 'api/account/keep-connected';
        this.acceptPersonalDataEndpoint = '/api/account/acceptPersonalData/';
        this.changeLangAPI = '/api/account/change-language/';
    }
    Object.defineProperty(AccountService.prototype, "getCurrentUser", {
        get: function () {
            return !!localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountService.prototype, "getPersonalDataFlag", {
        get: function () {
            return localStorage.getItem(this.seenPersonalDataFlagKey)
                ? JSON.parse(localStorage.getItem(this.seenPersonalDataFlagKey))
                : null;
        },
        enumerable: true,
        configurable: true
    });
    AccountService.prototype.getAccount = function () {
        return this.http.get(this.endpoint);
    };
    AccountService.prototype.getProfilePicture = function (login) {
        return this.http.get(this.publicProfilePhoto + "/" + login, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    AccountService.prototype.getAccountFast = function () {
        return this.http.get(this.endpoint + "/deviceFast");
    };
    AccountService.prototype.getUserIP = function () {
        return this.http.get("" + this.externalIpEndpoint).toPromise();
    };
    AccountService.prototype.getUnreadMessagesCount = function () {
        return this.http.get("" + this.unreadMessagesEndpoint);
    };
    AccountService.prototype.saveUserProfile = function (userProfile) {
        return this.http.post(this.endpoint, userProfile);
    };
    AccountService.prototype.activateUserWithHash = function (hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("api/account/hash/" + hash + "/activate", null).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AccountService.prototype.getUserPhotoList = function () {
        return this.http.get('api/account/photos');
    };
    AccountService.prototype.changeUserEmail = function (login, newEmail) {
        return this.http.post("/api/account/" + login + "/change-email", newEmail);
    };
    AccountService.prototype.changeUserPassword = function (passwordProps) {
        return this.http.post('/api/account/change-password', passwordProps);
    };
    AccountService.prototype.setKeepConnected = function (keepConnectedData) {
        var _this = this;
        return this.http.put(this.keepConnectedEndpoint, keepConnectedData).subscribe(function () {
            var user = tslib_1.__assign({}, _this.getCurrentUser, { keepConnected: keepConnectedData.keepConnected });
            localStorage.setItem('user', JSON.stringify(user));
            _this.currentUser$.next(user);
        }, function (error) {
            throw Error(error);
        });
    };
    AccountService.prototype.getUploadedImageId = function (imageList) {
        var uploadedImage = {};
        if (!imageList.length) {
            uploadedImage.originalImageId = null;
            uploadedImage.profileImageId = null;
        }
        else if (imageList.length < 2) {
            uploadedImage.originalImageId = imageList[0];
            uploadedImage.profileImageId = imageList[0];
        }
        else {
            uploadedImage.originalImageId = imageList[imageList.length - 2];
            uploadedImage.profileImageId = imageList[imageList.length - 1];
        }
        return uploadedImage;
    };
    AccountService.prototype.acceptPersonalData = function (date) {
        return this.http.post("" + this.acceptPersonalDataEndpoint + date, {}).subscribe(function () { }, function (error) { });
    };
    AccountService.prototype.accountLogout = function () {
        localStorage.removeItem(this.seenPersonalDataFlagKey);
        this.currentUser$.next(null);
    };
    AccountService.prototype.changeLanguage = function (language) {
        return this.http.post(this.changeLangAPI, language).toPromise();
    };
    return AccountService;
}());
export { AccountService };
