import * as tslib_1 from "tslib";
import { PlatformHelperService } from './helpers/platform-helper.service';
var WhatsAppService = /** @class */ (function () {
    function WhatsAppService(platformHelperService) {
        this.platformHelperService = platformHelperService;
        this.whatsAppAPI = 'https://wa.me';
    }
    WhatsAppService.prototype.sendMessageViaWhataspp = function (phoneNumber, defaultMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fullPhoneInternationalFormat, encodedText, phone, whatsAppLink, whatsAppWindow;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                fullPhoneInternationalFormat = phoneNumber.replace(/[(\s\D+)]/g, '');
                encodedText = encodeURIComponent(defaultMsg);
                phone = Number(fullPhoneInternationalFormat);
                whatsAppLink = this.buildWhatasppLink(phone, encodedText);
                whatsAppWindow = window.open(whatsAppLink, '_system');
                setTimeout(function () {
                    if (!_this.platformHelperService.isDesktopMode()) {
                        whatsAppWindow.close();
                    }
                }, 1000);
                return [2 /*return*/];
            });
        });
    };
    WhatsAppService.prototype.buildWhatasppLink = function (phoneNumber, preFilledMsg) {
        return this.whatsAppAPI + "/" + phoneNumber + "?text=" + preFilledMsg + "&time=" + new Date();
    };
    return WhatsAppService;
}());
export { WhatsAppService };
