import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageService, PlatformHelperService } from '@app/core';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss']
})
export class PreviewImageComponent implements OnInit {
  imageSrc: string = '';
  defaultImage: string = this.platformConfigHelper.Defaults().imagePlaceholder;

  constructor(
    private route: ActivatedRoute,
    private platformConfigHelper: PlatformHelperService,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    this.setUpImageId();
  }

  setUpImageId() {
    const imageId = this.route.snapshot.params['id'];
    if (imageId && Number(imageId)) {
      this.imageSrc = this.getMediumImg(imageId);
    }
  }

  getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }
}
