import { state } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService, I18nService, PlatformHelperService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { icon } from '@fortawesome/fontawesome-svg-core';

import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FavoriteSpotService } from '../../../../../../shared/services/favorite-spot.service';
@Component({
  selector: 'app-spot-map-mobile-card',
  templateUrl: './spot-map-mobile-card.component.html',
  styleUrls: ['./spot-map-mobile-card.component.scss']
})
export class SpotMapMobileCardComponent implements OnInit {
  @Input() spot: SpotBuildingDetailDTO;
  defaultImage: string = this.platformConfigHelper.Defaults().imagePlaceholder;
  faHeart: any;

  constructor(
    private spotBuildingsService: SpotBuildingsService,
    private imageService: ImageService,
    private router: Router,
    public commonService: CommonGenericService,
    public i18n: I18nService,
    private platformConfigHelper: PlatformHelperService,
    private favSpotService: FavoriteSpotService
  ) {}

  ngOnInit() {
    this.faHeart = faHeart;
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  getCroppedOrOriginalImage(listingPhoto: any) {
    if (listingPhoto) {
      if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
        return listingPhoto.croppedImage.id;
      }
      return listingPhoto.image.id;
    }
  }

  public async openCard(id: number, $event: Event, externalLink = false) {
    $event.stopImmediatePropagation();

    await this.spotBuildingsService.goToSpotDetailsURL(id).then((spotURL: any) => {
      this.router.navigateByUrl(spotURL, { state: state });
    });
  }

  public getAvailableAreaLabel(spot: SpotBuildingDetailDTO) {
    return this.commonService.getAvailableAreaLabel(spot);
  }

  public getAvailableAreaText(spot: SpotBuildingDetailDTO) {
    return this.commonService.getAvailableAreaText(spot.spaceRangesDTO, spot.type);
  }

  public getAvgAskingRent(spot: SpotBuildingDetailDTO) {
    return this.commonService.getAvgAskingRent(spot, spot.type);
  }

  buildFavBtnClass(listing: any) {
    const favClass = this.getFavClass(listing);
    const customClasses = `spot-info-window__title__favorite-button ${favClass} `;
    return customClasses;
  }

  getFavClass(listing: any) {
    return listing.favorite ? 'is-favorite' : 'no-favorite';
  }

  getCityState(listing: any) {
    return `${listing.id} - ${this.i18n.getTranslation(listing.cityName)} - ${this.i18n.getTranslation(
      listing.stateName
    )}`;
  }

  getSpotImg(listing: any) {
    const photo = this.spot && this.spot.buildingListingPhotos ? this.spot.buildingListingPhotos[0] : null;
    const photoURL =
      photo && photo.image ? this.getMediumImg(this.getCroppedOrOriginalImage(photo)) : this.defaultImage;

    return photoURL;
  }

  favoriteListener(event: any, spot: SpotBuildingDetailDTO) {
    event.stopImmediatePropagation();
    let favBtnElement = document.getElementById('favButton');

    this.favSpotService.setFavoriteSpot(event, spot).then((resp: any) => {
      let currentFavClass = favBtnElement.className;
      currentFavClass = currentFavClass.replace('is-favorite', '');
      currentFavClass = currentFavClass.replace('no-favorite', '');
      favBtnElement.className = `${currentFavClass} ${this.getFavClass(spot)}`;
    });
  }
}
