<!-- Range Filters-->

<!-- industrial -->
<div
  class="filter-container ml-3"
  [ngClass]="{ 'industrial-lease': type[0] === 'L', 'industrial-sale': type[0] === 'S' }"
  *ngIf="property == 1001"
  id="ranges-container"
>
  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">{{ 'buildinsDetail.totalAreaSaleFilter' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.lower"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  value="{{ rangeModelValues.totalArea.upper.toFixed(2) }}"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.upper"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.askingPrice' | translate }}({{ searchDto.currencyType }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalAskingPrice"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalAskingPrice.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('totalAskingPrice')"
                  [(ngModel)]="rangeModelValues.totalAskingPrice.lower"
                  [max]="staticMinMax.totalAskingPrice.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('totalAskingPrice')"
                  [(ngModel)]="rangeModelValues.totalAskingPrice.upper"
                  [max]="staticMinMax.totalAskingPrice.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">
        {{ 'buildinsDetail.askingPriceRm' | translate }} ({{ searchDto.currencyType }}/m²)
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.askingPricePerArea"
            ticks="false"
            [min]="staticMinMax.askingPricePerArea.min"
            [max]="staticMinMax.askingPricePerArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('askingPricePerArea')"
                  [(ngModel)]="rangeModelValues.askingPricePerArea.lower"
                  [max]="staticMinMax.askingPricePerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('askingPricePerArea')"
                  [(ngModel)]="rangeModelValues.askingPricePerArea.upper"
                  [max]="staticMinMax.askingPricePerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.propertySize' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.propertySize"
            ticks="false"
            min="0"
            [max]="staticMinMax.propertySize.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.lower"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.upper"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.totalLanArea' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.landArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.landArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('landArea')"
                  [(ngModel)]="rangeModelValues.landArea.lower"
                  [max]="staticMinMax.landArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('landArea')"
                  [(ngModel)]="rangeModelValues.landArea.upper"
                  [max]="staticMinMax.landArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">{{ 'global.addtSearchFilters.ceilingHeight' | translate }} (m)</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.ceilingHeight"
            ticks="false"
            min="0"
            [max]="staticMinMax.ceilingHeight.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('ceilingHeight')"
                  [(ngModel)]="rangeModelValues.ceilingHeight.lower"
                  [max]="staticMinMax.ceilingHeight.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('ceilingHeight')"
                  [(ngModel)]="rangeModelValues.ceilingHeight.upper"
                  [max]="staticMinMax.ceilingHeight.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title">
        {{ 'buildinsDetail.availableSpace' | translate }}
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.lower"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.upper"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title" style="font-weight: 600;font-size: 14px;">
        {{ 'global.currency' | translate }}
      </ion-label>
    </div>
    <ion-list>
      <div
        style="width: 50%;float: left; margin-left: 10px;
      "
      >
        <input type="checkbox" [checked]="usd" (click)="setCurrency('USD', 1)" />
        <ion-label class="weight" style="margin-left: 7px;" translate="global.usd"></ion-label>
      </div>
      <div style="margin-left: 2%;">
        <input type="checkbox" [checked]="mxn" (click)="setCurrency('MXN', 2)" />
        <ion-label class="weight" style="margin-left: 7px;" translate="global.mxn"></ion-label>
      </div>
    </ion-list>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.totalMonthlyRent' | translate }} ({{ searchDto.currencyType + '$' }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalMonthlyRent"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalMonthlyRent.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('totalMonthlyRent')"
                  [(ngModel)]="rangeModelValues.totalMonthlyRent.lower"
                  [max]="staticMinMax.totalMonthlyRent.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('totalMonthlyRent')"
                  [(ngModel)]="rangeModelValues.totalMonthlyRent.upper"
                  [max]="staticMinMax.totalMonthlyRent.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title">
        {{ 'buildinsDetail.monthlyRentRange' | translate }} ({{ searchDto.currencyType + '/m²' }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.monthlyRentPerArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.monthlyRentPerArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('monthlyRentPerArea')"
                  [(ngModel)]="rangeModelValues.monthlyRentPerArea.lower"
                  [max]="staticMinMax.monthlyRentPerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('monthlyRentPerArea')"
                  [(ngModel)]="rangeModelValues.monthlyRentPerArea.upper"
                  [max]="staticMinMax.monthlyRentPerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title">{{ 'global.addtSearchFilters.ceilingHeight' | translate }} (m)</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.ceilingHeight"
            ticks="false"
            min="0"
            [max]="staticMinMax.ceilingHeight.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('ceilingHeight')"
                  [(ngModel)]="rangeModelValues.ceilingHeight.lower"
                  [max]="staticMinMax.ceilingHeight.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('ceilingHeight')"
                  [(ngModel)]="rangeModelValues.ceilingHeight.upper"
                  [max]="staticMinMax.ceilingHeight.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.propertySize' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.propertySize"
            ticks="false"
            min="0"
            [max]="staticMinMax.propertySize.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.lower"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.upper"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.kva' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.kva"
            ticks="false"
            min="0"
            [max]="staticMinMax.kva.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('kva')"
                  [(ngModel)]="rangeModelValues.kva.lower"
                  [max]="staticMinMax.kva.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('kva')"
                  [(ngModel)]="rangeModelValues.kva.upper"
                  [max]="staticMinMax.kva.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
      <br />
      <ion-row>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker)"
              [(ngModel)]="yearBuilt1"
              [matDatepicker]="datepicker"
              [max]="rangeModelValues.yearBuilt.upper"
              [min]="rangeModelValues.yearBuilt.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker
            (yearSelected)="_yearSelectedHandler($event, datepicker, 1)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker2)"
              [(ngModel)]="yearBuilt2"
              [matDatepicker]="datepicker2"
              [max]="rangeModelValues.yearBuilt.upper"
              [min]="rangeModelValues.yearBuilt.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker2
            (yearSelected)="_yearSelectedHandler($event, datepicker2, 2)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
      </ion-row>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.yearRenovated' | translate }} </ion-label>
      <br />
      <ion-row>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker3)"
              [(ngModel)]="yearRenovated1"
              [matDatepicker]="datepicker3"
              [max]="rangeModelValues.yearRenovated.upper"
              [min]="rangeModelValues.yearRenovated.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker3"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker3
            (yearSelected)="_yearSelectedHandler($event, datepicker3, 3)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker4)"
              [(ngModel)]="yearRenovated2"
              [matDatepicker]="datepicker4"
              [max]="rangeModelValues.yearRenovated.upper"
              [min]="rangeModelValues.yearRenovated.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker4"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker4
            (yearSelected)="_yearSelectedHandler($event, datepicker4, 4)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>

<!-- office -->
<div
  class="filter-container ml-3"
  [ngClass]="{ 'office-lease': type[0] === 'L', 'office-sale': type[0] === 'S' }"
  *ngIf="property == 2001 && type[0] !== 'C'"
  id="ranges-container"
>
  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title">
        {{ 'buildinsDetail.availableSpace' | translate }}
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.lower"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.upper"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title" style="font-weight: 600;font-size: 14px;">
        {{ 'global.currency' | translate }}
      </ion-label>
    </div>
    <ion-list>
      <div
        style="width: 50%;float: left; margin-left: 10px;
      "
      >
        <input type="checkbox" [checked]="usd" (click)="setCurrency('USD', 1)" />
        <ion-label class="weight" style="margin-left: 7px;" translate="global.usd"></ion-label>
      </div>
      <div style="margin-left: 2%;">
        <input type="checkbox" [checked]="mxn" (click)="setCurrency('MXN', 2)" />
        <ion-label class="weight" style="margin-left: 7px;" translate="global.mxn"></ion-label>
      </div>
    </ion-list>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.totalMonthlyRent' | translate }} ({{ searchDto.currencyType + '$' }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalMonthlyRent"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalMonthlyRent.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('totalMonthlyRent')"
                  [(ngModel)]="rangeModelValues.totalMonthlyRent.lower"
                  [max]="staticMinMax.totalMonthlyRent.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('totalMonthlyRent')"
                  [(ngModel)]="rangeModelValues.totalMonthlyRent.upper"
                  [max]="staticMinMax.totalMonthlyRent.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.monthlyRentRange' | translate }} ({{ searchDto.currencyType + '/m²' }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.monthlyRentPerArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.monthlyRentPerArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('monthlyRentPerArea')"
                  [(ngModel)]="rangeModelValues.monthlyRentPerArea.lower"
                  [max]="staticMinMax.monthlyRentPerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('monthlyRentPerArea')"
                  [(ngModel)]="rangeModelValues.monthlyRentPerArea.upper"
                  [max]="staticMinMax.monthlyRentPerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">{{ 'buildinsDetail.totalAreaSaleFilter' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.lower"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('area')"
                  [(ngModel)]="rangeModelValues.totalArea.upper"
                  [max]="staticMinMax.totalArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.askingPrice' | translate }} ({{ searchDto.currencyType }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.totalAskingPrice"
            ticks="false"
            min="0"
            [max]="staticMinMax.totalAskingPrice.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('totalAskingPrice')"
                  [(ngModel)]="rangeModelValues.totalAskingPrice.lower"
                  [max]="staticMinMax.totalAskingPrice.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('totalAskingPrice')"
                  [(ngModel)]="rangeModelValues.totalAskingPrice.upper"
                  [max]="staticMinMax.totalAskingPrice.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.askingPriceRm' | translate }} ({{ searchDto.currencyType }}/m²)
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.askingPricePerArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.askingPricePerArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('askingPricePerArea')"
                  [(ngModel)]="rangeModelValues.askingPricePerArea.lower"
                  [max]="staticMinMax.askingPricePerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('askingPricePerArea')"
                  [(ngModel)]="rangeModelValues.askingPricePerArea.upper"
                  [max]="staticMinMax.askingPricePerArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.propertySize' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.propertySize"
            ticks="false"
            min="0"
            [max]="staticMinMax.propertySize.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.lower"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.upper"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.floors' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.numberFloor"
            ticks="false"
            min="0"
            [max]="staticMinMax.numberFloor.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('numberFloor')"
                  [(ngModel)]="rangeModelValues.numberFloor.lower"
                  [max]="staticMinMax.numberFloor.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('numberFloor')"
                  [(ngModel)]="rangeModelValues.numberFloor.upper"
                  [max]="staticMinMax.numberFloor.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'">
    <div>
      <ion-label class="font-title">{{ 'global.addtSearchFilters.ceilingHeight' | translate }} (m)</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.ceilingHeight"
            ticks="false"
            min="0"
            [max]="staticMinMax.ceilingHeight.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('ceilingHeight')"
                  [(ngModel)]="rangeModelValues.ceilingHeight.lower"
                  [max]="staticMinMax.ceilingHeight.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('ceilingHeight')"
                  [(ngModel)]="rangeModelValues.ceilingHeight.upper"
                  [max]="staticMinMax.ceilingHeight.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div *ngIf="type == 'L'"></div>

  <div *ngIf="type == 'S'">
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.totalLanArea' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.landArea"
            ticks="false"
            min="0"
            [max]="staticMinMax.landArea.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('landArea')"
                  [(ngModel)]="rangeModelValues.landArea.lower"
                  [max]="staticMinMax.landArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('landArea')"
                  [(ngModel)]="rangeModelValues.landArea.upper"
                  [max]="staticMinMax.landArea.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.addtSearchFilters.carParkingSpaces' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.parkingSpaces"
            ticks="false"
            min="0"
            [max]="staticMinMax.parkingSpaces.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('parkingSpaces')"
                  [(ngModel)]="rangeModelValues.parkingSpaces.lower"
                  [max]="staticMinMax.parkingSpaces.max"
                  [min]="staticMinMax.parkingSpaces.min"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('parkingSpaces')"
                  [(ngModel)]="rangeModelValues.parkingSpaces.upper"
                  [max]="staticMinMax.parkingSpaces.max"
                  [min]="staticMinMax.parkingSpaces.min"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
      <br />
      <ion-row>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass" style="width: 140px;">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker)"
              [(ngModel)]="yearBuilt1"
              [matDatepicker]="datepicker"
              [max]="rangeModelValues.yearBuilt.upper"
              [min]="rangeModelValues.yearBuilt.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker
            (yearSelected)="_yearSelectedHandler($event, datepicker, 1)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass" style="width: 140px;">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker2)"
              [(ngModel)]="yearBuilt2"
              [matDatepicker]="datepicker2"
              [max]="rangeModelValues.yearBuilt.upper"
              [min]="rangeModelValues.yearBuilt.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker2
            (yearSelected)="_yearSelectedHandler($event, datepicker2, 2)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
      </ion-row>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.yearRenovated' | translate }} </ion-label>
      <br />
      <ion-row>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass" style="width: 140px;">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker3)"
              [(ngModel)]="yearRenovated1"
              [matDatepicker]="datepicker3"
              [max]="rangeModelValues.yearRenovated.upper"
              [min]="rangeModelValues.yearRenovated.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker3"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker3
            (yearSelected)="_yearSelectedHandler($event, datepicker3, 3)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass" style="width: 140px;">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker4)"
              [(ngModel)]="yearRenovated2"
              [matDatepicker]="datepicker4"
              [max]="rangeModelValues.yearRenovated.upper"
              [min]="rangeModelValues.yearRenovated.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker4"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker4
            (yearSelected)="_yearSelectedHandler($event, datepicker4, 4)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>

<!-- Coworking -->
<div
  class="filter-container ml-3"
  [ngClass]="{ coworking: type[0] === 'C' }"
  *ngIf="property == 2001 && type[0] == 'C'"
  id="ranges-container"
>
  <div>
    <div>
      <ion-label class="font-title">{{ 'buildinsDetail.numberPositions' | translate }}</ion-label>

      <div class="col-6">
        <div class="content-input w-100 text-right" style="margin-top: 12px;">
          <ion-input class="form-control" style="text-align: left;" type="number" [(ngModel)]="searchDto.positions">
          </ion-input>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.propertySize' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.propertySize"
            ticks="false"
            min="0"
            [max]="staticMinMax.propertySize.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.lower"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('propertySize')"
                  [(ngModel)]="rangeModelValues.propertySize.upper"
                  [max]="staticMinMax.propertySize.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title"
        >{{ 'buildinsDetail.monthlyCostPerson' | translate }} ({{ searchDto.currencyType }})
      </ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.monthlyCostPerPerson"
            ticks="false"
            min="0"
            [max]="staticMinMax.monthlyCostPerPerson.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('monthlyCostPerPerson')"
                  [(ngModel)]="rangeModelValues.monthlyCostPerPerson.lower"
                  [max]="staticMinMax.monthlyCostPerPerson.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('monthlyCostPerPerson')"
                  [(ngModel)]="rangeModelValues.monthlyCostPerPerson.upper"
                  [max]="staticMinMax.monthlyCostPerPerson.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.floors' | translate }}</ion-label>
      <ion-list style="width: 100%" class="mt-3 mt-md-1" lines="none">
        <ion-item class="margin-range">
          <ion-range
            style="padding-top: 5px;"
            color="secondary"
            dual-knobs="true"
            pin="false"
            [(ngModel)]="rangeModelValues.numberFloor"
            ticks="false"
            min="0"
            [max]="staticMinMax.numberFloor.max"
            step="1"
            snaps="false"
          >
          </ion-range>
        </ion-item>
        <ion-item class="mt-2 mt-md-0">
          <div class="w-100 row m-0">
            <div class="col-6">
              <div style="float: left;">
                <ion-input
                  type="number"
                  (change)="checkValues('numberFloor')"
                  [(ngModel)]="rangeModelValues.numberFloor.lower"
                  [max]="staticMinMax.numberFloor.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
            <div class="col-6">
              <div class="content-input w-100 text-right">
                <ion-input
                  type="number"
                  (change)="checkValues('numberFloor')"
                  [(ngModel)]="rangeModelValues.numberFloor.upper"
                  [max]="staticMinMax.numberFloor.max"
                  min="0"
                >
                </ion-input>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.yearBuilt' | translate }} </ion-label>
      <br />
      <ion-row>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker)"
              [(ngModel)]="yearBuilt1"
              [matDatepicker]="datepicker"
              [max]="rangeModelValues.yearBuilt.upper"
              [min]="rangeModelValues.yearBuilt.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker
            (yearSelected)="_yearSelectedHandler($event, datepicker, 1)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker2)"
              [(ngModel)]="yearBuilt2"
              [matDatepicker]="datepicker2"
              [max]="rangeModelValues.yearBuilt.upper"
              [min]="rangeModelValues.yearBuilt.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker2"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker2
            (yearSelected)="_yearSelectedHandler($event, datepicker2, 2)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
      </ion-row>
    </div>
  </div>

  <div>
    <div>
      <ion-label class="font-title">{{ 'global.list-your-property.yearRenovated' | translate }} </ion-label>
      <br />
      <ion-row>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker3)"
              [(ngModel)]="yearRenovated1"
              [matDatepicker]="datepicker3"
              [max]="rangeModelValues.yearRenovated.upper"
              [min]="rangeModelValues.yearRenovated.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker3"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker3
            (yearSelected)="_yearSelectedHandler($event, datepicker3, 3)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
        <ion-col size="12" sizeMd="6">
          <mat-form-field class="w-100" appearance="standard" [ngClass]="jpCustomFormFieldClass">
            <input
              matInput
              (click)="_openDatepickerOnClick(datepicker4)"
              [(ngModel)]="yearRenovated2"
              [matDatepicker]="datepicker4"
              [max]="rangeModelValues.yearRenovated.upper"
              [min]="rangeModelValues.yearRenovated.lower"
              readonly
            />
            <mat-datepicker-toggle matSuffix [for]="datepicker4"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker
            #datepicker4
            (yearSelected)="_yearSelectedHandler($event, datepicker4, 4)"
            [touchUi]="touchUi"
            panelClass="jp-year-picker"
            startView="multi-year"
          >
          </mat-datepicker>
        </ion-col>
      </ion-row>
    </div>
  </div>
</div>
