import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'listing-analytics-info',
  templateUrl: './listing-analytics-info.component.html',
  styleUrls: ['./listing-analytics-info.component.scss']
})
export class ListingAnalyticsInfoComponent implements OnInit {
  @Input('listingAnalytics') listingAnalytics: any;
  @Input('listingCount') listingCount?: any;
  @Input('listing') listing?: any;
  constructor() {}

  ngOnInit() {}
}
