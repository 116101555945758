/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./startup/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "./startup/loading-spinner/loading-spinner.component";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i8 from "./app.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@ionic-native/status-bar/ngx/index";
import * as i11 from "@ionic-native/splash-screen/ngx/index";
import * as i12 from "./core/i18n.service";
import * as i13 from "@ngx-meta/core";
import * as i14 from "./shared/pwa-service";
import * as i15 from "./core/helpers/platform-helper.service";
import * as i16 from "./core/language.service";
import * as i17 from "@angular/material/dialog";
import * as i18 from "@angular/service-worker";
import * as i19 from "ngx-google-analytics";
import * as i20 from "./google-analytics/analytics-service";
import * as i21 from "./matomo/matomo-service";
import * as i22 from "./core/helpers/routing-helper.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(3, 114688, null, 0, i3.LoadingSpinnerComponent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "ion-router-outlet", [["main", ""]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i4.IonRouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i4.Config, i4.NavController, i6.Location, i1.ElementRef, i5.Router, i1.NgZone, i5.ActivatedRoute, [3, i4.IonRouterOutlet]], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-app", [], null, null, null, i7.View_IonApp_0, i7.RenderType_IonApp)), i1.ɵdid(1, 49152, null, 0, i4.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDataAvailable; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isDataAvailable; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i5.Router, i9.Title, i4.Platform, i10.StatusBar, i11.SplashScreen, i12.I18nService, i13.MetaService, i14.PWAService, i15.PlatformHelperService, i16.LanguageService, i17.MatDialog, i18.SwUpdate, i19.GoogleAnalyticsService, i20.AnalyticsService, i21.MatomoService, i6.Location, i22.RoutingHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
