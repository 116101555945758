import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { environment } from '@env/environment';
import { CoreModule, Logger } from '@app/core';
import { SharedModule } from '@app/shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SearchNavigationModule } from './search-navigation/search-navigation.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { InViewportModule } from 'ng-in-viewport';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';
import { MetaModule, MetaLoader, MetaStaticLoader, PageTitlePositioning } from '@ngx-meta/core';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from './core/seo';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCERS, metaReducers } from './store/reducers';
import { CartService } from './store/services/cart.service';
import { httpInterceptorProviders } from './interceptors';
import { UserHomeModule } from './user-home/user-home.module';
import { WebsocketModule } from './websockets';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotFoundModule } from './not-found/not-found.module';
import { PreviewImageModule } from './preview-image/preview-image.module';
import { AgmCoreModule } from '@agm/core';
import { SpotBuildingsModule } from './spot-buildings/spot-buildings.module';
import { CompanyModule } from './company/company.module';
import { BrokersModule } from './brokers/brokers.module';
import { HomeModule } from './home/home.module';
import { FavoritesModule } from './favorites/favorites.module';
import { StartupModule } from './startup/startup.module';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';

export function initializeLogger(logger: NGXLogger) {
  return () => {
    Logger.setLogger(logger);
  };
}

export function metaFactory(translate: TranslateService): MetaLoader {
  const baseImageURL = `${environment.spotServerUrl}/assets/images/icons/SiiLA-SPOT-Twitter-v2-440x220.png`;
  return new MetaStaticLoader({
    callback: (key: string) => translate.get(key),
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' | ',
    applicationName: 'SiiLA SPOT',
    defaults: {
      name: 'SiiLA SPOT',
      title: 'Logo SiiLA SPOT',
      'og:name': 'SiiLA SPOT',
      image: encodeURI(baseImageURL),
      description: translate.instant('meta'),
      url: environment.spotServerUrl,
      'og:title': 'Logo SiiLA SPOT',
      'og:site_name': 'SiiLA SPOT',
      'mobile-web-app-title': 'SiiLA SPOT',
      'og:description': translate.instant('meta'),
      'og:image': encodeURI(baseImageURL),
      'twitter:card': 'summary_large_image',
      'twitter:image': encodeURI(baseImageURL),
      'twitter:title': 'SiiLA SPOT',
      'twitter:description': translate.instant('meta'),
      'og:email': encodeURIComponent('contact.us@reixcorp.com'),
      'og:type': 'website',
      'og:url': encodeURI(environment.spotServerUrl),
      'og:locale': 'en_US',
      'og:locale:alternate': 'en_US,pt_BR,es_MX'
    }
  });
}

@NgModule({
  imports: [
    StartupModule,
    IonicModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    WebsocketModule.config({
      url: environment.ws
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsTracking),
    // NgxGoogleAnalyticsRouterModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
      deps: [TranslateService]
    }),
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    CoreModule,
    InViewportModule,
    SharedModule,
    ShellModule,
    SpotBuildingsModule,
    HomeModule,
    CompanyModule,
    BrokersModule,
    PreviewImageModule,
    SearchNavigationModule,
    FavoritesModule,
    UserHomeModule,
    AppRoutingModule,
    NotFoundModule,
    MatTooltipModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictStateSerializability: true,
        strictActionImmutability: true,
        strictActionSerializability: true
      }
    }),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ['geometry', 'places'],
      apiVersion: 'quarterly'
    })
  ],
  entryComponents: [],
  declarations: [AppComponent],
  providers: [
    ...httpInterceptorProviders,
    StatusBar,
    SplashScreen,
    CookieService,
    MetaService,
    CartService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLogger,
      deps: [NGXLogger],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
