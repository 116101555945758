import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { PopoverController, NavParams } from '@ionic/angular';
import { EventCheckerService } from '@app/core/listings';
var RenewModalComponent = /** @class */ (function () {
    function RenewModalComponent(router, purchaseCreditsService, popoverCtrl, navParams, _eventChecker) {
        this.router = router;
        this.purchaseCreditsService = purchaseCreditsService;
        this.popoverCtrl = popoverCtrl;
        this.navParams = navParams;
        this._eventChecker = _eventChecker;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.renewalChoice = 'activate';
    }
    RenewModalComponent.prototype.ngOnInit = function () {
        this.getPopData();
    };
    RenewModalComponent.prototype.getPopData = function () {
        this.expirationDate = this.navParams.data.credit.listingExpirationDate;
        this.listingClass = this.navParams.data.credit.premium;
        this.creditId = this.navParams.data.credit.id;
        this.listingId = this.navParams.data.listingId;
        this.listing = this.navParams.data.listing;
    };
    RenewModalComponent.prototype.choiceSelection = function (event) {
        this.renewalChoice = event.detail.value;
    };
    RenewModalComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    RenewModalComponent.prototype.renew = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listing, listing;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.popoverCtrl.dismiss();
                        if (!(this.renewalChoice === 'activate')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.purchaseCreditsService.renewListing(this.creditId, this.listingId)];
                    case 1:
                        listing = _a.sent();
                        this._eventChecker.updateListing$.next(listing);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.purchaseCreditsService.renewDeactivated(this.creditId, this.listingId)];
                    case 3:
                        listing = _a.sent();
                        this._eventChecker.updateListing$.next(listing);
                        _a.label = 4;
                    case 4:
                        this._eventChecker.updateListing$.next('refresh');
                        this.router.navigateByUrl('/user-home/my-listings');
                        return [2 /*return*/];
                }
            });
        });
    };
    RenewModalComponent.prototype.redirectTo = function (uri) {
        var _this = this;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(function () { return _this.router.navigate([uri]); });
    };
    return RenewModalComponent;
}());
export { RenewModalComponent };
