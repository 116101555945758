import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class BuildingRentRollsService {
  constructor(private http: HttpClient) {}

  endpoint = '/api/building-rent-rolls';

  getAll(buildingId: number) {
    try {
      let buildingRentRollsParams = new URLSearchParams();
      if (buildingId) {
        buildingRentRollsParams.append('building', buildingId.toString());
      }

      return this.http
        .get(`${this.endpoint}?${buildingRentRollsParams}`, { observe: 'response' })
        .pipe(map(val => val.body))
        .toPromise()
        .then((rentRolls: any[]) => {
          return rentRolls;
        });
    } catch (error) {
      console.error(error);
    }
  }
}
