import { Component, OnInit } from '@angular/core';
import { MetaService } from '@app/core/seo';
import { UserActivityType } from '@app/models/user-activity.model';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { SpotBuildingsSearchService } from '../../../spot-buildings/services/spot-buildings-search.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isMobile: boolean = false;

  constructor(
    private spotSearchService: SpotBuildingsSearchService,
    private metaService: MetaService,
    private userActivityService: UserActivityService
  ) {}

  ngOnInit() {
    if (window.screen.width < 1000) {
      this.isMobile = true;
    }
    this.spotSearchService.removeFilterPreferencesFrom(SelectedFilterKey.SPOT_BUILDING_SEARCH);
    this.spotSearchService.removeFilterPreferencesFrom(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS);
    this.metaService.setHomeMetatags();
    this.userActivityService.trackVisitedPages(UserActivityType.PAGEVIEW_HOMEPAGE);
  }
}
