import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { AccountService } from '@app/core/auth';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { AgentDTO } from '../../../models/spot-buildings/agent-broker.model';
import { RequestInfoService } from '../../services/request-info.service';
import { AuthenticationService } from '@app/core/auth/auth.service';
import { Router } from '@angular/router';
import { LoginFormDialogService } from '@app/shared/login';
import { RequestInfoDTO, RequestInfoInput, RequestInformationType } from '@app/models/request-information.model';
import { environment } from '../../../../environments/environment';
import { WhatsAppService } from '../../../core/whatsapp.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
@Component({
  selector: 'app-request-information',
  templateUrl: './request-information.component.html',
  styleUrls: ['./request-information.component.scss']
})
export class RequestInformationComponent implements OnInit {
  constructor(
    private navParams: NavParams,
    private i18n: I18nService,
    private popoverCtrl: PopoverController,
    private platformConfigHelper: PlatformHelperService,
    private imageService: ImageService,
    private alertCtrl: AlertController,
    private accountSrv: AccountService,
    private requestInfoSrv: RequestInfoService,
    private auth: AuthenticationService,
    private router: Router,
    private loginFormDialog: LoginFormDialogService,
    private userActivityService: UserActivityService,
    private whatsappService: WhatsAppService
  ) {}

  formData: FormGroup;
  closeIcon: any;

  defaultImage: string;
  noAgentPhotos: boolean;
  agents: AgentDTO[] = [];
  noteFormCtrl: any;
  currentUser: any;
  isLoggedIn: any;
  customLoginText: string;
  newToSiilaTxt: string;
  spotTitle: string;
  input: RequestInfoInput;
  isMobile: boolean = false;
  requestInfoType: RequestInformationType = RequestInformationType.REQ_INFO;
  sendPlaceHolder: string;
  modalTitle: string;
  companyLogoImageId: number;

  ngOnInit() {
    if (window.screen.width < 1000) {
      this.isMobile = true;
    }
    this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
    this.customLoginText = this.i18n.get('global.form.defaultSignInMessage');

    this.createForm();
    this.closeIcon = faTimesCircle;
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    this.input = this.navParams.data.reqInfoInput;

    this.spotTitle = this.input.requestTitle;
    this.noAgentPhotos = !this.input.agents || this.input.agents.length == 0;
    this.agents = this.input.agents && this.input.agents.length > 0 ? [...this.input.agents] : [];
    if (this.agents && this.agents.length > 2) {
      this.agents = this.agents.slice(0, 2);
    }

    if (this.agents && this.agents.length > 0) {
      this.companyLogoImageId = this.agents[0].companyLogoImageId;
      if (!this.companyLogoImageId) {
        this.companyLogoImageId = this.input.companyLogoImageId;
      }
    }
    this.setUserInfo();
    this.newToSiilaTxt = this.i18n.get('requestInformation.newToSiila');
    if (this.input.requestType) {
      this.requestInfoType = this.input.requestType;
    }

    if (this.requestInfoType == RequestInformationType.REQ_INFO) {
      this.userActivityService.trackSpotBuildingMessage(this.input.spotId);
    }

    if (
      this.requestInfoType == RequestInformationType.REQ_INFO ||
      this.requestInfoType == RequestInformationType.REQ_BROKER
    ) {
      this.sendPlaceHolder = this.i18n.get('requestInformation.pleaseSendInfo');
      this.modalTitle = this.i18n.get('requestInformation.title');
    }
    if (this.requestInfoType == RequestInformationType.REQ_BROKER) {
      this.sendPlaceHolder = this.i18n.get('requestInformation.pleaseSendInfo');
      this.modalTitle = this.i18n.get('requestInformation.title');
    }

    if (this.requestInfoType == RequestInformationType.REQ_TOUR) {
      this.userActivityService.trackSpotBuildingRequestVisit(this.input.spotId);
      this.sendPlaceHolder = this.i18n.get('requestInformation.requestTourSendPlaceHolder');
      this.modalTitle = this.i18n.get('requestInformation.tourTitle');
    }

    if (this.requestInfoType == RequestInformationType.REQ_COMPANY) {
      this.sendPlaceHolder = this.i18n.get('requestInformation.companyPlaceholder');
      this.modalTitle = this.i18n.get('requestInformation.companyTitle');
    }

    if (this.requestInfoType == RequestInformationType.REQ_SCHEDULE) {
      this.sendPlaceHolder = this.i18n.get('requestInformation.schedulePlaceholder');
      this.modalTitle = this.i18n.get('requestInformation.scheduleTitle');
    }

    if (this.requestInfoType == RequestInformationType.REQ_CLAIM) {
      this.sendPlaceHolder = this.i18n.get('requestInformation.claimPlaceholder');
      this.modalTitle = this.i18n.get('requestInformation.claimTitle');
    }

    this.formData.controls['note'].setValue(this.sendPlaceHolder);
  }

  createForm() {
    this.formData = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      phoneNumber: new FormControl(''),
      /* ext: new FormControl('', Validators.required), */
      email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
      note: new FormControl('', Validators.required)
    });

    this.noteFormCtrl = this.formData.controls['note'];
  }

  setUserInfo() {
    this.currentUser = this.accountSrv.getCurrentUser;
    if (this.currentUser != null) {
      this.formData.controls['firstName'].setValue(`${this.currentUser.firstName}`);
      this.formData.controls['lastName'].setValue(`${this.currentUser.lastName}`);
      this.formData.controls['email'].setValue(`${this.currentUser.email}`);
      const phones: Array<any> = this.currentUser.phones;
      if (phones != null && phones.length > 0) {
        let phone = phones.find(phone => phone.phoneType === 'PHONE_NUMBER');
        if (phone != null && phone.phoneNumber.length > 0) {
          this.formData.controls['phoneNumber'].setValue(phone.phoneNumber);
        } else {
          phone = phones.find(phone => phone.phoneType === 'WHATSAPP');
          if (phone != null) {
            this.formData.controls['phoneNumber'].setValue(phone.phoneNumber);
          }
        }
      }
    }
  }

  closeModal() {
    this.popoverCtrl.dismiss();
  }

  public getCompanyLogoImg(id: any) {
    return id ? this.getMediumImg(id) : '../../../assets/placeholder-image.png';
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  sendMessage() {
    if (this.formData.invalid) {
      this.showAlert(this.i18n.get('requestInformation.enterRequiredFields'));
      return;
    } else {
      // send contact email
      if ((this.input.sentEmailTo == undefined || this.input.sentEmailTo.length == 0) && this.agents.length > 0) {
        this.input.sentEmailTo = [this.agents[0].userEmail];
      }
      let requestInfoDTO: RequestInfoDTO = {
        spotTitle: this.spotTitle,
        firstName: this.formData.value.firstName,
        lastName: this.formData.value.lastName,
        /* ext: this.formData.value.ext, */
        phoneNumber: this.formData.value.phoneNumber,
        comments: this.formData.value.note,
        email: this.formData.value.email,
        sentEmailTo: this.input.sentEmailTo,
        spotId: this.input.spotId,
        brokerRequest: this.input.brokerRequest,
        requestType: this.requestInfoType
      };

      this.requestInfoSrv.sendRequestInfoEmail(requestInfoDTO, this.i18n.getCurrentLanguage()).then((result: any) => {
        if (result) {
          this.showAlert(this.i18n.get('requestInformation.sucessMsg'));
        }
      });
    }
  }

  disableSentBtn() {
    const invalid =
      this.formData &&
      (this.formData.get('email').invalid ||
        this.formData.get('note').invalid ||
        this.formData.get('firstName').invalid ||
        this.formData.get('lastName').invalid ||
        this.formData.get('phoneNumber').invalid); /* ||
        this.formData.get('ext').invalid); */

    return invalid;
  }

  openLoginPopup() {
    if (!this.isLoggedIn) {
      this.openLoginDialog(this.customLoginText)
        .afterClosed()
        .subscribe(() => {
          this.formData.enable();
          if (this.isLoggedIn) {
            this.setUserInfo();
          }
        });
    }
  }

  openLoginDialog(text: string | Array<string>) {
    const currentRoute: string = this.router.url;
    this.formData.disable();

    return this.loginFormDialog.openDialog({
      url: currentRoute,
      customText: text,
      autoClose: true,
      autoFocus: true,
      restoreFocus: true
    });
  }

  async clickToChat(phoneNumber: any) {
    const spotURL = encodeURI(this.buildUrl());
    const headerMsg = this.i18n.get('global.broker-detail.clickToChat.whatsAppMsgHeader');
    const msgToUserAgent = this.i18n.get('global.broker-detail.clickToChat.msgToUserAgent');

    const message = `${headerMsg} ${spotURL}

${msgToUserAgent}
    `;

    this.whatsappService.sendMessageViaWhataspp(phoneNumber, message);
    return false;
  }

  private buildUrl() {
    return window.location.href.concat(this.i18n.getLanguagePathURL());
  }

  openWhatsapp(event: any, agent: AgentDTO) {
    event.stopImmediatePropagation();

    if (agent.showWhatsapp && agent.phoneNumber) {
      this.clickToChat(agent.phoneNumber);
    }

    agent.showWhatsapp = true;

    return false;
  }

  async showAlert(alertMsg: string) {
    const alert = await this.alertCtrl
      .create({
        header: this.i18n.get('global.form.sendMsgToAgent.notification'),
        subHeader: alertMsg,
        buttons: [
          {
            text: this.i18n.get('global.form.sendMsgToAgent.ok'),
            handler: () => {
              if (this.formData.valid) {
                this.popoverCtrl.dismiss();
              }
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
  }
}
