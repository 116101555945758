import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CreateSpotBuildingsRequest } from '@app/models/spot-buildings/create-spot-buildings-request.model';

@Injectable()
export class CreateSpotBuildingsService {
  private buildingsEndPoint: string = 'api/buildinglisting/';
  private getExcelEndPoint: string = 'api/buildings/download-space-template?spotId=';
  private importSpaces: string = 'api/buildings/import-spot-spaces';

  constructor(private http: HttpClient) {}

  createSpotBuildings(createSpotBuildingsRequest: CreateSpotBuildingsRequest) {
    return this.http
      .post(`${this.buildingsEndPoint}`, createSpotBuildingsRequest)
      .pipe(map(val => val))
      .toPromise();
  }

  getIndustrialStatus() {
    return this.http
      .get(`api/industrialStatus/`)
      .pipe(map(val => val))
      .toPromise();
  }

  getSpotBuildings(baseId: number) {
    return this.http
      .get(`${this.buildingsEndPoint}${baseId}`)
      .pipe(map(val => val))
      .toPromise();
  }

  getSpotBuildingsFromBuilding(buildingId: number, spotBuildingType: string, lang: string) {
    return this.http
      .get(`${this.buildingsEndPoint}find/spot/building/${spotBuildingType}/${buildingId}/${lang}`)
      .pipe(map(val => val))
      .toPromise();
  }

  getSpotBuildingsMetadataFromBuilding(buildingId: number, spotBuildingType: string) {
    return this.http
      .get(`${this.buildingsEndPoint}find-metadata/spot/building/${spotBuildingType}/${buildingId}`)
      .pipe(map(val => val))
      .toPromise();
  }

  updateSpotBuildings(createSpotBuildingsRequest: CreateSpotBuildingsRequest) {
    return this.http
      .put(`${this.buildingsEndPoint}`, createSpotBuildingsRequest)
      .pipe(map(val => val))
      .toPromise();
  }

  getSpaceConditions(buildingId: number) {
    return this.http
      .get(`${this.buildingsEndPoint + buildingId}/spaces`)
      .pipe(map(val => val))
      .toPromise();
  }

  getBuildingById(id: number) {
    return this.http
      .get(`api/buildings/${id}`)
      .pipe(map(val => val))
      .toPromise();
  }

  getUrlExcel(id: number) {
    return this.http
      .get(this.getExcelEndPoint + id)
      .pipe(map(val => val))
      .toPromise();
  }

  downloadExcel(fileName: string) {
    window.open(`api/transactionPrints/files/${fileName}`, '_blank');
  }

  createSpacesBuilding(file: any, buildingId: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('spotId', buildingId.toString());

    return this.http.post(this.importSpaces, formData).toPromise();
  }

  validateProperty(buildingId: number, spotBuildingType: string, lang: string) {
    return this.http
      .get(`${this.buildingsEndPoint}building/valid/${buildingId}/${spotBuildingType}/${lang}`)
      .pipe(map(val => val))
      .toPromise();
  }
}
