import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { BackwardGuard } from '@app/guards';
import { Shell } from '@app/shell/shell.service';

import { FavoritesComponent } from './favorites.component';
import { AppRouteNames } from '@app/core/helpers/app-route-names.service';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  Shell.childRoutesRunningGuardsResolversAlways([
    {
      path: `${AppRouteNames.MY_SPOTS_ROUTE}`,
      component: FavoritesComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('dashboard'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.MY_SPOTS_ROUTE}/:language`,
      component: FavoritesComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('dashboard'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.MY_SPOTS_ROUTE_CL}`,
      component: FavoritesComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('dashboard'), shouldReuse: false }
    },
    {
      path: `${AppRouteNames.MY_SPOTS_ROUTE_CL}/:language`,
      component: FavoritesComponent,
      canDeactivate: [BackwardGuard],
      data: { title: extract('dashboard'), shouldReuse: false }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FavoritesRoutingModule {}
