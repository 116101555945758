<div *ngIf="!isPromoted" class="slide-block">
  <ion-slides
    *ngIf="loadedItems.length"
    #slider
    class="lazy-slides"
    id="slider"
    (ionSlideDidChange)="notifyChange()"
    (ionSlidesDidLoad)="onSlidesInit()"
    (ionSlideNextEnd)="onSlideNextEnd()"
    [options]="sliderOptions"
    [pager]="showPager"
    [scrollbar]="showScrollbar"
  >
    <ng-content></ng-content>
  </ion-slides>
  <div *ngIf="!loadedItems.length" class="text-center text-secondary my-3">{{ noDataMsg }}</div>
</div>

<div *ngIf="isPromoted" class="slide-block">
  <ion-slides
    #slider
    class="lazy-slides"
    id="slider"
    (ionSlideDidChange)="notifyChange()"
    (ionSlidesDidLoad)="onSlidesInit()"
    (ionSlideNextEnd)="onSlideNextEnd()"
    [options]="sliderOptions"
    [pager]="showPager"
    [scrollbar]="showScrollbar"
  >
    <ng-content></ng-content>
  </ion-slides>
  <!-- <div *ngIf="!isPromoted && !loadedItems.length" class="text-center text-secondary my-3">{{ noDataMsg }}</div> -->
</div>
