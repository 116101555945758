import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';
import { ImageService } from '@app/core/image.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(fb, registerService, router, imgService, deviceDetectorService, loadingController, alertCtrl) {
        this.fb = fb;
        this.registerService = registerService;
        this.router = router;
        this.imgService = imgService;
        this.deviceDetectorService = deviceDetectorService;
        this.loadingController = loadingController;
        this.alertCtrl = alertCtrl;
        this.passwordLength = false;
        this.passwordMatch = false;
        this.isCompleted = false;
        this.uniqueEmailError = false;
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.lastStep = false;
        this.selectedCompany = [];
        this.allCompanies = [];
        this.stepIndex = 0;
        this.isLoaded = false;
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.ios = false;
        this.android = false;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.detectDevice();
    };
    ChangePasswordComponent.prototype.portChange = function (event) {
        this.selectedCompany = event.value;
    };
    ChangePasswordComponent.prototype.detectDevice = function () {
        if (this.deviceDetectorService.os.toLowerCase() === 'ios') {
            this.ios = true;
        }
        else if (this.deviceDetectorService.os.toLowerCase() === 'android') {
            this.android = true;
        }
    };
    ChangePasswordComponent.prototype.showUploadError = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.registerService.showUploadErr();
                return [2 /*return*/];
            });
        });
    };
    ChangePasswordComponent.prototype.changePassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var changePasswordObj;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                changePasswordObj = {};
                changePasswordObj.newPassword = this.registerForm.controls.emailGroup.value.password;
                changePasswordObj.secretKey = this.registerService.getKeyForChangePassword();
                this.registerService
                    .changePassword(changePasswordObj)
                    .then(function (response) {
                    _this.showPasswordChangeMessage();
                })
                    .catch(function (err) {
                    console.error('Password change failed');
                });
                return [2 /*return*/];
            });
        });
    };
    ChangePasswordComponent.prototype.trimValue = function (formControl) {
        this.registerForm.get('emailGroup.email').setValue(formControl.trim());
    };
    ChangePasswordComponent.prototype.showPasswordChangeMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: 'New Password',
                            subHeader: 'Password changed. Please log in using your new password.',
                            buttons: ['OK']
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            _this.router.navigateByUrl('/');
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChangePasswordComponent.prototype.createForm = function () {
        this.registerForm = this.fb.group({
            emailGroup: this.fb.group({
                password: ['', Validators.compose([Validators.required])],
                confirmPass: ['', Validators.compose([Validators.required])]
            })
        }, {
            validator: this.checkFormErrors
        });
    };
    ChangePasswordComponent.prototype.checkFormErrors = function (formGroup) {
        var password = formGroup.controls.emailGroup.controls['password'].value;
        var confirmPass = formGroup.controls.emailGroup.controls['confirmPass'].value;
        if (password.length < 6) {
            return password.length < 6 ? { passwordLength: true } : null;
        }
        else if (password !== confirmPass) {
            return password !== confirmPass ? { passwordMatch: true } : null;
        }
        if (formGroup.controls.emailGroup.status == 'INVALID') {
            return { passwordLength: true };
        }
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
