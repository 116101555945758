import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImageService } from '@app/core/image.service';
import { faTimes, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-preview-images',
  templateUrl: './preview-images.component.html',
  styleUrls: ['./preview-images.component.scss']
})
export class PreviewImagesComponent implements OnInit {
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;

  faTimes: any;
  faChevronRight: any;
  faChevronLeft: any;
  listing: any;
  pag = 0;
  photos: any;

  public slideOptions = {
    initialSlide: 0,
    speed: 400
  };

  constructor(private data: MatDialogRef<any>, private dialog: MatDialog, private imageService: ImageService) {
    this.faTimes = faTimes;
    this.faChevronRight = faChevronRight;
    this.faChevronLeft = faChevronLeft;

    if (this.data._containerInstance._config.data) {
      this.listing = this.data._containerInstance._config.data;
    }
    if (this.listing.listingPhotos) {
      this.photos = this.listing.listingPhotos;
    }

    if (this.listing.buildingListingPhotos) {
      this.photos = this.listing.buildingListingPhotos;
    }
  }

  ngOnInit(): void {}

  getCroppedOrOriginalImage(listingPhoto: any) {
    if (listingPhoto) {
      if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
        return listingPhoto.croppedImage.id;
      }
      return listingPhoto.image.id;
    }
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  public async getPreviousPhoto() {
    const index = await this.photoSlider.getActiveIndex();
    if (index === 0) {
      this.photoSlider.slideTo(this.photos.length);
      this.pag = this.photos.length - 1;
    } else {
      this.pag--;
      this.photoSlider.slidePrev();
    }
  }

  public async getNextPhoto() {
    const index = await this.photoSlider.getActiveIndex();
    if (index + 1 === this.photos.length) {
      this.photoSlider.slideTo(0);
      this.pag = 0;
    } else {
      this.pag++;
      this.photoSlider.slideNext();
    }
  }

  public close() {
    this.dialog.closeAll();
  }
}
