<header class="col-md-12 title">
  <div style="padding-top: 20px;padding-left: 10px;">
    <span style="font-size: 18px;font-weight: 800;">{{ 'notifications.sendNotificationTitle' | translate }}</span>
    <img *ngIf="isMobile" class="close-icon" (click)="close()" src="assets/images/icons/closeWhite.png" alt="" />
    <img *ngIf="!isMobile" class="close-icon" (click)="close()" src="assets/images/icons/close.png" alt="" />
  </div>
</header>
<div>
  <div *ngIf="yesNo" style="padding: 20px">
    <span *ngIf="yesNo" style="font-size: 18px;">{{ 'notifications.sendNotification' | translate }}</span>
  </div>
  <div *ngIf="!yesNo">
    <div style="padding: 24px;">
      <select
        id="predefinedoptions"
        [(ngModel)]="predefinedId"
        class="form-select form-control"
        aria-label="Default select example"
      >
        <option [ngValue]="null" selected disabled>{{ 'notifications.selectOption' | translate }}</option>
        <option *ngFor="let option of notificationOptions" [ngValue]="option.id">{{ option.name }} </option>
      </select>
    </div>
    <div style="padding: 24px;" *ngIf="predefinedId == 8">
      <multi-language-elem
        #description
        [height]="'70px'"
        (onValueChange)="descriptionChange($event)"
        [options]="descriptionFieldOptions"
      >
      </multi-language-elem>
      <span *ngIf="errorMaxLength" style="color: red;">{{ 'notifications.errorMaxLength' | translate }}</span>
    </div>
    <div style="width: 100%;text-align: center;" class="tags-section" *ngIf="searching">
      <ion-spinner id="mainSpinner" name="dots" color="primary"></ion-spinner>
    </div>
  </div>

  <div class="col-md-12 mobile-btn" style="background: #F8F9FA;">
    <button
      *ngIf="isMobile && !yesNo"
      (click)="close()"
      style="margin-top: 12px;"
      class="btn-color-1 btn-border btn-mobile"
    >
      {{ 'Cancel' | translate }}
    </button>
    <ion-button
      *ngIf="!isMobile && !yesNo"
      [disabled]="
        (data.notify.content == '' && predefinedId == 8) || searching || predefinedId == null || errorMaxLength
      "
      style="margin-top: 12px;"
      class="btn-color-2 btn-mobile"
      (click)="sendNotification()"
    >
      {{ 'favorites.send' | translate }}
    </ion-button>
    <ion-button
      *ngIf="isMobile && !yesNo"
      [ngClass]="{
        'btn-color-mobile': data.notify.content == ''
      }"
      [disabled]="
        (data.notify.content == '' && predefinedId == 8) || searching || predefinedId == null || errorMaxLength
      "
      style="margin-top: 12px;"
      class="btn-color-2 btn-mobile"
      (click)="sendNotification()"
    >
      {{ 'favorites.send' | translate }}
    </ion-button>
    <button *ngIf="!isMobile && !yesNo" (click)="close()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
      {{ 'Cancel' | translate }}
    </button>

    <ion-button *ngIf="yesNo" style="margin-top: 12px;" class="btn-color-2 btn-mobile" (click)="yesNotification()">
      {{ 'global.revisionChanges.yes' | translate }}
    </ion-button>
    <button
      *ngIf="!isMobile && yesNo"
      (click)="noNotification()"
      style="margin-top: 12px;width: 58px;height: 40px;"
      class="btn-color-1 btn-border btn-mobile"
    >
      {{ 'global.revisionChanges.no' | translate }}
    </button>
    <button
      *ngIf="isMobile && yesNo"
      (click)="noNotification()"
      style="margin-top: 12px;"
      class="btn-color-1 btn-border btn-mobile"
    >
      {{ 'global.revisionChanges.no' | translate }}
    </button>
  </div>
</div>
