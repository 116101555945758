import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../services/notification.service';
import { I18nService } from '../../core/i18n.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  searching = false;
  descriptionFieldOptions: any;
  isMobile: boolean = false;
  notificationOptions: any;
  predefinedId: any = null;
  yesNo = true;
  errorMaxLength = false;

  constructor(
    private dialog: MatDialogRef<SendNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver,
    private i18n: I18nService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit() {
    this.descriptionFieldOptions = {
      columnLabel: 'notifications.messageNotification',
      element: { richtext: true },
      formArrayName: 'description',
      required: true
    };

    this.notificationService.predefinedNotification().then(resp => {
      this.notificationOptions = resp;
      for (let option of this.notificationOptions) {
        option.name = this.i18n.getTranslation(option.name);
      }
    });
  }

  close() {
    this.dialog.close();
  }

  sendNotification() {
    this.searching = true;
    this.data.notify.predefinedNotificationId = this.predefinedId;
    this.notificationService.sendNotification(this.data.notify).then(() => {
      this.dialog.close(true);
    });
  }

  descriptionChange(value: any) {
    this.validateMaxLength(value);
    if (value.description.length > 0) {
      let mergedObject: any = {};
      let nullValues: boolean = false;
      Object.keys(value.description).forEach(async (key, idx) => {
        let obj: any = value.description[key];
        if (obj['en'] === '' || obj['en'] === null || obj['pt-br'] === '') {
          nullValues = true;
        } else {
          mergedObject = Object.assign(mergedObject, obj);
        }
      });
      nullValues ? (this.data.notify.content = '') : (this.data.notify.content = JSON.stringify(mergedObject));
    }
  }

  validateMaxLength(value: any) {
    let length1 = value.description[0] ? JSON.stringify(value.description[0]).length : '';
    let length2 = value.description[1] ? JSON.stringify(value.description[1]).length : '';

    if (length1 > 120 || length2 > 120) {
      this.errorMaxLength = true;
    } else {
      this.errorMaxLength = false;
    }
  }

  yesNotification() {
    this.yesNo = false;
  }

  noNotification() {
    this.dialog.close('close');
  }
}
