<div class="col-md-12" style="text-align: right;">
  <label style="float: left;">{{ pag + 1 }}/{{ photos.length }}</label>
  <span class="closePreview" (click)="close()">
    <fa-icon class="img-icon" [icon]="faTimes" size="lg"></fa-icon>
  </span>
</div>

<div class="col-md-12 images">
  <ion-slides [options]="slideOptions" pager="true" #photoSlider id="photoSlider" style="margin: 0px -30px 0px -78px;">
    <ion-slide *ngFor="let photo of photos; let i = index">
      <img
        alt="listing"
        [defaultImage]="defaultImage"
        class="img-listing-card"
        [lazyLoad]="photo && photo.image ? getMediumImg(getCroppedOrOriginalImage(photo)) : photo"
      />
    </ion-slide>
  </ion-slides>

  <button (click)="getPreviousPhoto()" class="prev">
    <fa-icon class="img-icon" [icon]="faChevronLeft" size="lg" style="color: white;"></fa-icon>
  </button>

  <button (click)="getNextPhoto()" class="next">
    <fa-icon class="img-icon" [icon]="faChevronRight" size="lg" style="color: white;"></fa-icon>
  </button>
</div>
