import { Injectable } from '@angular/core';

@Injectable()
export class RichTextEditorService {
  private setHeadingStyles(): any[] {
    return [
      { title: 'Heading 1', format: 'h1' },
      { title: 'Heading 2', format: 'h2' },
      { title: 'Heading 3', format: 'h3' },
      { title: 'Heading 4', format: 'h4' },
      { title: 'Heading 5', format: 'h5' },
      { title: 'Heading 6', format: 'h6' }
    ];
  }

  private setInlineStyles(): any[] {
    return [
      { title: 'Bold', format: 'bold' },
      { title: 'Italic', format: 'italic' },
      { title: 'Underline', format: 'underline' },
      { title: 'Strikethrough', format: 'strikethrough' },
      { title: 'Superscript', format: 'superscript' },
      { title: 'Subscript', format: 'subscript' },
      { title: 'Code', format: 'code' }
    ];
  }

  private setBlockStyles(): any[] {
    return [
      { title: 'Paragraph', format: 'p' },
      { title: 'Blockquote', format: 'blockquote' },
      { title: 'Div', format: 'div' },
      { title: 'Pre', format: 'pre' }
    ];
  }

  private setFontSizeStyles(): any[] {
    return [
      {
        title: '8px',
        inline: 'span',
        styles: {
          'font-size': '8pt'
        }
      },
      {
        title: '10px',
        inline: 'span',
        styles: {
          'font-size': '10pt'
        }
      },
      {
        title: '12px',
        inline: 'span',
        styles: {
          'font-size': '12pt'
        }
      },
      {
        title: '14px',
        inline: 'span',
        styles: {
          'font-size': '14pt'
        }
      },
      {
        title: '16px',
        inline: 'span',
        styles: {
          'font-size': '16pt'
        }
      },
      {
        title: '18px',
        inline: 'span',
        styles: {
          'font-size': '18pt'
        }
      },
      {
        title: '24px',
        inline: 'span',
        styles: {
          'font-size': '24pt'
        }
      },
      {
        title: '36px',
        inline: 'span',
        styles: {
          'font-size': '36pt'
        }
      }
    ];
  }

  private setAlignStyles(): any[] {
    return [
      { title: 'Left', format: 'alignleft' },
      { title: 'Center', format: 'aligncenter' },
      { title: 'Right', format: 'alignright' },
      { title: 'Justify', format: 'alignjustify' }
    ];
  }

  private setDefaultTinyMceStyle(): any[] {
    return [
      {
        title: 'Headings',
        items: this.setHeadingStyles()
      },
      {
        title: 'Inline',
        items: this.setInlineStyles()
      },
      {
        title: 'Blocks',
        items: this.setBlockStyles()
      },
      {
        title: 'Align',
        items: this.setAlignStyles()
      },
      {
        title: 'Font Sizes',
        items: this.setFontSizeStyles()
      }
    ];
  }

  public initTinyMceEditor() {
    const firstToolbar: string = 'newdocument | preview | undo redo | bold italic | copy cut paste pastetext |';
    const secondToolbar: string = 'bullist numlist | outdent indent |';
    const thirdToolbar: string = 'alignleft alignright alignjustify | ltr rtl |';
    const fourthToolbar: string = 'remove removeformat selectall |';
    const fifthToolbar: string = 'strikethrough subscript superscript underline | formatselect |';
    const sixthToolbar: string = 'anchor charmap emoticons link blockquote | table toc wordcount |';
    const seventhToolbar: string = 'backcolor forecolor | pagebreak nonbreaking | searchreplace spellchecker';
    const firstPlugins: string = 'lists advlist anchor charmap emoticons link table toc wordcount directionality';
    const secondPlugins: string = 'pagebreak nonbreaking paste preview searchreplace spellchecker';
    const plugins: string = `${firstPlugins} ${secondPlugins}`;
    const toolbars: string = `${firstToolbar} ${secondToolbar} ${thirdToolbar} ${fourthToolbar} ${fifthToolbar} ${sixthToolbar} ${seventhToolbar}`;
    return {
      base_url: '/assets/tinymce',
      suffix: '.min',
      resize: false,
      plugins: plugins,
      toolbar: toolbars,
      mobile: {
        theme: 'mobile',
        plugins: 'lists advlist',
        toolbar: 'undo redo | bold italic underline | bullist numlist | styleselect',
        style_formats: this.setDefaultTinyMceStyle(),
        style_formats_merge: true
      }
    };
  }
}
