import { Directive, OnInit, ElementRef, Input, Renderer2, HostBinding, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@app/core';

@Directive({
  selector: '[localize]'
})
export class LocaleChangerDirective implements OnChanges {
  @Input() public textValue: any;

  constructor(
    private _el: ElementRef,
    private _ts: TranslateService,
    private _r: Renderer2,
    private i18nService: I18nService
  ) {}

  ngOnChanges() {
    try {
      if (this.textValue && JSON.parse(this.textValue)) {
        let result = this.i18nService.getTranslation(this.textValue);
        this._r.setProperty(this._el.nativeElement, 'innerHTML', result);
      } else {
        this._r.setProperty(this._el.nativeElement, 'innerHTML', '');
      }
    } catch (e) {
      console.error('failed to parse locale string -> locale directive');
    }
  }
}
