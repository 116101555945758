<ion-content class="edit-image-modal">
  <ion-row>
    <ion-col>
      <div class="header-col">
        <h4 *ngIf="!editLogo" color="secondary" [translate]="'global.editImage'"></h4>
        <h4 *ngIf="editLogo" color="secondary" [translate]="'global.editLogo'"></h4>
        <ion-icon class="close-btn pointer-cursor" color="primary" name="close-circle-outline" (click)="cancel()">
        </ion-icon>
      </div>
    </ion-col>
  </ion-row>

  <div class="flex-row">
    <ion-row>
      <ion-col class="flex-row-nowrap" *ngIf="croppedImage">
        <img id="croppedImage" *ngIf="!editLogo" class="croppedImage" [src]="croppedImage" />
        <img id="croppedImage" *ngIf="editLogo" class="croppedImageLogo" [src]="croppedImage" />
        <div>
          <ion-icon
            name="close-circle-outline"
            class="remove-image pointer-cursor"
            color="primary"
            (click)="fileChangedEvent($event, true)"
          >
          </ion-icon>
        </div>
      </ion-col>

      <ion-col *ngIf="!croppedImage">
        <ion-text class="new-image-label" color="danger" [translate]="'global.user.newImage'"></ion-text>
        <button mat-raised-button (click)="inputFile.click()" class="next-btn">
          {{ 'FileUpload' | translate }}
        </button>
        <input
          #inputFile
          id="inputImageFile"
          type="file"
          class="form-control input-file"
          (change)="fileChangedEvent($event, false); setNewImageFlag(true)"
          style="visibility:hidden;"
        />
      </ion-col>
    </ion-row>

    <ion-row class="rotate-icon-row" *ngIf="croppedImage">
      <ion-col class="rotate-icon">
        <img src="../../../assets/images/icons/rotate-left.png" (click)="rotateLeft()" />
        <img src="../../../assets/images/icons/rotate-right.png" (click)="rotateRight()" />
        <img src="../../../assets/images/icons/flip-horizontal.png" (click)="flipHorizontal()" />
        <img src="../../../assets/images/icons/flip-vertical.png" (click)="flipVertical()" />
      </ion-col>
    </ion-row>
    <ion-row class="flex-col-nowrap">
      <div class="image-cropper-container" *ngIf="!editLogo">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageURL]="existingImgURL"
          [maintainAspectRatio]="imageCropperOptions.maintainAspectRatio"
          [cropperMinWidth]="imageCropperOptions.cropperMinWidth"
          [cropperMinHeight]="imageCropperOptions.cropperMinHeight"
          [resizeToWidth]="imageCropperOptions.resizeToWidth"
          [format]="imageCropperOptions.format"
          [roundCropper]="imageCropperOptions.roundCropper"
          [onlyScaleDown]="imageCropperOptions.onlyScaleDown"
          (imageCropped)="imageCropped($event)"
          [canvasRotation]="canvasRotation"
          [aspectRatio]="1"
          [transform]="transform"
        >
        </image-cropper>
      </div>

      <div class="image-cropper-container" *ngIf="editLogo">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageBase64]="existingImgURL"
          [aspectRatio]="16 / 6.7"
          [maintainAspectRatio]="imageCropperOptions.maintainAspectRatio"
          [resizeToWidth]="imageCropperOptions.resizeToWidth"
          [format]="imageCropperOptions.format"
          [onlyScaleDown]="imageCropperOptions.onlyScaleDown"
          (imageCropped)="imageCropped($event)"
          [canvasRotation]="canvasRotation"
          [transform]="transform"
        >
        </image-cropper>
      </div>

      <div class="control-btn">
        <ion-button class="cancel-btn" [translate]="'Cancel'" (click)="cancel()"></ion-button>
        <ion-button
          *ngIf="!editLogo"
          color="secondary"
          [disabled]="isProcessingImg"
          [translate]="'global.saveChanges'"
          (click)="saveImage()"
        ></ion-button>
        <ion-button
          *ngIf="editLogo"
          color="secondary"
          [disabled]="!originalImage"
          [translate]="'global.saveChanges'"
          (click)="saveImage()"
          style="width: 138px;"
        >
        </ion-button>
      </div>
    </ion-row>
  </div>
</ion-content>
