import { Injectable } from '@angular/core';

export enum UserRoles {
  ROLE_SPOT_ADMIN = 'ROLE_SPOT_ADMIN',
  ROLE_LISTING_ADMIN = 'ROLE_LISTING_ADMIN'
}

@Injectable()
export class UserService {
  private _user: any;

  getCurrentUser() {
    this._user = JSON.parse(localStorage.getItem('user'));

    return this._user;
  }

  checkAdmin() {
    if (this.getCurrentUser() && this.getCurrentUser().roles.includes('ROLE_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }

  checkRole(role: UserRoles) {
    if (this.getCurrentUser()) {
      if (this.getCurrentUser().roles.includes(role)) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }
}
