import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserActivity, UserActivityType } from '../../models/user-activity.model';

@Injectable()
export class UserActivityService {
  private endpoint: string = '/api/user-activities/';
  private listingsSearchEndpoint: string = '/api/user-activities/listings-search';

  constructor(private http: HttpClient) {}

  public saveUserActivity(userActivity: UserActivity) {
    try {
      userActivity.application = 'SPOT';
      return this.http.post(this.endpoint, userActivity).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  trackListingShare(listingId: any) {
    if (listingId) {
      const userActivity: UserActivity = {
        module: UserActivityType.LISTING_SHARE,
        listingId: listingId
      };
      this.saveUserActivity(userActivity);
    }
  }

  async trackListingsSearch(listingSearchDTO: any, searchTagsDTO: any) {
    try {
      const listingSearchTrackingDTO = {
        searchDTO: listingSearchDTO,
        searchTagsDTO: searchTagsDTO
      };
      return await this.http.post(this.listingsSearchEndpoint, listingSearchTrackingDTO).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  async trackVisitedPages(userActivityType: UserActivityType) {
    const userActivity: UserActivity = {
      module: userActivityType
    };
    this.saveUserActivity(userActivity);
  }

  trackSpotBuildingShare(listingId: any) {
    if (listingId) {
      const userActivity: UserActivity = {
        module: UserActivityType.LISTING_SHARE,
        buildingListingId: listingId
      };
      this.saveUserActivity(userActivity);
    }
  }

  trackCompanyShare(companyId: any) {
    if (companyId) {
      const userActivity: UserActivity = {
        module: UserActivityType.COMPANY_SHARE,
        companyId: companyId
      };
      this.saveUserActivity(userActivity);
    }
  }

  trackSpotBuildingRequestVisit(listingId: any) {
    if (listingId) {
      const userActivity: UserActivity = {
        module: UserActivityType.LISTING_REQUEST_VISIT,
        buildingListingId: listingId
      };
      this.saveUserActivity(userActivity);
    }
  }

  trackSpotBuildingMessage(listingId: any) {
    if (listingId) {
      const userActivity: UserActivity = {
        module: UserActivityType.LISTING_MESSAGE,
        buildingListingId: listingId
      };
      this.saveUserActivity(userActivity);
    }
  }

  trackSpotBuildingVirtualTour(listingId: any) {
    if (listingId) {
      const userActivity: UserActivity = {
        module: UserActivityType.LISTING_VIRTUAL_TOUR,
        buildingListingId: listingId
      };
      this.saveUserActivity(userActivity);
    }
  }

  trackSpotBuildingFavorite(listingId: any) {
    if (listingId) {
      const userActivity: UserActivity = {
        module: UserActivityType.LISTING_FAVORITE,
        buildingListingId: listingId
      };
      this.saveUserActivity(userActivity);
    }
  }

  trackInstallApp() {
    const userActivity: UserActivity = {
      module: UserActivityType.INSTALL_APP
    };
    this.saveUserActivity(userActivity);
  }

  trackBrokerShare(brokerId: any) {
    if (brokerId) {
      const userActivity: UserActivity = {
        module: UserActivityType.BROKER_SHARE,
        brokerId: brokerId
      };
      this.saveUserActivity(userActivity);
    }
  }
}
