<ion-content class="save-change-modal">
  <ion-row style="margin-top: 10px; border-bottom: 1px solid #6c757d">
    <div style="width: 80%;margin-bottom: 12px;">
      <span style="font-weight: 700;font-size: 23px;  margin-left: 20px;" [translate]="headerTitle"></span>
    </div>
    <div style="width: 20%;">
      <img class="close-icon" (click)="dismissAsDialog()" src="assets/images/icons/close.png" alt="" />
    </div>
  </ion-row>

  <ion-row *ngIf="isForSaveSearch()">
    <ion-col id="customcontent" class="modal--pading">
      <span *ngIf="customMsg" class="custom__content__txt">{{ customMsg }}</span>
      <ion-input
        [(ngModel)]="customInput"
        type="text"
        style="border: 1px solid #ced4da; padding: 0px; padding-left: 5px !important;"
        autofocus="true"
      >
      </ion-input>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="isForLoadSearch()">
    <ion-col class="modal--pading">
      <span *ngIf="customMsg" class="custom__content__txt">{{ customMsg }}</span>
      <div class="load__search__container  filter--dropdown">
        <div ngbDropdown class="d-inline-block full-width">
          <button class="form-control btn_type h-100 full-width" id="dropdownMap" ngbDropdownToggle>
            {{ selectedItem ? selectedItem.name : '' }}
          </button>
          <div class="full-width" style="overflow-y: auto; height: 90px;" ngbDropdownMenu aria-labelledby="dropdownMap">
            <ng-container class="full-width" *ngFor="let item of dropdownItems">
              <button ngbDropdownItem class="dropdown-item full-width" (click)="setItemSelection(item)">
                {{ item.name }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="control-button">
        <ion-button
          class="btn-color-2"
          style="width: 100%;font-size: 14px;margin: 15px 0px 0px 0px;"
          type="submit"
          value="submit"
          [disabled]="disableSentBtn()"
          [translate]="rightButtonLabel"
          (click)="userAction('OK')"
        >
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
