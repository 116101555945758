import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { AuthenticationService, AccountService } from '@app/core/auth';

@Component({
  selector: 'update-email-modal',
  templateUrl: './update-email-modal.component.html',
  styleUrls: ['./update-email-modal.component.scss']
})
export class UpdateEmailModalComponent implements OnInit {
  @Input() currentEmail: string;
  @Input() userHomeService: UserHomeService;
  @Input() auth: AuthenticationService;
  @Input() userAccountService: AccountService;
  @Input() openInfoModal: any;

  updateEmailForm: FormGroup;
  isError: boolean = false;
  infoProp: any = {};

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.updateEmailForm = new FormGroup({
      newEmail: new FormControl('', [Validators.required, Validators.email]),
      confirmNewEmail: new FormControl('', [Validators.required, Validators.email])
    });
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  validateForm(): boolean {
    let newEmail: any = this.updateEmailForm.get('newEmail');
    let confirmEmail: any = this.updateEmailForm.get('confirmNewEmail');

    return [
      newEmail.errors && (newEmail.errors.required || newEmail.errors.email),
      confirmEmail.errors && (confirmEmail.errors.required || confirmEmail.errors.email),
      newEmail.value.toLowerCase() !== confirmEmail.value.toLowerCase()
    ].some(condition => condition);
  }

  updateEmail() {
    const newEmailAddress: string = this.updateEmailForm.get('newEmail').value;

    this.userAccountService.changeUserEmail(this.currentEmail, newEmailAddress).subscribe(
      () => {
        const action = 'changeEmailSuccess';
        this.infoProp = this.userHomeService.getEmailUpdateSuccessMessage();
        this.isError = false;
        this.modalCtrl.dismiss();
        this.openInfoModal(this.infoProp.header, this.infoProp.bodyMessage, action);
      },
      (error: Error) => {
        this.isError = true;
        this.infoProp = this.userHomeService.getEmailUpdateErrorMessage(error);
      }
    );
  }
}
