import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { UserHomeComponent } from './user-home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyMessageComponent } from './my-message/my-message.component';
import { ListingAnalyticsComponent } from './listing-analytics/listing-analytics.component';
import { AuthGuard } from '@app/guards/auth.guard';
import { CanDeactivateGuard } from '@app/guards/can-deactivate.guard';

const routes: Routes = [
  Shell.childRoutesRunningGuardsResolversAlways([
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
      path: 'user-home/my-account',
      component: MyAccountComponent,
      canDeactivate: [CanDeactivateGuard],
      data: {
        title: extract('User Account')
      }
    },
    {
      path: 'user-home/my-account/:language',
      component: MyAccountComponent,
      canDeactivate: [CanDeactivateGuard],
      data: {
        title: extract('User Account')
      }
    },
    {
      path: 'user-home/my-messages',
      component: MyMessageComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('My Messages')
      }
    },
    {
      path: 'user-home/my-messages/:language',
      component: MyMessageComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('My Messages')
      }
    },
    {
      path: 'user-home/listing-analytics',
      component: ListingAnalyticsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Listing Analytics')
      },
      runGuardsAndResolvers: 'always'
    },
    {
      path: 'user-home/listing-analytics/:language',
      component: ListingAnalyticsComponent,
      canActivate: [AuthGuard],
      data: {
        title: extract('Listing Analytics')
      },
      runGuardsAndResolvers: 'always'
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class UserHomeRoutingModule {}
