import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuploMapComponent } from './components/duplomap';
import { GeoLocationService } from '@app/core';
import { MapControlHelperService } from './helpers';
import { MapComponent } from './map/map.component';
import { MaterialModule } from '../material.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@env/environment';
import { HttpClientJsonpModule } from '@angular/common/http';

@NgModule({
  declarations: [DuploMapComponent, MapComponent],
  imports: [
    HttpClientJsonpModule,
    CommonModule,
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPIKey,
      libraries: ['geometry', 'places'],
      apiVersion: 'quarterly'
    })
  ],
  exports: [DuploMapComponent, MapComponent],
  providers: [GeoLocationService, MapControlHelperService]
})
export class DuploMapModule {}
