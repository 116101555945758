import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { I18nService } from '@app/core';
import { BaseService } from './base-service';
import { map } from 'rxjs/operators';
var RequestInfoService = /** @class */ (function (_super) {
    tslib_1.__extends(RequestInfoService, _super);
    function RequestInfoService(http, i18n) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.i18n = i18n;
        _this.requestInfoAPI = "/api/info/request-info/{lang}";
        return _this;
    }
    RequestInfoService.prototype.sendRequestInfoEmail = function (dto, lang) {
        if (!lang) {
            lang = 'en';
        }
        var url = _super.prototype.interporlateURL.call(this, this.requestInfoAPI, { lang: lang });
        return this.http
            .post(url, dto, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }))
            .toPromise();
    };
    return RequestInfoService;
}(BaseService));
export { RequestInfoService };
