import { NGXLogger } from 'ngx-logger';
import { Injectable, Inject, Optional } from '@angular/core';

export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug
}

export type LogOutput = (source: string | undefined, level: LogLevel, ...objects: any[]) => void;

export class Logger {
  static level = LogLevel.Debug;
  static outputs: LogOutput[] = [];
  private static ngxLogger: NGXLogger;

  static enableProductionMode() {
    Logger.level = LogLevel.Warning;
  }

  // Inject NGXLogger at the static level
  static setLogger(logger: NGXLogger) {
    Logger.ngxLogger = logger;
  }

  constructor(private source?: string) {}

  debug(...objects: any[]) {
    this.log(Logger.ngxLogger.debug.bind(Logger.ngxLogger), LogLevel.Debug, objects);
  }

  info(...objects: any[]) {
    this.log(Logger.ngxLogger.info.bind(Logger.ngxLogger), LogLevel.Info, objects);
  }

  warn(...objects: any[]) {
    this.log(Logger.ngxLogger.warn.bind(Logger.ngxLogger), LogLevel.Warning, objects);
  }

  error(...objects: any[]) {
    this.log(Logger.ngxLogger.error.bind(Logger.ngxLogger), LogLevel.Error, objects);
  }

  private log(func: Function, level: LogLevel, objects: any[]) {
    if (level <= Logger.level) {
      const log = this.source ? ['[' + this.source + ']'].concat(objects) : objects;
      func.apply(Logger.ngxLogger, log);
      Logger.outputs.forEach(output => output.apply(output, [this.source, level, ...objects]));
    }
  }
}
