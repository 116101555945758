import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import * as IntlLanguageService from './intl-language.service';
import { CommonGenericService } from '@app/core/common.service';
import { TranslateService } from '@ngx-translate/core';
import { MultiLanguageService } from '@app/core/multi-language.service';
import { I18nService } from '@app/core';

@Component({
  selector: 'intl-language-element',
  templateUrl: './intl-language.component.html',
  styleUrls: ['./intl-language.component.scss']
})
export class IntlLanguageElemComponent implements OnInit {
  userPreferLanguage: string = '';
  languageSelection: any;
  selectedLanguage: any[] = [];
  tempFormData: any = {};
  getLanguageLabel: any;
  placeholder: string;
  richTextEditorOption: any;
  @Input('parentForm') parentForm: FormGroup;
  @Input('formArrayControl') formArrayControl: FormArray;
  @Input('formArrayValue') formArrayValue: any;
  @Input('options') options: any;

  constructor(
    private commonSvc: CommonGenericService,
    private fb: FormBuilder,
    private multLanguageSvc: MultiLanguageService,
    private tranlateSvc: TranslateService,
    private i18NService: I18nService
  ) {}

  ngOnInit() {
    this.languageSelection = IntlLanguageService.initSelectionControl();
    this.getLanguageLabel = this.commonSvc.getLanguage;
    this.placeholder = this.tranlateSvc.instant(this.options.placeholder);
    this.richTextEditorOption = this.multLanguageSvc.ngQuillEditorToolbarOption();
    this.getUserPreferLanguage();
    this.loadExistingFormData();
  }

  private addFormControlElement(formControlName: string, value?: any) {
    let formGroup: FormGroup;
    const controlHasSavedData: boolean = IntlLanguageService.hasOwnProperty(formControlName, this.tempFormData);

    if (controlHasSavedData) {
      const santizedTempFormData: any = IntlLanguageService.setFormControlValue(formControlName, this.tempFormData);
      formGroup = IntlLanguageService.addFormGroup(
        this.fb,
        formControlName,
        this.options.validators,
        santizedTempFormData
      );
    } else {
      formGroup = IntlLanguageService.addFormGroup(this.fb, formControlName, this.options.validators, value);
    }
    this.formArrayControl.push(formGroup);
  }

  private getUserPreferLanguage() {
    const currentLanguage = this.multLanguageSvc.getCurrentLanguage(this.i18NService.language);
    const defaultLanguage: string = this.multLanguageSvc.getBrowserLanguage(currentLanguage);
    this.userPreferLanguage = defaultLanguage;
  }

  private saveFormDataTemporary() {
    this.tempFormData = IntlLanguageService.saveTemporaryFormData(
      this.tempFormData,
      this.parentForm.value,
      this.options.formArrayName,
      this.options.formGroupName
    );
  }

  private removeFormArrayControl(languageISO: string) {
    const index: number = this.commonSvc.getItemIndex(this.selectedLanguage, languageISO);
    this.saveFormDataTemporary();
    this.selectedLanguage.splice(index, 1);
    this.formArrayControl.removeAt(index);
  }

  private loadExistingFormData() {
    const isFormDataEmpty: boolean = this.commonSvc.isObjectEmpty(this.formArrayValue);
    if (!isFormDataEmpty) {
      Object.keys(this.formArrayValue).forEach(key => {
        this.selectedLanguage.push(key);
        this.addFormControlElement(key, this.formArrayValue[key]);
        this.setLanguageSelection(key);
      });
    } else {
      this.selectedLanguage.push(this.userPreferLanguage);
      this.setLanguageSelection(this.userPreferLanguage);
      this.addFormControlElement(this.userPreferLanguage);
    }
  }

  private setLanguageSelection(propName: string) {
    const key: any = {
      en: 'english',
      es: 'spanish',
      'pt-br': 'portuguese'
    };
    this.languageSelection[key[propName]] = true;
  }

  public getCheckboxName(isSelected: boolean) {
    return isSelected ? 'checkmark-circle' : 'radio-button-off';
  }

  public selectLanguage(language: string, languageISO: string) {
    this.languageSelection[language] = !this.languageSelection[language];

    if (this.languageSelection[language]) {
      this.selectedLanguage.push(languageISO);
      this.addFormControlElement(languageISO);
    } else {
      this.removeFormArrayControl(languageISO);
    }
  }

  setLanguageOrder(language: string) {
    return language.toLowerCase() === this.userPreferLanguage.toLowerCase()
      ? 'lang-elem-1st-order'
      : 'lang-elem-2nd-order';
  }

  setElementOrder(language: string) {
    return this.selectedLanguage.length === 2 && this.userPreferLanguage.toLowerCase() === language.toLowerCase()
      ? 'elem-1st-order'
      : 'elem-2nd-order';
  }
}
