import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService, AccountService } from '@app/core/auth';
import { ToastController } from '@ionic/angular';
import { I18nService } from '@app/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private toastPresent: boolean = false;
  constructor(
    private auth: AuthenticationService,
    private account: AccountService,
    private toastCtrl: ToastController,
    private i18nService: I18nService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isRequestingToken = req.url.includes('token');
    const token: string = this.auth.getToken();

    if (token && !isRequestingToken) {
      try {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + JSON.parse(token)['access_token'])
        });
      } catch (error) {}
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error ? error.error.error : '';
        const userHasKeepConnected = this.account.getCurrentUser && this.account.getCurrentUser.keepConnected;
        const shouldRefreshToken = error.status === 401 && errorMessage === 'invalid_token' && userHasKeepConnected;

        if (isRequestingToken) {
          const grantTypeParam = (req.body.updates || []).find(
            ({ param }: { param: string }) => param === 'grant_type'
          );
          const isRefreshingToken = (grantTypeParam || {}).value === 'refresh_token';

          if (isRefreshingToken) {
            console.error(this.i18nService.get('TokenExpiration.failRefreshToken'));
            this.auth.logout();
          }

          return throwError(error);
        }

        if (error.status === 504 || error.status === 503) {
          this.showTimeoutErr();
          return throwError(error);
        }

        if (error.status === 401 && !shouldRefreshToken) {
          if (this.auth.hasToken() || this.account.getCurrentUser) {
            this.auth.logout();
            return throwError(error);
          }

          return throwError(error);
        }

        return throwError(error);
      })
    );
  }

  async showTimeoutErr() {
    if (!this.toastPresent) {
      this.toastPresent = true;
      const toast = await this.toastCtrl.create({
        message: this.i18nService.get('global.user.message.timeoutError'),
        duration: 3000,
        animated: true,
        keyboardClose: true,
        cssClass: 'toast-alert'
      });
      toast.onDidDismiss().then(() => {
        this.toastPresent = false;
      });
      return await toast.present();
    }
  }
}
