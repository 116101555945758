import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginFormDialogService } from '../login';
import { AuthenticationService } from '@app/core/auth';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
var FavoriteSpotService = /** @class */ (function () {
    function FavoriteSpotService(http, loginFormDialog, userActivityService, _ts, router, auth) {
        var _this = this;
        this.http = http;
        this.loginFormDialog = loginFormDialog;
        this.userActivityService = userActivityService;
        this._ts = _ts;
        this.router = router;
        this.auth = auth;
        this.favoriteListing = 'api/spot/favorites/';
        this.favoritesCountSubject = new BehaviorSubject(0);
        this.spotSetAPI = '/api/spotset/';
        this.tourBook = 'api/tourbookdashboard/';
        this.savedSearchesAPI = 'api/savedsearchesdashboard/';
        this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe(function (res) {
            _this.customLoginText = res;
        });
        if (this.auth) {
            this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) {
                _this.isLoggedIn = val;
            });
        }
    }
    FavoriteSpotService.prototype.toggleFavoriteListing = function (listing, isFavorite) {
        var _this = this;
        listing.favorite = isFavorite;
        var currentFavoritesCount = this.favoritesCountSubject.getValue();
        if (isFavorite) {
            return this.http.delete("" + this.favoriteListing + listing.id + "/unmark").pipe(tap(function () {
                listing.favorite = false;
                if (_this.isActiveListing(listing)) {
                    _this.favoritesCountSubject.next(currentFavoritesCount - 1);
                }
            }));
        }
        else {
            this.favoriteActivityLog(listing);
            return this.http.put("" + this.favoriteListing + listing.id + "/mark", {}).pipe(tap(function () {
                listing.favorite = true;
                if (_this.isActiveListing(listing)) {
                    _this.favoritesCountSubject.next(currentFavoritesCount + 1);
                }
            }));
        }
    };
    FavoriteSpotService.prototype.getFavoriteListings = function () {
        return this.http.get(this.favoriteListing);
    };
    FavoriteSpotService.prototype.isActiveListing = function (listing) {
        return listing;
    };
    FavoriteSpotService.prototype.favoriteActivityLog = function (listing) {
        this.userActivityService.trackSpotBuildingFavorite(listing.id);
    };
    FavoriteSpotService.prototype.updateFavoritesCount = function () {
        var _this = this;
        this.getFavoriteListings().subscribe(function (val) {
            _this.favoritesCountSubject.next(val.filter(function (l) { return l.listingStatus.id == 4; }).length);
        });
    };
    FavoriteSpotService.prototype.openLoginDialog = function () {
        var currentRoute = this.router.url;
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: this.customLoginText
        });
    };
    FavoriteSpotService.prototype.setFavoriteSpot = function (event, spot) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                event.stopPropagation();
                promise = new Promise(function (resolve, reject) {
                    if (!_this.isLoggedIn) {
                        _this.openLoginDialog()
                            .afterClosed()
                            .subscribe(function () {
                            if (_this.isLoggedIn) {
                                _this.toggleFavoriteListing(spot, spot.favorite || false).subscribe(function (res) {
                                    resolve(res);
                                });
                            }
                        });
                    }
                    else {
                        _this.toggleFavoriteListing(spot, spot.favorite || false).subscribe(function (res) {
                            resolve(res);
                        });
                    }
                });
                return [2 /*return*/, promise];
            });
        });
    };
    FavoriteSpotService.prototype.getSpotSetFavorite = function (page, lang, bodySpotSet) {
        return this.http.post(this.spotSetAPI + "search?page=" + page + "&per_page=10&lang=" + lang, bodySpotSet).toPromise();
    };
    FavoriteSpotService.prototype.deleteAction = function (id, apiName) {
        return this.http.delete("" + apiName + id).toPromise();
    };
    FavoriteSpotService.prototype.getTourBook = function (page, lang, body) {
        return this.http.post(this.tourBook + "search?page=" + page + "&per_page=10&lang=" + lang, body).toPromise();
    };
    FavoriteSpotService.prototype.sendEmail = function (id, email, lang) {
        return this.http.post(this.tourBook + "share/" + id + "/" + lang, email).toPromise();
    };
    FavoriteSpotService.prototype.editTitleTourBook = function (editTitle) {
        return this.http
            .patch(this.tourBook, editTitle, {
            observe: 'response'
        })
            .pipe(map(function (val) {
            return val.status;
        }))
            .toPromise()
            .catch(function (val) {
            return val.status;
        });
    };
    FavoriteSpotService.prototype.getSavedSearches = function (page, lang, body) {
        return this.http.post(this.savedSearchesAPI + "search?page=" + page + "&per_page=10&lang=" + lang, body).toPromise();
    };
    return FavoriteSpotService;
}());
export { FavoriteSpotService };
