import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ListingAgentEmailRequest } from '@app/models/email-requests.model';

@Injectable()
export class EmailService {
  private endpoint: string = '/api/email';

  constructor(private http: HttpClient) {}

  public sendListingMessage(listingAgentEmailRequest: ListingAgentEmailRequest) {
    try {
      return this.http.post(this.endpoint + '/listingAgent', listingAgentEmailRequest).toPromise();
    } catch (error) {
      console.error(error);
    }
  }
}
