/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-screen-map-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/portal";
import * as i6 from "./full-screen-map-wrapper.component";
var styles_FullScreenMapWrapperComponent = [i0.styles];
var RenderType_FullScreenMapWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullScreenMapWrapperComponent, data: {} });
export { RenderType_FullScreenMapWrapperComponent as RenderType_FullScreenMapWrapperComponent };
function View_FullScreenMapWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-button", [["class", "fullscreen-button"], ["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goFullScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "expand"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(4, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var currVal_0 = "secondary"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "light"; var currVal_2 = "expand"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_FullScreenMapWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FullScreenMapWrapperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "close-fullscreen"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "top-spacing": 0 }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "ion-button", [["class", "close-fullscreen__button"], ["color", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exitFullScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(6, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-icon", [["color", "light"], ["name", "contract"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "close-fullscreen"; var currVal_1 = _ck(_v, 3, 0, _co.isApp); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "secondary"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "light"; var currVal_4 = "contract"; _ck(_v, 9, 0, currVal_3, currVal_4); }, null); }
function View_FullScreenMapWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenMapWrapperComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isIos && _co.fullscreenMode); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FullScreenMapWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { templatePortalContent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenMapWrapperComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenMapWrapperComponent_2)), i1.ɵdid(5, 212992, null, 0, i5.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [[1, 2], ["templatePortalContent", 2]], null, 0, null, View_FullScreenMapWrapperComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isIos; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.templatePortal; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FullScreenMapWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-full-screen-map-wrapper", [], null, null, null, View_FullScreenMapWrapperComponent_0, RenderType_FullScreenMapWrapperComponent)), i1.ɵdid(1, 4308992, null, 0, i6.FullScreenMapWrapperComponent, [i3.Platform, i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.ApplicationRef, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullScreenMapWrapperComponentNgFactory = i1.ɵccf("app-full-screen-map-wrapper", i6.FullScreenMapWrapperComponent, View_FullScreenMapWrapperComponent_Host_0, {}, {}, ["*"]);
export { FullScreenMapWrapperComponentNgFactory as FullScreenMapWrapperComponentNgFactory };
