<div class="main-container flex">
  <div class="flex">
    <div class="title-section">
      <span class="main-title"> {{ input.modalTitle }} </span>
    </div>
    <div class="flex-align-content-end header">
      <img
        class="close-icon"
        style="width: 14px;height: 14px;"
        (click)="closeModal()"
        src="assets/images/icons/close.png"
        alt=""
      />
      <!--  <fa-icon class="close-icon" (click)="closeModal()" [icon]="closeIcon"></fa-icon> -->
    </div>
  </div>

  <div class="social-media-buttons">
    <img
      *ngFor="let socialLink of socialMediaLinks"
      class="image-social"
      [src]="socialLink.img"
      (click)="shareLink(socialLink)"
    />
  </div>

  <div class="share-section">
    <div class="shared-this-link">
      <span>{{ 'ShareLink' | translate }}</span>
    </div>
    <div class="copy-url-section">
      <div class="url-box">{{ getSharedURL() }}</div>
      <div class="copy-btn-section">
        <button (click)="copyURLToClipboard()" class="copy-btn">{{ 'CopyURL' | translate }}</button>
      </div>
    </div>
  </div>

  <div class="tooltip-link">
    <span #toolTip> The Link has been copied </span>
  </div>
</div>
