import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { DomSanitizer } from '@angular/platform-browser';
var EditCropImageSaveModalComponent = /** @class */ (function () {
    function EditCropImageSaveModalComponent(modalCtrl, commonService, sanitizer) {
        this.modalCtrl = modalCtrl;
        this.commonService = commonService;
        this.sanitizer = sanitizer;
        this.imgList = null;
        this.showCroppingToggle = false;
        this.fileName = null;
        this.UPLOAD_FILE_SIZE_LIMIT = 20000000;
        this.imageCropperOptions = {};
        this.imageChangedEvent = {};
        this.canvasRotation = 0;
        this.rotation = 0;
        this.transform = {};
        /*  Image based 64 */
        this.principalImageToEdit = null;
        this.croppedImage = null;
        this.cropOn = true;
        this.removedImage = false;
        this.replacedImage = false;
        this.restoreToOriginalImage = false;
        this.croppedImageWidth = '200px';
        this.croppedImageHeight = '150px';
        this.originalImageUrl = null;
        this.validImage = true;
        this.img = 0;
    }
    EditCropImageSaveModalComponent.prototype.ngOnInit = function () {
        this.initImage();
        this.imageCropperOptions = this.getImageCropperOptions();
    };
    EditCropImageSaveModalComponent.prototype.initImage = function () {
        if (this.imgList.length != this.imgCount && this.img == 0) {
            this.img = this.imgList.length - this.imgCount;
        }
        this.imageUrlToEdit = "/images/" + this.imgList[this.img].image.id + "/thumbnail/ml";
        this.getPrincipalImage();
    };
    EditCropImageSaveModalComponent.prototype.getImageCropperOptions = function () {
        return {
            maintainAspectRatio: 'true',
            cropperMinWidth: 128,
            cropperMinHeight: 128,
            resizeToWidth: 200,
            format: 'png',
            roundCropper: false,
            onlyScaleDown: true,
            aspectRatio: 4 / 3
        };
    };
    EditCropImageSaveModalComponent.prototype.getPrincipalImage = function () {
        var _this = this;
        this.convertToDataURL(this.imageUrlToEdit, function (dataURL) {
            _this.principalImageToEdit = dataURL;
        });
    };
    EditCropImageSaveModalComponent.prototype.convertToDataURL = function (imageUrl, callback) {
        var xhr = new XMLHttpRequest();
        this.fileName = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = function () {
            var fileReader = new FileReader();
            fileReader.onloadend = function () {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    };
    EditCropImageSaveModalComponent.prototype.closeModal = function () {
        this.modalCtrl.dismiss();
    };
    EditCropImageSaveModalComponent.prototype.imageCropped = function (image) {
        if (image && image.cropperPosition) {
            this.croppedImageWidth = image.cropperPosition.x2 - image.cropperPosition.x1 + 'px';
            this.croppedImageHeight = image.cropperPosition.y2 - image.cropperPosition.y1 + 'px';
            this.croppedImage = image.base64;
        }
    };
    EditCropImageSaveModalComponent.prototype.convertImageToFile = function (imgBase64) {
        return this.commonService.convertImageBase64ToFileSync(imgBase64, this.fileName);
    };
    EditCropImageSaveModalComponent.prototype.saveImage = function () {
        var originalImageFile = this.convertImageToFile(this.principalImageToEdit);
        var croppedImageFile = this.croppedImage ? this.convertImageToFile(this.croppedImage) : null;
        this.imgList[this.img].img = this.principalImageToEdit;
        this.imgList[this.img].content =
            originalImageFile.name && originalImageFile.name != null ? originalImageFile.name : 'New Image';
        this.imgList[this.img].croppedImage = this.croppedImage;
        if (this.img == this.imgList.length - 1) {
            this.closeModal();
        }
        else {
            this.img++;
            this.initImage();
        }
    };
    EditCropImageSaveModalComponent.prototype.rotateLeft = function () {
        this.canvasRotation--;
        this.flipAfterRotate();
    };
    EditCropImageSaveModalComponent.prototype.rotateRight = function () {
        this.canvasRotation++;
        this.flipAfterRotate();
    };
    EditCropImageSaveModalComponent.prototype.flipAfterRotate = function () {
        var flippedH = this.transform.flipH;
        var flippedV = this.transform.flipV;
        this.transform = tslib_1.__assign({}, this.transform, { flipH: flippedV, flipV: flippedH });
    };
    EditCropImageSaveModalComponent.prototype.flipHorizontal = function () {
        this.transform = tslib_1.__assign({}, this.transform, { flipH: !this.transform.flipH });
    };
    EditCropImageSaveModalComponent.prototype.flipVertical = function () {
        this.transform = tslib_1.__assign({}, this.transform, { flipV: !this.transform.flipV });
    };
    EditCropImageSaveModalComponent.prototype.showCroppedImage = function () {
        if (this.cropOn && !this.removedImage) {
            return true;
        }
        return false;
    };
    EditCropImageSaveModalComponent.prototype.showNotCroppedImage = function () {
        var showCroppedImage = this.showCroppedImage();
        if (!showCroppedImage) {
            if (!this.imageUrlToEdit || this.imageUrlToEdit == null) {
                if (!this.principalImageToEdit || this.principalImageToEdit == null) {
                    return false;
                }
            }
            if (!this.validImage) {
                return false;
            }
            return !this.removedImage;
        }
        return !showCroppedImage;
    };
    EditCropImageSaveModalComponent.prototype.getNotCroppedImage = function () {
        if (this.imageUrlToEdit && !this.cropOn && !this.replacedImage) {
            return this.imageUrlToEdit;
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.principalImageToEdit);
    };
    return EditCropImageSaveModalComponent;
}());
export { EditCropImageSaveModalComponent };
