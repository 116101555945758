import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { ListingService, ListingDetailService, EventCheckerService } from '@app/core/listings';
import { AdminUpdatesComponent } from '@app/user-home/my-listing/admin-updates/admin-updates.component';
import { PurchaseCreditsService, I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterListingService } from '@app/core/register-listing.service';
var MyListingEditComponent = /** @class */ (function () {
    function MyListingEditComponent(data, dialog, router, alertCtrl, listingService, listingDetailService, popoverCtrl, purchaseService, _eventChecker, _ts, i18nService, registerListingService) {
        this.data = data;
        this.dialog = dialog;
        this.router = router;
        this.alertCtrl = alertCtrl;
        this.listingService = listingService;
        this.listingDetailService = listingDetailService;
        this.popoverCtrl = popoverCtrl;
        this.purchaseService = purchaseService;
        this._eventChecker = _eventChecker;
        this._ts = _ts;
        this.i18nService = i18nService;
        this.registerListingService = registerListingService;
        if (this.data._containerInstance._config.data.data) {
            var listing = this.data._containerInstance._config.data.data;
            this.fromMyListings = this.data._containerInstance._config.data.myListingPage;
            this.nextListing = listing;
            var listingId = listing.id;
            this.checkListingStatus(listingId);
        }
        this.spotAdmin = this.checkRole('ROLE_SPOT_ADMIN');
        this.deleteDraftHeader = i18nService.get('global.my-list-menu.deleteDraft');
        this.deleteDraftSubHeader = i18nService.get('global.my-list-menu.deleteDraftSubHeader');
        this.discardChangesLbl = i18nService.get('global.discardChanges');
        this.cancelLbl = i18nService.get('Cancel');
    }
    MyListingEditComponent.prototype.ngOnInit = function () { };
    MyListingEditComponent.prototype.checkListingStatus = function (listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getListing(listingId)];
                    case 1:
                        _a.sent();
                        this.listingStatus = this.tempListing.listingStatus;
                        this.setListing(this.tempListing);
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.getListing = function (listingId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listingDetail, listingPhotos;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingDetailService.getListingDetails(listingId).toPromise()];
                    case 1:
                        listingDetail = _a.sent();
                        return [4 /*yield*/, this.listingDetailService.getListingPhotos(listingId).toPromise()];
                    case 2:
                        listingPhotos = _a.sent();
                        this.tempListing = listingDetail.listing;
                        this.tempListing.listingPhotos = listingPhotos;
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.setListing = function (listing) {
        this.listing = listing;
        return this.listing;
    };
    MyListingEditComponent.prototype.dismissAsDialog = function () {
        this.dialog.closeAll();
    };
    MyListingEditComponent.prototype.discardDraft = function (event) {
        this.discardConfirmMessage();
        this.dialog.closeAll();
    };
    MyListingEditComponent.prototype.reactivateListing = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.displayAlert();
                this.dialog.closeAll();
                return [2 /*return*/];
            });
        });
    };
    MyListingEditComponent.prototype.deactivateListing = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.deactivateAlert();
                this.dialog.closeAll();
                return [2 /*return*/];
            });
        });
    };
    MyListingEditComponent.prototype.reviseListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.router.navigateByUrl("/user-home/my-listings/create-property/" + this.listing.id, {
                    state: { listing: this.listing, myListingPage: this.fromMyListings, date: new Date().getTime() },
                    replaceUrl: true,
                    queryParams: { date: new Date().getTime() }
                });
                this.dismissAsDialog();
                return [2 /*return*/];
            });
        });
    };
    MyListingEditComponent.prototype.showSubmitForApprovalOption = function () {
        return (!this.checkRole('ROLE_LISTING_ADMIN') &&
            !this.checkRole('ROLE_SPOT_ADMIN') &&
            (this.listing.listingStatus.id === 7 || this.listing.listingStatus.id === 8));
    };
    MyListingEditComponent.prototype.submitForApproval = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.registerListingService.updateToApproval(this.listing)];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            this._eventChecker.updateListing$.next('refresh');
                        }
                        this.dismissAsDialog();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error Submitting Listing: ', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.updateReactivatedListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listing;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.purchaseService.reactivateListing(this.listing.id)];
                    case 1:
                        listing = _a.sent();
                        if (listing) {
                            this._eventChecker.updateListing$.next(listing);
                            this.dismissAsDialog();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.updateDeactivatedListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listing;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.purchaseService.deactivateListing(this.listing.id)];
                    case 1:
                        listing = _a.sent();
                        if (listing) {
                            this._eventChecker.updateListing$.next(listing);
                            this.dismissAsDialog();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.deactivateAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: this._ts.instant('global.deactivateListing'),
                            subHeader: this._ts.instant('global.deactivateListingText'),
                            cssClass: 'ion-alert-listings',
                            buttons: [
                                {
                                    text: this._ts.instant('Cancel'),
                                    role: 'Cancel',
                                    handler: function () { }
                                },
                                {
                                    text: this._ts.instant('Ok'),
                                    handler: function () {
                                        _this.updateDeactivatedListing();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            //this.router.navigateByUrl('/user-home/my-listings');
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.displayAlert = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: this._ts.instant('global.reactivateListing'),
                            subHeader: this._ts.instant('global.reactivateListingText'),
                            cssClass: 'ion-alert-listings',
                            buttons: [
                                {
                                    text: this._ts.instant('Cancel'),
                                    role: 'Cancel',
                                    handler: function () { }
                                },
                                {
                                    text: this._ts.instant('Ok'),
                                    handler: function () {
                                        _this.updateReactivatedListing();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            //this.router.navigateByUrl('/user-home/my-listings');
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.adminUpdates = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dialog.closeAll();
                        return [4 /*yield*/, this.popoverCtrl.create({
                                component: AdminUpdatesComponent,
                                event: ev,
                                cssClass: 'popover-renewal',
                                componentProps: { listing: this.listing },
                                backdropDismiss: false
                            })];
                    case 1:
                        popover = _a.sent();
                        popover.onDidDismiss().then(function (popoverData) {
                            if (popoverData.data) {
                            }
                        });
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MyListingEditComponent.prototype.discardConfirmMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cancelTxt, discardTxt, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cancelTxt = this.i18nService.get('Cancel');
                        discardTxt = this.i18nService.get('global.discardChanges');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: this.deleteDraftHeader,
                                subHeader: this.deleteDraftSubHeader,
                                cssClass: 'ion-alert-listings',
                                buttons: [
                                    {
                                        text: cancelTxt,
                                        role: 'Cancel',
                                        handler: function () {
                                            //console.log('Cancelar')
                                        }
                                    },
                                    {
                                        text: discardTxt,
                                        handler: function () {
                                            _this.listingService
                                                .deleteDraft(_this.listing.id)
                                                .toPromise()
                                                .then(function (result) {
                                                _this.listingService.updateModel('reload');
                                                _this.router.navigateByUrl('/user-home/my-listings');
                                            });
                                        }
                                    }
                                ]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            //this.router.navigateByUrl('/user-home/my-listings');
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MyListingEditComponent.prototype.checkRole = function (role) {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.roles.includes(role)) {
            return true;
        }
        else {
            return false;
        }
    };
    MyListingEditComponent.prototype.isUserAllowToEditPendingApprovalListing = function () {
        return this.registerListingService.isUserAllowToEditPendingApprovalListing(this.listing);
    };
    MyListingEditComponent.prototype.isRegularUserEditingRevisionListing = function () {
        return (!this.checkRole('ROLE_LISTING_ADMIN') && !this.checkRole('ROLE_SPOT_ADMIN') && this.listing.listingStatus.id === 8);
    };
    return MyListingEditComponent;
}());
export { MyListingEditComponent };
