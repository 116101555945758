<form [formGroup]="formData" (ngSubmit)="sendMessage()">
  <ion-content>
    <!-- Mobile -->
    <div *ngIf="isMobile" class="main-container-mobile">
      <div class="row" style="background: #00334D;height: 76px;padding: 20px 18px 0px 18px;">
        <div style="float: left;width: 80%;margin-top: 10px;">
          <label
            style="color: white; font-size: 20px;
          font-weight: 700;"
            class="main-title"
          >
            {{ modalTitle }}
          </label>
        </div>

        <div style="float: right;width: 20%;">
          <img
            class="close-icon"
            style="width: 14px;height: 14px;float: right;
          margin-top: 18px;"
            (click)="closeModal()"
            src="assets/images/icons/closeWhite.png"
            alt=""
          />
        </div>
      </div>
      <div *ngIf="!isLoggedIn" class="already-member">
        <span translate="requestInformation.alreadyMember"></span>
        <span class="login new-link-color" (click)="openLoginPopup()" translate="requestInformation.login"></span>
      </div>
      <ion-row class="row-border"></ion-row>

      <div *ngIf="!input.brokerRequest" class="offered-by">
        <label translate="requestInformation.offeredBy"></label>
      </div>

      <div *ngIf="agents && agents.length > 0">
        <div *ngIf="agents.length == 1" class="row">
          <div style="width: 49%;">
            <div style="float: right; margin: 14px 14px 0px 14px;">
              <div>
                <img
                  style="height:60px; object-fit: contain;width: 170px;margin-top: 20px;"
                  [src]="getCompanyLogoImg(companyLogoImageId)"
                  default="assets/no-company-logo.png"
                />
              </div>
            </div>
          </div>
          <div class="company-separator-mobile"></div>
          <div style="float:right;width: 49%;">
            <div
              class="agent-section agent-padding"
              style="float: left;margin: 14px 14px 0px 14px;width: 170px;text-align: center;"
            >
              <div>
                <img
                  style=" border-radius: 50%;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                object-fit: cover;"
                  [src]="getMediumImg(agents[0].photoId)"
                  default="assets/images/default-profile-photo.png"
                />
              </div>
              <div class="flex-agent">
                <label class="agent-name">{{ agents[0].firstNameAndLastName }}</label>
                <div *ngIf="agents[0].phoneNumber" class="agent-info">
                  <ion-icon class="wa-icon-color" name="logo-whatsapp"></ion-icon>
                  <label
                    *ngIf="agents[0].showWhatsapp"
                    (click)="openWhatsapp($event, agents[0])"
                    class="agent-phone-number pointer-cursor"
                    >{{ agents[0].phoneNumber }}</label
                  >
                  <label
                    *ngIf="!agents[0].showWhatsapp"
                    class="agent-phone-number pointer-cursor"
                    (click)="openWhatsapp($event, agents[0])"
                  >
                    {{ 'global.listing-detail.showWhataspp' | translate }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="agents.length > 1" class="col-md-12" style="text-align: center;">
          <img
            style="height:50px; object-fit: contain;"
            [src]="getCompanyLogoImg(companyLogoImageId)"
            default="assets/no-company-logo.png"
          />
        </div>
        <div *ngIf="agents.length > 1" class="row" style="margin-top: 16px;text-align: center;">
          <div style="float: left;width: 50%;">
            <div class="agent-section agent-padding" style="margin: 14px 14px 0px 14px;">
              <div>
                <img
                  style=" border-radius: 49%;
                  min-width: 56px;
                  max-width: 56px;
                  height: 56px;
                  object-fit: cover;"
                  [src]="getMediumImg(agents[0].photoId)"
                  default="assets/images/default-profile-photo.png"
                />
              </div>
              <div class="flex-agent">
                <label class="">{{ agents[0].firstNameAndLastName }}</label>
                <div *ngIf="agents[0].phoneNumber" class="agent-info">
                  <ion-icon class="wa-icon-color" name="logo-whatsapp"></ion-icon>
                  <label
                    *ngIf="agents[0].showWhatsapp"
                    (click)="openWhatsapp($event, agents[0])"
                    class="agent-phone-number pointer-cursor"
                    >{{ agents[0].phoneNumber }}</label
                  >
                  <label
                    *ngIf="!agents[0].showWhatsapp"
                    class="agent-phone-number pointer-cursor"
                    (click)="openWhatsapp($event, agents[0])"
                  >
                    {{ 'global.listing-detail.showWhataspp' | translate }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="company-separator-mobile"></div>
          <div style="float:right;width: 49%;">
            <div class="agent-section agent-padding" style="margin: 14px 14px 0px 14px;">
              <div>
                <img
                  style=" border-radius: 50%;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                object-fit: cover;"
                  [src]="getMediumImg(agents[1].photoId)"
                  default="assets/images/default-profile-photo.png"
                />
              </div>
              <div class="flex-agent">
                <label class="agent-name">{{ agents[1].firstNameAndLastName }}</label>
                <div *ngIf="agents[1].phoneNumber" class="agent-info">
                  <ion-icon class="wa-icon-color" name="logo-whatsapp"></ion-icon>
                  <label
                    *ngIf="agents[1].showWhatsapp"
                    (click)="openWhatsapp($event, agents[1])"
                    class="agent-phone-number"
                    >{{ agents[1].phoneNumber }}</label
                  >
                  <label
                    *ngIf="!agents[1].showWhatsapp"
                    class="agent-phone-number pointer-cursor"
                    (click)="openWhatsapp($event, agents[1])"
                  >
                    {{ 'global.listing-detail.showWhataspp' | translate }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="input-boxes-container">
        <div class="input-box-section">
          <ion-input
            #firstName
            autocapitalize="off"
            autocomplete="off"
            formControlName="firstName"
            type="text"
            class="input-box"
            placeholder="{{ 'requestInformation.firstNameHolder' | translate }}"
          >
          </ion-input>
        </div>

        <div class="input-box-section">
          <ion-input
            #lastName
            autocapitalize="off"
            autocomplete="off"
            formControlName="lastName"
            type="text"
            class="input-box"
            placeholder="{{ 'requestInformation.lastNameHolder' | translate }}"
          >
          </ion-input>
        </div>

        <div class="input-box-section">
          <ion-input
            #email
            autocapitalize="off"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            autocomplete="off"
            formControlName="email"
            type="text"
            class="input-box"
            placeholder="{{ 'requestInformation.emailHolder' | translate }}"
          >
          </ion-input>
        </div>

        <div class="flex input-box-section" style="gap: 20px;">
          <ion-input
            #phoneNumber
            autocapitalize="off"
            autocomplete="off"
            formControlName="phoneNumber"
            type="text"
            style="width: 70%;"
            class="input-box"
            placeholder="{{ 'requestInformation.phoneHolder' | translate }}"
          >
          </ion-input>

          <!-- <ion-input #ext autocapitalize="off" autocomplete="off" formControlName="ext" type="text"
            class="input-box ext" placeholder="{{ 'requestInformation.extHolder' | translate }}">
          </ion-input> -->
        </div>

        <div class="input-box-section">
          <ion-textarea
            class="input-box textarea-note"
            name="note"
            [rows]="14"
            type="text"
            maxlength="500"
            spellcheck="true"
            [placeholder]="sendPlaceHolder"
            formControlName="note"
            #note
          ></ion-textarea>
        </div>

        <div class="sent-btn-section">
          <button
            class="full-width btn-orange"
            expand="full"
            shape="round"
            type="submit"
            value="submit"
            [disabled]="disableSentBtn()"
            style="width: 100%;font-size: 14px;--border-radius: 5px;"
          >
            {{ 'requestInformation.sent' | translate }}
          </button>
          <div class="sent-info">
            <span [innerHtml]="newToSiilaTxt"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Desktop -->
    <div *ngIf="!isMobile" class="main-container flex">
      <div class="flex">
        <div class="flex-align-content-end header">
          <img
            class="close-icon"
            style="width: 14px;height: 14px;"
            (click)="closeModal()"
            src="assets/images/icons/close.png"
            alt=""
          />
          <!--  <fa-icon class="close-icon" (click)="closeModal()" [icon]="closeIcon"></fa-icon> -->
        </div>
      </div>

      <div class="title-section">
        <label class="main-title">{{ modalTitle }} </label>
      </div>

      <div *ngIf="!isLoggedIn" class="already-member">
        <span translate="requestInformation.alreadyMember"></span>
        <span class="login new-link-color" (click)="openLoginPopup()" translate="requestInformation.login"></span>
      </div>

      <div *ngIf="!input.brokerRequest" class="offered-by">
        <label translate="requestInformation.offeredBy"></label>
      </div>

      <div *ngIf="!input.brokerRequest" id="companyLogoAgentPhoto" class="flex company-agent-photos-section ">
        <div [ngClass]="{ 'company-logo-width': agents.length == 1 }" class="company-logo-section">
          <img
            style="height:50px; object-fit: contain;"
            [src]="getCompanyLogoImg(companyLogoImageId)"
            default="assets/no-company-logo.png"
          />
        </div>

        <div class="company-separator"></div>

        <div
          *ngIf="agents && agents.length > 0"
          [ngClass]="{ 'company-logo-width': agents.length == 1 }"
          class="flex agent-photos-section company-agent-separator"
        >
          <div class="flex agent-section agent-padding">
            <div>
              <img
                style=" border-radius: 50%;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                object-fit: cover;"
                [src]="getMediumImg(agents[0].photoId)"
                default="assets/images/default-profile-photo.png"
              />
            </div>
            <div class="flex-col-wrap agent-info-container">
              <label class="agent-name">{{ agents[0].firstNameAndLastName }}</label>
              <div *ngIf="agents[0].phoneNumber" class="flex agent-info">
                <ion-icon class="wa-icon-color" name="logo-whatsapp"></ion-icon>
                <label
                  *ngIf="agents[0].showWhatsapp"
                  (click)="openWhatsapp($event, agents[0])"
                  class="agent-phone-number pointer-cursor"
                  >{{ agents[0].phoneNumber }}</label
                >
                <label
                  *ngIf="!agents[0].showWhatsapp"
                  class="agent-phone-number pointer-cursor"
                  (click)="openWhatsapp($event, agents[0])"
                >
                  {{ 'global.listing-detail.showWhataspp' | translate }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <div [ngClass]="{ 'company-separator': agents && agents.length > 1 }"></div>

        <div *ngIf="agents && agents.length > 1" class="flex agent-photos-section company-agent-separator">
          <div class="flex agent-section agent-padding">
            <div>
              <img
                style=" border-radius: 50%;
                min-width: 56px;
                max-width: 56px;
                height: 56px;
                object-fit: cover;"
                [src]="getMediumImg(agents[1].photoId)"
                default="assets/images/default-profile-photo.png"
              />
            </div>
            <div class="flex-col-wrap agent-info-container">
              <label class="agent-name">{{ agents[1].firstNameAndLastName }}</label>
              <div *ngIf="agents[1].phoneNumber" class="flex agent-info">
                <ion-icon class="wa-icon-color" name="logo-whatsapp"></ion-icon>
                <label
                  *ngIf="agents[1].showWhatsapp"
                  (click)="openWhatsapp($event, agents[1])"
                  class="agent-phone-number pointer-cursor"
                  >{{ agents[1].phoneNumber }}</label
                >
                <label
                  *ngIf="!agents[1].showWhatsapp"
                  class="agent-phone-number pointer-cursor"
                  (click)="openWhatsapp($event, agents[1])"
                >
                  {{ 'global.listing-detail.showWhataspp' | translate }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="input-boxes-container">
        <div class="input-box-section">
          <ion-input
            #firstName
            autocapitalize="off"
            autocomplete="off"
            formControlName="firstName"
            type="text"
            class="input-box"
            placeholder="{{ 'requestInformation.firstNameHolder' | translate }}"
          >
          </ion-input>
        </div>

        <div class="input-box-section">
          <ion-input
            #lastName
            autocapitalize="off"
            autocomplete="off"
            formControlName="lastName"
            type="text"
            class="input-box"
            placeholder="{{ 'requestInformation.lastNameHolder' | translate }}"
          >
          </ion-input>
        </div>

        <div class="input-box-section">
          <ion-input
            #email
            autocapitalize="off"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            autocomplete="off"
            formControlName="email"
            type="text"
            class="input-box"
            placeholder="{{ 'requestInformation.emailHolder' | translate }}"
          >
          </ion-input>
        </div>

        <div class="flex input-box-section" style="gap: 20px;">
          <ion-input
            #phoneNumber
            autocapitalize="off"
            autocomplete="off"
            formControlName="phoneNumber"
            type="text"
            style="width: 70%;"
            class="input-box"
            placeholder="{{ 'requestInformation.phoneHolder' | translate }}"
          >
          </ion-input>

          <!-- <ion-input #ext autocapitalize="off" autocomplete="off" formControlName="ext" type="text"
            class="input-box ext" placeholder="{{ 'requestInformation.extHolder' | translate }}">
          </ion-input> -->
        </div>

        <div class="input-box-section">
          <ion-textarea
            class="input-box textarea-note"
            name="note"
            [rows]="14"
            type="text"
            maxlength="500"
            spellcheck="true"
            [placeholder]="sendPlaceHolder"
            formControlName="note"
            #note
          ></ion-textarea>
        </div>

        <div class="sent-btn-section">
          <ion-button
            class="btn-color-2"
            expand="full"
            shape="round"
            type="submit"
            value="submit"
            [disabled]="disableSentBtn()"
            style="width: 100%;font-size: 14px;--border-radius: 5px;"
          >
            {{ 'requestInformation.sent' | translate }}
          </ion-button>
          <div class="sent-info">
            <span [innerHtml]="newToSiilaTxt"></span>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</form>
