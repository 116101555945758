import { environment } from '@env/environment';
import * as fromRouter from '@ngrx/router-store';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromCart from '../reducers/cart.reducer';
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
// import * as fromLayout from ';
import { InjectionToken } from '@angular/core';
/**
 * LocalStorageSync reducers
 */
export function localStorageSyncReducer(reducer) {
    return localStorageSync({ keys: ['Cart'] })(reducer);
}
/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export var ROOT_REDUCERS = new InjectionToken('Root reducers token', {
    factory: function () {
        var _a;
        return (_a = {},
            _a[fromCart.layoutFeatureKey] = fromCart.reducer,
            _a.router = fromRouter.routerReducer,
            _a);
    }
});
// console.log all actions
export function logger(reducer) {
    return function (state, action) {
        var result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.groupEnd();
        return result;
    };
}
/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export var metaReducers = !environment.production ? [logger] : [];
/**
 * Layout Reducers
 */
// export const getLayoutState = createFeatureSelector<State, fromLayout.State>(
//   'layout'
// );
// export const getShowSidenav = createSelector(
//   // getLayoutState,
//   // fromLayout.getShowSidenav
