import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageService, I18nService } from '@app/core';
import { ListingService } from '@app/core/listings';
import { FormBuilder, Validators } from '@angular/forms';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { WhatsAppService } from '@app/core/whatsapp.service';
import { AgentNotificationComponent } from '@app/search-navigation/components/agent-notification/agent-notification.component';
import { PopoverController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { AuthenticationService } from '@app/core/auth';
import { LoginFormDialogService } from '@app/shared/login';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material';
import { SubscribeModalComponent } from '@app/shared/subscribe/subscribe-modal.component';
var ListUserComponent = /** @class */ (function () {
    function ListUserComponent(breakpointObserver, route, router, fb, listingDetailService, location, _ts, imgService, listingService, userActivityService, i18nService, whatsAppService, agentNotPopOverCtrl, analyticsService, deviceDetector, auth, loginFormDialog, dialog) {
        var _this = this;
        this.breakpointObserver = breakpointObserver;
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.listingDetailService = listingDetailService;
        this.location = location;
        this._ts = _ts;
        this.imgService = imgService;
        this.listingService = listingService;
        this.userActivityService = userActivityService;
        this.i18nService = i18nService;
        this.whatsAppService = whatsAppService;
        this.agentNotPopOverCtrl = agentNotPopOverCtrl;
        this.analyticsService = analyticsService;
        this.deviceDetector = deviceDetector;
        this.auth = auth;
        this.loginFormDialog = loginFormDialog;
        this.dialog = dialog;
        this.sendMessage = false;
        this.loading = true;
        this.submitted = false;
        this.brokerEmail = false;
        this.brokerPhone = false;
        this.unsetBioOverflow = true;
        this.currentPage = 1;
        this.totalListingsCount = 0;
        this.isLoggedIn = false;
        this.whatsappPhone = false;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
        var navigation = this.router.getCurrentNavigation();
        this.listingId =
            navigation.extras.state && navigation.extras.state.listingId ? navigation.extras.state.listingId : null;
    }
    ListUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        try {
            this.isDesktop = this.deviceDetector.isDesktop() ? 'desktop' : '';
            this.isMobilePhone = this.deviceDetector.isMobile() ? 'mobile' : '';
            this.isTablet = this.deviceDetector.isTablet() ? 'tablet' : '';
            if (this.isDesktop.length) {
                this.currentDevice = this.isDesktop;
            }
            else if (this.isMobilePhone.length) {
                this.currentDevice = this.isMobilePhone;
            }
            else if (this.isTablet.length) {
                this.currentDevice = this.isTablet;
            }
            this.loginSubscription = this.auth.$isLoginSubject.subscribe(function (val) { return (_this.isLoggedIn = val); });
            this.loading = true;
            this.userId = this.route.snapshot.params.id;
            this.createForm();
            this.getUserInfo(this.userId)
                .then(function (result) {
                _this.broker = result;
                11;
                var id = { offeredByUserId: _this.broker.userId };
                _this.listingService.searchListings(id, _this.currentPage).then(function (response) {
                    _this.totalListingsCount = parseInt(response.headers.get('x-total-count'));
                    _this.listings = response.body.listings;
                    _this.listingIds = response.body.allListingIds;
                    _this.showMoreIcon = _this.totalListingsCount - _this.currentPage * 20 > 0;
                    _this.loading = false;
                });
            })
                .catch(function (e) {
                var state = {
                    badURL: environment.spotServerUrl + "/listing-user/" + _this.userId
                };
                console.error('Listing User page', e, _this.userId);
                _this.router.navigateByUrl('/not-found', { state: state });
            });
        }
        catch (e) {
            var state = {
                badURL: environment.spotServerUrl + "/listing-user/" + this.userId
            };
            console.error('Listing User page', e, this.userId);
            this.router.navigateByUrl('/not-found', { state: state });
        }
    };
    ListUserComponent.prototype.ngOnDestroy = function () {
        this.loginSubscription.unsubscribe();
    };
    ListUserComponent.prototype.unsetOverflow = function () {
        this.unsetBioOverflow = true;
    };
    Object.defineProperty(ListUserComponent.prototype, "form", {
        get: function () {
            return this.angForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ListUserComponent.prototype.checkOverflow = function () {
        var el = document.getElementById('#bio');
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === 'visible')
            el.style.overflow = 'hidden';
        var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        el.style.overflow = curOverflow;
        return isOverflowing;
    };
    ListUserComponent.prototype.getMoreListings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                id = { offeredByUserId: this.broker.userId };
                this.listingService.searchListings(id, ++this.currentPage).then(function (response) {
                    var newListings = response.body.listings;
                    _this.showMoreIcon = _this.totalListingsCount - _this.currentPage * 20 > 0;
                    if (newListings && newListings.length) {
                        _this.listings = _this.listings.concat(newListings);
                    }
                    _this.loading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    ListUserComponent.prototype.createForm = function () {
        this.angForm = this.fb.group({
            name: ['', Validators.required],
            phone: [''],
            email: [''],
            note: ['', Validators.required]
        }, { validator: this.checkFields });
    };
    ListUserComponent.prototype.checkFields = function (formGroup) {
        var emailField = formGroup.controls['email'].value;
        var phoneField = formGroup.controls['phone'].value;
        return !emailField.length && !phoneField.length ? { oneRequired: true } : null;
    };
    ListUserComponent.prototype.goBack = function () {
        this.location.back();
    };
    ListUserComponent.prototype.isHeaderVisible = function (event) {
        this.displayScrollToTop = event.visible ? false : true;
    };
    ListUserComponent.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    ListUserComponent.prototype.openShowMoreLogInModal = function (id) {
        var _this = this;
        var subscribeDialog = this.dialog.open(SubscribeModalComponent, {
            width: '400px',
            panelClass: 'my-custom-dialog-class',
            data: id
        });
        subscribeDialog.afterClosed().subscribe(function (val) {
            if (val != undefined) {
                switch (val) {
                    case 1:
                        _this.showBrokerPhone(val);
                        break;
                    case 2:
                        _this.showBrokerEmail(val);
                        break;
                    case 3:
                        _this.showBrokerWhatsapp(val);
                        break;
                    default:
                        return null;
                }
            }
        });
    };
    ListUserComponent.prototype.showBrokerPhone = function (notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(1);
                return;
            }
        }
        this.brokerPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWPHONE);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_PHONE, this.currentDevice);
    };
    ListUserComponent.prototype.showBrokerEmail = function (notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(2);
                return;
            }
        }
        this.brokerEmail = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWEMAIL);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_EMAIL, this.currentDevice);
    };
    ListUserComponent.prototype.showBrokerWhatsapp = function (notSubscribe) {
        if (notSubscribe === void 0) { notSubscribe = null; }
        if (!this.isLoggedIn) {
            if (sessionStorage.getItem('contSubscribe') && notSubscribe == null) {
                this.countSubscribe = parseInt(sessionStorage.getItem('contSubscribe'));
                this.countSubscribe = this.countSubscribe + 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
            else {
                this.countSubscribe = 1;
                sessionStorage.setItem('contSubscribe', this.countSubscribe.toString());
            }
        }
        if (!this.isLoggedIn && notSubscribe == null && (this.countSubscribe == 4 || this.countSubscribe == 1)) {
            if (!localStorage.getItem('emailSubscribed')) {
                this.countSubscribe != 1 ? sessionStorage.removeItem('contSubscribe') : '';
                this.openShowMoreLogInModal(3);
                return;
            }
        }
        this.whatsappPhone = true;
        this.registerUserActivity(UserActivityType.BROKER_SHOWWHATSAPP);
        this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_WHATSAPP, this.currentDevice);
    };
    ListUserComponent.prototype.registerUserActivity = function (userActivityType) {
        var userActivity = {
            module: userActivityType,
            brokerId: this.broker.userId,
            listingId: null
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    ListUserComponent.prototype.getUserInfo = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.listingDetailService.getUserById(id, null).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListUserComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    ListUserComponent.prototype.clickToChat = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headerMsg, msgToUserAgent, spotURL, message;
            return tslib_1.__generator(this, function (_a) {
                headerMsg = this.i18nService.get('global.broker-detail.clickToChat.whatsAppMsgHeader');
                msgToUserAgent = this.i18nService.get('global.broker-detail.clickToChat.msgToUserAgent');
                spotURL = environment.spotServerUrl.concat(this.i18nService.getLanguagePathURL());
                message = headerMsg + " " + spotURL + "\n\n" + msgToUserAgent + "\n    ";
                this.whatsAppService.sendMessageViaWhataspp(this.broker.whatsappNumber, message);
                return [2 /*return*/, false];
            });
        });
    };
    ListUserComponent.prototype.clickToEmail = function (ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaultMsg, message, popover;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        defaultMsg = this.i18nService.get('global.broker-detail.clickToEmail.msgToUserAgent');
                        message = "" + defaultMsg;
                        return [4 /*yield*/, this.agentNotPopOverCtrl.create({
                                component: AgentNotificationComponent,
                                event: ev,
                                cssClass: 'popover-agent-email',
                                componentProps: {
                                    defaultMsg: message,
                                    brokerId: this.broker.userId,
                                    popupCtrl: this.agentNotPopOverCtrl,
                                    pageURLFrom: environment.spotServerUrl
                                }
                            })];
                    case 1:
                        popover = _a.sent();
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ListUserComponent.prototype.openEmail = function () {
        window.location.href = "mailto:" + this.broker.email;
        return false;
    };
    return ListUserComponent;
}());
export { ListUserComponent };
