import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';

//TODO: Expand to detect browser/mobile and determine to use cordova geolocation or capacitor plugins
@Injectable()
export class GeoLocationService implements OnInit {
  isTracking: boolean = false;
  positions: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  ngOnInit(): void {}

  startBrowserTracking() {
    if (navigator && navigator.geolocation) {
      this.isTracking = true;
      try {
        navigator.geolocation.watchPosition((position: any) => {
          this.positions.next(position);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  get geolocationRecord(): Observable<any> {
    return this.positions.asObservable();
  }
}
