<div class="accept-cancel-modal">
  <ion-icon class="close-icon" name="close" (click)="closeModal('close')"></ion-icon>
  <h2 matDialogTitle>{{ data.title }}</h2>

  <p matDialogContent [innerHtml]="data.body"></p>

  <div matDialogActions class="actions">
    <ion-button class="close-button" color="secondary" (click)="onCancelClick()">{{ data.cancelText }}</ion-button>
    <ion-button class="close-button" color="primary" (click)="onAcceptClick()">{{ data.acceptText }}</ion-button>
  </div>
</div>
