import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';

@Injectable()
export class MultiLanguageService {
  public createMultiLanguageForm(formArrayTitle: string): FormGroup {
    return new FormGroup({
      [formArrayTitle]: new FormArray([])
    });
  }

  public getLanguageOption() {
    return {
      en: 'global.languageChoice.english',
      es: 'global.languageChoice.spanish',
      'pt-br': 'global.languageChoice.brasilportuguese'
    };
  }

  public createLanguageFormGroup(
    fb: FormBuilder,
    selectedLanguage: string,
    formControlName?: string,
    value?: any
  ): FormGroup {
    const formControlPropName = formControlName ? formControlName : selectedLanguage;
    return fb.group({
      [formControlPropName]: new FormControl(value ? value : '', Validators.required)
    });
  }

  public getLanguageModel() {
    return {
      en: false,
      es: false,
      'pt-br': false
    };
  }

  public santizeFormValue(formValue: any, fieldName: string) {
    const santizeValue: any = {};
    formValue[fieldName].forEach((item: any) => {
      Object.keys(item).forEach(key => {
        Object.assign(santizeValue, { [key]: item[key] });
      });
    });
    return santizeValue;
  }

  public ngQuillEditorToolbarOption() {
    return {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: <any>[] }, { background: <any>[] }],
        [{ font: <any>[] }],
        [{ align: <any>[] }],
        ['clean'],
        ['link', 'image', 'video']
      ]
    };
  }

  public getBrowserLanguage(lang: string) {
    const supportLanguage: any = {
      en: 'en',
      es: 'es',
      pt: 'pt-br'
    };
    return supportLanguage.hasOwnProperty(lang.toLowerCase()) ? supportLanguage[lang] : 'en';
  }

  public getCurrentLanguage(currentLanguage: string): string {
    let language: string = '';
    if (currentLanguage.toLowerCase() === 'es-mx') {
      language = 'es';
    } else if (currentLanguage.toLowerCase() === 'en-us') {
      language = 'en';
    }
    return language;
  }
}
