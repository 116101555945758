import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { UserProfile } from '@app/models/user-account.model';
import { HttpClient } from '@angular/common/http';
import { ImageService } from '../image.service';
import { BehaviorSubject } from 'rxjs';
import { validatePhoneNumber } from '../../user-home/my-account/phone-custom-validation';

@Injectable()
export class UserHomeService {
  constructor(private http: HttpClient, private imageService: ImageService) {}

  public profileImage$ = new BehaviorSubject<any>({});
  private updateProfileImageUrl: string = 'api/resizedimage';
  primaryPhoneCountryISO: string;
  whatsAppCountryISO: string;

  private santizeContactNumber(rawFormData: FormGroup) {
    const formPrimaryPhoneNumber = rawFormData.get('contact').get('primaryPhone').value;
    const formWhatsAppPhoneNumber = rawFormData.get('contact').get('whatsApp').value;

    const userPhones: any = [];
    if (formPrimaryPhoneNumber) {
      let obj = {
        phoneType: 'PHONE_NUMBER',
        phoneNumber:
          formPrimaryPhoneNumber.internationalNumber && formPrimaryPhoneNumber.length > 0
            ? formPrimaryPhoneNumber.internationalNumber
            : formPrimaryPhoneNumber.number,
        isoCode: formPrimaryPhoneNumber.countryCode
      };
      userPhones.push(obj);
    }
    if (formWhatsAppPhoneNumber) {
      let obj = {
        phoneType: 'WHATSAPP',
        phoneNumber: formWhatsAppPhoneNumber.internationalNumber,
        isoCode: formWhatsAppPhoneNumber.countryCode
      };
      userPhones.push(obj);
    }
    return userPhones;
  }

  private santizeUserBiography(rawFormData: FormGroup) {
    const biographyList: any[] = rawFormData.get('profileInfo').get('biography').value;
    const biography: string = biographyList.reduce((accumulator: any, item: any) => {
      accumulator = Object.assign(accumulator, item);
      return accumulator;
    }, {});
    return JSON.stringify(biography);
  }

  public sanitizeUserProfile(rawUserProfile: any, userFormProfile: any) {
    Object.keys(userFormProfile).forEach(key => {
      rawUserProfile[key] = userFormProfile[key];
    });
    return rawUserProfile;
  }

  public santizeUserProfileForm(rawFormData: FormGroup) {
    return {
      jobTitle: rawFormData.get('profileInfo').get('jobTitle').value,
      firstName: rawFormData.get('userName').get('firstName').value,
      lastName: rawFormData.get('userName').get('lastName').value,
      address: rawFormData.get('location').get('address').value,
      city: rawFormData.get('location').get('city').value,
      state: rawFormData.get('location').get('state').value,
      postalCode: rawFormData.get('location').get('postalCode').value,
      country: rawFormData.get('location').get('country').value,
      userPhones: this.santizeContactNumber(rawFormData),
      biography: this.santizeUserBiography(rawFormData),
      excludeFromCommunity: rawFormData.get('advancedSettings').get('excludeFromCommunity').value,
      emailNotification: rawFormData.get('advancedSettings').get('emailNotification').value,
      excludeFromCommunityEmails: rawFormData.get('advancedSettings').get('excludeFromCommunityEmails').value
    };
  }

  public createUserProfileForm() {
    return new FormGroup({
      languages: new FormControl(''),
      profileInfo: new FormGroup({
        jobTitle: new FormControl('', Validators.required),
        biography: new FormArray([])
      }),
      userName: new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required)
      }),
      location: new FormGroup({
        address: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        postalCode: new FormControl(''),
        country: new FormControl(''),
        company: new FormControl({ value: '', disabled: true })
      }),
      contact: new FormGroup({
        primaryPhone: new FormControl('', [Validators.required, validatePhoneNumber(false)]),
        whatsApp: new FormControl('', [validatePhoneNumber(true)])
      }),
      advancedSettings: new FormGroup({
        excludeFromCommunity: new FormControl(),
        emailNotification: new FormControl(),
        excludeFromCommunityEmails: new FormControl()
      })
    });
  }

  public createChangePasswordForm() {
    return new FormGroup({
      currentPassword: new FormControl(''),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
      confirmNewPassword: new FormControl('', Validators.required)
    });
  }

  public getContactNumber(userAccount: UserProfile, phoneType: string) {
    const contactNumber: any = userAccount.phones.filter(
      item =>
        (item.phoneType && item.phoneType.toLowerCase() === phoneType.toLowerCase()) ||
        (!item.phoneType && phoneType === 'PHONE_NUMBER')
    );
    return contactNumber.length ? contactNumber[0].phoneNumber : '';
  }

  public getEmailUpdateErrorMessage(error: any) {
    const error406: any = {
      header: 'global.user.emailAlreadyTaken',
      bodyMessage: 'global.user.message.updateEmailAlreadyExistError'
    };

    const genericError: any = {
      header: 'global.user.updateEmailAddress',
      bodyMessage: 'global.user.message.updateEmailGenericError'
    };
    return error.status === 406 ? error406 : genericError;
  }

  public getEmailUpdateSuccessMessage() {
    return {
      header: 'global.user.updateEmailAddress',
      bodyMessage: 'global.user.message.updateEmailSuccessfully'
    };
  }

  public getPasswordChangeErrorMessage(error: any) {
    const error400: any = {
      header: 'global.user.changePassword',
      bodyMessage: 'global.user.message.incorrectCurrentPassword'
    };

    const genericError: any = {
      header: 'global.user.changePassword',
      bodyMessage: 'global.user.message.updatePasswordGenericError'
    };
    return error.status === 400 ? error400 : genericError;
  }

  public getPasswordChangeSuccessMessage() {
    return {
      header: 'global.user.changePassword',
      bodyMessage: 'global.user.message.updatePasswordSuccessfully'
    };
  }

  public getModalProp(component: any, componentProps: any, cssClass?: string) {
    return {
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: cssClass,
      component: component,
      componentProps: componentProps
    };
  }

  public getImageCropperOptions(): any {
    return {
      maintainAspectRatio: 'true',
      cropperMinWidth: 128,
      cropperMinHeight: 128,
      resizeToWidth: 200,
      format: 'png',
      roundCropper: true,
      onlyScaleDown: true
    };
  }

  public getLogoCropperOptions(): any {
    return {
      maintainAspectRatio: 'true',
      resizeToWidth: 136,
      format: 'png',
      onlyScaleDown: false
    };
  }

  public getUploadImageInfoModalParam(isSuccess: boolean, data?: any) {
    let params: any = isSuccess
      ? {
          headerTitle: 'global.user.editImage',
          bodyMessage: 'global.user.message.imageProfileEditSuccessfully',
          action: 'updateProfileImageSuccess',
          extraData: data
        }
      : {
          headerTitle: 'global.user.editImage',
          bodyMessage: 'global.user.message.imageProfileEditError',
          action: 'updateProfileImageError'
        };
    return params;
  }

  public updateUserProfileImage(login: any, file: any, newPhoto: any) {
    const payload = new FormData();
    payload.append('caption', `${login}_profile_photo_resize`); // If it changes, we need to made some changes on the API as well.
    payload.append('file', file);
    payload.append('newPhoto', newPhoto);
    payload.append('application', 'SPOT');
    return this.http.post(this.updateProfileImageUrl, payload);
  }

  public uploadUserProfileImage(login: any, file: any) {
    const payload = new FormData();
    payload.append('caption', `${login}_profile_photo`);
    payload.append('file', file);
    return this.http.post(this.updateProfileImageUrl, payload);
  }

  public setProfileImageProp(image: any) {
    return {
      croppedImage: this.imageService.mediumThumbnail(image.profileImageId),
      originalImage: this.imageService.mediumThumbnail(image.originalImageId)
    };
  }

  public setUserAccountProp(account: any, prop: any) {
    return {
      profile: account,
      profileImage: this.imageService.mediumThumbnail(prop.profileImageId),
      originalImage: this.imageService.mediumThumbnail(prop.originalImageId)
    };
  }

  public addBiographyFormGroup(
    fb: FormBuilder,
    currentSelectedLanguage: string,
    value?: any,
    formControlName?: string
  ): FormGroup {
    const formControlPropName = formControlName ? formControlName : currentSelectedLanguage;
    return fb.group({
      [formControlPropName]: new FormControl(value ? value : '', Validators.required)
    });
  }

  public getContactCountriesISO(userPhones: any[]) {
    this.primaryPhoneCountryISO = this.getContactISO('PHONE_NUMBER', userPhones);
    this.whatsAppCountryISO = this.getContactISO('WHATSAPP', userPhones);
  }

  private getContactISO(contactType: string, contacts: any[]) {
    const ISO = contacts.filter(item => item.phoneType && item.phoneType.toLowerCase() === contactType.toLowerCase());
    return ISO.length && ISO[0].isoCode ? ISO[0].isoCode.toLowerCase() : '';
  }

  public getMultiLangOption() {
    return {
      element: { richtext: true },
      formArrayName: 'biography',
      formGroupName: 'profileInfo',
      placeholder: 'global.user.biographyPlaceholder',
      columnLabel: 'global.user.profileForm.biography',
      isRegister: false
    };
  }
}
