<div [ngClass]="{ 'with-label-section': includeLabel }" class="card-info-bar">
  <ion-button
    [ngClass]="{ 'is-favorite': isFavoriteListing(), 'md-size': isMdSize(), 'lg-size': isLgSize() }"
    class="card-info-bar__favorite-button"
    expand="full"
    size="small"
    fill="clear"
    (click)="setFavoriteLisitng($event)"
  >
    <fa-icon class="icon-size" [icon]="faHeart"></fa-icon>
  </ion-button>
  <span class="favorite__text" *ngIf="includeLabel" translate="buildinsDetail.favorite"></span>
</div>
