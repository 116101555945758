import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  @Input() videoURL: string;
  videoURLEmbedded: string;
  videoURLEmbedded2: string;
  videosURLEmbedded: any;

  youtubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  youtubeUrls = ['youtube.com', 'youtu.be'];

  showVideoComponent = true;

  loadingVideo = true;

  closeIcon: any;
  fromPopup: boolean = false;
  spotTitle: string;

  constructor(private navParams: NavParams, private popoverCtrl: PopoverController) {}

  ngAfterViewInit() {
    this.loadingVideo = false;
  }

  onLoad(id: string) {
    let videoNotFound = false;
    const videoIFrame: any = document.getElementById('videoTourIFrame' + id);
    if (videoIFrame && videoIFrame.contentDocument) {
      const prerenderStatusCodeElement = videoIFrame.contentDocument.getElementsByName('prerender-status-code');
      if (prerenderStatusCodeElement) {
        if (prerenderStatusCodeElement[0]) {
          if (prerenderStatusCodeElement[0].content == '404') {
            videoNotFound = true;
          }
        }
      }
    }
    this.showVideoComponent = !videoNotFound;
    if (!this.loadingVideo && this.showVideoComponent) {
      document.getElementById('videoTourIFrame').style.visibility = 'visible';
      document.getElementById('videoTourIFrame' + id).style.visibility = 'visible';
    }
  }

  ngOnInit() {
    if (this.navParams && this.navParams.data) {
      if (this.navParams.data.fromPopup) {
        this.videoURL = this.navParams.data.videoURL;
        this.fromPopup = this.navParams.data.fromPopup;
        this.spotTitle = this.navParams.data.spotTitle;
        this.closeIcon = faTimes;
      }
    }
    if (this.videoURL != null) {
      this.videosURLEmbedded = this.videoURL.split('\n');
      if (this.videosURLEmbedded.length == 1) {
        this.videoURLEmbedded = this.videosURLEmbedded[0].trim();
        for (const youtubeUrl of this.youtubeUrls) {
          if (this.videosURLEmbedded[0].includes(youtubeUrl)) {
            this.videoURLEmbedded = this.getYoutubeURL(this.videosURLEmbedded[0].trim());
            break;
          }
        }
      } else {
        this.videoURLEmbedded = this.videosURLEmbedded[0].trim();
        this.videoURLEmbedded2 = this.videosURLEmbedded[1].trim();
        for (const youtubeUrl of this.youtubeUrls) {
          if (this.videosURLEmbedded[0].includes(youtubeUrl)) {
            this.videoURLEmbedded = this.getYoutubeURL(this.videosURLEmbedded[0].trim());
          }
          if (this.videosURLEmbedded[1].includes(youtubeUrl)) {
            this.videoURLEmbedded2 = this.getYoutubeURL(this.videosURLEmbedded[1].trim());
          }
        }
      }
    }
  }

  getYoutubeURL(url: string) {
    const match = url.match(this.youtubeRegExp);

    if (match && match[2] && match[2].split(' ').length === 1) {
      return `//www.youtube.com/embed/${match[2]}`;
    } else {
      return this.getURLFromVideoWithIframeAttributes(url);
    }
  }

  private getURLFromVideoWithIframeAttributes(videoURL: string) {
    let youtubeVideo = null;
    const iframeAttributes = videoURL.split(' ');
    if (iframeAttributes && iframeAttributes.length > 0) {
      for (const attr of iframeAttributes) {
        if (attr.includes('youtube.com')) {
          const iframeAttrMatch = attr.match(this.youtubeRegExp);
          if (iframeAttrMatch && iframeAttrMatch.length >= 2) {
            const embededVideo = iframeAttrMatch[2].replace(/\W/g, '');
            youtubeVideo = `//www.youtube.com/embed/${embededVideo}`;
            break;
          }
        }
      }
    }
    return youtubeVideo;
  }

  closeModal() {
    this.popoverCtrl.dismiss();
  }
}
