<ion-header style="z-index:9999">
  <ion-toolbar id="breadCrumToolbar">
    <ion-buttons slot="start" *ngIf="!hideButtons">
      <ion-button
        *ngIf="!standardBackButton"
        class="back-button"
        ion-button
        icon-only
        tappable
        (mouseover)="iosBack($event)"
        (click)="back($event)"
        matTooltip="{{ 'global.form.back' | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip-font"
        [matTooltipDisabled]="!isDesktop"
      >
        <ion-icon color="primary" name="md-arrow-back" size="medium"></ion-icon>
      </ion-button>
      <button
        *ngIf="standardBackButton"
        translate="global.form.back"
        (click)="back($event)"
        class="btn ml-2 custom-toolbar-button"
      ></button>
    </ion-buttons>
    <ng-content></ng-content>
  </ion-toolbar>

  <ng-content select="[secondaryToolbar]"></ng-content>
</ion-header>
