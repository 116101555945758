import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { environment } from '@env/environment';
import { Logger, I18nService, PlatformHelperService, LanguageService } from '@app/core';
import { MetaService } from '@ngx-meta/core';
import { PWAService } from './shared/pwa-service';
import { MatDialog } from '@angular/material';
import { AccessGrantedDialogComponent } from './shared/access-granted/access-granted.component';
import { SwUpdate } from '@angular/service-worker';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AnalyticsService } from './google-analytics/analytics-service';
import { MatomoService } from './matomo/matomo-service';
import { Location } from '@angular/common';
import { ReixLangCulture } from './core/reixspotlanguages.service';
import { RoutingHelperService } from './core/helpers/routing-helper.service';
import { AppRouteNames } from './core/helpers/app-route-names.service';
import { urlRedirects } from './shared/util/params';
import { urlPromotedRedirects } from './shared/util/params';
var log = new Logger('App');
var AppComponent = /** @class */ (function () {
    function AppComponent(router, titleService, platform, statusBar, splashScreen, i18nService, meta, pwaService, platformHelperService, languageService, dialog, swUpdate, gaService$, analyticsService, matomoService, location, routingHelperSrv) {
        var _this = this;
        this.router = router;
        this.titleService = titleService;
        this.platform = platform;
        this.statusBar = statusBar;
        this.splashScreen = splashScreen;
        this.i18nService = i18nService;
        this.meta = meta;
        this.pwaService = pwaService;
        this.platformHelperService = platformHelperService;
        this.languageService = languageService;
        this.dialog = dialog;
        this.swUpdate = swUpdate;
        this.gaService$ = gaService$;
        this.analyticsService = analyticsService;
        this.matomoService = matomoService;
        this.location = location;
        this.routingHelperSrv = routingHelperSrv;
        this.isDataAvailable = false;
        this.redirectionRules();
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function (event) {
            if (event.url !== event.urlAfterRedirects) {
                _this.setNotFoundMeta();
            }
            else {
                _this.unsetNotFoundMeta();
            }
            if (event.url && event.url == '/') {
                _this.routingHelperSrv.navigateToSpotHomePage();
            }
            if (event.url &&
                (event.url == "/" + AppRouteNames.MY_SPOTS_ROUTE || event.url == "/" + AppRouteNames.MY_SPOTS_ROUTE_CL)) {
                _this.routingHelperSrv.navigateFavoritesPage();
                return;
            }
            if (!_this.i18nService.hasURLLangPathParam(event.url)) {
                event.url = _this.i18nService.addDefaultLanguageToPath(event.url);
                if (!_this.previousURL || _this.previousURL !== event.url) {
                    var state = history.state;
                    _this.location.go(event.url, '', state);
                }
                else {
                    return;
                }
            }
            _this.i18nService.setLanguageBasedOnURL(event.url);
            _this.gaService$.pageView(window.location.pathname, _this.titleService.getTitle(), window.location.href, {
                dimension1: _this.platformHelperService.isInStandaloneMode() ? 'App' : 'Browser',
                dimension2: localStorage.getItem('userType') ? localStorage.getItem('userType') : 'Visitor',
                dimension3: localStorage.getItem('userCompany') ? localStorage.getItem('userCompany') : 'Visitor'
            });
            setTimeout(function () {
                _this.matomoService.trackPageView2(_this.titleService.getTitle());
            }, 2500);
            _this.previousURL = event.url;
        });
    }
    AppComponent.prototype.validateUpdate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                localStorage.removeItem('isDataAvailableReload');
                if (this.swUpdate.isEnabled) {
                    this.swUpdate.available.subscribe(function (evt) {
                        if (sessionStorage.getItem('isDataAvailable') == null) {
                            _this.isDataAvailable = true;
                            sessionStorage.setItem('isDataAvailable', 'true');
                            window.location.reload();
                        }
                    });
                }
                this.swUpdate
                    .checkForUpdate()
                    .then(function () {
                    _this.isDataAvailable = true;
                })
                    .catch(function (err) {
                    _this.isDataAvailable = true;
                });
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var defaultLang;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.validateNoIndexNoFollowTag();
                        if (environment.production) {
                            Logger.enableProductionMode();
                        }
                        this.languageService.registerCulture(ReixLangCulture.COUNTRY_LANG);
                        this.languageService.registerCulture(ReixLangCulture.EN);
                        defaultLang = this.i18nService.getDefaultLang();
                        if (this.i18nService.currentURLHasLangParam()) {
                            defaultLang = this.i18nService.getLanguagePathURL();
                        }
                        this.i18nService.init(defaultLang, environment.supportedLanguages);
                        if (!this.pwaService.signatureExpired() &&
                            (this.platformHelperService.isIosChrome() || this.platformHelperService.isIosSafari())) {
                            this.pwaService.appInstalled$.next(true);
                        }
                        // Cordova platform and plugins initialization
                        return [4 /*yield*/, this.platform.ready()];
                    case 1:
                        // Cordova platform and plugins initialization
                        _a.sent();
                        this.onCordovaReady();
                        setTimeout(function () {
                            _this.matomoService.init();
                        }, 1000);
                        setTimeout(function () {
                            if (!_this.isDataAvailable) {
                                _this.isDataAvailable = true;
                            }
                            sessionStorage.setItem('isDataAvailable', 'true');
                            window.prerenderReady = true;
                            _this.analyticsService.init();
                        }, 5000);
                        setTimeout(function () {
                            _this.validateUpdate();
                            window.addEventListener('beforeinstallprompt', function (evt) {
                                evt.preventDefault();
                                _this.pwaService.setDeferredPrompt(evt);
                                _this.pwaService.appInstalled$.next(false);
                                _this.pwaService.pwaRemoveInstallSignature();
                            });
                            window.addEventListener('appinstalled', function (evt) {
                                _this.pwaService.appInstalled$.next(true);
                                _this.pwaService.pwaSaveInstallSignature();
                            });
                        }, 2000);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.reloadWindow = function () {
        if (window) {
            window.location.reload();
        }
    };
    AppComponent.prototype.setNotFoundMeta = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.meta.setTag('prerender-status-code', '404');
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.unsetNotFoundMeta = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.meta.removeTag('name = "prerender-status-code"');
                return [2 /*return*/];
            });
        });
    };
    AppComponent.prototype.checkAccess = function () {
        this.dialog.open(AccessGrantedDialogComponent, { disableClose: true });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.i18nService.destroy();
        this.routerObserver.unsubscribe();
    };
    AppComponent.prototype.onCordovaReady = function () {
        if (window['cordova']) {
            window['Keyboard'].hideFormAccessoryBar(true);
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        }
    };
    AppComponent.prototype.validateNoIndexNoFollowTag = function () {
        switch (window.location.host) {
            case 'test.siilaspot.com.br':
            case 'stg.siilaspot.com.br':
            case 'stg.siilaspot.com.mx':
            case 'test.siilaspot.com.mx':
            case 'dev.siilaspot.com.br':
            case 'localhost:4200':
            case 'localhost':
                this.addNoIndexMetaTag();
                break;
            case 'siilaspot.com.br':
            case 'www.siilaspot.com.br':
            case 'siilaspot.com.mx':
            case 'www.siilaspot.com.mx':
                break;
        }
    };
    AppComponent.prototype.addNoIndexMetaTag = function () {
        var metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'robots');
        metaTag.setAttribute('content', 'noindex, nofollow');
        document.head.appendChild(metaTag);
    };
    AppComponent.prototype.redirectionRules = function () {
        var currentUrl = window.location.href;
        if (urlRedirects.has(currentUrl)) {
            window.location.replace(urlRedirects.get(currentUrl));
        }
        var result = this.findMatchingUrl(currentUrl, urlPromotedRedirects);
        if (result) {
            window.location.replace(result);
        }
    };
    AppComponent.prototype.findMatchingUrl = function (url, urlMap) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(urlMap.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = tslib_1.__read(_c.value, 2), regex = _d[0], value = _d[1];
                var pattern = new RegExp(regex);
                var match = url.match(pattern);
                if (match) {
                    // Match found, return the corresponding value
                    return value;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // No match found
        return null;
    };
    return AppComponent;
}());
export { AppComponent };
