import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationExtras } from '@angular/router';
import { PlatformHelperService } from '@app/core';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
import { ListingService } from '@app/core/listings';
import { I18nService } from '@app/core/i18n.service';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input('') options: BreadcrumbOptions;
  @Input('hideButtons') hideButtons?: any;
  @Input() standardBackButton?: boolean = false;
  @Input() backToPage?: string;
  isDesktop: any;
  isIos: any;

  constructor(
    private _location: Location,
    private router: Router,
    private platformHelperService: PlatformHelperService,
    private listingService: ListingService,
    private listingSearchService: ListingSearchService,
    private i18n: I18nService
  ) {}

  ngOnInit(): void {
    this.isDesktop = this.platformHelperService.isDesktopMode();
    this.isIos = this.platformHelperService.isIosSafari() && /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  back(event: any) {
    if (this.backToPage) {
      this.router.navigate([this.backToPage]);
      return;
    }
    if (this.options) {
      //let state: any = this._location.getState();
      if (this.options.navigateHome) {
        this.router.navigate(['/']);
      } else {
        this._location.back();
      }
    } else {
      this._location.back();
    }
    event.preventDefault();
  }

  iosBack(event: any) {
    if (this.isIos && !this.isDesktop) {
      if (this.options) {
        //let state: any = this._location.getState();
        if (this.options.navigateHome) {
          this.router.navigate(['/']);
        } else {
          this._location.back();
        }
      } else {
        this._location.back();
      }
      event.preventDefault();
    }
  }

  goToSearchPage() {
    const lastPageSeen = this.listingSearchService.readLastSearchPageFromStorage();
    if (lastPageSeen && lastPageSeen == SelectedFilterKey.MY_LISTINGS) {
      this.router.navigateByUrl('user-home/my-listings');
      return;
    } else if (lastPageSeen && lastPageSeen == SelectedFilterKey.LISTING_ANALYTICS) {
      this.router.navigateByUrl('user-home/listing-analytics');
      return;
    }
    const listingSearchDto: any = {};
    const searchDTO = this.listingSearchService.readSearchDTOFromStorage(
      SelectedFilterKey.REGULAR_SEARCH,
      listingSearchDto
    );
    if (searchDTO) {
      listingSearchDto.buildingTypes = searchDTO.buildingTypes;
      listingSearchDto.listingLeaseOrSale = searchDTO.listingLeaseOrSale;
      listingSearchDto.stateId = searchDTO.stateId;
      listingSearchDto.cityId = searchDTO.cityId;
      listingSearchDto.keyword = searchDTO.keyword;
    }
    if (
      listingSearchDto.buildingTypes ||
      listingSearchDto.listingLeaseOrSale ||
      listingSearchDto.stateId ||
      listingSearchDto.cityId ||
      listingSearchDto.keyword
    ) {
      let lang = this.i18n.getCurrentLanguage();
      this.listingService.getUrlFromListingsSearchDto(listingSearchDto).then((response: any) => {
        response.url = JSON.parse(response.url);
        const navigationExtras: NavigationExtras = {
          queryParamsHandling: 'merge',
          queryParams: response.keyword
            ? {
                keyword: response.keyword
              }
            : null
        };
        this.router.navigate([`/search${response.url[lang]}`], navigationExtras);
      });
    } else {
      this.router.navigate([`/search`]);
    }
  }
}

export interface BreadcrumbOptions {
  usePlatformLocation: boolean;
  navigateHome?: boolean;
  stickyBackButton?: boolean;
}
