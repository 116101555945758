import { Injectable } from '@angular/core';

@Injectable()
export class SearchUrlService {
  url: any;

  constructor() {}

  get getSearchUrl() {
    return this.getCache() || this.url;
  }

  setSearchUrl(url: any) {
    this.url = url;
    this.cache();
  }

  getCache() {
    try {
      if (localStorage) {
        const position = localStorage.getItem('search-filters');
        return JSON.parse(position);
      }
    } catch (e) {
      console.error(e);
    }
  }

  delCache() {
    try {
      if (localStorage) localStorage.removeItem('search-filters');
    } catch (e) {
      console.error(e);
    }
  }

  cache() {
    localStorage.setItem('search-filters', JSON.stringify(this.url));
  }
}
