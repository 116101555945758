import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonGenericService } from '@app/core/common.service';
import * as MyListingModel from '@app/models/my-listing.model';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'my-listing-filter',
  templateUrl: './my-listing-filter.component.html',
  styleUrls: ['./my-listing-filter.component.scss']
})
export class MyListingFilterComponent {
  @Input('screenWidth') screenWidth: number;
  @Input('agents') agents: any;
  @Input('listingCompanies') listingCompanies: Array<MyListingModel.MyListingCompany>;
  @Input('isSpotAdmin') isSpotAdmin: boolean;
  @Input('isListingAdmin') isListingAdmin: boolean;
  @Input('selectCompany') selectCompany: MyListingModel.MyListingCompany;
  @Input('selectAgent') selectAgent: MyListingModel.MyListingAgent;
  @Input('listingSearchDto') listingSearchDto: any;
  @Output() updateFilter: EventEmitter<any> = new EventEmitter();

  constructor(private commonSvc: CommonGenericService, private popoverCtrl: PopoverController) {}

  public setButtonColor(width: number): string {
    return this.screenWidth >= width ? 'light' : 'secondary';
  }

  public overrideColor(width: number): string {
    return this.screenWidth >= width ? 'filter-btn-color' : 'filter-btn-white';
  }

  private async onModalDismiss(modal: any) {
    modal.onWillDismiss().then((result: MyListingModel.MyListingFilter) => {
      this.updateFilter.emit(result.data);
    });
  }
}
