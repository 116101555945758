/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./duplo-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./duplo-map.component";
import * as i3 from "../../helpers/map-control.service";
import * as i4 from "../../../../core/geolocation.service";
import * as i5 from "@angular/cdk/layout";
import * as i6 from "../../../../core/common.service";
import * as i7 from "../../../../core/generic-map.service";
var styles_DuploMapComponent = [i0.styles];
var RenderType_DuploMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DuploMapComponent, data: {} });
export { RenderType_DuploMapComponent as RenderType_DuploMapComponent };
export function View_DuploMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mapElement: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["duploMap", 1]], null, 0, "div", [["class", "duplo-map"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_DuploMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-duplomap", [], null, null, null, View_DuploMapComponent_0, RenderType_DuploMapComponent)), i1.ɵdid(1, 4833280, null, 0, i2.DuploMapComponent, [i3.MapControlHelperService, i4.GeoLocationService, i5.BreakpointObserver, i6.CommonGenericService, i7.GenericMapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DuploMapComponentNgFactory = i1.ɵccf("app-duplomap", i2.DuploMapComponent, View_DuploMapComponent_Host_0, { markers: "markers", options: "options", clientPosition: "clientPosition", mapTypeToggle: "mapTypeToggle", showInCard: "showInCard", building: "building", isSpotNearMe: "isSpotNearMe" }, { geopositionEmitter: "geopositionEmitter", callbackEmitter: "callbackEmitter" }, []);
export { DuploMapComponentNgFactory as DuploMapComponentNgFactory };
