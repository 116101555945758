import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {
  constructor(
    public messageDialog: MatDialogRef<MessageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {}

  closeModal() {
    this.messageDialog.close();
  }
}
