<!-- Office Filters -->
<ion-row class="padding-10" *ngIf="!propertyType || propertyType == 2001">
  <ion-col size="12">
    <ion-label class="weight">{{ 'global.list-your-property.office' | translate }}</ion-label>
  </ion-col>
</ion-row>
<ion-row *ngIf="!propertyType || propertyType == 2001" style="display: inline-block;padding: 20px;">
  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.plugAndPlay }"
    fill="outline"
    (click)="searchDto.plugAndPlay = !searchDto.plugAndPlay"
  >
    <span [ngClass]="{ white: searchDto.plugAndPlay }" translate="global.addtSearchFilters.plugAndPlay"></span>
  </ion-button>

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.finishedOut }"
    fill="outline"
    (click)="searchDto.finishedOut = !searchDto.finishedOut"
  >
    <span [ngClass]="{ white: searchDto.finishedOut }" translate="global.addtSearchFilters.finishedOut"></span>
  </ion-button-->

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.furnishedConditionCoworking }"
    fill="outline"
    (click)="searchDto.furnishedConditionCoworking = !searchDto.furnishedConditionCoworking"
  >
    <span
      [ngClass]="{ white: searchDto.furnishedConditionCoworking }"
      translate="global.addtSearchFilters.furnished"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.coreAndShel }"
    fill="outline"
    (click)="searchDto.coreAndShel = !searchDto.coreAndShel"
  >
    <span [ngClass]="{ white: searchDto.coreAndShel }" translate="global.addtSearchFilters.coreAndShel"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.leedStatus }"
    fill="outline"
    (click)="searchDto.leedStatus = !searchDto.leedStatus"
  >
    <span [ngClass]="{ white: searchDto.leedStatus }" translate="global.addtSearchFilters.leedStatus"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.centralAirConditioning }"
    fill="outline"
    (click)="searchDto.centralAirConditioning = !searchDto.centralAirConditioning"
  >
    <span
      [ngClass]="{ white: searchDto.centralAirConditioning }"
      translate="global.addtSearchFilters.centralAirConditioning"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.miniSplitAirConditioning }"
    fill="outline"
    (click)="searchDto.miniSplitAirConditioning = !searchDto.miniSplitAirConditioning"
  >
    <span
      [ngClass]="{ white: searchDto.miniSplitAirConditioning }"
      translate="global.addtSearchFilters.miniSplitAirConditioning"
    ></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.bicycleRack }"
    fill="outline"
    (click)="searchDto.bicycleRack = !searchDto.bicycleRack"
  >
    <span [ngClass]="{ white: searchDto.bicycleRack }" translate="global.addtSearchFilters.bicycleRack"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.fiberOptic }"
    fill="outline"
    (click)="searchDto.fiberOptic = !searchDto.fiberOptic"
  >
    <span [ngClass]="{ white: searchDto.fiberOptic }" translate="global.addtSearchFilters.fiberOptic"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.raisedFloor }"
    fill="outline"
    (click)="searchDto.raisedFloor = !searchDto.raisedFloor"
  >
    <span [ngClass]="{ white: searchDto.raisedFloor }" translate="global.addtSearchFilters.raisedFloor"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.neverUsed }"
    fill="outline"
    (click)="searchDto.neverUsed = !searchDto.neverUsed"
  >
    <span [ngClass]="{ white: searchDto.neverUsed }" translate="global.addtSearchFilters.neverUsed"></span>
  </ion-button>

  <ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasRestrooms }"
    fill="outline"
    (click)="searchDto.hasRestrooms = !searchDto.hasRestrooms"
  >
    <span [ngClass]="{ white: searchDto.hasRestrooms }" translate="global.addtSearchFilters.restroom"></span>
  </ion-button>

  <!--ion-button
    color="secondary"
    [ngClass]="{ selectedBtn: searchDto.hasParkingSpaces }"
    fill="outline"
    (click)="searchDto.hasParkingSpaces = !searchDto.hasParkingSpaces"
  >
    <span
      [ngClass]="{ white: searchDto.hasParkingSpaces }"
      translate="global.addtSearchFilters.hasParkingSpaces"
    ></span>
  </ion-button-->
</ion-row>
<ion-row *ngIf="propertyType == 2001" style="margin-bottom: 70px;"> </ion-row>
