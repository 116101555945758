import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { DomSanitizer } from '@angular/platform-browser';

export interface ImageResponse {
  imgBase64: any;
  file: any;
}

export interface EditImageResponse {
  croppedImage: ImageResponse;
  originalImage: ImageResponse;
  wasImageCropped: boolean;
  wasOriginalImgReplaced: boolean;
  originalImageId: number;
  wasRestoreToOriginalImage: boolean;
}

@Component({
  selector: 'app-edit-crop-image-save-modal',
  templateUrl: './edit-crop-image-save-modal.component.html',
  styleUrls: ['./edit-crop-image-save-modal.component.scss']
})
export class EditCropImageSaveModalComponent implements OnInit {
  @Input() imgList: any = null;
  @Input() imgCount: number;

  originalImageId: any;
  imageUrlToEdit: string;
  newImageBased64: any;
  showCroppingToggle: boolean = false;
  fileName: string = null;

  private UPLOAD_FILE_SIZE_LIMIT: number = 20000000;
  imageCropperOptions: any = {};
  imageChangedEvent: any = {};
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};
  /*  Image based 64 */
  principalImageToEdit: any = null;
  croppedImage: any = null;
  cropOn: boolean = true;
  removedImage: boolean = false;
  replacedImage: boolean = false;
  restoreToOriginalImage: boolean = false;

  croppedImageWidth: string = '200px';
  croppedImageHeight: string = '150px';

  originalImageUrl: string = null;
  validImage: boolean = true;
  img = 0;

  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  constructor(
    private modalCtrl: ModalController,
    private commonService: CommonGenericService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.initImage();
    this.imageCropperOptions = this.getImageCropperOptions();
  }

  initImage() {
    if (this.imgList.length != this.imgCount && this.img == 0) {
      this.img = this.imgList.length - this.imgCount;
    }
    this.imageUrlToEdit = `/images/${this.imgList[this.img].image.id}/thumbnail/ml`;
    this.getPrincipalImage();
  }

  public getImageCropperOptions(): any {
    return {
      maintainAspectRatio: 'true',
      cropperMinWidth: 128,
      cropperMinHeight: 128,
      resizeToWidth: 200,
      format: 'png',
      roundCropper: false,
      onlyScaleDown: true,
      aspectRatio: 4 / 3
    };
  }

  private getPrincipalImage() {
    this.convertToDataURL(this.imageUrlToEdit, (dataURL: any) => {
      this.principalImageToEdit = dataURL;
    });
  }

  private convertToDataURL(imageUrl: string, callback: any) {
    let xhr: XMLHttpRequest = new XMLHttpRequest();
    this.fileName = this.commonService.getFileNameFromPath(imageUrl);

    xhr.onload = () => {
      let fileReader = new FileReader();
      fileReader.onloadend = () => {
        callback(fileReader.result);
      };
      fileReader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', imageUrl);
    xhr.responseType = 'blob';
    xhr.send();
  }

  private closeModal() {
    this.modalCtrl.dismiss();
  }

  public imageCropped(image: ImageCroppedEvent) {
    if (image && image.cropperPosition) {
      this.croppedImageWidth = image.cropperPosition.x2 - image.cropperPosition.x1 + 'px';
      this.croppedImageHeight = image.cropperPosition.y2 - image.cropperPosition.y1 + 'px';
      this.croppedImage = image.base64;
    }
  }

  private convertImageToFile(imgBase64: any) {
    return this.commonService.convertImageBase64ToFileSync(imgBase64, this.fileName);
  }

  public saveImage() {
    const originalImageFile = this.convertImageToFile(this.principalImageToEdit);
    const croppedImageFile = this.croppedImage ? this.convertImageToFile(this.croppedImage) : null;

    this.imgList[this.img].img = this.principalImageToEdit;
    this.imgList[this.img].content =
      originalImageFile.name && originalImageFile.name != null ? originalImageFile.name : 'New Image';
    this.imgList[this.img].croppedImage = this.croppedImage;
    if (this.img == this.imgList.length - 1) {
      this.closeModal();
    } else {
      this.img++;
      this.initImage();
    }
  }

  public rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  public rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  public flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  public flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  public showCroppedImage() {
    if (this.cropOn && !this.removedImage) {
      return true;
    }
    return false;
  }

  public showNotCroppedImage() {
    const showCroppedImage = this.showCroppedImage();
    if (!showCroppedImage) {
      if (!this.imageUrlToEdit || this.imageUrlToEdit == null) {
        if (!this.principalImageToEdit || this.principalImageToEdit == null) {
          return false;
        }
      }
      if (!this.validImage) {
        return false;
      }

      return !this.removedImage;
    }
    return !showCroppedImage;
  }

  public getNotCroppedImage() {
    if (this.imageUrlToEdit && !this.cropOn && !this.replacedImage) {
      return this.imageUrlToEdit;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.principalImageToEdit);
  }
}
