import * as tslib_1 from "tslib";
export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Off"] = 0] = "Off";
    LogLevel[LogLevel["Error"] = 1] = "Error";
    LogLevel[LogLevel["Warning"] = 2] = "Warning";
    LogLevel[LogLevel["Info"] = 3] = "Info";
    LogLevel[LogLevel["Debug"] = 4] = "Debug";
})(LogLevel || (LogLevel = {}));
var Logger = /** @class */ (function () {
    function Logger(source) {
        this.source = source;
    }
    Logger.enableProductionMode = function () {
        Logger.level = LogLevel.Warning;
    };
    // Inject NGXLogger at the static level
    Logger.setLogger = function (logger) {
        Logger.ngxLogger = logger;
    };
    Logger.prototype.debug = function () {
        var objects = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            objects[_i] = arguments[_i];
        }
        this.log(Logger.ngxLogger.debug.bind(Logger.ngxLogger), LogLevel.Debug, objects);
    };
    Logger.prototype.info = function () {
        var objects = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            objects[_i] = arguments[_i];
        }
        this.log(Logger.ngxLogger.info.bind(Logger.ngxLogger), LogLevel.Info, objects);
    };
    Logger.prototype.warn = function () {
        var objects = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            objects[_i] = arguments[_i];
        }
        this.log(Logger.ngxLogger.warn.bind(Logger.ngxLogger), LogLevel.Warning, objects);
    };
    Logger.prototype.error = function () {
        var objects = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            objects[_i] = arguments[_i];
        }
        this.log(Logger.ngxLogger.error.bind(Logger.ngxLogger), LogLevel.Error, objects);
    };
    Logger.prototype.log = function (func, level, objects) {
        var _this = this;
        if (level <= Logger.level) {
            var log = this.source ? ['[' + this.source + ']'].concat(objects) : objects;
            func.apply(Logger.ngxLogger, log);
            Logger.outputs.forEach(function (output) { return output.apply(output, tslib_1.__spread([_this.source, level], objects)); });
        }
    };
    Logger.level = LogLevel.Debug;
    Logger.outputs = [];
    return Logger;
}());
export { Logger };
