import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { I18nService } from '@app/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatDialog, MatDialogRef } from '@angular/material';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';
import { ImageService } from '@app/core/image.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingController } from '@ionic/angular';
import { CommonGenericService } from '@app/core/common.service';
import { AccountService, AuthenticationService } from '@app/core/auth';
import { CompleteProfileComponent } from './complete-profile/complete-profile-modal.component';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { AnalyticsService } from '@app/google-analytics/analytics-service';
import { GoogleAnalyticsType } from '@app/google-analytics/google-analytics-type';
import { EditImageModalComponent } from '@app/user-home/edit-image/edit-image-modal.component';
var RegisterFormComponent = /** @class */ (function () {
    function RegisterFormComponent(fb, registerService, router, commonService, deviceDetectorService, loadingController, i18nService, commonSvc, auth, dialog, analyticsService, userHomeService, userAccountService, imageService, registerDialog) {
        this.fb = fb;
        this.registerService = registerService;
        this.router = router;
        this.commonService = commonService;
        this.deviceDetectorService = deviceDetectorService;
        this.loadingController = loadingController;
        this.i18nService = i18nService;
        this.commonSvc = commonSvc;
        this.auth = auth;
        this.dialog = dialog;
        this.analyticsService = analyticsService;
        this.userAccountService = userAccountService;
        this.imageService = imageService;
        this.registerDialog = registerDialog;
        this.passwordLength = false;
        this.passwordMatch = false;
        this.isCompleted = false;
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.lastStep = false;
        this.selectedCompany = [];
        this.allCompanies = [];
        this.stepIndex = 0;
        this.isLoaded = false;
        this.ios = false;
        this.android = false;
        this.intlTelephoneOptions = this.commonService.getDefaultIntlPhoneProp();
        this.logoId = null;
        this.hide = true;
        this.hide1 = true;
        this.uniqueEmailError = false;
        this.inactiveAccountExists = false;
        this.phoneError = false;
        this.companySelectedError = false;
        this.userHomeService = userHomeService;
    }
    RegisterFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isDesktop = this.deviceDetectorService.isDesktop() ? 'desktop' : '';
        this.isMobilePhone = this.deviceDetectorService.isMobile() ? 'mobile' : '';
        this.isTablet = this.deviceDetectorService.isTablet() ? 'tablet' : '';
        if (this.isDesktop.length) {
            this.currentDevice = this.isDesktop;
        }
        else if (this.isMobilePhone.length) {
            this.currentDevice = this.isMobilePhone;
        }
        else if (this.isTablet.length) {
            this.currentDevice = this.isTablet;
        }
        this.imageService.getLogoId().subscribe(function (val) {
            _this.logoId = val;
        });
        this.createForm();
        this.detectDevice();
        this.getCompanies().then(function (result) {
            _this.allCompanies = result;
            var notListedText = _this.i18nService.get('global.register.notListed');
            _this.allCompanies.unshift({ name: [notListedText], id: 0, value: null });
            /* this.isLoaded = true; */
        });
    };
    RegisterFormComponent.prototype.portChange = function (event) {
        this.selectedCompany = event /* .value */;
        /*  if (event.value.companyPhotoImageId) {
           this.image = this.imageService.mediumThumbnail(event.value.companyPhotoImageId);
           this.imageId = event.value.companyPhotoImageId;
           this.logoId = null;
         } else {
           this.image = null;
           this.imageId = null;
         } */
    };
    RegisterFormComponent.prototype.getCompanies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingCompanies().toPromise()];
            });
        });
    };
    RegisterFormComponent.prototype.detectDevice = function () {
        if (this.deviceDetectorService.os.toLowerCase() === 'ios') {
            this.ios = true;
        }
        else if (this.deviceDetectorService.os.toLowerCase() === 'android') {
            this.android = true;
        }
    };
    RegisterFormComponent.prototype.presentToast = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.registerService.presentToast();
                return [2 /*return*/];
            });
        });
    };
    RegisterFormComponent.prototype.showWelcomeAlert = function (onDismiss) {
        if (onDismiss === void 0) { onDismiss = function () { }; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var title, msg;
            return tslib_1.__generator(this, function (_a) {
                title = this.i18nService.get('global.form.registrationComplete');
                msg = this.i18nService.get('global.form.welcomeNewUser');
                this.registerService.showAlert(title, msg, onDismiss.bind(this));
                return [2 /*return*/];
            });
        });
    };
    RegisterFormComponent.prototype.checkEmail = function () {
        var _this = this;
        if (this.registerForm.controls.emailGroup.value.email != '') {
            this.resetErrors();
            this.registerService
                .getEmailUnique(this.registerForm.controls.emailGroup.value.email)
                .toPromise()
                .then(function (resp) {
                var emailAccount = resp;
                /*  if (emailAccount && emailAccount.accountExists && emailAccount.inactiveAccountExists) {
                   this.inactiveAccountExists = true;
                   return false;
                 } else if (emailAccount && emailAccount.accountExists) {
                   this.uniqueEmailError = true;
                   return false;
                 } */
                if (emailAccount) {
                    _this.uniqueEmailError = true;
                    return false;
                }
                return true;
            });
        }
        /*   if (emailAccount && emailAccount.accountExists && emailAccount.inactiveAccountExists) {
            this.uniqueEmailError = true;
            this.isCompleted = false;
            this.emailName = this.registerForm.controls.emailGroup.value.email;
            this.presentToast(); //TODO:: change this message to the one listed in ticket and should be popup not a toast
          } else if (emailAccount && emailAccount.accountExists) {
            this.uniqueEmailError = true;
            this.isCompleted = false;
            this.emailName = this.registerForm.controls.emailGroup.value.email;
            this.presentToast();
          } else {
            this.isCompleted = true;
            this.uniqueEmailError = false;
            setTimeout(() => {
              this.goForward();
            }, 0);
          } */
    };
    RegisterFormComponent.prototype.resetErrors = function () {
        this.uniqueEmailError = false;
        this.inactiveAccountExists = false;
        this.phoneError = false;
        this.companySelectedError = false;
    };
    RegisterFormComponent.prototype.openCompleteProfileModal = function () {
        if (this.auth.$isLoginSubject.value) {
            this.dialog.open(CompleteProfileComponent, {
                closeOnNavigation: true,
                disableClose: false,
                width: '300px'
            });
        }
    };
    RegisterFormComponent.prototype.removeLogo = function () {
        this.imageService.setLogoId(null);
    };
    RegisterFormComponent.prototype.submitBasicAccount = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var phoneNumber, mergedForm, userObj;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.validateFieldsRequired()) {
                            return [2 /*return*/];
                        }
                        this.isLoaded = true;
                        phoneNumber = this.sanitizePhoneNumbers();
                        mergedForm = tslib_1.__assign({}, this.registerForm.value.emailGroup);
                        mergedForm.phoneNumber = tslib_1.__assign({}, phoneNumber);
                        userObj = this.registerService.formatUserDetails(mergedForm);
                        /*  if (this.logoId || this.imageId) {
                           userObj.companyLogoImageId = this.image ? this.imageId : this.logoId;
                         } */
                        /*   mergedForm.companyId = this.registerForm.controls.emailGroup.value.dropdown.id
                            ? this.registerForm.controls.emailGroup.value.dropdown.id
                            : null;
                          mergedForm.companyName = this.registerForm.controls.emailGroup.value.dropdown.name
                            ? this.registerForm.controls.emailGroup.value.dropdown.name
                            : null;
                    
                          delete mergedForm['confirmPass'];
                          delete mergedForm['dropdown']; */
                        return [4 /*yield*/, this.registerService
                                .register(userObj)
                                .toPromise()
                                .then(function () {
                                _this.analyticsService.sentEvent(GoogleAnalyticsType.CLICK_ON_GET_STARTED, _this.currentDevice);
                                _this.isCompleted = true;
                                _this.isLoaded = false;
                                /* this.loadingController.dismiss();
                                window.history.go(-1); */
                                /* this.showWelcomeAlert(this.openCompleteProfileModal); */
                                /*this.auth.submitLoginCredentials(userObj.email, userObj.password).then(() => {
                                  this.router.navigate([`/`]);
                                  this.showWelcomeAlert(this.openCompleteProfileModal);
                                });*/
                            })
                                .catch(function () {
                                /* this.loadingController.dismiss(); */
                                _this.dismissAsDialog(true);
                                _this.isLoaded = false;
                                var title = _this.i18nService.get('global.register.registerErrorTitle');
                                var msg = _this.i18nService.get('global.register.registerErrorMessage');
                                _this.registerService.showAlert(title, msg);
                            })];
                    case 1:
                        /*  if (this.logoId || this.imageId) {
                           userObj.companyLogoImageId = this.image ? this.imageId : this.logoId;
                         } */
                        /*   mergedForm.companyId = this.registerForm.controls.emailGroup.value.dropdown.id
                            ? this.registerForm.controls.emailGroup.value.dropdown.id
                            : null;
                          mergedForm.companyName = this.registerForm.controls.emailGroup.value.dropdown.name
                            ? this.registerForm.controls.emailGroup.value.dropdown.name
                            : null;
                    
                          delete mergedForm['confirmPass'];
                          delete mergedForm['dropdown']; */
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterFormComponent.prototype.validateFieldsRequired = function () {
        this.resetErrors();
        /*  if (!this.validatePrimaryPhoneNumber(true)) {
           this.phoneError = true;
           return false;
         }
      */
        if (this.selectedCompany.id === 0 && this.registerForm.controls.emailGroup.value.companySelected.toString() == '') {
            this.companySelectedError = true;
            return false;
        }
        return true;
    };
    /*  goForward() {
       this.stepper.next();
     } */
    /*  goBack() {
       this.lastStep = false;
       this.stepper.previous();
     } */
    /*  backButton() {
       this.uniqueEmailError = false;
       this.isCompleted = false;
       this.stepper.previous();
     } */
    /*  checkFormErrors(formGroup: any) {
       let password = formGroup.controls.emailGroup.controls['password'].value;
       let confirmPass = formGroup.controls.emailGroup.controls['confirmPass'].value;
       if (password.length < 6) {
         return password.length < 6 ? { passwordLength: true } : null;
       } else if (password !== confirmPass) {
         return password !== confirmPass ? { passwordMatch: true } : null;
       }
     } */
    RegisterFormComponent.prototype.trimValue = function (formControl) {
        this.registerForm.get('emailGroup.email').setValue(formControl.trim());
    };
    RegisterFormComponent.prototype.presentLoadingWithOptions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingController.create({
                            spinner: 'lines',
                            translucent: true,
                            showBackdrop: false,
                            cssClass: 'spin-loading'
                        })];
                    case 1:
                        loading = _a.sent();
                        return [4 /*yield*/, loading.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RegisterFormComponent.prototype.createForm = function () {
        this.registerForm = this.fb.group({
            emailGroup: this.fb.group({
                email: ['', Validators.compose([Validators.required, Validators.email])],
                password: ['', Validators.compose([Validators.required])],
                confirmPass: ['', Validators.compose([Validators.required])],
                firstName: ['', Validators.compose([Validators.required])],
                lastName: ['', Validators.compose([Validators.required])],
                dropdown: [null, Validators.compose([Validators.required])],
                primaryPhone: ['', Validators.compose([Validators.required])],
                companySelected: ['', Validators.compose([])]
            })
        });
        /*  this.registerForm = this.fb.group(
           {
             detailGroup: this.fb.group({
               email: ['', Validators.compose([Validators.required, Validators.email])],
               password: ['', Validators.compose([Validators.required])],
               confirmPass: ['', Validators.compose([Validators.required])],
               firstName: ['', Validators.compose([Validators.required])],
               lastName: ['', Validators.compose([Validators.required])] */
        /*  companySelected: ['', Validators.compose([])],
         dropdown: ['', Validators.compose([Validators.required])],
         primaryPhone: ['', Validators.compose([Validators.required])] */
        /*    })
         },
         { validator: this.checkFormErrors }
       ); */
    };
    RegisterFormComponent.prototype.validatePrimaryPhoneNumber = function (showAlerts) {
        var primaryPhone = this.registerForm.value.emailGroup.primaryPhone;
        if (primaryPhone == null) {
            /* if (showAlerts) {
              let bodyMessage: string = 'global.user.profileForm.invalidNumber';
              this.openInfoModal('', bodyMessage);
            } */
            return false;
        }
        if (primaryPhone.errors || primaryPhone.internationalNumber.length === 0) {
            /*  const headerLabel: string = '';
             let bodyMessage: string = 'global.user.profileForm.invalidNumber'; */
            /*  if (
               (primaryPhone.errors && primaryPhone.errors.invalidNumberForRegion) ||
               primaryPhone.internationalNumber.length === 0
             ) {
               bodyMessage = 'global.user.profileForm.invalidNumberForRegion';
             }
             if (showAlerts) {
               this.openInfoModal(headerLabel, bodyMessage);
             } */
            return false;
        }
        return true;
    };
    RegisterFormComponent.prototype.openInfoModal = function (headerTitle, bodyMessage, data, extraData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modalProps, infoModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        modalProps = {
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: 'generic-info-modal',
                            component: InfoModalComponent,
                            componentProps: {
                                headerTitle: headerTitle,
                                bodyMessage: bodyMessage,
                                data: data,
                                extraData: extraData
                            }
                        };
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        infoModal = _a.sent();
                        infoModal.onWillDismiss().then(function (result) { });
                        return [2 /*return*/, infoModal.present()];
                }
            });
        });
    };
    RegisterFormComponent.prototype.sanitizePhoneNumbers = function () {
        var formPrimaryPhoneNumber = this.registerForm.value.emailGroup.primaryPhone;
        var userPhones = {};
        if (formPrimaryPhoneNumber) {
            var obj = {
                phoneType: 'PHONE_NUMBER',
                phoneNumber: formPrimaryPhoneNumber.internationalNumber && formPrimaryPhoneNumber.length > 0
                    ? formPrimaryPhoneNumber.internationalNumber
                    : formPrimaryPhoneNumber.number,
                isoCode: formPrimaryPhoneNumber.countryCode,
                nationalNumber: '',
                countryCode: ''
            };
            obj.nationalNumber = obj.phoneNumber;
            obj.countryCode = obj.isoCode;
            userPhones = tslib_1.__assign({}, obj);
        }
        return userPhones;
    };
    RegisterFormComponent.prototype.getMediumImg = function () {
        this.image = null;
        this.imageId = null;
        return this.imageService.mediumThumbnail(this.logoId);
    };
    RegisterFormComponent.prototype.openEditLogoModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var imageLogos, userlogo, imageLogo, componentProps, cssClass, modalProps, editImageModal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userlogo = [];
                        userlogo.push(this.logoId);
                        imageLogo = this.userAccountService.getUploadedImageId(userlogo);
                        imageLogos = this.userHomeService.setProfileImageProp(imageLogo);
                        componentProps = {
                            commonService: this.commonService,
                            openInfoModal: this.openInfoModal,
                            userHomeService: this.userHomeService,
                            profileImage: imageLogos,
                            editLogo: true
                        };
                        cssClass = 'edit-image-modal';
                        modalProps = this.userHomeService.getModalProp(EditImageModalComponent, componentProps, cssClass);
                        return [4 /*yield*/, this.commonService.createModal(modalProps)];
                    case 1:
                        editImageModal = _a.sent();
                        return [4 /*yield*/, editImageModal.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RegisterFormComponent.prototype.dismissAsDialog = function (error) {
        this.registerDialog.close(error);
    };
    return RegisterFormComponent;
}());
export { RegisterFormComponent };
