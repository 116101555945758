import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ImageService {
  publicImageURL: string = '/images';
  dimensionMap: {
    large: '1920x1080';
    medium: '1360x728';
    small: '500x500';
  };

  logoId: any;
  private subject = new Subject<any>();

  constructor() {}

  getFullImagePath(id: any) {
    return `${this.publicImageURL}/${id}/content`;
  }

  largeThumbnail(id: any, dimensions?: any) {
    //if dimensions resize
    return `${this.publicImageURL}/${id}/thumbnail/l`;
  }

  mediumThumbnail(id: any, dimensions?: any) {
    //if dimensions resize
    return `${this.publicImageURL}/${id}/thumbnail/ml`;
  }

  smallThumbnail(id: any, dimensions?: any) {
    //if dimensions resize
    return `${this.publicImageURL}/${id}/thumbnail/s`;
  }

  formImage(file: any, fileName: any, email: any, cropped: boolean) {
    let img = {
      image: file,
      fileName: fileName,
      caption: cropped ? email.value + '_profile_photo_resize' : email.value + '_profile_photo'
    };
    return img;
  }

  setLogoId(logoId: any) {
    this.subject.next(logoId);
  }

  getLogoId(): Observable<any> {
    //return this.logoId;
    return this.subject.asObservable();
  }
}
