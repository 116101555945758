<header class="col-md-12 title">
  <div style="padding-top: 20px;padding-left: 10px;">
    <span style="font-size: 18px;font-weight: 800;">{{ 'buildinsDetail.spotSet' | translate }}</span>
    <img *ngIf="isMobile" class="close-icon" (click)="close()" src="assets/images/icons/closeWhite.png" alt="" />
    <img *ngIf="!isMobile" class="close-icon" (click)="close()" src="assets/images/icons/close.png" alt="" />
  </div>
</header>
<mat-tab-group *ngIf="!isEdit" mat-align-tabs="start" (selectedTabChange)="changeStep($event)">
  <mat-tab [label]="'buildinsDetail.spacesSetSpot' | translate" (click)="changeStep(1)" style="color: #6C757D ;">
    <div class="row" style="padding: 24px;">
      <div class="col-md-12">
        <h6 style="margin-top: 20px;">
          <b>{{ 'buildinsDetail.NewSPOTset' | translate }}</b>
        </h6>
      </div>
      <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
        <input
          #spaceInput
          id="spaceInput"
          name="spaceInput"
          type="text"
          [(ngModel)]="spotSet.name"
          class="form-control"
          [placeholder]="'buildinsDetail.title' | translate"
        />
      </div>
    </div>
  </mat-tab>
  <mat-tab [label]="'buildinsDetail.spacesUpdate' | translate" (click)="changeStep(2)">
    <div class="row" style="padding: 24px;">
      <div class="col-md-12" style="margin-top: 14px;">
        <span
          ><b>{{ 'buildinsDetail.selectSpace' | translate }}</b></span
        >
      </div>
      <div class="col-md-12" style="padding: 18px;">
        <select name="" id="" [(ngModel)]="setSpotSpace" class="form-control">
          <option [value]="undefined" disabled>{{ 'global.my-list-menu.select' | translate }}</option>
          <option *ngFor="let spotSet of listSpotSet" [ngValue]="spotSet">{{ spotSet.name }}</option>
        </select>
      </div>
      <div class="row" style="margin-left: 20px;">
        <div class="col-md-12 custom-control custom-radio">
          <input
            type="radio"
            id="customRadio1"
            name="customRadio"
            class="custom-control-input"
            (click)="onChangeOpt(1)"
          />
          <label class="custom-control-label" for="customRadio1"
            ><b>{{ 'buildinsDetail.addCurrenSpace' | translate }}</b></label
          >
        </div>
        <div class="col-md-12 custom-control custom-radio" style="margin-top: 12px;">
          <input
            type="radio"
            id="customRadio2"
            name="customRadio"
            class="custom-control-input"
            (click)="onChangeOpt(2)"
          />
          <label class="custom-control-label" for="customRadio2"
            ><b>{{ 'buildinsDetail.replaceSpace' | translate }}</b></label
          >
        </div>
        <div class="col-md-12 custom-control custom-radio" style="margin-top: 12px;">
          <input
            type="radio"
            id="customRadio3"
            name="customRadio"
            class="custom-control-input"
            (click)="onChangeOpt(3)"
          />
          <label class="custom-control-label" for="customRadio3"
            ><b>{{ 'buildinsDetail.removeSelection' | translate }}</b></label
          >
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<div *ngIf="isEdit">
  <div style="padding: 24px;">
    <div class="col-md-12">
      <h6 style="margin-top: 20px;">
        <b>{{ 'favorites.spotSets.spotSet' | translate }}</b>
      </h6>
    </div>
    <div class="col-md-12" style="padding: 18px;margin-top: -18px;">
      <input
        #spaceInput
        id="spaceInput"
        name="spaceInput"
        type="text"
        [(ngModel)]="spotSetTitle"
        class="form-control"
        [placeholder]="'buildinsDetail.title' | translate"
      />
    </div>
  </div>
</div>
<div *ngIf="!isEdit" class="col-md-12 mobile-btn" style="background: #F8F9FA;">
  <button *ngIf="isMobile" (click)="close()" style="margin-top: 12px;" class="btn-color-1 btn-border btn-mobile">
    {{ 'Cancel' | translate }}
  </button>
  <ion-button
    *ngIf="step == 0 && !isMobile"
    [disabled]="!spotSet.name"
    style="margin-top: 12px;"
    class="btn-color-2 btn-mobile"
    (click)="createSpotSet()"
  >
    {{ 'create' | translate }}
  </ion-button>
  <ion-button
    *ngIf="step == 0 && isMobile"
    [ngClass]="{
      'btn-color-mobile': !spotSet.name
    }"
    [disabled]="!spotSet.name"
    style="margin-top: 12px;"
    class="btn-color-2 btn-mobile"
    (click)="createSpotSet()"
  >
    {{ 'buildinsDetail.btnCreate' | translate }}
  </ion-button>
  <ion-button
    *ngIf="step == 1 && !isMobile"
    style="margin-top: 12px;"
    [disabled]="setSpotSpace == undefined || (addSetSpot == false && replaceSetSpot == false && removeSetSpot == false)"
    class="btn-color-2 btn-mobile"
    (click)="updateSetSpot()"
  >
    {{ 'Update' | translate }}
  </ion-button>
  <ion-button
    *ngIf="step == 1 && isMobile"
    style="margin-top: 12px;"
    [ngClass]="{
      'btn-color-mobile':
        setSpotSpace == undefined || (addSetSpot == false && replaceSetSpot == false && removeSetSpot == false)
    }"
    [disabled]="setSpotSpace == undefined || (addSetSpot == false && replaceSetSpot == false && removeSetSpot == false)"
    class="btn-color-2 btn-mobile"
    (click)="updateSetSpot()"
  >
    {{ 'buildinsDetail.btnUpdate' | translate }}
  </ion-button>
  <button *ngIf="!isMobile" (click)="close()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
    {{ 'Cancel' | translate }}
  </button>
</div>
<div *ngIf="isEdit" class="col-md-12 mobile-btn" style="background: #F8F9FA;">
  <button *ngIf="isMobile" (click)="close()" style="margin-top: 12px;" class="btn-color-1 btn-border btn-mobile">
    {{ 'Cancel' | translate }}
  </button>

  <ion-button
    *ngIf="!isMobile"
    style="margin-top: 12px;"
    [disabled]="spotSetTitle == ''"
    class="btn-color-2 btn-mobile"
    (click)="ubdateSpotSetTitle()"
  >
    {{ 'Update' | translate }}
  </ion-button>
  <ion-button
    *ngIf="isMobile"
    style="margin-top: 12px;"
    [ngClass]="{
      'btn-color-mobile': spotSetTitle == ''
    }"
    [disabled]="spotSetTitle == ''"
    class="btn-color-2 btn-mobile"
    (click)="ubdateSpotSetTitle()"
  >
    {{ 'buildinsDetail.btnUpdate' | translate }}
  </ion-button>
  <button *ngIf="!isMobile" (click)="close()" style="margin-top: 12px;" class="btn-cancel-set btn-mobile">
    {{ 'Cancel' | translate }}
  </button>
</div>
