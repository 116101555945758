import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { I18nService } from '@app/core';
import { BaseService } from './base-service';
import { map } from 'rxjs/operators';
var TurnOffNotificationService = /** @class */ (function (_super) {
    tslib_1.__extends(TurnOffNotificationService, _super);
    function TurnOffNotificationService(http, i18n) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.i18n = i18n;
        _this.turnOffAPI = "/api/user/turnoff/notifications";
        return _this;
    }
    TurnOffNotificationService.prototype.turnOffNotification = function (login, notificationType, hash) {
        var requestDto = { login: login, notificationType: notificationType, hash: hash };
        return this.http
            .post(this.turnOffAPI, requestDto, { observe: 'response' })
            .pipe(map(function (val) { return val.body; }))
            .toPromise();
    };
    return TurnOffNotificationService;
}(BaseService));
export { TurnOffNotificationService };
