import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { I18nService, PlatformHelperService } from '@app/core';
import { ImageService } from '@app/core/image.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
  @Input('article') article: any;
  isMobile = false;
  defaultImage: any;

  constructor(
    private imageService: ImageService,
    private datePipe: DatePipe,
    private i18: I18nService,
    private breakpointObserver: BreakpointObserver,
    private platformConfigHelper: PlatformHelperService
  ) {
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
  }

  ngOnInit() {}

  getMediumImg(id: number) {
    return this.imageService.mediumThumbnail(id);
  }

  getSmallImg(id: number) {
    return this.imageService.smallThumbnail(id);
  }

  headerImageNews(item: string): string {
    const url = item.replace('***', '640w');
    return `${environment.cloudfrontURL}${url}`;
  }

  formatDate(date: any, format: any) {
    return this.datePipe.transform(date, format);
  }

  translateText(text: string) {
    return this.i18.getTranslation(text);
  }

  openDetailNews(id: number, title: any) {
    window.open(
      `${environment.serverUrl}/${this.i18.getCurrentLanguage() == 'en' ? 'news' : 'noticias'}/${this.i18
        .getTranslation(title.urlTitle ? title.urlTitle : title.headline)
        .toLowerCase()
        .replace(/[ %?]/g, '-')
        .replace(/['"()<>&\\\/]/g, '')}/${id}/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? '_self' : '_blank'
    );
  }
}
