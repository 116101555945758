import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class NewsService {
  constructor(private http: HttpClient) {}

  getNewsSearch(lang: string, page: number, perPage: number, keyword: string, tag: any = null, companyId: any = null) {
    return this.http.post(
      `${environment.apiURL}/content/news/search/${environment.platformId}/${lang}?page=${page}&per_page=${perPage}`,
      {
        keywordFilter: keyword,
        regionId: 0,
        tagIdFilter: companyId ? null : tag,
        includeSections: false,
        includeContentTags: true,
        includeAuthors: false,
        statusFilter: ['PUBLISHED'],
        companyId: companyId
      }
    );
  }
}
