/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i4 from "@angular/material/divider";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./access-granted.component";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/cdk/layout";
var styles_AccessGrantedDialogComponent = [""];
var RenderType_AccessGrantedDialogComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AccessGrantedDialogComponent, data: {} });
export { RenderType_AccessGrantedDialogComponent as RenderType_AccessGrantedDialogComponent };
export function View_AccessGrantedDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 28, "div", [["class", "flex-col"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(3, 0, null, null, 11, "div", [["class", "flex-row"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { "flex-wrap": 0 }), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "input", [["style", "width:100%; border: unset;"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.accessPass = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(11, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i3.View_MatDivider_0, i3.RenderType_MatDivider)), i0.ɵdid(17, 49152, null, 0, i4.MatDivider, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(19, 0, null, null, 9, "div", [["class", "flex-row"], ["style", "justify-content:space-evenly; padding-top:20px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(21, 0, null, null, 6, "button", [["class", "siila-submit login-btn"], ["mat-raised-button", ""], ["style", "width:100%"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.access() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(22, 180224, null, 0, i6.MatButton, [i0.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n          "])), (_l()(), i0.ɵeld(24, 0, null, 0, 2, "span", [["translate", "access"]], null, null, null, null, null)), i0.ɵdid(25, 8536064, null, 0, i9.TranslateDirective, [i9.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Access"])), (_l()(), i0.ɵted(-1, 0, ["\n        "])), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "flex-row"; var currVal_1 = _ck(_v, 6, 0, _co.handsetMode); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_9 = _co.accessPass; _ck(_v, 11, 0, currVal_9); var currVal_16 = _co.check(); _ck(_v, 22, 0, currVal_16); var currVal_17 = "access"; _ck(_v, 25, 0, currVal_17); }, function (_ck, _v) { var currVal_2 = i0.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 13).ngClassValid; var currVal_7 = i0.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = (i0.ɵnov(_v, 17).vertical ? "vertical" : "horizontal"); var currVal_11 = i0.ɵnov(_v, 17).vertical; var currVal_12 = !i0.ɵnov(_v, 17).vertical; var currVal_13 = i0.ɵnov(_v, 17).inset; _ck(_v, 16, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = (i0.ɵnov(_v, 22).disabled || null); var currVal_15 = (i0.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_14, currVal_15); }); }
export function View_AccessGrantedDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "access-granted-component", [], null, null, null, View_AccessGrantedDialogComponent_0, RenderType_AccessGrantedDialogComponent)), i0.ɵdid(1, 49152, null, 0, i10.AccessGrantedDialogComponent, [i11.MAT_DIALOG_DATA, i12.BreakpointObserver, i11.MatDialog], null, null)], null, null); }
var AccessGrantedDialogComponentNgFactory = i0.ɵccf("access-granted-component", i10.AccessGrantedDialogComponent, View_AccessGrantedDialogComponent_Host_0, {}, {}, []);
export { AccessGrantedDialogComponentNgFactory as AccessGrantedDialogComponentNgFactory };
