import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { I18nService } from '@app/core';
import { SpotSet } from '@app/models/spotSet';
import { MessageModalService } from '@app/shared/services/message-modal.service';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { AlertController } from '@ionic/angular';
var SetSpacesComponent = /** @class */ (function () {
    function SetSpacesComponent(dialog, data, spotBuildingsService, alertCtrl, i18nService, messageFormDialog) {
        this.dialog = dialog;
        this.data = data;
        this.spotBuildingsService = spotBuildingsService;
        this.alertCtrl = alertCtrl;
        this.i18nService = i18nService;
        this.messageFormDialog = messageFormDialog;
        this.spotSet = new SpotSet();
        this.listSpotSet = [];
        this.setSpotSpace = new SpotSet();
        this.addSetSpot = false;
        this.replaceSetSpot = false;
        this.removeSetSpot = false;
        this.step = 0;
        this.isMobile = false;
        this.isEdit = false;
        this.spotSetTitle = '';
    }
    SetSpacesComponent.prototype.ngOnInit = function () {
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        if (this.data.id) {
            this.isEdit = true;
            this.spotSetTitle = this.data.name;
        }
        else {
            this.spacesParameters = this.data.data;
            this.getSpotsSet();
        }
    };
    SetSpacesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.spaceInput.nativeElement.focus();
        }, 800);
    };
    SetSpacesComponent.prototype.close = function () {
        this.dialog.close();
    };
    SetSpacesComponent.prototype.changeStep = function (step) {
        this.step = step.index;
    };
    SetSpacesComponent.prototype.showMessage = function (header, message, keepUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var okText, alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        okText = this.i18nService.get('global.OK');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: header,
                                message: message,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SetSpacesComponent.prototype.createSpotSet = function () {
        var _this = this;
        var ids = [];
        this.spacesParameters.forEach(function (param) {
            ids.push(param.id);
        });
        this.spotSet.spotSetValue = "{\"spaceIds\":[" + ids + "]}";
        this.spotBuildingsService.setSpotSpace(this.spotSet).then(function (val) {
            var message = _this.i18nService.get('global.changesSaved');
            _this.dialog.close(message);
        });
    };
    SetSpacesComponent.prototype.getSpotsSet = function () {
        var _this = this;
        this.spotBuildingsService.getSpotSet().then(function (val) {
            _this.listSpotSet = val.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
        });
    };
    SetSpacesComponent.prototype.updateSetSpot = function () {
        var _this = this;
        if (this.addSetSpot) {
            var spaceIds = Object.values(JSON.parse(this.setSpotSpace.spotSetValue));
            var ids_1 = spaceIds[0];
            this.spacesParameters.forEach(function (param) {
                ids_1.push(param.id);
            });
            this.setSpotSpace.spotSetValue = "{\"spaceIds\":[" + ids_1 + "]}";
            this.spotBuildingsService.putSpotSet(this.setSpotSpace).then(function (val) {
                if (val == 200) {
                    var message = _this.i18nService.get('global.changesSaved');
                    _this.dialog.close(message);
                }
                else {
                    var message = _this.i18nService.get('global.errorSetSpaces');
                    _this.messageFormDialog.openDialogMessage(message);
                }
            });
        }
        if (this.replaceSetSpot) {
            var ids_2 = [];
            this.spacesParameters.forEach(function (param) {
                ids_2.push(param.id);
            });
            this.setSpotSpace.spotSetValue = "{\"spaceIds\":[" + ids_2 + "]}";
            this.setSpotSpace.action = 'ADD';
            this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(function (val) {
                if (val == 200) {
                    var message = _this.i18nService.get('global.changesSaved');
                    _this.dialog.close(message);
                }
                else {
                    var message = _this.i18nService.get('global.errorSetSpaces');
                    _this.messageFormDialog.openDialogMessage(message);
                }
            });
        }
        if (this.removeSetSpot) {
            var ids_3 = [];
            this.spacesParameters.forEach(function (param) {
                ids_3.push(param.id);
            });
            this.setSpotSpace.spotSetValue = "{\"spaceIds\":[" + ids_3 + "]}";
            this.setSpotSpace.action = 'REMOVE';
            this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(function (val) {
                if (val == 200) {
                    var message = _this.i18nService.get('global.changesSaved');
                    _this.dialog.close(message);
                }
                else {
                    var message = _this.i18nService.get('global.errorSetSpaces');
                    _this.messageFormDialog.openDialogMessage(message);
                }
            });
        }
    };
    SetSpacesComponent.prototype.onChangeOpt = function (opt) {
        if (opt == 1) {
            this.addSetSpot = true;
            this.removeSetSpot = false;
            this.replaceSetSpot = false;
        }
        if (opt == 2) {
            this.addSetSpot = false;
            this.replaceSetSpot = true;
            this.removeSetSpot = false;
        }
        if (opt == 3) {
            this.addSetSpot = false;
            this.replaceSetSpot = false;
            this.removeSetSpot = true;
        }
    };
    SetSpacesComponent.prototype.ubdateSpotSetTitle = function () {
        var _this = this;
        this.setSpotSpace.name = this.spotSetTitle;
        this.setSpotSpace.id = this.data.id;
        this.spotBuildingsService.patchSpotSet(this.setSpotSpace).then(function (val) {
            if (val == 200) {
                var message = _this.i18nService.get('global.changesSaved');
                _this.dialog.close(message);
            }
            else {
                var message = _this.i18nService.get('global.errorSetSpaces');
                _this.messageFormDialog.openDialogMessage(message);
            }
        });
    };
    return SetSpacesComponent;
}());
export { SetSpacesComponent };
