import { FormControl } from '@angular/forms';
function isObjectEmpty(object) {
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}
export function initSelectionControl() {
    return {
        english: false,
        spanish: false,
        portuguese: false
    };
}
export function addFormGroup(fb, formControlName, validator, value) {
    var _a;
    var validators = validator && validator.length ? validator : [];
    return fb.group((_a = {},
        _a[formControlName] = new FormControl(value ? value : '', validators),
        _a));
}
export function selectLanguageValue() {
    return {
        selectEnglish: 'en',
        selectSpanish: 'es',
        selectPortuguese: 'pt-br'
    };
}
export function setFormControlValue(formControlName, formData) {
    return formData[formControlName];
}
export function hasOwnProperty(propName, formData) {
    var isEmptyFormArray = isObjectEmpty(formData);
    return !isEmptyFormArray ? formData.hasOwnProperty(propName) : false;
}
export function saveTemporaryFormData(currentTempData, formValue, propName, groupName) {
    var formData = Object.assign({}, currentTempData);
    formValue[groupName][propName].forEach(function (item) {
        Object.keys(item).forEach(function (key) {
            var _a;
            Object.assign(formData, (_a = {}, _a[key] = item[key], _a));
        });
    });
    return formData;
}
