import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '@app/not-found/not-found.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutesRunningGuardsResolversAlways([
    {
      path: '',
      component: NotFoundComponent
    },
    {
      path: '**',
      pathMatch: 'full',
      component: NotFoundComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotFoundRoutingModule {}
