<div class="keyword-search p-relative">
  <div class="search-field p-relative" clickOutside (clickOutside)="setDropdownVisibility(false)">
    <form autocomplete="new-password" novalidate>
      <input
        id="search"
        name="keyword"
        class="keyword-input form-control"
        placeholder="{{ 'global.list-your-property.placeholderSearch' | translate }}"
        [(ngModel)]="keyword"
        (input)="onKeywordChange()"
        (focus)="onFocus()"
        type="text"
      />

      <div class="options p-absolute rounded shadow-sm" *ngIf="showOptions">
        <mat-tab-group
          mat-align-tabs="start"
          dynamicHeight
          [(selectedIndex)]="selectedIndex"
          (selectedIndexChange)="onTabChange()"
          animationDuration="100ms"
        >
          <mat-tab [label]="'buildinsDetail.all' | translate" class="short-label">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
          <mat-tab [label]="'global.market' | translate" class="short-label">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
          <mat-tab [label]="'buildinsDetail.state' | translate" class="short-label">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
          <mat-tab [label]="'buildinsDetail.city' | translate" class="short-label">
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
          <mat-tab
            [label]="'buildinsDetail.neighborhood' | translate"
            class="short-label"
            *ngIf="this.propertyTypeId !== this.propertyTypes.industrial"
          >
            <ng-container *ngTemplateOutlet="tabContent"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>
  </div>
</div>

<ng-template matTabContent #tabContent>
  <p *ngIf="loading" class="m-2">Loading...</p>
  <p *ngIf="searchFailed" class="m-2 text-danger">An error occurred while getting the data</p>
  <p *ngIf="missingParams" class="m-2">Please select a property Type</p>
  <ul class="option-list" *ngIf="data">
    <p *ngIf="tabNames[selectedIndex] === 'all' && noData" class="m-2">
      {{ 'global.list-your-property.noResultsfound' | translate }}
    </p>
    <div *ngFor="let section of orderedSections">
      <div *ngIf="tabNames[selectedIndex] === section || data[section].length">
        <p class="typeTitle mt-2">{{ getSectionName(section) | titlecase }}</p>
        <p class="m-2" *ngIf="tabNames[selectedIndex] === section && !data[section].length">
          {{ 'global.list-your-property.noResultsfound' | translate }}
        </p>
        <li
          *ngFor="let opt of data[section]"
          class="option"
          [ngClass]="{ selected: this.selected && opt.name === this.selected.name && opt.type === this.selected.type }"
          (click)="onItemClick(opt)"
        >
          <span [innerHTML]="opt.name | customHighlight: keyword:'typeahead-highlight'"></span>
        </li>
      </div>
    </div>
  </ul>
</ng-template>
