<div class="listing-map-container">
  <div class="listing-map">
    <app-full-screen-map-wrapper>
      <app-duplomap *ngIf="markers.length" [markers]="markers" [options]="mapOptions"></app-duplomap>
    </app-full-screen-map-wrapper>
    <div class="listing-info">
      <div class="row">
        <ion-icon class="pin-class" name="pin" style="padding-left: 13px;"></ion-icon>
        <span class="pin-class" localize [textValue]="currentListing?.building.address"></span>
      </div>
    </div>
  </div>
  <div [ngClass]="filterStyle">
    <div class="tag-label-col" *ngIf="searchTags && searchTags.length">
      <ion-label class="tag-label" [translate]="'global.nearbyListingFilters'"></ion-label>
    </div>

    <div class="tag-field-col">
      <ion-chip class="chip-tag" *ngFor="let tag of searchTags; let i = index">
        <ion-text *ngIf="!tag.max" [translate]="tag.name"></ion-text>

        <ion-text *ngIf="hasMinMaxRange(tag)">
          {{ tag.name | translate }} : {{ tag.min | localNumber: getMinRangeLocale(tag) }} -
          {{ tag.name | translate }} : {{ tag.max | localNumber: getMaxRangeLocale(tag) }}
          <ion-icon *ngIf="tag.showPlus" icon name="add"></ion-icon>
        </ion-text>

        <ion-icon *ngIf="!isRestricted(tag.name)" name="close" (click)="getNewListing(i, tag.fieldName)"></ion-icon>
      </ion-chip>

      <ion-chip *ngIf="searchTags && searchTags.length">
        <ion-text [translate]="'ClearSearch'" (click)="clearSearch()"></ion-text>
      </ion-chip>
    </div>
  </div>
</div>
