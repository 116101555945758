import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Subject } from 'rxjs';
import { LazySliderService } from '../services/lazy-slider.service';
import { cloneDeep } from 'lodash';
var LazySliderComponent = /** @class */ (function () {
    function LazySliderComponent(lazySliderSvc) {
        this.lazySliderSvc = lazySliderSvc;
        this.noDataMsg = 'No Data Found';
        this.showPager = false;
        this.showScrollbar = false;
        this.loadedItems = []; // Unloaded items should be a falsy value like "null".
        this.loading = false;
        this.changeSlide$ = null;
        this.getMoreItems = new EventEmitter();
        this.onSliderUpdate = new EventEmitter();
        this.pagerSubscription = null;
        this.defaultSliderOptions = cloneDeep(this.lazySliderSvc.defaultSliderOpts);
    }
    LazySliderComponent.prototype.ngOnChanges = function (changes) {
        if (this.slider && changes.loadedItems) {
            this.slider.update();
            this.slider.lockSwipes(false);
        }
    };
    LazySliderComponent.prototype.ngOnInit = function () {
        this.setSliderOptions();
        this.subscribeToSlideChange();
    };
    LazySliderComponent.prototype.ngOnDestroy = function () {
        if (this.pagerSubscription) {
            this.pagerSubscription.unsubscribe();
        }
    };
    LazySliderComponent.prototype.setSliderOptions = function () {
        this.sliderOptions = tslib_1.__assign({}, this.defaultSliderOptions, this.slideOptionOverride);
    };
    LazySliderComponent.prototype.subscribeToSlideChange = function () {
        var _this = this;
        if (!this.changeSlide$)
            return;
        this.pagerSubscription = this.changeSlide$.subscribe(function (Direction) {
            if (Direction === 'next')
                return _this.slideNext();
            if (Direction === 'prev')
                return _this.slidePrev();
        });
    };
    LazySliderComponent.prototype.onSlidesInit = function () {
        var _this = this;
        this.slider.update().then(function () {
            _this.setSlidesPerView(window.innerWidth);
            _this.notifyChange();
        });
    };
    LazySliderComponent.prototype.getPaginationInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var activeIndex, currentPage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.slider.getActiveIndex()];
                    case 1:
                        activeIndex = _a.sent();
                        currentPage = Math.ceil((activeIndex + 1) / this.slidesPerView);
                        return [2 /*return*/, { activeIndex: activeIndex, currentPage: currentPage }];
                }
            });
        });
    };
    LazySliderComponent.prototype.slidePrev = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.slider.slidePrev();
                return [2 /*return*/];
            });
        });
    };
    LazySliderComponent.prototype.slideNext = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.slider.slideNext();
                return [2 /*return*/];
            });
        });
    };
    LazySliderComponent.prototype.onSlideNextEnd = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentPage, loadedAmount, missingItems;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPaginationInfo()];
                    case 1:
                        currentPage = (_a.sent()).currentPage;
                        loadedAmount = this.loadedItems.filter(function (i) { return i; }).length;
                        missingItems = currentPage * this.slidesPerView > loadedAmount;
                        if (missingItems && loadedAmount < this.totalCount) {
                            this.fetchItems();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LazySliderComponent.prototype.fetchItems = function () {
        this.slider.lockSwipes(true);
        this.getMoreItems.emit({
            pageSize: this.queryPageSize,
            pageNumber: this.queryPage + 1
        });
    };
    LazySliderComponent.prototype.onResize = function (event) {
        this.setSlidesPerView(event.target.innerWidth);
    };
    LazySliderComponent.prototype.setSlidesPerView = function (containerWidth) {
        if (this.sliderOptions.breakpoints) {
            var breakpointProps = this.lazySliderSvc.getBreakPointProps(containerWidth, this.sliderOptions.breakpoints);
            if (this.slidesPerView === breakpointProps.slidesPerView)
                return;
            this.slidesPerView = breakpointProps.slidesPerView;
            this.sliderOptions = tslib_1.__assign({}, cloneDeep(this.sliderOptions), breakpointProps);
        }
    };
    LazySliderComponent.prototype.notifyChange = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isEnd, isBeginning, sliderState;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.slider.isEnd()];
                    case 1:
                        isEnd = _a.sent();
                        return [4 /*yield*/, this.slider.isBeginning()];
                    case 2:
                        isBeginning = _a.sent();
                        sliderState = { isEnd: isEnd, isBeginning: isBeginning };
                        this.onSliderUpdate.emit(sliderState);
                        return [2 /*return*/];
                }
            });
        });
    };
    return LazySliderComponent;
}());
export { LazySliderComponent };
