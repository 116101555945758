import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { PurchaseCreditsService } from '@app/core/purchase-credits.service';
import { PopoverController, NavParams, AlertController } from '@ionic/angular';
import { RegisterService } from '@app/core/register.service';
import { TranslateService } from '@ngx-translate/core';
import { EventCheckerService } from '@app/core/listings';
import { I18nService } from '@app/core';
import { ListingDetailService } from '@app/core/listings/listing-detail.service';
var AdminUpdatesComponent = /** @class */ (function () {
    function AdminUpdatesComponent(router, purchaseCreditsService, popoverCtrl, navParams, registerService, _ts, creditService, _eventChecker, alertCtrl, purchaseService, i18nService, listingDetailService) {
        this.router = router;
        this.purchaseCreditsService = purchaseCreditsService;
        this.popoverCtrl = popoverCtrl;
        this.navParams = navParams;
        this.registerService = registerService;
        this._ts = _ts;
        this.creditService = creditService;
        this._eventChecker = _eventChecker;
        this.alertCtrl = alertCtrl;
        this.purchaseService = purchaseService;
        this.i18nService = i18nService;
        this.listingDetailService = listingDetailService;
        this.breadcrumbOptions = {
            navigateHome: false,
            usePlatformLocation: true
        };
        this.renewalChoice = 'activate';
    }
    AdminUpdatesComponent.prototype.ngOnInit = function () {
        this.getStatuses();
    };
    AdminUpdatesComponent.prototype.getStatuses = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.classes = [{ name: 'FEATURED', id: 0 }, { name: 'PREMIUM', id: 1 }, { name: 'PROMOTED', id: 2 }];
                        _a = this;
                        return [4 /*yield*/, this.getStatusType()];
                    case 1:
                        _a.statuses = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.getStatusTranslations(this.statuses)];
                    case 2:
                        _b.statuses = _c.sent();
                        this.listing = this.navParams.data.listing;
                        this.setAdminValues(this.listing);
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminUpdatesComponent.prototype.getStatusType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.registerService.getListingStatus().toPromise()];
            });
        });
    };
    AdminUpdatesComponent.prototype.getStatusTranslations = function (statuses) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Object.keys(statuses).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        statuses[key].status = this.getI18nValue(statuses[key].status);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, statuses];
            });
        });
    };
    AdminUpdatesComponent.prototype.getI18nValue = function (textValue) {
        try {
            if (textValue && JSON.parse(textValue)) {
                return this.i18nService.getTranslation(textValue);
            }
            else {
                return '';
            }
        }
        catch (e) {
            console.error('failed to parse locale string -> listings search');
        }
    };
    AdminUpdatesComponent.prototype.getPopData = function () { };
    AdminUpdatesComponent.prototype.setAdminValues = function (listing) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var index;
            return tslib_1.__generator(this, function (_a) {
                index = this.classes.findIndex(function (v) {
                    return v.name === listing.listingType;
                });
                this.listingClass = this.classes[index];
                /*let statusIndex = this.statuses.findIndex((v: any) => {
                  return v.id === listing.listingStatus.id;
                });
                this.listingStatus = this.statuses[statusIndex];*/
                this.expirationDate = listing.endDate ? new Date(listing.endDate) : null;
                return [2 /*return*/];
            });
        });
    };
    AdminUpdatesComponent.prototype.checkDate = function (ev) { };
    AdminUpdatesComponent.prototype.choiceSelection = function (event) {
        this.renewalChoice = event.detail.value;
    };
    AdminUpdatesComponent.prototype.displayAlert = function (approval) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl.create({
                            header: this._ts.instant('global.removeListing'),
                            subHeader: this._ts.instant('global.removeListingText'),
                            cssClass: 'ion-alert-listings',
                            backdropDismiss: true,
                            buttons: [
                                {
                                    text: this._ts.instant('Cancel'),
                                    role: 'Cancel',
                                    handler: function () { }
                                },
                                {
                                    text: this._ts.instant('Ok'),
                                    handler: function () {
                                        _this.spotDeactivate();
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminUpdatesComponent.prototype.numDaysBetween = function (d1, d2) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var diff;
            return tslib_1.__generator(this, function (_a) {
                diff = d1.getTime() - d2.getTime();
                return [2 /*return*/, diff / (1000 * 60 * 60 * 24)];
            });
        });
    };
    AdminUpdatesComponent.prototype.displayActivateAlert = function (approval) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var expired, d1, d2, differenceDate, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        d1 = new Date();
                        d2 = new Date(this.listing.endDate);
                        return [4 /*yield*/, this.numDaysBetween(d1, d2)];
                    case 1:
                        differenceDate = _a.sent();
                        differenceDate > 0 ? (expired = true) : (expired = false);
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: this._ts.instant('global.adminUpdates.activateListing'),
                                subHeader: expired
                                    ? this._ts.instant('global.adminUpdates.activateExpired')
                                    : !this.listing.priorListingId
                                        ? this._ts.instant('global.adminUpdates.activate')
                                        : this._ts.instant('global.adminUpdates.activateRevision'),
                                cssClass: 'ion-alert-listings',
                                backdropDismiss: true,
                                buttons: [
                                    {
                                        text: this._ts.instant('Cancel'),
                                        role: 'Cancel',
                                        handler: function () { }
                                    },
                                    {
                                        text: this._ts.instant('Ok'),
                                        handler: function () {
                                            _this.updateReactivatedListing();
                                        }
                                    }
                                ]
                            })];
                    case 2:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () { });
                        return [4 /*yield*/, alert.present()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminUpdatesComponent.prototype.updateReactivatedListing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listing;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.purchaseService.reactivateListing(this.listing.id)];
                    case 1:
                        listing = _a.sent();
                        if (listing) {
                            this._eventChecker.updateListing$.next(listing);
                            this.popoverCtrl.dismiss();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminUpdatesComponent.prototype.checkIfDate = function () {
        if (this.expirationDate) {
            if (this.expirationDate instanceof Date &&
                Object.prototype.toString.call(this.expirationDate) === '[object Date]') {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    };
    AdminUpdatesComponent.prototype.spotDeactivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var listing, response, updatedListing;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.purchaseCreditsService.spotDeactivate(this.listing.status.id === 8 ? this.listing.priorListingId : this.listing.id)];
                    case 1:
                        listing = _a.sent();
                        this._eventChecker.updateListing$.next(listing);
                        if (this.listing.nextListingStatus && this.listing.nextListingStatus.id === 1) {
                            this._eventChecker.removeListing$.next(this.listing.nextListingId);
                        }
                        if (!(this.listing.listingStatus.id === 1)) return [3 /*break*/, 3];
                        this._eventChecker.removeListing$.next(this.listing.id);
                        return [4 /*yield*/, this.listingDetailService.getListingDetails(this.listing.priorListingId).toPromise()];
                    case 2:
                        response = _a.sent();
                        updatedListing = response.listing;
                        updatedListing.listingPhotos = [];
                        updatedListing.buildingTitle = updatedListing.title;
                        updatedListing.cityName = updatedListing.building.city.name;
                        updatedListing.buildingTitle = updatedListing.building.title;
                        updatedListing.buildingTypeId = updatedListing.building.buildingType.id;
                        updatedListing.buildingId = updatedListing.building.id;
                        updatedListing.buildingAddress = updatedListing.building.address;
                        this._eventChecker.paUpdate$.next(updatedListing);
                        _a.label = 3;
                    case 3:
                        this.popoverCtrl.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminUpdatesComponent.prototype.update = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var update, updatedListing;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.listing.listingType = this.listingClass.id;
                        this.listing.endDate = this.expirationDate;
                        return [4 /*yield*/, this.purchaseCreditsService.adminListingUpdate(this.listing)];
                    case 1:
                        update = _a.sent();
                        this._eventChecker.updateListing$.next(update);
                        this.popoverCtrl.dismiss();
                        if (!(this.listing.listingStatus.id === 4 &&
                            this.listing.nextListingStatus &&
                            this.listing.nextListingStatus.id === 4)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.listingDetailService.getListingDetails(this.listing.nextListingId)];
                    case 2:
                        updatedListing = _a.sent();
                        this._eventChecker.paUpdate$.next(updatedListing);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdminUpdatesComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    return AdminUpdatesComponent;
}());
export { AdminUpdatesComponent };
