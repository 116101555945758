<form class="change-password-modal" [formGroup]="updatePasswordForm">
  <ion-row>
    <ion-col class="header-col">
      <h4 class="header" color="secondary" [translate]="'global.user.changePassword'"></h4>
      <ion-text color="danger" [translate]="'global.user.message.updatePassword'"></ion-text>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="header-col">
      <ion-label color="secondary" [translate]="'global.user.currentPassword'"></ion-label>
      <input
        type="password"
        class="form-control password-input"
        name="currentPassword"
        formControlName="currentPassword"
      />
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="header-col">
      <ion-label color="secondary" [translate]="'global.form.newpassword'"></ion-label>
      <input type="password" class="form-control password-input" name="newPassword" formControlName="newPassword" />
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="header-col">
      <ion-label color="secondary" [translate]="'global.user.confirmNewPassword'"></ion-label>
      <input
        type="password"
        class="form-control password-input"
        name="confirmNewPassword"
        formControlName="confirmNewPassword"
      />

      <div *ngIf="isError" class="error-message">
        <ion-text color="danger" [translate]="infoProp.bodyMessage"></ion-text>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="control-button" [ngClass]="{ 'error-control': isError }">
        <ion-button class="cancel-btn" [translate]="'Cancel'" (click)="cancel()"></ion-button>
        <ion-button color="secondary" [translate]="'Save'" [disabled]="validatePassword()" (click)="changePassword()">
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</form>
