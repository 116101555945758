<div class="basic-info-modal">
  <ion-row>
    <ion-col size="11">
      <ion-title class="header header-title" [translate]="headerTitle"></ion-title>
    </ion-col>
    <ion-col size="1">
      <ion-icon class="close-icon" name="close" (click)="closeModal()"></ion-icon>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <p class="body-message" [translate]="bodyMessage"></p>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="footer">
      <ion-button class="close-button" color="secondary" [translate]="'Close'" (click)="closeModal()"></ion-button>
    </ion-col>
  </ion-row>
</div>
