import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NavParams, IonInput } from '@ionic/angular';
import { PopoverController } from '@ionic/angular';
import { ListPropertyService } from '@app/core/listings/list-property.service';
import { I18nService } from '@app/core';
var CoworkingPopoverComponent = /** @class */ (function () {
    function CoworkingPopoverComponent(navParams, popoverCtrl, listingService, i18nService) {
        this.navParams = navParams;
        this.popoverCtrl = popoverCtrl;
        this.listingService = listingService;
        this.i18nService = i18nService;
        this.btnFilter = false;
        this.loading = true;
        this.propertySubTypes = [];
        this.popDataCalled = false;
    }
    CoworkingPopoverComponent.prototype.ngOnInit = function () {
        this.getPopData();
        //this.setMinOptions(this.rangeFilters, this.searchDto);
    };
    CoworkingPopoverComponent.prototype.coworkingTypeChanged = function (event) {
        if (this.popDataCalled) {
            this.popDataCalled = false;
            return;
        }
        this.coworkingType = event.detail.value;
    };
    CoworkingPopoverComponent.prototype.getPopData = function () {
        this.popDataCalled = true;
        if (this.navParams.data.searchDto.coworkingType == undefined ||
            this.navParams.data.searchDto.coworkingType == 'null' ||
            this.navParams.data.searchDto.coworkingType === '') {
            this.coworkingType = 'shared';
        }
        else {
            this.coworkingType = this.navParams.data.searchDto.coworkingType;
        }
        if (this.navParams.data.searchDto.coworkingPositions == undefined ||
            this.navParams.data.searchDto.coworkingPositions === '') {
            this.coworkingPositions = 1;
        }
        else {
            this.coworkingPositions = this.navParams.data.searchDto.coworkingPositions;
        }
        this.searchDto = this.navParams.data.searchDto;
        this.loading = false;
    };
    CoworkingPopoverComponent.prototype.update = function () {
        var searchCopyDto = tslib_1.__assign({}, this.searchDto);
        /*
        if (searchCopyDto.propertySubTypes) {
          searchCopyDto.propertySubtypeIds = searchCopyDto.propertySubTypes.map((t: any) => t.id);
        } else {
          searchCopyDto.propertySubtypeIds = null;
        }*/
        if (this.coworkingType && this.coworkingType != 'null') {
            searchCopyDto.coworkingType = this.coworkingType;
        }
        else {
            searchCopyDto.coworkingType = null;
        }
        searchCopyDto.coworkingPositions = this.coworkingPositions;
        this.popoverCtrl.dismiss(searchCopyDto);
    };
    CoworkingPopoverComponent.prototype.cancel = function () {
        this.popoverCtrl.dismiss();
    };
    CoworkingPopoverComponent.prototype.clearSearch = function () {
        this.searchDto = this.listingService.clearFilters(this.searchDto, null);
        this.rangeModelValues = this.listingService.mapMinMaxOptions(null, this.staticMinMax);
    };
    CoworkingPopoverComponent.prototype.handleKeyboardEvent = function (event) {
        if (event.keyCode === 27) {
            this.cancel();
        }
    };
    return CoworkingPopoverComponent;
}());
export { CoworkingPopoverComponent };
