import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
var LocationService = /** @class */ (function () {
    function LocationService(http) {
        this.http = http;
        this.statesAPI = "/api/states";
        this.statesByCompanyAndTypeAPI = "/api/states/company/";
        this.citiesEndpoint = "/api/cities/with-listings";
        this.countryStatesEndpoint = "/api/states/with-listings";
        this.stateByCountryEndpoint = '/api/statesByCountry/properties?buildingTypeId=';
        this.citiesByStateEndpoint = '/api/citiesByMultipleStates/properties?buildingTypeId=';
        this.statesWithSpotsEndpoint = '/api/statesByCountry/spots?buildingTypeId=';
        this.citiesByStateWithSpotsEndpoint = '/api/citiesByMultipleStates/spots?buildingTypeId=';
    }
    LocationService.prototype.getStatesByIds = function (stateIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .post(this.statesAPI, stateIds)
                            .toPromise()
                            .then(function (response) {
                            var listings = response;
                            return listings;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getStatesByCompanyAndSpotBuildingType = function (companyId, spotType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("" + this.statesByCompanyAndTypeAPI + companyId + "/" + spotType).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getStatesByBrokerIdAndSpotBuildingType = function (brokerId, spotType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http
                            .get("" + this.statesByCompanyAndTypeAPI + brokerId + "/" + spotType + "?brokerId=" + brokerId)
                            .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getCities = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("" + this.citiesEndpoint).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(this.countryStatesEndpoint).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getStatesByCountry = function (buildingTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("" + this.stateByCountryEndpoint + buildingTypeId + "&countryId=2001").toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getcitiesByState = function (buildingTypeId, stateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get("" + this.citiesByStateEndpoint + buildingTypeId + "&stateIds=" + stateId).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getStatesWithSpots = function (buildingTypeId, spotType, propertySubTypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!propertySubTypeId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http
                                .get("" + this.statesWithSpotsEndpoint + buildingTypeId + "&spotType=" + spotType + "&countryId=2001")
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.http
                            .get("" + this.statesWithSpotsEndpoint + buildingTypeId + "&spotType=" + spotType + "&countryId=2001&propertySubtypeId=" + propertySubTypeId)
                            .toPromise()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.getCitiesByStatesWithSpots = function (buildingTypeId, stateId, spotType, propertySubtypeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!propertySubtypeId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http
                                .get("" + this.citiesByStateWithSpotsEndpoint + buildingTypeId + "&stateIds=" + stateId + "&spotType=" + spotType)
                                .toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.http
                            .get("" + this.citiesByStateWithSpotsEndpoint + buildingTypeId + "&stateIds=" + stateId + "&spotType=" + spotType + "&propertySubtypeId=" + propertySubtypeId)
                            .toPromise()];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    LocationService.prototype.putRioAndSaoPauloOnTop = function (states) {
        if (states && states.length > 1) {
            for (var i = 0; i < states.length; ++i) {
                var state = states[i];
                if (state.name.includes('Rio de Janeiro') || state.name.includes('São Paulo')) {
                    states.splice(i, 1);
                    if (states[0].name.includes('São Paulo')) {
                        states.splice(1, 0, state);
                    }
                    else {
                        states.splice(0, 0, state);
                    }
                }
            }
        }
        return states;
    };
    return LocationService;
}());
export { LocationService };
