import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from '@app/core';
import { BaseService } from '../../spot-buildings/services/base-service';

@Injectable()
export class BrokersService implements BaseService {
  private brokersAPI: string = 'api/brokers';
  private spotAPI: string = 'api/buildinglisting';

  constructor(private http: HttpClient, private i18NService: I18nService) {}
  interporlateURL(url: string, values: any): string {
    throw new Error('Method not implemented.');
  }

  getById(brokerID: number) {
    return this.http
      .get(`${this.brokersAPI}/${brokerID}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getMetadataById(brokerID: number) {
    return this.http
      .get(`${this.brokersAPI}/${brokerID}/metadata`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getBrokerURL(brokerID: number) {
    return this.http
      .get(`${this.brokersAPI}/url/${brokerID}/${this.i18NService.getCurrentLanguage()}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getSpotTitlesByBrokerId(userId: number) {
    return this.http
      .get(`${this.spotAPI}/titles/user/${userId}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }
}
