export class BrokersDTO {
  id: number;
  buildingListingId: number;
  userId: number;
  photoId: number;
  companyName: string;
  userFullName: string;
  userEmail: string;
  phoneNumber: string;
  firstNameAndLastName: string;
  jobTitle: string;
  companyURL: string;
  company: any;
}
