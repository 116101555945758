import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ListingService } from '@app/core/listings';
import { I18nService, ImageService, LanguageService } from '@app/core';
import { MatSidenav, MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { ContactDialogService } from '@app/shared/contact-dialog/contact-dialog.service';
import { LoginFormDialogService } from '@app/shared/login';
import { CommonGenericService } from '@app/core/common.service';
import { AuthenticationService } from '../core/auth/auth.service';
import { AccountService } from '@app/core/auth';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SwUpdate } from '@angular/service-worker';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import * as CartActions from '@app/store/actions';
import { PrivacyPolicyService } from '@app/shared/privacy-policy-dialog';
import { AppAcceptCancelModalService } from '@app/shared/accept-cancel-modal/accept-cancel-modal.service';
import { CookieService } from 'ngx-cookie-service';
import { RegisterFormComponent } from '@app/shared/register';
import { HomePageAppService } from '../shared/services/homepageapp.service';
import { RoutingHelperService } from '@app/core/helpers/routing-helper.service';
import { AppRouteNamesService } from '@app/core/helpers/app-route-names.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
var ShellComponent = /** @class */ (function () {
    function ShellComponent(router, activatedRoute, translateService, platform, alertController, i18nService, contactDialogService, loginFormDialog, privacyPolicyService, commonService, auth, listingService, accountService, imageService, dialog, myMessageService, swUpdate, nearbyListingSvc, alerter, gaService$, store, location, langService, acceptCancelModal, cookieSvc, homepageAppSrv, routingHelperSrv, routeNamesSrv, breakpointObserver) {
        var _this = this;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this.platform = platform;
        this.alertController = alertController;
        this.i18nService = i18nService;
        this.contactDialogService = contactDialogService;
        this.loginFormDialog = loginFormDialog;
        this.privacyPolicyService = privacyPolicyService;
        this.commonService = commonService;
        this.auth = auth;
        this.listingService = listingService;
        this.accountService = accountService;
        this.imageService = imageService;
        this.dialog = dialog;
        this.myMessageService = myMessageService;
        this.swUpdate = swUpdate;
        this.nearbyListingSvc = nearbyListingSvc;
        this.alerter = alerter;
        this.gaService$ = gaService$;
        this.store = store;
        this.location = location;
        this.langService = langService;
        this.acceptCancelModal = acceptCancelModal;
        this.cookieSvc = cookieSvc;
        this.homepageAppSrv = homepageAppSrv;
        this.routingHelperSrv = routingHelperSrv;
        this.routeNamesSrv = routeNamesSrv;
        this.breakpointObserver = breakpointObserver;
        this.imagePlaceholder = 'assets/images/default-profile-photo.png';
        this.userAccount = { name: '' };
        this.activeRoute = '';
        this.regularSearchActiveRoute = '';
        this.nearbySpotActiveRoute = '';
        this.cookieAcceptanceName = 'SIILA-cookie-usage-acceptance';
        this.cookieAcceptanceAccepted = true;
        this.links = {
            searchListings: {
                translationKey: 'global.headline'
            },
            home: {
                translationKey: 'global.home'
            },
            register: {
                translationKey: 'global.register.signup'
            },
            listYourSpot: {
                translationKey: 'global.listyour'
            },
            listPropertyNearby: {
                translationKey: 'global.list-property.listPropertyNearby'
            },
            contactUs: {
                translationKey: 'global.menu.options.contactUs'
            },
            aboutUs: {
                translationKey: 'global.menu.options.silBr'
            },
            blog: {
                translationKey: 'global.menu.options.blog'
            },
            subscribe: {
                translationKey: 'global.newsForm.subscribe'
            },
            userProfile: {
                translationKey: 'global.user.myAccount'
            }
        };
        this.isAdmin = false;
        this.newSwUpdateAvailable$ = new BehaviorSubject(false);
        this.enLangDesc = null;
        this.esLangDesc = null;
        this.$currentUserExists = this.accountService.currentUser$;
        this.enLangDesc = this.i18nService.get('navLinks.english');
        this.esLangDesc = this.i18nService.get('navLinks.spanish');
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(function (result) {
            _this.isMobile = result.matches;
        });
    }
    ShellComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isAdmin = this.checkSpotAdmin();
                this.validateUpdate();
                this.checkCookiesUsageAcceptance();
                this.faMapMarkerAlt = faMapMarkerAlt;
                this.customLoginText = this.i18nService.get('global.form.defaultSignInMessage');
                this.isLoggedIn$ = this.auth.$isLoginSubject;
                this.spotNearMeSubScription();
                this.openLoginDialogFirstLoad(false);
                if (this.location.path().includes('activate-user')) {
                    this.goToHomepage();
                    this.callHashAPI(this.activatedRoute.snapshot.queryParamMap.get('hash'));
                }
                this.routerObserver = this.router.events
                    .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
                    .subscribe(function (event) {
                    _this.activeRoute = event.url;
                    _this.regularSearchActiveRoute = _this.nearbyListingSvc.resetActiveRoute(_this.activeRoute, _this.regularSearchActiveRoute);
                    _this.nearbySpotActiveRoute = _this.nearbyListingSvc.resetActiveRoute(_this.activeRoute, _this.nearbySpotActiveRoute);
                    _this.drawer.close();
                });
                this.userAccountSubscription = this.accountService.currentUser$.subscribe(function (userAccount) {
                    if (userAccount) {
                        _this.acceptPrivacyPolicy(userAccount);
                        if (!_this.accountService.getPersonalDataFlag) {
                            _this.openPersonalDataDialog(userAccount);
                        }
                        if (!userAccount.profileImage) {
                            _this.getUserProfile(userAccount);
                        }
                    }
                    else {
                        _this.userAccount = userAccount;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ShellComponent.prototype.newVersionSpot = function () {
        if (!this.cookieSvc.get('modalNewVersion')) {
            this.cookieSvc.set('modalNewVersion', 'newVersion');
            this.alerter
                .create({
                header: '',
                message: "<div class=\"tittle-new-version\"> " + this.i18nService.get('global.newVersionTittle') + "</div>\n                    <div class=\"row row-border2\"></div>\n                    <div class=\"content-text-align\">\n                    " + this.i18nService.get('global.newVersionModal') + "\n                    </div>\n          ",
                buttons: [
                    {
                        text: this.i18nService.get('global.form.sendMsgToAgent.ok'),
                        handler: function () { }
                    }
                ]
            })
                .then(function (alert) {
                alert.present();
            });
        }
    };
    ShellComponent.prototype.validateUpdate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.swUpdate.isEnabled) {
                    this.swUpdate.available.subscribe(function (evt) {
                        localStorage.setItem('isDataAvailableReload', 'true');
                    });
                }
                this.swUpdate
                    .checkForUpdate()
                    .then(function () { })
                    .catch(function () { });
                return [2 /*return*/];
            });
        });
    };
    ShellComponent.prototype.openPersonalDataDialog = function (account) {
        var _this = this;
        if (account && !account.personalDataAccepted) {
            var personalDataModalInstance = this.acceptCancelModal.openDialog({
                title: this.i18nService.get('personalDataModal.title'),
                body: this.i18nService.get('personalDataModal.description'),
                acceptText: this.i18nService.get('personalDataModal.accept'),
                cancelText: this.i18nService.get('personalDataModal.review')
            });
            personalDataModalInstance.beforeClosed().subscribe(function () {
                localStorage.setItem(_this.accountService.seenPersonalDataFlagKey, JSON.stringify(true));
            });
            personalDataModalInstance.afterClosed().subscribe(function (result) {
                switch (result) {
                    case 'accept':
                        var currentDate = _this.commonService.getFormatedDate(new Date());
                        _this.accountService.acceptPersonalData(currentDate);
                        break;
                    case 'cancel':
                        _this.router.navigateByUrl('/user-home/my-account');
                        break;
                    case 'close':
                    default:
                        break;
                }
            });
        }
    };
    ShellComponent.prototype.checkCookiesUsageAcceptance = function () {
        var _this = this;
        var cookieAcceptance = false;
        try {
            cookieAcceptance = !!window.localStorage.getItem(this.cookieAcceptanceName);
        }
        catch (error) {
            console.error('No localStorage available');
        }
        if (!cookieAcceptance) {
            setTimeout(function () { return (_this.cookieAcceptanceAccepted = false); }, 1000);
        }
    };
    ShellComponent.prototype.acceptCookieUsage = function () {
        this.cookieAcceptanceAccepted = true;
        try {
            localStorage.setItem(this.cookieAcceptanceName, 'Accepted');
        }
        catch (error) {
            console.error('No localStorage available');
        }
    };
    ShellComponent.prototype.callHashAPI = function (hash) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountService
                            .activateUserWithHash(hash)
                            .then(function (result) {
                            _this.showActivationMessageOK();
                        })
                            .catch(function (error) {
                            _this.showActivationMessageFail();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShellComponent.prototype.ngOnDestroy = function () {
        this.userAccountSubscription.unsubscribe();
        this.spotNearMeFilterSubscription.unsubscribe();
        this.routerObserver.unsubscribe();
    };
    ShellComponent.prototype.acceptPrivacyPolicy = function (account) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isLoggedIn, hasAcceptedPolicy;
            return tslib_1.__generator(this, function (_a) {
                isLoggedIn = this.isLoggedIn$.value;
                hasAcceptedPolicy = this.privacyPolicyService.isUserPolicyUpdated(account.ppAcceptedVersion);
                if (isLoggedIn && !hasAcceptedPolicy && !this.privacyPolicyService.isPpDialogOpened) {
                    this.openPrivacyPolicyModal();
                }
                return [2 /*return*/];
            });
        });
    };
    ShellComponent.prototype.openPrivacyPolicyModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.privacyPolicyService.openPPDialog();
                return [2 /*return*/];
            });
        });
    };
    ShellComponent.prototype.presentAlertConfirm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var heading, message, launch, dismiss, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        heading = this.i18nService.get('global.menu.newVersion.heading');
                        message = this.i18nService.get('global.menu.newVersion.message');
                        launch = this.i18nService.get('global.menu.newVersion.launch');
                        dismiss = this.i18nService.get('global.menu.newVersion.dismiss');
                        return [4 /*yield*/, this.alerter.create({
                                header: heading,
                                message: message,
                                backdropDismiss: false,
                                buttons: [
                                    {
                                        text: launch,
                                        handler: function () {
                                            /* localStorage.removeItem('lastVisitDate'); */
                                            window.location.reload();
                                        }
                                    },
                                    {
                                        text: dismiss,
                                        handler: function () {
                                            _this.alerter.dismiss();
                                        }
                                    }
                                ]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            /* localStorage.removeItem('lastVisitDate'); */
                            _this.openLoginDialogFirstLoad(false);
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShellComponent.prototype.openLoginDialogFirstLoad = function (validateListing) {
        var countNavigation;
        if (!validateListing) {
            localStorage.setItem('sell', 'true');
        }
        var isLoggedIn;
        this.isLoggedIn$.pipe(take(1)).subscribe(function (val) { return (isLoggedIn = val); });
        var lastVisitDate = localStorage.getItem('lastVisitDate');
        var hasLoggedInToday = lastVisitDate && this.isToday(new Date(lastVisitDate));
        if (hasLoggedInToday) {
            return;
        }
        if (!isLoggedIn && !hasLoggedInToday) {
            localStorage.setItem('lastVisitDate', new Date().toString());
            //setTimeout(() => this.openLoginDialog(), 500);
        }
    };
    ShellComponent.prototype.spotNearMeSubScription = function () {
        var _this = this;
        this.spotNearMeFilterSubscription = this.listingService.spotNearMeFilter.subscribe(function (selected) {
            _this.regularSearchActiveRoute = _this.nearbyListingSvc.setRegularSearchActiveRoute(_this.activeRoute, selected);
            _this.nearbySpotActiveRoute = _this.nearbyListingSvc.setSpotNearMeActiveRoute(_this.activeRoute, selected);
        });
    };
    ShellComponent.prototype.getUserProfile = function (userAccount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userPhotos, image;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountService.getUserPhotoList().toPromise()];
                    case 1:
                        userPhotos = _a.sent();
                        image = this.accountService.getUploadedImageId(userPhotos);
                        this.userAccount = {
                            profile: userAccount,
                            profileImage: this.getMediumImg(image.profileImageId),
                            originalImage: this.getMediumImg(image.originalImageId),
                            name: userAccount.firstName + ' ' + userAccount.lastName
                        };
                        return [2 /*return*/];
                }
            });
        });
    };
    ShellComponent.prototype.clearBuySpotCart = function () {
        this.store.dispatch(CartActions.ClearCart({ payload: { items: [] } }));
    };
    Object.defineProperty(ShellComponent.prototype, "isWeb", {
        get: function () {
            if (this.platform) {
                return !this.platform.is('cordova');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShellComponent.prototype, "hasToken", {
        get: function () {
            return !!localStorage.getItem('token');
        },
        enumerable: true,
        configurable: true
    });
    ShellComponent.prototype.hasSearchDTO = function (searchDTO) {
        return [searchDTO.buildingTypes, searchDTO.listingLeaseOrSale, searchDTO.keyword !== '' || searchDTO.keyword].some(function (condition) { return condition; });
    };
    ShellComponent.prototype.loadNewAppVersion = function () {
        window.location.reload();
    };
    ShellComponent.prototype.getSearchUrl = function () {
        return '/search';
    };
    ShellComponent.prototype.changeLanguage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertController;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: this.translateService.instant('Change language'),
                            inputs: this.i18nService.supportedLanguages.map(function (language) { return ({
                                type: 'radio',
                                name: language,
                                label: language,
                                value: language,
                                checked: language === _this.i18nService.language
                            }); }),
                            buttons: [
                                {
                                    text: this.translateService.instant('Cancel'),
                                    role: 'cancel'
                                },
                                {
                                    text: this.translateService.instant('Ok'),
                                    handler: function (language) {
                                        _this.i18nService.language = language;
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alertController = _a.sent();
                        alertController.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShellComponent.prototype.toggleSideMenu = function (event) {
        this.drawer.toggle();
    };
    ShellComponent.prototype.presentContactDialog = function () {
        this.contactDialogService.openDialog();
    };
    ShellComponent.prototype.openLoginDialog = function () {
        var loginData = {
            url: this.router.url,
            customText: this.customLoginText
        };
        this.loginFormDialog.openDialog(loginData);
    };
    ShellComponent.prototype.isToday = function (someDate) {
        var today = new Date();
        /* localStorage.setItem('currentDay', today.toString());
        console.log('currentDay ', today.toString()); */
        return (someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear());
    };
    ShellComponent.prototype.goToSiiLaHomePage = function () {
        this.commonService.navgiateToSiilaHomePage();
    };
    ShellComponent.prototype.goToSiiLaBlog = function () {
        var externalBlogRoute = "https://siila.com.mx";
        window.open(externalBlogRoute, '_blank');
    };
    ShellComponent.prototype.goToSiilaProductsPage = function () {
        window.open(this.homepageAppSrv.getProductsURL(), '_system');
    };
    ShellComponent.prototype.logOut = function (event) {
        // Clear search on landing page
        this.listingService.updateModel({
            propertyType: null,
            listingType: null,
            stateId: null,
            cityId: null,
            keywordText: ''
        });
        this.auth.logout();
        this.clearBuySpotCart();
        if (this.router.url === '/') {
            this.toggleSideMenu(event);
        }
    };
    ShellComponent.prototype.navigateToListProperty = function () {
        var _this = this;
        this.$currentUserExists.pipe(take(1)).subscribe(function (val) {
            if (val) {
                _this.router.navigate(["/list-property"]);
            }
            else {
                _this.loginFormDialog.openDialog('/list-property');
            }
        });
    };
    ShellComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    ShellComponent.prototype.goToUserHome = function () {
        return this.router.navigateByUrl('/user-home');
    };
    ShellComponent.prototype.goToHomepage = function () {
        if (this.drawer) {
            this.drawer.close();
        }
        return this.router.navigateByUrl(this.i18nService.getHomeSPOTURL());
    };
    ShellComponent.prototype.goToSearch = function () {
        return this.router.navigateByUrl(this.getSearchUrl());
    };
    ShellComponent.prototype.openListYourSpotDialogue = function () {
        var _this = this;
        this.isLoggedIn$.pipe(take(1)).subscribe(function (val) {
            if (val) {
            }
            else {
                _this.loginFormDialog.openDialog('my-listing');
            }
        });
    };
    ShellComponent.prototype.getMyMessagesPage = function () {
        return '/user-home/my-messages';
    };
    ShellComponent.prototype.goToMyMessages = function () {
        return this.router.navigateByUrl(this.getMyMessagesPage());
    };
    ShellComponent.prototype.getUnreadMessages = function () {
        return this.myMessageService.getUnreadMessages();
    };
    ShellComponent.prototype.getCurrentLanguageDesc = function () {
        var currentLanguage = this.i18nService.language;
        if (currentLanguage === 'en-US') {
            return this.enLangDesc;
        }
        return this.esLangDesc;
    };
    ShellComponent.prototype.isItemActive = function (language) {
        return this.i18nService.language === language ? 'active-route' : '';
    };
    ShellComponent.prototype.isCurrentRegion = function (language) {
        return true;
    };
    ShellComponent.prototype.setLanguage = function (language) {
        this.i18nService.language = language;
        this.langService.registerCulture(language);
        var currentRoute = this.router.url;
        var isLoggedIn = this.isLoggedIn$.value;
        if (isLoggedIn) {
            this.accountService.changeLanguage(this.i18nService.getCurrentLanguage());
        }
        var url = this.routeNamesSrv.findRouteAlternative(currentRoute);
        this.router.navigateByUrl(url);
    };
    ShellComponent.prototype.showActivationMessageOK = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headerTxt, subHeaderTxt, okText, alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headerTxt = this.i18nService.get('global.register.accountActivation');
                        subHeaderTxt = this.i18nService.get('global.register.accountActivationMsgOK');
                        okText = this.i18nService.get('global.OK');
                        return [4 /*yield*/, this.alertController.create({
                                header: headerTxt,
                                subHeader: subHeaderTxt,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(function () {
                            setTimeout(function () { return _this.openLoginDialog(); }, 500);
                        });
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShellComponent.prototype.showActivationMessageFail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var headerTxt, subHeaderTxt, okText, alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        headerTxt = this.i18nService.get('global.register.accountActivation');
                        subHeaderTxt = this.i18nService.get('global.register.accountActivationMsgFail');
                        okText = this.i18nService.get('global.OK');
                        return [4 /*yield*/, this.alertController.create({
                                header: headerTxt,
                                subHeader: subHeaderTxt,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShellComponent.prototype.goToSiilaNews = function () {
        this.commonService.navgiateToSiilaNews();
    };
    ShellComponent.prototype.goToSiilaSpot = function () {
        window.open(this.homepageAppSrv.getSpotURL(), '_blank');
    };
    ShellComponent.prototype.navigateToRegister = function () {
        this.dialog.open(RegisterFormComponent, {
            height: 'auto',
            width: '350px',
            maxWidth: 'none'
        });
    };
    ShellComponent.prototype.goToMyAccount = function () {
        return this.router.navigateByUrl('/user-home/my-account');
    };
    ShellComponent.prototype.checkSpotAdmin = function () {
        this.user = JSON.parse(localStorage.getItem('user'));
        if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
            return true;
        }
        else {
            return false;
        }
    };
    ShellComponent.prototype.goToFavorites = function () {
        this.routingHelperSrv.navigateFavoritesPage();
    };
    return ShellComponent;
}());
export { ShellComponent };
