import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { PreviewImageComponent } from './preview-image.component';

const routes: Routes = [
  Shell.childRoutesRunningGuardsResolversAlways(
    [
      {
        path: ':id',
        component: PreviewImageComponent
      },
      {
        path: ':id/content',
        component: PreviewImageComponent
      },
      {
        path: ':id/thumbnail/:size',
        component: PreviewImageComponent
      }
    ],
    'images'
  )
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class PreviewImageRoutingModule {}
