import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FavoritesRoutingModule } from './favorites-routing.module';
import { FavoritesComponent } from './favorites.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material';
import { BrokersRoutingModule } from '@app/brokers/brokers.routing.module';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { MaterialModule } from '@app/shared/material.module';
import { ShellModule } from '@app/shell/shell.module';
import { SpotBuildingsModule } from '@app/spot-buildings/spot-buildings.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetaModule } from '@ngx-meta/core';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { PaginationModule } from 'ngx-bootstrap';
import { QuillModule } from 'ngx-quill';
import { LocalNumberPipe } from '@app/shared/pipes/number-pipe';
import { BaseService } from '@app/spot-buildings/services/base-service';

@NgModule({
  declarations: [FavoritesComponent],
  imports: [
    CommonModule,
    FavoritesRoutingModule,
    CoreModule,
    FormsModule,
    InViewportModule,
    IonicModule,
    MetaModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    SharedModule,
    ShellModule,
    TranslateModule,
    BrokersRoutingModule,
    SpotBuildingsModule,
    FontAwesomeModule,
    PaginationModule.forRoot(),
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    NgbModule,
    MaterialModule,
    MatTabsModule
  ],
  providers: [BaseService, LocalNumberPipe]
})
export class FavoritesModule {}
