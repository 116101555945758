import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MultiLanguageService } from '@app/core/multi-language.service';
import { CommonGenericService } from '@app/core/common.service';
import { IonCheckbox } from '@ionic/angular';
import { I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { EventCheckerService } from '@app/core/listings';
import { BuildingLocation } from '../maps/map/map.component';

@Component({
  selector: 'multi-language-elem',
  templateUrl: './multi-language.component.html',
  styleUrls: ['./multi-language.component.scss']
})
export class MultiLanguageComponent implements OnInit, AfterViewInit, OnChanges {
  multiLangForm: FormGroup;
  selectedLanguage: any[] = [];
  tempFormData: any = {};
  languageOption: any;
  languageModel: any;
  /*  isBrazilRegion: boolean; */
  defaultLanguage: string;
  richTextEditorOption: any;
  insertTextHere: string;
  editorStyle = {
    height: '200px'
  };

  @Input('options') options: any;
  @Input('formArrayValue') formArrayValue?: any;
  @Output('onValueChange') onValueChange?: EventEmitter<any> = new EventEmitter();
  @Input('height') height: any = null;

  constructor(
    private commonService: CommonGenericService,
    private fb: FormBuilder,
    private multiLanguageService: MultiLanguageService,
    private i18nService: I18nService,
    private eventCheckerSrv: EventCheckerService
  ) {}

  ngOnInit() {
    if (this.height) {
      this.editorStyle.height = this.height;
    }
    const currentLanguage = this.multiLanguageService.getCurrentLanguage(this.i18nService.language);
    const browserLanguage: string = this.multiLanguageService.getBrowserLanguage(currentLanguage);
    this.options = this.options ? this.options : {};
    this.multiLangForm = this.multiLanguageService.createMultiLanguageForm(this.options.formArrayName);
    this.languageOption = this.multiLanguageService.getLanguageOption();
    this.languageModel = this.multiLanguageService.getLanguageModel();
    /* this.isBrazilRegion = this.commonService.checkApplicationEnvironment('br'); */
    this.defaultLanguage = browserLanguage;
    this.richTextEditorOption = this.multiLanguageService.ngQuillEditorToolbarOption();
    this.insertTextHere = this.i18nService.get('global.insertTxtHere');
    if (this.options.formArrayName === 'address') {
      this.detectBuildingLocationChanges();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.populateFormArrayValue();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    //FIXME  SIIL-3660
    try {
      if (JSON.parse(changes.formArrayValue.currentValue)) {
        this.formArrayValue = JSON.parse(changes.formArrayValue.currentValue);
        this.populateFormArrayValue(1);
      }
    } catch (error) {}
  }

  private getSelectedLanguageIndex(language: string): number {
    return this.selectedLanguage.findIndex(item => item === language);
  }

  private assignTemporaryFormData(key: string, data: any) {
    Object.assign(this.tempFormData, { [key]: data });
  }

  private populateFormArrayValue(value = 0) {
    const isEmpty = this.commonService.isObjectEmpty(this.formArrayValue);
    this.languageModel = this.multiLanguageService.getLanguageModel();
    if (!isEmpty) {
      let formArray: FormArray = this.multiLangForm.get(this.options.formArrayName) as FormArray;
      if (value == 1) {
        formArray.clear();
        this.selectedLanguage = [];
      }

      Object.keys(this.formArrayValue).forEach(key => {
        const formGroup: FormGroup = this.multiLanguageService.createLanguageFormGroup(
          this.fb,
          key,
          null,
          this.formArrayValue[key]
        );
        this.languageModel[key] = true;
        this.selectedLanguage = [...this.selectedLanguage, key];
        this.assignTemporaryFormData(key, this.formArrayValue[key]);
        formArray.push(formGroup);
      });
      this.onValueChange.emit(this.multiLangForm.value);
    } else {
      this.addDefaultLanguageElement();
    }
  }

  private addDefaultLanguageElement() {
    const formArray: FormArray = this.multiLangForm.get(this.options.formArrayName) as FormArray;
    const formGroup: FormGroup = this.multiLanguageService.createLanguageFormGroup(this.fb, this.defaultLanguage);
    this.selectedLanguage.push(this.defaultLanguage);
    this.languageModel[this.defaultLanguage] = true;
    formArray.push(formGroup);
  }

  private addLanguageControl(elemValue: string) {
    const isObjectEmpty = this.commonService.isObjectEmpty(this.tempFormData);
    const tempFormData = isObjectEmpty ? null : this.tempFormData[elemValue];
    const multiLangFormArray = this.multiLangForm.get(this.options.formArrayName) as FormArray;
    const formGroup: FormGroup = this.multiLanguageService.createLanguageFormGroup(
      this.fb,
      elemValue,
      null,
      tempFormData
    );
    this.selectedLanguage.push(elemValue);
    multiLangFormArray.push(formGroup);
    this.onValueChange.emit(this.multiLangForm.value);
  }

  public removeLanguageElem(language: any) {
    const isRemoveElement: boolean = true;
    const index = this.getSelectedLanguageIndex(language);
    const multiLangFormArray = this.multiLangForm.get(this.options.formArrayName) as FormArray;
    multiLangFormArray.removeAt(index);
    this.selectedLanguage.splice(index, 1);
    this.onElemChange(isRemoveElement);
  }

  get languageElemList() {
    return <FormArray>this.multiLangForm.get(this.options.formArrayName);
  }

  public getSelectLanguage(index: number, isKey: boolean) {
    const language = this.selectedLanguage[index];
    return isKey ? language : this.languageOption[language];
  }

  public onElemChange(isRemoveElement?: boolean) {
    if (!isRemoveElement) {
      this.tempFormData = this.multiLanguageService.santizeFormValue(
        this.multiLangForm.value,
        this.options.formArrayName
      );
    }
    this.onValueChange.emit(this.multiLangForm.value);
  }

  public reset() {
    this.multiLangForm.reset();
    this.onElemChange();
  }

  public elemOrder(elem: IonCheckbox): string {
    return elem.value === this.defaultLanguage ? 'first-order' : 'second-order';
  }

  public orderControlElem(index: number): string {
    const language: string = this.selectedLanguage[index];
    if (this.selectedLanguage.length === 2) {
      return language === this.defaultLanguage ? 'first-order' : 'second-control';
    }
    return 'first-order';
  }

  public selectLanguageElement(selection: string, checkboxElem: any) {
    this.languageModel[selection] = !this.languageModel[selection];
    if (this.languageModel[selection]) {
      this.addLanguageControl(checkboxElem.value);
    } else {
      this.removeLanguageElem(checkboxElem.value);
    }
  }

  private detectBuildingLocationChanges() {
    this.eventCheckerSrv.updateBuildingLocation$.subscribe((buildingLocation: BuildingLocation) => {
      if (this.selectedLanguage && this.selectedLanguage.length > 0 && buildingLocation.address) {
        let formArrayValue: any[] = [];
        this.selectedLanguage.forEach((lang: any) => {
          const addressObj: any = {};
          addressObj[lang] = buildingLocation.address;
          formArrayValue.push(addressObj);
        });
        if (this.multiLangForm.get('address')) {
          this.multiLangForm.get('address').setValue(formArrayValue);
          this.onValueChange.emit(this.multiLangForm.value);
        }
      }
    });
  }
}
