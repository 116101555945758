import { AgentDTO } from './spot-buildings/agent-broker.model';

export enum RequestInformationType {
  REQ_INFO = 'REQ_INFO',
  REQ_TOUR = 'REQ_TOUR',
  REQ_BROKER = 'REQ_BROKER',
  REQ_CLAIM = 'REQ_CLAIM',
  REQ_COMPANY = 'REQ_COMPANY',
  REQ_SCHEDULE = 'REQ_SCHEDULE'
}
export interface RequestInfoInput {
  companyLogoImageId?: number;
  requestTitle: string;
  spotId?: number;
  agents?: AgentDTO[];
  sentEmailTo?: string[];
  brokerRequest?: boolean;
  requestType: RequestInformationType;
}

export interface RequestInfoDTO {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  /* ext: string; */
  comments: string;
  spotTitle: string;
  sentEmailTo?: string[];
  spotId?: number;
  brokerRequest?: boolean;
  requestType: RequestInformationType;
}
