import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../language.service';

@Injectable()
export class ListingPriceService {
  constructor(private http: HttpClient, private langService: LanguageService) {}

  convertForNumber(value: any) {
    if (value) {
      if (this.langService.locale === 'en-US') {
        value = value.replace(/\,/g, '');
      } else {
        value = value.replace(/\./g, '').replace(/\,/g, '.');
      }
      return value;
    }
  }

  convertToNumber(value: any) {
    if (value) {
      if (this.langService.locale !== 'en-US') {
        value = value.replace(/\./g, '').replace(/\,/g, '.');
        value = Number(value).toFixed(2);
      } else {
        value = value.replace(/\,/g, '');
        value = Number(value).toFixed(2);
      }
      return value;
    }
  }

  convertForDisplay(value: any) {
    if (value) {
      if (this.langService.locale !== 'en-US') {
        value = Number(value).toFixed(2);
        value = value.replace(/\./g, ',');
      } else {
        value = Number(value).toFixed(2);
      }
      return value;
    }
  }
}
