import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./search-navigation/search-navigation.module.ngfactory").then(function (m) { return m.SearchNavigationModuleNgFactory; }); }, ɵ2 = function () { return import("./user-home/user-home.routing.module.ngfactory").then(function (m) { return m.UserHomeRoutingModuleNgFactory; }); }, ɵ3 = function () { return import("./spot-buildings/spot-buildings.module.ngfactory").then(function (m) { return m.SpotBuildingsModuleNgFactory; }); }, ɵ4 = function () { return import("./company/company.module.ngfactory").then(function (m) { return m.CompanyModuleNgFactory; }); }, ɵ5 = function () { return import("./brokers/brokers.module.ngfactory").then(function (m) { return m.BrokersModuleNgFactory; }); }, ɵ6 = function () { return import("./favorites/favorites.module.ngfactory").then(function (m) { return m.FavoritesModuleNgFactory; }); }, ɵ7 = function () { return import("./preview-image/preview-image.module.ngfactory").then(function (m) { return m.PreviewImageModuleNgFactory; }); }, ɵ8 = function () { return import("./not-found/not-found.module.ngfactory").then(function (m) { return m.NotFoundModuleNgFactory; }); };
var routes = [
    { path: '', redirectTo: '/', pathMatch: 'full' },
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: 'search-navigation',
        loadChildren: ɵ1
    },
    {
        path: 'user-home',
        loadChildren: ɵ2
    },
    {
        path: 'spot-buildings',
        loadChildren: ɵ3
    },
    {
        path: 'companies',
        loadChildren: ɵ4
    },
    {
        path: 'brokers',
        loadChildren: ɵ5
    },
    { path: 'favorites', loadChildren: ɵ6 },
    {
        path: 'images',
        loadChildren: ɵ7
    },
    {
        path: 'not-found',
        loadChildren: ɵ8
    },
    {
        path: '**',
        redirectTo: 'not-found'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
