import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ToastController, AlertController } from '@ionic/angular';
import { I18nService } from './i18n.service';
var RegisterService = /** @class */ (function () {
    function RegisterService(http, toastCtrl, alertCtrl, i18NService) {
        this.http = http;
        this.toastCtrl = toastCtrl;
        this.alertCtrl = alertCtrl;
        this.i18NService = i18NService;
        this.emailHost = 'api/account/existing-email/';
        this.forgotPasswordEndpoint = 'api/forgot-password';
        this.changePasswordEndpoint = 'api/account/forced-change-password';
        this.listingHost = 'api/companies/with-listings-access';
        this.registerHost = 'api/account/listings-app/sign-up';
        this.imgHost = 'api/images/base64';
        this.offeredByUserApi = 'api/users/allOfSameCompany';
        this.status = 'api/listingStatuss';
        this.usersByCompany = 'api/companys/get-users-by-company/';
        this.measurement = 'api/measurementStandards';
        this.subType = 'api/lookUpTables/building-types/';
        this.headers = {
            headers: new HttpHeaders()
                .append('Content-Type', 'application/x-www-form-urlencoded')
                .append('Accept', 'application/json')
        };
    }
    RegisterService.prototype.getEmailUnique = function (email) {
        return this.http.get("" + this.emailHost + email, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.getListingCompanies = function () {
        return this.http.get("" + this.listingHost, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.getUsers = function () {
        return this.http.get("" + this.offeredByUserApi, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.getListingStatus = function () {
        return this.http.get("" + this.status, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.getUsersByCompany = function (id) {
        return this.http.get("" + this.usersByCompany + id + "/active", { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.getMeasurementStandard = function () {
        return this.http.get("" + this.measurement, { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.getBuildingSubTypes = function (id) {
        return this.http.get("" + this.subType + id + "/property-subtypes", { observe: 'response' }).pipe(map(function (val) { return val.body; }));
    };
    RegisterService.prototype.register = function (payload) {
        var httpParams = new HttpParams();
        Object.keys(payload).forEach(function (key) {
            if (key === 'userPhones') {
                httpParams = httpParams.append(key, JSON.stringify(payload[key]));
            }
            else {
                httpParams = httpParams.append(key, payload[key]);
            }
        });
        return this.http.post("" + this.registerHost, httpParams, this.headers).pipe(map(function (val) { return val; }));
    };
    RegisterService.prototype.uploadUserImage = function (payload) {
        return this.http.post("" + this.imgHost, payload).pipe(map(function (val) { return val; }));
    };
    RegisterService.prototype.formatUserDetails = function (registerObj, croppedObj, uncroppedObj) {
        var phoneNumber = registerObj.phoneNumber ? registerObj.phoneNumber.nationalNumber : null;
        var phoneNumberIso = registerObj.phoneNumber ? registerObj.phoneNumber.countryCode : null;
        var whatsappNumber = registerObj.whatsappNumber ? registerObj.whatsappNumber.nationalNumber : null;
        var whatsappNumberIso = registerObj.whatsappNumber ? registerObj.whatsappNumber.countryCode : null;
        var postalCode = registerObj.postalCode;
        var selectedComp = registerObj.companySelected.toString();
        if (selectedComp == '') {
            registerObj.companyId = registerObj.dropdown.id ? registerObj.dropdown.id : null;
            registerObj.companyName = registerObj.dropdown.name ? registerObj.dropdown.name : null;
        }
        else {
            registerObj.companyId = null;
            registerObj.companyName = selectedComp;
        }
        registerObj.userPhones = [];
        if (phoneNumber) {
            var obj = { phoneType: 'PHONE_NUMBER', phoneNumber: phoneNumber, isoCode: phoneNumberIso };
            registerObj.userPhones.push(obj);
        }
        if (whatsappNumber) {
            var obj = { phoneType: 'WHATSAPP', phoneNumber: whatsappNumber, isoCode: whatsappNumberIso };
            registerObj.userPhones.push(obj);
        }
        if (postalCode) {
            registerObj.postalCode = postalCode;
        }
        delete registerObj['confirmPass'];
        delete registerObj['dropdown'];
        delete registerObj['companySelected'];
        Object.keys(registerObj).forEach(function (key) {
            if (key !== 'whatsApp' &&
                (registerObj[key] === null || (isNaN(parseFloat(registerObj[key])) && !registerObj[key].length))) {
                delete registerObj[key];
            }
        });
        if (croppedObj) {
            registerObj.originalImageId = croppedObj.id;
        }
        if (uncroppedObj) {
            registerObj.resizedImageId = uncroppedObj.id;
        }
        return registerObj;
    };
    RegisterService.prototype.presentToast = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: 'Email is already in use.',
                            duration: 3500,
                            animated: true,
                            color: 'danger',
                            keyboardClose: true,
                            cssClass: 'toast-alert'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RegisterService.prototype.showAlert = function (title, message, onDismiss) {
        if (onDismiss === void 0) { onDismiss = function () { }; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var okText, alert;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        okText = this.i18NService.get('global.OK');
                        return [4 /*yield*/, this.alertCtrl.create({
                                header: title,
                                subHeader: message,
                                buttons: [okText]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.onDidDismiss().then(onDismiss);
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterService.prototype.showUploadErr = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastCtrl.create({
                            message: 'File size exceeded. Please try again.',
                            duration: 3500,
                            animated: true,
                            color: 'danger',
                            keyboardClose: true,
                            cssClass: 'toast-alert'
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () { });
                        return [4 /*yield*/, toast.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RegisterService.prototype.forgotPassword = function (body) {
        return this.http.post("" + this.forgotPasswordEndpoint, body).toPromise();
    };
    RegisterService.prototype.changePassword = function (body) {
        return this.http.post("" + this.changePasswordEndpoint, body).toPromise();
    };
    RegisterService.prototype.setKeyForChangePassword = function (key) {
        this.keyForChangePassword = key;
    };
    RegisterService.prototype.getKeyForChangePassword = function () {
        return this.keyForChangePassword;
    };
    RegisterService.prototype.getAmenities = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var industrialFilters, officeFilters;
            return tslib_1.__generator(this, function (_a) {
                if (type === 'industrial') {
                    industrialFilters = [
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.twentyFourHourSecurity',
                            fieldName: 'twentyFourHourSecurity'
                        },
                        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                        { selected: false, name: 'global.addtSearchFilters.armoredCabin', fieldName: 'armoredCabin' },
                        { selected: false, name: 'global.addtSearchFilters.manufacturing', fieldName: 'manufacturing' },
                        //{ selected: false, name: 'global.addtSearchFilters.logistics', fieldName: 'logistics' },
                        { selected: false, name: 'global.addtSearchFilters.officeSpaceAvailable', fieldName: 'officeSpaceAvailable' },
                        //{ selected: false, name: 'global.addtSearchFilters.fireProtectionSystem', fieldName: 'fireProtectionSystem' },
                        { selected: false, name: 'global.addtSearchFilters.blockConstruction', fieldName: 'blockConstruction' },
                        { selected: false, name: 'global.addtSearchFilters.steelConstruction', fieldName: 'steelConstruction' },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.blockAndSteelConstruction',
                            fieldName: 'blockAndSteelConstruction'
                        },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.skyLights',
                            fieldName: 'skyLights'
                        },
                        { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                        { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                        //{ selected: false, name: 'global.addtSearchFilters.industrialPark', fieldName: 'industrialPark' },
                        //{ selected: false, name: 'global.addtSearchFilters.industrialZone', fieldName: 'industrialZone' },
                        { selected: false, name: 'global.addtSearchFilters.truckYard', fieldName: 'truckYard' },
                        { selected: false, name: 'global.addtSearchFilters.crossDocking', fieldName: 'crossDocking' },
                        { selected: false, name: 'global.addtSearchFilters.refrigerated', fieldName: 'refrigerated' },
                        { selected: false, name: 'global.addtSearchFilters.sluiceGate', fieldName: 'sluiceGate' },
                        { selected: false, name: 'global.addtSearchFilters.lockerRoom', fieldName: 'hasLockerRooms' }
                    ];
                    return [2 /*return*/, industrialFilters];
                }
                if (type === 'office') {
                    officeFilters = [
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.twentyFourHourSecurity',
                            fieldName: 'twentyFourHourSecurity'
                        },
                        { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                        { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                        { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                        { selected: false, name: 'global.addtSearchFilters.plugAndPlay', fieldName: 'plugAndPlay' },
                        //{ selected: false, name: 'global.addtSearchFilters.finishedOut', fieldName: 'finishedOut' },
                        { selected: false, name: 'global.addtSearchFilters.furnished', fieldName: 'furnished' },
                        { selected: false, name: 'global.addtSearchFilters.coreAndShel', fieldName: 'coreAndShel' },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.centralAirConditioning',
                            fieldName: 'centralAirConditioning'
                        },
                        {
                            selected: false,
                            name: 'global.addtSearchFilters.miniSplitAirConditioning',
                            fieldName: 'miniSplitAirConditioning'
                        },
                        { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
                        { selected: false, name: 'global.addtSearchFilters.leedStatus', fieldName: 'leedStatus' },
                        { selected: false, name: 'global.addtSearchFilters.raisedFloor', fieldName: 'raisedFloor' },
                        { selected: false, name: 'global.addtSearchFilters.neverUsed', fieldName: 'neverUsed' }
                    ];
                    return [2 /*return*/, officeFilters];
                }
                return [2 /*return*/];
            });
        });
    };
    RegisterService.prototype.getAmenitiesBuildings = function (type, offer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var industrialFilters, buildingsOfficeFilters, buildingsOfficeFilters;
            return tslib_1.__generator(this, function (_a) {
                if (type === 'industrialBuildings') {
                    industrialFilters = [];
                    return [2 /*return*/, industrialFilters];
                }
                if (type === 'officeBuildings') {
                    if (offer == 'L') {
                        buildingsOfficeFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                            { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                            { selected: false, name: 'global.addtSearchFilters.bicycleRack', fieldName: 'bicycleRack' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.centralAirConditioning',
                                fieldName: 'centralAirConditioning'
                            },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.petFriendly',
                                fieldName: 'petFriendly'
                            }
                        ];
                        return [2 /*return*/, buildingsOfficeFilters];
                    }
                    else {
                        buildingsOfficeFilters = [
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.twentyFourHourSecurity',
                                fieldName: 'twentyFourHourSecurity'
                            },
                            { selected: false, name: 'global.addtSearchFilters.electricGenerator', fieldName: 'electricGenerator' },
                            { selected: false, name: 'global.addtSearchFilters.heliport', fieldName: 'heliport' },
                            { selected: false, name: 'global.addtSearchFilters.sprinklers', fieldName: 'sprinklers' },
                            { selected: false, name: 'global.addtSearchFilters.sustentability', fieldName: 'leedStatus' },
                            { selected: false, name: 'global.addtSearchFilters.singleTenant', fieldName: 'singleTenant' },
                            { selected: false, name: 'global.addtSearchFilters.multiTenant', fieldName: 'multiTenant' },
                            {
                                selected: false,
                                name: 'global.addtSearchFilters.centralAirConditioning',
                                fieldName: 'centralAirConditioning'
                            }
                        ];
                        return [2 /*return*/, buildingsOfficeFilters];
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    RegisterService.prototype.selectExistingFeatures = function (listing, officeFilters, industrialFilters) {
        Object.keys(listing).forEach(function (key) {
            if (industrialFilters) {
                if (listing[key] === true) {
                    var result = industrialFilters.find(function (filter) { return filter.fieldName === key; });
                    if (result) {
                        result.selected = true;
                    }
                }
            }
            else {
                if (listing[key] === true) {
                    var result = officeFilters.find(function (filter) { return filter.fieldName === key; });
                    if (result) {
                        result.selected = true;
                    }
                }
            }
        });
        if (officeFilters) {
            return officeFilters;
        }
        else {
            return industrialFilters;
        }
    };
    RegisterService.prototype.selectExistingFeaturesFromBuilding = function (building, officeFilters, industrialFilters) {
        Object.keys(building).forEach(function (key) {
            if (industrialFilters) {
                if (building[key] === true &&
                    (key === 'twentyFourHourSecurity' ||
                        key === 'armoredCabin' ||
                        key === 'manufacturing' ||
                        key === 'electricGenerator' ||
                        key === 'sprinklers' ||
                        key === 'truckYard' ||
                        key === 'crossDocking' ||
                        key === 'heliport')) {
                    var result = industrialFilters.find(function (filter) { return filter.fieldName === key; });
                    if (result) {
                        result.selected = true;
                    }
                }
            }
            else {
                if (building[key] === true &&
                    (key === 'twentyFourHourSecurity' ||
                        key === 'centralAirConditioning' ||
                        key === 'miniSplitAirConditioning' ||
                        key === 'electricGenerator' ||
                        key === 'sprinklers' ||
                        key === 'bicycleRack' ||
                        key === 'leedStatus' ||
                        key === 'heliport')) {
                    var result = officeFilters.find(function (filter) { return filter.fieldName === key; });
                    if (result) {
                        result.selected = true;
                    }
                }
            }
        });
        if (officeFilters) {
            return officeFilters;
        }
        else {
            return industrialFilters;
        }
    };
    return RegisterService;
}());
export { RegisterService };
