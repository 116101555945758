import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-range-filters',
  templateUrl: './range-filters.component.html',
  styleUrls: ['./range-filters.component.scss']
})
export class RangeFilterComponent implements OnInit {
  @Input('staticMinMax') staticMinMax: any;
  @Input('rangeModelValues') rangeModelValues: any;
  @Input('checkboxValues') checkboxValues: any;
  minZero: any = 0;
  searchDTO: any;
  listingLeaseOrSale: any;

  constructor() {}

  ngOnInit(): void {
    this.searchDTO = JSON.parse(localStorage.getItem('searchDto'));
    if (this.searchDTO && this.searchDTO.listingLeaseOrSale) {
      this.listingLeaseOrSale = this.searchDTO.listingLeaseOrSale[0];
    } else {
      this.listingLeaseOrSale = null;
    }
  }

  checkValues(filterName: string) {
    if (
      this.rangeModelValues[filterName].lower < 0 ||
      this.rangeModelValues[filterName].lower == null ||
      this.rangeModelValues[filterName].lower > this.rangeModelValues[filterName].upper
    ) {
      this.rangeModelValues[filterName].lower = 0;
    }

    if (
      this.rangeModelValues[filterName].upper > this.staticMinMax[filterName].max ||
      this.rangeModelValues[filterName].upper == null
    ) {
      this.rangeModelValues[filterName].upper = this.staticMinMax[filterName].max;
    }

    this.rangeModelValues[filterName] = {
      lower: this.rangeModelValues[filterName].lower,
      upper: this.rangeModelValues[filterName].upper
    };
  }

  checkboxValue(checboxName: string, filterName: string) {
    if (this.checkboxValues[checboxName]) {
      this.rangeModelValues[filterName].lower = 0;
      this.rangeModelValues[filterName].upper = this.staticMinMax[filterName].max;
      this.rangeModelValues[filterName] = {
        lower: this.rangeModelValues[filterName].lower,
        upper: this.rangeModelValues[filterName].upper
      };
    }
  }
}
