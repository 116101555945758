/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/material/tooltip";
import * as i5 from "@angular/cdk/overlay";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@ngx-translate/core";
import * as i12 from "@angular/common";
import * as i13 from "./breadcrumb.component";
import * as i14 from "@angular/router";
import * as i15 from "../../core/helpers/platform-helper.service";
import * as i16 from "../../core/listings/listing.service";
import * as i17 from "../../search-navigation/services/listing-search.service";
import * as i18 from "../../core/i18n.service";
var styles_BreadcrumbComponent = [i0.styles];
var RenderType_BreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbComponent, data: {} });
export { RenderType_BreadcrumbComponent as RenderType_BreadcrumbComponent };
function View_BreadcrumbComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "ion-button", [["class", "back-button"], ["icon-only", ""], ["ion-button", ""], ["matTooltipClass", "tooltip-font"], ["matTooltipPosition", "right"], ["tappable", ""]], null, [[null, "mouseover"], [null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("mouseover" === en)) {
        var pd_3 = (_co.iosBack($event) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.back($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), i1.ɵdid(2, 212992, null, 0, i4.MatTooltip, [i5.Overlay, i1.ElementRef, i6.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i7.Platform, i8.AriaDescriber, i8.FocusMonitor, i4.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i9.Directionality], [2, i4.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i10.HAMMER_LOADER]], { position: [0, "position"], disabled: [1, "disabled"], message: [2, "message"], tooltipClass: [3, "tooltipClass"] }, null), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 0, ["\n        "])), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "ion-icon", [["color", "primary"], ["name", "md-arrow-back"], ["size", "medium"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(6, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"], size: [2, "size"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "right"; var currVal_1 = !_co.isDesktop; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 3).transform("global.form.back")), ""); var currVal_3 = "tooltip-font"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "primary"; var currVal_5 = "md-arrow-back"; var currVal_6 = "medium"; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6); }, null); }
function View_BreadcrumbComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn ml-2 custom-toolbar-button"], ["translate", "global.form.back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i11.TranslateDirective, [i11.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null)], function (_ck, _v) { var currVal_0 = "global.form.back"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BreadcrumbComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(1, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BreadcrumbComponent_2)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n      "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BreadcrumbComponent_3)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.standardBackButton; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.standardBackButton; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_BreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-header", [["style", "z-index:9999"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "ion-toolbar", [["id", "breadCrumToolbar"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(4, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BreadcrumbComponent_1)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), i1.ɵncd(0, 0), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n\n  "])), i1.ɵncd(0, 1), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideButtons; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_BreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumb", [], null, null, null, View_BreadcrumbComponent_0, RenderType_BreadcrumbComponent)), i1.ɵdid(1, 114688, null, 0, i13.BreadcrumbComponent, [i12.Location, i14.Router, i15.PlatformHelperService, i16.ListingService, i17.ListingSearchService, i18.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbComponentNgFactory = i1.ɵccf("app-breadcrumb", i13.BreadcrumbComponent, View_BreadcrumbComponent_Host_0, { options: "options", hideButtons: "hideButtons", standardBackButton: "standardBackButton", backToPage: "backToPage" }, {}, ["*", "[secondaryToolbar]"]);
export { BreadcrumbComponentNgFactory as BreadcrumbComponentNgFactory };
