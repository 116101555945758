import { Component, Input } from '@angular/core';

@Component({
  selector: 'nearby-listing-card',
  templateUrl: './nearby-listing.component.html',
  styleUrls: ['./nearby-listing.component.scss']
})
export class NearbyListingCardComponent {
  @Input('selectedBuildingName') selectedBuildingName: any;
  @Input('nearbyListings') nearbyListings: any[];
  @Input('searchCriteria') searchCriteria: any;
  @Input('searchTags') searchTags: any[];
}
