import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@app/core';
import { BaseService } from './base-service';
import { map } from 'rxjs/operators';
import { RequestInfoDTO } from '@app/models/request-information.model';

@Injectable()
export class RequestInfoService extends BaseService {
  requestInfoAPI = `/api/info/request-info/{lang}`;

  constructor(private http: HttpClient, private i18n: I18nService) {
    super();
  }

  sendRequestInfoEmail(dto: RequestInfoDTO, lang?: string) {
    if (!lang) {
      lang = 'en';
    }

    const url = super.interporlateURL(this.requestInfoAPI, { lang: lang });

    return this.http
      .post(url, dto, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }
}
