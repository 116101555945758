import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { I18nService, ImageService, PlatformHelperService } from '@app/core';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { ServiceProvider } from '@app/models/service-provider';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { faChevronRight, faChevronLeft, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() spotBuildingId: number;
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;
  services: ServiceProvider[] = [];
  defaultImage: string;
  faChevronRight: IconDefinition;
  faChevronLeft: IconDefinition;

  constructor(
    private spotBuildingsService: SpotBuildingsService,
    private i18NService: I18nService,
    private platformConfigHelper: PlatformHelperService,
    private userActivityService: UserActivityService,
    private imgService: ImageService
  ) {
    this.faChevronRight = faChevronRight;
    this.faChevronLeft = faChevronLeft;
  }

  ngOnInit() {
    this.getServices();
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
  }

  getServices(): void {
    this.spotBuildingsService.getServiceProvider(this.spotBuildingId).then((response: ServiceProvider[]) => {
      this.services = response;

      this.services.forEach((item: ServiceProvider) => {
        item.serviceCategory = this.i18NService.getTranslation(item.serviceCategory);
        item.services = this.removeHTMLCode(this.i18NService.getTranslation(item.services));
      });
    });
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }

  getMediumImg(id: number) {
    return this.imgService.mediumThumbnail(id);
  }

  getCroppedOrOriginalImage(buildinListingPhoto: any) {
    if (buildinListingPhoto) {
      if (buildinListingPhoto.croppedImage && buildinListingPhoto.croppedImage.id) {
        return buildinListingPhoto.croppedImage.id;
      }
      return buildinListingPhoto.image.id;
    }
  }

  public async getPreviousPhoto() {
    this.photoSlider.slidePrev();
  }

  public async getNextPhoto() {
    this.photoSlider.slideNext();
  }

  cssImgServiceMobile() {
    return {
      width: window.screen.width - 80 + 'px',
      height: ((window.screen.width - 80) / 4) * 3 + 'px'
    };
  }

  goToService(service: ServiceProvider) {
    window.open(service.website, '_blank');
    this.registerUserActivity(service.id, UserActivityType.SERVICE_PROVIDER_DETAIL);
  }

  registerUserActivity(adCompanyId: number, module: UserActivityType) {
    const userActivity: UserActivity = {
      module: module,
      brokerId: null,
      buildingListingId: this.spotBuildingId,
      adCompanyId: adCompanyId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }
}
