import { AgentDTO } from './spot-buildings/agent-broker.model';
export class CompanyDTO {
  id: number;
  name: string;
  mainImageId: number;
  users: AgentDTO[];
  biography: string;
  websiteURL: string;
  spotProfile: boolean;
}
