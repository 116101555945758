import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MoreFilterMinMaxDTO, MoreFilterRangesDTO } from '@app/models/spot-buildings/spot-buildings-types';
import { IonGrid, NavParams, PopoverController } from '@ionic/angular';
import { SpotBuildingsSearchService } from '../../../services/spot-buildings-search.service';
import { SpotBuildingSearchRequest } from '../../../../models/spot-buildings/spot-building-search.model';
import { I18nService } from '@app/core';
import { PropertySubType } from '@app/models/property-subtype.model';
import * as Fuse from 'fuse.js';
import { SpotTitlesOrAddress } from '@app/models/titlesOrAddressDTO';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CoworkingSpaceType } from '@app/models/spot-buildings/spot-available-spaces';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-more-filters-spotbuildings',
  templateUrl: './more-filters-spotbuildings.component.html',
  styleUrls: ['./more-filters-spotbuildings.component.scss']
})
export class MoreFiltersSpotbuildingsComponent implements OnInit {
  @ViewChild('modalContent', { read: ElementRef, static: false }) modalContent: ElementRef;

  checkboxValues: any;
  propertyType: any;
  expandFilters: any;
  rangeFilters: any;
  btnFilter: boolean = false;
  minMaxvalues: any;
  rangeModelValues: any;
  staticMinMax: MoreFilterRangesDTO;
  searchDto: SpotBuildingSearchRequest;
  loading: boolean = true;
  states: any = [];
  cities: any;
  selectProperty = true;
  clear: number = 0;
  isMobile: boolean = false;
  user: any;

  propertySearch: any;
  public inputBuilding: string = '';
  fuse: Fuse<any, any>;
  selectedProperty: SpotTitlesOrAddress[] = [];
  stopFuzzy = false;
  stateName: string = '';
  cityName: string = '';
  faTimes: any;
  isAdmin: boolean;

  filter: number = 0;

  constructor(
    private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private spotBuildingsSearchService: SpotBuildingsSearchService,
    private i18NService: I18nService
  ) {
    this.faTimes = faTimes;
  }

  ngOnInit(): void {
    this.getPopData();

    if (window.screen.width < 1000) {
      this.isMobile = true;
    }

    if (this.searchDto.coworkingType) {
      this.coworkingFilter(0, this.searchDto.coworkingType);
    } /* else {
      this.coworkingFilter(1, CoworkingSpaceType.PRIVATE_OFFICE);
    } */

    if (!this.searchDto.buildingTypes) {
      this.searchDto.buildingTypes = [];
      this.searchDto.buildingTypes[0] = '2001';
    }

    if (!this.searchDto.listingLeaseOrSale) {
      this.searchDto.listingLeaseOrSale = [];
      this.searchDto.listingLeaseOrSale[0] = 'L';
    }

    if (!this.searchDto.propertySubTypes) {
      this.searchDto.propertySubTypes = [];
    }

    if (this.searchDto.spotTitlesOrAddresses) {
      this.selectedProperty = this.searchDto.spotTitlesOrAddresses;
    }

    if (!this.searchDto.spotStatus) {
      this.searchDto.spotStatus = 'ACTIVE';
    }

    this.isAdmin = this.checkSpotAdmin();

    this.setMinOptions(this.searchDto);
    this.getStatesByCountry();
    this.getPropertySearch();
    this.getcitiesByState(null);
  }

  async getPropertySearch() {
    this.propertySearch = await this.spotBuildingsSearchService.getPropertySearch(
      this.searchDto.buildingTypes[0],
      this.searchDto.listingLeaseOrSale[0],
      this.searchDto.propertySubTypes.length > 0 ? this.searchDto.propertySubTypes[0].id : null
    );
    this.initBuildingSearch();
  }

  async changeFuzzySearch() {
    this.propertySearch = this.suggest_property(this.inputBuilding);
  }

  async initBuildingSearch() {
    const options: Fuse.FuseOptions<any> = {
      keys: ['name'],
      shouldSort: true,
      caseSensitive: false,
      threshold: 0.5,
      findAllMatches: true
    };

    this.fuse = new Fuse(this.propertySearch, options);
    this.changeFuzzySearch();
  }

  async setProperty(building: SpotTitlesOrAddress) {
    this.inputBuilding = '';
    this.searchDto.stateId = null;
    this.searchDto.cityId = null;
    this.stateName = '';
    this.cityName = '';
    this.selectedProperty.push(building);
  }

  suggest_property(term: any) {
    if (term) {
      var q = term.toLowerCase().trim();
      return this.fuse
        .search(q)
        .slice(0, 10)
        .map((element: any) => {
          return element;
        });
    }
  }

  checkSpotAdmin() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user && this.user.roles.includes('ROLE_SPOT_ADMIN')) {
      return true;
    } else {
      return false;
    }
  }

  removeProperty(index: number) {
    this.selectedProperty.splice(index, 1);
  }

  clearProperties() {
    this.selectedProperty = [];
  }

  setBuildinType(event: any) {
    this.selectProperty = false;
    this.searchDto.buildingTypes[0] = event;
    if (this.searchDto.listingLeaseOrSale[0] == 'C') {
      this.setListingLeaseOrSale('L');
    }

    this.clearSearch();
    this.getStatesByCountry();
    this.getPropertySearch();
    this.setMinOptions(this.searchDto);
  }

  setDefaultBuildinTypeAndListingType() {
    this.selectProperty = false;
    this.searchDto.buildingTypes[0] = '2001';
    this.setListingLeaseOrSale('L');
    this.clearSearch();
    this.getStatesByCountry();
    this.getPropertySearch();
  }

  setPropertySubTypes(event: any) {
    let subtype: PropertySubType;
    if (event == 1001) {
      subtype = {
        id: event,
        name: 'Industrial Complex',
        isChecked: true,
        translationNames: ''
      };
      this.searchDto.propertySubTypes[0] = subtype;
    } else {
      if (event == 1002) {
        subtype = {
          id: event,
          name: 'Stand Alone',
          isChecked: true,
          translationNames: ''
        };
        this.searchDto.propertySubTypes[0] = subtype;
      } else {
        this.searchDto.propertySubTypes = [];
      }
    }
    this.getPropertySearch();
    this.getStatesByCountry();
  }

  setListingLeaseOrSale(event: any) {
    this.searchDto.listingLeaseOrSale[0] = event;
    if (event == 'C') {
      this.searchDto.buildingTypes[0] = '2001';
    }
    this.clearSearch();
    this.getPropertySearch();
    this.getStatesByCountry();
    this.setMinOptions(this.searchDto);
  }

  setActiveFilter(active: any) {
    if (active) {
      this.searchDto.spotStatus = 'ACTIVE';
    } else {
      this.searchDto.spotStatus = 'INACTIVE';
    }
  }

  private getBuildingSubTypeId() {
    if (this.searchDto.propertySubTypes && this.searchDto.propertySubTypes.length > 0) {
      return this.searchDto.propertySubTypes[0].id;
    }
    return null;
  }

  async getStatesByCountry() {
    if (!this.searchDto.stateId) {
      this.searchDto.stateId = null;
      this.searchDto.cityId = null;
      this.states = [];
      this.cities = [];
    }

    this.states = await this.spotBuildingsSearchService.getStatesByCountry(
      this.searchDto.buildingTypes[0],
      this.searchDto.listingLeaseOrSale[0],
      this.getBuildingSubTypeId()
    );

    if (this.searchDto.stateId && this.states) {
      let state = this.states.filter((state: any) => state.id == this.searchDto.stateId);
      this.stateName = state && state.length > 0 ? this.i18NService.getTranslation(state[0].name) : null;
    }

    this.states = this.states.sort(this.SortArray);

    this.putRioAndSaoPauloOnTop(this.states);

    if (this.searchDto.cityId) {
      this.getcitiesByState(null);
    }
  }

  SortArray(x: any, y: any) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }

  putRioAndSaoPauloOnTop(states: any) {
    if (states && states.length > 1) {
      for (var i = 0; i < states.length; ++i) {
        var state = states[i];
        if (state.name.includes('Rio de Janeiro') || state.name.includes('São Paulo')) {
          states.splice(i, 1);
          if (states[0].name.includes('São Paulo')) {
            states.splice(1, 0, state);
          } else {
            states.splice(0, 0, state);
          }
        }
      }
    }

    return states;
  }

  async getcitiesByState(state: any) {
    if (state != null) {
      this.stateName = this.i18NService.getTranslation(state.name);
      this.searchDto.stateId = state.id;
    }

    this.cities = await this.spotBuildingsSearchService.getcitiesByState(
      this.searchDto.buildingTypes[0],
      this.searchDto.stateId,
      this.searchDto.listingLeaseOrSale[0],
      this.getBuildingSubTypeId()
    );

    if (state == null) {
      let city = this.cities.filter((city: any) => city.id == this.searchDto.cityId);
      this.cityName = this.i18NService.getTranslation(city[0].name);
    }
  }

  setCity(city: any) {
    this.cityName = this.i18NService.getTranslation(city.name);
    this.searchDto.cityId = city.id;
  }

  getPopData() {
    this.checkboxValues = this.navParams.data.checkboxValues;
    if (this.navParams.data.propType === '') {
      this.propertyType = null;
    } else {
      this.propertyType = this.navParams.data.propType;
      this.searchDto = this.navParams.data.searchDto;
    }
  }

  setMinOptions(searchDto: any) {
    this.spotBuildingsSearchService
      .getMinMaxOptions(searchDto)
      .toPromise()
      .then((result: MoreFilterMinMaxDTO) => {
        localStorage.setItem('minMax', JSON.stringify(result));
        this.staticMinMax = this.spotBuildingsSearchService.setStaticMinMaxValues(result);
        this.rangeModelValues = this.spotBuildingsSearchService.mapMinMaxOptions(this.searchDto, this.staticMinMax);
        this.loading = false;
      });
  }

  dismiss() {
    this.rangeFilters = this.spotBuildingsSearchService.setSearchRangeFilters(this.rangeModelValues, this.staticMinMax);
    this.searchDto.spotTitlesOrAddresses = this.selectedProperty;
    let searchCopyDto = { ...this.searchDto, ...this.rangeFilters };
    if (this.filter == 4) {
      searchCopyDto.positions = null;
    }
    if (this.filter == 0) {
      searchCopyDto.coworkingType = null;
    }
    this.popoverCtrl.dismiss(searchCopyDto);
  }

  cancel() {
    this.popoverCtrl.dismiss();
  }

  clearSearch() {
    this.clear = this.clear + 1;
    this.searchDto = this.spotBuildingsSearchService.clearFilters(this.searchDto, this.checkboxValues);
    let result: MoreFilterMinMaxDTO = JSON.parse(localStorage.getItem('minMax'));
    this.staticMinMax = this.spotBuildingsSearchService.setStaticMinMaxValues(result);
    this.rangeModelValues = this.spotBuildingsSearchService.mapMinMaxOptions(null, this.staticMinMax);
    //this.searchDto.buildingTypes = ['2001'];
    //this.searchDto.listingLeaseOrSale = ['L'];
    this.searchDto.stateId = null;
    this.searchDto.cityId = null;
    this.searchDto.location = null;
    this.selectProperty = true;
    this.searchDto.yearBuiltIni = null;
    this.searchDto.yearBuiltEnd = null;
    this.searchDto.yearRenovatedIni = null;
    this.searchDto.yearRenovatedEnd = null;
    this.searchDto.spotTitlesOrAddresses = null;
    this.selectedProperty = [];
    this.stateName = '';
    this.cityName = '';
    this.searchDto.propertySubTypes = [];
    this.searchDto.availabilityDate = null;
    this.searchDto.coworkingType = null;
    this.searchDto.positions = null;
    this.filter = 0;
  }

  clearSearchExternal() {
    this.setDefaultBuildinTypeAndListingType();
  }

  scrollToTop() {
    this.modalContent.nativeElement.scrollTop = 0;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.cancel();
    }
  }

  coworkingFilter(coworkingType: number, CoworkingSpace?: string) {
    if (coworkingType == 1 || CoworkingSpace == CoworkingSpaceType.PRIVATE_OFFICE) {
      this.searchDto.coworkingType = CoworkingSpaceType.PRIVATE_OFFICE;
      this.filter = this.filter == 1 ? 0 : 1;
    }

    if (coworkingType == 2 || CoworkingSpace == CoworkingSpaceType.DEDICATED_WORKSPACE) {
      this.searchDto.coworkingType = CoworkingSpaceType.DEDICATED_WORKSPACE;
      this.filter = this.filter == 2 ? 0 : 2;
    }
    if (coworkingType == 3 || CoworkingSpace == CoworkingSpaceType.OPEN_WORKSPACE) {
      this.searchDto.coworkingType = CoworkingSpaceType.OPEN_WORKSPACE;
      this.filter = this.filter == 3 ? 0 : 3;
    }
    if (coworkingType == 4 || CoworkingSpace == CoworkingSpaceType.FULL_FLOOR_OFFICES) {
      this.searchDto.coworkingType = CoworkingSpaceType.FULL_FLOOR_OFFICES;
      this.filter = this.filter == 4 ? 0 : 4;
    }
  }

  setOptions() {
    this.setMinOptions(this.searchDto);
  }
}
