import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ImageUploadService {
  constructor(private http: HttpClient) {}

  private buildingListingEndpoint: string = 'api/buildinglistingPhotos';
  private buildingListingPhotosEndpoint: string = 'api/buildinglistingPhotosByListing/image-formats-only';

  private spaceEndpoint: string = 'api/spacePhotos';
  private spacePhotoEndpoint: string = 'api/spacePhotosBySpace';

  createImage(formData: FormData) {
    return this.http
      .post(`${this.buildingListingEndpoint}/no-listing/upload`, formData)
      .pipe(map(val => val))
      .toPromise();
  }

  createSpaceImage(formData: FormData) {
    return this.http
      .post(`${this.spaceEndpoint}/no-space/upload`, formData)
      .pipe(map(val => val))
      .toPromise();
  }

  associatePhotosToBuildingListing(buildingListingId: any, imgList: any) {
    if (!buildingListingId) {
      return imgList;
    }

    let buildingListingPhotos = imgList.map((photo: any) => {
      photo.buildingListingId = buildingListingId;
      return photo;
    });

    const photoIds = buildingListingPhotos.map((img: any) => img.id);
    this.http
      .put(`${this.buildingListingEndpoint}/listing/${buildingListingId}`, photoIds)
      .pipe(map(val => val))
      .toPromise();

    return buildingListingPhotos;
  }

  associatePhotosToSpace(spaceId: any, imgList: any) {
    if (!spaceId) {
      return imgList;
    }

    let spacePhotos = imgList.map((photo: any) => {
      photo.spaceId = spaceId;
      return photo;
    });

    const photoIds = spacePhotos.map((img: any) => img.id);
    this.http
      .put(`${this.spaceEndpoint}/space/${spaceId}`, photoIds)
      .pipe(map(val => val))
      .toPromise();

    return spacePhotos;
  }

  getBuildingListingPhotos(buildingListingId: number) {
    return this.http
      .get(`${this.buildingListingPhotosEndpoint}/?listingId=${buildingListingId}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  getSpacePhotos(spaceId: number) {
    return this.http
      .get(`${this.spacePhotoEndpoint}?spaceId=${spaceId}`, { observe: 'response' })
      .pipe(map(val => val.body))
      .toPromise();
  }

  async logListingPhotonDeletion(deletedListingPhoto: any) {
    return this.http
      .post('api/deletedphotolog/', deletedListingPhoto)
      .pipe(map(val => val))
      .toPromise();
  }

  async deleteBuildingListingPhoto(bildingListingPhoto: any) {
    return this.http
      .delete(`${this.buildingListingEndpoint}/${bildingListingPhoto.id}`)
      .pipe(map(val => val))
      .toPromise();
  }

  async deleteSpacePhoto(spacePhoto: any) {
    return this.http
      .delete(`${this.spaceEndpoint}/${spacePhoto.id}`)
      .pipe(map(val => val))
      .toPromise();
  }

  async uploadCroppedImage(file: any, caption: any, BuildingListingPhotoId: any) {
    const formData = new FormData();
    formData.append('listingPhotoId', BuildingListingPhotoId);
    formData.append('caption', caption);
    formData.append('file', file);

    return this.http
      .post(`${this.buildingListingEndpoint}/cropped/`, formData)
      .pipe(map(val => val))
      .toPromise();
  }

  async uploadCroppedImageSpace(file: any, caption: any, spacePhotoId: any) {
    const formData = new FormData();
    formData.append('spacePhotoId', spacePhotoId);
    formData.append('caption', caption);
    formData.append('file', file);

    return this.http
      .post(`${this.spaceEndpoint}/cropped/`, formData)
      .pipe(map(val => val))
      .toPromise();
  }

  async updateOrderOrCaptionFrom(buildingListingPhotoId: number, caption?: string, orderId?: number) {
    return this.http
      .patch(`${this.buildingListingEndpoint}/patch/${buildingListingPhotoId}`, { orderId: orderId, caption: caption })
      .pipe(map(val => val))
      .toPromise();
  }

  async updateOrderOrCaptionFromSpace(spacePhotoId: number, caption?: string, orderId?: number) {
    return this.http
      .patch(`${this.spaceEndpoint}/patch/${spacePhotoId}`, { orderId: orderId, caption: caption })
      .pipe(map(val => val))
      .toPromise();
  }

  async setFloorPlant(spacePhotoId: number, floorplant: boolean) {
    return this.http
      .patch(`${this.spaceEndpoint}/patch/${spacePhotoId}`, { floorplant: floorplant })
      .pipe(map(val => val))
      .toPromise();
  }
}
