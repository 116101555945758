import { Router } from '@angular/router';
import { AuthenticationService } from '../core/auth/auth.service';
import { LoginFormDialogService } from '@app/shared/login';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AccessGrantedDialogComponent } from '@app/shared';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../core/auth/auth.service";
import * as i3 from "../shared/login/login-form-dialog.service";
import * as i4 from "@angular/material/dialog";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, authenticationService, loginFormDialog, dialog) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.loginFormDialog = loginFormDialog;
        this.dialog = dialog;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.authenticationService.isLoggedIn().pipe(map(function (isAuthenticated) {
            if (!isAuthenticated && state.url === '/user-home') {
                return false;
            }
            else if (!isAuthenticated) {
                //if (environment.production || environment.spotServerUrl.includes('dev')) {
                var access = localStorage.getItem('accessGranted');
                if (!access) {
                    _this.router.navigate(['/']);
                    //this.checkAccess();
                    return false;
                }
                else {
                    _this.loginFormDialog.openDialog();
                    _this.router.navigate(['/']);
                    return false;
                }
                /*} else {
                  this.loginFormDialog.openDialog();
                  this.router.navigate(['/']);
                  return false;
                }*/
            }
            return true;
        }));
    };
    AuthGuard.prototype.checkAccess = function () {
        this.dialog.open(AccessGrantedDialogComponent, { disableClose: true });
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.LoginFormDialogService), i0.ɵɵinject(i4.MatDialog)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
