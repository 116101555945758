export class BuildingFeaturesDTO {
  twentyFourHourSecurity: false;
  electricGenerator: false;
  heliport: false;
  sprinklers: false;
  leedStatus: false;
  singleTenant: false;
  multiTenant: false;
  bicycleRack: false;
  fiberOptic: false;
  centralAirConditioning: false;
  petFriendly: false;
  truckYard: false;
  crossDocking: false;
  sluiceGate: false;
  blockAndSteelConstruction: false;
  blockConstruction: false;
  steelConstruction: false;
  includesOffice: false;
  includesRetail: false;
  coldStorage: false;
  fullBuildOut: false;
  skyLights: false;
  bulletProofCabin: false;
  mailPackageHandling: false;
  barista: false;
  enhancedCleaning: false;
  cafeteria: false;
  waterHydrant: false;
  withinIndustrialPark: false;
  accessToShowers: false;
}
