import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { I18nService, ImageService, PlatformHelperService } from '@app/core';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivityType } from '@app/models/user-activity.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IonSlides } from '@ionic/angular';
var ServicesComponent = /** @class */ (function () {
    function ServicesComponent(spotBuildingsService, i18NService, platformConfigHelper, userActivityService, imgService) {
        this.spotBuildingsService = spotBuildingsService;
        this.i18NService = i18NService;
        this.platformConfigHelper = platformConfigHelper;
        this.userActivityService = userActivityService;
        this.imgService = imgService;
        this.services = [];
        this.faChevronRight = faChevronRight;
        this.faChevronLeft = faChevronLeft;
    }
    ServicesComponent.prototype.ngOnInit = function () {
        this.getServices();
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    };
    ServicesComponent.prototype.getServices = function () {
        var _this = this;
        this.spotBuildingsService.getServiceProvider(this.spotBuildingId).then(function (response) {
            _this.services = response;
            _this.services.forEach(function (item) {
                item.serviceCategory = _this.i18NService.getTranslation(item.serviceCategory);
                item.services = _this.removeHTMLCode(_this.i18NService.getTranslation(item.services));
            });
        });
    };
    ServicesComponent.prototype.removeHTMLCode = function (htmlText) {
        var div = document.createElement('div');
        div.innerHTML = htmlText;
        var text = div.textContent || div.innerText || '';
        return text;
    };
    ServicesComponent.prototype.getMediumImg = function (id) {
        return this.imgService.mediumThumbnail(id);
    };
    ServicesComponent.prototype.getCroppedOrOriginalImage = function (buildinListingPhoto) {
        if (buildinListingPhoto) {
            if (buildinListingPhoto.croppedImage && buildinListingPhoto.croppedImage.id) {
                return buildinListingPhoto.croppedImage.id;
            }
            return buildinListingPhoto.image.id;
        }
    };
    ServicesComponent.prototype.getPreviousPhoto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.photoSlider.slidePrev();
                return [2 /*return*/];
            });
        });
    };
    ServicesComponent.prototype.getNextPhoto = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.photoSlider.slideNext();
                return [2 /*return*/];
            });
        });
    };
    ServicesComponent.prototype.cssImgServiceMobile = function () {
        return {
            width: window.screen.width - 80 + 'px',
            height: ((window.screen.width - 80) / 4) * 3 + 'px'
        };
    };
    ServicesComponent.prototype.goToService = function (service) {
        window.open(service.website, '_blank');
        this.registerUserActivity(service.id, UserActivityType.SERVICE_PROVIDER_DETAIL);
    };
    ServicesComponent.prototype.registerUserActivity = function (adCompanyId, module) {
        var userActivity = {
            module: module,
            brokerId: null,
            buildingListingId: this.spotBuildingId,
            adCompanyId: adCompanyId
        };
        this.userActivityService.saveUserActivity(userActivity);
    };
    return ServicesComponent;
}());
export { ServicesComponent };
