import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AccountService } from '@app/core/auth';
import { UserHomeService } from '@app/core/user-home/user-home.service';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CommonGenericService } from '@app/core/common.service';
import { ImageService } from '@app/core';
import { RegisterListingService } from '@app/core/register-listing.service';
var EditImageModalComponent = /** @class */ (function () {
    function EditImageModalComponent(modalCtrl, imgService, registerListinService) {
        this.modalCtrl = modalCtrl;
        this.imgService = imgService;
        this.registerListinService = registerListinService;
        this.UPLOAD_FILE_SIZE_LIMIT = 20000000;
        this.imageCropperOptions = {};
        this.imageChangedEvent = {};
        this.originalImage = null;
        this.croppedImage = null;
        this.filename = null;
        this.originalImageId = null;
        this.uploadingNewPhoto = false;
        this.isProcessingImg = null;
        this.existingImgURL = null;
        this.canvasRotation = 0;
        this.rotation = 0;
        this.transform = {};
    }
    EditImageModalComponent.prototype.convertToDataURL = function (imageUrl, callback) {
        var xhr = new XMLHttpRequest();
        this.filename = this.commonService.getFileNameFromPath(imageUrl);
        xhr.onload = function () {
            var fileReader = new FileReader();
            fileReader.onloadend = function () {
                callback(fileReader.result);
            };
            fileReader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', imageUrl);
        xhr.responseType = 'blob';
        xhr.send();
    };
    EditImageModalComponent.prototype.closeModal = function () {
        this.modalCtrl.dismiss();
    };
    EditImageModalComponent.prototype.resetFileInput = function (ctrl) {
        try {
            ctrl.value = null;
        }
        catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    };
    EditImageModalComponent.prototype.fileUploadErrorHandler = function (action) {
        var infoModalParams = {
            headerTitle: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFile' : 'global.uploadImage',
            bodyMessage: action.toLowerCase() === 'invalid-file' ? 'global.invalidImageFileError' : 'global.uploadImageSizeExceed'
        };
        this.imageChangedEvent = null;
        this.originalImage = null;
        this.isProcessingImg = null;
        this.filename = null;
        this.resetFileInput(document.getElementById('inputImageFile'));
        this.openInfoModal(infoModalParams.headerTitle, infoModalParams.bodyMessage);
    };
    EditImageModalComponent.prototype.uploadOriginalImage = function (login, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userHomeService
                            .uploadUserProfileImage(login, file)
                            .toPromise()
                            .then(function (result) {
                            return { originalImageId: result.imageId, error: false };
                        })
                            .catch(function (error) {
                            return { response: error, error: true };
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EditImageModalComponent.prototype.updateProfileImage = function (originalImageId, login, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userHomeService
                            .updateUserProfileImage(login, file, this.uploadingNewPhoto)
                            .toPromise()
                            .then(function (result) {
                            var profileImageId = { originalImageId: originalImageId, profileImageId: result.imageId };
                            var params = _this.userHomeService.getUploadImageInfoModalParam(true, profileImageId);
                            _this.closeModal();
                            _this.openInfoModal(params.headerTitle, params.bodyMessage, params.action, params.extraData);
                        })
                            .catch(function (error) {
                            var params = _this.userHomeService.getUploadImageInfoModalParam(false);
                            _this.openInfoModal(params.headerTitle, params.bodyMessage, params.action);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditImageModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.imageCropperOptions = this.editLogo
            ? this.userHomeService.getLogoCropperOptions()
            : this.userHomeService.getImageCropperOptions();
        var imgURL;
        this.isProcessingImg = false;
        if (this.profileImage) {
            if (this.profileImage.originalImage) {
                imgURL = this.profileImage.originalImage;
            }
            else {
                imgURL = this.profileImageUrl;
            }
        }
        else {
            imgURL = this.profileImageUrl;
        }
        this.existingImgURL = imgURL;
        this.convertToDataURL(imgURL, function (dataURL) {
            _this.profileImgBase64 = dataURL;
        });
    };
    EditImageModalComponent.prototype.imageCropped = function (image) {
        this.croppedImage = image.base64;
    };
    EditImageModalComponent.prototype.setNewImageFlag = function (flag) {
        this.uploadingNewPhoto = true;
    };
    EditImageModalComponent.prototype.fileChangedEvent = function (event, remove) {
        var _this = this;
        this.uploadingNewPhoto = false;
        this.originalImage = null;
        this.isProcessingImg = true;
        this.profileImgBase64 = null;
        this.existingImgURL = null;
        if (remove) {
            this.croppedImage = null;
            this.imageChangedEvent = null;
        }
        else {
            var imageExtensions = this.commonService.getValidImageExension();
            var isValidImage = this.commonService.validateExtension(imageExtensions, event.srcElement.files[0].name);
            if (!isValidImage) {
                this.fileUploadErrorHandler('invalid-file');
                return;
            }
            else if (event.srcElement.files[0].size > this.UPLOAD_FILE_SIZE_LIMIT) {
                this.fileUploadErrorHandler('exceed-limit');
                return;
            }
            this.imageChangedEvent = event;
            var file = event.srcElement.files[0];
            this.filename = event.srcElement.files[0].name;
            var fileReader_1 = new FileReader();
            fileReader_1.readAsDataURL(file);
            if (this.editLogo) {
                fileReader_1.onload = function () {
                    var originalImageFile = _this.convertImageToFile(fileReader_1.result);
                    _this.registerListinService.uploadNewImage(originalImageFile, "Logo_cropped").then(function (createdImages) {
                        _this.originalImageId = createdImages[0].id;
                        var imgURL = _this.imgService.mediumThumbnail(_this.originalImageId);
                        _this.convertToDataURL(imgURL, function (dataURL) {
                            _this.originalImage = dataURL;
                        });
                    });
                };
            }
            else {
                fileReader_1.onload = function () {
                    var originalImageFile = _this.convertImageToFile(fileReader_1.result);
                    _this.uploadOriginalImage(_this.login, originalImageFile).then(function (result) {
                        if (result.error) {
                            var params = _this.userHomeService.getUploadImageInfoModalParam(false);
                            _this.openInfoModal(params.headerTitle, params.bodyMessage, params.action);
                        }
                        else {
                            _this.originalImageId = result.originalImageId;
                            _this.isProcessingImg = false;
                        }
                    });
                };
            }
        }
    };
    EditImageModalComponent.prototype.cancel = function () {
        this.closeModal();
    };
    EditImageModalComponent.prototype.convertImageToFile = function (imgBase64) {
        return this.commonService.convertImageBase64ToFileSync(imgBase64, this.filename);
    };
    EditImageModalComponent.prototype.saveImage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var croppedImage;
            return tslib_1.__generator(this, function (_a) {
                croppedImage = this.croppedImage == null ? this.croppedImage : this.convertImageToFile(this.croppedImage);
                if (this.editLogo) {
                    this.updateLogoImage(croppedImage);
                }
                else {
                    this.updateProfileImage(this.originalImageId, this.login, croppedImage);
                }
                return [2 /*return*/];
            });
        });
    };
    EditImageModalComponent.prototype.updateLogoImage = function (croppedImage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(croppedImage != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.registerListinService.uploadNewImage(croppedImage, "Logo_cropped").then(function (createdImages) {
                                if (createdImages) {
                                    _this.closeModal();
                                    _this.imgService.setLogoId(createdImages[0].id);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.closeModal();
                        this.imgService.setLogoId(croppedImage);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EditImageModalComponent.prototype.rotateLeft = function () {
        this.canvasRotation--;
        this.flipAfterRotate();
    };
    EditImageModalComponent.prototype.rotateRight = function () {
        this.canvasRotation++;
        this.flipAfterRotate();
    };
    EditImageModalComponent.prototype.flipAfterRotate = function () {
        var flippedH = this.transform.flipH;
        var flippedV = this.transform.flipV;
        this.transform = tslib_1.__assign({}, this.transform, { flipH: flippedV, flipV: flippedH });
    };
    EditImageModalComponent.prototype.flipHorizontal = function () {
        this.transform = tslib_1.__assign({}, this.transform, { flipH: !this.transform.flipH });
    };
    EditImageModalComponent.prototype.flipVertical = function () {
        this.transform = tslib_1.__assign({}, this.transform, { flipV: !this.transform.flipV });
    };
    EditImageModalComponent.prototype.resetImage = function () {
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    };
    return EditImageModalComponent;
}());
export { EditImageModalComponent };
