import * as tslib_1 from "tslib";
import { I18nService } from '../i18n.service';
import { RegisterService } from '../register.service';
var StatusHelperService = /** @class */ (function () {
    function StatusHelperService(i18NService, registerService) {
        this.i18NService = i18NService;
        this.registerService = registerService;
    }
    StatusHelperService.prototype.updateSelectedStatusesBasedOnDTO = function (listingSearchDto) {
        var _this = this;
        this.selectStatus = [];
        if (listingSearchDto.listingStatuses && this.statuses) {
            if (listingSearchDto.listingStatuses.length > 0) {
                listingSearchDto.listingStatuses.forEach(function (statusTag) {
                    var statusFound = _this.statuses.find(function (status) { return status.id == statusTag; });
                    if (statusFound) {
                        _this.selectStatus.push(statusFound);
                    }
                });
            }
        }
        return this.selectStatus;
    };
    /**
     // tslint:disable-next-line: jsdoc-format
     * Get the current selected status Ids
     */
    StatusHelperService.prototype.getStatusIds = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var arr;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                arr = [];
                Object.keys(this.selectStatus).forEach(function (key, idx) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        arr.push(this.selectStatus[key].id);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/, arr];
            });
        });
    };
    StatusHelperService.prototype.removeStatus = function (status) {
        var _this = this;
        this.selectStatus.forEach(function (item, index) {
            if (item.id == status.id) {
                _this.selectStatus.splice(index, 1);
                return;
            }
        });
    };
    /**
     * Returns translated statuses.
     */
    StatusHelperService.prototype.getStatuses = function () {
        var _this = this;
        return this.getStatusType().then(function (statusType) {
            _this.statuses = statusType.map(function (item) {
                var clonedStatus = { status: item.status };
                var clonedItem = tslib_1.__assign({}, item, { status: _this.i18NService.getTranslation(clonedStatus.status) });
                return clonedItem;
            });
        });
    };
    StatusHelperService.prototype.getStatusType = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.statusType) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.registerService.getListingStatus().toPromise()];
                    case 1:
                        _a.statusType = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, this.statusType];
                }
            });
        });
    };
    return StatusHelperService;
}());
export { StatusHelperService };
