import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { I18nService, PlatformHelperService, ImageService } from '@app/core';
import { AccountService } from '@app/core/auth';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertController, NavParams, PopoverController } from '@ionic/angular';
import { RequestInfoService } from '../../services/request-info.service';
import { AuthenticationService } from '@app/core/auth/auth.service';
import { Router } from '@angular/router';
import { LoginFormDialogService } from '@app/shared/login';
import { RequestInformationType } from '@app/models/request-information.model';
import { WhatsAppService } from '../../../core/whatsapp.service';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
var RequestInformationComponent = /** @class */ (function () {
    function RequestInformationComponent(navParams, i18n, popoverCtrl, platformConfigHelper, imageService, alertCtrl, accountSrv, requestInfoSrv, auth, router, loginFormDialog, userActivityService, whatsappService) {
        this.navParams = navParams;
        this.i18n = i18n;
        this.popoverCtrl = popoverCtrl;
        this.platformConfigHelper = platformConfigHelper;
        this.imageService = imageService;
        this.alertCtrl = alertCtrl;
        this.accountSrv = accountSrv;
        this.requestInfoSrv = requestInfoSrv;
        this.auth = auth;
        this.router = router;
        this.loginFormDialog = loginFormDialog;
        this.userActivityService = userActivityService;
        this.whatsappService = whatsappService;
        this.agents = [];
        this.isMobile = false;
        this.requestInfoType = RequestInformationType.REQ_INFO;
    }
    RequestInformationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (window.screen.width < 1000) {
            this.isMobile = true;
        }
        this.auth.$isLoginSubject.subscribe(function (val) { return (_this.isLoggedIn = val); });
        this.customLoginText = this.i18n.get('global.form.defaultSignInMessage');
        this.createForm();
        this.closeIcon = faTimesCircle;
        this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
        this.input = this.navParams.data.reqInfoInput;
        this.spotTitle = this.input.requestTitle;
        this.noAgentPhotos = !this.input.agents || this.input.agents.length == 0;
        this.agents = this.input.agents && this.input.agents.length > 0 ? tslib_1.__spread(this.input.agents) : [];
        if (this.agents && this.agents.length > 2) {
            this.agents = this.agents.slice(0, 2);
        }
        if (this.agents && this.agents.length > 0) {
            this.companyLogoImageId = this.agents[0].companyLogoImageId;
            if (!this.companyLogoImageId) {
                this.companyLogoImageId = this.input.companyLogoImageId;
            }
        }
        this.setUserInfo();
        this.newToSiilaTxt = this.i18n.get('requestInformation.newToSiila');
        if (this.input.requestType) {
            this.requestInfoType = this.input.requestType;
        }
        if (this.requestInfoType == RequestInformationType.REQ_INFO) {
            this.userActivityService.trackSpotBuildingMessage(this.input.spotId);
        }
        if (this.requestInfoType == RequestInformationType.REQ_INFO ||
            this.requestInfoType == RequestInformationType.REQ_BROKER) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.pleaseSendInfo');
            this.modalTitle = this.i18n.get('requestInformation.title');
        }
        if (this.requestInfoType == RequestInformationType.REQ_BROKER) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.pleaseSendInfo');
            this.modalTitle = this.i18n.get('requestInformation.title');
        }
        if (this.requestInfoType == RequestInformationType.REQ_TOUR) {
            this.userActivityService.trackSpotBuildingRequestVisit(this.input.spotId);
            this.sendPlaceHolder = this.i18n.get('requestInformation.requestTourSendPlaceHolder');
            this.modalTitle = this.i18n.get('requestInformation.tourTitle');
        }
        if (this.requestInfoType == RequestInformationType.REQ_COMPANY) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.companyPlaceholder');
            this.modalTitle = this.i18n.get('requestInformation.companyTitle');
        }
        if (this.requestInfoType == RequestInformationType.REQ_SCHEDULE) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.schedulePlaceholder');
            this.modalTitle = this.i18n.get('requestInformation.scheduleTitle');
        }
        if (this.requestInfoType == RequestInformationType.REQ_CLAIM) {
            this.sendPlaceHolder = this.i18n.get('requestInformation.claimPlaceholder');
            this.modalTitle = this.i18n.get('requestInformation.claimTitle');
        }
        this.formData.controls['note'].setValue(this.sendPlaceHolder);
    };
    RequestInformationComponent.prototype.createForm = function () {
        this.formData = new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            phoneNumber: new FormControl(''),
            /* ext: new FormControl('', Validators.required), */
            email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')]),
            note: new FormControl('', Validators.required)
        });
        this.noteFormCtrl = this.formData.controls['note'];
    };
    RequestInformationComponent.prototype.setUserInfo = function () {
        this.currentUser = this.accountSrv.getCurrentUser;
        if (this.currentUser != null) {
            this.formData.controls['firstName'].setValue("" + this.currentUser.firstName);
            this.formData.controls['lastName'].setValue("" + this.currentUser.lastName);
            this.formData.controls['email'].setValue("" + this.currentUser.email);
            var phones = this.currentUser.phones;
            if (phones != null && phones.length > 0) {
                var phone = phones.find(function (phone) { return phone.phoneType === 'PHONE_NUMBER'; });
                if (phone != null && phone.phoneNumber.length > 0) {
                    this.formData.controls['phoneNumber'].setValue(phone.phoneNumber);
                }
                else {
                    phone = phones.find(function (phone) { return phone.phoneType === 'WHATSAPP'; });
                    if (phone != null) {
                        this.formData.controls['phoneNumber'].setValue(phone.phoneNumber);
                    }
                }
            }
        }
    };
    RequestInformationComponent.prototype.closeModal = function () {
        this.popoverCtrl.dismiss();
    };
    RequestInformationComponent.prototype.getCompanyLogoImg = function (id) {
        return id ? this.getMediumImg(id) : '../../../assets/placeholder-image.png';
    };
    RequestInformationComponent.prototype.getMediumImg = function (id) {
        return this.imageService.mediumThumbnail(id);
    };
    RequestInformationComponent.prototype.sendMessage = function () {
        var _this = this;
        if (this.formData.invalid) {
            this.showAlert(this.i18n.get('requestInformation.enterRequiredFields'));
            return;
        }
        else {
            // send contact email
            if ((this.input.sentEmailTo == undefined || this.input.sentEmailTo.length == 0) && this.agents.length > 0) {
                this.input.sentEmailTo = [this.agents[0].userEmail];
            }
            var requestInfoDTO = {
                spotTitle: this.spotTitle,
                firstName: this.formData.value.firstName,
                lastName: this.formData.value.lastName,
                /* ext: this.formData.value.ext, */
                phoneNumber: this.formData.value.phoneNumber,
                comments: this.formData.value.note,
                email: this.formData.value.email,
                sentEmailTo: this.input.sentEmailTo,
                spotId: this.input.spotId,
                brokerRequest: this.input.brokerRequest,
                requestType: this.requestInfoType
            };
            this.requestInfoSrv.sendRequestInfoEmail(requestInfoDTO, this.i18n.getCurrentLanguage()).then(function (result) {
                if (result) {
                    _this.showAlert(_this.i18n.get('requestInformation.sucessMsg'));
                }
            });
        }
    };
    RequestInformationComponent.prototype.disableSentBtn = function () {
        var invalid = this.formData &&
            (this.formData.get('email').invalid ||
                this.formData.get('note').invalid ||
                this.formData.get('firstName').invalid ||
                this.formData.get('lastName').invalid ||
                this.formData.get('phoneNumber').invalid); /* ||
        this.formData.get('ext').invalid); */
        return invalid;
    };
    RequestInformationComponent.prototype.openLoginPopup = function () {
        var _this = this;
        if (!this.isLoggedIn) {
            this.openLoginDialog(this.customLoginText)
                .afterClosed()
                .subscribe(function () {
                _this.formData.enable();
                if (_this.isLoggedIn) {
                    _this.setUserInfo();
                }
            });
        }
    };
    RequestInformationComponent.prototype.openLoginDialog = function (text) {
        var currentRoute = this.router.url;
        this.formData.disable();
        return this.loginFormDialog.openDialog({
            url: currentRoute,
            customText: text,
            autoClose: true,
            autoFocus: true,
            restoreFocus: true
        });
    };
    RequestInformationComponent.prototype.clickToChat = function (phoneNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var spotURL, headerMsg, msgToUserAgent, message;
            return tslib_1.__generator(this, function (_a) {
                spotURL = encodeURI(this.buildUrl());
                headerMsg = this.i18n.get('global.broker-detail.clickToChat.whatsAppMsgHeader');
                msgToUserAgent = this.i18n.get('global.broker-detail.clickToChat.msgToUserAgent');
                message = headerMsg + " " + spotURL + "\n\n" + msgToUserAgent + "\n    ";
                this.whatsappService.sendMessageViaWhataspp(phoneNumber, message);
                return [2 /*return*/, false];
            });
        });
    };
    RequestInformationComponent.prototype.buildUrl = function () {
        return window.location.href.concat(this.i18n.getLanguagePathURL());
    };
    RequestInformationComponent.prototype.openWhatsapp = function (event, agent) {
        event.stopImmediatePropagation();
        if (agent.showWhatsapp && agent.phoneNumber) {
            this.clickToChat(agent.phoneNumber);
        }
        agent.showWhatsapp = true;
        return false;
    };
    RequestInformationComponent.prototype.showAlert = function (alertMsg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertCtrl
                            .create({
                            header: this.i18n.get('global.form.sendMsgToAgent.notification'),
                            subHeader: alertMsg,
                            buttons: [
                                {
                                    text: this.i18n.get('global.form.sendMsgToAgent.ok'),
                                    handler: function () {
                                        if (_this.formData.valid) {
                                            _this.popoverCtrl.dismiss();
                                        }
                                    }
                                }
                            ]
                        })
                            .then(function (alert) {
                            alert.present();
                        })];
                    case 1:
                        alert = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return RequestInformationComponent;
}());
export { RequestInformationComponent };
