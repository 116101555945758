import { CompanyDTO } from '../company.model';
import { AgentDTO } from './agent-broker.model';
import { SpacePhotoDTO } from './space-photo.model';

export interface SpacesParameters {
  id: number;
  buildingListingId: number;
  companyId?: number;
  agentId?: number;
  secondAgentId?: number;
  agentDTO?: AgentDTO;
  secondAgentDTO?: AgentDTO;
  spaceCondition: SpaceCondition;
  description: string;
  industrialLeaseType?: string;
  warehouse: string;
  module: string;
  floor?: string;
  suite?: string;
  ceilingHeight?: number;
  docks?: number;
  truckParkingSpaces?: number;
  loadCapacity?: number;
  monthlyIptu: number;
  maxContiguous?: number;
  area: number;
  parkingSpaces?: number;
  meetingRoomHoursPerMonth?: number;
  printers?: number;
  askingPrice?: number;
  monthlyAskingRent: number;
  monthlyCondo: number;
  term: number;
  skyLights?: boolean;
  coldStorage?: boolean;
  includesOffice?: boolean;
  includesRetail?: boolean;
  fullBuildOut?: boolean;
  mailPackageHandling?: boolean;
  conferenceRoomAccess?: boolean;
  barista?: boolean;
  enhancedCleaning?: boolean;
  createdDate: string;
  modifiedDate?: string;
  spacePhotos?: SpacePhotoDTO[];
  totalMonthlyRent: number;
  investmentOpportunity?: string;
  mailHandling?: boolean;
  parkingAvailable?: boolean;
  sharedOutdoor?: boolean;
  privateOutdoor?: boolean;
  printCopies?: number;
  monthlyCost?: number;
  parkingCost?: number;
  conferenceTimeHours?: number;
  positions?: number;
  coworkingType?: string;
  vrTourUrl?: string;
  availabilityDate?: Date;
  select: boolean;
}

export interface SpaceCondition {
  id: number;
  type: string;
  name: string;
  forIndustrial: boolean;
  forOffice: boolean;
  forCoworking: boolean;
}

export interface SpaceDetailsTableField {
  name: string;
  translate: string;
  value: any;
  priceClass?: boolean;
  decimals?: number;
}

export interface SpaceDetailsTableRow {
  col1: SpaceDetailsTableField;
  col2?: SpaceDetailsTableField;
}

export enum UnitOfMeasure {
  SQUARE_METERS = 'm²',
  METERS = 'm',
  PRICE = 'R$',
  SQUARE_TON_METERS = 'ton/m²',
  PRICE_SQUARE_METERS = 'R$/m²',
  MXN = 'MXN'
}

export interface SpacesFilterDTO {
  totalAreaIni: number; // OR aVAILABLE SPACE
  totalAreaEnd: number;
  totalAskingPriceIni: number;
  totalAskingPriceEnd: number;
  totalMonthlyRentIni: number;
  totalMonthlyRentEnd: number;
  askingPricePerAreaMin: number;
  askingPricePerAreaMax: number;
  monthlyRentPerAreaMin: number;
  monthlyRentPerAreaMax: number;
  positions: number;
  availabilityDate: Date;
  conferenceRoom: boolean;
  privateOutdoorSpace: boolean;
  parkingAvailable: boolean;
  coworkingType: CoworkingSpaceType;

  plugAndPlayConditionCoworking?: boolean;
  furnishedConditionCoworking?: boolean;
  includeColdStorage: boolean;
  skyLightsSpace: boolean;
  subLease: boolean;
  datacenterCondition: boolean;
  forRetailUseConditionIndustrial: boolean;
  flexCondition: boolean;
  manufacturingCondition: boolean;
  logisticsCondition: boolean;
  preBuildCondition: boolean;
  plugAndPlayConditionOffice: boolean;
  fullFloorCondition: boolean;
  forRetailUseConditionOffice: boolean;
  coreAndShellCondition: boolean;

  monthlyCostPerPersonMin: number;
  monthlyCostPerPersonMax: number;
}

export interface SpaceFilterCondition {
  totalAreaFilter: (space: SpacesParameters) => boolean;
  totalAskingPriceFilter: (space: SpacesParameters) => boolean;
  totalmonthlyRentFilter: (space: SpacesParameters) => boolean;
  askingPricePerAreaFilter: (space: SpacesParameters) => boolean;
  monthlyRentPerAreaFilter: (space: SpacesParameters) => boolean;
  includeColdStorageFilter: (space: SpacesParameters) => boolean;
  skyLightsSpaceFilter: (space: SpacesParameters) => boolean;
  subLeaseFilter: (space: SpacesParameters) => boolean;
  dataCenterConditionFilter: (space: SpacesParameters) => boolean;
  forRetailUseConditionIndustrialFilter: (space: SpacesParameters) => boolean;
  flexConditionFilter: (space: SpacesParameters) => boolean;
  manufacturingConditionFilter: (space: SpacesParameters) => boolean;
  logisticsConditionFilter: (space: SpacesParameters) => boolean;
  preBuildConditionFilter: (space: SpacesParameters) => boolean;
  plugAndPlayConditionOfficeFilter: (space: SpacesParameters) => boolean;
  fullFloorConditionFilter: (space: SpacesParameters) => boolean;
  forRetailUseConditionOfficeFilter: (space: SpacesParameters) => boolean;
  coreAndShellConditionFilter: (space: SpacesParameters) => boolean;
  positionsFilter: (space: SpacesParameters) => boolean;
  availabilityDateFilter: (space: SpacesParameters) => boolean;
  conferenceRoomFilter: (space: SpacesParameters) => boolean;
  parkingAvailableFilter: (space: SpacesParameters) => boolean;
  privateOutdoorFilter: (space: SpacesParameters) => boolean;
  furnishedConditionFilter: (space: SpacesParameters) => boolean;
  plugAndPlayConditionCoworkingFilter: (space: SpacesParameters) => boolean;
  monthlyCostPerPersonConditionFilter: (space: SpacesParameters) => boolean;
  coworkingTypeFilter: (space: SpacesParameters) => boolean;
}

export enum IndustrialLeaseType {
  SUBLEASE = 'SUBLEASE',
  DIRECT = 'DIRECT'
}

export enum CoworkingSpaceType {
  PRIVATE_OFFICE = 'PRIVATE_OFFICE',
  DEDICATED_WORKSPACE = 'DEDICATED_WORKSPACE',
  OPEN_WORKSPACE = 'OPEN_WORKSPACE',
  FULL_FLOOR_OFFICES = 'FULL_FLOOR_OFFICES'
}

export enum SpaceConditionsTypes {
  DATA_CENTER = 1,
  FLEX = 2,
  FOR_RETAIL_USE_I = 3,
  LOGISTICS = 4,
  MANUFACTURING = 5,
  OTHER_I = 6,
  FULL_FLOOR = 7,
  PREBUILD = 8,
  CORE_AND_SHEEL = 9,
  PLUG_AND_PLAY_O = 10,
  FOR_RETAIL_USE_O = 11,
  OTHER_O = 12,
  FURNISHED = 13,
  PLUG_AND_PLAY_C = 14
}
