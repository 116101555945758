import * as tslib_1 from "tslib";
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, MenuController, ModalController, NavController, PickerController, Platform, PopoverController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
var BackwardGuard = /** @class */ (function () {
    function BackwardGuard(platform, router, location, navController, menuController, alertController, pickerController, actionSheetController, popoverController, modalController) {
        this.platform = platform;
        this.router = router;
        this.location = location;
        this.navController = navController;
        this.menuController = menuController;
        this.alertController = alertController;
        this.pickerController = pickerController;
        this.actionSheetController = actionSheetController;
        this.popoverController = popoverController;
        this.modalController = modalController;
    }
    BackwardGuard.prototype.canDeactivate = function (component, currentRoute, currentState) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentNavigation, backButtonPressed, isMenuOpen, activeController, _a, _b, _c, _d;
            var _this = this;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!this.platform.is('mobileweb')) {
                            return [2 /*return*/, true];
                        }
                        currentNavigation = this.router.getCurrentNavigation();
                        backButtonPressed = currentNavigation.trigger === 'popstate';
                        if (!backButtonPressed || this.isPop) {
                            return [2 /*return*/, true];
                        }
                        // Workaround for history being broken
                        // https://github.com/angular/angular/issues/13586#issuecomment-458241789
                        this.location.go(currentState.url);
                        return [4 /*yield*/, this.menuController.isOpen()];
                    case 1:
                        isMenuOpen = _e.sent();
                        if (isMenuOpen) {
                            this.menuController.close();
                            return [2 /*return*/, false]; // Prevent navigation
                        }
                        return [4 /*yield*/, this.alertController.getTop()];
                    case 2:
                        _d = (_e.sent());
                        if (_d) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.pickerController.getTop()];
                    case 3:
                        _d = (_e.sent());
                        _e.label = 4;
                    case 4:
                        _c = _d;
                        if (_c) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.actionSheetController.getTop()];
                    case 5:
                        _c = (_e.sent());
                        _e.label = 6;
                    case 6:
                        _b = _c;
                        if (_b) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.popoverController.getTop()];
                    case 7:
                        _b = (_e.sent());
                        _e.label = 8;
                    case 8:
                        _a = _b;
                        if (_a) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.modalController.getTop()];
                    case 9:
                        _a = (_e.sent());
                        _e.label = 10;
                    case 10:
                        activeController = _a;
                        if (activeController) {
                            activeController.dismiss();
                            return [2 /*return*/, false]; // Prevent navigation
                        }
                        // Pop
                        this.isPop = true;
                        this.navController.pop().then(function () {
                            _this.isPop = false;
                        });
                        return [2 /*return*/, false]; // Prevent navigation
                }
            });
        });
    };
    BackwardGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackwardGuard_Factory() { return new BackwardGuard(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i1.MenuController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i1.PickerController), i0.ɵɵinject(i1.ActionSheetController), i0.ɵɵinject(i1.PopoverController), i0.ɵɵinject(i1.ModalController)); }, token: BackwardGuard, providedIn: "root" });
    return BackwardGuard;
}());
export { BackwardGuard };
