/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lazy-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./lazy-slider.component";
import * as i6 from "../services/lazy-slider.service";
var styles_LazySliderComponent = [i0.styles];
var RenderType_LazySliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LazySliderComponent, data: {} });
export { RenderType_LazySliderComponent as RenderType_LazySliderComponent };
function View_LazySliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-slides", [["class", "lazy-slides"], ["id", "slider"]], null, [[null, "ionSlideDidChange"], [null, "ionSlidesDidLoad"], [null, "ionSlideNextEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionSlideDidChange" === en)) {
        var pd_0 = (_co.notifyChange() !== false);
        ad = (pd_0 && ad);
    } if (("ionSlidesDidLoad" === en)) {
        var pd_1 = (_co.onSlidesInit() !== false);
        ad = (pd_1 && ad);
    } if (("ionSlideNextEnd" === en)) {
        var pd_2 = (_co.onSlideNextEnd() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(1, 49152, [[1, 4], ["slider", 4]], 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { options: [0, "options"], pager: [1, "pager"], scrollbar: [2, "scrollbar"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), i1.ɵncd(0, 0), (_l()(), i1.ɵted(-1, 0, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliderOptions; var currVal_1 = _co.showPager; var currVal_2 = _co.showScrollbar; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LazySliderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center text-secondary my-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noDataMsg; _ck(_v, 1, 0, currVal_0); }); }
function View_LazySliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "slide-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LazySliderComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LazySliderComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadedItems.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.loadedItems.length; _ck(_v, 6, 0, currVal_1); }, null); }
function View_LazySliderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "slide-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "ion-slides", [["class", "lazy-slides"], ["id", "slider"]], null, [[null, "ionSlideDidChange"], [null, "ionSlidesDidLoad"], [null, "ionSlideNextEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionSlideDidChange" === en)) {
        var pd_0 = (_co.notifyChange() !== false);
        ad = (pd_0 && ad);
    } if (("ionSlidesDidLoad" === en)) {
        var pd_1 = (_co.onSlidesInit() !== false);
        ad = (pd_1 && ad);
    } if (("ionSlideNextEnd" === en)) {
        var pd_2 = (_co.onSlideNextEnd() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonSlides_0, i2.RenderType_IonSlides)), i1.ɵdid(3, 49152, [[1, 4], ["slider", 4]], 0, i3.IonSlides, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { options: [0, "options"], pager: [1, "pager"], scrollbar: [2, "scrollbar"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n    "])), i1.ɵncd(0, 1), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliderOptions; var currVal_1 = _co.showPager; var currVal_2 = _co.showScrollbar; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LazySliderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { slider: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LazySliderComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LazySliderComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isPromoted; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isPromoted; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_LazySliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lazy-slider", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LazySliderComponent_0, RenderType_LazySliderComponent)), i1.ɵdid(1, 770048, null, 0, i5.LazySliderComponent, [i6.LazySliderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LazySliderComponentNgFactory = i1.ɵccf("lazy-slider", i5.LazySliderComponent, View_LazySliderComponent_Host_0, { noDataMsg: "noDataMsg", slideOptionOverride: "slideOptionOverride", showPager: "showPager", showScrollbar: "showScrollbar", loadedItems: "loadedItems", totalCount: "totalCount", loading: "loading", queryPage: "queryPage", queryPageSize: "querySize", changeSlide$: "changeSlide", isPromoted: "isPromoted" }, { getMoreItems: "getItems", onSliderUpdate: "onSliderUpdate" }, ["*", "*"]);
export { LazySliderComponentNgFactory as LazySliderComponentNgFactory };
