<form class="update-email-modal" [formGroup]="updateEmailForm">
  <ion-row>
    <ion-col class="header-col">
      <h4 class="header" color="secondary" [translate]="'global.user.updateEmailAddress'"></h4>
      <ion-text color="danger" [translate]="'global.user.message.updateEmail'"></ion-text>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="header-col">
      <ion-label color="secondary" [translate]="'global.user.newEmail'"></ion-label>
      <input type="text" class="form-control email-input" name="newPassword" formControlName="newEmail" />
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="header-col">
      <ion-label color="secondary" [translate]="'global.user.confirmNewEmail'"></ion-label>
      <input type="text" class="form-control email-input" name="confirmNewPassword" formControlName="confirmNewEmail" />

      <div *ngIf="isError" class="error-message">
        <ion-text color="danger" [translate]="infoProp.bodyMessage"></ion-text>
      </div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <div class="control-button" [ngClass]="{ 'error-control': isError }">
        <ion-button class="cancel-btn" [translate]="'Cancel'" (click)="cancel()"></ion-button>
        <ion-button color="secondary" [translate]="'Save'" [disabled]="validateForm()" (click)="updateEmail()">
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
</form>
