<div *ngIf="!subscribed">
  <ion-row>
    <div class="col-md-12">
      <a class="closeSubscribe" (click)="dismissAsDialog()">X</a>
      <h5 style="font-weight: 700; margin-top: 20px;font-size: 24px;">{{ 'global.subscribe.title' | translate }}</h5>
    </div>
  </ion-row>
  <ion-row class="row-border"></ion-row>
  <ion-row>
    <div class="text">
      <p *ngIf="lang != 'en'" class="fontText sizePt">{{ 'global.subscribe.subscribeText' | translate }}</p>
      <p *ngIf="lang == 'en'" class="fontText sizeEn">{{ 'global.subscribe.subscribeText' | translate }}</p>
    </div>
  </ion-row>
  <ion-row>
    <input
      class="form-control"
      autocomplete="on"
      placeholder="{{ 'global.subscribe.email' | translate }}"
      [formControl]="emailCtrl"
      autocomplete="email"
      required
    />
    <mat-error *ngIf="checkMail">
      <span class="invalid-field">{{ 'global.form.invalidEmail' | translate }}</span>
    </mat-error>
    <input
      class="form-control"
      autocomplete="on"
      [formControl]="userName"
      placeholder="{{ 'global.register.firstName' | translate }}"
      autocomplete="given-name"
      style="margin-top: 14px;"
      required
    />
    <input
      class="form-control"
      autocomplete="on"
      [formControl]="userLastName"
      placeholder="{{ 'global.register.lastName' | translate }}"
      autocomplete="family-name"
      style="margin-top: 14px;"
      required
    />
  </ion-row>
  <ion-row>
    <div class="col-md-12 text terms">
      <i>{{ 'global.subscribe.terms' | translate }}</i>
    </div>
  </ion-row>
  <ion-row>
    <div class="col-md-12 text">
      <ion-button
        (click)="subscribe()"
        class="btn-color-2"
        [disabled]="
          emailCtrl.hasError('required') || userName.hasError('required') || userLastName.hasError('required')
        "
        style="width: 166px;"
      >
        <span>{{ 'global.subscribe.subscribe' | translate }}</span>
      </ion-button>
    </div>
  </ion-row>
  <ion-row>
    <div class="col-md-12 text">
      <a class="font" style="color: #6C757D;"> {{ 'global.subscribe.registered' | translate }}</a>
      <a class="font" style="text-decoration: underline;cursor: pointer; color: #0D6EFD;" (click)="openLoginDialog()">
        {{ 'global.subscribe.login' | translate }}</a
      >
    </div>
  </ion-row>
  <ion-row>
    <div class="col-md-12 text">
      <a class="font" style="text-decoration: underline;cursor: pointer;" (click)="dismissAsDialog()">
        {{ 'global.subscribe.continue' | translate }}</a
      >
    </div>
  </ion-row>
</div>
<div *ngIf="subscribed">
  <ion-row>
    <div class="col-md-12" style="text-align: center;">
      <img class="checkIcon" src="assets/images/icons/check-mark-SPOT-box.png" alt="" />
      <a class="closeSubscribe" (click)="dismissAsDialog()">X</a>
      <h5 style="font-weight: 700;">{{ 'global.subscribe.successSubscription' | translate | uppercase }}</h5>
    </div>
  </ion-row>
  <ion-row class="row-border"></ion-row>
  <ion-row>
    <div class="col-md-12" style="text-align: center;margin-top: -20px;">
      <h5 style="font-weight: 600;color: #212529;">{{ 'global.subscribe.subscriptionMessage' | translate }}</h5>
    </div>
  </ion-row>
  <ion-row>
    <div class="text-Subscribed">
      <p class="fontText sizeEn">{{ 'global.subscribe.subscribedText' | translate }}</p>
    </div>
  </ion-row>
  <ion-row>
    <div class="text-Subscribed">
      <i class="fontText sizeEn">{{ 'global.subscribe.market' | translate }}</i>
      <i
        ><a
          class="font"
          style="text-decoration: underline;cursor: pointer; color: #0D6EFD;"
          (click)="openLoginDialog()"
        >
          {{ 'login' | translate }}</a
        ></i
      >
      <i class="fontText sizeEn">{{ 'global.subscribe.market2' | translate }}</i>
    </div>
  </ion-row>
  <ion-row>
    <div class="col-md-12 text">
      <ion-button (click)="navigateToRegister()" class="btn-color-2" style="width: 166px;">
        <span> {{ 'createAccount' | translate | uppercase }}</span>
      </ion-button>
    </div>
  </ion-row>
  <ion-row>
    <div class="col-md-12 text-Subscribed" style="margin-top: 10px;">
      <a class="font" style="text-decoration: underline;cursor: pointer;" (click)="dismissAsDialog()">
        {{ 'global.subscribe.maybeLater' | translate }}</a
      >
    </div>
  </ion-row>
</div>
